// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "./Modal.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import Shortid from "shortid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as TextInput from "./TextInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as IconSources from "./IconSources.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var content = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: /* [] */0
    });

var label = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(11)),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.lineHeight(Css.pct(140.0)),
          tl: {
            hd: Css.color(Styles.Color.light10),
            tl: {
              hd: Css.marginBottom(Css.px(2)),
              tl: {
                hd: Css.display("block"),
                tl: {
                  hd: Css.paddingLeft(Css.px(8)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var platforms = Curry._1(Css.style, {
      hd: Css.gridTemplateColumns({
            hd: {
              NAME: "repeat",
              VAL: [
                "autoFill",
                {
                  NAME: "minmax",
                  VAL: [
                    Css.px(64),
                    Css.fr(1.0)
                  ]
                }
              ]
            },
            tl: /* [] */0
          }),
      tl: {
        hd: Css.gridGap(Css.px(8)),
        tl: {
          hd: Css.display("grid"),
          tl: {
            hd: Css.paddingBottom(Css.px(20)),
            tl: {
              hd: Css.maxWidth(Css.px(440)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function platformButton(selected) {
  return Curry._1(Css.style, {
              hd: Css.justifyItems("center"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.gridTemplateRows({
                        hd: Css.px(40),
                        tl: {
                          hd: "auto",
                          tl: /* [] */0
                        }
                      }),
                  tl: {
                    hd: Css.display("grid"),
                    tl: {
                      hd: Css.width(Css.px(64)),
                      tl: {
                        hd: Css.height(Css.px(80)),
                        tl: {
                          hd: Css.borderWidth("zero"),
                          tl: {
                            hd: Css.borderRadius(Css.px(6)),
                            tl: {
                              hd: Css.backgroundColor(selected ? Styles.Color.white : "transparent"),
                              tl: {
                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(10), Css.px(20), undefined, undefined, selected ? Css.rgba(0, 0, 0, {
                                                NAME: "num",
                                                VAL: 0.05
                                              }) : "transparent")),
                                tl: {
                                  hd: Css.margin(Css.px(0)),
                                  tl: {
                                    hd: Css.paddingTop(Css.px(8)),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.color(selected ? Styles.Color.light12 : Styles.Color.light10),
                                        tl: {
                                          hd: Css.transition({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$default
                                              }, undefined, undefined, "all"),
                                          tl: {
                                            hd: Css.focus({
                                                  hd: Css.outlineStyle("none"),
                                                  tl: {
                                                    hd: Css.boxShadows({
                                                          hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, true, Styles.Color.deepBlue),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: {
                                              hd: Css.hover(selected ? ({
                                                        hd: Css.focus({
                                                              hd: Css.boxShadows({
                                                                    hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(4), Css.px(16), undefined, undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.05)),
                                                                    tl: {
                                                                      hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(0), Css.px(0), Css.px(2), true, Styles.Color.deepBlue),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: /* [] */0
                                                      }) : ({
                                                        hd: Css.color(Styles.Color.light12),
                                                        tl: {
                                                          hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
                                                          tl: /* [] */0
                                                        }
                                                      })),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function platformIcon(selected) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.width(Css.px(40)),
                    tl: {
                      hd: Css.height(Css.px(40)),
                      tl: {
                        hd: Css.borderRadius(Css.px(20)),
                        tl: {
                          hd: Css.backgroundColor(selected ? Styles.Color.light02 : Styles.Color.white),
                          tl: {
                            hd: Css.overflow("hidden"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var footer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.padding(Css.px(12)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function ConnectionsSourceCreateModal(Props) {
  var onClose = Props.onClose;
  var tabAfterCreate = Props.tabAfterCreate;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var sources = ModelStore.useSources(undefined);
  var match = React.useState(function () {
        return "";
      });
  var setName = match[1];
  var name = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setSelectedPlatform = match$1[1];
  var selectedPlatform = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setNameEdited = match$2[1];
  var nameEdited = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setLoading = match$3[1];
  var isLoading = match$3[0];
  var sourceNameIsUnique = Belt_List.every(sources, (function (source) {
          return Caml_obj.caml_notequal(Belt_Option.map(source.name, Case.camel), Case.camel(name));
        }));
  var handleCreate = function (param) {
    return Belt_Option.forEach(selectedPlatform, (function (platform) {
                  Curry._1(setLoading, (function (param) {
                          return true;
                        }));
                  var sourceId = Shortid();
                  var context = {
                    sourceId: sourceId,
                    sourceQuery: sourceId
                  };
                  Curry.app(sendActions, [
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        (function (branch) {
                            Router.Schema.pushDrawerItem(undefined, undefined, {
                                  NAME: "source",
                                  VAL: [
                                    sourceId,
                                    Belt_Option.getWithDefault(tabAfterCreate, "overview")
                                  ]
                                });
                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                            return AnalyticsRe.dataSourceUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, name, undefined, platform, undefined, AnalyticsUtils.analyticsLanguage(undefined), undefined, "", undefined, "", undefined, "SourceCreated", undefined, [], undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                          }),
                        undefined,
                        [
                          [
                            {
                              NAME: "CreateSource",
                              VAL: sourceId
                            },
                            context
                          ],
                          [
                            {
                              NAME: "UpdateSourceName",
                              VAL: [
                                sourceId,
                                name
                              ]
                            },
                            context
                          ],
                          [
                            {
                              NAME: "UpdateSourcePlatformV3",
                              VAL: [
                                sourceId,
                                platform
                              ]
                            },
                            context
                          ]
                        ]
                      ]);
                  Curry._1(setLoading, (function (param) {
                          return false;
                        }));
                  return Curry._1(onClose, undefined);
                }));
  };
  var creationDisabled = Belt_Option.isNone(selectedPlatform) || name.trim() === "" || !sourceNameIsUnique || isLoading;
  var hasJsonSchemaFeatureFlag = AppFeatureFlag.useFeatureFlag("JsonSchema");
  var hasGtmFeatureFlag = AppFeatureFlag.useFeatureFlag("Gtm");
  return React.createElement(Modal.make, {
              title: "New Source",
              onClose: onClose,
              children: null
            }, React.createElement("div", {
                  className: content
                }, React.createElement("div", {
                      className: label
                    }, "Platform"), React.createElement("div", {
                      className: platforms
                    }, Belt_Array.map(Belt_Array.concatMany([
                              AvoConfig.devPlatforms,
                              hasJsonSchemaFeatureFlag ? ["JsonSchema"] : [],
                              hasGtmFeatureFlag ? ["Gtm"] : []
                            ]), (function (platform) {
                            var selected = selectedPlatform !== undefined ? platform === selectedPlatform : false;
                            return React.createElement("button", {
                                        key: AvoConfig.devPlatformLabel(platform),
                                        className: platformButton(selected),
                                        onClick: (function (param) {
                                            Curry._1(setSelectedPlatform, (function (param) {
                                                    return platform;
                                                  }));
                                            if (!nameEdited) {
                                              return Curry._1(setName, (function (param) {
                                                            return AvoConfig.devPlatformLabel(platform);
                                                          }));
                                            }
                                            
                                          })
                                      }, React.createElement("div", {
                                            className: platformIcon(selected)
                                          }, React.createElement(IconSources.make, {
                                                platform: platform
                                              })), React.createElement($$Text.make, {
                                            size: "Tiny",
                                            weight: "Semi",
                                            children: AvoConfig.devPlatformLabel(platform)
                                          }));
                          }))), React.createElement("label", {
                      className: label,
                      htmlFor: "name"
                    }, "Name"), React.createElement(TextInput.make, {
                      id: "name",
                      onChange: (function (value) {
                          Curry._1(setNameEdited, (function (param) {
                                  return value !== "";
                                }));
                          return Curry._1(setName, (function (param) {
                                        return value;
                                      }));
                        }),
                      stretch: true,
                      onEnter: (function (param) {
                          if (creationDisabled) {
                            return ;
                          } else {
                            return handleCreate(undefined);
                          }
                        }),
                      value: name
                    }), sourceNameIsUnique ? null : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.paddingLeft(Css.px(8)),
                              tl: {
                                hd: Css.paddingTop(Css.px(4)),
                                tl: {
                                  hd: Css.marginBottom(Css.px(-4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            color: Styles.Color.red,
                            children: null
                          }, React.createElement($$Text.make, {
                                children: "Another source with the name \"" + name + "\" already exists."
                              }), React.createElement($$Text.make, {
                                children: "Please choose a unique name."
                              })))), React.createElement("footer", {
                  className: footer
                }, React.createElement(Button.make, {
                      disabled: isLoading,
                      label: "Cancel",
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        }),
                      style: "ghost"
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(Button.make, {
                      disabled: creationDisabled,
                      label: "Create Source",
                      loading: isLoading,
                      onClick: handleCreate
                    })));
}

var make = ConnectionsSourceCreateModal;

export {
  make ,
  
}
/* content Not a pure module */
