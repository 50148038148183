// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dopt from "./externals/Dopt.mjs";
import * as React from "react";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as ProductTour__Root from "./productTour/ProductTour__Root.mjs";
import * as ProductTour__Step from "./productTour/ProductTour__Step.mjs";
import * as CreateMoreProductTour from "./CreateMoreProductTour.mjs";
import * as DemoBranchProductTour from "./DemoBranchProductTour.mjs";
import * as ProductTour__GenericStep from "./productTour/ProductTour__GenericStep.mjs";
import * as ProductTour__GenericModal from "./productTour/ProductTour__GenericModal.mjs";
import * as HowToSaveViewAndGetAlertsFlow from "./HowToSaveViewAndGetAlertsFlow.mjs";

function ProductTours$TestFlow(Props) {
  return React.createElement(ProductTour__Root.make, {
              tourId: "test-flow",
              tourMayShow: true,
              children: null
            }, React.createElement(ProductTour__Step.make, {
                  stepId: "step-1",
                  stepType: {
                    TAG: /* Modal */3,
                    _0: {
                      NAME: "num",
                      VAL: 320
                    }
                  },
                  delay: 0,
                  transitions: [{
                      key: "next",
                      label: "Next",
                      icon: undefined
                    }],
                  children: React.createElement(ProductTour__GenericStep.make, {})
                }), React.createElement(ProductTour__Step.make, {
                  stepId: "step-2",
                  stepType: {
                    TAG: /* Modal */3,
                    _0: {
                      NAME: "num",
                      VAL: 320
                    }
                  },
                  delay: 0,
                  transitions: [{
                      key: "complete",
                      label: "Complete",
                      icon: undefined
                    }],
                  children: React.createElement(ProductTour__GenericStep.make, {
                        withExit: false
                      })
                }));
}

var TestFlow = {
  make: ProductTours$TestFlow
};

function ProductTours$DemoBranchWarning(Props) {
  var match = Dopt.Flow.use("demo-branch-product-tour");
  var demoBranchProductFlow = match[0];
  var branch = RouterStore.Schema.useBranch(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var showModal;
  if (typeof branch === "object" && branch.NAME === "branch") {
    var userId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
    showModal = branch.VAL === "demo-" + userId && schemaRoute !== "dashboard" && (demoBranchProductFlow.state.finished || demoBranchProductFlow.state.stopped);
  } else {
    showModal = false;
  }
  return React.createElement(ProductTour__Root.make, {
              tourId: "demo-branch-warning",
              tourMayShow: showModal,
              children: React.createElement(ProductTour__Step.make, {
                    stepId: "warning-modal",
                    stepType: {
                      TAG: /* Modal */3,
                      _0: {
                        NAME: "num",
                        VAL: 550
                      }
                    },
                    delay: 750,
                    transitions: [{
                        key: "complete",
                        label: "Got it!",
                        icon: undefined
                      }],
                    children: React.createElement(ProductTour__GenericModal.make, {})
                  })
            });
}

var DemoBranchWarning = {
  make: ProductTours$DemoBranchWarning
};

function ProductTours(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement(ProductTours$TestFlow, {}), React.createElement(DemoBranchProductTour.make, {}), React.createElement(ProductTours$DemoBranchWarning, {}), React.createElement(HowToSaveViewAndGetAlertsFlow.make, {}), React.createElement(CreateMoreProductTour.make, {}));
}

var make = ProductTours;

export {
  TestFlow ,
  DemoBranchWarning ,
  make ,
  
}
/* Dopt Not a pure module */
