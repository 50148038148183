// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as DemoBranch from "./DemoBranch.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as AvoEngagement from "./avoEngagement/AvoEngagement.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function LearnHub__DemoBranchTourButton(Props) {
  var onTourStart = Props.onTourStart;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var user = ViewerContext.use(undefined);
  var sources = ModelStore.useSources(undefined);
  var openBranches = ModelStore.useOpenBranches(undefined);
  var branch = RouterStore.Schema.useBranch(undefined);
  var modelMigrations = ModelStore.useMigrations(undefined);
  var events = ModelStore.useEvents(undefined);
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = Router.QueryParams.useStringKey("start_tour");
  var setStartTour = match.replace;
  var hasCreatedDemoBranch = Belt_Option.isSome(FirebaseFetcherHooks.useBranchOpenAction(schemaBundle.schemaId, "demo-" + user.id, "Demo", undefined, undefined));
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsLoading = match$1[1];
  var match$2 = AvoEngagement.Engagement.use("demo-branch-product-tour");
  var intent = match$2[1];
  var onClick = function (param) {
    if (Caml_obj.caml_equal(branch, {
            NAME: "branch",
            VAL: "demo-" + user.id
          })) {
      return Curry._1(intent.start, undefined);
    } else if (hasCreatedDemoBranch) {
      return Router.Schema.swapBranches(undefined, {
                  NAME: "branch",
                  VAL: "demo-" + user.id
                });
    } else {
      Curry._1(setIsLoading, (function (param) {
              return true;
            }));
      return DemoBranch.create(user, hasCreatedDemoBranch, sources, openBranches, schemaBundle, sendActions, (function (demoBranch) {
                    Curry._1(setIsLoading, (function (param) {
                            return false;
                          }));
                    Curry._1(setStartTour, "demo-branch-product-tour");
                    Router.Schema.swapBranches(undefined, {
                          NAME: "branch",
                          VAL: NamedBranch.getId(demoBranch)
                        });
                    Curry._1(onTourStart, undefined);
                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, demoBranch);
                    AnalyticsRe.demoBranchCreated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, Belt_List.length(events), NamedBranch.getId(demoBranch), schemaBundle$1.schemaId);
                    
                  }), (function (error) {
                    Curry._1(setIsLoading, (function (param) {
                            return false;
                          }));
                    console.warn("Error creating demo branch", error);
                    Sentry.captureException(error, {
                          message: "Error creating demo branch"
                        });
                    return Curry._1(addToast, {
                                message: "Could not create demo branch",
                                toastType: /* Error */1
                              });
                  }), Belt_List.some(modelMigrations, (function (migration) {
                        return migration === "EventSpecificAllowedPropertyValues";
                      })));
    }
  };
  return React.createElement(Button.make, {
              icon: "presentation",
              label: "Take tour",
              loading: match$1[0],
              onClick: onClick,
              style: {
                NAME: "custom",
                VAL: {
                  customColor: Styles.Color.white,
                  hoverColor: Styles.Color.white,
                  customBackgroundColor: Styles.Color.primaryPurple,
                  customBackgroundHoverColor: Styles.Color.darkPurple,
                  optionalCustomOutlineColor: undefined,
                  optionalCustomOutlineHoverColor: undefined
                }
              },
              minWidth: 132
            });
}

var make = LearnHub__DemoBranchTourButton;

export {
  make ,
  
}
/* Toast Not a pure module */
