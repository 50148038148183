// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as IconSend from "./IconSend.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SourceRowDiff from "./SourceRowDiff.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";

function DiffEventSources(Props) {
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var actions = Props.actions;
  var addedItems = Belt_List.keepMapU(actions, (function (action) {
          if (typeof action !== "object") {
            return ;
          }
          var variant = action.NAME;
          if (variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2") {
            return action.VAL[1];
          }
          
        }));
  var removedItems = Belt_List.keepMapU(actions, (function (action) {
          if (typeof action !== "object") {
            return ;
          }
          var variant = action.NAME;
          if (variant === "ExcludeEventFromSource" || variant === "ExcludeEventFromSourceV2") {
            return action.VAL[1];
          }
          
        }));
  var addedOrRemovedItems = Belt_List.concat(addedItems, removedItems);
  var changedItems = Belt_List.keepMap(BeltListExtensions.dedupeString(Belt_List.flatten(Belt_List.keepMapU(actions, (function (action) {
                      if (typeof action !== "object") {
                        return ;
                      }
                      var variant = action.NAME;
                      if (variant === "IncludeDestinationInEventSource" || variant === "ExcludeDestinationFromEventSource" || variant === "ToggleIncludeEventInCodegenForSource") {
                        return {
                                hd: action.VAL[1],
                                tl: /* [] */0
                              };
                      } else if (variant === "ConfigureInspectorValidation") {
                        return Belt_List.fromArray(Belt_MapString.keysToArray(action.VAL[1]));
                      } else {
                        return ;
                      }
                    })))), (function (sourceId) {
          if (Belt_List.some(addedOrRemovedItems, (function (id) {
                    return id === sourceId;
                  }))) {
            return ;
          } else {
            return sourceId;
          }
        }));
  var updatedItems = BeltListExtensions.dedupeString(Belt_List.concatMany([
            addedItems,
            removedItems,
            changedItems
          ]));
  if (updatedItems) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(14), Css.px(10), Css.px(0), Css.px(0)),
                          tl: {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.light07),
                            tl: {
                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement(IconSend.make, {})), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.width(Css.pct(100)),
                          tl: /* [] */0
                        })
                  }, Belt_List.toArray(Belt_List.concatMany([
                            Belt_List.mapU(removedItems, (function (sourceId) {
                                    return Belt_Option.mapWithDefault(Belt_List.getByU(masterModel.sources, (function (source) {
                                                      return source.id === sourceId;
                                                    })), null, (function (source) {
                                                  return React.createElement(SourceRowDiff.make, {
                                                              source: source,
                                                              colors: [
                                                                Styles.Color.darkRed,
                                                                Styles.Color.darkRed
                                                              ],
                                                              actions: actions,
                                                              branchModel: branchModel,
                                                              key: source.id + "-removed"
                                                            });
                                                }));
                                  })),
                            Belt_List.mapU(addedItems, (function (sourceId) {
                                    return Belt_Option.mapWithDefault(Belt_List.getByU(branchModel.sources, (function (source) {
                                                      return source.id === sourceId;
                                                    })), null, (function (source) {
                                                  return React.createElement(SourceRowDiff.make, {
                                                              source: source,
                                                              colors: [
                                                                Styles.Color.darkGreen,
                                                                Styles.Color.darkGreen
                                                              ],
                                                              actions: actions,
                                                              branchModel: branchModel,
                                                              key: source.id + "-added"
                                                            });
                                                }));
                                  })),
                            Belt_List.mapU(changedItems, (function (sourceId) {
                                    return Belt_Option.mapWithDefault(Belt_List.getByU(branchModel.sources, (function (source) {
                                                      return source.id === sourceId;
                                                    })), null, (function (source) {
                                                  return React.createElement(SourceRowDiff.make, {
                                                              source: source,
                                                              colors: [
                                                                Styles.Color.light10,
                                                                Styles.Color.light04
                                                              ],
                                                              actions: actions,
                                                              branchModel: branchModel,
                                                              key: source.id + "-changed"
                                                            });
                                                }));
                                  }))
                          ]))));
  } else {
    return null;
  }
}

var make = DiffEventSources;

export {
  make ,
  
}
/* Css Not a pure module */
