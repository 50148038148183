// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelStore from "../ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as InspectorViewHeader from "./InspectorViewHeader.mjs";
import * as InspectorEventViewModel from "./InspectorEventViewModel.mjs";
import * as InspectorIssuesGenerator from "../../../api/functions/src/inspectorPipeline/utils/InspectorIssuesGenerator.mjs";
import * as InspectorEventsStoreHooks from "./InspectorEventsStoreHooks.mjs";
import * as InspectorViewEventsList__V2 from "./InspectorViewEventsList__V2.mjs";
import * as InspectorViewTrackingPlanUtils from "./InspectorViewTrackingPlanUtils.mjs";

function InspectorViewWithStats__V2(Props) {
  var hasSourcesWithoutInspector = Props.hasSourcesWithoutInspector;
  var hasSourcesWithInspector = Props.hasSourcesWithInspector;
  var stats = Props.stats;
  var match = InspectorViewTrackingPlanUtils.useQueryTimeWindow(undefined);
  var timeWindow = match[0];
  var match$1 = InspectorViewTrackingPlanUtils.useQueryEnvironment(InspectorViewTrackingPlanUtils.getHighestEnvironmentWithData(stats));
  var environment = match$1[0];
  var match$2 = Hooks.useUrlQueryParam("filterByEventName", (function (name) {
          return Belt_Option.getWithDefault(name, "");
        }), (function (name) {
          return name;
        }));
  var filterByEventName = match$2[0];
  var match$3 = InspectorEventsStoreHooks.use(undefined, environment, timeWindow);
  var events = match$3.events;
  var mappedEvents = ModelStore.Mapped.useEvents(undefined);
  var rules = Belt_List.toArray(ModelStore.useRules(undefined));
  var eventNamesNotInTrackingPlan = Curry._2(InspectorEventViewModel.mapToArray, Curry._2(InspectorEventViewModel.keep, events, (function (param) {
              return Belt_Option.isNone(InspectorIssuesGenerator.getTrackingPlanEvent_mappedModel(mappedEvents, rules, param.eventName));
            })), (function (param) {
          return param.eventName;
        }));
  return React.createElement(React.Fragment, undefined, React.createElement(InspectorViewHeader.make, {
                  environment: environment,
                  eventNamesNotInTrackingPlan: eventNamesNotInTrackingPlan,
                  lastUpdated: undefined,
                  fetchEvents: match$3.fetch,
                  filterByEventName: filterByEventName,
                  loadingStatus: match$3.fetchingStatus,
                  newerVersionAvailable: match$3.canRefreshCurrentViewData,
                  onRefresh: match$3.refreshCurrentViewData,
                  setEnvironment: match$1[1],
                  setFiltering: match$2[1],
                  setTimeWindow: match[1],
                  timeWindow: timeWindow,
                  hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                  hasSourcesWithInspector: hasSourcesWithInspector,
                  inspectorView: "events"
                }), React.createElement(InspectorViewEventsList__V2.make, {
                  timeWindow: timeWindow,
                  environment: environment,
                  events: events,
                  loadingStatus: match$3.loadingStatus,
                  filterByEventName: filterByEventName,
                  mappedEvents: mappedEvents,
                  rules: rules
                }));
}

var EventsByName;

var make = InspectorViewWithStats__V2;

export {
  EventsByName ,
  make ,
  
}
/* Hooks Not a pure module */
