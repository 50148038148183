// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import Shortid from "shortid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as SheetTypes from "./SheetTypes.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as UniqueEventName from "./UniqueEventName.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as EventNameComponent from "../../shared/models/EventNameComponent.mjs";
import * as EventsSheet__Utils from "./EventsSheet__Utils.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GuardRailsConfigUtils from "./guardrails/GuardRailsConfigUtils.mjs";
import * as EventNameComponentCollection from "./EventNameComponentCollection.mjs";
import * as EventsSheet__EditableNameCell from "./EventsSheet__EditableNameCell.mjs";
import * as EventsSheet__EditableNameCellContext from "./EventsSheet__EditableNameCellContext.mjs";

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.height(Css.px(50)),
        tl: {
          hd: Css.cursor("pointer"),
          tl: /* [] */0
        }
      }
    });

var inputBox = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.zIndex(2),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.flexShrink(0.0),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.height(Css.pct(100)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(38)),
                    tl: {
                      hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.palePurple),
                      tl: {
                        hd: Css.borderRight(Css.px(1), "solid", Styles.Color.lightPurple),
                        tl: {
                          hd: Styles.backgroundColorGuarantee(Styles.Color.white),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var empty = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.height(Css.pct(100)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.borderRight(Css.px(1), "solid", Styles.Color.lightPurple),
              tl: {
                hd: Css.lastChild({
                      hd: Css.borderRight(Css.px(1), "solid", Styles.Color.palePurple),
                      tl: {
                        hd: Css.flexGrow(1.0),
                        tl: {
                          hd: Css.marginRight(Css.px(16)),
                          tl: /* [] */0
                        }
                      }
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function EventSheet_NewEvent(Props) {
  var columns = Props.columns;
  var currentFilters = Props.currentFilters;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var match$1 = React.useState(function () {
        return Uuid.v4();
      });
  var setEventId = match$1[1];
  var eventId = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setEditing = match$2[1];
  var editing = match$2[0];
  var match$3 = React.useState(function () {
        return 0;
      });
  var setNumEventsCreatedInSequence = match$3[1];
  var numEventsCreatedInSequence = match$3[0];
  var match$4 = React.useContext(DiscrepancyContext.context);
  var nameComponents = ValidationConfigV2.EventsV2.getNameComponents(match$4.config.events);
  var columnWidth = EventsSheet__Utils.getColumnWidthByType(columns, "EventName");
  var activeEditableEventId = Curry._1(EventsSheet__EditableNameCellContext.Provider.State.use, undefined);
  var setActiveEditableEventId = Curry._1(EventsSheet__EditableNameCellContext.Provider.SetState.use, undefined);
  React.useEffect((function () {
          if (editing) {
            if (ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events)) {
              var arg = Case.toAnalytics(expectedEventCase);
              var arg$1 = schemaBundle.branchId;
              var arg$2 = schemaBundle.schemaId;
              AnalyticsUtils.withSchemaBundle(AnalyticsUtils.withCurrentFilters((function (param) {
                          var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26) {
                            return AnalyticsRe.eventInitiated(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26);
                          };
                          return function (param) {
                            var func$1 = Curry._1(func, param);
                            return function (param) {
                              var func$2 = Curry._1(func$1, param);
                              return function (param) {
                                var func$3 = Curry._1(func$2, param);
                                return function (param) {
                                  var func$4 = Curry._1(func$3, param);
                                  return function (param) {
                                    var func$5 = Curry._1(func$4, param);
                                    return function (param) {
                                      var func$6 = Curry._1(func$5, param);
                                      return function (param) {
                                        var func$7 = Curry._1(func$6, param);
                                        return function (param) {
                                          var func$8 = Curry._1(func$7, param);
                                          return function (param) {
                                            var func$9 = Curry._1(func$8, param);
                                            return function (param) {
                                              var func$10 = Curry._1(func$9, param);
                                              return function (param) {
                                                var func$11 = Curry._1(func$10, param);
                                                return function (param) {
                                                  var func$12 = Curry._1(func$11, param);
                                                  return function (param) {
                                                    var func$13 = Curry._1(func$12, param);
                                                    return function (param) {
                                                      var func$14 = Curry._1(func$13, param);
                                                      return function (param) {
                                                        var func$15 = Curry._1(func$14, param);
                                                        return function (param) {
                                                          var func$16 = Curry._1(func$15, param);
                                                          return function (param) {
                                                            var func$17 = Curry._1(func$16, param);
                                                            return function (param) {
                                                              return Curry.app(func$17, [
                                                                          param,
                                                                          eventId,
                                                                          "",
                                                                          true,
                                                                          arg,
                                                                          "InlineRow",
                                                                          "EventsView",
                                                                          arg$1,
                                                                          arg$2
                                                                        ]);
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          };
                        }), currentFilters), schemaBundle);
            } else {
              var arg$3 = ValidationConfigV2.EventsV2.getNamesAsStructuredString(config.events);
              var arg$4 = ValidationConfigV2.EventsV2.getComponentCasesAsStructuredString(config.events);
              var arg$5 = schemaBundle.branchId;
              var arg$6 = schemaBundle.schemaId;
              AnalyticsUtils.withSchemaBundle(AnalyticsUtils.withCurrentFilters((function (param) {
                          var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27) {
                            return AnalyticsRe.eventInitiatedAdvancedEventName(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27);
                          };
                          return function (param) {
                            var func$1 = Curry._1(func, param);
                            return function (param) {
                              var func$2 = Curry._1(func$1, param);
                              return function (param) {
                                var func$3 = Curry._1(func$2, param);
                                return function (param) {
                                  var func$4 = Curry._1(func$3, param);
                                  return function (param) {
                                    var func$5 = Curry._1(func$4, param);
                                    return function (param) {
                                      var func$6 = Curry._1(func$5, param);
                                      return function (param) {
                                        var func$7 = Curry._1(func$6, param);
                                        return function (param) {
                                          var func$8 = Curry._1(func$7, param);
                                          return function (param) {
                                            var func$9 = Curry._1(func$8, param);
                                            return function (param) {
                                              var func$10 = Curry._1(func$9, param);
                                              return function (param) {
                                                var func$11 = Curry._1(func$10, param);
                                                return function (param) {
                                                  var func$12 = Curry._1(func$11, param);
                                                  return function (param) {
                                                    var func$13 = Curry._1(func$12, param);
                                                    return function (param) {
                                                      var func$14 = Curry._1(func$13, param);
                                                      return function (param) {
                                                        var func$15 = Curry._1(func$14, param);
                                                        return function (param) {
                                                          var func$16 = Curry._1(func$15, param);
                                                          return function (param) {
                                                            var func$17 = Curry._1(func$16, param);
                                                            return function (param) {
                                                              return Curry.app(func$17, [
                                                                          param,
                                                                          eventId,
                                                                          "",
                                                                          true,
                                                                          "InlineRow",
                                                                          "EventsView",
                                                                          arg$3,
                                                                          arg$4,
                                                                          arg$5,
                                                                          arg$6
                                                                        ]);
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          };
                        }), currentFilters), schemaBundle);
            }
          }
          
        }), [eventId]);
  var events = ModelStore.Mapped.useEvents(undefined);
  var onSave = function (nameComponents, guardrailsEnabled, name, schemaId, values, param, onError, param$1, withKeyboard) {
    var match;
    if (guardrailsEnabled) {
      var eventName = Belt_Array.map(values, (function (value) {
                if (value.NAME === "separator") {
                  return value.VAL;
                } else {
                  return Belt_Option.getWithDefault(value.VAL.value, "");
                }
              })).join("");
      var eventNameComponents = Belt_Array.keepMap(values, (function (value) {
              if (value.NAME === "separator") {
                return ;
              }
              var match = value.VAL;
              return EventNameComponent.make(match.name, Belt_Option.getWithDefault(match.value, ""));
            }));
      match = [
        eventNameComponents,
        eventName
      ];
    } else {
      match = [
        EventNameComponent.getEventNameComponentFromName(name, nameComponents),
        name
      ];
    }
    var eventName$1 = match[1];
    var eventNameComponents$1 = match[0];
    var uniqueName = UniqueEventName.getUniqueName(events, eventId, eventName$1);
    var actions = Belt_Array.concat([[
            {
              NAME: "AddEvent",
              VAL: [
                eventId,
                eventName$1
              ]
            },
            {
              eventId: eventId,
              eventQuery: eventId
            }
          ]], uniqueName !== undefined ? [[
              {
                NAME: "UpdateEventUniqueNameV2",
                VAL: [
                  eventId,
                  uniqueName
                ]
              },
              {
                eventId: eventId,
                eventQuery: eventId
              }
            ]] : []);
    var writeAction = function (param) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (param) {
                      Curry._1(addToast, {
                            message: "Event created",
                            toastType: /* Success */0,
                            action: {
                              message: "View",
                              toastCallback: (function (param) {
                                  return Router.Schema.pushDrawerItem(undefined, undefined, {
                                              NAME: "event",
                                              VAL: [
                                                eventId,
                                                undefined,
                                                undefined,
                                                false
                                              ]
                                            });
                                })
                            }
                          });
                      var newEventId = Shortid();
                      Curry._1(setEventId, (function (param) {
                              return newEventId;
                            }));
                      if (withKeyboard) {
                        Curry._1(setActiveEditableEventId, (function (param) {
                                return newEventId;
                              }));
                        return Curry._1(setEditing, (function (param) {
                                      return true;
                                    }));
                      }
                      
                    }),
                  (function (param) {
                      if (ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events)) {
                        var correctCase = NameUtils.isCorrectCase(expectedEventCase, name);
                        var arg = correctCase;
                        var arg$1 = Case.toAnalytics(expectedEventCase);
                        var arg$2 = Case.toIssueReportEventCasing(expectedEventCase);
                        var arg$3 = numEventsCreatedInSequence + 1 | 0;
                        var arg$4 = schemaBundle.branchId;
                        var arg$5 = schemaBundle.schemaId;
                        AnalyticsUtils.withSchemaBundle(AnalyticsUtils.withCurrentFilters((function (param) {
                                    var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31) {
                                      return AnalyticsRe.eventCreated(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31);
                                    };
                                    return function (param) {
                                      var func$1 = Curry._1(func, param);
                                      return function (param) {
                                        var func$2 = Curry._1(func$1, param);
                                        return function (param) {
                                          var func$3 = Curry._1(func$2, param);
                                          return function (param) {
                                            var func$4 = Curry._1(func$3, param);
                                            return function (param) {
                                              var func$5 = Curry._1(func$4, param);
                                              return function (param) {
                                                var func$6 = Curry._1(func$5, param);
                                                return function (param) {
                                                  var func$7 = Curry._1(func$6, param);
                                                  return function (param) {
                                                    var func$8 = Curry._1(func$7, param);
                                                    return function (param) {
                                                      var func$9 = Curry._1(func$8, param);
                                                      return function (param) {
                                                        var func$10 = Curry._1(func$9, param);
                                                        return function (param) {
                                                          var func$11 = Curry._1(func$10, param);
                                                          return function (param) {
                                                            var func$12 = Curry._1(func$11, param);
                                                            return function (param) {
                                                              var func$13 = Curry._1(func$12, param);
                                                              return function (param) {
                                                                var func$14 = Curry._1(func$13, param);
                                                                return function (param) {
                                                                  var func$15 = Curry._1(func$14, param);
                                                                  return function (param) {
                                                                    var func$16 = Curry._1(func$15, param);
                                                                    return function (param) {
                                                                      var func$17 = Curry._1(func$16, param);
                                                                      return function (param) {
                                                                        return Curry.app(func$17, [
                                                                                    param,
                                                                                    eventId,
                                                                                    name,
                                                                                    arg,
                                                                                    arg$1,
                                                                                    "EventsView",
                                                                                    arg$2,
                                                                                    "Basic",
                                                                                    true,
                                                                                    arg$3,
                                                                                    "Manual",
                                                                                    undefined,
                                                                                    arg$4,
                                                                                    arg$5
                                                                                  ]);
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  }), currentFilters), schemaBundle);
                        var arg$6 = Case.toAnalytics(expectedEventCase);
                        var arg$7 = name.length;
                        var arg$8 = Case.to_("Sentence", name).split(" ").length;
                        var arg$9 = correctCase;
                        var arg$10 = schemaBundle.branchId;
                        var arg$11 = schemaBundle.schemaId;
                        return AnalyticsUtils.withCurrentFilters(AnalyticsUtils.withSchemaBundle((function (param) {
                                          var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31) {
                                            return AnalyticsRe.eventNameUpdated(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31);
                                          };
                                          return function (param) {
                                            var func$1 = Curry._1(func, param);
                                            return function (param) {
                                              var func$2 = Curry._1(func$1, param);
                                              return function (param) {
                                                var func$3 = Curry._1(func$2, param);
                                                return function (param) {
                                                  var func$4 = Curry._1(func$3, param);
                                                  return function (param) {
                                                    var func$5 = Curry._1(func$4, param);
                                                    return function (param) {
                                                      var func$6 = Curry._1(func$5, param);
                                                      return function (param) {
                                                        var func$7 = Curry._1(func$6, param);
                                                        return function (param) {
                                                          var func$8 = Curry._1(func$7, param);
                                                          return function (param) {
                                                            var func$9 = Curry._1(func$8, param);
                                                            return function (param) {
                                                              var func$10 = Curry._3(func$9, param, eventId, name);
                                                              return function (param) {
                                                                var func$11 = Curry._1(func$10, param);
                                                                return function (param) {
                                                                  var func$12 = Curry._1(func$11, param);
                                                                  return function (param) {
                                                                    var func$13 = Curry._1(func$12, param);
                                                                    return function (param) {
                                                                      var func$14 = Curry._1(func$13, param);
                                                                      return function (param) {
                                                                        var func$15 = Curry._1(func$14, param);
                                                                        return function (param) {
                                                                          var func$16 = Curry._1(func$15, param);
                                                                          return function (param) {
                                                                            var func$17 = Curry._1(func$16, param);
                                                                            return function (param) {
                                                                              return Curry.app(func$17, [
                                                                                          param,
                                                                                          arg$6,
                                                                                          undefined,
                                                                                          arg$7,
                                                                                          arg$8,
                                                                                          arg$9,
                                                                                          "EventDetails",
                                                                                          eventId,
                                                                                          arg$10,
                                                                                          arg$11,
                                                                                          eventId,
                                                                                          name
                                                                                        ]);
                                                                            };
                                                                          };
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        }), schemaBundle), currentFilters);
                      }
                      var correctCase$1 = NameUtils.isCorrectCase(expectedEventCase, name);
                      var eventNamingConvention = GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(ValidationConfigV2.EventsV2.getNameComponents(config.events));
                      var advancedEventNameStructureComponentNames = ValidationConfigV2.EventsV2.getNamesAsStructuredString(config.events);
                      var advancedEventNameStructureComponentConfig = ValidationConfigV2.EventsV2.getComponentCasesAsStructuredString(config.events);
                      var arg$12 = correctCase$1;
                      var arg$13 = numEventsCreatedInSequence + 1 | 0;
                      var arg$14 = schemaBundle.branchId;
                      var arg$15 = schemaBundle.schemaId;
                      AnalyticsUtils.withSchemaBundle(AnalyticsUtils.withCurrentFilters((function (param) {
                                  var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31, param$32) {
                                    return AnalyticsRe.eventCreatedAdvancedNamingConvention(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31, param$32);
                                  };
                                  return function (param) {
                                    var func$1 = Curry._1(func, param);
                                    return function (param) {
                                      var func$2 = Curry._1(func$1, param);
                                      return function (param) {
                                        var func$3 = Curry._1(func$2, param);
                                        return function (param) {
                                          var func$4 = Curry._1(func$3, param);
                                          return function (param) {
                                            var func$5 = Curry._1(func$4, param);
                                            return function (param) {
                                              var func$6 = Curry._1(func$5, param);
                                              return function (param) {
                                                var func$7 = Curry._1(func$6, param);
                                                return function (param) {
                                                  var func$8 = Curry._1(func$7, param);
                                                  return function (param) {
                                                    var func$9 = Curry._1(func$8, param);
                                                    return function (param) {
                                                      var func$10 = Curry._1(func$9, param);
                                                      return function (param) {
                                                        var func$11 = Curry._1(func$10, param);
                                                        return function (param) {
                                                          var func$12 = Curry._1(func$11, param);
                                                          return function (param) {
                                                            var func$13 = Curry._1(func$12, param);
                                                            return function (param) {
                                                              var func$14 = Curry._1(func$13, param);
                                                              return function (param) {
                                                                var func$15 = Curry._1(func$14, param);
                                                                return function (param) {
                                                                  var func$16 = Curry._1(func$15, param);
                                                                  return function (param) {
                                                                    var func$17 = Curry._1(func$16, param);
                                                                    return function (param) {
                                                                      return Curry.app(func$17, [
                                                                                  param,
                                                                                  eventId,
                                                                                  name,
                                                                                  arg$12,
                                                                                  "Advanced",
                                                                                  "EventsView",
                                                                                  eventNamingConvention,
                                                                                  true,
                                                                                  arg$13,
                                                                                  "Manual",
                                                                                  undefined,
                                                                                  advancedEventNameStructureComponentNames,
                                                                                  advancedEventNameStructureComponentConfig,
                                                                                  arg$14,
                                                                                  arg$15
                                                                                ]);
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                }), currentFilters), schemaBundle);
                      var arg$16 = name.length;
                      var arg$17 = Case.to_("Sentence", name).split(" ").length;
                      var arg$18 = correctCase$1;
                      var arg$19 = schemaBundle.branchId;
                      var arg$20 = schemaBundle.schemaId;
                      AnalyticsUtils.withCurrentFilters(AnalyticsUtils.withSchemaBundle((function (param) {
                                  var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31, param$32) {
                                    return AnalyticsRe.eventNameUpdatedAdvancedStructure(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31, param$32);
                                  };
                                  return function (param) {
                                    var func$1 = Curry._1(func, param);
                                    return function (param) {
                                      var func$2 = Curry._1(func$1, param);
                                      return function (param) {
                                        var func$3 = Curry._1(func$2, param);
                                        return function (param) {
                                          var func$4 = Curry._1(func$3, param);
                                          return function (param) {
                                            var func$5 = Curry._1(func$4, param);
                                            return function (param) {
                                              var func$6 = Curry._1(func$5, param);
                                              return function (param) {
                                                var func$7 = Curry._1(func$6, param);
                                                return function (param) {
                                                  var func$8 = Curry._1(func$7, param);
                                                  return function (param) {
                                                    var func$9 = Curry._1(func$8, param);
                                                    return function (param) {
                                                      var func$10 = Curry._3(func$9, param, eventId, name);
                                                      return function (param) {
                                                        var func$11 = Curry._1(func$10, param);
                                                        return function (param) {
                                                          var func$12 = Curry._1(func$11, param);
                                                          return function (param) {
                                                            var func$13 = Curry._1(func$12, param);
                                                            return function (param) {
                                                              var func$14 = Curry._1(func$13, param);
                                                              return function (param) {
                                                                var func$15 = Curry._1(func$14, param);
                                                                return function (param) {
                                                                  var func$16 = Curry._1(func$15, param);
                                                                  return function (param) {
                                                                    var func$17 = Curry._1(func$16, param);
                                                                    return function (param) {
                                                                      return Curry.app(func$17, [
                                                                                  param,
                                                                                  undefined,
                                                                                  arg$16,
                                                                                  arg$17,
                                                                                  arg$18,
                                                                                  "EventDetails",
                                                                                  advancedEventNameStructureComponentNames,
                                                                                  advancedEventNameStructureComponentConfig,
                                                                                  eventId,
                                                                                  arg$19,
                                                                                  arg$20,
                                                                                  eventId,
                                                                                  name
                                                                                ]);
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                }), schemaBundle), currentFilters);
                      return Curry._1(setNumEventsCreatedInSequence, (function (param) {
                                    return numEventsCreatedInSequence + 1 | 0;
                                  }));
                    }),
                  undefined,
                  actions
                ]);
    };
    if (eventNameComponents$1 !== undefined) {
      return EventNameComponentCollection.setEventNameComponent(Firebase.app(undefined).firestore(), schemaId, eventName$1, eventNameComponents$1, ValidationConfigV2.NameComponent.getNameConfigAsString(nameComponents)).then(function (res) {
                  if (res.TAG === /* Ok */0) {
                    return writeAction(undefined);
                  } else {
                    return Curry._1(onError, res._0);
                  }
                });
    } else {
      return Promise.resolve(writeAction(undefined));
    }
  };
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(activeEditableEventId, eventId) && editing) {
            Curry._1(setEditing, (function (param) {
                    return false;
                  }));
          }
          
        }), [
        activeEditableEventId,
        editing
      ]);
  return React.createElement("div", {
              className: root,
              onClick: (function (e) {
                  Curry._1(setEditing, (function (param) {
                          return true;
                        }));
                  Curry._1(setActiveEditableEventId, (function (param) {
                          return eventId;
                        }));
                  e.stopPropagation();
                  
                })
            }, React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: /* [] */0
                        }
                      })
                }, Belt_Array.mapWithIndexU(Belt_Array.keepU(columns, (function (param) {
                            return param.visible;
                          })), (function (index, param) {
                        var width = param.width;
                        var columnType = param.columnType;
                        if (index === 0) {
                          return React.createElement("div", {
                                      key: SheetTypes.columnTypeToJs(columnType),
                                      className: Curry._1(Css.merge, {
                                            hd: inputBox,
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.width(Css.px(width)),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, editing ? React.createElement(EventsSheet__EditableNameCell.make, {
                                            columnWidth: columnWidth,
                                            currentFilters: currentFilters,
                                            eventId: eventId,
                                            eventName: undefined,
                                            nameComponents: nameComponents,
                                            onExitEditing: (function (param) {
                                                Curry._1(setEditing, (function (param) {
                                                        return false;
                                                      }));
                                                return Curry._1(setActiveEditableEventId, (function (param) {
                                                              
                                                            }));
                                              }),
                                            onSave: onSave,
                                            schemaBundle: schemaBundle,
                                            structure: undefined
                                          }) : React.createElement($$Text.make, {
                                            size: "Medium",
                                            weight: "Medium",
                                            color: Styles.Color.light08,
                                            children: "Add new event"
                                          }));
                        } else {
                          return React.createElement("div", {
                                      key: SheetTypes.columnTypeToJs(columnType),
                                      className: Curry._1(Css.merge, {
                                            hd: empty,
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.width(Css.px(width)),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          })
                                    });
                        }
                      }))));
}

var make = EventSheet_NewEvent;

export {
  make ,
  
}
/* root Not a pure module */
