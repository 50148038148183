// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "./Modal.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import Shortid from "shortid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as TextInput from "./TextInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as IntegrationIcon from "./integration/IntegrationIcon.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function getIntegrationLabel(integrationType) {
  if (integrationType === "RudderstackTrackingPlans") {
    return "RudderStack Tracking Plans";
  } else if (integrationType === "Protocols") {
    return "Segment Protocols";
  } else if (integrationType === "SnowplowDataStructures") {
    return "Snowplow Iglu";
  } else if (integrationType === "Lexicon") {
    return "Mixpanel Lexicon";
  } else if (integrationType === "MParticleDataMaster") {
    return "mParticle Data Master";
  } else if (integrationType === "Taxonomy") {
    return "Amplitude Data";
  } else if (integrationType === "Webhook") {
    return "Webhook";
  } else {
    return "RudderStack Data Catalog";
  }
}

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("stretch"),
          tl: {
            hd: Css.paddingTop(Css.px(20)),
            tl: {
              hd: Css.paddingRight(Css.px(16)),
              tl: {
                hd: Css.paddingBottom(Css.px(20)),
                tl: {
                  hd: Css.paddingLeft(Css.px(16)),
                  tl: {
                    hd: Css.minWidth(Css.px(480)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.fontSize(Css.px(11)),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.lineHeight(Css.pct(140.0)),
            tl: {
              hd: Css.color(Styles.Color.light10),
              tl: {
                hd: Css.marginBottom(Css.px(4)),
                tl: {
                  hd: Css.marginLeft(Css.px(8)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var options = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.paddingBottom(Css.px(20)),
          tl: /* [] */0
        }
      }
    });

function integrationButton(selected) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.width(Css.px(88)),
                    tl: {
                      hd: Css.minHeight(Css.px(80)),
                      tl: {
                        hd: Css.borderWidth("zero"),
                        tl: {
                          hd: Css.backgroundColor(selected ? Styles.Color.white : "transparent"),
                          tl: {
                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(10), Css.px(20), undefined, undefined, selected ? Css.rgba(0, 0, 0, {
                                            NAME: "num",
                                            VAL: 0.05
                                          }) : "transparent")),
                            tl: {
                              hd: Css.marginTop(Css.px(8)),
                              tl: {
                                hd: Css.marginRight(Css.px(8)),
                                tl: {
                                  hd: Css.paddingTop(Css.px(16)),
                                  tl: {
                                    hd: Css.paddingRight(Css.px(8)),
                                    tl: {
                                      hd: Css.paddingBottom(Css.px(8)),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(8)),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(10)),
                                          tl: {
                                            hd: Css.cursor("pointer"),
                                            tl: {
                                              hd: Css.color(selected ? Styles.Color.light12 : Styles.Color.light10),
                                              tl: {
                                                hd: Css.transition({
                                                      NAME: "ms",
                                                      VAL: Styles.Duration.$$default
                                                    }, undefined, undefined, "all"),
                                                tl: {
                                                  hd: Css.focus({
                                                        hd: Css.outlineStyle("none"),
                                                        tl: {
                                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(0), Css.px(0), Css.px(2), true, Styles.Color.deepBlue)),
                                                          tl: /* [] */0
                                                        }
                                                      }),
                                                  tl: {
                                                    hd: Css.hover(selected ? ({
                                                              hd: Css.focus({
                                                                    hd: Css.boxShadows({
                                                                          hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(4), Css.px(16), undefined, undefined, {
                                                                                NAME: "rgba",
                                                                                VAL: [
                                                                                  0,
                                                                                  0,
                                                                                  0,
                                                                                  {
                                                                                    NAME: "num",
                                                                                    VAL: 0.05
                                                                                  }
                                                                                ]
                                                                              }),
                                                                          tl: {
                                                                            hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(0), Css.px(0), Css.px(2), true, Styles.Color.deepBlue),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }),
                                                                    tl: /* [] */0
                                                                  }),
                                                              tl: /* [] */0
                                                            }) : ({
                                                              hd: Css.color(Styles.Color.light12),
                                                              tl: /* [] */0
                                                            })),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var footer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.padding(Css.px(12)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function CreateIntegrationModal(Props) {
  var onClose = Props.onClose;
  var integrations = Props.integrations;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        return "";
      });
  var setName = match[1];
  var name = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setSelectedType = match$1[1];
  var selectedType = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setNameEdited = match$2[1];
  var nameEdited = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setLoading = match$3[1];
  var isLoading = match$3[0];
  var hasRudderStackDataCatalogPublishingFeatureFlag = AppFeatureFlag.useFeatureFlag("RudderStackDataCatalogPublishing");
  var handleCreate = function (param) {
    return Belt_Option.forEach(selectedType, (function (integrationType) {
                  Curry._1(setLoading, (function (param) {
                          return true;
                        }));
                  var integrationId = Shortid();
                  var context = {
                    integrationId: integrationId,
                    integrationQuery: integrationId
                  };
                  Curry.app(sendActions, [
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        (function (branch) {
                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                            AnalyticsRe.integrationCreated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, integrationType === "RudderstackTrackingPlans" ? "RudderstackTrackingPlans" : (
                                    integrationType === "Protocols" ? "SegmentProtocols" : (
                                        integrationType === "SnowplowDataStructures" ? "SnowplowDataStructures" : (
                                            integrationType === "Lexicon" ? "MixpanelLexicon" : (
                                                integrationType === "MParticleDataMaster" ? "MParticleDataMaster" : (
                                                    integrationType === "Taxonomy" ? "AmplitudeGovern" : (
                                                        integrationType === "Webhook" ? "Webhook" : "RudderstackDataCatalog"
                                                      )
                                                  )
                                              )
                                          )
                                      )
                                  ), [], name, integrationId, integrationType === "Webhook" ? "JsonSchema" : undefined, Belt_List.length(integrations) + 1 | 0, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                            return Router.Schema.pushDrawerItem(undefined, undefined, {
                                        NAME: "integration",
                                        VAL: [
                                          integrationId,
                                          undefined
                                        ]
                                      });
                          }),
                        undefined,
                        Belt_Array.concat([
                              [
                                {
                                  NAME: "CreateIntegration",
                                  VAL: integrationId
                                },
                                context
                              ],
                              [
                                {
                                  NAME: "UpdateIntegrationName",
                                  VAL: [
                                    integrationId,
                                    name
                                  ]
                                },
                                context
                              ],
                              [
                                {
                                  NAME: "UpdateIntegrationType",
                                  VAL: [
                                    integrationId,
                                    integrationType
                                  ]
                                },
                                context
                              ]
                            ], integrationType === "Protocols" ? [[
                                  {
                                    NAME: "UpdateIntegrationConfig",
                                    VAL: [
                                      integrationId,
                                      {
                                        TAG: /* Protocols */0,
                                        _0: {
                                          accessKey: undefined,
                                          trackingPlanId: undefined,
                                          workspace: undefined,
                                          usePublicApi: true,
                                          euDataResidency: false
                                        }
                                      }
                                    ]
                                  },
                                  context
                                ]] : [])
                      ]);
                  Curry._1(setLoading, (function (param) {
                          return false;
                        }));
                  return Curry._1(onClose, undefined);
                }));
  };
  return React.createElement(Modal.make, {
              title: "Add Integration",
              onClose: onClose,
              children: null
            }, React.createElement("div", {
                  className: content
                }, React.createElement("div", {
                      className: label
                    }, "Integration Type"), React.createElement("div", {
                      className: options
                    }, Belt_Array.mapU(Belt_Array.keepU(TrackingPlanModel.integrationTypes, (function (integrationType) {
                                if (integrationType !== "RudderstackDataCatalog") {
                                  return true;
                                } else {
                                  return hasRudderStackDataCatalogPublishingFeatureFlag;
                                }
                              })), (function (integrationType) {
                            return React.createElement("button", {
                                        key: getIntegrationLabel(integrationType),
                                        className: integrationButton(Caml_obj.caml_equal(selectedType, integrationType)),
                                        onClick: (function (param) {
                                            Curry._1(setSelectedType, (function (param) {
                                                    return integrationType;
                                                  }));
                                            if (!nameEdited) {
                                              return Curry._1(setName, (function (param) {
                                                            return getIntegrationLabel(integrationType);
                                                          }));
                                            }
                                            
                                          })
                                      }, React.createElement(IntegrationIcon.ByType.make, {
                                            integration: integrationType,
                                            size: 40
                                          }), React.createElement(Spacer.make, {
                                            height: 8
                                          }), React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            children: getIntegrationLabel(integrationType)
                                          }));
                          }))), React.createElement("label", {
                      className: label,
                      htmlFor: "name"
                    }, "Name"), React.createElement(TextInput.make, {
                      id: "name",
                      onChange: (function (value) {
                          Curry._1(setNameEdited, (function (param) {
                                  return value !== "";
                                }));
                          return Curry._1(setName, (function (param) {
                                        return value;
                                      }));
                        }),
                      value: name
                    })), React.createElement("footer", {
                  className: footer
                }, React.createElement(TextButton.make, {
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        }),
                      size: "Small",
                      disabled: isLoading,
                      children: "Cancel"
                    }), React.createElement(Spacer.make, {
                      width: 12
                    }), React.createElement(Button.make, {
                      disabled: !Belt_Option.isSome(selectedType) || name === "" || isLoading,
                      label: isLoading ? "Creating..." : "Create Integration",
                      onClick: handleCreate
                    })));
}

var make = CreateIntegrationModal;

export {
  getIntegrationLabel ,
  content ,
  label ,
  options ,
  integrationButton ,
  footer ,
  make ,
  
}
/* content Not a pure module */
