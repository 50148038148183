// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Monospace from "./Monospace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as ComputeUnionAllowedValuesUseCase from "../../model/src/eventSpecificPropertyValues/ComputeUnionAllowedValuesUseCase.mjs";
import * as GetEventSpecificPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetEventSpecificPropertyValuesUseCase.mjs";

var styles = Curry._1(Css.style, {
      hd: Css.textOverflow("ellipsis"),
      tl: {
        hd: Css.whiteSpace("nowrap"),
        tl: {
          hd: Css.overflow("hidden"),
          tl: {
            hd: Css.fontFamily(Styles.FontFamily.polar),
            tl: {
              hd: Css.flexShrink(1.0),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.regular),
                tl: {
                  hd: Css.fontSize(Css.px(11)),
                  tl: {
                    hd: Css.marginTop(Css.px(-3)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function getPropertyRegexOverrideForMaybeVariant(variant, propertyId) {
  var match = Belt_Option.flatMap(variant, (function (variant) {
          return Belt_MapString.get(variant.propertyOverrides, propertyId);
        }));
  if (match !== undefined && match) {
    return match._0.regexValidation;
  } else {
    return /* NoneOverride */0;
  }
}

function getValidations(model, propertyId, validations, $$event, maybeVariant) {
  return Belt_Array.keepMapU(Belt_List.toArray(validations), (function (validation) {
                var variant = validation.NAME;
                if (variant === "EndsWith" || variant === "Shape" || variant === "NestedProperty" || variant === "Contains" || variant === "StartsWith") {
                  return ;
                }
                if (variant === "Regex") {
                  var maybeVariantRegex = getPropertyRegexOverrideForMaybeVariant(maybeVariant, propertyId);
                  if (maybeVariantRegex) {
                    var regexValidationRule = maybeVariantRegex._0;
                    if (regexValidationRule !== undefined) {
                      return Caml_option.some(React.createElement("span", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.color(Styles.Color.primaryPurple),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.lightPurple),
                                              tl: {
                                                hd: Css.borderRadius(Css.px(2)),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, "regex (on this event variant): " + regexValidationRule.regex));
                    }
                    
                  }
                  return Belt_Option.mapU(PropertyUtils.mostSpecificPropertyRegex(model, $$event, validation.VAL), (function (mostSpecificPropertyRegex) {
                                return mostSpecificPropertyRegex;
                              }));
                }
                if (variant === "Max") {
                  var match = validation.VAL;
                  if (typeof match !== "object") {
                    return ;
                  }
                  var variant$1 = match.NAME;
                  if (variant$1 === "IntLit") {
                    return Caml_option.some("max " + match.VAL.toString());
                  } else if (variant$1 === "FloatLit") {
                    return Caml_option.some("max " + match.VAL.toString());
                  } else {
                    return ;
                  }
                }
                if (variant !== "Min") {
                  return ;
                }
                var match$1 = validation.VAL;
                if (typeof match$1 !== "object") {
                  return ;
                }
                var variant$2 = match$1.NAME;
                if (variant$2 === "IntLit") {
                  return Caml_option.some("min " + match$1.VAL.toString());
                } else if (variant$2 === "FloatLit") {
                  return Caml_option.some("min " + match$1.VAL.toString());
                } else {
                  return ;
                }
              }));
}

function getAllowedValues(model, $$event, property) {
  if (property.type_ !== "string") {
    return [];
  } else if ($$event !== undefined) {
    return Belt_Array.mapU(GetEventSpecificPropertyValuesUseCase.get(property.eventSpecificAllowedPropertyValues, $$event), (function (param) {
                  return param[0];
                }));
  } else {
    return ComputeUnionAllowedValuesUseCase.computeWithMappedModel(model, Curry._1(TrackingPlanMappedModel.Sources.keys, model.sources), property);
  }
}

function getPropertyValueOverridesForMaybeVariant(variant, propertyId) {
  return Belt_Option.flatMap(Belt_Option.flatMap(variant, (function (variant) {
                    return Belt_MapString.get(variant.propertyOverrides, propertyId);
                  })), (function (propertyOverride) {
                if (!propertyOverride) {
                  return ;
                }
                var valueOverrides = propertyOverride._0.allowedValues;
                if (valueOverrides) {
                  return Caml_option.some(valueOverrides._0);
                }
                
              }));
}

function diffValuesWithOverrides(values, overrides) {
  var existing = Belt_Array.map(values, (function (value) {
          var _override = Belt_MapString.get(overrides, value);
          if (_override !== undefined) {
            return [
                    value,
                    "Added"
                  ];
          } else {
            return [
                    value,
                    "Unchanged"
                  ];
          }
        }));
  var removals = Belt_Array.map(Belt_MapString.keysToArray(Belt_MapString.keep(overrides, (function (param, override) {
                  if (override) {
                    return false;
                  } else {
                    return true;
                  }
                }))), (function (value) {
          return [
                  value,
                  "Removed"
                ];
        }));
  return Belt_Array.concat(existing, removals).sort(function (a, b) {
              return a[0].localeCompare(b[0]) | 0;
            });
}

function getConstraints(nonMappedModel, model, $$event, property, maybeVariant) {
  var allowedValues = getAllowedValues(model, $$event, property);
  if (!Caml_obj.caml_notequal(allowedValues, [])) {
    return getValidations(nonMappedModel, property.id, property.validations, $$event, maybeVariant).sort();
  }
  var valueOverrides = getPropertyValueOverridesForMaybeVariant(maybeVariant, property.id);
  if (valueOverrides !== undefined) {
    return [React.createElement(React.Fragment, undefined, "Allowed values:", Belt_Array.mapWithIndex(diffValuesWithOverrides(allowedValues, Caml_option.valFromOption(valueOverrides)), (function (index, param) {
                        var state = param[1];
                        var value = param[0];
                        return React.createElement("span", {
                                    key: value
                                  }, index === 0 ? " " : ", ", React.createElement("span", {
                                        className: Curry._1(Css.style, state === "Unchanged" ? /* [] */0 : (
                                                state === "Added" ? ({
                                                      hd: Css.color(Styles.Color.primaryPurple),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.lightPurple),
                                                        tl: {
                                                          hd: Css.borderRadius(Css.px(2)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }) : ({
                                                      hd: Css.color(Styles.Color.primaryPurple),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.lightPurple),
                                                        tl: {
                                                          hd: Css.textDecoration("lineThrough"),
                                                          tl: {
                                                            hd: Css.borderRadius(Css.px(2)),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    })
                                              ))
                                      }, React.createElement(Monospace.make, {
                                            children: value
                                          })));
                      })))];
  } else {
    return ["Allowed values: " + allowedValues.join(", ")];
  }
}

function PropertyConstraints(Props) {
  var property = Props.property;
  var $$event = Props.event;
  var variant = Props.variant;
  var nonMappedModel = ModelStore.useModel(undefined);
  var model = ModelStore.Mapped.use(undefined);
  return Belt_Array.mapWithIndexU(getConstraints(nonMappedModel, model, $$event, property, variant), (function (index, constraint_) {
                return React.createElement("span", {
                            key: "match-" + index.toString(),
                            className: styles
                          }, index !== 0 ? ", " : null, constraint_);
              }));
}

var make = PropertyConstraints;

export {
  styles ,
  getPropertyValueOverridesForMaybeVariant ,
  diffValuesWithOverrides ,
  getConstraints ,
  make ,
  
}
/* styles Not a pure module */
