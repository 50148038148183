// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as UsageBasedPricingTiers from "../utils/UsageBasedPricingTiers.mjs";

function encode(t) {
  return Json_encode.object_({
              hd: [
                "ok",
                t.ok
              ],
              tl: {
                hd: [
                  "plans",
                  UsageBasedPricingTiers.Plans.encode(t.plans)
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          ok: Json_decode.field("ok", Json_decode.bool, json),
          plans: Json_decode.field("plans", UsageBasedPricingTiers.Plans.decode, json)
        };
}

var $$Response = {
  encode: encode,
  decode: decode
};

export {
  $$Response ,
  
}
/* No side effect */
