// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Sidebar from "./Sidebar.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function root(rowType) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.minWidth({
                                  NAME: "subtract",
                                  VAL: [
                                    Css.vw(100.0),
                                    Css.px(Sidebar.sidebarWidth + 32 | 0)
                                  ]
                                }),
                            tl: {
                              hd: Css.height(Css.pct(100.0)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.textAlign("left"),
                                  tl: {
                                    hd: Css.backgroundColor("transparent"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: rowType ? Curry._1(Css.style, {
                          hd: Css.cursor("default"),
                          tl: /* [] */0
                        }) : Curry._1(Css.style, {
                          hd: Css.hover({
                                hd: Css.selector(" > div", {
                                      hd: Css.backgroundColor(Styles.Color.light04),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: Css.selector(" > div", {
                                  hd: Css.backgroundColor(Styles.Color.white),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$default
                                        }, undefined, undefined, "background-color"),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                              tl: /* [] */0
                            }
                          }
                        }),
                  tl: /* [] */0
                }
              }
            });
}

var stickyCell = Curry._1(Css.style, {
      hd: Css.zIndex(1),
      tl: {
        hd: Css.position("sticky"),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.minHeight(Css.pct(100.0)),
              tl: {
                hd: Css.width(Css.px(800)),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.flexGrow(1.0),
                    tl: {
                      hd: Css.flexShrink(0.0),
                      tl: {
                        hd: Css.flexDirection("row"),
                        tl: {
                          hd: Css.justifyContent("flexStart"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.paddingLeft(Css.px(16)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function emptyCell(totalColumnWidth) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.width(Css.px(totalColumnWidth - 800 | 0)),
                tl: {
                  hd: Css.flexShrink(0.0),
                  tl: {
                    hd: Css.height(Css.pct(100)),
                    tl: {
                      hd: Css.borderTopRightRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.borderBottomRightRadius(Styles.Border.radius),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var Style = {
  root: root,
  stickyCell: stickyCell,
  emptyCell: emptyCell
};

function SheetInfoRow(Props) {
  var onClick = Props.onClick;
  var rowTypeOpt = Props.rowType;
  var totalColumnWidth = Props.totalColumnWidth;
  var children = Props.children;
  var rowType = rowTypeOpt !== undefined ? rowTypeOpt : /* RowButton */0;
  var tmp = {
    className: root(rowType)
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("button", tmp, React.createElement("div", {
                  className: stickyCell
                }, children), React.createElement("div", {
                  className: emptyCell(totalColumnWidth)
                }));
}

var stickyCellWidth = 800;

var make = SheetInfoRow;

export {
  stickyCellWidth ,
  Style ,
  make ,
  
}
/* stickyCell Not a pure module */
