// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Styles from "./styles.mjs";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as DocsLink from "./DocsLink.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DomainStore from "./DomainStore.mjs";
import * as DomainMembers from "../../model/src/domains/DomainMembers.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as BranchBar__Utils from "./BranchBar__Utils.mjs";
import * as CollaboratorsView from "./CollaboratorsView.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as BranchChangesContext from "./BranchChangesContext.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as DomainDetails__Components from "./domains/DomainDetails__Components.mjs";
import * as CreateEventForVariantUseCase from "../../model/src/variants/createEventForVariantUseCase.mjs";

function rootStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.padding(Css.px(16)),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.lastChild({
                        hd: Css.borderBottomWidth(Css.px(0)),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: disabled ? Css.pointerEvents("none") : Styles.emptyStyle,
                    tl: /* [] */0
                  }
                }
              }
            });
}

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.height(Css.px(28)),
            tl: /* [] */0
          }
        }
      }
    });

function domainLinkStyles(showBubble) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.color(Styles.Color.light11),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light12),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: showBubble ? Css.after({
                              hd: Css.display("block"),
                              tl: {
                                hd: Css.unsafe("content", "''"),
                                tl: {
                                  hd: Css.position("absolute"),
                                  tl: {
                                    hd: Css.right(Css.px(-10)),
                                    tl: {
                                      hd: Css.top(Css.px(4)),
                                      tl: {
                                        hd: Css.width(Css.px(6)),
                                        tl: {
                                          hd: Css.height(Css.px(6)),
                                          tl: {
                                            hd: Css.borderRadius(Css.px(3)),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.avoPink),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }) : Styles.emptyStyle,
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var eventButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.color(Styles.Color.light10),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$short
                        }, undefined, undefined, "color"),
                    tl: {
                      hd: Css.hover({
                            hd: Css.color(Styles.Color.light11),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.textAlign("left"),
                        tl: {
                          hd: Css.wordBreak("breakAll"),
                          tl: {
                            hd: Css.height(Css.px(24)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var showMoreButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.color(Styles.Color.darkBlue),
              tl: {
                hd: Css.hover({
                      hd: Css.textDecoration("underline"),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

function BranchBar__Dropdown$ImpactedDomain$DummyContent(Props) {
  var domain = Props.domain;
  var eventIds = Props.eventIds;
  var domainMembers = DomainStore.useDomainMembers(undefined, undefined);
  var domainMembersInitialized = DomainStore.useMembersInitialized(undefined);
  var match = Curry._1(DomainMembers.size, domainMembers);
  var tmp;
  if (domainMembersInitialized && match !== 0) {
    var membersToDisplay = Belt_Array.slice(Belt_Array.shuffle(Belt_SetString.toArray(Belt_SetString.fromArray(DomainMembers.getUserIds(domainMembers)))), 0, 5);
    tmp = React.createElement(CollaboratorsView.CollaboratorProfilePhotos.make, {
          ids: membersToDisplay,
          notDisplayedCount: membersToDisplay.length + 2 | 0
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: rootStyles(true)
            }, React.createElement("div", {
                  className: headerStyles
                }, React.createElement("span", {
                      className: domainLinkStyles(true)
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          weight: "Semi",
                          children: domain.name
                        })), tmp), React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light10,
                  children: AvoUtils.plural(undefined, undefined, eventIds.length, "event") + " impacted"
                }));
}

function BranchBar__Dropdown$ImpactedDomain(Props) {
  var dummyContentOpt = Props.dummyContent;
  var domain = Props.domain;
  var eventAndEventVariantIds = Props.eventAndEventVariantIds;
  var domainsCount = Props.domainsCount;
  var onClose = Props.onClose;
  var impactedStakeholdersCount = Props.impactedStakeholdersCount;
  var dummyContent = dummyContentOpt !== undefined ? dummyContentOpt : false;
  var viewerId = ViewerContext.use(undefined).id;
  var domainMembers = DomainStore.useDomainMembers(domain.id, undefined);
  var domainMembersInitialized = DomainStore.useMembersInitialized(undefined);
  var memberDomains = DomainStore.useMemberDomains(viewerId);
  var events = ModelStore.Mapped.useEvents(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setShowAllEvents = match[1];
  var showAllEvents = match[0];
  var impactedItems = Belt_SortArray.stableSortBy(Belt_Array.keepMap(eventAndEventVariantIds, (function (itemId) {
              if (!VariantEventIdUseCase.isVariantEventId(itemId)) {
                return Belt_Option.map(Curry._2(TrackingPlanMappedModel.Events.get, events, itemId), (function ($$event) {
                              return {
                                      NAME: "Event",
                                      VAL: $$event
                                    };
                            }));
              }
              var baseEventId = VariantEventIdUseCase.baseEventIdFromVariantEventId(itemId);
              var variantId = VariantEventIdUseCase.variantIdFromVariantEventId(itemId);
              return Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Events.get, events, baseEventId), (function ($$event) {
                            return Belt_Option.map(Belt_Array.getBy($$event.variants, (function (variant) {
                                              return variant.id === variantId;
                                            })), (function (variant) {
                                          return {
                                                  NAME: "EventVariant",
                                                  VAL: [
                                                    $$event,
                                                    variant
                                                  ]
                                                };
                                        }));
                          }));
            })), (function (a, b) {
          var getName = function (item) {
            if (item.NAME !== "EventVariant") {
              return item.VAL.name;
            }
            var match = item.VAL;
            return CreateEventForVariantUseCase.variantEventDisplayName(match[0], match[1].nameSuffix);
          };
          return $$String.compare(getName(a), getName(b));
        }));
  var itemsToShow = showAllEvents ? impactedItems : Belt_Array.slice(impactedItems, 0, 3);
  var isDomainMember = Curry._2(Domains.has, memberDomains, domain.id);
  if (itemsToShow.length === 0 && dummyContent) {
    return React.createElement(BranchBar__Dropdown$ImpactedDomain$DummyContent, {
                domain: domain,
                eventIds: eventAndEventVariantIds
              });
  }
  var match$1 = Curry._1(DomainMembers.size, domainMembers);
  var tmp;
  if (domainMembersInitialized && match$1 !== 0) {
    var membersToDisplay = DomainMembers.getUserIds(Curry._2(DomainMembers.take, domainMembers, 5));
    tmp = React.createElement(CollaboratorsView.CollaboratorProfilePhotos.make, {
          ids: membersToDisplay,
          notDisplayedCount: Curry._1(DomainMembers.size, domainMembers) - membersToDisplay.length | 0
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: rootStyles(false)
            }, React.createElement("div", {
                  className: headerStyles
                }, React.createElement(Link.make, {
                      path: Router.Link.getSchemaRouteLink(undefined, {
                            NAME: "domain",
                            VAL: domain.id
                          }),
                      className: domainLinkStyles(!isDomainMember),
                      onClick: (function (e) {
                          if (!e.metaKey && !e.ctrlKey) {
                            Curry._1(onClose, undefined);
                          }
                          return AnalyticsRe.branchBarInteractionStakeholderDomain(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, impactedStakeholdersCount, domain.name, isDomainMember ? "DomainTeamMember" : "Admin", domain.id, domainsCount, "ImpactedStakeholdersOpened", schemaBundle.schemaId, schemaBundle.branchId);
                        }),
                      children: React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            children: domain.name
                          })
                    }), tmp), dummyContent ? null : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        height: 4
                      }), React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Semi",
                        color: Styles.Color.light10,
                        children: "IMPACTED EVENTS"
                      })), React.createElement(Spacer.make, {
                  height: 4
                }), dummyContent ? React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light10,
                    children: AvoUtils.plural(undefined, undefined, itemsToShow.length, "event") + " impacted"
                  }) : Belt_Array.map(itemsToShow, (function (item) {
                      var tmp;
                      if (item.NAME === "EventVariant") {
                        var match = item.VAL;
                        tmp = VariantEventIdUseCase.variantEventId({
                              baseEventId: match[0].id,
                              variantId: match[1].id
                            });
                      } else {
                        tmp = item.VAL.id;
                      }
                      var tmp$1;
                      if (item.NAME === "EventVariant") {
                        var match$1 = item.VAL;
                        var name = CreateEventForVariantUseCase.variantEventDisplayName(match$1[0], match$1[1].nameSuffix);
                        tmp$1 = React.createElement($$Text.make, {
                              element: "Span",
                              size: "Small",
                              weight: "Semi",
                              lines: 1,
                              title: name,
                              children: name
                            });
                      } else {
                        var $$event = item.VAL;
                        tmp$1 = React.createElement($$Text.make, {
                              element: "Span",
                              size: "Small",
                              weight: "Semi",
                              lines: 1,
                              title: $$event.name,
                              children: $$event.name
                            });
                      }
                      return React.createElement("button", {
                                  key: tmp,
                                  className: eventButtonStyles,
                                  onClick: (function (e) {
                                      if (!e.metaKey && !e.ctrlKey) {
                                        Curry._1(onClose, undefined);
                                      }
                                      var tmp;
                                      if (item.NAME === "EventVariant") {
                                        var match = item.VAL;
                                        tmp = {
                                          NAME: "eventVariant",
                                          VAL: [
                                            {
                                              baseEventId: match[0].id,
                                              variantId: match[1].id
                                            },
                                            undefined
                                          ]
                                        };
                                      } else {
                                        tmp = {
                                          NAME: "event",
                                          VAL: [
                                            item.VAL.id,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        };
                                      }
                                      Router.Schema.pushDrawerItem(undefined, undefined, tmp);
                                      var tmp$1;
                                      if (item.NAME === "EventVariant") {
                                        var match$1 = item.VAL;
                                        tmp$1 = VariantEventIdUseCase.variantEventId({
                                              baseEventId: match$1[0].id,
                                              variantId: match$1[1].id
                                            });
                                      } else {
                                        tmp$1 = item.VAL.id;
                                      }
                                      var tmp$2;
                                      if (item.NAME === "EventVariant") {
                                        var match$2 = item.VAL;
                                        tmp$2 = CreateEventForVariantUseCase.variantEventDisplayName(match$2[0], match$2[1].nameSuffix);
                                      } else {
                                        tmp$2 = item.VAL.name;
                                      }
                                      return AnalyticsRe.branchBarInteractionImpactedEvent(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, impactedStakeholdersCount, tmp$1, tmp$2, domain.name, isDomainMember ? "DomainTeamMember" : "Admin", domain.id, domainsCount, "ImpactedStakeholdersOpened", schemaBundle.schemaId, schemaBundle.branchId);
                                    })
                                }, tmp$1, item.NAME === "EventVariant" ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                            width: 4
                                          }), React.createElement(DomainDetails__Components.VariantPill.make, {})) : null);
                    })), itemsToShow.length < impactedItems.length ? React.createElement("button", {
                    className: showMoreButtonStyles,
                    onClick: (function (param) {
                        return Curry._1(setShowAllEvents, (function (param) {
                                      return true;
                                    }));
                      })
                  }, React.createElement($$Text.make, {
                        element: "Span",
                        size: "Small",
                        children: "Show all"
                      })) : null, showAllEvents ? React.createElement("button", {
                    className: showMoreButtonStyles,
                    onClick: (function (param) {
                        return Curry._1(setShowAllEvents, (function (param) {
                                      return false;
                                    }));
                      })
                  }, React.createElement($$Text.make, {
                        element: "Span",
                        size: "Small",
                        children: "Show fewer"
                      })) : null);
}

var popup = Curry._1(Css.style, {
      hd: Css.boxShadow(Styles.Shadow.box),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.light05),
            tl: {
              hd: Css.paddingTop(Css.px(4)),
              tl: {
                hd: Css.paddingBottom(Css.px(4)),
                tl: {
                  hd: Css.width(Css.px(300)),
                  tl: {
                    hd: Css.maxHeight(Css.vh(90.0)),
                    tl: {
                      hd: Css.overflow("auto"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var impactedDomainsInfo = Curry._1(Css.style, {
      hd: Css.margin(Css.px(4)),
      tl: {
        hd: Css.marginTop(Css.px(0)),
        tl: {
          hd: Css.padding(Css.px(12)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light02),
            tl: {
              hd: Css.borderRadius(Css.px(3)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function BranchBar__Dropdown$DummyContent(Props) {
  var viewerId = ViewerContext.use(undefined).id;
  var impacted = [
    [
      {
        id: "1",
        name: "Checkout",
        description: "",
        items: {
          events: [
            "1",
            "2",
            "3"
          ],
          eventVariants: []
        },
        createdAt: new Date(),
        lastUpdatedAt: new Date(),
        lastUpdatedBy: viewerId,
        archived: false
      },
      [
        "1",
        "2",
        "3"
      ]
    ],
    [
      {
        id: "1",
        name: "Search",
        description: "",
        items: {
          events: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ],
          eventVariants: []
        },
        createdAt: new Date(),
        lastUpdatedAt: new Date(),
        lastUpdatedBy: viewerId,
        archived: false
      },
      [
        "1",
        "2",
        "3",
        "4",
        "5"
      ]
    ]
  ];
  return React.createElement("div", {
              className: popup
            }, React.createElement("div", {
                  className: impactedDomainsInfo
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.avoPink,
                      children: "You are not a member of " + AvoUtils.plural(undefined, undefined, impacted.length, "impacted domain")
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          children: "💡"
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light11,
                          children: "Using event variants and/or event specific property constraints might help to reduce impact on these domains."
                        }))), Belt_Array.map(impacted, (function (param) {
                    var domain = param[0];
                    return React.createElement(BranchBar__Dropdown$ImpactedDomain, {
                                dummyContent: true,
                                domain: domain,
                                eventAndEventVariantIds: param[1],
                                domainsCount: 0,
                                onClose: (function (param) {
                                    
                                  }),
                                impactedStakeholdersCount: 0,
                                key: domain.id
                              });
                  })));
}

function BranchBar__Dropdown(Props) {
  var dummyContentOpt = Props.dummyContent;
  var onClose = Props.onClose;
  var dummyContent = dummyContentOpt !== undefined ? dummyContentOpt : false;
  var globalSend = GlobalSendContext.use(undefined);
  var domains = DomainStore.useDomains(undefined);
  var viewerId = ViewerContext.use(undefined).id;
  var memberDomains = DomainStore.useMemberDomains(viewerId);
  var branchChanges = BranchChangesContext.use(undefined);
  var impacted = React.useMemo((function () {
          return BranchBar__Utils.getImpactedEventAndVariantIdsByDomain(BranchBar__Utils.getImpactedEventAndVariantIds(branchChanges.changes), domains);
        }), [branchChanges.changes]);
  var impacted$1 = React.useMemo((function () {
          if (dummyContent) {
            return Belt_Array.slice(Belt_Array.shuffle(impacted), 0, 3);
          } else {
            return impacted;
          }
        }), [impacted]);
  var numDomainsImpactedWhichUserIsntMemberOf = Belt_Array.keepMap(impacted$1, (function (param) {
          var domain = param[0];
          if (!Curry._2(Domains.has, memberDomains, domain.id)) {
            return domain;
          }
          
        })).length;
  if (impacted$1.length === 0 && dummyContent) {
    return React.createElement(BranchBar__Dropdown$DummyContent, {});
  }
  var match = impacted$1.length;
  var match$1 = Curry._1(Domains.size, domains);
  return React.createElement("div", {
              className: popup
            }, numDomainsImpactedWhichUserIsntMemberOf > 0 ? React.createElement("div", {
                    className: impactedDomainsInfo
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.avoPink,
                        children: "You are not a member of " + AvoUtils.plural(undefined, undefined, numDomainsImpactedWhichUserIsntMemberOf, "impacted domain")
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.gap(Css.px(4)),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            children: "💡"
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light11,
                            children: "Using event variants and/or event specific property constraints might help to reduce impact on these domains."
                          }))) : null, match$1 !== 0 ? (
                match !== 0 ? null : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding(Css.px(16)),
                              tl: {
                                hd: Styles.prettyWrap,
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light11,
                            children: "No changes have been made that will impact stakeholders"
                          }))
              ) : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding(Css.px(16)),
                          tl: {
                            hd: Styles.prettyWrap,
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        color: Styles.Color.light10,
                        children: "Domains are used to connect events to their stakeholders"
                      }), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light11,
                        children: "There are no domains defined in your workspace yet. Start defining domains to gain visibility into the impact of your tracking plan changes as you make them."
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("spaceBetween"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement(DocsLink.make, {
                            path: "data-design/guides/managing-changes-impacting-multiple-stakeholders",
                            label: "Learn more",
                            display: "inlineFlex"
                          }), React.createElement(Button.make, {
                            label: "Create domain",
                            onClick: (function (param) {
                                Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "Settings",
                                        VAL: /* Domains */4
                                      }
                                    });
                                return Curry._1(onClose, undefined);
                              })
                          }))), Belt_Array.map(impacted$1, (function (param) {
                    var domain = param[0];
                    return React.createElement(BranchBar__Dropdown$ImpactedDomain, {
                                dummyContent: dummyContent,
                                domain: domain,
                                eventAndEventVariantIds: param[1],
                                domainsCount: Curry._1(Domains.size, domains),
                                onClose: onClose,
                                impactedStakeholdersCount: impacted$1.length,
                                key: domain.id
                              });
                  })));
}

var make = BranchBar__Dropdown;

export {
  make ,
  
}
/* headerStyles Not a pure module */
