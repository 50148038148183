// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "../Link.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Styles from "../styles.mjs";
import * as CopyButton from "../CopyButton.mjs";
import * as InspectorViewInstallDocsJsFamilyInitCodeSnippet from "./InspectorViewInstallDocsJsFamilyInitCodeSnippet.mjs";
import * as InspectorViewInstallDocsJsFamilyTrackEventCodeSnippet from "./InspectorViewInstallDocsJsFamilyTrackEventCodeSnippet.mjs";

var docsContentStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(24), Css.px(32)),
      tl: /* [] */0
    });

var docsDescriptionStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(24)),
      tl: {
        hd: Css.marginBottom(Css.px(0)),
        tl: {
          hd: Css.display("block"),
          tl: {
            hd: Css.color(Styles.Color.light12),
            tl: {
              hd: Css.fontSize(Styles.FontSize.medium),
              tl: {
                hd: Css.lineHeight(Css.pct(140.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var docsCodeBoxStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.fontWeight("normal"),
        tl: /* [] */0
      }
    });

var docsCodeStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.marginTop(Css.px(12)),
          tl: {
            hd: Css.display("block"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light11),
              tl: {
                hd: Css.paddingTop(Css.px(16)),
                tl: {
                  hd: Css.paddingRight(Css.px(70)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(16)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(24)),
                      tl: {
                        hd: Css.fontFamily(Styles.FontFamily.polar),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.small),
                          tl: {
                            hd: Css.color(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Css.px(3)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var docsCodeLightBlueStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.deepBlue),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: /* [] */0
      }
    });

var docsCodeMintGreenStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.green),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: /* [] */0
      }
    });

var docsCodeOrangeStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.orange),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: /* [] */0
      }
    });

var copyButtonBoxStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(10)),
        tl: {
          hd: Css.top(Css.px(10)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var docsLinkStyles = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.marginTop(Css.px(32)),
        tl: {
          hd: Css.color(Styles.Color.darkBlue),
          tl: {
            hd: Css.textDecoration("none"),
            tl: {
              hd: Css.fontWeight(Styles.FontWeight.semi),
              tl: {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: 200.0
                    }, undefined, undefined, "color"),
                tl: {
                  hd: Css.hover({
                        hd: Css.color(Styles.Color.deepBlue),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function InspectorViewInstallDocsJsFamily(Props) {
  var apiKey = Props.apiKey;
  var language = Props.language;
  var platform = Props.platform;
  var onClose = Props.onClose;
  return React.createElement("div", {
              className: docsContentStyles
            }, React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement("strong", undefined, "1. "), "Avo Inspector SDK is available through ", React.createElement("strong", undefined, "npm"), ". To install it run:"), React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, "yarn add avo-inspector"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "yarn add avo-inspector"
                        }))), React.createElement("p", {
                  className: docsDescriptionStyles
                }, "in your project directory."), React.createElement("p", {
                  className: docsDescriptionStyles
                }, platform === "ReactNative" ? React.createElement("a", {
                        href: "https://www.npmjs.com/package/react-native-avo-inspector",
                        rel: "noopener noreferrer",
                        target: "_blank"
                      }, React.createElement("img", {
                            alt: "npm package page",
                            src: "https://img.shields.io/npm/v/react-native-avo-inspector"
                          })) : (
                    platform === "Web" ? React.createElement("a", {
                            href: "https://www.npmjs.com/package/avo-inspector",
                            rel: "noopener noreferrer",
                            target: "_blank"
                          }, React.createElement("img", {
                                alt: "npm package page",
                                src: "https://img.shields.io/npm/v/avo-inspector"
                              })) : null
                  )), React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement("strong", undefined, "2. "), "Initialize with your Api Key "), InspectorViewInstallDocsJsFamilyInitCodeSnippet.code("avo-inspector", docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, docsCodeOrangeStyles, copyButtonBoxStyles, apiKey, language), React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement("strong", undefined, "3. "), "Send event schemas to Avo"), InspectorViewInstallDocsJsFamilyTrackEventCodeSnippet.code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, docsCodeOrangeStyles, copyButtonBoxStyles, language), React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement("strong", undefined, "4. "), "View your first events on the ", React.createElement(Link.make, {
                      path: Router.Link.getSchemaRouteLink(undefined, {
                            NAME: "inspector",
                            VAL: "events"
                          }),
                      className: docsLinkStyles,
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        }),
                      children: "events page"
                    })), React.createElement("a", {
                  className: docsLinkStyles,
                  href: "https://github.com/avohq/js-avo-inspector",
                  target: "_blank"
                }, "View Complete Quick Start Guide"), React.createElement("div", undefined, React.createElement("a", {
                      className: docsLinkStyles,
                      href: "https://www.avo.app/docs/implementation/inspector/sdk/web",
                      target: "_blank"
                    }, "View Full Documentation")));
}

var make = InspectorViewInstallDocsJsFamily;

export {
  docsContentStyles ,
  docsDescriptionStyles ,
  docsCodeBoxStyles ,
  docsCodeStyles ,
  docsCodeLightBlueStyles ,
  docsCodeMintGreenStyles ,
  docsCodeOrangeStyles ,
  copyButtonBoxStyles ,
  docsLinkStyles ,
  make ,
  
}
/* docsContentStyles Not a pure module */
