// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as DateFns from "../DateFns.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_types from "rescript/lib/es6/js_types.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as React$1 from "@dopt/react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";

var date = new Date();

var doptExpires = new Date(2024, 7, 15);

var hasExpired = DateFns.isAfter(doptExpires, date);

var Provider = {};

function Dopt$CustomProvider(Props) {
  var apiKey = Props.apiKey;
  var flowVersions = Props.flowVersions;
  var userId = Props.userId;
  var groupId = Props.groupId;
  var children = Props.children;
  var match = Router.QueryParams.useStringKey("dopt");
  var value = match.value;
  var customFlows = React.useMemo((function () {
          return Belt_Option.mapWithDefault(value, {}, (function (value) {
                        return Js_dict.fromArray(Belt_Array.keepMap(value.split(","), (function (f) {
                                          var match = f.split("__");
                                          if (match.length !== 2) {
                                            return ;
                                          }
                                          var flowId = match[0];
                                          var version = match[1];
                                          return Belt_Option.map(Belt_Int.fromString(version), (function (version) {
                                                        return [
                                                                flowId,
                                                                version
                                                              ];
                                                      }));
                                        })));
                      }));
        }), []);
  var flowVersions$1 = React.useMemo((function () {
          return Object.assign(flowVersions, customFlows);
        }), [customFlows]);
  if (hasExpired) {
    return children;
  }
  var tmp = {
    userId: userId,
    apiKey: apiKey,
    flowVersions: flowVersions$1,
    children: children
  };
  if (groupId !== undefined) {
    tmp.groupId = groupId;
  }
  return React.createElement(React$1.DoptProvider, tmp);
}

var CustomProvider = {
  make: Dopt$CustomProvider
};

function fromField(str) {
  return str;
}

var RichText = {
  fromField: fromField
};

function classifyField(field) {
  if (field == null) {
    return "null";
  }
  var _rt = Js_types.classify(field);
  if (typeof _rt === "number") {
    switch (_rt) {
      case /* JSFalse */0 :
          return {
                  NAME: "boolean",
                  VAL: false
                };
      case /* JSTrue */1 :
          return {
                  NAME: "boolean",
                  VAL: true
                };
      case /* JSNull */2 :
          return "null";
      default:
        return "null";
    }
  } else {
    switch (_rt.TAG | 0) {
      case /* JSNumber */0 :
          return {
                  NAME: "number",
                  VAL: _rt._0
                };
      case /* JSString */1 :
          return {
                  NAME: "string",
                  VAL: _rt._0
                };
      case /* JSObject */3 :
          return {
                  NAME: "richText",
                  VAL: field
                };
      default:
        return "null";
    }
  }
}

var Field = {
  classifyField: classifyField
};

function field(t, str) {
  return Belt_Option.map(Caml_option.nullable_to_opt(t.field(str)), classifyField);
}

var emptyBlock_state = {
  active: false,
  entered: false,
  exited: false
};

var emptyBlock_transitioned = {};

var emptyBlock = {
  type: "custom",
  kind: "block",
  uid: "",
  sid: "",
  version: 0,
  state: emptyBlock_state,
  transitioned: emptyBlock_transitioned,
  containerUid: undefined
};

function use(sid) {
  if (hasExpired) {
    return [
            emptyBlock,
            (function (_transition) {
                
              })
          ];
  } else {
    return React$1.useBlock(sid);
  }
}

function getBlockId(t) {
  return Belt_Option.getExn(Belt_Array.get(t.sid.split("."), 1));
}

var Block = {
  field: field,
  emptyBlock: emptyBlock,
  use: use,
  getBlockId: getBlockId
};

var empty_state = {
  started: false,
  stopped: false,
  finished: false
};

var empty_blocks = [];

var empty = {
  type: "flow",
  kind: "flow",
  uid: "",
  sid: "",
  version: -1,
  state: empty_state,
  blocks: empty_blocks
};

var noIntent = {
  start: (function (param) {
      
    }),
  stop: (function (param) {
      
    }),
  finish: (function (param) {
      
    }),
  reset: (function (param) {
      
    })
};

function start(forceOpt, intent) {
  var force = forceOpt !== undefined ? forceOpt : false;
  if (hasExpired) {
    return ;
  } else {
    intent.start({
          force: force
        });
    return ;
  }
}

function reset(forceOpt, intent) {
  var force = forceOpt !== undefined ? forceOpt : false;
  if (hasExpired) {
    return ;
  } else {
    intent.reset({
          force: force
        });
    return ;
  }
}

function finish(intent) {
  if (hasExpired) {
    return ;
  } else {
    intent.finish();
    return ;
  }
}

function stop(intent) {
  if (hasExpired) {
    return ;
  } else {
    intent.stop();
    return ;
  }
}

function use$1(sid) {
  if (hasExpired) {
    return [
            empty,
            noIntent
          ];
  } else {
    return React$1.useFlow(sid);
  }
}

function isValidQuery(flow) {
  return flow.version !== -1;
}

function withValidQuery(flow, fn) {
  if (flow.version !== -1) {
    return Caml_option.some(Curry._1(fn, flow));
  }
  
}

function active(flow) {
  if (hasExpired) {
    return ;
  } else {
    return withValidQuery(flow, (function (flow) {
                  return Belt_Array.someU(flow.blocks, (function (param) {
                                var match = param.state;
                                if (match.active) {
                                  return match.entered;
                                } else {
                                  return false;
                                }
                              }));
                }));
  }
}

function started(flow) {
  if (hasExpired) {
    return ;
  } else {
    return withValidQuery(flow, (function (flow) {
                  return flow.state.started;
                }));
  }
}

function stopped(flow) {
  if (hasExpired) {
    return ;
  } else {
    return withValidQuery(flow, (function (flow) {
                  if (flow.state.started) {
                    return flow.state.stopped;
                  } else {
                    return false;
                  }
                }));
  }
}

function finished(flow) {
  if (hasExpired) {
    return ;
  } else {
    return withValidQuery(flow, (function (flow) {
                  return flow.state.finished;
                }));
  }
}

function getStepNumber(stepNumberId) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.null_to_opt(stepNumberId.match(/\d+/)), (function (arr) {
                    return Belt_Array.get(arr, 0);
                  })), Belt_Int.fromString);
}

function getStepChar(stepNumberId) {
  return Belt_Option.flatMap(Caml_option.null_to_opt(stepNumberId.match(/[a-z]$/)), (function (arr) {
                return Belt_Array.get(arr, 0);
              }));
}

function isRootStep(stepId) {
  return /^(pre-)?step-\d+$/.test(stepId);
}

function compareSteps(a, b) {
  var a$1 = Belt_Option.getWithDefault(Belt_Array.get(a.sid.split("."), 1), "");
  var b$1 = Belt_Option.getWithDefault(Belt_Array.get(b.sid.split("."), 1), "");
  var _a = a$1;
  var _b = b$1;
  while(true) {
    var b$2 = _b;
    var a$2 = _a;
    if (a$2.startsWith("pre") && b$2.startsWith("pre") || a$2.startsWith("step-") && b$2.startsWith("step-")) {
      var aIdArr = a$2.split("step-");
      var aId = Belt_Option.getWithDefault(Belt_Array.get(aIdArr, aIdArr.length - 1 | 0), "0");
      var bIdArr = b$2.split("step-");
      var bId = Belt_Option.getWithDefault(Belt_Array.get(bIdArr, bIdArr.length - 1 | 0), "0");
      _b = bId;
      _a = aId;
      continue ;
    }
    if (a$2.startsWith("pre") && b$2.startsWith("step-")) {
      return -1;
    }
    if (a$2.startsWith("step-") && b$2.startsWith("pre")) {
      return 1;
    }
    if (a$2 === b$2) {
      return 0;
    }
    switch (a$2) {
      case "complete" :
      case "done" :
      case "finish" :
          return 1;
      default:
        switch (b$2) {
          case "complete" :
          case "done" :
          case "finish" :
              return -1;
          default:
            var match = getStepNumber(a$2);
            var match$1 = getStepNumber(b$2);
            if (match !== undefined) {
              if (match$1 !== undefined) {
                if (match === match$1) {
                  return Caml_obj.caml_compare(getStepChar(a$2), getStepChar(b$2));
                } else {
                  return Caml.caml_int_compare(match, match$1);
                }
              } else {
                return -1;
              }
            } else if (match$1 !== undefined) {
              return 1;
            } else {
              return Caml.caml_string_compare(a$2, b$2);
            }
        }
    }
  };
}

function getOrderedBlocks(flow) {
  var match = Belt_Array.partition(flow.blocks, (function (param) {
          return param.state.active;
        }));
  var match$1 = Belt_Array.partition(flow.blocks, (function (param) {
          var sid = param.sid;
          if (sid.endsWith("complete") || sid.endsWith("finish")) {
            return true;
          } else {
            return sid.endsWith("done");
          }
        }));
  var match$2 = Belt_Array.partition(match$1[1], (function (param) {
          var stepId = Belt_Option.getWithDefault(Belt_Array.get(param.sid.split("."), 1), "");
          if (stepId.startsWith("pre")) {
            return true;
          } else {
            return stepId.startsWith("step-");
          }
        }));
  var sortedSteps = Belt_SortArray.stableSortBy(match$2[0], compareSteps);
  return {
          steps: sortedSteps,
          active: match[0],
          inactive: match[1],
          numSteps: Belt_Array.keep(sortedSteps, (function (block) {
                  return isRootStep(getBlockId(block));
                })).length,
          others: match$2[1],
          completed: match$1[0]
        };
}

var Flow = {
  empty: empty,
  noIntent: noIntent,
  start: start,
  reset: reset,
  finish: finish,
  stop: stop,
  use: use$1,
  isValidQuery: isValidQuery,
  withValidQuery: withValidQuery,
  active: active,
  started: started,
  stopped: stopped,
  finished: finished,
  getStepNumber: getStepNumber,
  getStepChar: getStepChar,
  isRootStep: isRootStep,
  compareSteps: compareSteps,
  getOrderedBlocks: getOrderedBlocks
};

function useReady(sid) {
  if (hasExpired) {
    return false;
  }
  var match = React$1.useFlowStatus(sid);
  if (match.pending) {
    return false;
  } else {
    return !match.failed;
  }
}

var FlowStatus = {
  useReady: useReady
};

function getBlocksApiKey(env) {
  if (env === "development") {
    return "blocks-17e5aae0a713e50677e4759ba6b29ebd8922b4dac20293adef263f9f4fe81e22_MjQz";
  } else {
    return "blocks-eccc77a321f041097414d34fb0c361f2e5362b3a19bbaa68c4e7da9aa28fee10_MjYz";
  }
}

var flowVersions = {
  "test-flow": 0,
  "demo-branch-product-tour": 6,
  "demo-branch-warning": 3,
  "inspector-issues-onboarding-flow-v2": 8,
  "how-to-save-a-view-and-get-alerts": 1,
  "ignore-on-branch-merge": 1,
  "actionable-issue-details": 2,
  "set-up-alerts": 4
};

export {
  hasExpired ,
  Provider ,
  CustomProvider ,
  RichText ,
  Field ,
  Block ,
  Flow ,
  FlowStatus ,
  getBlocksApiKey ,
  flowVersions ,
  
}
/* date Not a pure module */
