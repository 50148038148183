// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DiffComponents from "./DiffComponents.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";

function DiffGroupType(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "GroupType") {
    return null;
  }
  var groupTypeId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "CreateGroupType";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "GroupType";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var groupType = Belt_Option.getWithDefault(Belt_Array.getBy(masterModel.groupTypes, (function (groupType) {
              return groupType.id === groupTypeId;
            })), TrackingPlanModel.emptyGroupType(groupTypeId));
  var nextGroupType = Belt_Option.getWithDefault(Belt_Array.getBy(branchModel.groupTypes, (function (groupType) {
              return groupType.id === groupTypeId;
            })), TrackingPlanModel.emptyGroupType(groupTypeId));
  var maybeAction = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "UpdateGoalDescription";
          } else {
            return false;
          }
        }));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.alignSelf("flexStart"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.maxWidth(DiffComponents.maxWidthPx),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.position("sticky"),
                                tl: {
                                  hd: Css.top(Css.px(20)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.padding(Css.px(15)),
                                  tl: changeType === "Create" ? ({
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.darkGreen),
                                        tl: /* [] */0
                                      }) : (
                                      changeType === "Update" ? ({
                                            hd: Css.border(Css.px(1), "solid", "transparent"),
                                            tl: /* [] */0
                                          }) : ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.darkRed),
                                            tl: /* [] */0
                                          })
                                    )
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: /* [] */0
                              })
                        }, JsDiff.renderStringDiff(undefined, undefined, undefined, groupType.name, nextGroupType.name)), maybeAction !== undefined ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: /* [] */0
                                  }
                                })
                          }, JsDiff.renderStringDiff(undefined, undefined, undefined, groupType.description, nextGroupType.description)) : null)));
}

var make = DiffGroupType;

export {
  make ,
  
}
/* Css Not a pure module */
