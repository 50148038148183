// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Models from "./Models.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BranchStatus from "./externals/BranchStatus.mjs";
import * as Collaborators from "./Collaborators.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as DiscrepancyUtils from "./DiscrepancyUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as CollaboratorsView from "./CollaboratorsView.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as BranchDiscrepancies from "./BranchDiscrepancies.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as BranchActionBox__Utils from "./BranchActionBox__Utils.mjs";
import * as BranchActionBox__Components from "./BranchActionBox__Components.mjs";

function BranchActionBar$OpenState(Props) {
  var approvalStateAvailable = Props.approvalStateAvailable;
  var blockBranchWithEnforcement = Props.blockBranchWithEnforcement;
  var branchCreatorId = Props.branchCreatorId;
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var collaborators = Props.collaborators;
  var isBranchCreator = Props.isBranchCreator;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var match$1 = ViewerContext.use(undefined);
  var viewerEmail = match$1.email;
  var viewerId = match$1.id;
  var member = WorkspaceContext.useMemberWithUserId(viewerId);
  var viewerRole = React.useMemo((function () {
          if (member !== undefined) {
            return Models.Role.tFromJs(Caml_option.valFromOption(member).role);
          }
          
        }), [member]);
  var numApprovals;
  numApprovals = typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6 ? 0 : Belt_List.length(branchStatus._0);
  if (isBranchCreator) {
    return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "Done making changes?"
                  }), approvalStateAvailable ? React.createElement(Button.make, {
                      disabled: !isBranchCreator && role !== "Admin" || Caml_obj.caml_equal(blockBranchWithEnforcement, "beforeRequestForReview") || !RoleUtils.canEdit(role),
                      label: "Ready for review",
                      loading: match[0],
                      onClick: (function (param) {
                          return BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "StickyBranchHeader", branchName, collaborators, "ReadyForReview", numApprovals, schemaBundle, sendActions, setLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                        }),
                      size: "small",
                      singleLine: true
                    }) : null);
  } else {
    return React.createElement($$Text.make, {
                size: "Medium",
                weight: "Semi",
                color: Styles.Color.light11,
                children: "This branch is still a work in progress"
              });
  }
}

var OpenState = {
  make: BranchActionBar$OpenState
};

function BranchActionBar$ReadyForReviewState(Props) {
  var branchCreatorId = Props.branchCreatorId;
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var collaborators = Props.collaborators;
  var isBranchCreator = Props.isBranchCreator;
  var requestedReviewerIds = Props.requestedReviewerIds;
  var requireAdminApproval = Props.requireAdminApproval;
  var role = Props.role;
  var viewerIsReviewer = Props.viewerIsReviewer;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var match = ViewerContext.use(undefined);
  var viewerEmail = match.email;
  var viewerId = match.id;
  var member = WorkspaceContext.useMemberWithUserId(viewerId);
  var viewerRole = React.useMemo((function () {
          if (member !== undefined) {
            return Models.Role.tFromJs(Caml_option.valFromOption(member).role);
          }
          
        }), [member]);
  var match$1 = React.useState(function () {
        return false;
      });
  var setApproveLoading = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setRequestChangesLoading = match$2[1];
  var numApprovals;
  numApprovals = typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6 ? 0 : Belt_List.length(branchStatus._0);
  if (isBranchCreator) {
    return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "Get feedback before merging this branch"
                  }), React.createElement(BranchActionBox__Components.EditReviewers.make, {
                    type_: "button",
                    branchId: branchId,
                    branchCreatorId: branchCreatorId,
                    branchInteractionLocation: "StickyBranchHeader",
                    branchStatus: BranchStatus.toAnalytics(branchStatus),
                    collaborators: collaborators,
                    numApprovals: numApprovals,
                    requireAdminApproval: requireAdminApproval,
                    requestedReviewerIds: requestedReviewerIds,
                    role: role
                  }), Caml_obj.caml_equal(requestedReviewerIds, []) ? null : React.createElement(CollaboratorsView.CollaboratorProfilePhotos.make, {
                      ids: requestedReviewerIds,
                      notDisplayedCount: 0
                    }));
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: viewerIsReviewer ? "You are assigned to review this branch" : "Review and approve branch to enable merging"
                  }), React.createElement(Button.make, {
                    disabled: requireAdminApproval && role !== "Admin",
                    label: "Approve",
                    loading: match$1[0],
                    onClick: (function (param) {
                        return BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "StickyBranchHeader", branchName, collaborators, "Approved", numApprovals + 1 | 0, schemaBundle, sendActions, setApproveLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                      }),
                    size: "small",
                    singleLine: true
                  }), React.createElement(Button.make, {
                    disabled: requireAdminApproval && role !== "Admin",
                    label: "Request changes",
                    loading: match$2[0],
                    onClick: (function (param) {
                        return BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "StickyBranchHeader", branchName, collaborators, "ChangesRequested", numApprovals, schemaBundle, sendActions, setRequestChangesLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                      }),
                    size: "small",
                    style: "outline",
                    singleLine: true
                  }));
  }
}

var ReadyForReviewState = {
  make: BranchActionBar$ReadyForReviewState
};

function BranchActionBar$ChangesRequestedState(Props) {
  var blockBranchWithEnforcement = Props.blockBranchWithEnforcement;
  var branchCreatorId = Props.branchCreatorId;
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var changeAction = Props.changeAction;
  var collaborators = Props.collaborators;
  var isBranchCreator = Props.isBranchCreator;
  var requireAdminApproval = Props.requireAdminApproval;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var match = ViewerContext.use(undefined);
  var viewerEmail = match.email;
  var viewerId = match.id;
  var member = WorkspaceContext.useMemberWithUserId(viewerId);
  var viewerRole = React.useMemo((function () {
          if (member !== undefined) {
            return Models.Role.tFromJs(Caml_option.valFromOption(member).role);
          }
          
        }), [member]);
  var userRequestedChanges = changeAction.createdBy === viewerId;
  var numApprovals;
  numApprovals = typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6 ? 0 : Belt_List.length(branchStatus._0);
  var match$1 = React.useState(function () {
        return false;
      });
  var setReadyForReviewLoading = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setApproveLoading = match$2[1];
  if (isBranchCreator) {
    return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "Done making requested changes?"
                  }), React.createElement(Button.make, {
                    disabled: role !== "Admin" || Caml_obj.caml_equal(blockBranchWithEnforcement, "beforeRequestForReview") || !RoleUtils.canEdit(role),
                    label: "Ready for review",
                    loading: match$1[0],
                    onClick: (function (param) {
                        return BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "StickyBranchHeader", branchName, collaborators, "ReadyForReview", numApprovals, schemaBundle, sendActions, setReadyForReviewLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                      }),
                    size: "small",
                    singleLine: true
                  }));
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: userRequestedChanges ? "You requested changes on this branch" : "Changes were requested on this branch"
                  }), React.createElement(Button.make, {
                    disabled: requireAdminApproval && role !== "Admin",
                    label: "Approve",
                    loading: match$2[0],
                    onClick: (function (param) {
                        return BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "StickyBranchHeader", branchName, collaborators, "Approved", numApprovals + 1 | 0, schemaBundle, sendActions, setApproveLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                      }),
                    size: "small",
                    style: "outline",
                    singleLine: true
                  }));
  }
}

var ChangesRequestedState = {
  make: BranchActionBar$ChangesRequestedState
};

function BranchActionBar$ApprovedState(Props) {
  var approveActions = Props.approveActions;
  var branchCreatorId = Props.branchCreatorId;
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var collaborators = Props.collaborators;
  var isBranchCreator = Props.isBranchCreator;
  var members = Props.members;
  var numRequiredApprovals = Props.numRequiredApprovals;
  var requestedReviewerIds = Props.requestedReviewerIds;
  var requireAdminApproval = Props.requireAdminApproval;
  var role = Props.role;
  var viewerIsReviewer = Props.viewerIsReviewer;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var match = ViewerContext.use(undefined);
  var viewerEmail = match.email;
  var viewerId = match.id;
  var member = WorkspaceContext.useMemberWithUserId(viewerId);
  var viewerRole = React.useMemo((function () {
          if (member !== undefined) {
            return Models.Role.tFromJs(Caml_option.valFromOption(member).role);
          }
          
        }), [member]);
  var userApprovedBranch = Belt_List.some(approveActions, (function (a) {
          return a.createdBy === viewerId;
        }));
  var numApprovals = Belt_List.length(approveActions);
  var isFullyApproved;
  if (typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6) {
    isFullyApproved = false;
  } else {
    var approveActions$1 = branchStatus._0;
    if (requireAdminApproval) {
      var adminApprovals = Belt_List.keep(approveActions$1, (function (action) {
              return Belt_Option.getWithDefault(Belt_Option.mapU(Belt_List.getByU(members, (function (member) {
                                    return member.id === action.createdBy;
                                  })), (function (member) {
                                return Caml_obj.caml_equal(Models.Role.tFromJs(member.role), "Admin");
                              })), false);
            }));
      isFullyApproved = Belt_List.size(adminApprovals) >= numRequiredApprovals;
    } else {
      isFullyApproved = Belt_List.size(approveActions$1) >= numRequiredApprovals;
    }
  }
  var userIdsApprovedBranch = Belt_List.toArray(Belt_List.map(approveActions, (function (a) {
              return a.createdBy;
            })));
  var match$1 = React.useState(function () {
        return false;
      });
  var setRequestChangesLoading = match$1[1];
  var requestChangesLoading = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setApproveLoading = match$2[1];
  var approveLoading = match$2[0];
  if (userApprovedBranch) {
    return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "Changes approved by you"
                  }), Caml_obj.caml_equal(userIdsApprovedBranch, []) ? null : React.createElement(CollaboratorsView.CollaboratorProfilePhotos.make, {
                      ids: userIdsApprovedBranch,
                      notDisplayedCount: 0
                    }), React.createElement(Button.make, {
                    disabled: userApprovedBranch || requireAdminApproval && role !== "Admin",
                    label: "Approve",
                    loading: approveLoading,
                    onClick: (function (param) {
                        return BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "StickyBranchHeader", branchName, collaborators, "Approved", numApprovals + 1 | 0, schemaBundle, sendActions, setApproveLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                      }),
                    size: "small",
                    singleLine: true
                  }), React.createElement(Button.make, {
                    disabled: requireAdminApproval && role !== "Admin",
                    label: "Request changes",
                    loading: requestChangesLoading,
                    onClick: (function (param) {
                        return BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "StickyBranchHeader", branchName, collaborators, "ChangesRequested", numApprovals, schemaBundle, sendActions, setRequestChangesLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                      }),
                    size: "small",
                    style: "outline",
                    singleLine: true
                  }));
  } else if (isFullyApproved) {
    return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "Changes approved by"
                  }), Caml_obj.caml_equal(userIdsApprovedBranch, []) ? null : React.createElement(CollaboratorsView.CollaboratorProfilePhotos.make, {
                      ids: userIdsApprovedBranch,
                      notDisplayedCount: 0
                    }), React.createElement(Button.make, {
                    disabled: isBranchCreator || requireAdminApproval && role !== "Admin",
                    label: "Approve",
                    loading: approveLoading,
                    onClick: (function (param) {
                        return BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "StickyBranchHeader", branchName, collaborators, "Approved", numApprovals + 1 | 0, schemaBundle, sendActions, setApproveLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                      }),
                    size: "small",
                    singleLine: true
                  }), React.createElement(Button.make, {
                    disabled: isBranchCreator || requireAdminApproval && role !== "Admin",
                    label: "Request changes",
                    loading: requestChangesLoading,
                    onClick: (function (param) {
                        return BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "StickyBranchHeader", branchName, collaborators, "ChangesRequested", numApprovals, schemaBundle, sendActions, setRequestChangesLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                      }),
                    size: "small",
                    style: "outline",
                    singleLine: true
                  }));
  } else {
    return React.createElement(BranchActionBar$ReadyForReviewState, {
                branchCreatorId: branchCreatorId,
                branchId: branchId,
                branchName: branchName,
                branchStatus: branchStatus,
                collaborators: collaborators,
                isBranchCreator: isBranchCreator,
                requestedReviewerIds: requestedReviewerIds,
                requireAdminApproval: requireAdminApproval,
                role: role,
                viewerIsReviewer: viewerIsReviewer
              });
  }
}

var ApprovedState = {
  make: BranchActionBar$ApprovedState
};

function BranchActionBar$StateContainer(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(16)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, children);
}

var StateContainer = {
  make: BranchActionBar$StateContainer
};

function BranchActionBar(Props) {
  var actionDiff = Props.actionDiff;
  var actions = Props.actions;
  var branchCreatorId = Props.branchCreatorId;
  var branchDiffStatus = Props.branchDiffStatus;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var mergeConflicts = Props.mergeConflicts;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var role = Props.role;
  var workspace = WorkspaceContext.use(undefined);
  var match = workspace.settings;
  var requireAdminApproval = match.requireAdminApproval;
  var match$1 = ViewerContext.use(undefined);
  var viewerId = match$1.id;
  var collaborators = Collaborators.useCollaborators(branchId);
  var match$2 = React.useContext(DiscrepancyContext.context);
  var discrepancyConfig = match$2.config;
  var match$3 = BranchDiscrepancies.use(undefined);
  var branchAuditChecks = match$3.branchAuditChecks;
  var blockBranchWithEnforcement = React.useMemo((function () {
          var match = ValidationConfigV2.EventsV2.getCase(discrepancyConfig.events);
          if (DiscrepancyUtils.hasDiscrepencyErrors(typeof match === "object" ? true : false, ValidationConfigV2.EventsV2.hasValueChecking(discrepancyConfig.events), branchAuditChecks)) {
            return discrepancyConfig.pointOfBlock;
          }
          
        }), [
        branchModelWithPulledMaster,
        masterModel,
        discrepancyConfig
      ]);
  var approvalWorkflow = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  var requestedReviewerIds = Belt_List.toArray(Belt_List.map(Belt_List.keep(collaborators, (function (c) {
                  return Caml_obj.caml_equal(Caml_option.undefined_to_opt(c.isReviewer), true);
                })), (function (prim) {
              return prim.id;
            })));
  var isBranchCreator = branchCreatorId === viewerId;
  var viewerIsReviewer = Belt_Array.some(requestedReviewerIds, (function (id) {
          return id === viewerId;
        }));
  if (typeof branchStatus === "number") {
    return null;
  }
  switch (branchStatus.TAG | 0) {
    case /* Merged */1 :
    case /* Closed */2 :
        return null;
    default:
      var tmp;
      var exit = 0;
      if (typeof branchStatus === "number") {
        tmp = null;
      } else {
        switch (branchStatus.TAG | 0) {
          case /* Open */0 :
          case /* Draft */3 :
              exit = 1;
              break;
          case /* ReadyForReview */4 :
              tmp = React.createElement(BranchActionBar$StateContainer, {
                    children: React.createElement(BranchActionBar$ReadyForReviewState, {
                          branchCreatorId: branchCreatorId,
                          branchId: branchId,
                          branchName: branchName,
                          branchStatus: branchStatus,
                          collaborators: collaborators,
                          isBranchCreator: isBranchCreator,
                          requestedReviewerIds: requestedReviewerIds,
                          requireAdminApproval: requireAdminApproval,
                          role: role,
                          viewerIsReviewer: viewerIsReviewer
                        })
                  });
              break;
          case /* ChangesRequested */5 :
              tmp = React.createElement(BranchActionBar$StateContainer, {
                    children: React.createElement(BranchActionBar$ChangesRequestedState, {
                          blockBranchWithEnforcement: blockBranchWithEnforcement,
                          branchCreatorId: branchCreatorId,
                          branchId: branchId,
                          branchName: branchName,
                          branchStatus: branchStatus,
                          changeAction: branchStatus._0,
                          collaborators: collaborators,
                          isBranchCreator: isBranchCreator,
                          requireAdminApproval: requireAdminApproval,
                          role: role
                        })
                  });
              break;
          case /* Approved */6 :
              tmp = React.createElement(BranchActionBar$StateContainer, {
                    children: React.createElement(BranchActionBar$ApprovedState, {
                          approveActions: branchStatus._0,
                          branchCreatorId: branchCreatorId,
                          branchId: branchId,
                          branchName: branchName,
                          branchStatus: branchStatus,
                          collaborators: collaborators,
                          isBranchCreator: isBranchCreator,
                          members: workspace.members,
                          numRequiredApprovals: match.numRequiredApprovals,
                          requestedReviewerIds: requestedReviewerIds,
                          requireAdminApproval: requireAdminApproval,
                          role: role,
                          viewerIsReviewer: viewerIsReviewer
                        })
                  });
              break;
          default:
            tmp = null;
        }
      }
      if (exit === 1) {
        tmp = React.createElement(BranchActionBar$StateContainer, {
              children: React.createElement(BranchActionBar$OpenState, {
                    approvalStateAvailable: approvalWorkflow !== "Unavailable",
                    blockBranchWithEnforcement: blockBranchWithEnforcement,
                    branchCreatorId: branchCreatorId,
                    branchId: branchId,
                    branchName: branchName,
                    branchStatus: branchStatus,
                    collaborators: collaborators,
                    isBranchCreator: isBranchCreator,
                    role: role
                  })
            });
      }
      var tmp$1;
      if (typeof branchStatus === "number") {
        tmp$1 = null;
      } else {
        switch (branchStatus.TAG | 0) {
          case /* Merged */1 :
          case /* Closed */2 :
              tmp$1 = null;
              break;
          default:
            tmp$1 = React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.gap(Css.px(8)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(BranchActionBox__Components.MergeButtonSection.MergeButton.make, {
                      actionDiff: actionDiff,
                      actions: actions,
                      approvalWorkflow: approvalWorkflow,
                      blockBranchWithEnforcement: blockBranchWithEnforcement,
                      branchId: branchId,
                      branchModel: branchModel,
                      branchModelWithPulledMaster: branchModelWithPulledMaster,
                      branchName: branchName,
                      branchDiffStatus: branchDiffStatus,
                      branchStatus: branchStatus,
                      changes: changes,
                      infoPosition: "left",
                      masterModel: masterModel,
                      masterModelBeforeBranch: masterModelBeforeBranch,
                      mergeConflicts: mergeConflicts,
                      role: role,
                      masterModelLatestAction: masterModelLatestAction
                    }));
        }
      }
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(60)),
                        tl: {
                          hd: Css.maxHeight(Css.px(60)),
                          tl: {
                            hd: Css.width(Css.pct(100.0)),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.justifyContent("spaceBetween"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.white),
                                    tl: {
                                      hd: Css.borderBottom(Css.px(1), Css.solid, Styles.Color.light06),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(22)),
                                        tl: {
                                          hd: Css.paddingRight(Css.px(32)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, tmp, tmp$1);
  }
}

var make = BranchActionBar;

export {
  OpenState ,
  ReadyForReviewState ,
  ChangesRequestedState ,
  ApprovedState ,
  StateContainer ,
  make ,
  
}
/* Css Not a pure module */
