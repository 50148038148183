// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Invite from "./Invite.mjs";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Redirect from "./Redirect.mjs";
import * as SsoUtils from "./SsoUtils.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as SsoChecks from "./SsoChecks.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as FetchModel from "./FetchModel.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App from "firebase/app";
import * as QueryString from "query-string";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as IconNewWorkspace from "./IconNewWorkspace.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as IconJoinWorkspace from "./IconJoinWorkspace.mjs";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as AuthenticationMethods from "./AuthenticationMethods.mjs";

function Welcome$AutoAcceptingInvite(Props) {
  var invite = Props.invite;
  var onAccept = Props.onAccept;
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = AnalyticsUtils.makeSchemaBundle(workspace, "master", "main");
  React.useEffect((function () {
          Curry._2(onAccept, invite, schemaBundle);
          
        }), []);
  return React.createElement(LoadingFullscreen.make, {
              message: "Accepting invite..."
            });
}

var AutoAcceptingInvite = {
  make: Welcome$AutoAcceptingInvite
};

function Welcome$AutoAcceptingInviteWrapper(Props) {
  var invite = Props.invite;
  var onAccept = Props.onAccept;
  var schemaState = FirebaseFetcherHooks.useSchema(invite.schemaId, false);
  if (typeof schemaState === "object") {
    return React.createElement(WorkspaceContext.make, {
                schema: schemaState.VAL,
                children: React.createElement(Welcome$AutoAcceptingInvite, {
                      invite: invite,
                      onAccept: onAccept
                    })
              });
  } else if (schemaState === "NotFound") {
    return null;
  } else {
    return React.createElement(LoadingFullscreen.make, {
                message: "Loading workspace..."
              });
  }
}

var AutoAcceptingInviteWrapper = {
  make: Welcome$AutoAcceptingInviteWrapper
};

var emptyContainerStyle = Curry._1(Css.style, {
      hd: Css.width(Css.px(900)),
      tl: {
        hd: Css.maxWidth(Css.vw(100)),
        tl: {
          hd: Css.padding(Css.px(50)),
          tl: {
            hd: Css.margin2(Css.px(0), "auto"),
            tl: /* [] */0
          }
        }
      }
    });

var emptyHeaderStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: /* [] */0
        }
      }
    });

var emptyHeaderBodyStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var emptyHeaderBodyTextStyle = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(20)),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.color(Styles.Color.light10),
          tl: /* [] */0
        }
      }
    });

var emptyBodyContainerStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.height(Css.vh(50)),
              tl: {
                hd: Css.marginTop(Css.px(50)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var emptyBodyContainerFlex = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.padding2(Css.px(25), Css.px(0)),
        tl: /* [] */0
      }
    });

var optionContainerStyle = Curry._1(Css.style, {
      hd: Css.flex({
            NAME: "num",
            VAL: 1.0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.padding2(Css.px(15), Css.px(0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var optionTitleStyle = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.bold),
      tl: {
        hd: Css.fontSize(Styles.FontSize.large),
        tl: /* [] */0
      }
    });

var optionBodyStyle = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(10), Css.px(25)),
      tl: {
        hd: Css.textAlign("center"),
        tl: {
          hd: Css.fontSize(Styles.FontSize.regular),
          tl: /* [] */0
        }
      }
    });

var optionSeparatorStyle = Curry._1(Css.style, {
      hd: Css.height(Css.px(300)),
      tl: {
        hd: Css.width(Css.px(1)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.light04),
          tl: /* [] */0
        }
      }
    });

function addUserToSchema(schemaId, userId, role) {
  var batch = Firebase.app(undefined).firestore().batch();
  var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId);
  var schemaAclRef = schemaRef.collection("acls").doc(userId);
  var newUserSchemaRef = Firebase.app(undefined).firestore().collection("users").doc(userId).collection("schemas").doc(schemaId);
  batch.set(schemaAclRef, {
        id: userId,
        role: Models.Role.tToJs(role),
        type_: "user",
        createdAt: App.default.firestore.FieldValue.serverTimestamp()
      });
  batch.set(newUserSchemaRef, {
        id: schemaId,
        lastUsedAt: null
      });
  return $$Promise.$$catch(batch.commit().then(function (param) {
                  return Promise.resolve(schemaId);
                }), (function (error) {
                console.error("addUserToSchema error", schemaId, error);
                return Promise.resolve(schemaId);
              }));
}

function hasSsoSchemasToJoin(userSchemas, ssoSchemaIds) {
  return Belt_List.some(ssoSchemaIds, (function (schemaId) {
                return !Belt_List.some(userSchemas, (function (userSchema) {
                              return Models.UserSchema.getId(userSchema) === schemaId;
                            }));
              }));
}

function useAutoJoinSsoWorkspaces(userSchemasState, ssoSchemasState, userId) {
  var match = React.useState(function () {
        return "Initial";
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (typeof userSchemasState === "object" && userSchemasState.NAME === "Loaded" && typeof ssoSchemasState === "object" && ssoSchemasState.NAME === "Loaded") {
            var ssoSchemaIds = ssoSchemasState.VAL;
            var userSchemas = userSchemasState.VAL;
            if (hasSsoSchemasToJoin(userSchemas, ssoSchemaIds) && state === "Initial") {
              Curry._1(setState, (function (param) {
                      return "Loading";
                    }));
              var currentUser = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.getAuth(undefined).currentUser));
              var ssoSchemaIdsToJoin = Belt_List.keep(ssoSchemaIds, (function (schemaId) {
                      return !Belt_List.some(userSchemas, (function (userSchema) {
                                    return Models.UserSchema.getId(userSchema) === schemaId;
                                  }));
                    }));
              $$Promise.$$catch(Promise.all(Belt_Array.map(Belt_List.toArray(ssoSchemaIdsToJoin), (function (schemaId) {
                                return SsoChecks.getSsoProviderForSchemaFromFirestore(schemaId).then(function (ssoConfig) {
                                              if (ssoConfig === undefined) {
                                                return [
                                                        "Editor",
                                                        undefined
                                                      ];
                                              }
                                              var ssoConfig$1 = Caml_option.valFromOption(ssoConfig);
                                              return [
                                                      Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.undefined_to_opt(ssoConfig$1.defaultRole), Models.Role.tFromJs), "Editor"),
                                                      ssoConfig$1.authProviderId
                                                    ];
                                            }).then(function (param) {
                                            var isSessionUsingRequiredAuthProvider = Belt_Option.mapWithDefault(param[1], true, (function (authProviderId) {
                                                    return Belt_Array.some(currentUser.providerData, (function (provider) {
                                                                  return provider.providerId === authProviderId;
                                                                }));
                                                  }));
                                            if (isSessionUsingRequiredAuthProvider) {
                                              return addUserToSchema(schemaId, userId, param[0]).then(function (param) {
                                                          return Promise.resolve("Success");
                                                        });
                                            } else {
                                              return Promise.resolve("MissingAccess");
                                            }
                                          });
                              }))).then(function (results) {
                        var missingAccess = Belt_Array.some(results, (function (result) {
                                return result === "MissingAccess";
                              }));
                        Curry._1(setState, (function (param) {
                                if (missingAccess) {
                                  return "MissingAccess";
                                } else {
                                  return "Success";
                                }
                              }));
                        return Promise.resolve(undefined);
                      }), (function (param) {
                      Curry._1(setState, (function (param) {
                              return "Error";
                            }));
                      console.error("Failed to auto join SSO workspaces");
                      return Promise.resolve(undefined);
                    }));
            }
            
          }
          
        }), [
        userSchemasState,
        ssoSchemasState
      ]);
  return state;
}

function Welcome(Props) {
  var userId = Props.userId;
  var auth = Firebase.getAuth(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setAcceptingInvite = match[1];
  var currentUserEmail = Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser)).email;
  var currentUserEmail$1 = (currentUserEmail == null) ? undefined : Caml_option.some(currentUserEmail);
  var query = Router.getSearch(undefined).replace("?", "");
  var maybeRedirect = Belt_Option.map(Caml_option.nullable_to_opt(QueryString.parse(query).redirect), (function (param) {
          var redirectStartAt = query.indexOf("redirect=") + "redirect=".length | 0;
          return query.substring(redirectStartAt);
        }));
  var userSchemasState = FirebaseFetcherHooks.useUserSchemas(userId);
  var userSchemaInvitesState = FirebaseFetcherHooks.useUserSchemaInvites(currentUserEmail$1);
  var ssoSchemasState = FirebaseFetcherHooks.useSsoSchemasForDomain(Belt_Option.flatMap(currentUserEmail$1, SsoUtils.getEmailDomain));
  var handleAcceptInvite = function (invite, schemaBundle) {
    Curry._1(setAcceptingInvite, (function (param) {
            return true;
          }));
    $$Promise.$$catch(Invite.acceptInvite(invite, userId, schemaBundle).then(function (param) {
              Curry._1(setAcceptingInvite, (function (param) {
                      return false;
                    }));
              return Promise.resolve(undefined);
            }), (function (error) {
            console.log("Failed to accept invite", error);
            Sentry.captureException(error, {});
            return Promise.resolve(undefined);
          }));
    
  };
  var autoJoinSsoWorkspacesStatus = useAutoJoinSsoWorkspaces(userSchemasState, ssoSchemasState, userId);
  React.useEffect((function () {
          if (autoJoinSsoWorkspacesStatus === "Loading") {
            Curry._1(setAcceptingInvite, (function (param) {
                    return true;
                  }));
          } else if (autoJoinSsoWorkspacesStatus === "Initial") {
            
          } else {
            Curry._1(setAcceptingInvite, (function (param) {
                    return false;
                  }));
          }
          
        }), [autoJoinSsoWorkspacesStatus]);
  var searchParams = Router.getSearch(undefined);
  var exit = 0;
  var exit$1 = 0;
  if (match[0]) {
    return React.createElement(LoadingFullscreen.make, {
                message: "Joining workspace..."
              });
  }
  if (ssoSchemasState === "Initial" || userSchemaInvitesState === "Initial") {
    exit = 2;
  } else if (typeof userSchemasState === "object") {
    var schemas = userSchemasState.VAL;
    var exit$2 = 0;
    if (schemas) {
      if (schemas.tl || !(typeof userSchemaInvitesState === "object" && userSchemaInvitesState.NAME === "Loaded")) {
        exit$2 = 5;
      } else {
        if (!userSchemaInvitesState.VAL) {
          return React.createElement(Redirect.make, {
                      path: Belt_Option.getWithDefault(maybeRedirect, Curry._4(Router.Link.get, {
                                NAME: "schema",
                                VAL: schemas.hd.id
                              }, "master", "dashboard", /* [] */0) + searchParams)
                    });
        }
        exit$1 = 4;
      }
    } else {
      var exit$3 = 0;
      if (typeof userSchemaInvitesState === "object" && userSchemaInvitesState.NAME === "Loaded") {
        var match$1 = userSchemaInvitesState.VAL;
        if (match$1) {
          if (!match$1.tl) {
            return React.createElement(Welcome$AutoAcceptingInviteWrapper, {
                        invite: match$1.hd,
                        onAccept: handleAcceptInvite
                      });
          }
          exit$3 = 6;
        } else if (typeof ssoSchemasState === "object" && ssoSchemasState.NAME === "Loaded") {
          if (!ssoSchemasState.VAL) {
            return React.createElement("div", {
                        className: emptyContainerStyle
                      }, React.createElement("div", {
                            className: emptyHeaderStyle
                          }, React.createElement(Icons.AvoLogo.make, {
                                width: 100,
                                fill: Styles.Color.light12
                              }), React.createElement("div", {
                                className: emptyHeaderBodyStyle
                              }, React.createElement("div", {
                                    className: emptyHeaderBodyTextStyle
                                  }, "Welcome " + Belt_Option.getWithDefault(currentUserEmail$1, AvoConfig.anonymousUserDisplayName) + "!"), React.createElement(Spacer.make, {
                                    height: 24
                                  }), React.createElement(Button.make, {
                                    label: "Log out",
                                    onClick: (function (param) {
                                        AuthenticationMethods.signOut(auth);
                                        
                                      })
                                  }))), React.createElement("div", {
                            className: emptyBodyContainerStyle
                          }, React.createElement("h2", {
                                className: Curry._1(Css.style, {
                                      hd: Css.color(Styles.Color.light12),
                                      tl: /* [] */0
                                    })
                              }, "What would you like to do?"), React.createElement("div", {
                                className: emptyBodyContainerFlex
                              }, React.createElement("div", {
                                    className: optionContainerStyle
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.margin2(Css.px(15), Css.px(0)),
                                              tl: /* [] */0
                                            })
                                      }, React.createElement(IconNewWorkspace.make, {
                                            size: 72,
                                            color: Styles.Color.avoPink
                                          })), React.createElement("div", {
                                        className: optionTitleStyle
                                      }, "Set Up a New Workspace"), React.createElement("p", {
                                        className: optionBodyStyle
                                      }, "Set up a new workspace to kick off your data plan, invite team members and start collaborating."), React.createElement(Spacer.make, {
                                        height: 16
                                      }), React.createElement(Button.make, {
                                        label: "Create New Workspace",
                                        onClick: (function (param) {
                                            return Router.push(undefined, "onboarding?new_workspace=true&email=" + Belt_Option.getWithDefault(currentUserEmail$1, ""));
                                          })
                                      })), React.createElement("div", {
                                    className: optionSeparatorStyle
                                  }), React.createElement("div", {
                                    className: optionContainerStyle
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.margin2(Css.px(15), Css.px(0)),
                                              tl: /* [] */0
                                            })
                                      }, React.createElement(IconJoinWorkspace.make, {
                                            size: 72,
                                            color: Styles.Color.avoPink
                                          })), React.createElement("div", {
                                        className: optionTitleStyle
                                      }, "Join Existing Workspace"), React.createElement("p", {
                                        className: optionBodyStyle
                                      }, "To start collaborating, find someone in your team that can send you an invite to the Avo workspace.")))));
          }
          exit$3 = 6;
        } else {
          exit$3 = 6;
        }
      } else {
        exit$3 = 6;
      }
      if (exit$3 === 6) {
        return React.createElement(Redirect.make, {
                    path: "/schemas" + searchParams
                  });
      }
      
    }
    if (exit$2 === 5) {
      if (typeof userSchemaInvitesState === "object") {
        if (userSchemaInvitesState.NAME === "Loaded") {
          if (userSchemaInvitesState.VAL) {
            exit$1 = 4;
          } else {
            var workspacesUserHasOpened = Belt_List.keep(schemas, (function (schema) {
                    return Belt_Option.isSome(Caml_option.nullable_to_opt(schema.lastUsedAt));
                  }));
            var workspaceRedirectUri;
            var exit$4 = 0;
            var userSchema;
            if (workspacesUserHasOpened) {
              var userSchema$1 = workspacesUserHasOpened.hd;
              userSchema = userSchema$1;
              exit$4 = 6;
            } else {
              workspaceRedirectUri = "/schemas";
            }
            if (exit$4 === 6) {
              workspaceRedirectUri = Curry._4(Router.Link.get, {
                    NAME: "schema",
                    VAL: userSchema.id
                  }, "master", "dashboard", /* [] */0);
            }
            return React.createElement(Redirect.make, {
                        path: Belt_Option.getWithDefault(maybeRedirect, workspaceRedirectUri + searchParams)
                      });
          }
        } else if (userSchemaInvitesState === "Error") {
          exit = 3;
        } else {
          exit$1 = 4;
        }
      } else if (userSchemaInvitesState === "Error") {
        exit = 3;
      } else {
        exit$1 = 4;
      }
    }
    
  } else {
    exit = userSchemasState === "Initial" ? 2 : 3;
  }
  if (exit$1 === 4) {
    exit = typeof ssoSchemasState === "object" && ssoSchemasState.NAME === "Errored" ? 3 : 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(Redirect.make, {
                    path: Belt_Option.getWithDefault(maybeRedirect, "/schemas" + searchParams)
                  });
    case 2 :
        return React.createElement(LoadingFullscreen.make, {
                    message: "Loading your workspaces..."
                  });
    case 3 :
        return React.createElement(FetchModel.$$Error.make, {
                    message: "Could not fetch your workspace"
                  });
    
  }
}

var make = Welcome;

export {
  AutoAcceptingInvite ,
  AutoAcceptingInviteWrapper ,
  emptyContainerStyle ,
  emptyHeaderStyle ,
  emptyHeaderBodyStyle ,
  emptyHeaderBodyTextStyle ,
  emptyBodyContainerStyle ,
  emptyBodyContainerFlex ,
  optionContainerStyle ,
  optionTitleStyle ,
  optionBodyStyle ,
  optionSeparatorStyle ,
  addUserToSchema ,
  hasSsoSchemasToJoin ,
  useAutoJoinSsoWorkspaces ,
  make ,
  
}
/* emptyContainerStyle Not a pure module */
