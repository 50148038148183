// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Callout from "./Callout.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconErase from "./IconErase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as Core from "@mantine/core";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as GetEventSpecificPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetEventSpecificPropertyValuesUseCase.mjs";

var root = Curry._1(Css.style, {
      hd: Css.borderRadius(Styles.Border.radius),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.overflow("hidden"),
          tl: {
            hd: Css.width(Css.px(240)),
            tl: {
              hd: Css.boxShadow(Styles.Shadow.subtle),
              tl: {
                hd: Css.maxHeight({
                      NAME: "subtract",
                      VAL: [
                        Css.vh(100.0),
                        Css.px(32)
                      ]
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light03),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

var input = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "add",
            VAL: [
              Css.pct(100),
              Css.px(8)
            ]
          }),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.paddingLeft(Css.px(12)),
          tl: {
            hd: Css.fontSize(Css.px(14)),
            tl: {
              hd: Css.fontWeight({
                    NAME: "num",
                    VAL: 500
                  }),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.outlineStyle("none"),
                  tl: {
                    hd: Css.outlineWidth(Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var eraser = Curry._1(Css.style, {
      hd: Css.height(Css.pct(100)),
      tl: {
        hd: Css.padding(Css.px(2)),
        tl: {
          hd: Css.marginLeft(Css.px(6)),
          tl: {
            hd: Css.marginRight(Css.px(6)),
            tl: {
              hd: Css.borderWidth(Css.px(0)),
              tl: {
                hd: Css.borderRadius(Css.px(2)),
                tl: {
                  hd: Css.background("transparent"),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "opacity"),
                    tl: {
                      hd: Css.unsafe("appearance", "none"),
                      tl: {
                        hd: Css.outlineStyle("none"),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: {
                            hd: Css.disabled({
                                  hd: Css.opacity(0.0),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.active({
                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.focus({
                                      hd: Css.outlineStyle("none"),
                                      tl: {
                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(2)),
      tl: {
        hd: Css.paddingBottom(Css.px(2)),
        tl: {
          hd: Css.overflowY("auto"),
          tl: {
            hd: Css.maxHeight(Css.px(300)),
            tl: /* [] */0
          }
        }
      }
    });

var option = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(8)),
      tl: {
        hd: Css.paddingRight(Css.px(12)),
        tl: {
          hd: Css.paddingBottom(Css.px(8)),
          tl: {
            hd: Css.paddingLeft(Css.px(12)),
            tl: {
              hd: Css.cursor("pointer"),
              tl: {
                hd: Css.hover({
                      hd: Css.backgroundColor(Styles.Color.light02),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var fauxOption = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(8)),
      tl: {
        hd: Css.paddingRight(Css.px(12)),
        tl: {
          hd: Css.paddingBottom(Css.px(8)),
          tl: {
            hd: Css.paddingLeft(Css.px(12)),
            tl: /* [] */0
          }
        }
      }
    });

function EditEventSpecificPropertyValuesModal$AddValuePopup(Props) {
  var onCreate = Props.onCreate;
  var canCreate = Props.canCreate;
  var options = Props.options;
  var onAdd = Props.onAdd;
  var match = React.useState(function () {
        return "";
      });
  var setInput = match[1];
  var input$1 = match[0];
  var inputRef = React.useRef(null);
  var handleCreate = function (param) {
    Curry._1(onCreate, input$1);
    Curry._1(setInput, (function (param) {
            return "";
          }));
    return Belt_Option.forEach(Caml_option.nullable_to_opt(inputRef.current), (function (prim) {
                  prim.focus();
                  
                }));
  };
  var filteredOptions = Fuzzaldrin.filter(options, input$1, {
        maxResults: 100
      });
  var canCreate$1 = React.useMemo((function () {
          if (input$1 !== "") {
            return Curry._1(canCreate, input$1);
          } else {
            return false;
          }
        }), [
        canCreate,
        input$1
      ]);
  var tmp;
  var exit = 0;
  if (input$1 === "" && filteredOptions.length === 0) {
    tmp = React.createElement("div", {
          className: fauxOption
        }, React.createElement($$Text.make, {
              size: "Small",
              color: Styles.Color.light10,
              children: "Currently, no additional values are allowed for this property. To add a new value, simply type the name into the search field."
            }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = canCreate$1 ? React.createElement("div", {
            className: fauxOption
          }, React.createElement(Button.make, {
                icon: "plus",
                label: "Create \"" + input$1 + "\"",
                onClick: handleCreate,
                style: "ghost"
              })) : (
        filteredOptions.length !== 0 ? null : React.createElement("div", {
                className: fauxOption
              }, React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.light10,
                    children: "\"" + input$1 + "\" is already enabled"
                  }))
      );
  }
  return React.createElement("aside", {
              className: root
            }, React.createElement("header", {
                  className: header
                }, React.createElement("input", {
                      ref: inputRef,
                      className: input,
                      autoFocus: true,
                      placeholder: Caml_obj.caml_equal(options, []) ? "Create new value..." : "Filter by...",
                      type: "text",
                      value: input$1,
                      onKeyPress: (function ($$event) {
                          if ($$event.key === "Enter" && canCreate$1) {
                            return handleCreate(undefined);
                          }
                          
                        }),
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          return Curry._1(setInput, (function (param) {
                                        return value;
                                      }));
                        })
                    }), React.createElement("button", {
                      className: eraser,
                      disabled: input$1 === "",
                      onClick: (function (param) {
                          return Curry._1(setInput, (function (param) {
                                        return "";
                                      }));
                        })
                    }, React.createElement(IconErase.make, {
                          color: Styles.Color.light07,
                          hoverColor: Styles.Color.red
                        }))), React.createElement("div", {
                  className: content
                }, tmp, Belt_Array.map(filteredOptions, (function (value) {
                        return React.createElement("div", {
                                    key: value,
                                    className: option,
                                    onClick: (function (param) {
                                        return Curry._1(onAdd, value);
                                      })
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Semi",
                                        children: value
                                      }));
                      }))));
}

var root$1 = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.padding(Css.px(24)),
          tl: {
            hd: Css.width(Css.px(580)),
            tl: {
              hd: Css.maxHeight({
                    NAME: "subtract",
                    VAL: [
                      Css.vh(100.0),
                      Css.px(32)
                    ]
                  }),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.alignItems("flexStart"),
                    tl: {
                      hd: Css.boxShadows({
                            hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(8), Css.px(18), Css.px(-6), undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.12)),
                            tl: {
                              hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(12), Css.px(42), Css.px(-4), undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.12)),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var matches = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.alignSelf("stretch"),
        tl: {
          hd: Css.gridTemplateColumns({
                hd: Css.px(36),
                tl: {
                  hd: {
                    NAME: "minmax",
                    VAL: [
                      "auto",
                      Css.fr(1.0)
                    ]
                  },
                  tl: {
                    hd: {
                      NAME: "minmax",
                      VAL: [
                        "auto",
                        Css.fr(1.0)
                      ]
                    },
                    tl: /* [] */0
                  }
                }
              }),
          tl: {
            hd: Css.gridAutoRows({
                  NAME: "minmax",
                  VAL: [
                    Css.px(41),
                    "auto"
                  ]
                }),
            tl: {
              hd: Css.maxHeight({
                    NAME: "subtract",
                    VAL: [
                      Css.vh(100.0),
                      Css.px(400)
                    ]
                  }),
              tl: {
                hd: Css.overflowY("auto"),
                tl: {
                  hd: Css.position("relative"),
                  tl: {
                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var headerCell = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(2), "solid", Styles.Color.light06),
      tl: {
        hd: Css.paddingTop(Css.px(8)),
        tl: {
          hd: Css.paddingLeft(Css.px(8)),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.position("sticky"),
                  tl: {
                    hd: Css.top(Css.px(0)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.zIndex(1),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var headerInputCell = Curry._1(Css.merge, {
      hd: headerCell,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var matchCell = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
      tl: {
        hd: Css.paddingTop(Css.px(8)),
        tl: {
          hd: Css.paddingLeft(Css.px(8)),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var inputCell = Curry._1(Css.merge, {
      hd: matchCell,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var buttons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignSelf("stretch"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.justifyContent("flexEnd"),
            tl: {
              hd: Css.gap(Css.px(16)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function EditEventSpecificPropertyValuesModal(Props) {
  var onClose = Props.onClose;
  var eventId = Props.eventId;
  var propertyId = Props.propertyId;
  var maybeVariantId = Props.maybeVariantId;
  var sendActions = SendActionsContext.use(undefined);
  var model = ModelStore.useModel(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var properties = ModelStore.Mapped.useProperties(undefined);
  var sources = ModelStore.Mapped.useSources(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var baseEvent = Belt_Option.getExn(Curry._2(TrackingPlanMappedModel.Events.get, events, eventId));
  var maybeVariant = Belt_Option.flatMap(maybeVariantId, (function (id) {
          return Belt_Array.getBy(baseEvent.variants, (function (variant) {
                        return variant.id === id;
                      }));
        }));
  var match = React.useState(function () {
        
      });
  var setRemovedMatches = match[1];
  var removedMatches = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setNewMatches = match$1[1];
  var newMatches = match$1[0];
  var match$2;
  if (maybeVariant !== undefined) {
    var flattenedModel = FlattenVariantsUseCase.flattenOneVariant(model, baseEvent, maybeVariant);
    var $$event = Belt_Option.getExn(ModelUtils.getEventById(VariantEventIdUseCase.variantEventId(TrackingPlanModel.EventVariant.makeVariantIdentifier(maybeVariant)), flattenedModel));
    var property = Belt_Option.getExn(ModelUtils.resolvePropertyById(propertyId, flattenedModel));
    match$2 = [
      $$event,
      property
    ];
  } else {
    match$2 = [
      baseEvent,
      Belt_Option.getExn(Curry._2(TrackingPlanMappedModel.Properties.get, properties, propertyId))
    ];
  }
  var property$1 = match$2[1];
  var $$event$1 = match$2[0];
  var eventSourceIdsSet = Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray($$event$1.includeSources), (function (param) {
              return param.id;
            })));
  var saveDisabled = removedMatches === undefined && newMatches === undefined;
  var allMatchesOnProperty = Belt_Array.keepMap(property$1.eventSpecificAllowedPropertyValues, (function (param) {
          var literal = param[0];
          if (typeof literal === "object" && literal.NAME === "StringLit") {
            return literal.VAL;
          }
          
        }));
  var existingMatches = GetEventSpecificPropertyValuesUseCase.get(property$1.eventSpecificAllowedPropertyValues, $$event$1);
  var selectedCount = (existingMatches.length - Belt_MapString.size(removedMatches) | 0) + Belt_MapString.size(newMatches) | 0;
  var handleSave = function (param) {
    if (maybeVariant !== undefined) {
      var context = {
        eventId: eventId,
        eventQuery: $$event$1.id,
        propertyId: propertyId,
        propertyQuery: propertyId,
        eventVariantId: maybeVariant.id,
        eventVariantQuery: maybeVariant.id
      };
      var baseEventHasValue = function (value) {
        return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.getBy(property$1.eventSpecificAllowedPropertyValues, (function (param) {
                              return Caml_obj.caml_equal(param[0], {
                                          NAME: "StringLit",
                                          VAL: value
                                        });
                            })), (function (param) {
                          var match = Belt_MapString.get(param[1], maybeVariant.baseEventId);
                          if (match === undefined) {
                            return true;
                          }
                          if (!match) {
                            return false;
                          }
                          var disallowedSources = match._0;
                          return Belt_List.every(baseEvent.includeSources, (function (param) {
                                        return Belt_SetString.has(disallowedSources, param.id);
                                      }));
                        })), false);
      };
      var variantIdentifier = TrackingPlanModel.EventVariant.makeVariantIdentifier(maybeVariant);
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                return AnalyticsRe.eventVariantUpdatedProperty(maybeVariant.baseEventId, $$event$1.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "EditPropertyAllowedValues", maybeVariant.id, property$1.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), maybeVariant.baseEventId);
              }),
            undefined,
            Belt_Array.concat(Belt_Array.map(Belt_MapString.toArray(removedMatches), (function (param) {
                        var value = param[0];
                        return [
                                baseEventHasValue(value) ? ({
                                      NAME: "OverrideEventVariantSpecificPropertyValueToBeDisallowed",
                                      VAL: [
                                        variantIdentifier,
                                        propertyId,
                                        {
                                          NAME: "StringLit",
                                          VAL: value
                                        }
                                      ]
                                    }) : ({
                                      NAME: "ClearEventVariantSpecificPropertyValueOverride",
                                      VAL: [
                                        variantIdentifier,
                                        propertyId,
                                        {
                                          NAME: "StringLit",
                                          VAL: value
                                        }
                                      ]
                                    }),
                                context
                              ];
                      })), Belt_Array.map(Belt_MapString.keysToArray(newMatches), (function (value) {
                        return [
                                baseEventHasValue(value) ? ({
                                      NAME: "ClearEventVariantSpecificPropertyValueOverride",
                                      VAL: [
                                        variantIdentifier,
                                        propertyId,
                                        {
                                          NAME: "StringLit",
                                          VAL: value
                                        }
                                      ]
                                    }) : ({
                                      NAME: "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources",
                                      VAL: [
                                        variantIdentifier,
                                        propertyId,
                                        {
                                          NAME: "StringLit",
                                          VAL: value
                                        }
                                      ]
                                    }),
                                context
                              ];
                      })))
          ]);
    } else {
      var context$1 = {
        eventId: eventId,
        eventQuery: $$event$1.id,
        propertyId: propertyId,
        propertyQuery: propertyId
      };
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                var nextEventMatches = Belt_SetString.union(Belt_SetString.fromArray(Belt_Array.keepMap(existingMatches, (function (param) {
                                var value = param[0];
                                var removedAllowedSources = Belt_MapString.get(removedMatches, value);
                                if (removedAllowedSources !== undefined && Belt_SetString.eq(Belt_SetString.intersect(Belt_SetString.union(param[1], Caml_option.valFromOption(removedAllowedSources)), eventSourceIdsSet), eventSourceIdsSet)) {
                                  return ;
                                } else {
                                  return value;
                                }
                              }))), Belt_SetString.fromArray(Belt_MapString.keysToArray(newMatches)));
                Belt_Array.forEach(Belt_MapString.toArray(removedMatches), (function (param) {
                        var removedAllowedSources = param[1];
                        var value = param[0];
                        var existingDisallowedSources = Belt_Option.mapWithDefault(Belt_Array.getBy(existingMatches, (function (param) {
                                    return param[0] === value;
                                  })), undefined, (function (param) {
                                return param[1];
                              }));
                        var nextDisallowedSources = Belt_SetString.intersect(Belt_SetString.union(existingDisallowedSources, removedAllowedSources), eventSourceIdsSet);
                        var isDisallowedOnAllSources = Belt_SetString.eq(nextDisallowedSources, eventSourceIdsSet);
                        var nextAllowedSources = Belt_SetString.diff(eventSourceIdsSet, nextDisallowedSources);
                        var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                        var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(properties, property$1);
                        if (isDisallowedOnAllSources) {
                          return AnalyticsRe.matchUpdatedForEvent(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyBundle.propertyId, property$1.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, value, Belt_SetString.size(nextEventMatches), Belt_SetString.toArray(nextEventMatches), "DisallowOnEvent", Belt_SetString.size(eventSourceIdsSet), Belt_SetString.size(nextAllowedSources), "Event", $$event$1.id, $$event$1.name, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                        } else {
                          return Belt_SetString.forEach(removedAllowedSources, (function (sourceId) {
                                        return AnalyticsRe.matchUpdatedForEventSource(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, value, Belt_SetString.size(nextEventMatches), Belt_SetString.toArray(nextEventMatches), "DisallowOnEventSource", Belt_SetString.size(eventSourceIdsSet), Belt_SetString.size(nextAllowedSources), "Event", Belt_Option.getWithDefault(TrackingPlanMappedModel.Sources.getName(sources, sourceId), "Untitled Source"), $$event$1.id, $$event$1.name, sourceId, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                      }));
                        }
                      }));
                return Belt_Array.forEach(Belt_MapString.toArray(newMatches), (function (param) {
                              var newAllowedSources = param[1];
                              var value = param[0];
                              var existingDisallowedSources = Belt_Option.mapWithDefault(Belt_Array.getBy(existingMatches, (function (param) {
                                          return param[0] === value;
                                        })), undefined, (function (param) {
                                      return param[1];
                                    }));
                              var nextDisallowedSources = Belt_SetString.intersect(Belt_SetString.diff(existingDisallowedSources, newAllowedSources), eventSourceIdsSet);
                              var isAllowedOnAllSources = Belt_SetString.isEmpty(nextDisallowedSources);
                              var nextAllowedSources = Belt_SetString.diff(eventSourceIdsSet, nextDisallowedSources);
                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                              var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(properties, property$1);
                              if (isAllowedOnAllSources) {
                                return AnalyticsRe.matchUpdatedForEvent(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, value, Belt_SetString.size(nextEventMatches), Belt_SetString.toArray(nextEventMatches), "AllowOnEvent", Belt_SetString.size(eventSourceIdsSet), Belt_SetString.size(nextAllowedSources), "Event", $$event$1.id, $$event$1.name, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                              } else {
                                return Belt_SetString.forEach(newAllowedSources, (function (sourceId) {
                                              return AnalyticsRe.matchUpdatedForEventSource(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, value, Belt_SetString.size(nextEventMatches), Belt_SetString.toArray(nextEventMatches), "AllowOnEventSource", Belt_SetString.size(eventSourceIdsSet), Belt_SetString.size(nextAllowedSources), "Event", Belt_Option.getWithDefault(TrackingPlanMappedModel.Sources.getName(sources, sourceId), "Untitled Source"), $$event$1.id, $$event$1.name, sourceId, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                            }));
                              }
                            }));
              }),
            undefined,
            Belt_Array.concat(Belt_Array.map(Belt_MapString.toArray(removedMatches), (function (param) {
                        return [
                                {
                                  NAME: "RemoveEventSpecificPropertyValue",
                                  VAL: [
                                    eventId,
                                    propertyId,
                                    {
                                      NAME: "StringLit",
                                      VAL: param[0]
                                    },
                                    param[1]
                                  ]
                                },
                                context$1
                              ];
                      })), Belt_Array.map(Belt_MapString.keysToArray(newMatches), (function (value) {
                        return [
                                {
                                  NAME: "AddEventSpecificPropertyValue",
                                  VAL: [
                                    eventId,
                                    propertyId,
                                    {
                                      NAME: "StringLit",
                                      VAL: value
                                    },
                                    eventSourceIdsSet
                                  ]
                                },
                                context$1
                              ];
                      })))
          ]);
    }
    return Curry._1(onClose, undefined);
  };
  var unsupportedSources = Belt_Array.keepMap(Belt_Array.keepMap(Belt_Array.keepMap(Belt_List.toArray($$event$1.includeSources), (function (param) {
                  if (param.includeInCodegen) {
                    return param.id;
                  }
                  
                })), Curry._1(TrackingPlanMappedModel.Sources.get, sources)), (function (source) {
          var language = source.language;
          if (language !== undefined && Belt_Array.every(AvoConfig.supportedEventSpecificPropertyValuesPlatforms, (function (supportedLanguage) {
                    return supportedLanguage !== language;
                  }))) {
            return [
                    source,
                    language
                  ];
          }
          
        }));
  var isVariant = maybeVariant !== undefined;
  var tmp;
  if (unsupportedSources.length !== 0) {
    var sourceNames = Belt_Array.map(unsupportedSources, (function (param) {
              return AvoConfig.getSourceName(param[0]) + " (" + AvoConfigTypes.languageToJs(param[1]) + ")";
            })).join(", ");
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
              height: 16
            }), React.createElement(Callout.make, {
              title: "Event-specific values currently not supported on all your Codegen sources",
              body: "The following sources are using programming languages where Codegen does not yet support event-specific values: " + sourceNames + ". For Codegen on sources in those languages, the property will be represented and validated using a union of all the property values on that source. We're hoping to add full support for more language in the near future."
            }), React.createElement(Spacer.make, {
              height: 16
            }));
  } else {
    tmp = null;
  }
  return React.createElement("section", {
              className: root$1
            }, React.createElement(Title.make, {
                  children: (
                    Caml_obj.caml_equal(existingMatches, []) ? "Add" : "Edit"
                  ) + " allowed property values on event " + (
                    isVariant ? " variant" : ""
                  ),
                  size: "Small"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.light11,
                  children: "Configure which property values are allowed to be sent on this event " + (
                    isVariant ? " variant" : ""
                  ) + "."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: null
                }, "For the ", React.createElement($$Text.make, {
                      element: "Span",
                      weight: "Semi",
                      children: maybeVariant !== undefined ? $$event$1.name + " – " + maybeVariant.nameSuffix : $$event$1.name
                    }), " event " + (
                  isVariant ? " variant" : ""
                ) + " the property ", React.createElement($$Text.make, {
                      element: "Span",
                      weight: "Semi",
                      children: property$1.name
                    }), " should match:"), React.createElement(Spacer.make, {
                  height: 16
                }), Caml_obj.caml_equal(existingMatches, []) && newMatches === undefined ? React.createElement($$Text.make, {
                    size: "Medium",
                    color: Styles.Color.light10,
                    children: "There are no matches yet, this property will allow any string value."
                  }) : React.createElement("div", {
                    className: matches
                  }, React.createElement("div", {
                        className: headerInputCell
                      }, existingMatches.length > 1 ? React.createElement(Mantine.Checkbox.make, {
                              checked: Belt_MapString.size(removedMatches) < existingMatches.length,
                              color: "blue100",
                              indeterminate: removedMatches !== undefined && Belt_MapString.size(removedMatches) !== existingMatches.length,
                              onChange: (function (param) {
                                  return Curry._1(setRemovedMatches, (function (removedMatches) {
                                                if (removedMatches === undefined) {
                                                  return Belt_MapString.fromArray(existingMatches);
                                                }
                                                
                                              }));
                                }),
                              size: {
                                NAME: "num",
                                VAL: 16
                              }
                            }) : null), React.createElement("div", {
                        className: headerCell
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Allowed values (" + String(selectedCount) + ")"
                          })), React.createElement("div", {
                        className: headerCell
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Sources"
                          })), Belt_Array.map(existingMatches, (function (param) {
                          var excludedSources = param[1];
                          var match = param[0];
                          return React.createElement(React.Fragment, {
                                      children: null,
                                      key: match
                                    }, React.createElement("div", {
                                          className: inputCell
                                        }, React.createElement(Mantine.Checkbox.make, {
                                              checked: !Belt_MapString.has(removedMatches, match),
                                              color: "blue100",
                                              onChange: (function (param) {
                                                  return Curry._1(setRemovedMatches, (function (removedMatches) {
                                                                if (Belt_MapString.has(removedMatches, match)) {
                                                                  return Belt_MapString.remove(removedMatches, match);
                                                                } else {
                                                                  return Belt_MapString.set(removedMatches, match, Belt_SetString.diff(eventSourceIdsSet, excludedSources));
                                                                }
                                                              }));
                                                }),
                                              size: {
                                                NAME: "num",
                                                VAL: 16
                                              }
                                            })), React.createElement("label", {
                                          className: matchCell
                                        }, React.createElement($$Text.make, {
                                              size: "Medium",
                                              color: Styles.Color.light12,
                                              children: match
                                            })), React.createElement("div", {
                                          className: matchCell
                                        }, React.createElement($$Text.make, {
                                              size: "Medium",
                                              weight: "Semi",
                                              color: Styles.Color.light11,
                                              children: excludedSources === undefined ? "All event " + (
                                                  isVariant ? " variant" : ""
                                                ) + " sources" : Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(Belt_SetString.diff(eventSourceIdsSet, excludedSources)), (function (id) {
                                                              return Curry._2(TrackingPlanMappedModel.Sources.get, sources, id);
                                                            })), AvoConfig.getSourceName).join(", ")
                                            })));
                        })), Belt_Array.map(Belt_MapString.keysToArray(newMatches), (function (match) {
                          return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                          key: match,
                                          className: inputCell
                                        }, React.createElement(Button.make, {
                                              icon: "xBold",
                                              onClick: (function (param) {
                                                  return Curry._1(setNewMatches, (function (newMatches) {
                                                                return Belt_MapString.remove(newMatches, match);
                                                              }));
                                                }),
                                              size: "tiny",
                                              style: "ghost"
                                            })), React.createElement("div", {
                                          className: matchCell
                                        }, React.createElement($$Text.make, {
                                              size: "Medium",
                                              color: Styles.Color.light12,
                                              children: match
                                            })), React.createElement("div", {
                                          className: matchCell
                                        }, React.createElement($$Text.make, {
                                              size: "Medium",
                                              weight: "Semi",
                                              color: Styles.Color.light11,
                                              children: "All event " + (
                                                isVariant ? " variant" : ""
                                              ) + " sources"
                                            })));
                        }))), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement(Mantine.Popover.make, {
                  offset: 4,
                  position: "bottom-start",
                  children: null
                }, React.createElement(Core.Popover.Target, {
                      children: React.createElement(Button.make, {
                            icon: "plus",
                            label: "Add value",
                            size: "small",
                            style: Caml_obj.caml_equal(existingMatches, []) ? "outline" : "ghost"
                          })
                    }), React.createElement(Mantine.Popover.Dropdown.make, {
                      children: React.createElement(EditEventSpecificPropertyValuesModal$AddValuePopup, {
                            onCreate: (function (value) {
                                return Curry._1(setNewMatches, (function (newMatches) {
                                              return Belt_MapString.set(newMatches, value, undefined);
                                            }));
                              }),
                            canCreate: (function (value) {
                                if (Belt_MapString.has(newMatches, value)) {
                                  return false;
                                } else {
                                  return Belt_Array.every(allMatchesOnProperty, (function (match) {
                                                return match !== value;
                                              }));
                                }
                              }),
                            options: Belt_SetString.toArray(Belt_SetString.diff(Belt_SetString.diff(Belt_SetString.fromArray(allMatchesOnProperty), Belt_SetString.fromArray(Belt_Array.map(existingMatches, (function (prim) {
                                                    return prim[0];
                                                  })))), Belt_SetString.fromArray(Belt_MapString.keysToArray(newMatches)))),
                            onAdd: (function (value) {
                                return Curry._1(setNewMatches, (function (newMatches) {
                                              return Belt_MapString.set(newMatches, value, undefined);
                                            }));
                              })
                          })
                    })), tmp, React.createElement("div", {
                  className: buttons
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        }),
                      size: "large",
                      style: "ghost"
                    }), React.createElement(Button.make, {
                      disabled: saveDisabled,
                      label: "Save changes",
                      onClick: handleSave,
                      size: "large"
                    })));
}

var make = EditEventSpecificPropertyValuesModal;

export {
  make ,
  
}
/* root Not a pure module */
