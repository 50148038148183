// Generated by ReScript, PLEASE EDIT WITH CARE


var clientId = "77143576753.8047360626835";

var redirectUri = "https://www.avo.app/api/slack/oauth";

var scopes = "incoming-webhook,users:read,users:read.email";

var Dev = {
  clientId: clientId,
  redirectUri: redirectUri,
  scopes: scopes
};

var clientId$1 = "77143576753.1410806608161";

var redirectUri$1 = "https://www.avo.app/api/slack/oauth";

var scopes$1 = "incoming-webhook";

var Prod = {
  clientId: clientId$1,
  redirectUri: redirectUri$1,
  scopes: scopes$1
};

function getClientId(dev) {
  if (dev) {
    return clientId;
  } else {
    return clientId$1;
  }
}

function getRedirectUri(dev) {
  if (dev) {
    return redirectUri;
  } else {
    return redirectUri$1;
  }
}

function getScopes(dev) {
  if (dev) {
    return scopes;
  } else {
    return scopes$1;
  }
}

export {
  Dev ,
  Prod ,
  getClientId ,
  getRedirectUri ,
  getScopes ,
  
}
/* No side effect */
