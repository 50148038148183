// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import Shortid from "shortid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchSelect from "./SearchSelect.mjs";
import * as Core from "@mantine/core";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as UniqueEventName from "./UniqueEventName.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as StringSimilarity from "string-similarity";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as EventNameComponent from "../../shared/models/EventNameComponent.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as GuardRailsConfigUtils from "./guardrails/GuardRailsConfigUtils.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as GetEventNamespaceUseCase from "./getEventNamespaceUseCase.mjs";
import * as GuardRailsConfigComponents from "./guardrails/GuardRailsConfigComponents.mjs";
import * as EventNameComponentCollection from "./EventNameComponentCollection.mjs";
import * as AdvancedNameInput__Validations from "./AdvancedNameInput__Validations.mjs";

var targetContainerStyles = Curry._1(Css.style, {
      hd: Css.label("name-component"),
      tl: {
        hd: Css.background("transparent"),
        tl: {
          hd: Css.margin(Css.px(-4)),
          tl: {
            hd: Css.padding(Css.px(4)),
            tl: {
              hd: Css.outline(Css.px(0), "solid", "transparent"),
              tl: {
                hd: Css.transitions({
                      hd: Css_Legacy_Core.Transition.shorthand({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "outline"),
                      tl: {
                        hd: Css_Legacy_Core.Transition.shorthand({
                              NAME: "ms",
                              VAL: Styles.Duration.$$short
                            }, undefined, undefined, "padding"),
                        tl: {
                          hd: Css_Legacy_Core.Transition.shorthand({
                                NAME: "ms",
                                VAL: Styles.Duration.$$short
                              }, undefined, undefined, "margin"),
                          tl: /* [] */0
                        }
                      }
                    }),
                tl: {
                  hd: Css.hover({
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.85)),
                            tl: {
                              hd: Css.outline(Css.px(1), "solid", Styles.Color.light08),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: {
                    hd: Css.focusWithin({
                          hd: Css.backgroundColor(Styles.Color.white),
                          tl: {
                            hd: Css.outline(Css.px(1), "solid", Styles.Color.light07),
                            tl: {
                              hd: Css.marginLeft(Css.px(0)),
                              tl: {
                                hd: Css.marginRight(Css.px(0)),
                                tl: {
                                  hd: Css.padding4(Css.px(4), Css.px(18), Css.px(4), Css.px(4)),
                                  tl: {
                                    hd: Css.selector(" .allowed-value__chevron-down", {
                                          hd: Css.opacity(1.0),
                                          tl: {
                                            hd: Css.width(Css.px(16)),
                                            tl: {
                                              hd: Css.pointerEvents("none"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var targetContentStyles = Curry._1(Css.style, {
      hd: Css.zIndex(0),
      tl: {
        hd: Css.padding("zero"),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: /* [] */0
        }
      }
    });

var targetOpenedStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.zIndex(1),
          tl: {
            hd: Css.outline(Css.px(1), "solid", Styles.Color.light08),
            tl: {
              hd: Css.outline(Css.px(1), "solid", Styles.Color.red),
              tl: {
                hd: Css.marginLeft(Css.px(0)),
                tl: {
                  hd: Css.marginRight(Css.px(0)),
                  tl: {
                    hd: Css.padding4(Css.px(4), Css.px(18), Css.px(4), Css.px(4)),
                    tl: {
                      hd: Css.selector(" .allowed-value__chevron-down", {
                            hd: Css.opacity(1.0),
                            tl: {
                              hd: Css.width(Css.px(16)),
                              tl: {
                                hd: Css.pointerEvents("none"),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var dropdownContainerStyles_0 = Css.transforms({
      hd: Css.translateY(Css.px(-6)),
      tl: {
        hd: Css.translateX(Css.px(-4)),
        tl: /* [] */0
      }
    });

var dropdownContainerStyles = {
  hd: dropdownContainerStyles_0,
  tl: /* [] */0
};

var buttonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("row"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.focus({
                              hd: Css.outlineWidth("zero"),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var chevronStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(-16)),
        tl: {
          hd: Css.top(Css.px(2)),
          tl: /* [] */0
        }
      }
    });

function EventsSheet__EditableNameCell$AllowedValuesSelect(Props) {
  var allowedValues = Props.allowedValues;
  var autoFocus = Props.autoFocus;
  var $$case = Props.case;
  var dropdownContainerRef = Props.dropdownContainerRef;
  var eventEdit = Props.eventEdit;
  var name = Props.name;
  var onChange = Props.onChange;
  var onEnter = Props.onEnter;
  var onEscape = Props.onEscape;
  var role = Props.role;
  var id = Props.id;
  var validation = Props.validation;
  var value = Props.value;
  var actionString = eventEdit === "Update" ? "updated" : "created";
  var buttonRef = React.useRef(null);
  var currentValue = value !== undefined ? (
      Belt_Array.every(allowedValues, (function (allowedValue) {
              return allowedValue !== value;
            })) && value.trim() !== "" ? [{
            value: value,
            label: value,
            selected: true,
            message: role === "Admin" ? "This value will be added to the predefined values list of \"" + name + "\" when the event is " + actionString + "." : "This value is not a valid \"" + name + "\" value.",
            valid: role === "Admin"
          }] : []
    ) : [];
  var allowedValues$1 = Belt_Array.map(Belt_SetString.toArray(Belt_SetString.fromArray(allowedValues)), (function (allowedValue) {
          return {
                  value: allowedValue,
                  label: allowedValue,
                  selected: Belt_Option.mapWithDefault(value, false, (function (value) {
                          return value === allowedValue;
                        })),
                  valid: true
                };
        }));
  var tmp = {
    items: Belt_Array.concat(currentValue, allowedValues$1),
    maxItemsBeforeOfferingSearch: 0,
    arbitraryChoice: role === "Admin" ? ({
          NAME: "withMessage",
          VAL: (function (searchInput) {
              return "\"" + searchInput + "\" will be added to the predefined values list of \"" + name + "\" when the event is " + actionString;
            })
        }) : ({
          NAME: "disabled",
          VAL: (function (param) {
              return "Only workspace admins can add allowed values to event name fields";
            })
        }),
    onChange: (function (v) {
        return Curry._1(onChange, v);
      }),
    targetContainerStyles: targetContainerStyles,
    targetContentStyles: targetContentStyles,
    targetOpenedStyles: targetOpenedStyles,
    dropdownContainerStyles: dropdownContainerStyles,
    dropdownItemStyles: (function (selected) {
        return Curry._1(Css.style, {
                    hd: Css.color(Styles.Color.light10),
                    tl: {
                      hd: selected ? Css.fontWeight(Styles.FontWeight.semi) : Styles.emptyStyle,
                      tl: /* [] */0
                    }
                  });
      }),
    children: React.createElement("button", {
          ref: buttonRef,
          className: buttonStyles,
          tabIndex: 0,
          autoFocus: autoFocus
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Medium",
              color: value !== undefined ? (
                  validation === "valid" || role === "Admin" ? Styles.Color.light11 : Styles.Color.darkOrange
                ) : Styles.Color.light07,
              children: Belt_Option.getWithDefault(value, Case.to_($$case, name))
            }), React.createElement("span", {
              className: chevronStyles + " allowed-value__chevron-down"
            }, React.createElement(Spacer.make, {
                  width: 4,
                  grow: 1.0
                }), React.createElement(Icon.make, {
                  type_: "chevronDown",
                  size: "small",
                  color: Styles.Color.light08
                })))
  };
  if (onEnter !== undefined) {
    tmp.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEscape !== undefined) {
    tmp.onEscape = Caml_option.valFromOption(onEscape);
  }
  if (dropdownContainerRef !== undefined) {
    tmp.dropdownContainerRef = Caml_option.valFromOption(dropdownContainerRef);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement(SearchSelect.make, tmp);
}

function EventsSheet__EditableNameCell$Component(Props) {
  var allowedValues = Props.allowedValues;
  var autoFocus = Props.autoFocus;
  var $$case = Props.case;
  var disabled = Props.disabled;
  var eventEdit = Props.eventEdit;
  var inputRef = Props.inputRef;
  var onPaste = Props.onPaste;
  var onEnter = Props.onEnter;
  var onEscape = Props.onEscape;
  var name = Props.name;
  var onChange = Props.onChange;
  var role = Props.role;
  var validation = Props.validation;
  var value = Props.value;
  var hasBlockedValue = Props.hasBlockedValue;
  var dropdownContainerRef = Props.dropdownContainerRef;
  var parentId = Props.parentId;
  var componentId = React.useRef(Shortid());
  if (allowedValues.length !== 0) {
    var tmp = {
      allowedValues: allowedValues,
      autoFocus: autoFocus,
      case: $$case,
      eventEdit: eventEdit,
      name: name,
      onChange: onChange,
      role: role,
      id: parentId + "-" + componentId.current,
      validation: validation,
      value: value !== undefined && value.trim() !== "" ? value : undefined
    };
    if (dropdownContainerRef !== undefined) {
      tmp.dropdownContainerRef = Caml_option.valFromOption(dropdownContainerRef);
    }
    if (onEnter !== undefined) {
      tmp.onEnter = Caml_option.valFromOption(onEnter);
    }
    if (onEscape !== undefined) {
      tmp.onEscape = Caml_option.valFromOption(onEscape);
    }
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.display("inlineBlock"),
                        tl: {
                          hd: Css.focusWithin({
                                hd: Css.zIndex(1),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }),
                onClick: (function (prim) {
                    prim.stopPropagation();
                    
                  })
              }, React.createElement(EventsSheet__EditableNameCell$AllowedValuesSelect, tmp));
  }
  var tmp$1 = {
    containerClassName: Curry._1(Css.style, {
          hd: Css.label("name-component"),
          tl: {
            hd: Css.display("inlineBlock"),
            tl: {
              hd: Css.margin(Css.px(-6)),
              tl: {
                hd: Css.marginTop(Css.px(-5)),
                tl: {
                  hd: Css.padding(Css.px(6)),
                  tl: {
                    hd: Css.paddingTop(Css.px(7)),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.focusWithin({
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.outline(Css.px(1), "solid", Styles.Color.light08),
                                tl: {
                                  hd: Css.margin2(Css.px(-6), Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: {
                          hd: Css.hover({
                                hd: Css.zIndex(1),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.85)),
                                  tl: {
                                    hd: Css.outline(Css.px(1), "solid", Styles.Color.light07),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          tl: {
                            hd: Css.outline("zero", "solid", "transparent"),
                            tl: {
                              hd: Css.marginBottom(Css.px(-20)),
                              tl: {
                                hd: Css.paddingBottom(Css.pxFloat(20.5)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }),
    className: Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.padding("zero"),
                tl: {
                  hd: Css.margin("zero"),
                  tl: {
                    hd: Css.left(Css.px(6)),
                    tl: {
                      hd: Css.top(Css.px(5)),
                      tl: {
                        hd: Css.fontSize(Css.px(14)),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }),
          tl: {
            hd: hasBlockedValue ? Curry._1(Css.style, {
                    hd: Css.textDecorationLine("underline"),
                    tl: {
                      hd: Css.textDecorationStyle("wavy"),
                      tl: {
                        hd: Css.textDecorationColor(Styles.Color.avoPink),
                        tl: {
                          hd: Css.unsafe("textDecorationThickness", "0.8px"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }) : Curry._1(Css.style, /* [] */0),
            tl: /* [] */0
          }
        }),
    height: 24,
    onBlur: (function (param) {
        if (value !== undefined) {
          return Curry._1(onChange, Case.to_($$case, value));
        }
        
      }),
    onChange: (function (newValue) {
        if (newValue !== undefined) {
          return Curry._1(onChange, GuardRailsConfigUtils.getCasedValue($$case, newValue));
        } else {
          return Curry._1(onChange, newValue);
        }
      }),
    onPaste: (function (e) {
        return Belt_Option.forEach(onPaste, (function (f) {
                      return Curry._1(f, (e.clipboardData.getData('text/plain')));
                    }));
      }),
    placeholder: Case.to_($$case, name),
    value: Belt_Option.getWithDefault(value, ""),
    autoFocus: autoFocus
  };
  if (disabled !== undefined) {
    tmp$1.disabled = Caml_option.valFromOption(disabled);
  }
  if (inputRef !== undefined) {
    tmp$1.inputRef = Caml_option.valFromOption(inputRef);
  }
  if (onEnter !== undefined) {
    tmp$1.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEscape !== undefined) {
    tmp$1.onEscape = Caml_option.valFromOption(onEscape);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("inlineBlock"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.marginBottom(Css.px(-2)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingRight(Css.px(0)),
                        tl: /* [] */0
                      }),
                  onClick: (function (prim) {
                      prim.stopPropagation();
                      
                    })
                }, React.createElement(GuardRailsConfigComponents.AutoGrowingInput.make, tmp$1)));
}

function isAllowedValueValid(componentValue, allowedValues) {
  if (allowedValues.length !== 0) {
    return Belt_Array.some(allowedValues, (function (allowedValue) {
                  return allowedValue === componentValue;
                }));
  } else {
    return true;
  }
}

function isBlockedValue(componentValue, blockedValues) {
  if (blockedValues.length === 0) {
    return false;
  } else {
    return Belt_Array.someU(blockedValues, (function (blockedValue) {
                  return componentValue.includes(blockedValue);
                }));
  }
}

function getInputValidation(componentValue, $$case, allowedValues, blockedValues) {
  if (componentValue === undefined) {
    return "empty";
  }
  if (componentValue.trim() === "") {
    return "empty";
  }
  if (allowedValues.length === 0 && blockedValues.length === 0) {
    if (componentValue.trim() !== componentValue) {
      return "includesTrailingWhitespace";
    } else if (NameUtils.isCorrectCase($$case, componentValue)) {
      return "valid";
    } else {
      return "invalidCase";
    }
  }
  if (blockedValues.length === 0) {
    if (isAllowedValueValid(componentValue, allowedValues)) {
      return "valid";
    } else {
      return "allowedValuesConflict";
    }
  }
  if (!NameUtils.isCorrectCase($$case, componentValue)) {
    return "invalidCase";
  }
  var blockedWordsUsed = Belt_Array.keepMapU(blockedValues, (function (blockedValue) {
          if (componentValue.includes(blockedValue)) {
            return blockedValue;
          }
          
        }));
  if (blockedWordsUsed.length !== 0) {
    return {
            NAME: "blockedValueConflict",
            VAL: blockedWordsUsed
          };
  } else {
    return "valid";
  }
}

function EventsSheet__EditableNameCell$Components(Props) {
  var setContainerRef = Props.setContainerRef;
  var values = Props.values;
  var indexWithAutoFocus = Props.indexWithAutoFocus;
  var firstComponentRef = Props.firstComponentRef;
  var onEnter = Props.onEnter;
  var onEscape = Props.onEscape;
  var setValues = Props.setValues;
  var role = Props.role;
  var setDropdownContainerRefs = Props.setDropdownContainerRefs;
  var parentId = Props.parentId;
  var sessionIdentifier = Props.sessionIdentifier;
  return React.createElement("div", {
              ref: setContainerRef,
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  })
            }, Belt_Array.mapWithIndex(values, (function (index, value) {
                    if (value.NAME === "separator") {
                      return React.createElement("div", {
                                  key: String(index),
                                  className: Curry._1(Css.style, {
                                        hd: Css.unsafe("whiteSpace", "preserve"),
                                        tl: /* [] */0
                                      }),
                                  id: parentId + "-separator-" + String(index)
                                }, React.createElement($$Text.make, {
                                      element: "Span",
                                      size: "Medium",
                                      weight: "Semi",
                                      color: Styles.Color.light08,
                                      children: React.createElement("span", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.letterSpacing(Css.px(1)),
                                                  tl: /* [] */0
                                                })
                                          }, value.VAL)
                                    }));
                    }
                    var match = value.VAL;
                    var blockedValues = match.blockedValues;
                    var allowedValues = match.allowedValues;
                    var $$case = match.case;
                    var value$1 = match.value;
                    var name = match.name;
                    var tmp = {
                      allowedValues: allowedValues,
                      autoFocus: Belt_Option.mapWithDefault(indexWithAutoFocus, false, (function (i) {
                              return i === index;
                            })),
                      case: $$case,
                      eventEdit: "Update",
                      onEnter: onEnter,
                      onEscape: onEscape,
                      name: name,
                      onChange: (function (newValue) {
                          return Curry._1(setValues, (function (currentValues) {
                                        return Belt_Array.mapWithIndex(currentValues, (function (i, value) {
                                                      if (index !== i) {
                                                        return value;
                                                      }
                                                      if (value.NAME === "separator") {
                                                        return value;
                                                      }
                                                      var buildingBlock = value.VAL;
                                                      return {
                                                              NAME: "nameBuildingBlock",
                                                              VAL: {
                                                                name: buildingBlock.name,
                                                                value: newValue,
                                                                validation: getInputValidation(newValue, $$case, allowedValues, blockedValues),
                                                                case: buildingBlock.case,
                                                                allowedValues: buildingBlock.allowedValues,
                                                                blockedValues: buildingBlock.blockedValues
                                                              }
                                                            };
                                                    }));
                                      }));
                        }),
                      role: role,
                      validation: match.validation,
                      value: value$1,
                      hasBlockedValue: value$1 !== undefined ? isBlockedValue(value$1, blockedValues) : false,
                      parentId: parentId + "-component-" + String(index),
                      key: String(index) + sessionIdentifier.current
                    };
                    var tmp$1 = Belt_Option.mapWithDefault(indexWithAutoFocus, undefined, (function (i) {
                            if (i === index) {
                              return firstComponentRef;
                            }
                            
                          }));
                    if (tmp$1 !== undefined) {
                      tmp.inputRef = Caml_option.valFromOption(tmp$1);
                    }
                    var tmp$2 = Belt_Option.map(setDropdownContainerRefs, (function (cb) {
                            return Curry._1(cb, "component-ref-" + name);
                          }));
                    if (tmp$2 !== undefined) {
                      tmp.dropdownContainerRef = Caml_option.valFromOption(tmp$2);
                    }
                    return React.createElement(EventsSheet__EditableNameCell$Component, tmp);
                  })));
}

function getNameFromStructure(values) {
  return Belt_Array.map(values, (function (value) {
                  if (value.NAME === "separator") {
                    return value.VAL;
                  } else {
                    return Belt_Option.getWithDefault(value.VAL.value, "");
                  }
                })).join("");
}

function getSimpleNameFromStructure(values) {
  if (Belt_Array.every(values, (function (value) {
            if (value.NAME === "separator") {
              return true;
            }
            var match = value.VAL.value;
            return match !== undefined && match !== "" ? false : true;
          }))) {
    return "";
  } else {
    return getNameFromStructure(values);
  }
}

function getCurrentAllowedValues(values) {
  return Belt_Array.keepMap(values, (function (value) {
                if (typeof value !== "object") {
                  return ;
                }
                if (value.NAME !== "nameBuildingBlock") {
                  return ;
                }
                var match = value.VAL;
                var value$1 = match.value;
                if (value$1 !== undefined && match.allowedValues.length !== 0) {
                  return value$1;
                }
                
              }));
}

function onSave(nameComponents, guardrailsEnabled, name, schemaId, values, onChange, onError, onReset, param) {
  var match;
  if (guardrailsEnabled) {
    var updatedEventName = Belt_Array.map(values, (function (value) {
              if (value.NAME === "separator") {
                return value.VAL;
              } else {
                return Belt_Option.getWithDefault(value.VAL.value, "");
              }
            })).join("");
    var eventNameComponents = Belt_Array.keepMap(values, (function (value) {
            if (value.NAME === "separator") {
              return ;
            }
            var match = value.VAL;
            return EventNameComponent.make(match.name, Belt_Option.getWithDefault(match.value, ""));
          }));
    match = [
      eventNameComponents,
      updatedEventName
    ];
  } else {
    match = [
      EventNameComponent.getEventNameComponentFromName(name, nameComponents),
      name
    ];
  }
  var eventName = match[1];
  var eventNameComponents$1 = match[0];
  if (eventNameComponents$1 !== undefined) {
    return EventNameComponentCollection.setEventNameComponent(Firebase.app(undefined).firestore(), schemaId, eventName, eventNameComponents$1, ValidationConfigV2.NameComponent.getNameConfigAsString(nameComponents)).then(function (res) {
                if (res.TAG === /* Ok */0) {
                  return Promise.resolve(Curry._4(onChange, eventName, eventNameComponents$1, nameComponents, onReset));
                } else {
                  return Promise.resolve(Curry._1(onError, res._0));
                }
              });
  } else {
    return Promise.resolve(Curry._4(onChange, eventName, [], nameComponents, onReset));
  }
}

function EventsSheet__EditableNameCell(Props) {
  var columnWidth = Props.columnWidth;
  var currentFilters = Props.currentFilters;
  var eventId = Props.eventId;
  var currentEventName = Props.eventName;
  var nameComponents = Props.nameComponents;
  var onExitEditing = Props.onExitEditing;
  var onSaveOpt = Props.onSave;
  var schemaBundle = Props.schemaBundle;
  var currentStructure = Props.structure;
  var onSave$1 = onSaveOpt !== undefined ? onSaveOpt : onSave;
  var componentId = React.useRef("inline-creation-container-" + Shortid());
  var $$new = currentEventName === undefined;
  var eventName = Belt_Option.getWithDefault(currentEventName, "");
  var role = WorkspaceContext.useMemberRole(undefined);
  var sessionIdentifier = React.useRef(Shortid());
  var sendActions = SendActionsContext.use(undefined);
  var hasBasicNameCasing = ValidationConfigV2.NameComponent.hasBasicNameCasing(nameComponents);
  var model = ModelStore.useModel(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var events = ModelStore.Mapped.useEvents(undefined);
  var match$1 = React.useState(function () {
        var currentStructure$1 = currentStructure !== undefined ? currentStructure : Belt_Option.flatMap(currentEventName, (function (name) {
                  return EventNameComponent.getEventNameComponentFromName(name, nameComponents);
                }));
        return Belt_Array.map(nameComponents, (function (nameComponent) {
                      if (nameComponent.NAME === "separator") {
                        return {
                                NAME: "separator",
                                VAL: nameComponent.VAL
                              };
                      }
                      var match = nameComponent.VAL;
                      var blockedValues = match.blockedValues;
                      var allowedValues = match.allowedValues;
                      var $$case = match.case;
                      var name = match.name;
                      var value = Belt_Option.flatMap(currentStructure$1, (function (eventNameComponents) {
                              var component = Belt_Array.getBy(eventNameComponents, (function (component) {
                                      return component.componentName === name;
                                    }));
                              return Belt_Option.map(component, (function (component) {
                                            return component.componentValue;
                                          }));
                            }));
                      return {
                              NAME: "nameBuildingBlock",
                              VAL: {
                                name: name,
                                value: value,
                                validation: getInputValidation(value, Curry._1(ValidationConfigV2.Value.getCase, $$case.value), Curry._1(ValidationConfigV2.Value.getAllowedValues, allowedValues.value), Curry._1(ValidationConfigV2.Value.getBlockedValues, blockedValues.value)),
                                case: Curry._1(ValidationConfigV2.Value.getCase, $$case.value),
                                allowedValues: Curry._1(ValidationConfigV2.Value.getAllowedValues, allowedValues.value),
                                blockedValues: Curry._1(ValidationConfigV2.Value.getBlockedValues, blockedValues.value)
                              }
                            };
                    }));
      });
  var setValues = match$1[1];
  var values = match$1[0];
  var initialValues = React.useRef(values);
  var match$2 = React.useState(function () {
        return getSimpleNameFromStructure(values);
      });
  var setSimpleValue = match$2[1];
  var simpleValue = match$2[0];
  var firstComponentRef = React.useRef(null);
  var enforcesUniqueNames = Curry._1(ValidationConfigV2.Rule.isEnforced, config.events.uniqueName);
  var handleSave = React.useMemo((function () {
          return function (param) {
            var updatedEventName = Belt_Array.map(values, (function (value) {
                      if (value.NAME === "separator") {
                        return value.VAL;
                      } else {
                        return Belt_Option.getWithDefault(value.VAL.value, "");
                      }
                    })).join("");
            if (Caml_obj.caml_notequal(values, initialValues.current) && updatedEventName.trim() !== "" || hasBasicNameCasing && Caml_obj.caml_notequal(simpleValue, currentEventName) && Belt_Option.mapWithDefault(simpleValue, "", (function (prim) {
                      return prim.trim();
                    })) !== "") {
              Curry.app(onSave$1, [
                    nameComponents,
                    !hasBasicNameCasing,
                    Belt_Option.getWithDefault(simpleValue, ""),
                    schemaBundle.schemaId,
                    values,
                    (function (name, _eventNameComponents, nameComponents, _onReset) {
                        var match = ValidationConfigV2.NameComponent.getCase(nameComponents);
                        var simpleCase = typeof match === "object" ? match.VAL : "None";
                        var commonCases = ValidationConfigV2.NameComponent.findCommonCaseValues(Belt_Array.concat([ValidationConfigV2.NameComponent.getCaseVariances([name])], [[simpleCase]]));
                        var _case = Belt_Array.getBy(commonCases, (function (commonCase) {
                                return commonCase === simpleCase;
                              }));
                        var correctCase = _case !== undefined ? true : undefined;
                        var uniqueName = UniqueEventName.getUniqueName(events, eventId, name);
                        var actions = Belt_Array.concat([[
                                {
                                  NAME: "UpdateEventName",
                                  VAL: [
                                    eventId,
                                    name
                                  ]
                                },
                                {
                                  eventId: eventId,
                                  eventQuery: eventId
                                }
                              ]], uniqueName !== undefined ? [[
                                  {
                                    NAME: "UpdateEventUniqueNameV2",
                                    VAL: [
                                      eventId,
                                      uniqueName
                                    ]
                                  },
                                  {
                                    eventId: eventId,
                                    eventQuery: eventId
                                  }
                                ]] : []);
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (param) {
                                        if (ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events)) {
                                          var arg = simpleCase === "None" ? undefined : Case.toAnalytics(Case.getCasingWithWorkspaceCase(name, simpleCase));
                                          var arg$1 = name.length;
                                          var arg$2 = Case.to_("Sentence", name).split(" ").length;
                                          var arg$3 = schemaBundle.branchId;
                                          var arg$4 = schemaBundle.schemaId;
                                          return AnalyticsUtils.withCurrentFilters(AnalyticsUtils.withSchemaBundle((function (param) {
                                                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31) {
                                                              return AnalyticsRe.eventNameUpdated(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31);
                                                            };
                                                            return function (param) {
                                                              var func$1 = Curry._1(func, param);
                                                              return function (param) {
                                                                var func$2 = Curry._1(func$1, param);
                                                                return function (param) {
                                                                  var func$3 = Curry._1(func$2, param);
                                                                  return function (param) {
                                                                    var func$4 = Curry._1(func$3, param);
                                                                    return function (param) {
                                                                      var func$5 = Curry._1(func$4, param);
                                                                      return function (param) {
                                                                        var func$6 = Curry._1(func$5, param);
                                                                        return function (param) {
                                                                          var func$7 = Curry._1(func$6, param);
                                                                          return function (param) {
                                                                            var func$8 = Curry._1(func$7, param);
                                                                            return function (param) {
                                                                              var func$9 = Curry._1(func$8, param);
                                                                              return function (param) {
                                                                                var func$10 = Curry._3(func$9, param, eventId, eventName);
                                                                                return function (param) {
                                                                                  var func$11 = Curry._1(func$10, param);
                                                                                  return function (param) {
                                                                                    var func$12 = Curry._1(func$11, param);
                                                                                    return function (param) {
                                                                                      var func$13 = Curry._1(func$12, param);
                                                                                      return function (param) {
                                                                                        var func$14 = Curry._1(func$13, param);
                                                                                        return function (param) {
                                                                                          var func$15 = Curry._1(func$14, param);
                                                                                          return function (param) {
                                                                                            var func$16 = Curry._1(func$15, param);
                                                                                            return function (param) {
                                                                                              var func$17 = Curry._1(func$16, param);
                                                                                              return function (param) {
                                                                                                return Curry.app(func$17, [
                                                                                                            param,
                                                                                                            arg,
                                                                                                            currentEventName,
                                                                                                            arg$1,
                                                                                                            arg$2,
                                                                                                            correctCase,
                                                                                                            "InlineEventsView",
                                                                                                            eventId,
                                                                                                            arg$3,
                                                                                                            arg$4,
                                                                                                            eventId,
                                                                                                            eventName
                                                                                                          ]);
                                                                                              };
                                                                                            };
                                                                                          };
                                                                                        };
                                                                                      };
                                                                                    };
                                                                                  };
                                                                                };
                                                                              };
                                                                            };
                                                                          };
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          }), schemaBundle), currentFilters);
                                        }
                                        var arg$5 = name.length;
                                        var arg$6 = Case.to_("Sentence", name).split(" ").length;
                                        var arg$7 = NameUtils.isCorrectCase(expectedEventCase, name);
                                        var arg$8 = ValidationConfigV2.EventsV2.getNamesAsStructuredString(config.events);
                                        var arg$9 = ValidationConfigV2.EventsV2.getComponentCasesAsStructuredString(config.events);
                                        var arg$10 = schemaBundle.branchId;
                                        var arg$11 = schemaBundle.schemaId;
                                        return AnalyticsUtils.withCurrentFilters(AnalyticsUtils.withSchemaBundle((function (param) {
                                                          var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31, param$32) {
                                                            return AnalyticsRe.eventNameUpdatedAdvancedStructure(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31, param$32);
                                                          };
                                                          return function (param) {
                                                            var func$1 = Curry._1(func, param);
                                                            return function (param) {
                                                              var func$2 = Curry._1(func$1, param);
                                                              return function (param) {
                                                                var func$3 = Curry._1(func$2, param);
                                                                return function (param) {
                                                                  var func$4 = Curry._1(func$3, param);
                                                                  return function (param) {
                                                                    var func$5 = Curry._1(func$4, param);
                                                                    return function (param) {
                                                                      var func$6 = Curry._1(func$5, param);
                                                                      return function (param) {
                                                                        var func$7 = Curry._1(func$6, param);
                                                                        return function (param) {
                                                                          var func$8 = Curry._1(func$7, param);
                                                                          return function (param) {
                                                                            var func$9 = Curry._1(func$8, param);
                                                                            return function (param) {
                                                                              var func$10 = Curry._3(func$9, param, eventId, name);
                                                                              return function (param) {
                                                                                var func$11 = Curry._1(func$10, param);
                                                                                return function (param) {
                                                                                  var func$12 = Curry._1(func$11, param);
                                                                                  return function (param) {
                                                                                    var func$13 = Curry._1(func$12, param);
                                                                                    return function (param) {
                                                                                      var func$14 = Curry._1(func$13, param);
                                                                                      return function (param) {
                                                                                        var func$15 = Curry._1(func$14, param);
                                                                                        return function (param) {
                                                                                          var func$16 = Curry._1(func$15, param);
                                                                                          return function (param) {
                                                                                            var func$17 = Curry._1(func$16, param);
                                                                                            return function (param) {
                                                                                              return Curry.app(func$17, [
                                                                                                          param,
                                                                                                          undefined,
                                                                                                          arg$5,
                                                                                                          arg$6,
                                                                                                          arg$7,
                                                                                                          "EventDetails",
                                                                                                          arg$8,
                                                                                                          arg$9,
                                                                                                          eventId,
                                                                                                          arg$10,
                                                                                                          arg$11,
                                                                                                          eventId,
                                                                                                          name
                                                                                                        ]);
                                                                                            };
                                                                                          };
                                                                                        };
                                                                                      };
                                                                                    };
                                                                                  };
                                                                                };
                                                                              };
                                                                            };
                                                                          };
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        }), schemaBundle), currentFilters);
                                      }),
                                    undefined,
                                    actions
                                  ]);
                      }),
                    (function (param) {
                        
                      }),
                    (function (param) {
                        
                      }),
                    param
                  ]);
            }
            return Curry._1(onExitEditing, undefined);
          };
        }), [
        values,
        simpleValue,
        config,
        nameComponents,
        hasBasicNameCasing,
        currentEventName
      ]);
  var match$3 = Hooks.useElementRefs(undefined, undefined);
  var addRef = match$3[1];
  React.useEffect((function () {
          var handler = function (e) {
            var target = e.target;
            var isContained = Belt_Array.some(document.querySelectorAll("[id^=\"" + componentId.current + "\"]"), (function (el) {
                    return el.contains(target);
                  }));
            if (!isContained) {
              return Curry._1(handleSave, false);
            }
            
          };
          window.addEventListener("click", handler);
          window.addEventListener("focusin", handler);
          return (function (param) {
                    window.removeEventListener("click", handler);
                    window.removeEventListener("focusin", handler);
                    
                  });
        }), [handleSave]);
  var match$4 = React.useState(function () {
        return false;
      });
  var setInvalidAllowedValuesConfirmed = match$4[1];
  var nameFromStructure = React.useMemo((function () {
          return getNameFromStructure(values);
        }), [values]);
  var existingNamesWithIdAndIsVariant = GetEventNamespaceUseCase.getEventNamespaceWithIdAndIsVariant(model.events);
  var allFieldsFilled = React.useMemo((function () {
          if (hasBasicNameCasing) {
            return Belt_Option.mapWithDefault(simpleValue, false, (function (value) {
                          return value.trim().length > 0;
                        }));
          } else {
            return Belt_Array.every(values, (function (value) {
                          if (value.NAME === "separator") {
                            return true;
                          } else {
                            return value.VAL.validation !== "empty";
                          }
                        }));
          }
        }), [
        values,
        hasBasicNameCasing,
        simpleValue
      ]);
  var existingNames = React.useMemo((function () {
          return Belt_List.keepU(Belt_List.map(existingNamesWithIdAndIsVariant, (function (param) {
                            return param[0];
                          })), (function (name) {
                        return name !== eventName;
                      }));
        }), [existingNamesWithIdAndIsVariant]);
  var conflictingName = React.useMemo((function () {
          return NameUtils.getConflictingNames(existingNames, hasBasicNameCasing ? Belt_Option.getWithDefault(simpleValue, "") : nameFromStructure);
        }), [
        existingNames,
        nameFromStructure,
        simpleValue,
        hasBasicNameCasing
      ]);
  var blockedValuesUsed = React.useMemo((function () {
          if (hasBasicNameCasing) {
            return [];
          } else {
            return Belt_Array.keepMap(values, (function (value) {
                          if (value.NAME === "separator") {
                            return ;
                          }
                          var match = value.VAL;
                          var match$1 = match.validation;
                          if (typeof match$1 === "object" && match$1.NAME === "blockedValueConflict") {
                            return [
                                    match.name,
                                    match$1.VAL
                                  ];
                          }
                          
                        }));
          }
        }), [
        values,
        hasBasicNameCasing
      ]);
  var match$5 = React.useState(function () {
        return getCurrentAllowedValues(values);
      });
  var setChosenAllowedValues = match$5[1];
  var chosenAllowedValues = match$5[0];
  React.useEffect((function () {
          var newChosenAllowedValues = getCurrentAllowedValues(values);
          if (!Belt_Array.eq(newChosenAllowedValues, chosenAllowedValues, (function (a, b) {
                    return a === b;
                  }))) {
            Curry._1(setChosenAllowedValues, (function (param) {
                    return newChosenAllowedValues;
                  }));
          }
          
        }), [values]);
  var invalidAllowedValues = React.useMemo((function () {
          if (hasBasicNameCasing) {
            return ;
          } else {
            return Belt_Array.keepMap(values, (function (value) {
                          if (value.NAME === "separator") {
                            return ;
                          }
                          var buildingBlock = value.VAL;
                          if (buildingBlock.validation === "allowedValuesConflict") {
                            return buildingBlock;
                          }
                          
                        }));
          }
        }), [
        hasBasicNameCasing,
        chosenAllowedValues
      ]);
  React.useEffect((function () {
          Curry._1(setInvalidAllowedValuesConfirmed, (function (param) {
                  return false;
                }));
          
        }), [invalidAllowedValues]);
  var similarNames = React.useMemo((function () {
          return Belt_List.mapU(Belt_List.sortU(Belt_List.keepU(Belt_List.mapU(existingNames, (function (name) {
                                    return [
                                            name,
                                            StringSimilarity.compareTwoStrings(name, hasBasicNameCasing ? Belt_Option.getWithDefault(simpleValue, "") : nameFromStructure)
                                          ];
                                  })), (function (param) {
                                return param[1] >= 0.8;
                              })), (function (param, param$1) {
                            var b = param$1[1];
                            var a = param[1];
                            if (a > b) {
                              return 1;
                            } else if (a < b) {
                              return -1;
                            } else {
                              return 0;
                            }
                          })), (function (param) {
                        return param[0];
                      }));
        }), [
        existingNames,
        nameFromStructure,
        simpleValue,
        hasBasicNameCasing
      ]);
  var tmp;
  if (hasBasicNameCasing) {
    tmp = React.createElement("div", {
          onBlur: (function (param) {
              return Curry._1(onExitEditing, undefined);
            }),
          onClick: (function (prim) {
              prim.stopPropagation();
              
            })
        }, React.createElement(GuardRailsConfigComponents.AutoGrowingInput.make, {
              containerClassName: Curry._1(Css.style, {
                    hd: Css.display("inlineBlock"),
                    tl: {
                      hd: Css.margin(Css.px(-6)),
                      tl: {
                        hd: Css.marginTop(Css.px(-5)),
                        tl: {
                          hd: Css.padding(Css.px(6)),
                          tl: {
                            hd: Css.paddingTop(Css.px(7)),
                            tl: {
                              hd: Css.focusWithin({
                                    hd: Css.backgroundColor(Styles.Color.white),
                                    tl: {
                                      hd: Css.outline(Css.px(0), "solid", Styles.Color.light07),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: Css.outline("zero", "solid", "transparent"),
                                tl: {
                                  hd: Css.paddingBottom(Css.pxFloat(24.5)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              className: Curry._1(Css.style, {
                    hd: Css.padding("zero"),
                    tl: {
                      hd: Css.margin("zero"),
                      tl: {
                        hd: Css.left(Css.px(6)),
                        tl: {
                          hd: Css.top(Css.pxFloat(6.0)),
                          tl: {
                            hd: Css.fontSize(Css.px(14)),
                            tl: {
                              hd: Css.letterSpacing(Css.em(-0.006)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.medium),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              height: 24,
              inputRef: firstComponentRef,
              onChange: (function (maybeValue) {
                  return Belt_Option.forEach(maybeValue, (function (value) {
                                return Curry._1(setSimpleValue, (function (param) {
                                              return value;
                                            }));
                              }));
                }),
              onEnter: (function (param) {
                  return Curry._1(handleSave, true);
                }),
              onEscape: (function (param) {
                  if ($$new) {
                    return Curry._1(onExitEditing, undefined);
                  } else {
                    return Curry._1(handleSave, true);
                  }
                }),
              placeholder: "Event name",
              value: Belt_Option.getWithDefault(simpleValue, ""),
              autoFocus: true
            }));
  } else if (currentStructure === undefined && !$$new) {
    tmp = React.createElement("div", undefined, React.createElement("div", {
              ref: Curry._1(addRef, "disabled-component")
            }, React.createElement(GuardRailsConfigComponents.AutoGrowingInput.make, {
                  containerClassName: Curry._1(Css.style, {
                        hd: Css.label("name-component"),
                        tl: {
                          hd: Css.display("inlineBlock"),
                          tl: {
                            hd: Css.margin(Css.px(-6)),
                            tl: {
                              hd: Css.marginTop(Css.px(-5)),
                              tl: {
                                hd: Css.padding(Css.px(6)),
                                tl: {
                                  hd: Css.paddingTop(Css.px(7)),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "all"),
                                    tl: {
                                      hd: Css.focusWithin({
                                            hd: Css.backgroundColor(Styles.Color.white),
                                            tl: {
                                              hd: Css.outline(Css.px(1), "solid", Styles.Color.light08),
                                              tl: {
                                                hd: Css.margin2(Css.px(-6), Css.px(0)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.zIndex(1),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.85)),
                                                tl: {
                                                  hd: Css.outline(Css.px(1), "solid", Styles.Color.light07),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }),
                                        tl: {
                                          hd: Css.outline("zero", "solid", "transparent"),
                                          tl: {
                                            hd: Css.marginBottom(Css.px(-20)),
                                            tl: {
                                              hd: Css.paddingBottom(Css.pxFloat(20.5)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, {
                              hd: Css.padding("zero"),
                              tl: {
                                hd: Css.margin("zero"),
                                tl: {
                                  hd: Css.left(Css.px(6)),
                                  tl: {
                                    hd: Css.top(Css.px(5)),
                                    tl: {
                                      hd: Css.fontSize(Css.px(14)),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }),
                  disabled: true,
                  height: 24,
                  onChange: (function (param) {
                      
                    }),
                  onEscape: (function (param) {
                      return Curry._1(handleSave, true);
                    }),
                  placeholder: "",
                  value: Belt_Option.getWithDefault(currentEventName, ""),
                  autoFocus: false
                })));
  } else {
    var indexWithAutoFocus = Belt_Array.getIndexBy(values, (function (value) {
            return value.NAME !== "separator";
          }));
    tmp = React.createElement("div", {
          ref: Curry._1(addRef, "components")
        }, React.createElement(EventsSheet__EditableNameCell$Components, {
              setContainerRef: Curry._1(addRef, "components-container"),
              values: values,
              indexWithAutoFocus: indexWithAutoFocus,
              firstComponentRef: firstComponentRef,
              onEnter: (function (param) {
                  return Curry._1(handleSave, true);
                }),
              onEscape: (function (param) {
                  if ($$new) {
                    return Curry._1(onExitEditing, undefined);
                  } else {
                    return Curry._1(handleSave, true);
                  }
                }),
              setValues: setValues,
              role: role,
              setDropdownContainerRefs: addRef,
              parentId: componentId.current,
              sessionIdentifier: sessionIdentifier
            }));
  }
  return React.createElement(Mantine.Popover.make, {
              dropdownStyles: {
                hd: Css.backgroundColor("transparent"),
                tl: /* [] */0
              },
              opened: true,
              position: "bottom-start",
              id: componentId.current,
              children: null
            }, React.createElement(Core.Popover.Target, {
                  children: tmp
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  children: React.createElement("div", undefined, React.createElement("div", {
                            ref: Curry._1(addRef, "validation-container"),
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: {
                                      hd: Css.gap(Css.px(4)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.white),
                                        tl: {
                                          hd: Css.padding(Css.px(16)),
                                          tl: {
                                            hd: Css.paddingBottom(Css.px(8)),
                                            tl: {
                                              hd: Css.border(Css.px(1), "solid", Styles.Color.light06),
                                              tl: {
                                                hd: Css.borderBottomLeftRadius(Css.px(8)),
                                                tl: {
                                                  hd: Css.borderBottomRightRadius(Css.px(8)),
                                                  tl: {
                                                    hd: Css.transforms({
                                                          hd: Css.translate(Css.px($$new ? -39 : -37), Css.px($$new ? 6 : 7)),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: {
                                                      hd: Css.width(Css.px(columnWidth)),
                                                      tl: {
                                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(24), Css.px(32), Css.px(0), undefined, Css.rgba(0, 0, 0, {
                                                                      NAME: "num",
                                                                      VAL: 0.12
                                                                    }))),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            onClick: (function (prim) {
                                prim.stopPropagation();
                                
                              })
                          }, currentStructure === undefined && !$$new ? React.createElement("div", {
                                  ref: Curry._1(addRef, "validation-components")
                                }, React.createElement(EventsSheet__EditableNameCell$Components, {
                                      setContainerRef: Curry._1(addRef, "validation-components-container"),
                                      values: values,
                                      indexWithAutoFocus: 0,
                                      firstComponentRef: firstComponentRef,
                                      onEnter: (function (param) {
                                          return Curry._1(handleSave, true);
                                        }),
                                      onEscape: (function (param) {
                                          return Curry._1(onExitEditing, undefined);
                                        }),
                                      setValues: setValues,
                                      role: role,
                                      setDropdownContainerRefs: addRef,
                                      parentId: componentId.current,
                                      sessionIdentifier: sessionIdentifier
                                    }), React.createElement(Spacer.make, {
                                      height: 16
                                    }), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.borderRadius(Css.px(4)),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.lightOrange),
                                              tl: {
                                                hd: Css.padding(Css.px(8)),
                                                tl: {
                                                  hd: Css.marginBottom(Css.px(8)),
                                                  tl: {
                                                    hd: Css.display("flex"),
                                                    tl: {
                                                      hd: Css.alignItems("flexStart"),
                                                      tl: {
                                                        hd: Css.gap(Css.px(8)),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    }, React.createElement(Icon.make, {
                                          type_: "warning",
                                          size: "small",
                                          color: Styles.Color.orange
                                        }), React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.maxWidth(Css.pct(100.0)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement($$Text.make, {
                                              size: "Small",
                                              weight: "Semi",
                                              color: Styles.Color.deepOrange,
                                              children: StringExt.widowless("Unable to get component values for event name")
                                            }), React.createElement($$Text.make, {
                                              size: "Small",
                                              color: Styles.Color.deepOrange,
                                              children: "Please update the event name to fit naming structure rules by manually filling in the component values"
                                            })))) : React.createElement(AdvancedNameInput__Validations.make, {
                                  allFieldsFilled: allFieldsFilled,
                                  guardrailsEnabled: !hasBasicNameCasing,
                                  conflictingName: conflictingName,
                                  highlightErrors: Belt_Option.isSome(currentEventName),
                                  getItemLink: (function (eventName) {
                                      var match = Belt_List.getByU(existingNamesWithIdAndIsVariant, (function (param) {
                                              return param[0] === eventName;
                                            }));
                                      if (match === undefined) {
                                        return ;
                                      }
                                      var variantEventId = match[1];
                                      if (!match[2]) {
                                        return Belt_Option.map(ModelUtils.getEventByName(eventName, model), (function (param) {
                                                      return Router.Link.addDrawerItem(undefined, {
                                                                  NAME: "event",
                                                                  VAL: [
                                                                    param.id,
                                                                    undefined,
                                                                    undefined,
                                                                    false
                                                                  ]
                                                                });
                                                    }));
                                      }
                                      var eventId = VariantEventIdUseCase.baseEventIdFromVariantEventId(variantEventId);
                                      var variantId = VariantEventIdUseCase.variantIdFromVariantEventId(variantEventId);
                                      return Router.Link.addDrawerItem(undefined, {
                                                  NAME: "eventVariant",
                                                  VAL: [
                                                    {
                                                      baseEventId: eventId,
                                                      variantId: variantId
                                                    },
                                                    undefined
                                                  ]
                                                });
                                    }),
                                  blockedValuesUsed: blockedValuesUsed,
                                  invalidAllowedValues: invalidAllowedValues,
                                  invalidAllowedValuesConfirmed: match$4[0],
                                  setInvalidAllowedValuesConfirmed: setInvalidAllowedValuesConfirmed,
                                  similarNames: similarNames,
                                  role: role,
                                  eventEdit: Belt_Option.isSome(currentEventName) ? "Update" : "Create",
                                  onClose: onExitEditing,
                                  enforcesUniqueNames: enforcesUniqueNames
                                })))
                }));
}

var make = EventsSheet__EditableNameCell;

export {
  make ,
  
}
/* targetContainerStyles Not a pure module */
