// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ViewerContext from "./ViewerContext.mjs";
import * as FramerMotion from "framer-motion";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as InspectorStats from "./inspector/InspectorStats.mjs";
import * as SegmentControl from "./SegmentControl.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as GettingStarted__Steps from "./GettingStarted__Steps.mjs";
import * as GettingStarted__Types from "./GettingStarted__Types.mjs";
import * as GettingStarted__Progress from "./GettingStarted__Progress.mjs";
import * as GettingStarted__ProgressStore from "./GettingStarted__ProgressStore.mjs";

function useHasGettingStarted(param) {
  var viewer = ViewerContext.use(undefined);
  var member = WorkspaceContext.useMemberWithUserId(viewer.id);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var hasFeatureFlag = AppFeatureFlag.useFeatureFlag("GettingStarted");
  var canEdit = Belt_Option.mapWithDefault(member, false, (function (member) {
          return RoleUtils.canEdit(RoleUtils.getRoleFromMember(member));
        }));
  var becameMemberAfterLaunch = Belt_Option.mapWithDefault(member, false, (function (member) {
          return DateFns.isAfter(new Date("2024-02-14"), Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(member.createdAt), new Date(), (function (prim) {
                            return prim.toDate();
                          })));
        }));
  if ((canEdit || FirebaseFetcherHooks.isSuperUser(undefined)) && (becameMemberAfterLaunch || hasFeatureFlag)) {
    return !isGlobalWorkspace;
  } else {
    return false;
  }
}

var root = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "subtract",
            VAL: [
              Css.pct(100.0),
              Css.px(376)
            ]
          }),
      tl: {
        hd: Css.maxWidth(Css.px(900)),
        tl: {
          hd: Css.paddingTop(Css.px(32)),
          tl: {
            hd: Css.paddingRight(Css.px(16)),
            tl: {
              hd: Css.paddingLeft(Css.px(16)),
              tl: {
                hd: Css.borderRadius(Css.px(16)),
                tl: {
                  hd: Css.marginLeft(Css.px(32)),
                  tl: {
                    hd: Css.marginRight(Css.px(32)),
                    tl: {
                      hd: Css.marginBottom(Css.px(16)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.light01),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: {
            hd: Css.paddingLeft(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

function percentageBar(stepsCompleted, stepCount) {
  var pctFinished = stepsCompleted / stepCount * 100.0;
  return Curry._1(Css.style, {
              hd: Css.height(Css.px(6)),
              tl: {
                hd: Css.width(Css.pxFloat(120.0)),
                tl: {
                  hd: Css.media("(max-width: 1400px)", {
                        hd: Css.width(Css.pxFloat(80.0)),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.light07),
                    tl: {
                      hd: Css.borderRadius(Css.px(4)),
                      tl: {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.after({
                                hd: Css.position("absolute"),
                                tl: {
                                  hd: Css.transition({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$long
                                      }, undefined, undefined, "width"),
                                  tl: {
                                    hd: Css.left(Css.px(0)),
                                    tl: {
                                      hd: Css.top(Css.px(0)),
                                      tl: {
                                        hd: Css.height(Css.px(6)),
                                        tl: {
                                          hd: Css.width(Css.pct(pctFinished)),
                                          tl: {
                                            hd: Css.maxWidth(Css.pct(pctFinished === 100.0 ? 100.0 : 92.0)),
                                            tl: {
                                              hd: Css.unsafe("content", "''"),
                                              tl: {
                                                hd: Css.display("block"),
                                                tl: {
                                                  hd: Css.borderRadius(Css.px(4)),
                                                  tl: {
                                                    hd: Css.backgroundColor(Styles.Color.green),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function GettingStarted$Section(Props) {
  var title = Props.title;
  var icon = Props.icon;
  var stepCount = Props.stepCount;
  var stepsCompleted = Props.stepsCompleted;
  var children = Props.children;
  return React.createElement("section", {
              className: root
            }, React.createElement("header", {
                  className: header
                }, React.createElement(Icon.make, {
                      type_: icon,
                      size: "large",
                      color: Styles.Color.light12
                    }), React.createElement(Title.make, {
                      children: title,
                      size: "Small"
                    }), React.createElement(Spacer.make, {
                      grow: 1.0
                    }), React.createElement($$Text.make, {
                      size: "Tiny",
                      color: Styles.Color.light11,
                      children: String(stepsCompleted) + " of " + AvoUtils.plural(undefined, undefined, stepCount, "step")
                    }), React.createElement("div", {
                      className: percentageBar(stepsCompleted, stepCount)
                    })), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(FramerMotion.AnimatePresence, {
                  initial: false,
                  children: children
                }));
}

var root$1 = Curry._1(Css.style, {
      hd: Css.width(Css.px(280)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderRadius(Styles.Border.radius),
            tl: {
              hd: Css.padding(Css.px(20)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.position("sticky"),
                    tl: {
                      hd: Css.top(Css.px(16)),
                      tl: {
                        hd: Css.right(Css.px(16)),
                        tl: {
                          hd: Css.$$float("right"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var docsLink = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: $$Text.sizeStyles("Small"),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.display("inlineFlex"),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.gap(Css.px(6)),
                    tl: {
                      hd: Css.color(Styles.Color.primaryPurpleShade1),
                      tl: {
                        hd: Css.textDecoration("none"),
                        tl: {
                          hd: Icon.color(Styles.Color.primaryPurpleShade1),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.textDecoration("underline"),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

function GettingStarted$Aside(Props) {
  var schemaBundle = SchemaBundleContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var trackInteracted = function (type_) {
    return AnalyticsRe.onboardingChecklistInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "GettingStarted", type_, schemaBundle.branchId, schemaBundle.schemaId);
  };
  return React.createElement("aside", {
              className: root$1
            }, workspace.isSandbox ? null : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light12,
                        children: "Explore a fully set up Avo workspace"
                      }), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement(Button.make, {
                        icon: "outsideLink",
                        label: "Open sandbox",
                        onClick: (function (param) {
                            window.open("https://avo.app/sandbox", "_blank");
                            return trackInteracted("OpenSandbox");
                          }),
                        style: {
                          NAME: "custom",
                          VAL: {
                            customColor: Styles.Color.white,
                            hoverColor: Styles.Color.white,
                            customBackgroundColor: Styles.Color.primaryPurpleShade1,
                            customBackgroundHoverColor: Styles.Color.primaryPurpleShade2,
                            optionalCustomOutlineColor: undefined,
                            optionalCustomOutlineHoverColor: undefined
                          }
                        }
                      }), React.createElement(Spacer.make, {
                        height: 24
                      })), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light12,
                  children: "Read the docs"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("a", {
                  className: docsLink,
                  href: "https://www.avo.app/docs",
                  target: "_blank",
                  onClick: (function (param) {
                      return trackInteracted("OpenDocs");
                    })
                }, React.createElement(Icon.make, {
                      type_: "outsideLink"
                    }), "Getting started with Avo", React.createElement(Spacer.make, {
                      height: 2
                    })), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light12,
                  children: "Need some human help?"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), Intercom.isAvailable(undefined) ? React.createElement("button", {
                    className: docsLink,
                    target: "_blank",
                    onClick: (function (param) {
                        Intercom.showNewMessage("Hey! I'd love to get some help using Avo");
                        return trackInteracted("OpenChat");
                      })
                  }, React.createElement(Icon.make, {
                        type_: "chat"
                      }), "Reach out via chat", React.createElement(Spacer.make, {
                        height: 2
                      })) : null, React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("a", {
                  className: docsLink,
                  href: "https://calendly.com/avohq/avo-onboarding",
                  rel: "noopener",
                  target: "_blank",
                  onClick: (function (param) {
                      return trackInteracted("BookOnboarding11");
                    })
                }, React.createElement(Icon.make, {
                      type_: "calendar"
                    }), "Book onboarding 1:1", React.createElement(Spacer.make, {
                      height: 2
                    })));
}

var header$1 = Curry._1(Css.style, {
      hd: Css.padding(Css.px(18)),
      tl: {
        hd: Css.paddingLeft(Css.px(32)),
        tl: {
          hd: Css.width(Css.pct(100.0)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: {
                hd: Css.zIndex(1),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var subHeader = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(40)),
      tl: {
        hd: Css.paddingRight(Css.px(32)),
        tl: {
          hd: Css.paddingLeft(Css.px(48)),
          tl: {
            hd: Css.marginBottom(Css.px(32)),
            tl: /* [] */0
          }
        }
      }
    });

function hasInspectorOnProd(inspectorStats) {
  if (typeof inspectorStats === "number") {
    return false;
  } else {
    return Belt_Array.some(inspectorStats._0, (function (param) {
                  return Belt_Array.some(param.envs, (function (param) {
                                return param.env === "prod";
                              }));
                }));
  }
}

function GettingStarted(Props) {
  var viewer = ViewerContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = GettingStarted__ProgressStore.use(undefined);
  var completedSteps = match.completedSteps;
  var onboardingChoice = match.onboardingChoice;
  var setOnboardingChoice = match.setOnboardingChoice;
  var stepCompleted = function (step) {
    return Belt_Option.getWithDefault(Js_dict.get(completedSteps, GettingStarted__Types.StepId.tToJs(step)), false);
  };
  var inspectorStats = InspectorStats.useStatsWithCache(undefined);
  var hasInspectorOnProd$1 = hasInspectorOnProd(inspectorStats);
  React.useEffect((function () {
          AnalyticsRe.onboardingChecklistOpened(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "GettingStarted", schemaBundle.branchId, schemaBundle.schemaId);
          return (function (param) {
                    return AnalyticsRe.onboardingChecklistClosed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Navigation", "GettingStarted", schemaBundle.branchId, schemaBundle.schemaId);
                  });
        }), []);
  var handleSetOnboardingChoice = function (choice) {
    Curry._1(setOnboardingChoice, choice);
    return AnalyticsRe.onboardingChecklistPathSelected(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "GettingStarted", choice === "manual" ? "Manual" : "LiveData", schemaBundle.branchId, schemaBundle.schemaId);
  };
  var name = viewer.name;
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: header$1
                }, React.createElement(Title.make, {
                      children: "Getting started",
                      size: "Small"
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.overflowY("auto"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(GettingStarted$Aside, {}), React.createElement("div", {
                      className: subHeader
                    }, React.createElement(Title.make, {
                          children: (name == null) ? "Welcome!" : "Welcome " + name + "!",
                          color: Styles.Color.avoPink,
                          size: "Small"
                        }), React.createElement(Spacer.make, {
                          height: 12
                        }), React.createElement($$Text.make, {
                          size: "Large",
                          color: Styles.Color.light11,
                          maxWidth: Css.px(600),
                          children: "Your journey to better data begins here. Get your workspace up and running by following the steps below."
                        })), React.createElement(GettingStarted$Section, {
                      title: "Add events to your tracking plan",
                      icon: "addToTrackingPlan",
                      stepCount: onboardingChoice === "manual" ? 1 : 2,
                      stepsCompleted: Belt_Array.keep(onboardingChoice === "manual" ? ["addYourFirstEventToTheTrackingPlan"] : [
                              "setUpInspector",
                              "addEventsFromInspector"
                            ], stepCompleted).length,
                      children: null
                    }, React.createElement(SegmentControl.make, {
                          options: [
                            [
                              "Build from your live data",
                              "liveData"
                            ],
                            [
                              "Build manually",
                              "manual"
                            ]
                          ],
                          onSelect: handleSetOnboardingChoice,
                          selected: onboardingChoice,
                          circular: true,
                          key: "OnboardingChoice"
                        }), React.createElement(Spacer.make, {
                          height: 24,
                          key: "Spacer-24"
                        }), onboardingChoice === "manual" ? React.createElement(GettingStarted__Steps.AddYourFirstEvent.make, {
                            completed: stepCompleted("addYourFirstEventToTheTrackingPlan"),
                            key: "AddYourFirstEvent"
                          }) : React.createElement(React.Fragment, undefined, React.createElement(GettingStarted__Steps.SetUpInspector.make, {
                                completed: stepCompleted("setUpInspector"),
                                inspectorStats: inspectorStats,
                                hasInspectorOnProd: hasInspectorOnProd$1,
                                key: "SetUpInspector"
                              }), React.createElement(GettingStarted__Steps.ImportEventsFromInspector.make, {
                                completed: stepCompleted("addEventsFromInspector"),
                                blocked: !stepCompleted("setUpInspector"),
                                inspectorStats: inspectorStats,
                                key: "ImportEventsFromInspector"
                              }))), React.createElement(GettingStarted$Section, {
                      title: "Find and fix data quality issues",
                      icon: "search",
                      stepCount: onboardingChoice === "manual" ? 3 : 2,
                      stepsCompleted: Belt_Array.keep(onboardingChoice === "manual" ? [
                              "setUpInspector",
                              "setUpAlertsForYourMostImportantEvents",
                              "triageAnIssue"
                            ] : [
                              "setUpAlertsForYourMostImportantEvents",
                              "triageAnIssue"
                            ], stepCompleted).length,
                      children: null
                    }, onboardingChoice === "manual" ? React.createElement(GettingStarted__Steps.SetUpInspector.make, {
                            completed: stepCompleted("setUpInspector"),
                            inspectorStats: inspectorStats,
                            hasInspectorOnProd: hasInspectorOnProd$1,
                            key: "SetUpInspector"
                          }) : null, React.createElement(GettingStarted__Steps.SetUpSlackAlerts.make, {
                          completed: stepCompleted("setUpAlertsForYourMostImportantEvents"),
                          blocked: !stepCompleted("setUpInspector") || !hasInspectorOnProd$1,
                          key: "SetUpSlackAlerts"
                        }), React.createElement(GettingStarted__Steps.TriageIssue.make, {
                          completed: stepCompleted("triageAnIssue"),
                          blocked: !stepCompleted("setUpInspector"),
                          key: "TriageIssue"
                        })), React.createElement(GettingStarted$Section, {
                      title: "Plan and implement analytics update on a branch",
                      icon: "branch",
                      stepCount: 1,
                      stepsCompleted: stepCompleted("completeDemoBranchTour") ? 1 : 0,
                      children: React.createElement(GettingStarted__Steps.BranchedWorkflows.make, {
                            completed: stepCompleted("completeDemoBranchTour"),
                            key: "BranchedWorkflows"
                          }),
                      key: "BranchedWorkflows"
                    })), match.dismissed && !match.complete ? React.createElement(GettingStarted__Progress.make, {}) : null);
}

var make = GettingStarted;

export {
  useHasGettingStarted ,
  make ,
  
}
/* root Not a pure module */
