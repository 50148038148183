// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AvoModel from "../app/src/avoModel.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

function isIntWhenFloatExpected(modelProperty, receivedPropertType) {
  if (receivedPropertType === "int") {
    if (modelProperty.type_ === "float") {
      return true;
    } else {
      return modelProperty.type_ === "long";
    }
  } else {
    return false;
  }
}

function isList(modelProperty, receivedPropertyType) {
  if (modelProperty.list && (receivedPropertyType === "list" || receivedPropertyType === "list<>") || modelProperty.list && (receivedPropertyType === "list(int)" || receivedPropertyType === "list<int>") && modelProperty.type_ === "int" || modelProperty.list && (receivedPropertyType === "list(float)" || receivedPropertyType === "list<float>") && modelProperty.type_ === "float" || modelProperty.list && (receivedPropertyType === "list(string)" || receivedPropertyType === "list<string>") && modelProperty.type_ === "string" || modelProperty.list && (receivedPropertyType === "list(object)" || receivedPropertyType === "list<object>") && modelProperty.type_ === "object") {
    return true;
  } else if (modelProperty.list && (receivedPropertyType === "list(bool)" || receivedPropertyType === "list<bool>" || receivedPropertyType === "list(boolean)" || receivedPropertyType === "list<boolean>")) {
    return modelProperty.type_ === "bool";
  } else {
    return false;
  }
}

function receivedPropertyTypeMatchesTrackingPlan(modelProperty, receivedPropertyType, optional) {
  var receivedPropertyType$1 = receivedPropertyType.toLowerCase();
  var isCorrectType = modelProperty.type_ === receivedPropertyType$1 || receivedPropertyType$1 === "boolean" && modelProperty.type_ === "bool" || isList(modelProperty, receivedPropertyType$1) || optional && receivedPropertyType$1 === "null" || modelProperty.type_ === "any" && receivedPropertyType$1 !== "null" || modelProperty.type_ === "any" && optional;
  if (isCorrectType) {
    return true;
  } else {
    return isIntWhenFloatExpected(modelProperty, receivedPropertyType$1);
  }
}

function getModelEventAndProperties(eventName, modelEventsMap, model) {
  var maybeModelEvent = Belt_MapString.get(modelEventsMap, eventName);
  var modelPropertiesFromModelEventFoundByEventName = Belt_Option.mapWithDefaultU(maybeModelEvent, /* [] */0, (function (modelEvent) {
          return AvoModel.getResolvedPropertiesForEvent(model, modelEvent);
        }));
  return [
          maybeModelEvent,
          modelPropertiesFromModelEventFoundByEventName
        ];
}

function filterInspectorDashboardIssuesWithTrackingPlan(source, maybeModelEvent, maybeModelProperty, model) {
  var maybeKnownEvent = Belt_Option.flatMapU(maybeModelEvent, (function ($$event) {
          if (Belt_List.someU($$event.includeSources, (function (includedSource) {
                    return includedSource.id === source.sourceId;
                  }))) {
            return $$event;
          }
          
        }));
  var shouldKeepInconsintentIssue = function (property, sourceIssueTypes) {
    var typesString = Belt_Array.map(sourceIssueTypes, (function (t) {
            return t.type_;
          }));
    var isFloatInt = typesString.length === 2 && Belt_Array.keep(typesString, (function (t) {
            if (t === "float") {
              return true;
            } else {
              return t === "int";
            }
          })).length === 2;
    return !(isFloatInt && property.type_ === "float");
  };
  if (maybeKnownEvent === undefined) {
    if (maybeModelEvent !== undefined) {
      return [];
    } else {
      return source.issues;
    }
  }
  var maybeKnownProperty = Belt_Option.flatMapU(maybeModelProperty, (function (modelProperty) {
          if (!Belt_List.has(ModelUtils.propertyExcludedSources(modelProperty, model), source.sourceId, (function (prim0, prim1) {
                    return prim0 === prim1;
                  }))) {
            return modelProperty;
          }
          
        }));
  if (maybeKnownProperty !== undefined) {
    if (ModelUtils.isPropertyOptionalOnEventAndSource(maybeKnownProperty, maybeKnownEvent.id, source.sourceId, model)) {
      return Belt_Array.keepU(source.issues, (function (issue) {
                    var variant = issue.NAME;
                    if (variant === "InconsistentType") {
                      return shouldKeepInconsintentIssue(maybeKnownProperty, issue.VAL.types);
                    } else {
                      return !(variant === "UnexpectedProperty" || variant === "SometimesMissing");
                    }
                  }));
    } else {
      return Belt_Array.keepU(source.issues, (function (issue) {
                    if (issue.NAME === "InconsistentType") {
                      return shouldKeepInconsintentIssue(maybeKnownProperty, issue.VAL.types);
                    } else {
                      return true;
                    }
                  }));
    }
  } else {
    return source.issues;
  }
}

export {
  isIntWhenFloatExpected ,
  isList ,
  receivedPropertyTypeMatchesTrackingPlan ,
  getModelEventAndProperties ,
  filterInspectorDashboardIssuesWithTrackingPlan ,
  
}
/* AvoModel Not a pure module */
