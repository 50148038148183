// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function toggleItem(items, id) {
  if (Belt_List.has(items, id, Caml_obj.caml_equal)) {
    return Belt_List.keep(items, (function (itemId) {
                  return Caml_obj.caml_notequal(itemId, id);
                }));
  } else {
    return {
            hd: id,
            tl: items
          };
  }
}

function EnableDestinationForSourceModal(Props) {
  var sourceId = Props.sourceId;
  var destinationId = Props.destinationId;
  var model = Props.model;
  var onClose = Props.onClose;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        return Belt_List.map(ModelUtils.getSourceEvents(undefined, model, sourceId), (function ($$event) {
                      return $$event.id;
                    }));
      });
  var setEvents = match[1];
  var events = match[0];
  var match$1 = ModelUtils.getSourceById(sourceId, model);
  var match$2 = ModelUtils.getDestinationById(destinationId, model);
  var tmp;
  if (match$1 !== undefined && match$2 !== undefined) {
    var isSelectAll = Caml_obj.caml_equal(Belt_List.sort(events, Caml.caml_string_compare), Belt_List.sort(Belt_List.map(ModelUtils.getSourceEvents(undefined, model, sourceId), (function ($$event) {
                    return $$event.id;
                  })), Caml.caml_string_compare));
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.textAlign("center"),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.large),
                        tl: /* [] */0
                      }
                    }
                  })
            }, "Which events should be sent from " + AvoConfig.getSourceName(match$1) + " to " + ModelUtils.getDestinationName(match$2) + "?"), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(15), Css.px(0)),
                    tl: {
                      hd: Css.textAlign("center"),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: /* [] */0
                      }
                    }
                  })
            }, "You can control which events on " + AvoConfig.getSourceName(match$1) + " will be sent to " + ModelUtils.getDestinationName(match$2) + ". In the list below are all events that are currently sent from " + AvoConfig.getSourceName(match$1) + "."), React.createElement("div", undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(10), Css.px(15)),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("input", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(15)),
                            tl: /* [] */0
                          }),
                      id: "all-events",
                      checked: isSelectAll,
                      type: "checkbox",
                      onChange: (function (domEvent) {
                          var isSelectAll = domEvent.target.checked;
                          return Curry._1(setEvents, (function (param) {
                                        if (isSelectAll) {
                                          return Belt_List.map(ModelUtils.getSourceEvents(undefined, model, sourceId), (function ($$event) {
                                                        return $$event.id;
                                                      }));
                                        } else {
                                          return /* [] */0;
                                        }
                                      }));
                        })
                    }), React.createElement("label", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(10)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.medium),
                              tl: /* [] */0
                            }
                          }),
                      htmlFor: "all-events"
                    }, "Send all events already attached to " + AvoConfig.getSourceName(match$1))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxHeight(Css.px(300)),
                        tl: {
                          hd: Css.overflow("auto"),
                          tl: /* [] */0
                        }
                      })
                }, Belt_List.toArray(Belt_List.map(ModelUtils.getSourceEvents(undefined, model, sourceId), (function ($$event) {
                            return React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.padding2(Css.px(10), Css.px(15)),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                                                  tl: {
                                                    hd: Css.width(Css.pct(100)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            })
                                      }, React.createElement("input", {
                                            id: "event-" + $$event.id,
                                            checked: Belt_List.some(events, (function (id) {
                                                    return id === $$event.id;
                                                  })),
                                            type: "checkbox",
                                            onChange: (function (param) {
                                                return Curry._1(setEvents, (function (events) {
                                                              return toggleItem(events, $$event.id);
                                                            }));
                                              })
                                          }), React.createElement("label", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.marginLeft(Css.px(10)),
                                                  tl: {
                                                    hd: Css.width(Css.pct(100)),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            htmlFor: "event-" + $$event.id
                                          }, $$event.name));
                          }))))), React.createElement(Spacer.make, {
              height: 16
            }), React.createElement(Button.make, {
              label: "Enable Connection",
              onClick: (function (param) {
                  Curry.app(sendActions, [
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        (function (branch) {
                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                            return AnalyticsRe.dataPathUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, Belt_Option.getExn(match$1.platform), match$2.type_, "Enable", Belt_List.length(events), Belt_List.length(ModelUtils.getSourceEvents(undefined, model, sourceId)), NamedBranch.getId(branch), schemaBundle$1.schemaId);
                          }),
                        undefined,
                        [[
                            {
                              NAME: "IncludeDestinationInSourceV2",
                              VAL: [
                                sourceId,
                                destinationId,
                                events
                              ]
                            },
                            {
                              eventIds: Belt_List.toArray(events),
                              eventsQuery: Belt_List.toArray(events),
                              sourceId: sourceId,
                              sourceQuery: sourceId,
                              destinationId: destinationId,
                              destinationQuery: destinationId
                            }
                          ]]
                      ]);
                  return Curry._1(onClose, undefined);
                })
            }));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(500)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.padding4(Css.px(35), Css.px(30), Css.px(20), Css.px(30)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.cursor("default"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, tmp);
}

var make = EnableDestinationForSourceModal;

export {
  toggleItem ,
  make ,
  
}
/* Css Not a pure module */
