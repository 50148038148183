// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconMetric from "./IconMetric.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function DiffEventMetrics(Props) {
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var actions = Props.actions;
  var addedItems = Belt_List.keep(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action === "object" && action.NAME === "AddEventToMetric") {
                return action.VAL[0];
              }
              
            })), (function (metricId) {
          return Belt_List.some(branchModel.metrics, (function (metric) {
                        return metric.id === metricId;
                      }));
        }));
  var removedItems = Belt_List.keep(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action === "object" && action.NAME === "RemoveEventFromMetric") {
                return action.VAL[0];
              }
              
            })), (function (metricId) {
          return Belt_List.some(masterModel.metrics, (function (metric) {
                        return metric.id === metricId;
                      }));
        }));
  var updatedItems = Belt_List.concat(addedItems, removedItems);
  if (Belt_List.length(updatedItems) === 0) {
    return null;
  } else {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(15), Css.px(10), Css.px(0), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(IconMetric.make, {
                        size: 14,
                        color: Styles.Color.light07
                      })), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.width(Css.pct(100)),
                          tl: /* [] */0
                        })
                  }, Belt_List.toArray(Belt_List.concatMany([
                            Belt_List.mapU(removedItems, (function (metricId) {
                                    return Belt_Option.mapWithDefault(Belt_List.getByU(branchModel.metrics, (function (metric) {
                                                      return metric.id === metricId;
                                                    })), null, (function (metric) {
                                                  return React.createElement("div", {
                                                              key: metric.id + "-removed",
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                    tl: {
                                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                                      tl: {
                                                                        hd: Css.padding2(Css.px(10), Css.px(15)),
                                                                        tl: {
                                                                          hd: Css.margin2(Css.px(5), Css.px(0)),
                                                                          tl: {
                                                                            hd: Css.borderRadius(Styles.Border.radius),
                                                                            tl: {
                                                                              hd: Css.border(Css.px(1), "solid", Styles.Color.darkRed),
                                                                              tl: {
                                                                                hd: Css.color(Styles.Color.darkRed),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  })
                                                            }, metric.name);
                                                }));
                                  })),
                            Belt_List.mapU(addedItems, (function (metricId) {
                                    return Belt_Option.mapWithDefault(Belt_List.getByU(branchModel.metrics, (function (metric) {
                                                      return metric.id === metricId;
                                                    })), null, (function (metric) {
                                                  return React.createElement("div", {
                                                              key: metric.id + "-added",
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                    tl: {
                                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                                      tl: {
                                                                        hd: Css.padding2(Css.px(10), Css.px(15)),
                                                                        tl: {
                                                                          hd: Css.margin2(Css.px(5), Css.px(0)),
                                                                          tl: {
                                                                            hd: Css.borderRadius(Styles.Border.radius),
                                                                            tl: {
                                                                              hd: Css.border(Css.px(1), "solid", Styles.Color.darkGreen),
                                                                              tl: {
                                                                                hd: Css.color(Styles.Color.darkGreen),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  })
                                                            }, metric.name);
                                                }));
                                  }))
                          ]))));
  }
}

var make = DiffEventMetrics;

export {
  make ,
  
}
/* Css Not a pure module */
