// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Changes from "../../../shared/Changes.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Dom_storage from "rescript/lib/es6/dom_storage.js";
import * as SnippetTypes from "../detailviews/SnippetTypes.mjs";
import * as SnippetUtils from "../detailviews/SnippetUtils.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ImplementationStatusContext from "../ImplementationStatusContext.mjs";
import * as FetchImplementationStatusUtils from "../../../shared/utils/FetchImplementationStatusUtils.mjs";

function useSourceUpdates(toSources, withImplementationStatus, changes) {
  var invocationsContext = React.useContext(ImplementationStatusContext.context).codegen;
  var loadingState = invocationsContext[0];
  var invocations = withImplementationStatus ? FetchImplementationStatusUtils.getInvocationsWithoutStatus(invocationsContext) : "Loading";
  var invocationsDependencyValue = typeof invocations === "object" ? Caml_option.some(Js_dict.map(Belt_List.toArray, invocations.VAL)) : undefined;
  return React.useMemo((function () {
                var sourceUpdates = Changes.getCodeChangesReport(toSources, invocations, changes);
                return {
                        loadingState: loadingState === "Ready" ? "ready" : "loading",
                        sourceUpdates: sourceUpdates
                      };
              }), [
              toSources,
              withImplementationStatus,
              invocationsDependencyValue,
              changes
            ]);
}

var usingManageDiffViewType_useOnce = {
  contents: false
};

var UsingManageDiffViewType_useOnceHookMoreThanOnceException = /* @__PURE__ */Caml_exceptions.create("BranchImplementationHooks.UsingManageDiffViewType_useOnceHookMoreThanOnceException");

function useManageDiffViewType_useOnce(diffViewType, setDiffViewType, onTrigger) {
  var diffViewTypeLocalStorageKey = "branchImplementationDiffViewType";
  React.useEffect((function () {
          if (usingManageDiffViewType_useOnce.contents) {
            throw {
                  RE_EXN_ID: UsingManageDiffViewType_useOnceHookMoreThanOnceException,
                  Error: new Error()
                };
          }
          usingManageDiffViewType_useOnce.contents = true;
          Curry._1(setDiffViewType, Belt_Option.getWithDefault(Belt_Option.flatMap(Dom_storage.getItem(diffViewTypeLocalStorageKey, localStorage), SnippetTypes.DiffViewType.tFromJs), "unified"));
          return (function (param) {
                    usingManageDiffViewType_useOnce.contents = false;
                    
                  });
        }), []);
  return Hooks.useDidUpdate1((function (param) {
                Dom_storage.setItem(diffViewTypeLocalStorageKey, SnippetTypes.DiffViewType.tToJs(diffViewType), localStorage);
                Curry._1(onTrigger, undefined);
                
              }), [diffViewType]);
}

var usingManageSelectedSourceId_useOnce = {
  contents: false
};

var UsingManageSelectedSourceId_useOnceHookMoreThanOnceException = /* @__PURE__ */Caml_exceptions.create("BranchImplementationHooks.UsingManageSelectedSourceId_useOnceHookMoreThanOnceException");

function useManageSelectedSourceId_useOnce(selectedSourceId, setSelectedSourceId, onTrigger) {
  var previousSelectedSourceId = Hooks.usePrevious1(selectedSourceId);
  var match = SnippetUtils.QueryParams.useSourceId(undefined);
  var removeSelectedSourceIdQueryParamByReplace = match.removeByReplace;
  var setSelectedSourceIdQueryParam = match.set;
  var selectedSourceIdQueryParam = match.value;
  var match$1 = SnippetUtils.QueryParams.useEventId(undefined);
  var removeEventIdQueryParamByReplace = match$1.removeByReplace;
  var match$2 = SnippetUtils.QueryParams.usePageName(undefined);
  var removePageNameQueryParamByReplace = match$2.removeByReplace;
  React.useEffect((function () {
          if (usingManageSelectedSourceId_useOnce.contents) {
            throw {
                  RE_EXN_ID: UsingManageSelectedSourceId_useOnceHookMoreThanOnceException,
                  Error: new Error()
                };
          }
          usingManageSelectedSourceId_useOnce.contents = true;
          return (function (param) {
                    Curry._1(removeSelectedSourceIdQueryParamByReplace, undefined);
                    usingManageSelectedSourceId_useOnce.contents = false;
                    
                  });
        }), []);
  Hooks.useDidUpdate1((function (param) {
          var routingBetweenSources = selectedSourceId !== undefined && previousSelectedSourceId !== undefined;
          var routingToOverview = selectedSourceId === undefined;
          var isSameSource = Caml_obj.caml_equal(previousSelectedSourceId, selectedSourceId);
          if ((routingBetweenSources || routingToOverview) && !isSameSource) {
            Curry._1(removeEventIdQueryParamByReplace, undefined);
            Curry._1(removePageNameQueryParamByReplace, undefined);
          }
          
        }), [selectedSourceId]);
  React.useEffect((function () {
          if (selectedSourceIdQueryParam !== undefined) {
            if (selectedSourceId !== undefined && selectedSourceIdQueryParam === selectedSourceId) {
              
            } else {
              Curry._1(setSelectedSourceId, selectedSourceIdQueryParam);
            }
          } else if (selectedSourceId !== undefined) {
            Curry._1(setSelectedSourceId, undefined);
          }
          
        }), [selectedSourceIdQueryParam]);
  React.useEffect((function () {
          return (function (param) {
                    Curry._1(setSelectedSourceId, undefined);
                    return Curry._1(removeSelectedSourceIdQueryParamByReplace, undefined);
                  });
        }), []);
  return function (value) {
    Curry._1(setSelectedSourceId, value !== "" && value !== "Overview" ? value : undefined);
    Curry._1(onTrigger, value);
    if (value !== "" && value !== "Overview") {
      return Curry._1(setSelectedSourceIdQueryParam, value);
    } else {
      return Curry._1(removeSelectedSourceIdQueryParamByReplace, undefined);
    }
  };
}

export {
  useSourceUpdates ,
  usingManageDiffViewType_useOnce ,
  UsingManageDiffViewType_useOnceHookMoreThanOnceException ,
  useManageDiffViewType_useOnce ,
  usingManageSelectedSourceId_useOnce ,
  UsingManageSelectedSourceId_useOnceHookMoreThanOnceException ,
  useManageSelectedSourceId_useOnce ,
  
}
/* Hooks Not a pure module */
