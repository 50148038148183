// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Actions from "./actions.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as FetchModel from "./FetchModel.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as $$AbortController from "./AbortController.mjs";
import * as BranchStatusModel from "../../shared/models/BranchStatusModel.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ActionDiffWorkerInstance from "./workers/ActionDiffWorkerInstance.mjs";

function calculateRebasedBranchModel(branchModel, masterModelBeforeBranch, currentMasterOrMasterAtBranchMergeOrClose, masterModelBeforeBranchJson, currentMasterOrMasterAtBranchMergeOrCloseJson) {
  return ActionDiffWorkerInstance.diff(masterModelBeforeBranch, masterModelBeforeBranchJson, currentMasterOrMasterAtBranchMergeOrClose, currentMasterOrMasterAtBranchMergeOrCloseJson).then(function (mainBranchChanges) {
              return [
                      mainBranchChanges,
                      Belt_List.reduceU(mainBranchChanges, branchModel, Actions.reduce)
                    ];
            });
}

function getFromAndToModels(branchStatus, masterModel, branchModel, maybeBranchModelJson, rebasedBranchModel, masterModelBeforeBranch, maybeMasterModelBeforeBranchJson) {
  if (typeof branchStatus !== "number" && branchStatus.TAG === /* Merged */1) {
    return {
            from: {
              model: masterModelBeforeBranch,
              json: maybeMasterModelBeforeBranchJson
            },
            to: {
              model: branchModel,
              json: maybeBranchModelJson
            }
          };
  }
  return {
          from: {
            model: masterModel,
            json: undefined
          },
          to: {
            model: rebasedBranchModel,
            json: undefined
          }
        };
}

function calculateDiffInWorker(fromModel, fromModelJson, toModel, toModelJson, masterModelBeforeBranch, branchModel, calculationId, signal) {
  var masterModelBeforeBranchJson = Printer.printModel(masterModelBeforeBranch);
  var branchModelJson = Printer.printModel(branchModel);
  return Promise.race([
              Promise.all([
                      ActionDiffWorkerInstance.diff(fromModel, fromModelJson, toModel, toModelJson),
                      ActionDiffWorkerInstance.diff(masterModelBeforeBranch, masterModelBeforeBranchJson, branchModel, branchModelJson)
                    ]).then(function (param) {
                    return {
                            NAME: "success",
                            VAL: {
                              calculationId: calculationId,
                              fromModel: fromModel,
                              toModel: toModel,
                              rawBranchChanges: param[1],
                              rebasedBranchChanges: param[0]
                            }
                          };
                  }),
              $$AbortController.Signal.toPromise(signal)
            ]);
}

function useToAndFromModels(mode, schemaId, branchId, branchModel, latestBranchAction, masterModel, latestMasterAction, branchStatus) {
  var updateDiffInformation = ModelStore.useUpdateDiffInformation(undefined);
  var match = FirebaseFetcherHooks.useBranchLifecycleActions(schemaId, branchId, mode, undefined);
  var match$1 = branchModel.branchPointer;
  var match$2 = branchModel.branchPointer;
  var fetchStateMainBeforeBranch = FetchModel.use(match$1 !== undefined ? undefined : Belt_Option.flatMap(match[0], (function (branchOpenAction) {
                return Models.toDateOption(Models.Action.getCreatedAt(branchOpenAction));
              })), match$2 !== undefined ? Caml_option.some(new Date(match$2[1])) : undefined, mode, "master");
  var match$3 = React.useMemo((function () {
          switch (fetchStateMainBeforeBranch.TAG | 0) {
            case /* Loading */0 :
            case /* Error */1 :
                return [
                        undefined,
                        undefined
                      ];
            case /* Result */2 :
                return [
                        fetchStateMainBeforeBranch._0,
                        fetchStateMainBeforeBranch._2
                      ];
            
          }
        }), [fetchStateMainBeforeBranch]);
  var maybeMainModelBeforeBranchLatestAction = match$3[1];
  var maybeMainModelBeforeBranch = match$3[0];
  var branchMergeOrCloseTimestamp = BranchStatusModel.getBranchMergeOrCloseTimestamp(match[1], match[2]);
  var fetchStateMainAtMergeOrClose = FetchModel.use(undefined, branchMergeOrCloseTimestamp, Belt_Option.mapWithDefault(branchMergeOrCloseTimestamp, "Skip", (function (param) {
              return "Fetch";
            })), "master");
  var match$4;
  switch (fetchStateMainAtMergeOrClose.TAG | 0) {
    case /* Loading */0 :
    case /* Error */1 :
        match$4 = [
          undefined,
          undefined
        ];
        break;
    case /* Result */2 :
        match$4 = [
          fetchStateMainAtMergeOrClose._0,
          fetchStateMainAtMergeOrClose._2
        ];
        break;
    
  }
  var match$5 = branchMergeOrCloseTimestamp !== undefined ? [
      match$4[0],
      match$4[1]
    ] : [
      masterModel,
      latestMasterAction
    ];
  var maybeCurrentMasterOrMasterAtBranchMergeOrCloseLatestAction = match$5[1];
  var maybeCurrentMasterOrMasterAtBranchMergeOrClose = match$5[0];
  var match$6 = React.useState(function () {
        
      });
  var setRebasedBranchInfo = match$6[1];
  var maybeRebasedBranchInfo = match$6[0];
  var getMaybeActionId = function (action) {
    return Belt_Option.getWithDefault(Belt_Option.map(action, Models.Action.getId), "");
  };
  var maybeCurrentMasterOrMasterAtBranchMergeOrCloseJson = React.useMemo((function () {
          return Belt_Option.map(maybeCurrentMasterOrMasterAtBranchMergeOrClose, Printer.printModel);
        }), [getMaybeActionId(maybeCurrentMasterOrMasterAtBranchMergeOrCloseLatestAction)]);
  var maybeMainModelBeforeBranchJson = React.useMemo((function () {
          return Belt_Option.map(maybeMainModelBeforeBranch, Printer.printModel);
        }), [maybeMainModelBeforeBranch]);
  var getRequestId = function (param) {
    return getMaybeActionId(maybeMainModelBeforeBranchLatestAction) + "-" + getMaybeActionId(maybeCurrentMasterOrMasterAtBranchMergeOrCloseLatestAction) + "-" + getMaybeActionId(latestBranchAction);
  };
  var currentRequestId = React.useRef("");
  React.useEffect((function () {
          if (maybeMainModelBeforeBranch !== undefined && maybeMainModelBeforeBranchJson !== undefined && maybeCurrentMasterOrMasterAtBranchMergeOrClose !== undefined && maybeCurrentMasterOrMasterAtBranchMergeOrCloseJson !== undefined) {
            var thisRequestId = getRequestId(undefined);
            currentRequestId.current = thisRequestId;
            calculateRebasedBranchModel(branchModel, maybeMainModelBeforeBranch, maybeCurrentMasterOrMasterAtBranchMergeOrClose, Caml_option.valFromOption(maybeMainModelBeforeBranchJson), Caml_option.valFromOption(maybeCurrentMasterOrMasterAtBranchMergeOrCloseJson)).then(function (param) {
                  if (thisRequestId !== currentRequestId.current) {
                    return ;
                  }
                  var rebasedBranchModel = param[1];
                  var mainBranchChanges = param[0];
                  return Curry._1(setRebasedBranchInfo, (function (param) {
                                return {
                                        rebasedBranchModel: rebasedBranchModel,
                                        mainBranchChanges: mainBranchChanges
                                      };
                              }));
                });
          }
          
        }), [getRequestId(undefined)]);
  var maybeFromAndToModels = React.useMemo((function () {
          if (maybeRebasedBranchInfo !== undefined && maybeMainModelBeforeBranch !== undefined) {
            return getFromAndToModels(branchStatus, masterModel, branchModel, undefined, maybeRebasedBranchInfo.rebasedBranchModel, maybeMainModelBeforeBranch, undefined);
          }
          
        }), [
        maybeRebasedBranchInfo,
        maybeMainModelBeforeBranch,
        branchStatus,
        masterModel,
        branchModel
      ]);
  React.useEffect((function () {
          if (maybeFromAndToModels !== undefined && maybeRebasedBranchInfo !== undefined && maybeMainModelBeforeBranch !== undefined) {
            Curry._1(updateDiffInformation, {
                  fromAndToModels: maybeFromAndToModels,
                  rebasedBranchModel: maybeRebasedBranchInfo.rebasedBranchModel,
                  mainModelBeforeBranch: maybeMainModelBeforeBranch,
                  mainBranchChanges: maybeRebasedBranchInfo.mainBranchChanges
                });
          } else {
            Curry._1(updateDiffInformation, undefined);
          }
          
        }), [maybeFromAndToModels]);
  React.useEffect((function () {
          if (Belt_Option.isSome(mode) && Caml_obj.caml_notequal(mode, "Fetch")) {
            Curry._1(updateDiffInformation, undefined);
          }
          
        }), [mode]);
  return maybeFromAndToModels;
}

export {
  calculateRebasedBranchModel ,
  getFromAndToModels ,
  calculateDiffInWorker ,
  useToAndFromModels ,
  
}
/* react Not a pure module */
