// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as IconLock from "./IconLock.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as BranchStatus from "./externals/BranchStatus.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var outerContainer = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.zIndex(Styles.ZIndex.aboveAll),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var innerContainer = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.maxWidth(Css.pct(100)),
        tl: {
          hd: Css.maxWidth(Css.px(400)),
          tl: {
            hd: Css.padding4(Css.px(32), Css.px(40), Css.px(24), Css.px(30)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.cursor("default"),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var iconContainer = Curry._1(Css.style, {
      hd: Css.margin2(Css.px(15), "auto"),
      tl: {
        hd: Css.marginTop(Css.px(-100)),
        tl: {
          hd: Css.textAlign("center"),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  outerContainer: outerContainer,
  innerContainer: innerContainer,
  iconContainer: iconContainer
};

function LockedBranchModal(Props) {
  var onClose = Props.onClose;
  var branchStatus = Props.branchStatus;
  var schemaBundle = SchemaBundleContext.use(undefined);
  React.useEffect((function () {
          AnalyticsRe.lockedBranchEditAttempted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, BranchStatus.toAnalytics(branchStatus), schemaBundle.branchId, schemaBundle.schemaId, schemaBundle.branchId, BranchStatus.toAnalytics(branchStatus));
          
        }), []);
  var tmp;
  if (typeof branchStatus === "number") {
    tmp = "This branch cannot be edited. To make changes, switch to an open branch or create a new one.";
  } else {
    switch (branchStatus.TAG | 0) {
      case /* Merged */1 :
          tmp = "This branch has been merged and cannot be edited. To make changes, switch to an open branch or create a new one.";
          break;
      case /* Closed */2 :
          tmp = "This branch has been deleted and cannot be edited. To make changes, switch to an open branch or create a new one.";
          break;
      default:
        tmp = "This branch cannot be edited. To make changes, switch to an open branch or create a new one.";
    }
  }
  return React.createElement("div", {
              className: outerContainer,
              onClick: (function (param) {
                  return Curry._1(onClose, undefined);
                })
            }, React.createElement("div", {
                  className: innerContainer,
                  onClick: (function (prim) {
                      prim.stopPropagation();
                      
                    })
                }, React.createElement("div", {
                      className: iconContainer
                    }, React.createElement(IconLock.make, {
                          size: 112
                        })), React.createElement(Title.make, {
                      children: "Branch locked",
                      size: "Medium"
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      children: tmp
                    }), React.createElement(Spacer.make, {
                      height: 24
                    }), React.createElement(Button.make, {
                      label: "Got it",
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        }),
                      size: "large"
                    })));
}

var make = LockedBranchModal;

export {
  Style ,
  make ,
  
}
/* outerContainer Not a pure module */
