// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as CopyButton from "../CopyButton.mjs";

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, copyButtonBoxStyles, language) {
  if (language === "go") {
    return React.createElement("div", {
                className: docsCodeBoxStyles
              }, React.createElement("pre", {
                    className: docsCodeStyles
                  }, "avoInspector.", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, "TrackSchemaFromEvent"), "(", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, "\"Event name\""), ",", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, "\n  map[string]interface{}"), "{", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, "\n    \"id\""), ":", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, " \"...\""), ",", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, "\n    \"number\""), ": ", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, "41"), ",\n  },\n)"), React.createElement("div", {
                    className: copyButtonBoxStyles
                  }, React.createElement(CopyButton.make, {
                        copyString: "avoInspector.TrackSchemaFromEvent(\"Event name\",map[string]interface{}{\n              \"id\": \"...\",\n              \"number\":  41,\n            },\n          )"
                      })));
  } else {
    return null;
  }
}

export {
  code ,
  
}
/* react Not a pure module */
