// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dopt from "../externals/Dopt.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Number from "../Number.mjs";
import * as Markdown from "../Markdown.mjs";
import * as StringExt from "../StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CmdKProvider from "../CmdKProvider.mjs";
import ReactRichText from "@dopt/react-rich-text";

function extractField(block, field) {
  var match = Dopt.Block.field(block, field);
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "string") {
    return React.createElement(Markdown.make, {
                source: StringExt.widowless(match.VAL)
              });
  } else if (variant === "number") {
    return $$Number.toLocaleString(match.VAL);
  } else if (variant === "richText") {
    return React.createElement(ReactRichText, {
                children: match.VAL
              });
  } else {
    return Pervasives.string_of_bool(match.VAL);
  }
}

function gatherBodyFields(block) {
  return Belt_Array.keepMapU(Belt_Array.makeBy(5, (function (i) {
                    return i + 1 | 0;
                  })), (function (i) {
                return Belt_Option.mapWithDefaultU(Dopt.Block.field(block, "body-" + String(i)), undefined, (function (type_) {
                              if (type_ === "null") {
                                return ;
                              } else {
                                return type_;
                              }
                            }));
              }));
}

function useCmdKHandler(disable) {
  var match = CmdKProvider.useDisabled(undefined);
  var setCmdkDisabled = match[1];
  var match$1 = React.useState(function () {
        return disable;
      });
  var setDisabledByStep = match$1[1];
  var disabledByStep = match$1[0];
  React.useEffect((function () {
          if (disable) {
            Curry._1(setDisabledByStep, (function (param) {
                    return true;
                  }));
            Curry._1(setCmdkDisabled, (function (param) {
                    return true;
                  }));
          }
          return (function (param) {
                    if (disabledByStep) {
                      Curry._1(setDisabledByStep, (function (param) {
                              return false;
                            }));
                      return Curry._1(setCmdkDisabled, (function (param) {
                                    return false;
                                  }));
                    }
                    
                  });
        }), [disable]);
  
}

var Hook = {
  useCmdKHandler: useCmdKHandler
};

export {
  extractField ,
  gatherBodyFields ,
  Hook ,
  
}
/* Dopt Not a pure module */
