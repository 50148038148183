// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as IconArrowDownEast from "./IconArrowDownEast.mjs";

function DiffEventPropertiesProperty$PropertyNameMappingDiff(Props) {
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var eventId = Props.eventId;
  var propertyId = Props.propertyId;
  var nameMappingsBefore = ModelUtils.getNameMappingsForPropertyOnEvent(masterModel, eventId, propertyId);
  var nameMappingsAfter = ModelUtils.getNameMappingsForPropertyOnEvent(branchModel, eventId, propertyId);
  var removedMappings = Belt_List.keep(nameMappingsBefore, (function (ruleBefore) {
          return !Belt_List.some(nameMappingsAfter, (function (ruleAfter) {
                        return ruleBefore.id === ruleAfter.id;
                      }));
        }));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingLeft(Css.px(4)),
                    tl: /* [] */0
                  })
            }, Belt_List.toArray(Belt_List.map(removedMappings, (function (removedRule) {
                        var match = ModelUtils.getNameMappingNameAndDestination(removedRule, masterModel);
                        return React.createElement("div", {
                                    key: removedRule.id,
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.padding2(Css.px(2), Css.px(0)),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, React.createElement(IconArrowDownEast.make, {
                                        size: 8,
                                        color: Styles.Color.darkRed
                                      }), React.createElement(Spacer.make, {
                                        width: 4
                                      }), React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Regular",
                                        color: Styles.Color.darkRed,
                                        children: "Send as \"" + match[0] + "\" to " + match[1] + " on this event"
                                      }));
                      }))), Belt_List.toArray(Belt_List.map(nameMappingsAfter, (function (currentRule) {
                        var match = ModelUtils.getNameMappingNameAndDestination(currentRule, branchModel);
                        var destinationAfter = match[1];
                        var nameAfter = match[0];
                        var maybeRuleBefore = ModelUtils.getRuleById(currentRule.id, masterModel);
                        var tmp;
                        if (maybeRuleBefore !== undefined) {
                          var match$1 = ModelUtils.getNameMappingNameAndDestination(maybeRuleBefore, masterModel);
                          tmp = React.createElement(React.Fragment, undefined, React.createElement(IconArrowDownEast.make, {
                                    size: 8,
                                    color: Styles.Color.light12
                                  }), React.createElement(Spacer.make, {
                                    width: 4
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Regular",
                                    color: Styles.Color.light12,
                                    children: JsDiff.renderStringDiff(undefined, true, true, "Send as \"" + match$1[0] + "\" to " + match$1[1] + " on this event", "Send as \"" + nameAfter + "\" to " + destinationAfter + " on this event")
                                  }));
                        } else {
                          tmp = React.createElement(React.Fragment, undefined, React.createElement(IconArrowDownEast.make, {
                                    size: 8,
                                    color: Styles.Color.darkGreen
                                  }), React.createElement(Spacer.make, {
                                    width: 4
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Regular",
                                    color: Styles.Color.darkGreen,
                                    children: "Send as \"" + nameAfter + "\" to " + destinationAfter + " on this event"
                                  }));
                        }
                        return React.createElement("div", {
                                    key: currentRule.id,
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.padding2(Css.px(2), Css.px(0)),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, tmp);
                      }))));
}

var PropertyNameMappingDiff = {
  make: DiffEventPropertiesProperty$PropertyNameMappingDiff
};

function containerStyles(primary, secondary) {
  return Curry._1(Css.style, {
              hd: Css.fontWeight(Styles.FontWeight.semi),
              tl: {
                hd: Css.fontSize(Styles.FontSize.small),
                tl: {
                  hd: Css.padding2(Css.px(10), Css.px(15)),
                  tl: {
                    hd: Css.margin4(Css.px(5), Css.px(20), Css.px(5), Css.px(0)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", secondary),
                        tl: {
                          hd: Css.color(primary),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var propertyNameRowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: /* [] */0
          }
        }
      }
    });

function DiffEventPropertiesProperty(Props) {
  var property = Props.property;
  var colors = Props.colors;
  var eventId = Props.eventId;
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var actions = Props.actions;
  var maybeFromPropertyRef = ModelUtils.getPropertyRefById(eventId, property.id, masterModel);
  var maybeToPropertyRef = ModelUtils.getPropertyRefById(eventId, property.id, branchModel);
  var addedToIntercomAllowlist = Belt_List.getByU(actions, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          if (action.NAME !== "AddPropertyToWhitelist") {
            return false;
          }
          var match = action.VAL;
          if (match[0] === eventId) {
            return match[1] === property.id;
          } else {
            return false;
          }
        }));
  var removedFromIntercomAllowlist = Belt_List.getByU(actions, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          if (action.NAME !== "RemovePropertyFromWhitelist") {
            return false;
          }
          var match = action.VAL;
          if (match[0] === eventId) {
            return match[1] === property.id;
          } else {
            return false;
          }
        }));
  var fromPinnedValues = PropertyUtils.getPinnedValues(maybeFromPropertyRef, property.id, eventId, masterModel);
  var fromPinnedValue = fromPinnedValues === /* [] */0 ? "" : "Pinned to: " + Belt_List.toArray(fromPinnedValues).join(", ");
  var toPinnedValues = PropertyUtils.getPinnedValues(maybeToPropertyRef, property.id, eventId, branchModel);
  var toPinnedValue = toPinnedValues === /* [] */0 ? "" : "Pinned to: " + Belt_List.toArray(toPinnedValues).join(", ");
  return React.createElement("div", {
              className: containerStyles(colors[0], colors[1])
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, addedToIntercomAllowlist !== undefined ? (
                    removedFromIntercomAllowlist !== undefined ? null : React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.marginRight(Css.px(5)),
                                  tl: /* [] */0
                                })
                          }, React.createElement(Icons.Destinations.Intercom.make, {
                                size: 10
                              }))
                  ) : (
                    removedFromIntercomAllowlist !== undefined ? React.createElement("div", {
                            className: Curry._1(Css.merge, {
                                  hd: Curry._1(Css.style, Styles.button),
                                  tl: {
                                    hd: Curry._1(Css.style, {
                                          hd: Css.opacity(0.5),
                                          tl: {
                                            hd: Css.filter({
                                                  hd: {
                                                    NAME: "grayscale",
                                                    VAL: 100.0
                                                  },
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.marginRight(Css.px(5)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement(Icons.Destinations.Intercom.make, {
                                size: 10
                              })) : null
                  ), React.createElement("div", {
                      className: propertyNameRowStyles
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          children: property.name
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Regular",
                          children: JsDiff.renderStringDiff(undefined, true, false, fromPinnedValue, toPinnedValue)
                        }))), React.createElement(DiffEventPropertiesProperty$PropertyNameMappingDiff, {
                  branchModel: branchModel,
                  masterModel: masterModel,
                  eventId: eventId,
                  propertyId: property.id
                }));
}

var make = DiffEventPropertiesProperty;

export {
  PropertyNameMappingDiff ,
  containerStyles ,
  propertyNameRowStyles ,
  make ,
  
}
/* propertyNameRowStyles Not a pure module */
