// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function make(param) {
  if (mixpanel == null) {
    return ;
  } else {
    return Caml_option.some(mixpanel);
  }
}

export {
  make ,
  
}
/* No side effect */
