// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Mantine from "./Mantine.mjs";
import * as AvoEngagement from "./avoEngagement/AvoEngagement.mjs";
import * as AvoProductTour__Root from "./productTour/AvoProductTour__Root.mjs";
import * as AvoProductTour__Step from "./productTour/AvoProductTour__Step.mjs";
import * as AvoProductTour__GenericStep from "./productTour/AvoProductTour__GenericStep.mjs";

function HowToSaveViewAndGetAlertsFlow(Props) {
  var match = AvoEngagement.Engagement.use("how-to-save-a-view-and-get-alerts");
  var intent = match[1];
  var flow = match[0];
  React.useEffect((function () {
          Curry._1(intent.exit, undefined);
          return (function (param) {
                    return Curry._1(intent.exit, undefined);
                  });
        }), []);
  var match$1 = React.useState(function () {
        return 500;
      });
  var setDelay = match$1[1];
  var delay = match$1[0];
  Hooks.useDidUpdate1((function (param) {
          if (flow.state.active) {
            Curry._1(setDelay, (function (param) {
                    return 0;
                  }));
          }
          
        }), [flow.state.active]);
  return React.createElement(AvoProductTour__Root.make, {
              engagementId: "how-to-save-a-view-and-get-alerts",
              showStepCount: true,
              children: null
            }, React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-1",
                  arrowOffset: 60,
                  delay: delay,
                  elementClickable: false,
                  elementPadding: 4,
                  position: "left-start",
                  stepType: {
                    TAG: /* OnElementId */0,
                    _0: "save-view-for-issues"
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: [{
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }],
                  children: React.createElement(AvoProductTour__GenericStep.make, {})
                }), React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-2",
                  arrowOffset: 60,
                  delay: delay,
                  elementClickable: false,
                  elementPadding: 4,
                  position: "left-start",
                  stepType: {
                    TAG: /* OnElementId */0,
                    _0: "set-up-alerts"
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  children: React.createElement(AvoProductTour__GenericStep.make, {})
                }), React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-3",
                  delay: delay,
                  elementClickable: false,
                  position: "right",
                  stepType: {
                    TAG: /* OnElementId */0,
                    _0: "intercom-sidebar-itemInspectorSavedViews"
                  },
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitionDuration: 400,
                  transitions: [{
                      key: "complete",
                      label: "Finish!",
                      icon: undefined
                    }],
                  children: React.createElement(AvoProductTour__GenericStep.make, {
                        withExit: false
                      })
                }));
}

var make = HowToSaveViewAndGetAlertsFlow;

export {
  make ,
  
}
/* Hooks Not a pure module */
