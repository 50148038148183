// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "./styles.mjs";

function IconPhoto(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 32;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light02;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              viewBox: "0 0 32 32",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  className: "nc-icon-wrapper",
                  fill: Styles.Color.toString(color),
                  stroke: Styles.Color.toString(color),
                  strokeLinecap: "square",
                  strokeWidth: "2"
                }, React.createElement("rect", {
                      height: "24",
                      width: "30",
                      fill: "none",
                      stroke: Styles.Color.toString(color),
                      x: "1",
                      y: "6"
                    }), React.createElement("line", {
                      fill: "none",
                      x1: "7",
                      x2: "25",
                      y1: "2",
                      y2: "2"
                    }), React.createElement("polygon", {
                      fill: "none",
                      points: "8 25 20 14 27 25 8 25"
                    }), React.createElement("circle", {
                      cx: "8.5",
                      cy: "13.5",
                      fill: "none",
                      r: "2.5"
                    })));
}

var make = IconPhoto;

export {
  make ,
  
}
/* react Not a pure module */
