// Generated by ReScript, PLEASE EDIT WITH CARE


function toHeader(format) {
  if (format >= 2) {
    return [
            "text/csv",
            "csv"
          ];
  } else {
    return [
            "application/json",
            "json"
          ];
  }
}

function toAnalytics(t) {
  switch (t) {
    case /* JsonSchema */0 :
        return "Json";
    case /* AvoJson */1 :
        return "AvoJson";
    case /* Csv */2 :
        return "Csv";
    
  }
}

export {
  toHeader ,
  toAnalytics ,
  
}
/* No side effect */
