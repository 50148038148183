// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Hooks from "./Hooks.mjs";
import * as Input from "./Input.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as IconSend from "./IconSend.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as SsoChecks from "./SsoChecks.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconPassword from "./IconPassword.mjs";
import * as QueryString from "query-string";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as OnboardingStep from "./OnboardingStep.mjs";
import * as OnboardingConfig from "./OnboardingConfig.mjs";
import * as AuthenticationMethods from "./AuthenticationMethods.mjs";
import * as ContinueWithGoogleButton from "./ContinueWithGoogleButton.mjs";
import * as OnboardingNewOrJoinModal from "./OnboardingNewOrJoinModal.mjs";

function trackOnboardingAction(state, action) {
  return AnalyticsRe.onboardingAction(Belt_Array.map(Belt_Set.toArray(state.sourceGroups), OnboardingConfig.sourceGroupToJs), Belt_List.toArray(state.destinations), Belt_Set.toArray(state.jobFunction), state.hasSeenJoinInstructions, Belt_Set.toArray(state.contribution), state.companyRole, state.companySize, action, state.email === "" ? undefined : state.email);
}

function shouldEmailAskToJoin(emailDomain) {
  var emailDomain$1 = encodeURIComponent(Belt_Option.getWithDefault(Belt_Array.get(emailDomain.split("@"), 1), ""));
  return fetch("/api/signup/shouldEmailAskToJoin?emailDomain=" + emailDomain$1, Fetch.RequestInit.make(/* Get */0, [[
                          "Accept",
                          "application/json"
                        ]], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                return prim.json();
              }).then(function (result) {
              return Promise.resolve(Belt_Option.getWithDefault(Js_json.decodeBoolean(result), false));
            });
}

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* UpdateEmail */0 :
        var email = action._0;
        return {
                email: email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: state.contribution,
                position: state.position,
                emailSso: email === state.email ? state.emailSso : /* Unknown */0,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* SetJoinInstructionsLoading */1 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: action._0,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: state.contribution,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* ShowNewOrJoinModal */2 :
        var showNewOrJoinModal = action._0;
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.showNewOrJoinModal ? true : showNewOrJoinModal,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: state.contribution,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdatePassword */3 :
        return {
                email: state.email,
                password: action._0,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: state.contribution,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* SsoDetected */4 :
        var match = action._0 === state.email;
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: state.contribution,
                position: state.position,
                emailSso: match ? (
                    action._1 ? /* HasSso */1 : /* HasNoSso */2
                  ) : state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdateSignUpStatus */5 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: action._0,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: state.contribution,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdateJobFunction */6 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: action._0,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: state.contribution,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdateCompanyRole */7 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: action._0,
                companySize: state.companySize,
                contribution: state.contribution,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdateCompanySize */8 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: action._0,
                contribution: state.contribution,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdateContribution */9 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: action._0,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdatePosition */10 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: state.contribution,
                position: action._0,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* ToggleSourceGroup */11 :
        var sourceGroup = action._0;
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: state.contribution,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: Belt_Set.has(state.sourceGroups, sourceGroup) ? Belt_Set.remove(state.sourceGroups, sourceGroup) : Belt_Set.add(state.sourceGroups, sourceGroup),
                destinations: state.destinations
              };
    case /* ToggleDestination */12 :
        var destination = action._0;
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                companyRole: state.companyRole,
                companySize: state.companySize,
                contribution: state.contribution,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: Belt_List.some(state.destinations, (function (item) {
                        return item === destination;
                      })) ? Belt_List.keep(state.destinations, (function (item) {
                          return item !== destination;
                        })) : Belt_List.concat(state.destinations, {
                        hd: destination,
                        tl: /* [] */0
                      })
              };
    
  }
}

function Onboarding(Props) {
  var onboardingRoute = Props.onboardingRoute;
  var forceReloadUser = Props.forceReloadUser;
  var match = React.useReducer(reducer, OnboardingConfig.getInitialState(onboardingRoute));
  var send = match[1];
  var state = match[0];
  var auth = Firebase.getAuth(undefined);
  var previousOnboardingRoute = Hooks.usePrevious1(onboardingRoute);
  var previousSignupStatus = Hooks.usePrevious1(state.signUpStatus);
  var pushStepToRouter = function (step) {
    return Router.push(undefined, Curry._4(Router.Link.get, {
                    NAME: "onboarding",
                    VAL: step
                  }, undefined, undefined, /* [] */0));
  };
  React.useEffect((function () {
          var query = window.location.search.replace("?", "");
          QueryString.parse(query).email;
          var newWorkspace = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(QueryString.parse(query).new_workspace), false);
          var startOnboarding = function (param) {
            Router.replace(Curry._4(Router.Link.get, {
                      NAME: "onboarding",
                      VAL: "job"
                    }, undefined, undefined, /* [] */0));
            AnalyticsRe.onboardingStarted(undefined);
            
          };
          var match = auth.currentUser;
          if (!(match == null) && !match.isAnonymous) {
            if (newWorkspace) {
              pushStepToRouter("job");
            } else {
              Router.push(undefined, Router.Link.getBaseRoute(undefined, "welcome"));
            }
          } else {
            startOnboarding(undefined);
          }
          Intercom.update({
                alignment: "left"
              });
          
        }), []);
  Hooks.useDidUpdate1((function (param) {
          var match = OnboardingConfig.validateStep(state, previousOnboardingRoute);
          var currentStepValid = match === "ValidEmptyState" || match === "Valid";
          var routeDirection = OnboardingConfig.getDirection(previousOnboardingRoute, onboardingRoute);
          var exit = 0;
          if (routeDirection === "Back" || routeDirection === "Forward" && currentStepValid) {
            exit = 1;
          }
          if (exit === 1) {
            Curry._1(send, {
                  TAG: /* UpdatePosition */10,
                  _0: {
                    NAME: "StartTransition",
                    VAL: [
                      previousOnboardingRoute,
                      onboardingRoute
                    ]
                  }
                });
          }
          
        }), [onboardingRoute]);
  Hooks.useDidUpdate1((function (param) {
          var match = state.signUpStatus;
          var exit = 0;
          if (previousSignupStatus === "Success") {
            if (match !== "Success") {
              exit = 1;
            }
            
          } else if (!(previousSignupStatus === "SigningUpViaGoogle" && match === "SigningUpViaGoogle")) {
            exit = 1;
          }
          if (exit === 1) {
            var match$1 = state.signUpStatus;
            if (typeof match$1 === "object" || match$1 === "Initial") {
              
            } else if (match$1 === "SigningUpViaEmail") {
              $$Promise.$$catch(AuthenticationMethods.signUpViaEmailAndPassword(auth, state.email, state.password, forceReloadUser).then(function (param) {
                        return Curry._1(send, {
                                    TAG: /* UpdateSignUpStatus */5,
                                    _0: "Success"
                                  });
                      }), (function (error) {
                      var match = AuthenticationMethods.Utils.getAuthenticationErrorFromException(error);
                      return Promise.resolve((AnalyticsRe.signUpFailed(state.email, match[0]), pushStepToRouter("password"), Curry._1(send, {
                                        TAG: /* UpdateSignUpStatus */5,
                                        _0: {
                                          NAME: "Error",
                                          VAL: match[1]
                                        }
                                      })));
                    }));
            } else if (match$1 === "SigningUpViaGoogle") {
              $$Promise.$$catch(AuthenticationMethods.signInViaGoogle(auth, undefined, forceReloadUser).then(function (_userCredentials) {
                        return Curry._1(send, {
                                    TAG: /* UpdateSignUpStatus */5,
                                    _0: "Success"
                                  });
                      }), (function (error) {
                      var match = AuthenticationMethods.Utils.getAuthenticationErrorFromException(error);
                      return Promise.resolve((AnalyticsRe.signUpFailed(state.email, match[0]), pushStepToRouter("password"), Curry._1(send, {
                                        TAG: /* UpdateSignUpStatus */5,
                                        _0: {
                                          NAME: "Error",
                                          VAL: match[1]
                                        }
                                      })));
                    }));
            } else {
              pushStepToRouter("loading");
              FirebaseUtils.buildSchemaFromOnboarding(auth, state.sourceGroups, state.destinations, Belt_Set.toArray(state.jobFunction), state.companyRole, state.companySize, Belt_Set.toArray(state.contribution), state.hasSeenJoinInstructions);
            }
          }
          
        }), [state.signUpStatus]);
  var transitionTimeoutId = React.useRef(undefined);
  Hooks.useDidUpdate1((function (param) {
          var match = state.position;
          if (typeof match === "object") {
            var variant = match.NAME;
            if (variant === "StartTransition") {
              var match$1 = match.VAL;
              var toStep = match$1[1];
              var fromStep = match$1[0];
              if (toStep !== "loading") {
                var timeoutId = setTimeout((function (param) {
                        return Curry._1(send, {
                                    TAG: /* UpdatePosition */10,
                                    _0: {
                                      NAME: "FinishTransition",
                                      VAL: [
                                        fromStep,
                                        toStep
                                      ]
                                    }
                                  });
                      }), 500);
                transitionTimeoutId.current = Caml_option.some(timeoutId);
              }
              
            } else if (variant === "FinishTransition") {
              var toStep$1 = match.VAL[1];
              var timeoutId$1 = setTimeout((function (param) {
                      return Curry._1(send, {
                                  TAG: /* UpdatePosition */10,
                                  _0: {
                                    NAME: "Step",
                                    VAL: toStep$1
                                  }
                                });
                    }), 500);
              transitionTimeoutId.current = Caml_option.some(timeoutId$1);
            }
            
          }
          var match$2 = state.position;
          if (typeof match$2 === "object" && match$2.NAME === "StartTransition") {
            var match$3 = match$2.VAL[0];
            if (match$3 === "contribution") {
              trackOnboardingAction(state, "DefineContribution");
            } else if (match$3 === "job") {
              trackOnboardingAction(state, "SelectRole");
            } else if (match$3 === "destinations") {
              trackOnboardingAction(state, "DefineDestination");
            } else if (match$3 === "sources") {
              trackOnboardingAction(state, "DefineSource");
            }
            
          }
          return (function (param) {
                    return Belt_Option.forEach(transitionTimeoutId.current, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [state.position]);
  var match$1 = state.position;
  var variant = match$1.NAME;
  var currentStep;
  if (variant === "FinishTransition") {
    var match$2 = match$1.VAL[1];
    currentStep = match$2 === "contribution" ? "contribution" : (
        match$2 === "job" ? "job" : (
            match$2 === "loading" ? "loading" : (
                match$2 === "destinations" ? "destinations" : (
                    match$2 === "sources" ? "sources" : "password"
                  )
              )
          )
      );
  } else if (variant === "Step") {
    var match$3 = match$1.VAL;
    currentStep = match$3 === "contribution" ? "contribution" : (
        match$3 === "job" ? "job" : (
            match$3 === "loading" ? "loading" : (
                match$3 === "destinations" ? "destinations" : (
                    match$3 === "sources" ? "sources" : "password"
                  )
              )
          )
      );
  } else {
    var match$4 = match$1.VAL[0];
    currentStep = match$4 === "contribution" ? "contribution" : (
        match$4 === "job" ? "job" : (
            match$4 === "loading" ? "loading" : (
                match$4 === "destinations" ? "destinations" : (
                    match$4 === "sources" ? "sources" : "password"
                  )
              )
          )
      );
  }
  var isValid = OnboardingConfig.validateStep(state, currentStep);
  var tmp;
  if (currentStep === "contribution") {
    tmp = React.createElement(OnboardingStep.make, {
          step: "contribution",
          title: "How do you contribute to event tracking within your organization?",
          subtitle: "This helps Avo personalize your onboarding experience.",
          isValid: isValid,
          onNext: (function (param) {
              return Belt_Option.forEach(OnboardingConfig.getNext("contribution"), pushStepToRouter);
            }),
          maySkip: true,
          children: null
        }, React.createElement("p", {
              className: OnboardingStep.headerStyles("green")
            }, "Select all that apply"), React.createElement(Spacer.make, {
              height: 16
            }), React.createElement("div", {
              className: OnboardingStep.pillBoxStyles
            }, Belt_Array.map([
                  "ImplementAnalytics",
                  "DefineEventDataStructures",
                  "ReviewEventDataStructures",
                  "DetectDataAnomalies",
                  "SetProductMinusrelatedGoalsAndMetrics",
                  "Other"
                ], (function (contribution) {
                    return React.createElement(OnboardingStep.Pill.make, {
                                selected: Belt_Set.has(state.contribution, contribution),
                                label: AnalyticsRe.trackingContributionToJs(contribution),
                                style: "green",
                                onClick: (function (param) {
                                    return Curry._1(send, {
                                                TAG: /* UpdateContribution */9,
                                                _0: Belt_Set.has(state.contribution, contribution) ? Belt_Set.remove(state.contribution, contribution) : Belt_Set.add(state.contribution, contribution)
                                              });
                                  }),
                                key: AnalyticsRe.trackingContributionToJs(contribution)
                              });
                  }))));
  } else if (currentStep === "job") {
    tmp = React.createElement(OnboardingStep.make, {
          step: "job",
          title: "Tell us about yourself",
          subtitle: "This helps Avo personalize your onboarding experience.",
          isValid: isValid,
          onNext: (function (param) {
              return Belt_Option.forEach(OnboardingConfig.getNext("job"), pushStepToRouter);
            }),
          maySkip: true,
          children: null
        }, React.createElement("p", {
              className: OnboardingStep.headerStyles("pink")
            }, "What kind of work do you do? ", React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight({
                              NAME: "num",
                              VAL: 200
                            }),
                        tl: {
                          hd: Css.fontFamily(Styles.FontFamily.acid),
                          tl: /* [] */0
                        }
                      })
                }, "(Select all that apply)")), React.createElement(Spacer.make, {
              height: 16
            }), React.createElement("div", {
              className: OnboardingStep.pillBoxStyles
            }, Belt_Array.map([
                  "ProductAnalytics",
                  "DataScience",
                  "SoftwareEngineering",
                  "ProductManagement",
                  "Marketing",
                  "Other",
                  "DataAnalyticsEngineering"
                ], (function (jobFunction) {
                    return React.createElement(OnboardingStep.Pill.make, {
                                selected: Belt_Set.has(state.jobFunction, jobFunction),
                                label: AnalyticsRe.onboardingJobFunctionToJs(jobFunction),
                                style: "pink",
                                onClick: (function (param) {
                                    return Curry._1(send, {
                                                TAG: /* UpdateJobFunction */6,
                                                _0: Belt_Set.has(state.jobFunction, jobFunction) ? Belt_Set.remove(state.jobFunction, jobFunction) : Belt_Set.add(state.jobFunction, jobFunction)
                                              });
                                  }),
                                key: AnalyticsRe.onboardingJobFunctionToJs(jobFunction)
                              });
                  }))), React.createElement(Spacer.make, {
              height: 32
            }), React.createElement("p", {
              className: OnboardingStep.headerStyles("purple")
            }, "What’s your role?"), React.createElement(Spacer.make, {
              height: 16
            }), React.createElement(Select.make, {
              additionalStyles: OnboardingStep.selectorStyles,
              onSelect: (function (selected) {
                  return Curry._1(send, {
                              TAG: /* UpdateCompanyRole */7,
                              _0: Belt_Option.getExn(AnalyticsRe.companyRoleFromJs(selected))
                            });
                }),
              options: Belt_Array.concat([[
                      {
                        NAME: "Disabled",
                        VAL: "Select role"
                      },
                      "Select role"
                    ]], Belt_Array.map(Belt_Array.map([
                            "ExecutiveVPCMinussuite",
                            "HeadOfTeam",
                            "PeopleManager",
                            "PlayerMinuscoach",
                            "IndividualContributor",
                            "ContractorFreelancer"
                          ], AnalyticsRe.companyRoleToJs), (function (role) {
                          return [
                                  {
                                    NAME: "Label",
                                    VAL: role
                                  },
                                  role
                                ];
                        }))),
              value: Belt_Option.mapWithDefault(state.companyRole, "Select role", AnalyticsRe.companyRoleToJs)
            }), React.createElement(Spacer.make, {
              height: 32
            }), React.createElement("p", {
              className: OnboardingStep.headerStyles("purple")
            }, "What’s your company’s size?"), React.createElement(Spacer.make, {
              height: 16
            }), React.createElement(Select.make, {
              additionalStyles: OnboardingStep.selectorStyles,
              onSelect: (function (selected) {
                  return Curry._1(send, {
                              TAG: /* UpdateCompanySize */8,
                              _0: Belt_Option.getExn(AnalyticsRe.workspaceCompanySizeFromJs(selected))
                            });
                }),
              options: Belt_Array.concat([[
                      {
                        NAME: "Disabled",
                        VAL: "Select size"
                      },
                      "Select size"
                    ]], Belt_Array.map(Belt_Array.map(OnboardingConfig.companySizes, AnalyticsRe.workspaceCompanySizeToJs), (function (size) {
                          return [
                                  {
                                    NAME: "Label",
                                    VAL: size
                                  },
                                  size
                                ];
                        }))),
              value: Belt_Option.mapWithDefault(state.companySize, "Select size", AnalyticsRe.workspaceCompanySizeToJs)
            }));
  } else if (currentStep === "loading") {
    tmp = null;
  } else if (currentStep === "destinations") {
    tmp = React.createElement(OnboardingStep.make, {
          step: "destinations",
          title: "Which downstream tools are in your data stack?",
          subtitle: "Avo helps you document where you send your events and customize them per destination if needed.",
          comfortText: "Don’t worry you can always edit your destinations later.",
          isValid: isValid,
          onNext: (function (param) {
              var user = auth.currentUser;
              if (!(user == null) && !user.isAnonymous) {
                pushStepToRouter("loading");
                return FirebaseUtils.buildSchemaFromOnboarding(auth, state.sourceGroups, state.destinations, Belt_Set.toArray(state.jobFunction), state.companyRole, state.companySize, Belt_Set.toArray(state.contribution), state.hasSeenJoinInstructions);
              } else {
                return pushStepToRouter("password");
              }
            }),
          maySkip: true,
          children: Belt_Array.map(AvoConfig.groupedAnalyticsTools, (function (param) {
                  var groupName = param[0];
                  return React.createElement("div", {
                              key: groupName,
                              className: Curry._1(Css.style, {
                                    hd: Css.marginBottom(Css.px(32)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement("p", {
                                  className: OnboardingStep.headerStyles("purple")
                                }, groupName), React.createElement(Spacer.make, {
                                  height: 16
                                }), React.createElement("div", {
                                  className: OnboardingStep.pillBoxStyles
                                }, Belt_Array.map(param[1], (function (tool) {
                                        return React.createElement(OnboardingStep.Pill.make, {
                                                    selected: Belt_List.some(state.destinations, (function (destination) {
                                                            return destination === tool;
                                                          })),
                                                    label: AvoConfig.analyticsToolsLabel(tool),
                                                    style: "purple",
                                                    onClick: (function (param) {
                                                        return Curry._1(send, {
                                                                    TAG: /* ToggleDestination */12,
                                                                    _0: tool
                                                                  });
                                                      }),
                                                    key: AvoConfig.analyticsToolsLabel(tool)
                                                  });
                                      }))));
                }))
        });
  } else if (currentStep === "sources") {
    tmp = React.createElement(OnboardingStep.make, {
          step: "sources",
          title: "Which of your platforms and products have event tracking?",
          subtitle: "Avo makes sure the events in your tracking plan will be consistent across all your platforms.",
          comfortText: "Don’t worry, you can always edit your sources later.",
          isValid: isValid,
          onNext: (function (param) {
              return Belt_Option.forEach(OnboardingConfig.getNext("sources"), pushStepToRouter);
            }),
          maySkip: true,
          children: null
        }, React.createElement("p", {
              className: OnboardingStep.headerStyles("purple")
            }, "Select all that apply"), React.createElement(Spacer.make, {
              height: 16
            }), React.createElement("div", {
              className: OnboardingStep.pillBoxStyles
            }, Belt_Array.map(OnboardingConfig.sourceGroups, (function (sourceGroup) {
                    return React.createElement(OnboardingStep.Pill.make, {
                                selected: Belt_Set.has(state.sourceGroups, sourceGroup),
                                label: OnboardingConfig.sourceGroupToJs(sourceGroup),
                                style: "blue",
                                onClick: (function (param) {
                                    return Curry._1(send, {
                                                TAG: /* ToggleSourceGroup */11,
                                                _0: sourceGroup
                                              });
                                  })
                              });
                  }))));
  } else {
    var handleCheckForEmailDomain = function (email) {
      Curry._1(send, {
            TAG: /* SetJoinInstructionsLoading */1,
            _0: true
          });
      $$Promise.$$catch(shouldEmailAskToJoin(email).then(function (shouldAskToJoin) {
                Curry._1(send, {
                      TAG: /* SetJoinInstructionsLoading */1,
                      _0: false
                    });
                trackOnboardingAction({
                      email: email,
                      password: state.password,
                      signUpStatus: state.signUpStatus,
                      showNewOrJoinModal: state.showNewOrJoinModal,
                      loadingJoinInstructions: state.loadingJoinInstructions,
                      hasSeenJoinInstructions: state.hasSeenJoinInstructions ? true : shouldAskToJoin,
                      jobFunction: state.jobFunction,
                      companyRole: state.companyRole,
                      companySize: state.companySize,
                      contribution: state.contribution,
                      position: state.position,
                      emailSso: state.emailSso,
                      sourceGroups: state.sourceGroups,
                      destinations: state.destinations
                    }, "AddEMinusmail");
                if (shouldAskToJoin) {
                  Curry._1(send, {
                        TAG: /* ShowNewOrJoinModal */2,
                        _0: true
                      });
                } else {
                  Curry._1(send, {
                        TAG: /* UpdateSignUpStatus */5,
                        _0: "SigningUpViaEmail"
                      });
                }
                return Promise.resolve(undefined);
              }), (function (param) {
              Curry._1(send, {
                    TAG: /* SetJoinInstructionsLoading */1,
                    _0: false
                  });
              trackOnboardingAction(state, "AddEMinusmail");
              Curry._1(send, {
                    TAG: /* UpdateSignUpStatus */5,
                    _0: "SigningUpViaEmail"
                  });
              return Promise.resolve(undefined);
            }));
      
    };
    var match$5 = state.signUpStatus;
    var match$6 = state.emailSso;
    tmp = React.createElement(OnboardingStep.make, {
          step: "password",
          title: "Complete your account",
          subtitle: "Sign in with Google or pick a password to log in",
          isValid: isValid,
          onNext: (function (param) {
              var email = state.email;
              var match = state.emailSso;
              if (typeof isValid === "object") {
                return ;
              }
              switch (match) {
                case /* Unknown */0 :
                    SsoChecks.ssoEmailCheck(email).then(function (ssoMode) {
                          if (ssoMode === /* NotSso */0) {
                            handleCheckForEmailDomain(email);
                          } else {
                            Router.push(undefined, "/login?email=" + email);
                          }
                          return Promise.resolve(undefined);
                        });
                    return ;
                case /* HasSso */1 :
                    return Router.push(undefined, Router.Link.getBaseRoute(undefined, "login") + "?email=" + state.email);
                case /* HasNoSso */2 :
                    return handleCheckForEmailDomain(email);
                
              }
            }),
          loading: state.loadingJoinInstructions,
          children: null
        }, state.showNewOrJoinModal ? React.createElement(OnboardingNewOrJoinModal.make, {
                onClose: (function (param) {
                    Curry._1(send, {
                          TAG: /* ShowNewOrJoinModal */2,
                          _0: false
                        });
                    return trackOnboardingAction(state, "DismissExistingWorkspacePrompt");
                  }),
                onCreate: (function (param) {
                    Curry._1(send, {
                          TAG: /* UpdateSignUpStatus */5,
                          _0: "SigningUpViaEmail"
                        });
                    return trackOnboardingAction(state, "ClickCreateWorkspaceOnExistingWorkspacePrompt");
                  }),
                onInvite: (function (param) {
                    return Router.push(undefined, Router.Link.getBaseRoute(undefined, "login"));
                  })
              }) : null, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.maxWidth(Css.px(600)),
                    tl: /* [] */0
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(20)),
                        tl: /* [] */0
                      })
                }, React.createElement(ContinueWithGoogleButton.make, {
                      onClick: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(send, {
                                      TAG: /* UpdateSignUpStatus */5,
                                      _0: "SigningUpViaGoogle"
                                    });
                        }),
                      disabled: state.signUpStatus === "SigningUpViaGoogle",
                      backgroundColor: Styles.Color.white,
                      textColor: Styles.Color.light12
                    })), React.createElement("form", {
                  id: "form",
                  onSubmit: (function (param) {
                      return Curry._1(send, {
                                  TAG: /* UpdateSignUpStatus */5,
                                  _0: "SigningUpViaEmail"
                                });
                    })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding2(Css.px(0), Css.px(20)),
                            tl: /* [] */0
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.marginTop(Css.px(30)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.height(Css.px(1)),
                                    tl: {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.light07),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.margin2(Css.px(10), Css.px(30)),
                                    tl: {
                                      hd: Css.color(Styles.Color.light08),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.flexShrink(0.0),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, "or use email"), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.height(Css.px(1)),
                                    tl: {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.light07),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }))), React.createElement("div", undefined, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light07),
                                    tl: {
                                      hd: Css.margin2(Css.px(20), Css.px(20)),
                                      tl: {
                                        hd: Css.media("(max-width: 900px)", {
                                              hd: Css.margin2(Css.px(50), Css.px(10)),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement(IconSend.make, {
                              size: 20,
                              color: Styles.Color.avoPink
                            }), React.createElement(Input.make, {
                              value: state.email,
                              onChange: (function (email) {
                                  Curry._1(send, {
                                        TAG: /* UpdateEmail */0,
                                        _0: email
                                      });
                                  return SsoChecks.debouncedSsoEmailCheck(email, (function (ssoMode) {
                                                return Curry._1(send, {
                                                            TAG: /* SsoDetected */4,
                                                            _0: email,
                                                            _1: ssoMode !== /* NotSso */0
                                                          });
                                              }));
                                }),
                              name: "email",
                              autoFocus: state.email === "",
                              onEnter: (function (param) {
                                  return Curry._1(send, {
                                              TAG: /* UpdateSignUpStatus */5,
                                              _0: "SigningUpViaEmail"
                                            });
                                }),
                              placeholder: "name@work.com",
                              className: Belt_List.reverse({
                                    hd: Css.padding4(Css.px(10), Css.px(0), Css.px(10), Css.px(15)),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.huge),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(0)),
                                          tl: {
                                            hd: Css.display("block"),
                                            tl: {
                                              hd: Css.color(Styles.Color.light11),
                                              tl: {
                                                hd: Css.media("(max-width: 900px)", {
                                                      hd: Css.fontSize(Css.px(20)),
                                                      tl: {
                                                        hd: Css.padding4(Css.px(10), Css.px(0), Css.px(10), Css.px(10)),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: Styles.input
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light07),
                                    tl: {
                                      hd: Css.margin2(Css.px(50), Css.px(20)),
                                      tl: {
                                        hd: Css.media("(max-width: 900px)", {
                                              hd: Css.margin2(Css.px(50), Css.px(10)),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement(IconPassword.make, {
                              size: 20,
                              color: Styles.Color.avoPink
                            }), React.createElement(Input.make, {
                              value: state.password,
                              onChange: (function (email) {
                                  return Curry._1(send, {
                                              TAG: /* UpdatePassword */3,
                                              _0: email
                                            });
                                }),
                              name: "password",
                              autoFocus: state.email !== "",
                              onEnter: (function (param) {
                                  return Curry._1(send, {
                                              TAG: /* UpdateSignUpStatus */5,
                                              _0: "SigningUpViaEmail"
                                            });
                                }),
                              placeholder: "password",
                              className: Belt_List.reverse({
                                    hd: Css.padding4(Css.px(10), Css.px(0), Css.px(10), Css.px(15)),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.huge),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(0)),
                                          tl: {
                                            hd: Css.display("block"),
                                            tl: {
                                              hd: Css.color(Styles.Color.light11),
                                              tl: {
                                                hd: Css.media("(max-width: 900px)", {
                                                      hd: Css.fontSize(Css.px(20)),
                                                      tl: {
                                                        hd: Css.padding4(Css.px(10), Css.px(0), Css.px(10), Css.px(10)),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: Styles.input
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }),
                              type_: "password"
                            }))), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding2(Css.px(0), Css.px(20)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.color(Styles.Color.light10),
                                tl: {
                                  hd: Css.media("(max-width: 900px)", {
                                        hd: Css.marginTop(Css.px(20)),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, "By continuing you agree to our ", React.createElement("a", {
                          href: "https://www.avo.app/terms",
                          target: "_blank"
                        }, "Terms and Conditions"), ", ", React.createElement("a", {
                          href: "https://www.avo.app/privacy",
                          target: "_blank"
                        }, "Privacy Policy"), " and ", React.createElement("a", {
                          href: "https://www.avo.app/cookies",
                          target: "_blank"
                        }, "Cookie Policy"), "."), typeof match$5 === "object" ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.color(Styles.Color.red),
                                tl: {
                                  hd: Css.padding(Css.px(20)),
                                  tl: {
                                    hd: Css.marginTop(Css.px(30)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, match$5.VAL) : (
                    match$5 === "SigningUpViaGoogle" || match$5 === "SigningUpViaEmail" ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.padding(Css.px(20)),
                                    tl: {
                                      hd: Css.marginTop(Css.px(30)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement("div", undefined, React.createElement(LoadingCircle.make, {
                                    size: 16,
                                    color: Styles.Color.avoPink
                                  })), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginLeft(Css.px(10)),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.color(Styles.Color.light10),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, "Creating user...")) : null
                  ), match$6 !== 1 ? null : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.color(Styles.Color.light10),
                                tl: {
                                  hd: Css.padding(Css.px(20)),
                                  tl: {
                                    hd: Css.marginTop(Css.px(30)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, "We detected SSO enabled for this email."))));
  }
  var match$7 = state.position;
  var tmp$1;
  var exit = 0;
  if (typeof match$7 === "object") {
    var variant$1 = match$7.NAME;
    if (variant$1 === "StartTransition") {
      var match$8 = match$7.VAL;
      if (OnboardingConfig.getDirection(match$8[0], match$8[1]) === "Forward") {
        tmp$1 = {
          hd: Css.transform(Css.translate3d(Css.pct(0), Css.px(0), Css.px(0))),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: 500.0
                }, undefined, "easeInOut", "transform"),
            tl: /* [] */0
          }
        };
      } else {
        exit = 1;
      }
    } else if (variant$1 === "FinishTransition") {
      var match$9 = match$7.VAL;
      if (OnboardingConfig.getDirection(match$9[0], match$9[1]) === "Forward") {
        tmp$1 = {
          hd: Css.transform(Css.translate3d(Css.pct(-100), Css.px(0), Css.px(0))),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: 500.0
                }, undefined, "easeInOut", "transform"),
            tl: /* [] */0
          }
        };
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$1 = {
      hd: Css.transform(Css.translate3d(Css.pct(100), Css.px(0), Css.px(0))),
      tl: {
        hd: Css.transition({
              NAME: "ms",
              VAL: 0.0
            }, undefined, "easeInOut", "transform"),
        tl: /* [] */0
      }
    };
  }
  var match$10 = state.position;
  var match$11 = state.position;
  var tmp$2;
  var exit$1 = 0;
  if (typeof match$11 === "object") {
    var variant$2 = match$11.NAME;
    if (variant$2 === "StartTransition") {
      var match$12 = match$11.VAL;
      if (OnboardingConfig.getDirection(match$12[0], match$12[1]) === "Back") {
        tmp$2 = {
          hd: Css.transform(Css.translate3d(Css.pct(0), Css.px(0), Css.px(0))),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: 500.0
                }, undefined, "easeInOut", "transform"),
            tl: /* [] */0
          }
        };
      } else {
        exit$1 = 1;
      }
    } else if (variant$2 === "FinishTransition") {
      var match$13 = match$11.VAL;
      if (OnboardingConfig.getDirection(match$13[0], match$13[1]) === "Back") {
        tmp$2 = {
          hd: Css.transform(Css.translate3d(Css.pct(100), Css.px(0), Css.px(0))),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: 500.0
                }, undefined, "easeInOut", "transform"),
            tl: /* [] */0
          }
        };
      } else {
        exit$1 = 1;
      }
    } else {
      exit$1 = 1;
    }
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp$2 = {
      hd: Css.transform(Css.translate3d(Css.pct(-100), Css.px(0), Css.px(0))),
      tl: {
        hd: Css.transition({
              NAME: "ms",
              VAL: 0.0
            }, undefined, "easeInOut", "transform"),
        tl: /* [] */0
      }
    };
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.minHeight(Css.vh(100)),
                      tl: {
                        hd: Css.overflow("hidden"),
                        tl: {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, tmp, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.backgroundColor(OnboardingConfig.getColor(onboardingRoute)),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                    tl: tmp$1
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, typeof match$10 === "object" && match$10.NAME === "StartTransition" && match$10.VAL[1] === "loading" ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.top(Css.px(0)),
                                tl: {
                                  hd: Css.right(Css.px(0)),
                                  tl: {
                                    hd: Css.bottom(Css.px(0)),
                                    tl: {
                                      hd: Css.left(Css.px(0)),
                                      tl: {
                                        hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                        tl: {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.justifyContent("center"),
                                              tl: {
                                                hd: Css.flexDirection("column"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        id: "creating schema"
                      }, React.createElement(LoadingCircle.make, {
                            color: Styles.Color.white
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight(Styles.FontWeight.bold),
                                  tl: {
                                    hd: Css.color(Styles.Color.white),
                                    tl: {
                                      hd: Css.marginTop(Css.px(50)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, "Building your Avo workspace...")) : null), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.backgroundColor(OnboardingConfig.getColor(onboardingRoute)),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                    tl: tmp$2
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }));
}

var make = Onboarding;

export {
  make ,
  
}
/* Css Not a pure module */
