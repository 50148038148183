// Generated by ReScript, PLEASE EDIT WITH CARE


function get($$event) {
  var name = $$event.uniqueName;
  var name$1 = name !== undefined && name !== "" ? name : $$event.name;
  return name$1.replace(/[^\w\s\-]/ig, "");
}

export {
  get ,
  
}
/* No side effect */
