// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import ReactDropzone from "react-dropzone";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";

var wrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.width(Css.pct(100)),
          tl: /* [] */0
        }
      }
    });

var innerWrapper = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.width(Css.pct(100)),
              tl: {
                hd: Css.height({
                      NAME: "subtract",
                      VAL: [
                        Css.pct(100),
                        Css.px(60)
                      ]
                    }),
                tl: {
                  hd: Css.padding(Css.px(16)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function dropzone(uploadStatus) {
  return Curry._1(Css.style, {
              hd: Css.margin(Css.px(80)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.border(Css.px(2), "dashed", Styles.Color.light04),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.height(Css.pct(65)),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css.opacity(uploadStatus === "Idle" ? 1.0 : 0.75),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.opacity(0.75),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$default
                                        }, undefined, undefined, "all"),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var image = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.pct(100)),
      tl: {
        hd: Css.maxHeight(Css.pct(100)),
        tl: {
          hd: Css.objectFit("contain"),
          tl: /* [] */0
        }
      }
    });

var buttonDrop = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("center"),
        tl: {
          hd: Css.padding(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

var button = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: $$Text.sizeStyles("Small"),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.transitions({
                      hd: Css_Legacy_Core.Transition.shorthand({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "background-color"),
                      tl: {
                        hd: Css_Legacy_Core.Transition.shorthand({
                              NAME: "ms",
                              VAL: Styles.Duration.$$short
                            }, undefined, undefined, "border-color"),
                        tl: {
                          hd: Css_Legacy_Core.Transition.shorthand({
                                NAME: "ms",
                                VAL: Styles.Duration.$$short
                              }, undefined, undefined, "color"),
                          tl: /* [] */0
                        }
                      }
                    }),
                tl: {
                  hd: Css.paddingTop(Css.px(4)),
                  tl: {
                    hd: Css.paddingRight(Css.px(12)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(12)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(4)),
                        tl: {
                          hd: Css.outlineStyle("none"),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.disabled({
                                        hd: Css.cursor("default"),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: {
                                      hd: Css.borderWidth(Css.px(1)),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: {
                                          hd: Css.borderColor("transparent"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.active({
                                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                                    tl: /* [] */0
                                                  }),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: {
                                                  hd: Css.backgroundColor(Styles.Color.white),
                                                  tl: {
                                                    hd: Css.boxShadows({
                                                          hd: Styles.Shadow.standard,
                                                          tl: {
                                                            hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, "transparent"),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    tl: {
                                                      hd: Css.transition({
                                                            NAME: "ms",
                                                            VAL: Styles.Duration.$$default
                                                          }, undefined, undefined, "all"),
                                                      tl: {
                                                        hd: Css.disabled({
                                                              hd: Css.cursor("default"),
                                                              tl: {
                                                                hd: Css.color(Styles.Color.light07),
                                                                tl: {
                                                                  hd: Css.backgroundColor(Styles.Color.light02),
                                                                  tl: {
                                                                    hd: Css.boxShadows({
                                                                          hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent"),
                                                                          tl: /* [] */0
                                                                        }),
                                                                    tl: {
                                                                      hd: Css.hover({
                                                                            hd: Css.boxShadows({
                                                                                  hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent"),
                                                                                  tl: /* [] */0
                                                                                }),
                                                                            tl: {
                                                                              hd: Css.transform(Css.translateY(Css.px(0))),
                                                                              tl: {
                                                                                hd: Css.color(Styles.Color.light07),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }
                                                                          }),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.color(Styles.Color.light12),
                                                                tl: {
                                                                  hd: Css.transform(Css.translateY(Css.px(-2))),
                                                                  tl: {
                                                                    hd: Css.boxShadows({
                                                                          hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, "transparent"),
                                                                          tl: /* [] */0
                                                                        }),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }),
                                                          tl: {
                                                            hd: Css.focus({
                                                                  hd: Css.boxShadows({
                                                                        hd: Styles.Shadow.standard,
                                                                        tl: {
                                                                          hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.setAlpha(Styles.Color.darkBlue, 0.5)),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: {
                                                              hd: Css.hover(/* [] */0),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var S = {
  wrapper: wrapper,
  innerWrapper: innerWrapper,
  dropzone: dropzone,
  image: image,
  buttonDrop: buttonDrop,
  button: button
};

function TriggerModalImage(Props) {
  var content = Props.content;
  var onUpdateContent = Props.onUpdateContent;
  var setUploadStatus = Props.setUploadStatus;
  var triggerId = Props.triggerId;
  var uploadStatus = Props.uploadStatus;
  var viewerCanEdit = Props.viewerCanEdit;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var handleUploadScreenshot = function (file) {
    Curry._1(setUploadStatus, (function (param) {
            return "Uploading";
          }));
    var path = "/workspace/" + workspace.id + "/triggers/" + triggerId + "/pictures/";
    $$Promise.$$catch(FirebaseUtils.uploadScreenshot(path, file).then(function (picture) {
              Curry._1(setUploadStatus, (function (param) {
                      return "Idle";
                    }));
              Curry._1(onUpdateContent, {
                    TAG: /* Image */0,
                    _0: {
                      url: picture.url,
                      storagePath: picture.storagePath,
                      width: picture.width,
                      height: picture.height
                    }
                  });
              return Promise.resolve(undefined);
            }), (function (_error) {
            Curry._1(setUploadStatus, (function (param) {
                    return "Idle";
                  }));
            window.alert("Something went wrong when uploading your image. Please try again.");
            return Promise.reject(Js_exn.raiseError("Failed to upload trigger image"));
          }));
    
  };
  var tmp;
  var exit = 0;
  if (viewerCanEdit) {
    if (content !== undefined) {
      exit = 1;
    } else {
      tmp = React.createElement(ReactDropzone, {
            accept: "image/jpg,image/jpeg,image/gif,image/png",
            className: dropzone(uploadStatus),
            onDrop: (function (files) {
                var file = Belt_Array.get(files, 0);
                if (file !== undefined) {
                  return handleUploadScreenshot(Caml_option.valFromOption(file));
                }
                
              }),
            children: uploadStatus === "Idle" ? React.createElement(React.Fragment, undefined, React.createElement(Icons.AddImage.make, {}), React.createElement(Spacer.make, {
                        height: 4
                      }), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        color: Styles.Color.light11,
                        children: "Select an image to upload"
                      }), React.createElement(Spacer.make, {
                        height: 4
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light11,
                        children: "or drag and drop it here"
                      })) : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: /* [] */0
                              }
                            }
                          }
                        })
                  }, React.createElement(LoadingCircle.make, {
                        size: 64,
                        color: Styles.Color.light11
                      }), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        color: Styles.Color.light11,
                        children: "Uploading..."
                      }))
          });
    }
  } else if (content !== undefined) {
    exit = 1;
  } else {
    tmp = React.createElement($$Text.make, {
          size: "Medium",
          color: Styles.Color.light08,
          children: "This trigger has no image"
        });
  }
  if (exit === 1) {
    if (content.TAG === /* Image */0) {
      var match = content._0;
      if (uploadStatus === "Idle") {
        var width = String(match.width);
        var height = String(match.height);
        var src = match.url + "=w" + width;
        tmp = React.createElement("img", {
              className: image,
              height: height,
              src: src,
              width: width
            });
      } else {
        tmp = React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(LoadingCircle.make, {
                  size: 64,
                  color: Styles.Color.light11
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: "Uploading..."
                }));
      }
    } else {
      tmp = null;
    }
  }
  return React.createElement("div", {
              className: wrapper
            }, React.createElement("div", {
                  className: innerWrapper
                }, tmp), Belt_Option.isSome(content) && viewerCanEdit ? React.createElement(ReactDropzone, {
                    className: buttonDrop,
                    onDrop: (function (files) {
                        var file = Belt_Array.get(files, 0);
                        if (file !== undefined) {
                          return handleUploadScreenshot(Caml_option.valFromOption(file));
                        }
                        
                      }),
                    children: React.createElement("button", {
                          className: button
                        }, "Replace Image")
                  }) : null);
}

var make = TriggerModalImage;

export {
  S ,
  make ,
  
}
/* wrapper Not a pure module */
