// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Tag from "../Tag.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "../IconX.mjs";
import * as Icons from "../Icons.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Styles from "../styles.mjs";
import * as AvoModel from "../avoModel.mjs";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ContextBox from "../ContextBox.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as SourceIcon from "../SourceIcon.mjs";
import * as TagsSelect from "../TagsSelect.mjs";
import * as IconCheckmark from "../IconCheckmark.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var selectedStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.flexWrap("wrap"),
          tl: {
            hd: Css.padding2(Css.px(4), Css.px(15)),
            tl: /* [] */0
          }
        }
      }
    });

var selectedItemStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.gap(Css.px(4)),
            tl: {
              hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
              tl: {
                hd: Css.padding2(Css.px(2), Css.px(5)),
                tl: {
                  hd: Css.margin(Css.px(2)),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.height(Css.px(30)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var selectedIconStyles = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(5)),
      tl: /* [] */0
    });

var selectedRemoveButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.width(Css.px(16)),
                    tl: {
                      hd: Css.height(Css.px(16)),
                      tl: {
                        hd: Css.padding(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.pct(50)),
                          tl: {
                            hd: Css_Legacy_Core.SVG.stroke(Styles.Color.darkBlue),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.backgroundColor(Styles.Color.darkBlue),
                                    tl: {
                                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.white),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var popupStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.bottom(Css.px(0)),
        tl: {
          hd: Css.transform(Css.translateY(Css.pct(100))),
          tl: {
            hd: Css.right(Css.px(0)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.padding4(Css.px(0), Css.px(20), Css.px(15), Css.px(20)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.width(Css.px(250)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function filterButtonStyles(isActive) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.button),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.padding4(Css.px(5), Css.px(5), Css.px(5), Css.px(8)),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.margin2(Css.px(3), Css.px(0)),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", isActive ? Styles.Color.darkBlue : Styles.Color.light02),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.color(isActive ? Styles.Color.darkBlue : Styles.Color.light10),
                                      tl: {
                                        hd: Css.textAlign("left"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function filterCheckboxStyles(isActive) {
  return Curry._1(Css.style, {
              hd: Css.flexShrink(0.0),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.width(Css.px(16)),
                      tl: {
                        hd: Css.height(Css.px(16)),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", isActive ? Styles.Color.darkBlue : Styles.Color.light07),
                            tl: {
                              hd: Css.backgroundColor(isActive ? Styles.Color.darkBlue : "transparent"),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$default
                                    }, undefined, undefined, "all"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function IntegrationEventsFilter(Props) {
  var model = Props.model;
  var filters = Props.filters;
  var onRemove = Props.onRemove;
  var onAdd = Props.onAdd;
  var selectedSources = Belt_List.keepMapU(AvoModel.getSourceFilters(filters), (function (id) {
          return ModelUtils.getSourceById(id, model);
        }));
  var selectedDestinations = Belt_List.keepMapU(AvoModel.getDestinationFilters(filters), (function (id) {
          return ModelUtils.getDestinationById(id, model);
        }));
  var selectedTags = AvoModel.getTagFilters(filters);
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: selectedStyles
                }, Belt_List.toArray(Belt_List.mapU(selectedSources, (function (source) {
                            return React.createElement("div", {
                                        key: source.id,
                                        className: selectedItemStyles
                                      }, React.createElement(SourceIcon.make, {
                                            source: source,
                                            color: Styles.Color.darkBlue
                                          }), React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.darkBlue,
                                            children: AvoConfig.getSourceName(source)
                                          }), React.createElement("button", {
                                            className: selectedRemoveButtonStyles,
                                            onClick: (function (param) {
                                                return Curry._1(onRemove, {
                                                            NAME: "Source",
                                                            VAL: source.id
                                                          });
                                              })
                                          }, React.createElement(IconX.make, {
                                                size: 8
                                              })));
                          }))), Belt_List.toArray(Belt_List.mapU(selectedDestinations, (function (destination) {
                            var destination$1 = destination.type_;
                            return React.createElement("div", {
                                        key: destination.id,
                                        className: selectedItemStyles
                                      }, destination$1 !== undefined ? React.createElement(Icons.Destinations.make, {
                                              destination: destination$1,
                                              size: 16
                                            }) : null, React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.darkBlue,
                                            children: ModelUtils.getDestinationName(destination)
                                          }), React.createElement("button", {
                                            className: selectedRemoveButtonStyles,
                                            onClick: (function (param) {
                                                return Curry._1(onRemove, {
                                                            NAME: "Destination",
                                                            VAL: destination.id
                                                          });
                                              })
                                          }, React.createElement(IconX.make, {
                                                size: 8
                                              })));
                          }))), Belt_List.toArray(Belt_List.mapU(selectedTags, (function (tag) {
                            return React.createElement(Tag.make, {
                                        tag: tag,
                                        onRemove: (function (param) {
                                            return Curry._1(onRemove, {
                                                        NAME: "Tag",
                                                        VAL: tag
                                                      });
                                          }),
                                        key: tag
                                      });
                          })))), React.createElement(ContextBox.make, {
                  children: (function (param, param$1, _close, buttonRef) {
                      return [
                              React.createElement(Button.make, {
                                    icon: "equalizer",
                                    size: "tiny",
                                    style: "ghost",
                                    ref: buttonRef
                                  }),
                              React.createElement("div", {
                                    className: popupStyles
                                  }, model.sources === /* [] */0 ? null : React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.marginTop(Css.px(15)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.marginTop(Css.px(6)),
                                                    tl: {
                                                      hd: Css.marginBottom(Css.px(6)),
                                                      tl: /* [] */0
                                                    }
                                                  })
                                            }, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  weight: "Semi",
                                                  children: "Only include events sent from"
                                                })), Belt_List.toArray(Belt_List.mapU(model.sources, (function (source) {
                                                    var isActive = Belt_List.has(selectedSources, source.id, (function (source, selectedSourceId) {
                                                            return source.id === selectedSourceId;
                                                          }));
                                                    return React.createElement("button", {
                                                                key: source.id,
                                                                className: filterButtonStyles(isActive),
                                                                onClick: (function (param) {
                                                                    if (isActive) {
                                                                      return Curry._1(onRemove, {
                                                                                  NAME: "Source",
                                                                                  VAL: source.id
                                                                                });
                                                                    } else {
                                                                      return Curry._1(onAdd, {
                                                                                  NAME: "Source",
                                                                                  VAL: source.id
                                                                                });
                                                                    }
                                                                  })
                                                              }, React.createElement(SourceIcon.make, {
                                                                    source: source,
                                                                    size: 12,
                                                                    color: isActive ? Styles.Color.darkBlue : Styles.Color.light10
                                                                  }), React.createElement("div", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.flexGrow(1.0),
                                                                          tl: {
                                                                            hd: Css.marginLeft(Css.px(5)),
                                                                            tl: /* [] */0
                                                                          }
                                                                        })
                                                                  }, React.createElement($$Text.make, {
                                                                        size: "Small",
                                                                        weight: "Semi",
                                                                        color: isActive ? Styles.Color.darkBlue : Styles.Color.light11,
                                                                        children: AvoConfig.getSourceName(source)
                                                                      })), React.createElement("div", {
                                                                    className: filterCheckboxStyles(isActive)
                                                                  }, isActive ? React.createElement(IconCheckmark.make, {
                                                                          size: 8,
                                                                          color: Styles.Color.white
                                                                        }) : null));
                                                  })))), model.destinations === /* [] */0 ? null : React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.marginTop(Css.px(15)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.marginTop(Css.px(6)),
                                                    tl: {
                                                      hd: Css.marginBottom(Css.px(6)),
                                                      tl: /* [] */0
                                                    }
                                                  })
                                            }, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  weight: "Semi",
                                                  children: "Only include events sent to"
                                                })), Belt_List.toArray(Belt_List.mapU(model.destinations, (function (destination) {
                                                    var isActive = Belt_List.some(AvoModel.getDestinationFilters(filters), (function (destinationId) {
                                                            return destinationId === destination.id;
                                                          }));
                                                    var destination$1 = destination.type_;
                                                    return React.createElement("button", {
                                                                key: destination.id,
                                                                className: filterButtonStyles(isActive),
                                                                onClick: (function (param) {
                                                                    if (isActive) {
                                                                      return Curry._1(onRemove, {
                                                                                  NAME: "Destination",
                                                                                  VAL: destination.id
                                                                                });
                                                                    } else {
                                                                      return Curry._1(onAdd, {
                                                                                  NAME: "Destination",
                                                                                  VAL: destination.id
                                                                                });
                                                                    }
                                                                  })
                                                              }, destination$1 !== undefined ? React.createElement("div", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.marginRight(Css.px(5)),
                                                                            tl: /* [] */0
                                                                          })
                                                                    }, React.createElement(Icons.Destinations.make, {
                                                                          destination: destination$1,
                                                                          size: 16
                                                                        })) : null, React.createElement("div", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.flexGrow(1.0),
                                                                          tl: /* [] */0
                                                                        })
                                                                  }, React.createElement($$Text.make, {
                                                                        size: "Small",
                                                                        weight: "Semi",
                                                                        color: isActive ? Styles.Color.darkBlue : Styles.Color.light11,
                                                                        children: ModelUtils.getDestinationName(destination)
                                                                      })), React.createElement("div", {
                                                                    className: filterCheckboxStyles(isActive)
                                                                  }, isActive ? React.createElement(IconCheckmark.make, {
                                                                          size: 8,
                                                                          color: Styles.Color.white
                                                                        }) : null));
                                                  })))), ModelUtils.getAllTags(model.events) === /* [] */0 ? null : React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.marginTop(Css.px(15)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.marginTop(Css.px(6)),
                                                    tl: {
                                                      hd: Css.marginBottom(Css.px(6)),
                                                      tl: /* [] */0
                                                    }
                                                  })
                                            }, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  weight: "Semi",
                                                  children: "Only include events with tag"
                                                })), React.createElement(TagsSelect.make, {
                                              events: model.events,
                                              selectedTags: selectedTags,
                                              onSelect: (function (tag) {
                                                  return Curry._1(onAdd, {
                                                              NAME: "Tag",
                                                              VAL: tag
                                                            });
                                                }),
                                              onRemove: (function (tag) {
                                                  return Curry._1(onRemove, {
                                                              NAME: "Tag",
                                                              VAL: tag
                                                            });
                                                })
                                            })))
                            ];
                    })
                }));
}

var make = IntegrationEventsFilter;

export {
  rootStyles ,
  selectedStyles ,
  selectedItemStyles ,
  selectedIconStyles ,
  selectedRemoveButtonStyles ,
  popupStyles ,
  filterButtonStyles ,
  filterCheckboxStyles ,
  make ,
  
}
/* rootStyles Not a pure module */
