// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconLogEvent(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light07;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  }),
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  strokeWidth: "1"
                }, React.createElement("polyline", {
                      fill: "none",
                      points: "12.5 5.5 15.5 8.5 12.5 11.5",
                      strokeLinecap: "round",
                      strokeLinejoin: "round"
                    }), React.createElement("line", {
                      fill: "none",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      x1: "5.5",
                      x2: "15.5",
                      y1: "8.5",
                      y2: "8.5"
                    }), React.createElement("path", {
                      d: "M7,11.623A4,4,0,1,1,7,5.377",
                      fill: "none",
                      strokeLinecap: "round",
                      strokeLinejoin: "round"
                    })));
}

var make = IconLogEvent;

export {
  make ,
  
}
/* Css Not a pure module */
