// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconDescription from "./IconDescription.mjs";

function DiffEventDescription(Props) {
  var $$event = Props.event;
  var actions = Props.actions;
  var maybeAction = Belt_List.getByU(actions, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "UpdateEventDescription";
          } else {
            return false;
          }
        }));
  if (maybeAction !== undefined && typeof maybeAction === "object" && maybeAction.NAME === "UpdateEventDescription") {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.gap(Css.px(12)),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.flexShrink(0.0),
                          tl: /* [] */0
                        })
                  }, React.createElement(IconDescription.make, {
                        size: 14
                      })), React.createElement($$Text.make, {
                    size: "Small",
                    withWordBreak: true,
                    children: JsDiff.renderStringDiff(undefined, undefined, undefined, $$event.description, maybeAction.VAL[1])
                  }));
  } else {
    return null;
  }
}

var make = DiffEventDescription;

export {
  make ,
  
}
/* Css Not a pure module */
