// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function DocsLink(Props) {
  var path = Props.path;
  var onClick = Props.onClick;
  var labelOpt = Props.label;
  var displayOpt = Props.display;
  var label = labelOpt !== undefined ? labelOpt : "Docs";
  var display = displayOpt !== undefined ? displayOpt : "flex";
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.display(display),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.color(Styles.Color.primaryPurpleShade1),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: {
                  hd: Css.textDecoration("none"),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: {
                      hd: Css.marginTop(Css.px(2)),
                      tl: {
                        hd: Css.selector(" path", {
                              hd: Css_Legacy_Core.SVG.fill(Styles.Color.primaryPurpleShade1),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.hover({
                                hd: Css.textDecoration("underline"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }),
    href: "https://www.avo.app/docs/" + path,
    target: "_blank"
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("a", tmp, label, React.createElement("svg", {
                  height: "15",
                  width: "11",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("path", {
                      d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                    })));
}

var make = DocsLink;

export {
  make ,
  
}
/* Css Not a pure module */
