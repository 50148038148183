// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Select from "./Select.mjs";
import * as Styles from "./styles.mjs";
import * as $$Window from "./externals/window.mjs";
import Shortid from "shortid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

var NoOptionsException = /* @__PURE__ */Caml_exceptions.create("ResizingSelect.NoOptionsException");

var ValueNotFoundException = /* @__PURE__ */Caml_exceptions.create("ResizingSelect.ValueNotFoundException");

function additionalSelectStyles(border, customMaxWidth, size, customStyle, forcedWidth) {
  return Belt_List.concat({
              hd: Css.important(Css.backgroundColor("transparent")),
              tl: {
                hd: Css.important(Css.borderWidth(typeof border === "object" ? Css.px(1) : "zero")),
                tl: {
                  hd: typeof border === "object" ? Css.important(Css.borderColor(border.VAL)) : Styles.emptyStyle,
                  tl: {
                    hd: Css.important(Css.backgroundImage("none")),
                    tl: {
                      hd: customStyle === "light" ? Css.important(Css.color(Styles.Color.light11)) : Css.important(Css.color(Styles.Color.light04)),
                      tl: {
                        hd: Css.transitions({
                              hd: Css_Legacy_Core.Transition.shorthand({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$short
                                  }, undefined, undefined, "color"),
                              tl: {
                                hd: Css_Legacy_Core.Transition.shorthand({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$short
                                    }, undefined, undefined, "background-color"),
                                tl: /* [] */0
                              }
                            }),
                        tl: {
                          hd: Css.important(Css.fontWeight(Styles.FontWeight.semi)),
                          tl: {
                            hd: Css.important(Css.fontSize(size === "small" ? Styles.FontSize.small : Styles.FontSize.regular)),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.color(customStyle === "light" ? Styles.Color.light12 : Styles.Color.white),
                                    tl: {
                                      hd: Css.backgroundColor(customStyle === "light" ? Styles.Color.light04 : Styles.Color.light11),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: Css.important(Css.padding(Css.px(5))),
                                tl: {
                                  hd: Css.important(Css.paddingLeft(Css.px(10))),
                                  tl: {
                                    hd: Css.important(Css.paddingRight(Css.px(32))),
                                    tl: {
                                      hd: Css.important(Css.margin("zero")),
                                      tl: {
                                        hd: Css.important(Css.marginBottom(Css.px(-1))),
                                        tl: {
                                          hd: Css.important(Css.textOverflow("ellipsis")),
                                          tl: {
                                            hd: Css.important(Belt_Option.mapWithDefault(forcedWidth, Styles.emptyStyle, (function (w) {
                                                        return Css.width(Css.px(w));
                                                      }))),
                                            tl: {
                                              hd: Css.important(Belt_Option.mapWithDefault(customMaxWidth, Styles.emptyStyle, (function (w) {
                                                          return Css.maxWidth(Css.px(w));
                                                        }))),
                                              tl: {
                                                hd: Css.important(Css.unsafe("WebKitAppearance", "none")),
                                                tl: {
                                                  hd: Css.important(Css.unsafe("MozAppearance", "none")),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }, Belt_Option.mapWithDefault(forcedWidth, /* [] */0, (function (w) {
                    return {
                            hd: Css.width(Css.px(w + 1 | 0)),
                            tl: /* [] */0
                          };
                  })));
}

var hiddenSelect_0 = Css.opacity(0.0);

var hiddenSelect_1 = {
  hd: Css.height(Css.px(0)),
  tl: {
    hd: Css.position("absolute"),
    tl: {
      hd: Css.right(Css.px(-1000)),
      tl: {
        hd: Css.disabled({
              hd: Css.opacity(0.0),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.pointerEvents("none"),
          tl: {
            hd: Css.overflow("hidden"),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var hiddenSelect = {
  hd: hiddenSelect_0,
  tl: hiddenSelect_1
};

function selectChevron(size) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.position("absolute"),
                    tl: {
                      hd: Css.pointerEvents("none"),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: size === "small" ? Curry._1(Css.style, {
                        hd: Css.right(Css.px(4)),
                        tl: {
                          hd: Css.top(Css.px(6)),
                          tl: /* [] */0
                        }
                      }) : Curry._1(Css.style, {
                        hd: Css.right(Css.px(4)),
                        tl: {
                          hd: Css.top(Css.px(4)),
                          tl: /* [] */0
                        }
                      }),
                tl: /* [] */0
              }
            });
}

var Style = {
  additionalSelectStyles: additionalSelectStyles,
  hiddenSelect: hiddenSelect,
  selectChevron: selectChevron
};

function getOption(options, value) {
  var option = Belt_Array.getBy(options, (function (option) {
          return Caml_obj.caml_equal(option[1], value);
        }));
  if (option !== undefined) {
    return option;
  }
  throw {
        RE_EXN_ID: ValueNotFoundException,
        Error: new Error()
      };
}

function ResizingSelect(Props) {
  var options = Props.options;
  var value = Props.value;
  var sizeOpt = Props.size;
  var borderOpt = Props.border;
  var customStyleOpt = Props.style;
  var maxWidth = Props.maxWidth;
  var onSelect = Props.onSelect;
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var border = borderOpt !== undefined ? borderOpt : "noBorder";
  var customStyle = customStyleOpt !== undefined ? customStyleOpt : "dark";
  var hiddenSelectId = React.useMemo((function () {
          return Shortid();
        }), []);
  var match = React.useState(function () {
        return getOption(options, value);
      });
  var setCurrentOption = match[1];
  var currentOption = match[0];
  React.useEffect((function () {
          Curry._1(setCurrentOption, (function (currentOption) {
                  return getOption(options, currentOption[1]);
                }));
          
        }), [options]);
  var match$1 = React.useState(function () {
        
      });
  var setForcedSelectWidth = match$1[1];
  React.useEffect((function () {
          Curry._1(setForcedSelectWidth, (function (param) {
                  return Belt_Option.map($$Window.$$Document.getElementById(hiddenSelectId), (function (prim) {
                                return prim.offsetWidth;
                              }));
                }));
          
        }), [currentOption]);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width("fitContent"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(Select.make, {
                  additionalStyles: Belt_List.concatMany([
                        additionalSelectStyles(border, maxWidth, size, customStyle, undefined),
                        hiddenSelect
                      ]),
                  disabled: true,
                  id: hiddenSelectId,
                  onSelect: (function (param) {
                      
                    }),
                  options: [currentOption],
                  value: value,
                  key: currentOption[0].VAL
                }), React.createElement(Select.make, {
                  additionalStyles: additionalSelectStyles(border, maxWidth, size, customStyle, match$1[0]),
                  onSelect: (function (value) {
                      Curry._1(setCurrentOption, (function (param) {
                              return getOption(options, value);
                            }));
                      return Curry._1(onSelect, value);
                    }),
                  options: options,
                  value: value
                }), React.createElement("span", {
                  className: selectChevron(size)
                }, React.createElement(Icon.make, {
                      type_: "chevronDown",
                      size: size === "small" ? "small" : "medium",
                      color: typeof border === "object" ? border.VAL : Styles.Color.light09
                    })));
}

var make = ResizingSelect;

export {
  NoOptionsException ,
  ValueNotFoundException ,
  Style ,
  getOption ,
  make ,
  
}
/* hiddenSelect Not a pure module */
