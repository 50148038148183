// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as EventTypeIcon from "./EventTypeIcon.mjs";
import * as SideEffectUtils from "./SideEffectUtils.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";

function renderEventTypeLabel(type_) {
  if (type_ === "UpdateGroups") {
    return "Update groups";
  } else if (type_ === "Page") {
    return "Navigate";
  } else {
    return TrackingPlanModel.eventTypeToJs(type_);
  }
}

function EventType(Props) {
  var type_ = Props.type_;
  var change = Props.change;
  try {
    var sideEffect = SideEffectUtils.eventTypeToSideEffect(type_);
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.padding4(Css.px(5), Css.px(10), Css.px(5), Css.px(8)),
                          tl: {
                            hd: Css.margin(Css.px(2)),
                            tl: {
                              hd: Css.backgroundColor(change === "Added" ? Styles.Color.green : Styles.Color.red),
                              tl: {
                                hd: Css.color(Styles.Color.white),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    })
              }, React.createElement(EventTypeIcon.make, {
                    sideEffect: sideEffect,
                    size: 12,
                    color: Styles.Color.white
                  }), React.createElement(Spacer.make, {
                    width: 5
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    children: renderEventTypeLabel(type_)
                  }));
  }
  catch (exn){
    return null;
  }
}

var make = EventType;

export {
  renderEventTypeLabel ,
  make ,
  
}
/* Css Not a pure module */
