// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "../styles.mjs";

var icon = React.createElement("svg", {
      height: "60",
      width: "60",
      fill: "none",
      viewBox: "0 0 60 60",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("rect", {
          height: "30",
          width: "5",
          fill: Styles.Color.toString(Styles.Color.light08),
          rx: "2.5",
          x: "27.5",
          y: "15"
        }), React.createElement("rect", {
          height: "30",
          width: "5",
          fill: Styles.Color.toString(Styles.Color.light08),
          rx: "2.5",
          transform: "rotate(90 45 27.5)",
          x: "45",
          y: "27.5"
        }));

export {
  icon ,
  
}
/* icon Not a pure module */
