// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconErase from "./IconErase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Fuzzaldrin from "fuzzaldrin";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.minHeight(Css.px(44)),
          tl: {
            hd: Css.padding2(Css.px(5), Css.px(20)),
            tl: {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$default
                  }, undefined, undefined, "background-color"),
              tl: {
                hd: Css.cursor("pointer"),
                tl: {
                  hd: Css.hover({
                        hd: Css.backgroundColor(Styles.Color.light02),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.active({
                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.focus({
                            hd: Css.outlineStyle("none"),
                            tl: {
                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), true, Styles.Color.darkBlue)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var inputStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.opacity(0),
        tl: {
          hd: Css.cursor("pointer"),
          tl: {
            hd: Css.height(Css.px(0)),
            tl: {
              hd: Css.width(Css.px(0)),
              tl: {
                hd: Css.margin(Css.px(0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function pictureStyles(checked) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.before({
                      hd: Css.display("block"),
                      tl: {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.zIndex(1),
                                  tl: {
                                    hd: Css.opacity(checked ? 1 : 0),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.darkBlue),
                                      tl: {
                                        hd: Css.borderRadius(Css.px(16)),
                                        tl: {
                                          hd: Css.unsafe("content", "' '"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: Css.after({
                        hd: Css.display("block"),
                        tl: {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.opacity(checked ? 1 : 0),
                                    tl: {
                                      hd: Css.zIndex(2),
                                      tl: {
                                        hd: Css.backgroundImage({
                                              NAME: "url",
                                              VAL: "'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjQ4IDYuNThMMTAuOS4yMmEuNzUuNzUgMCAwMTEuMDUgMGwuODMuODJjLjMuMjkuMy43NSAwIDEuMDNMNS4wMiA5Ljc4YS43NS43NSAwIDAxLTEuMDUgMEwuMiA2LjA1YS43NC43NCAwIDAxMC0xLjAzbC44NC0uODJhLjczLjczIDAgMDExLjA0IDBsMi40IDIuMzh6IiBmaWxsPSIjZmZmIi8+PC9zdmc+'"
                                            }),
                                        tl: {
                                          hd: Css.backgroundPosition({
                                                NAME: "hv",
                                                VAL: [
                                                  Css.pct(50),
                                                  Css.pct(50)
                                                ]
                                              }),
                                          tl: {
                                            hd: Css.backgroundRepeat("noRepeat"),
                                            tl: {
                                              hd: Css.borderRadius(Css.px(16)),
                                              tl: {
                                                hd: Css.unsafe("content", "' '"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            });
}

var infoStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(12)),
      tl: /* [] */0
    });

function EditCollaboratorsPopup$Item(Props) {
  var user = Props.user;
  var onToggle = Props.onToggle;
  var checked = Props.checked;
  return React.createElement("label", {
              className: rootStyles
            }, React.createElement("input", {
                  className: inputStyles,
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      return Curry._2(onToggle, user, !checked);
                    })
                }), React.createElement("div", {
                  className: pictureStyles(checked)
                }, React.createElement(ProfilePhoto.make, {
                      user: user,
                      size: 32
                    })), React.createElement("div", {
                  className: infoStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      children: AvoConfig.getUserDisplayName(user)
                    }), Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(user.name), null, (function (param) {
                        return React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Medium",
                                    color: Styles.Color.light08,
                                    children: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.email), AvoConfig.anonymousUserDisplayName)
                                  });
                      }))));
}

var Item = {
  rootStyles: rootStyles,
  inputStyles: inputStyles,
  pictureStyles: pictureStyles,
  infoStyles: infoStyles,
  make: EditCollaboratorsPopup$Item
};

var rootStyles$1 = Curry._1(Css.style, {
      hd: Css.width(Css.px(280)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.overflow("hidden"),
          tl: /* [] */0
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
      tl: {
        hd: Css.padding4(Css.px(16), Css.px(12), Css.px(8), Css.px(20)),
        tl: /* [] */0
      }
    });

var headerInputRowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var filterStyles = Curry._1(Css.style, {
      hd: Css.minWidth(Css.px(0)),
      tl: {
        hd: Css.width(Css.px(100)),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.flexGrow(1.0),
            tl: {
              hd: Css.borderWidth(Css.px(0)),
              tl: {
                hd: Css.fontSize(Styles.FontSize.regular),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.medium),
                  tl: {
                    hd: Css.lineHeight(Styles.LineHeight.regular),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.paddingTop(Css.px(0)),
                        tl: {
                          hd: Css.paddingRight(Css.px(8)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(0)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(0)),
                              tl: {
                                hd: Css.placeholder({
                                      hd: Css.color(Styles.Color.light08),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.focus({
                                        hd: Css.outlineStyle("none"),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function eraseButtonStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.padding(Css.px(0)),
              tl: {
                hd: Css.marginTop(Css.px(0)),
                tl: {
                  hd: Css.marginBottom(Css.px(0)),
                  tl: {
                    hd: Css.marginLeft(Css.px(0)),
                    tl: {
                      hd: Css.marginRight(Css.px(8)),
                      tl: {
                        hd: Css.borderWidth(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.px(2)),
                          tl: {
                            hd: Css.background("transparent"),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "opacity"),
                              tl: {
                                hd: Css.opacity(disabled ? 0 : 1),
                                tl: {
                                  hd: Css.unsafe("appearance", "none"),
                                  tl: {
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.active({
                                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.focus({
                                                hd: Css.outlineStyle("none"),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var listStyles = Curry._1(Css.style, {
      hd: Css.maxHeight({
            NAME: "subtract",
            VAL: [
              Css.vh(50),
              Css.px(50)
            ]
          }),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.paddingTop(Css.px(6)),
          tl: {
            hd: Css.paddingBottom(Css.px(6)),
            tl: /* [] */0
          }
        }
      }
    });

var emptyStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.padding2(Css.px(16), Css.px(20)),
            tl: /* [] */0
          }
        }
      }
    });

var profilePlaceholderStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.shimmer),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.height(Css.px(32)),
              tl: {
                hd: Css.width(Css.px(32)),
                tl: {
                  hd: Css.borderRadius(Css.px(16)),
                  tl: {
                    hd: Css.marginTop(Css.px(6)),
                    tl: {
                      hd: Css.marginBottom(Css.px(12)),
                      tl: {
                        hd: Css.marginLeft(Css.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function isRoleAllowed(userId, onlyRoles, membersDict) {
  if (Belt_Set.size(onlyRoles) === 0) {
    return true;
  }
  var member = Belt_MapString.get(membersDict, userId);
  if (member !== undefined) {
    return Belt_Option.mapWithDefault(Models.Role.tFromJs(Caml_option.valFromOption(member).role), false, (function (role) {
                  return Belt_Set.has(onlyRoles, role);
                }));
  } else {
    return false;
  }
}

function EditCollaboratorsPopup$WithUsers(Props) {
  var collaboratorIds = Props.collaboratorIds;
  var ignoreIdsOpt = Props.ignoreIds;
  var onlyRolesOpt = Props.onlyRoles;
  var onToggle = Props.onToggle;
  var searchPlaceholderOpt = Props.searchPlaceholder;
  var sortBy = Props.sortBy;
  var infoTextOpt = Props.infoText;
  var users = Props.users;
  var ignoreIds = ignoreIdsOpt !== undefined ? ignoreIdsOpt : [];
  var onlyRoles = onlyRolesOpt !== undefined ? onlyRolesOpt : [];
  var searchPlaceholder = searchPlaceholderOpt !== undefined ? searchPlaceholderOpt : "Find member...";
  var infoText = infoTextOpt !== undefined ? infoTextOpt : "Collaborators get notified about updates on this branch";
  var ignoreIds$1 = React.useMemo((function () {
          return Belt_SetString.fromArray(ignoreIds);
        }), [ignoreIds]);
  var onlyRoles$1 = React.useMemo((function () {
          return Belt_Set.fromArray(onlyRoles, Models.Role.Comparator);
        }), [onlyRoles]);
  var match = React.useState(function () {
        return "";
      });
  var setFilter = match[1];
  var filter = match[0];
  var match$1 = WorkspaceContext.use(undefined);
  var members = match$1.members;
  var membersDict = React.useMemo((function () {
          return Belt_MapString.fromArray(Belt_Array.mapU(Belt_List.toArray(members), (function (member) {
                            return [
                                    member.id,
                                    member
                                  ];
                          })));
        }), [members]);
  var filteredItems;
  if (typeof users === "object") {
    if (users.NAME === "Loaded") {
      var users$1 = Belt_Array.mapU(Fuzzaldrin.filter(Belt_Array.keepMapU(users.VAL, (function (user) {
                      if (Belt_SetString.has(ignoreIds$1, user.id) || !isRoleAllowed(user.id, onlyRoles$1, membersDict)) {
                        return ;
                      } else {
                        return {
                                id: user.id,
                                key: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.email), AvoConfig.anonymousUserDisplayName) + Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), ""),
                                user: user
                              };
                      }
                    })), filter, {
                key: "key",
                maxResults: 100
              }), (function (item) {
              return item.user;
            }));
      filteredItems = sortBy !== undefined ? Belt_SortArray.stableSortByU(users$1, (function (a, b) {
                return Curry._4(sortBy, Belt_MapString.get(membersDict, a.id), Belt_MapString.get(membersDict, b.id), a, b);
              })) : users$1;
    } else {
      filteredItems = [];
    }
  } else {
    filteredItems = [];
  }
  return React.createElement("div", {
              className: rootStyles$1
            }, React.createElement("div", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: headerInputRowStyles
                    }, React.createElement("input", {
                          className: filterStyles,
                          autoFocus: true,
                          placeholder: searchPlaceholder,
                          value: filter,
                          onChange: (function ($$event) {
                              var value = $$event.target.value;
                              return Curry._1(setFilter, (function (param) {
                                            return value;
                                          }));
                            })
                        }), React.createElement("button", {
                          className: eraseButtonStyles(filter === ""),
                          disabled: filter === "",
                          onClick: (function (param) {
                              return Curry._1(setFilter, (function (param) {
                                            return "";
                                          }));
                            })
                        }, React.createElement(IconErase.make, {
                              color: Styles.Color.light07,
                              hoverColor: Styles.Color.red
                            }))), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: infoText
                    })), typeof users === "object" ? (
                users.NAME === "Errored" ? React.createElement("div", {
                        className: emptyStyles
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light08,
                            children: "Failed to load members"
                          })) : (
                    users.VAL.length !== 0 ? (
                        filteredItems.length !== 0 ? React.createElement("div", {
                                className: listStyles
                              }, Belt_Array.mapU(filteredItems, (function (user) {
                                      return React.createElement(EditCollaboratorsPopup$Item, {
                                                  user: user,
                                                  onToggle: onToggle,
                                                  checked: Belt_List.has(collaboratorIds, user.id, (function (prim0, prim1) {
                                                          return prim0 === prim1;
                                                        })),
                                                  key: user.id
                                                });
                                    }))) : React.createElement("div", {
                                className: emptyStyles
                              }, React.createElement($$Text.make, {
                                    size: "Medium",
                                    weight: "Semi",
                                    color: Styles.Color.light08,
                                    children: "Found no matches"
                                  }))
                      ) : React.createElement("div", {
                            className: emptyStyles
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.light08,
                                children: "Found no members"
                              }))
                  )
              ) : React.createElement("div", {
                    className: listStyles
                  }, Belt_Array.mapU(Belt_MapString.keysToArray(membersDict), (function (memberId) {
                          return React.createElement("div", {
                                      key: memberId,
                                      className: profilePlaceholderStyles
                                    });
                        }))));
}

var WithUsers = {
  isRoleAllowed: isRoleAllowed,
  make: EditCollaboratorsPopup$WithUsers
};

function EditCollaboratorsPopup(Props) {
  var collaboratorIds = Props.collaboratorIds;
  var ignoreIds = Props.ignoreIds;
  var onlyRoles = Props.onlyRoles;
  var sortBy = Props.sortBy;
  var onToggle = Props.onToggle;
  var searchPlaceholderOpt = Props.searchPlaceholder;
  var infoTextOpt = Props.infoText;
  var searchPlaceholder = searchPlaceholderOpt !== undefined ? searchPlaceholderOpt : "Find member...";
  var infoText = infoTextOpt !== undefined ? infoTextOpt : "Collaborators get notified about updates on this branch";
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var memberIds = Belt_List.toArray(Belt_List.map(workspace.members, (function (member) {
              return member.id;
            })));
  var users = FirebaseFetcherHooks.useUsers(memberIds);
  var tmp = {
    collaboratorIds: collaboratorIds,
    onToggle: onToggle,
    searchPlaceholder: searchPlaceholder,
    infoText: infoText,
    users: users
  };
  if (ignoreIds !== undefined) {
    tmp.ignoreIds = Caml_option.valFromOption(ignoreIds);
  }
  if (onlyRoles !== undefined) {
    tmp.onlyRoles = Caml_option.valFromOption(onlyRoles);
  }
  if (sortBy !== undefined) {
    tmp.sortBy = Caml_option.valFromOption(sortBy);
  }
  return React.createElement(EditCollaboratorsPopup$WithUsers, tmp);
}

var make = EditCollaboratorsPopup;

export {
  Item ,
  rootStyles$1 as rootStyles,
  headerStyles ,
  headerInputRowStyles ,
  filterStyles ,
  eraseButtonStyles ,
  listStyles ,
  emptyStyles ,
  profilePlaceholderStyles ,
  WithUsers ,
  make ,
  
}
/* rootStyles Not a pure module */
