// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Comlink from "../externals/Comlink.mjs";

function MakeInstance($star) {
  var instance = new ComlinkWorker(new URL("./ChangesWorker.mjs", import.meta.url), {});
  var getModelChanges = function (fromModelWithFlattenedEventVariantsJson, toModelWithFlattenedEventVariantsJson) {
    return instance.getChanges(fromModelWithFlattenedEventVariantsJson, toModelWithFlattenedEventVariantsJson);
  };
  var terminate = function (param) {
    return Comlink.terminateWorker(instance);
  };
  return {
          getModelChanges: getModelChanges,
          terminate: terminate
        };
}

var instance = new ComlinkWorker(new URL("./ChangesWorker.mjs", import.meta.url), {});

function getModelChanges(fromModelWithFlattenedEventVariantsJson, toModelWithFlattenedEventVariantsJson) {
  return instance.getChanges(fromModelWithFlattenedEventVariantsJson, toModelWithFlattenedEventVariantsJson);
}

function terminate(param) {
  return Comlink.terminateWorker(instance);
}

var Stable = {
  getModelChanges: getModelChanges,
  terminate: terminate
};

export {
  MakeInstance ,
  Stable ,
  
}
/* instance Not a pure module */
