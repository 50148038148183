// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import Shortid from "shortid";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AutofillInputNew from "./AutofillInputNew.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as NewGroupTypeModal from "./NewGroupTypeModal.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function getId(item) {
  return item.id;
}

function messageToString(msg) {
  var match = msg.VAL;
  return match[0] + "-" + match[1].id;
}

var GroupAutofillInput = AutofillInputNew.Make({
      getId: getId,
      messageToString: messageToString
    });

var rowHoverStyle_0 = Css.backgroundColor(Styles.Color.light02);

var rowHoverStyle_1 = {
  hd: Css.color(Styles.Color.deepBlue),
  tl: /* [] */0
};

var rowHoverStyle = {
  hd: rowHoverStyle_0,
  tl: rowHoverStyle_1
};

function cmp(a, b) {
  return Caml.caml_string_compare(a.id, b.id);
}

var ItemComparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function queryGroups(options, currentGroups, query, expectedPropertyCase) {
  var optionsSet = Belt_Set.fromArray(options, ItemComparator);
  var currentSet = Belt_Set.fromArray(currentGroups, ItemComparator);
  var querySet = Belt_Set.diff(optionsSet, currentSet);
  var queryResults = [[
      undefined,
      Belt_Array.map(Fuzzaldrin.filter(Belt_Set.toArray(querySet), Case.to_(expectedPropertyCase, query), {
                key: "name",
                maxResults: 100
              }), (function (i) {
              return {
                      NAME: "Item",
                      VAL: i
                    };
            }))
    ]];
  var alreadyDefined = Belt_Option.isSome(Belt_Array.getBy(options, (function (group) {
              return Case.camel(group.name) === Case.camel(query);
            })));
  var alreadyAdded = Belt_Array.getBy(currentGroups, (function (group) {
          return group.name.toLowerCase() === query.toLowerCase();
        }));
  var tmp;
  var exit = 0;
  if (alreadyDefined || alreadyAdded !== undefined) {
    exit = 1;
  } else {
    tmp = query !== "" ? [{
          NAME: "CreateNew",
          VAL: Case.to_(expectedPropertyCase, query)
        }] : [];
  }
  if (exit === 1) {
    tmp = alreadyAdded !== undefined ? [{
          NAME: "Message",
          VAL: {
            NAME: "AlreadyAdded",
            VAL: [
              query,
              alreadyAdded
            ]
          }
        }] : [];
  }
  return Belt_Array.concat(queryResults, [[
                undefined,
                tmp
              ]]);
}

function getName(group) {
  return group.name;
}

function GroupTypeInput(Props) {
  var currentGroupsOpt = Props.currentGroups;
  var getIndexOfValue = Props.getIndexOfValue;
  var onCreate = Props.onCreate;
  var onSelect = Props.onSelect;
  var options = Props.options;
  var renderButton = Props.renderButton;
  var sendActions = Props.sendActions;
  var currentGroups = currentGroupsOpt !== undefined ? currentGroupsOpt : [];
  var model = ModelStore.Mapped.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var globalSend = GlobalSendContext.use(undefined);
  var tmp = {
    onSelect: (function (item, query, param) {
        var alreadyExists = Belt_Option.isSome(Belt_Array.getBy(currentGroups, (function (param) {
                    return param.id === item.id;
                  })));
        if (!alreadyExists) {
          return Curry._2(onSelect, item, query);
        }
        
      }),
    onCreate: (function (name, _groupTypeId) {
        if (!NewGroupTypeModal.isValid(name, expectedPropertyCase, model)) {
          return Curry._1(globalSend, {
                      TAG: /* OpenModal */4,
                      _0: {
                        NAME: "NewGroupType",
                        VAL: [
                          name,
                          onCreate
                        ]
                      }
                    });
        }
        var groupTypeId = Shortid();
        return NewGroupTypeModal.handleSubmit(name, groupTypeId, sendActions, (function (param) {
                      return Belt_Option.forEach(onCreate, (function (cb) {
                                    return Curry._2(cb, groupTypeId, name);
                                  }));
                    }), addToast);
      }),
    placeholder: "Add Group Type",
    search: (function (param) {
        return queryGroups(options, currentGroups, param, expectedPropertyCase);
      }),
    renderItem: (function (item, isActive) {
        var variant = item.NAME;
        if (variant === "CreateNew") {
          if (Belt_Option.isSome(onCreate)) {
            return React.createElement("div", {
                        className: Curry._1(Css.merge, {
                              hd: Curry._1(Css.style, Styles.button),
                              tl: {
                                hd: Curry._1(Css.style, Belt_List.concat({
                                          hd: Css.width(Css.pct(100)),
                                          tl: {
                                            hd: Css.padding4(Css.px(12), Css.px(15), Css.px(10), Css.px(15)),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.light01),
                                              tl: {
                                                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light01),
                                                tl: {
                                                  hd: Css.textAlign("left"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }, isActive ? rowHoverStyle : ({
                                              hd: Css.hover(rowHoverStyle),
                                              tl: /* [] */0
                                            }))),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight(Styles.FontWeight.regular),
                                  tl: {
                                    hd: Css.color(Styles.Color.light10),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.small),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, "\"" + item.VAL + "\" not found"), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Css.px(14)),
                                    tl: {
                                      hd: Css.paddingTop(Css.px(2)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, "Create New Group Type"));
          } else {
            return null;
          }
        } else if (variant === "CreateNewWithGrouping") {
          return null;
        } else if (variant === "Item") {
          return React.createElement("div", {
                      className: Curry._1(Css.style, Belt_List.concat({
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("spaceBetween"),
                                    tl: {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.padding4(Css.px(10), Css.px(35), Css.px(10), Css.px(15)),
                                        tl: {
                                          hd: Css.fontSize(Css.px(14)),
                                          tl: {
                                            hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }, isActive ? rowHoverStyle : ({
                                    hd: Css.hover(rowHoverStyle),
                                    tl: /* [] */0
                                  })))
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.flex({
                                      NAME: "num",
                                      VAL: 2.0
                                    }),
                                tl: {
                                  hd: Css.textAlign("left"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.regular),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: /* [] */0
                                      })
                                }, item.VAL.name), React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginLeft(Css.px(6)),
                                        tl: {
                                          hd: Css.color(Styles.Color.light07),
                                          tl: /* [] */0
                                        }
                                      })
                                }, "– Group Type"))));
        } else {
          return React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding4(Css.px(12), Css.px(15), Css.px(10), Css.px(15)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.light01),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, "The group type \"" + item.VAL.VAL[1].name + "\" has already been added.");
        }
      }),
    renderDetails: (function (item) {
        var tmp;
        if (item.NAME === "Item") {
          var item$1 = item.VAL;
          var usageCount = Curry._1(TrackingPlanMappedModel.Events.size, ModelUtils.eventsSendingGroupType__mapped(model.events, item$1.id));
          tmp = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    })
              }, React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.regular),
                                tl: /* [] */0
                              }
                            })
                      }, item$1.name)), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.fontSize(Styles.FontSize.small),
                          tl: {
                            hd: Css.padding2(Css.px(5), Css.px(0)),
                            tl: /* [] */0
                          }
                        })
                  }, usageCount !== 0 ? (
                      usageCount !== 1 ? "Used in " + String(usageCount) + " Events" : "Used in 1 Event"
                    ) : "Never used"));
        } else {
          tmp = null;
        }
        return React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding(Css.px(15)),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: /* [] */0
                          }
                        })
                  }, tmp);
      }),
    refreshKey: [
      currentGroups,
      options
    ]
  };
  if (getIndexOfValue !== undefined) {
    tmp.getIndexOfValue = Caml_option.valFromOption(getIndexOfValue);
  }
  if (renderButton !== undefined) {
    tmp.renderButton = Caml_option.valFromOption(renderButton);
  }
  return React.createElement(GroupAutofillInput.make, tmp);
}

var make = GroupTypeInput;

export {
  GroupAutofillInput ,
  rowHoverStyle ,
  ItemComparator ,
  queryGroups ,
  getName ,
  make ,
  
}
/* GroupAutofillInput Not a pure module */
