// Generated by ReScript, PLEASE EDIT WITH CARE

import Shortid from "shortid";
import * as AvoModel from "../../../app/src/avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanModel from "../TrackingPlanModel.mjs";
import * as BeltListExtensions from "../../../app/src/BeltListExtensions.mjs";
import * as VariantEventIdUseCase from "./variantEventIdUseCase.mjs";
import * as GetRegexValidationUseCase from "../../../app/src/regex/GetRegexValidationUseCase.mjs";
import * as CreateEventForVariantUseCase from "./createEventForVariantUseCase.mjs";
import * as GetAllEventUserAndGroupPropertyIdsForEventUseCase from "../base/GetAllEventUserAndGroupPropertyIdsForEventUseCase.mjs";

function buildSometimesSentPresenceForAllEventsSendingProperty(model, propertyId, sometimesSentDescription) {
  var eventsSendingProperty = Belt_List.toArray(ModelUtils.eventsSendingProperty(model, undefined, propertyId));
  return Belt_MapString.fromArray(Belt_Array.mapU(eventsSendingProperty, (function ($$event) {
                    return [
                            $$event.id,
                            {
                              TAG: /* AllSources */0,
                              _0: /* SometimesSent */{
                                _0: sometimesSentDescription
                              }
                            }
                          ];
                  })));
}

function buildSometimesSentPresenceForAllEventsAndVariantsSendingProperty(model, propertyId, sometimesSentDescription) {
  var eventsSendingProperty = Belt_List.toArray(ModelUtils.eventsSendingProperty(model, undefined, propertyId));
  return Belt_MapString.fromArray(Belt_Array.reduceU(eventsSendingProperty, [], (function (buildingAbsences, $$event) {
                    return Belt_Array.concat(Belt_Array.concat(buildingAbsences, Belt_Array.keepMapU($$event.variants, (function (variant) {
                                          var match = Belt_MapString.get(variant.propertyOverrides, propertyId);
                                          if (match !== undefined && !match) {
                                            return ;
                                          }
                                          return [
                                                  VariantEventIdUseCase.variantEventId(TrackingPlanModel.EventVariant.makeVariantIdentifier(variant)),
                                                  {
                                                    TAG: /* AllSources */0,
                                                    _0: /* SometimesSent */{
                                                      _0: sometimesSentDescription
                                                    }
                                                  }
                                                ];
                                        }))), [[
                                  $$event.id,
                                  {
                                    TAG: /* AllSources */0,
                                    _0: /* SometimesSent */{
                                      _0: sometimesSentDescription
                                    }
                                  }
                                ]]);
                  })));
}

function applyBasePropertyPresenceToModelForOneVariant(model, baseEvent, variant) {
  var flattenedVariantEventId = VariantEventIdUseCase.variantEventId(TrackingPlanModel.EventVariant.makeVariantIdentifier(variant));
  var propertiesWithoutAbsenceOverride = Belt_Array.keepU(GetAllEventUserAndGroupPropertyIdsForEventUseCase.get(model, baseEvent), (function (basePropertyId) {
          var propertyOverride = Belt_MapString.get(variant.propertyOverrides, basePropertyId);
          if (propertyOverride !== undefined && !(propertyOverride && !propertyOverride._0.absence)) {
            return false;
          } else {
            return true;
          }
        }));
  var modelWithVariantEventAbsencesWithNoOverrides = Belt_Array.reduceU(propertiesWithoutAbsenceOverride, model, (function (buildingModel, notOverridenPropertyId) {
          var notOverridenProperty = ModelUtils.resolvePropertyById(notOverridenPropertyId, buildingModel);
          if (notOverridenProperty !== undefined) {
            return ModelUtils.updateProperty(buildingModel, notOverridenProperty.id, (function (notOverridenProperty) {
                          var newrecord = Caml_obj.caml_obj_dup(notOverridenProperty);
                          var match = notOverridenProperty.absence;
                          var tmp;
                          if (match !== undefined) {
                            if (typeof match === "number") {
                              tmp = /* AlwaysSent */0;
                            } else if (match.TAG === /* SometimesSent */0) {
                              tmp = {
                                TAG: /* SometimesSent */0,
                                _0: match._0
                              };
                            } else {
                              var eventIdsAbsences = match._0;
                              var baseEventAbsence = Belt_MapString.get(eventIdsAbsences, baseEvent.id);
                              tmp = baseEventAbsence !== undefined ? ({
                                    TAG: /* Mixed */1,
                                    _0: Belt_MapString.set(eventIdsAbsences, flattenedVariantEventId, baseEventAbsence)
                                  }) : ({
                                    TAG: /* Mixed */1,
                                    _0: eventIdsAbsences
                                  });
                            }
                          } else {
                            tmp = undefined;
                          }
                          newrecord.absence = tmp;
                          return newrecord;
                        }));
          } else {
            return buildingModel;
          }
        }));
  var propertiesWithAbsenceOverride = Belt_MapString.keepU(variant.propertyOverrides, (function (_propertyId, propertyOverride) {
          if (propertyOverride && propertyOverride._0.absence) {
            return true;
          } else {
            return false;
          }
        }));
  return Belt_MapString.reduceU(propertiesWithAbsenceOverride, modelWithVariantEventAbsencesWithNoOverrides, (function (buildingModel, propertyId, propertyOverride) {
                if (!propertyOverride) {
                  return buildingModel;
                }
                var presenceOverride = propertyOverride._0.absence;
                if (!presenceOverride) {
                  return buildingModel;
                }
                var presenceOverride$1 = presenceOverride._0;
                return ModelUtils.updateProperty(buildingModel, propertyId, (function (baseProperty) {
                              var newrecord = Caml_obj.caml_obj_dup(baseProperty);
                              var match = baseProperty.absence;
                              var tmp;
                              var exit = 0;
                              if (match !== undefined && typeof match !== "number") {
                                if (match.TAG === /* SometimesSent */0) {
                                  var description = match._0;
                                  switch (presenceOverride$1) {
                                    case /* AlwaysSent */0 :
                                        tmp = {
                                          TAG: /* Mixed */1,
                                          _0: Belt_MapString.remove(buildSometimesSentPresenceForAllEventsSendingProperty(buildingModel, propertyId, description), flattenedVariantEventId)
                                        };
                                        break;
                                    case /* SometimesSent */1 :
                                        tmp = {
                                          TAG: /* SometimesSent */0,
                                          _0: description
                                        };
                                        break;
                                    case /* NeverSent */2 :
                                        tmp = {
                                          TAG: /* Mixed */1,
                                          _0: Belt_MapString.set(buildSometimesSentPresenceForAllEventsSendingProperty(buildingModel, propertyId, description), flattenedVariantEventId, {
                                                TAG: /* AllSources */0,
                                                _0: /* NeverSent */0
                                              })
                                        };
                                        break;
                                    
                                  }
                                } else {
                                  var mixedAbsence = match._0;
                                  switch (presenceOverride$1) {
                                    case /* AlwaysSent */0 :
                                        tmp = {
                                          TAG: /* Mixed */1,
                                          _0: Belt_MapString.remove(mixedAbsence, flattenedVariantEventId)
                                        };
                                        break;
                                    case /* SometimesSent */1 :
                                        var currentValue = Belt_MapString.get(mixedAbsence, flattenedVariantEventId);
                                        var tmp$1;
                                        if (currentValue !== undefined && currentValue.TAG === /* AllSources */0) {
                                          var descripton = currentValue._0;
                                          tmp$1 = descripton ? ({
                                                TAG: /* AllSources */0,
                                                _0: /* SometimesSent */{
                                                  _0: descripton._0
                                                }
                                              }) : ({
                                                TAG: /* AllSources */0,
                                                _0: /* SometimesSent */{
                                                  _0: ""
                                                }
                                              });
                                        } else {
                                          tmp$1 = {
                                            TAG: /* AllSources */0,
                                            _0: /* SometimesSent */{
                                              _0: ""
                                            }
                                          };
                                        }
                                        tmp = {
                                          TAG: /* Mixed */1,
                                          _0: Belt_MapString.set(mixedAbsence, flattenedVariantEventId, tmp$1)
                                        };
                                        break;
                                    case /* NeverSent */2 :
                                        tmp = {
                                          TAG: /* Mixed */1,
                                          _0: Belt_MapString.set(mixedAbsence, flattenedVariantEventId, {
                                                TAG: /* AllSources */0,
                                                _0: /* NeverSent */0
                                              })
                                        };
                                        break;
                                    
                                  }
                                }
                              } else {
                                exit = 1;
                              }
                              if (exit === 1) {
                                switch (presenceOverride$1) {
                                  case /* AlwaysSent */0 :
                                      tmp = /* AlwaysSent */0;
                                      break;
                                  case /* SometimesSent */1 :
                                      tmp = {
                                        TAG: /* Mixed */1,
                                        _0: Belt_MapString.fromArray([[
                                                flattenedVariantEventId,
                                                {
                                                  TAG: /* AllSources */0,
                                                  _0: /* SometimesSent */{
                                                    _0: ""
                                                  }
                                                }
                                              ]])
                                      };
                                      break;
                                  case /* NeverSent */2 :
                                      tmp = {
                                        TAG: /* Mixed */1,
                                        _0: Belt_MapString.fromArray([[
                                                flattenedVariantEventId,
                                                {
                                                  TAG: /* AllSources */0,
                                                  _0: /* NeverSent */0
                                                }
                                              ]])
                                      };
                                      break;
                                  
                                }
                              }
                              newrecord.absence = tmp;
                              return newrecord;
                            }));
              }));
}

function applyBasePropertyAllowedValuesToModelForOneVariant(model, baseEvent, variant) {
  var flattenedVariantEventId = VariantEventIdUseCase.variantEventId(TrackingPlanModel.EventVariant.makeVariantIdentifier(variant));
  var eventUserAndGroupPropertyIdsForBaseEventExceptRemoveOverrides = Belt_Array.keepU(GetAllEventUserAndGroupPropertyIdsForEventUseCase.get(model, baseEvent), (function (basePropertyId) {
          var propertyOverride = Belt_MapString.get(variant.propertyOverrides, basePropertyId);
          return Caml_obj.caml_notequal(propertyOverride, /* Removed */0);
        }));
  var modelWithBaseEventAllowedValuesCopiedForVariantEventExceptRemovedProperties = Belt_Array.reduceU(eventUserAndGroupPropertyIdsForBaseEventExceptRemoveOverrides, model, (function (buildingModel, basePropertyId) {
          var baseEventProperty = ModelUtils.resolvePropertyById(basePropertyId, buildingModel);
          if (baseEventProperty !== undefined) {
            return ModelUtils.updateProperty(buildingModel, baseEventProperty.id, (function (baseEventProperty) {
                          var newrecord = Caml_obj.caml_obj_dup(baseEventProperty);
                          newrecord.eventSpecificAllowedPropertyValues = Belt_Array.mapU(baseEventProperty.eventSpecificAllowedPropertyValues, (function (param) {
                                  var propertyValueDisallowedEvents = param[1];
                                  var maybeBaseEveneDisallowedSources = Belt_MapString.get(propertyValueDisallowedEvents, baseEvent.id);
                                  var flattenedVariantEventDisallowedSources = maybeBaseEveneDisallowedSources !== undefined ? Belt_MapString.set(propertyValueDisallowedEvents, flattenedVariantEventId, maybeBaseEveneDisallowedSources) : propertyValueDisallowedEvents;
                                  return [
                                          param[0],
                                          flattenedVariantEventDisallowedSources
                                        ];
                                }));
                          return newrecord;
                        }));
          } else {
            return buildingModel;
          }
        }));
  return Belt_MapString.reduceU(variant.propertyOverrides, modelWithBaseEventAllowedValuesCopiedForVariantEventExceptRemovedProperties, (function (buildingModel, propertyId, propertyOverride) {
                if (!propertyOverride) {
                  return buildingModel;
                }
                var _allowedValuesOverride = propertyOverride._0.allowedValues;
                if (!_allowedValuesOverride) {
                  return buildingModel;
                }
                var _allowedValuesOverride$1 = _allowedValuesOverride._0;
                return ModelUtils.updateProperty(buildingModel, propertyId, (function (baseProperty) {
                              var newrecord = Caml_obj.caml_obj_dup(baseProperty);
                              newrecord.eventSpecificAllowedPropertyValues = Belt_Array.mapU(baseProperty.eventSpecificAllowedPropertyValues, (function (param) {
                                      var propertyValueDisallowedEvents = param[1];
                                      var allowedValueLiteral = param[0];
                                      var allowedValueString = typeof allowedValueLiteral === "object" && allowedValueLiteral.NAME === "StringLit" ? allowedValueLiteral.VAL : Pervasives.failwith("Only string literals are supported for allowed values");
                                      var maybeAllowedValuesOverride = Belt_MapString.get(_allowedValuesOverride$1, allowedValueString);
                                      if (maybeAllowedValuesOverride !== undefined) {
                                        return [
                                                allowedValueLiteral,
                                                Belt_MapString.set(propertyValueDisallowedEvents, flattenedVariantEventId, maybeAllowedValuesOverride)
                                              ];
                                      } else {
                                        return [
                                                allowedValueLiteral,
                                                propertyValueDisallowedEvents
                                              ];
                                      }
                                    }));
                              return newrecord;
                            }));
              }));
}

function applyBasePropertyRegexRulesToModelForOneVariant(model, baseEvent, variant) {
  var flattenedVariantEventId = VariantEventIdUseCase.variantEventId(TrackingPlanModel.EventVariant.makeVariantIdentifier(variant));
  var eventUserAndGroupPropertyIdsForBaseEventExceptRemoveOverrides = Belt_Array.keepU(Belt_Array.concat(GetAllEventUserAndGroupPropertyIdsForEventUseCase.get(model, baseEvent), Belt_Array.mapU(Belt_List.toArray(AvoModel.getSystemProperties(model.properties)), (function (property) {
                  return property.id;
                }))), (function (basePropertyId) {
          var propertyOverride = Belt_MapString.get(variant.propertyOverrides, basePropertyId);
          return Caml_obj.caml_notequal(propertyOverride, /* Removed */0);
        }));
  var addedPropertiesOnTheVariant = Belt_Array.keepMapU(Belt_MapString.toArray(variant.propertyOverrides), (function (param) {
          var propertyOverride = param[1];
          if (propertyOverride && propertyOverride._0.regexValidation) {
            return param[0];
          }
          
        }));
  var propertyIdsOnTheVariantEvent = Belt_SetString.toArray(Belt_SetString.union(Belt_SetString.fromArray(eventUserAndGroupPropertyIdsForBaseEventExceptRemoveOverrides), Belt_SetString.fromArray(addedPropertiesOnTheVariant)));
  var enrichRegexValidationRuleForTheVariantEvent = function (regexValidationRule, propertyId) {
    var maybeBaseEventSpecificRegexRule = Belt_MapString.get(regexValidationRule.eventOverrides, baseEvent.id);
    var maybeVariantOverride = Belt_Option.flatMapU(Belt_Option.flatMapU(Belt_MapString.get(variant.propertyOverrides, propertyId), (function (propertyOverride) {
                if (!propertyOverride) {
                  return ;
                }
                var regexValidationRule = propertyOverride._0.regexValidation;
                if (regexValidationRule) {
                  return Caml_option.some(regexValidationRule._0);
                }
                
              })), (function (regexValidationRule) {
            return regexValidationRule;
          }));
    var regexValidation;
    var exit = 0;
    if (maybeBaseEventSpecificRegexRule !== undefined) {
      if (maybeVariantOverride !== undefined) {
        exit = 1;
      } else {
        regexValidation = {
          propertyRule: regexValidationRule.propertyRule,
          eventOverrides: Belt_MapString.set(regexValidationRule.eventOverrides, flattenedVariantEventId, maybeBaseEventSpecificRegexRule)
        };
      }
    } else if (maybeVariantOverride !== undefined) {
      exit = 1;
    } else {
      regexValidation = regexValidationRule;
    }
    if (exit === 1) {
      regexValidation = {
        propertyRule: regexValidationRule.propertyRule,
        eventOverrides: Belt_MapString.set(regexValidationRule.eventOverrides, flattenedVariantEventId, maybeVariantOverride)
      };
    }
    return {
            NAME: "Regex",
            VAL: regexValidation
          };
  };
  return Belt_Array.reduceU(propertyIdsOnTheVariantEvent, model, (function (buildingModel, propertyId) {
                var propertyOnTheVariantEvent = ModelUtils.resolvePropertyById(propertyId, buildingModel);
                if (propertyOnTheVariantEvent !== undefined) {
                  return ModelUtils.updateProperty(buildingModel, propertyOnTheVariantEvent.id, (function (propertyOnTheVariantEvent) {
                                var newrecord = Caml_obj.caml_obj_dup(propertyOnTheVariantEvent);
                                var maybeExistingRegex = GetRegexValidationUseCase.get(propertyOnTheVariantEvent);
                                var newRegex = enrichRegexValidationRuleForTheVariantEvent(maybeExistingRegex !== undefined ? maybeExistingRegex : ({
                                          propertyRule: undefined,
                                          eventOverrides: undefined
                                        }), propertyId);
                                newrecord.validations = {
                                  hd: newRegex,
                                  tl: Belt_List.keepU(propertyOnTheVariantEvent.validations, (function (validation) {
                                          if (typeof validation === "object") {
                                            return validation.NAME !== "Regex";
                                          } else {
                                            return true;
                                          }
                                        }))
                                };
                                return newrecord;
                              }));
                } else {
                  return buildingModel;
                }
              }));
}

function createEventRuleCopyForVariant(variant, baseRuleDefinition) {
  return {
          id: Shortid(),
          item: {
            NAME: "Event",
            VAL: VariantEventIdUseCase.variantEventId(TrackingPlanModel.EventVariant.makeVariantIdentifier(variant))
          },
          definition: baseRuleDefinition
        };
}

function createPropertyRefPinningRuleForVariantIfNotOverridden(variant, propertyId, basePinnedValue) {
  var hasPropertyPinningOverride = Belt_Option.mapWithDefaultU(Belt_MapString.get(variant.propertyOverrides, propertyId), false, (function (propertyOverride) {
          if (propertyOverride && !propertyOverride._0.pinnedValue) {
            return false;
          } else {
            return true;
          }
        }));
  if (!hasPropertyPinningOverride) {
    return {
            id: Shortid(),
            item: {
              NAME: "PropertyRef",
              VAL: [
                VariantEventIdUseCase.variantEventId(TrackingPlanModel.EventVariant.makeVariantIdentifier(variant)),
                propertyId
              ]
            },
            definition: {
              NAME: "PinnedValue",
              VAL: basePinnedValue
            }
          };
  }
  
}

function createNotOverridablePropertyRefRuleForVariant(variant, propertyId, baseDefinition) {
  return {
          id: Shortid(),
          item: {
            NAME: "PropertyRef",
            VAL: [
              VariantEventIdUseCase.variantEventId(TrackingPlanModel.EventVariant.makeVariantIdentifier(variant)),
              propertyId
            ]
          },
          definition: baseDefinition
        };
}

function createOverriddenRulesForVariant(variant) {
  return BeltListExtensions.flatMapU(Belt_MapString.toList(variant.propertyOverrides), (function (param) {
                var propertyOverride = param[1];
                if (!propertyOverride) {
                  return /* [] */0;
                }
                var match = propertyOverride._0.pinnedValue;
                if (!match) {
                  return /* [] */0;
                }
                var pinnedValueOverride = match._0;
                if (pinnedValueOverride === undefined) {
                  return /* [] */0;
                }
                var rule_id = Shortid();
                var rule_item = {
                  NAME: "PropertyRef",
                  VAL: [
                    VariantEventIdUseCase.variantEventId(TrackingPlanModel.EventVariant.makeVariantIdentifier(variant)),
                    param[0]
                  ]
                };
                var rule_definition = {
                  NAME: "PinnedValue",
                  VAL: pinnedValueOverride
                };
                var rule = {
                  id: rule_id,
                  item: rule_item,
                  definition: rule_definition
                };
                return {
                        hd: rule,
                        tl: /* [] */0
                      };
              }));
}

function createRulesForOverriddenPinnedValues(model) {
  return Belt_List.reduceU(model.events, /* [] */0, (function (rules, $$event) {
                return Belt_Array.reduceU($$event.variants, rules, (function (rules, variant) {
                              var extraRulesForVariant = createOverriddenRulesForVariant(variant);
                              return Belt_List.concat(rules, extraRulesForVariant);
                            }));
              }));
}

function flattenOneVariant(notFlattenedModel, baseEvent, variant) {
  var model = applyBasePropertyPresenceToModelForOneVariant(notFlattenedModel, baseEvent, variant);
  var model$1 = applyBasePropertyAllowedValuesToModelForOneVariant(model, baseEvent, variant);
  var model$2 = applyBasePropertyRegexRulesToModelForOneVariant(model$1, baseEvent, variant);
  var inheritedRulesToAdd = BeltListExtensions.flatMapU(model$2.rules, (function (baseRule) {
          var match = baseRule.item;
          var variant$1 = match.NAME;
          if (variant$1 !== "PropertyRef") {
            if (variant$1 === "Event" && match.VAL === baseEvent.id) {
              return {
                      hd: createEventRuleCopyForVariant(variant, baseRule.definition),
                      tl: /* [] */0
                    };
            } else {
              return /* [] */0;
            }
          }
          var baseDefinition = baseRule.definition;
          var match$1 = match.VAL;
          var propertyId = match$1[1];
          var eventId = match$1[0];
          if (typeof baseDefinition === "object" && baseDefinition.NAME === "PinnedValue" && eventId === baseEvent.id) {
            return Belt_Option.mapWithDefault(createPropertyRefPinningRuleForVariantIfNotOverridden(variant, propertyId, baseDefinition.VAL), /* [] */0, (function (rule) {
                          return {
                                  hd: rule,
                                  tl: /* [] */0
                                };
                        }));
          }
          if (eventId === baseEvent.id) {
            return {
                    hd: createNotOverridablePropertyRefRuleForVariant(variant, propertyId, baseDefinition),
                    tl: /* [] */0
                  };
          } else {
            return /* [] */0;
          }
        }));
  var overridenRulesToAdd = createOverriddenRulesForVariant(variant);
  var model_types = model$2.types;
  var model_properties = model$2.properties;
  var model_propertyBundles = model$2.propertyBundles;
  var model_events = model$2.events;
  var model_migrations = model$2.migrations;
  var model_sources = model$2.sources;
  var model_destinations = model$2.destinations;
  var model_groupTypes = model$2.groupTypes;
  var model_goals = model$2.goals;
  var model_metrics = model$2.metrics;
  var model_archive = model$2.archive;
  var model_openBranches = model$2.openBranches;
  var model_branchPointer = model$2.branchPointer;
  var model_rules = Belt_List.concat(Belt_List.concat(model$2.rules, inheritedRulesToAdd), overridenRulesToAdd);
  var model_integrations = model$2.integrations;
  var model_globalRequirements = model$2.globalRequirements;
  var model_types$1 = model_types;
  var model_properties$1 = model_properties;
  var model_propertyBundles$1 = model_propertyBundles;
  var model_events$1 = Belt_List.concat(model_events, {
        hd: CreateEventForVariantUseCase.create(variant, baseEvent),
        tl: /* [] */0
      });
  var model_migrations$1 = model_migrations;
  var model_sources$1 = model_sources;
  var model_destinations$1 = model_destinations;
  var model_groupTypes$1 = model_groupTypes;
  var model_goals$1 = model_goals;
  var model_metrics$1 = model_metrics;
  var model_archive$1 = model_archive;
  var model_openBranches$1 = model_openBranches;
  var model_branchPointer$1 = model_branchPointer;
  var model_rules$1 = model_rules;
  var model_integrations$1 = model_integrations;
  var model_globalRequirements$1 = model_globalRequirements;
  return {
          types: model_types$1,
          properties: model_properties$1,
          propertyBundles: model_propertyBundles$1,
          events: Belt_List.mapU(model_events$1, (function ($$event) {
                  if ($$event.id === baseEvent.id) {
                    return {
                            id: $$event.id,
                            name: $$event.name,
                            uniqueName: $$event.uniqueName,
                            description: $$event.description,
                            directPropertyRefs: $$event.directPropertyRefs,
                            propertyBundles: $$event.propertyBundles,
                            variants: Belt_Array.keepU($$event.variants, (function (eventVariant) {
                                    return eventVariant.id !== variant.id;
                                  })),
                            types: $$event.types,
                            tags: $$event.tags,
                            excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                            includeSources: $$event.includeSources,
                            includeDestinations: $$event.includeDestinations,
                            hashes: $$event.hashes,
                            propertyWhitelist: $$event.propertyWhitelist,
                            eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                            userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                            triggers: $$event.triggers,
                            globalRequirementsMetadata: $$event.globalRequirementsMetadata
                          };
                  } else {
                    return $$event;
                  }
                })),
          migrations: model_migrations$1,
          sources: model_sources$1,
          destinations: model_destinations$1,
          groupTypes: model_groupTypes$1,
          goals: model_goals$1,
          metrics: model_metrics$1,
          archive: model_archive$1,
          openBranches: model_openBranches$1,
          branchPointer: model_branchPointer$1,
          rules: model_rules$1,
          integrations: model_integrations$1,
          globalRequirements: model_globalRequirements$1
        };
}

function flatten(notFlattenedModel) {
  var propertyIdsWithAbsenceOverrides = Belt_SetString.fromArray(Belt_List.reduceU(notFlattenedModel.events, [], (function (allPropertiesWithAbsenceOverridesIds, $$event) {
              return Belt_Array.reduceU($$event.variants, allPropertiesWithAbsenceOverridesIds, (function (oneEventPropertiesWithAbsenceOverridesIds, variant) {
                            return Belt_MapString.reduceU(variant.propertyOverrides, oneEventPropertiesWithAbsenceOverridesIds, (function (oneVariantPropertiesWithAbsenceOverridesIds, propertyId, propertyOverride) {
                                          if (propertyOverride && propertyOverride._0.absence) {
                                            return Belt_Array.concat(oneVariantPropertiesWithAbsenceOverridesIds, [propertyId]);
                                          } else {
                                            return oneVariantPropertiesWithAbsenceOverridesIds;
                                          }
                                        }));
                          }));
            })));
  var notFlattenedModel_types = notFlattenedModel.types;
  var notFlattenedModel_properties = Belt_List.mapU(notFlattenedModel.properties, (function (wrappedProperty) {
          var property = Belt_Option.getExn(ModelUtils.resolveProperty(notFlattenedModel, wrappedProperty));
          var match = property.absence;
          var tmp;
          if (match !== undefined && !(typeof match === "number" || !(match.TAG === /* SometimesSent */0 && Belt_SetString.has(propertyIdsWithAbsenceOverrides, property.id)))) {
            var newrecord = Caml_obj.caml_obj_dup(property);
            newrecord.absence = {
              TAG: /* Mixed */1,
              _0: buildSometimesSentPresenceForAllEventsAndVariantsSendingProperty(notFlattenedModel, property.id, match._0)
            };
            tmp = newrecord;
          } else {
            tmp = property;
          }
          return {
                  TAG: /* Property */1,
                  _0: tmp
                };
        }));
  var notFlattenedModel_propertyBundles = notFlattenedModel.propertyBundles;
  var notFlattenedModel_events = notFlattenedModel.events;
  var notFlattenedModel_migrations = notFlattenedModel.migrations;
  var notFlattenedModel_sources = notFlattenedModel.sources;
  var notFlattenedModel_destinations = notFlattenedModel.destinations;
  var notFlattenedModel_groupTypes = notFlattenedModel.groupTypes;
  var notFlattenedModel_goals = notFlattenedModel.goals;
  var notFlattenedModel_metrics = notFlattenedModel.metrics;
  var notFlattenedModel_archive = notFlattenedModel.archive;
  var notFlattenedModel_openBranches = notFlattenedModel.openBranches;
  var notFlattenedModel_branchPointer = notFlattenedModel.branchPointer;
  var notFlattenedModel_rules = notFlattenedModel.rules;
  var notFlattenedModel_integrations = notFlattenedModel.integrations;
  var notFlattenedModel_globalRequirements = notFlattenedModel.globalRequirements;
  var notFlattenedModel$1 = {
    types: notFlattenedModel_types,
    properties: notFlattenedModel_properties,
    propertyBundles: notFlattenedModel_propertyBundles,
    events: notFlattenedModel_events,
    migrations: notFlattenedModel_migrations,
    sources: notFlattenedModel_sources,
    destinations: notFlattenedModel_destinations,
    groupTypes: notFlattenedModel_groupTypes,
    goals: notFlattenedModel_goals,
    metrics: notFlattenedModel_metrics,
    archive: notFlattenedModel_archive,
    openBranches: notFlattenedModel_openBranches,
    branchPointer: notFlattenedModel_branchPointer,
    rules: notFlattenedModel_rules,
    integrations: notFlattenedModel_integrations,
    globalRequirements: notFlattenedModel_globalRequirements
  };
  var model = Belt_List.reduceU(notFlattenedModel_events, notFlattenedModel$1, (function (buildingFullModel, $$event) {
          var modelWithEnrichedVariantsAbsencesForEvent = Belt_Array.reduceU($$event.variants, buildingFullModel, (function (buildingPerEventModel, variant) {
                  return applyBasePropertyPresenceToModelForOneVariant(buildingPerEventModel, $$event, variant);
                }));
          var modelWithEnrichedVariantsAllowedValuesForEvent = Belt_Array.reduceU($$event.variants, modelWithEnrichedVariantsAbsencesForEvent, (function (buildingPerEventModel, variant) {
                  return applyBasePropertyAllowedValuesToModelForOneVariant(buildingPerEventModel, $$event, variant);
                }));
          return Belt_Array.reduceU($$event.variants, modelWithEnrichedVariantsAllowedValuesForEvent, (function (buildingPerEventModel, variant) {
                        return applyBasePropertyRegexRulesToModelForOneVariant(buildingPerEventModel, $$event, variant);
                      }));
        }));
  var inheritedRulesToAdd = BeltListExtensions.flatMapU(model.rules, (function (baseRule) {
          var match = baseRule.item;
          var variant = match.NAME;
          if (variant !== "PropertyRef") {
            if (variant === "Event") {
              var eventId = match.VAL;
              var ruleDefinition = baseRule.definition;
              var $$event = ModelUtils.getEventById(eventId, model);
              if ($$event !== undefined) {
                return Belt_List.fromArray(Belt_Array.mapU($$event.variants, (function (variant) {
                                  return createEventRuleCopyForVariant(variant, ruleDefinition);
                                })));
              } else {
                return /* [] */0;
              }
            } else {
              return /* [] */0;
            }
          }
          var baseDefinition = baseRule.definition;
          var match$1 = match.VAL;
          var propertyId = match$1[1];
          var eventId$1 = match$1[0];
          if (typeof baseDefinition === "object" && baseDefinition.NAME === "PinnedValue") {
            var basePinnedValue = baseDefinition.VAL;
            var $$event$1 = ModelUtils.getEventById(eventId$1, model);
            if ($$event$1 !== undefined) {
              return Belt_List.fromArray(Belt_Array.keepMapU($$event$1.variants, (function (variant) {
                                return createPropertyRefPinningRuleForVariantIfNotOverridden(variant, propertyId, basePinnedValue);
                              })));
            } else {
              return /* [] */0;
            }
          } else {
            var $$event$2 = ModelUtils.getEventById(eventId$1, model);
            if ($$event$2 !== undefined) {
              return Belt_List.fromArray(Belt_Array.mapU($$event$2.variants, (function (variant) {
                                return createNotOverridablePropertyRefRuleForVariant(variant, propertyId, baseDefinition);
                              })));
            } else {
              return /* [] */0;
            }
          }
        }));
  var overridenRulesToAdd = createRulesForOverriddenPinnedValues(model);
  var model_types = model.types;
  var model_properties = model.properties;
  var model_propertyBundles = model.propertyBundles;
  var model_events = model.events;
  var model_migrations = model.migrations;
  var model_sources = model.sources;
  var model_destinations = model.destinations;
  var model_groupTypes = model.groupTypes;
  var model_goals = model.goals;
  var model_metrics = model.metrics;
  var model_archive = model.archive;
  var model_openBranches = model.openBranches;
  var model_branchPointer = model.branchPointer;
  var model_rules = Belt_List.concat(Belt_List.concat(model.rules, inheritedRulesToAdd), overridenRulesToAdd);
  var model_integrations = model.integrations;
  var model_globalRequirements = model.globalRequirements;
  var model_types$1 = model_types;
  var model_properties$1 = model_properties;
  var model_propertyBundles$1 = model_propertyBundles;
  var model_events$1 = BeltListExtensions.flatMapU(model_events, (function ($$event) {
          return Belt_List.concat({
                      hd: $$event,
                      tl: /* [] */0
                    }, Belt_List.fromArray(Belt_Array.map($$event.variants, (function (variant) {
                                return CreateEventForVariantUseCase.create(variant, $$event);
                              }))));
        }));
  var model_migrations$1 = model_migrations;
  var model_sources$1 = model_sources;
  var model_destinations$1 = model_destinations;
  var model_groupTypes$1 = model_groupTypes;
  var model_goals$1 = model_goals;
  var model_metrics$1 = model_metrics;
  var model_archive$1 = model_archive;
  var model_openBranches$1 = model_openBranches;
  var model_branchPointer$1 = model_branchPointer;
  var model_rules$1 = model_rules;
  var model_integrations$1 = model_integrations;
  var model_globalRequirements$1 = model_globalRequirements;
  return {
          types: model_types$1,
          properties: model_properties$1,
          propertyBundles: model_propertyBundles$1,
          events: Belt_List.mapU(model_events$1, (function ($$event) {
                  return {
                          id: $$event.id,
                          name: $$event.name,
                          uniqueName: $$event.uniqueName,
                          description: $$event.description,
                          directPropertyRefs: $$event.directPropertyRefs,
                          propertyBundles: $$event.propertyBundles,
                          variants: [],
                          types: $$event.types,
                          tags: $$event.tags,
                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                          includeSources: $$event.includeSources,
                          includeDestinations: $$event.includeDestinations,
                          hashes: $$event.hashes,
                          propertyWhitelist: $$event.propertyWhitelist,
                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                          triggers: $$event.triggers,
                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                        };
                })),
          migrations: model_migrations$1,
          sources: model_sources$1,
          destinations: model_destinations$1,
          groupTypes: model_groupTypes$1,
          goals: model_goals$1,
          metrics: model_metrics$1,
          archive: model_archive$1,
          openBranches: model_openBranches$1,
          branchPointer: model_branchPointer$1,
          rules: model_rules$1,
          integrations: model_integrations$1,
          globalRequirements: model_globalRequirements$1
        };
}

export {
  flattenOneVariant ,
  flatten ,
  
}
/* shortid Not a pure module */
