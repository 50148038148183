// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function getVariantsCountOnEvents(propertyEvents) {
  return Belt_Array.reduceU(propertyEvents, 0, (function (acc, propertyEvent) {
                return acc + propertyEvent.variants.length | 0;
              }));
}

export {
  getVariantsCountOnEvents ,
  
}
/* No side effect */
