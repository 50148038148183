// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "date-fns";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as AnalyticsUtilsBundles from "../analyticsUtilsBundles.mjs";

function issuesToMap(issues) {
  return Belt_MapString.fromArray(Belt_Array.mapU(issues, (function (issue) {
                    return [
                            issue.id,
                            issue
                          ];
                  })));
}

function inspectorIssueTypeToAnalytics(issueType) {
  if (typeof issueType === "number") {
    if (issueType === /* EventNotInTrackingPlan */0) {
      return "EventNotFoundInTrackingPlan";
    } else {
      return "UnexpectedEvent";
    }
  }
  switch (issueType.TAG | 0) {
    case /* MissingExpectedProperty */0 :
        return "MissingExpectedProperty";
    case /* PropertyTypeInconsistentWithTrackingPlan */1 :
        return "PropertyInconsistentWithTrackingPlan";
    case /* UnexpectedProperty */2 :
        return "UnexpectedProperty";
    case /* InconsistentType */3 :
        return "InconsistentPropertyValueType";
    
  }
}

function inspectorIssueStatusToAnalytics(issueStatus) {
  var match = issueStatus.status;
  if (typeof match === "number") {
    return "Unresolved";
  } else if (match.TAG === /* Ignored */0) {
    return "Ignored";
  } else {
    return "Resolved";
  }
}

function createInspectorIssueDetailsGroup(inspectorIssueDetailsInteractionType, sourceCount, issue) {
  var match = issue.issueCount;
  var match$1 = issue.eventCount;
  return AnalyticsUtilsBundles.inspectorIssueDetails(match !== 0 ? (
                match$1 !== 0 ? match / match$1 : 0.0
              ) : 0.0, issue.issueCount, issue.eventCount, sourceCount, DateFns.differenceInHours(new Date(), issue.firstSeen) | 0, DateFns.differenceInHours(new Date(), issue.lastSeen) | 0, inspectorIssueTypeToAnalytics(issue.issueType), issue.id, issue.sharedId, inspectorIssueDetailsInteractionType, inspectorIssueStatusToAnalytics(issue.issueStatus));
}

export {
  issuesToMap ,
  inspectorIssueTypeToAnalytics ,
  inspectorIssueStatusToAnalytics ,
  createInspectorIssueDetailsGroup ,
  
}
/* date-fns Not a pure module */
