// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Errors from "../../../shared/utils/Errors.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function useInvocationsWithPromise(param) {
  var invocations = param[1];
  var status = param[0];
  var match = React.useState(function () {
        
      });
  var setResolveFunction = match[1];
  var resolveFunction = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setRejectFunction = match$1[1];
  var rejectFunction = match$1[0];
  var match$2 = React.useState(function () {
        return new Promise((function (resolve, reject) {
                      Curry._1(setResolveFunction, (function (param) {
                              return resolve;
                            }));
                      return Curry._1(setRejectFunction, (function (param) {
                                    return reject;
                                  }));
                    }));
      });
  React.useEffect((function () {
          if (status !== "Loading" && resolveFunction !== undefined) {
            var resolve = Belt_Option.getExn(resolveFunction);
            resolve(invocations);
          }
          
        }), [
        status,
        resolveFunction
      ]);
  React.useEffect((function () {
          var timeoutId = setTimeout((function (param) {
                  if (!Belt_Option.isSome(rejectFunction)) {
                    return ;
                  }
                  var reject = Belt_Option.getExn(rejectFunction);
                  return reject({
                              RE_EXN_ID: Errors.TimeoutException,
                              _1: "Timeout of 10 seconds"
                            });
                }), 10000);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), []);
  return match$2[0];
}

export {
  useInvocationsWithPromise ,
  
}
/* react Not a pure module */
