// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Dopt from "./externals/Dopt.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Issues from "../../shared/models/Issues.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as $$Window from "./externals/window.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Dom_storage from "rescript/lib/es6/dom_storage.js";
import * as RouterStore from "./RouterStore.mjs";
import * as AvoEngagement from "./avoEngagement/AvoEngagement.mjs";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as InspectorStats from "./inspector/InspectorStats.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as ProductTour__Root from "./productTour/ProductTour__Root.mjs";
import * as ProductTour__Step from "./productTour/ProductTour__Step.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as AvoProductTour__Root from "./productTour/AvoProductTour__Root.mjs";
import * as AvoProductTour__Step from "./productTour/AvoProductTour__Step.mjs";
import * as InspectorIssuesStore from "./inspector/InspectorIssuesStore.mjs";
import * as InspectorIssuesFilter from "../../shared/models/InspectorIssuesFilter.mjs";
import * as ProductTour__GenericStep from "./productTour/ProductTour__GenericStep.mjs";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesFilterUtils from "../../shared/utils/InspectorIssuesFilterUtils.mjs";
import * as AvoProductTour__GenericStep from "./productTour/AvoProductTour__GenericStep.mjs";

function setFiltersOrDoNothing(appVersions, inspectorStats, issues, model, setFilters) {
  var inspectorSources = Belt_List.toArray(Belt_List.keep(model.sources, (function (source) {
              var match = InspectorStats.getSourceStatus(source, inspectorStats);
              if (typeof match === "number") {
                return match === 0;
              } else {
                return true;
              }
            })));
  var countedAvailableFilters = InspectorIssuesFilterUtils.FilterCounter.get(inspectorSources, model, appVersions, Curry._1(Issues.toArray, issues));
  var mostCommonSource = Belt_Option.mapWithDefault(Belt_Map.get(countedAvailableFilters, "source"), [], (function (item) {
          if (typeof item !== "object") {
            return [];
          }
          if (item.NAME !== "sources") {
            return [];
          }
          var countedSources = item.VAL;
          if (countedSources.length !== 0) {
            return Belt_Array.slice(Belt_Array.mapU(Belt_SortArray.stableSortByU(Belt_Array.keepU(countedSources, (function (param) {
                                      return param[1] > 0;
                                    })), (function (b, a) {
                                  return Caml.caml_int_compare(a[1], b[1]);
                                })), (function (param) {
                              return {
                                      NAME: "source",
                                      VAL: param[0]
                                    };
                            })), 0, 1);
          } else {
            return [];
          }
        }));
  return Curry._1(setFilters, (function (currentFilters) {
                if (Caml_obj.caml_equal(currentFilters, [])) {
                  return mostCommonSource;
                }
                var currentFilteredIssuesCount = Curry._1(Issues.size, InspectorIssuesFilterUtils.filterIssueViewModels(undefined, appVersions, issues, currentFilters));
                if (currentFilteredIssuesCount === 0) {
                  return mostCommonSource;
                } else {
                  return currentFilters;
                }
              }));
}

function InspectorIssuesOnboardingFlow$IssuesWrapper(Props) {
  var blockId = Props.blockId;
  var arrowOffset = Props.arrowOffset;
  var borderRadius = Props.borderRadius;
  var delay = Props.delay;
  var elementClickable = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var lockBackground = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var onOutsideClick = Props.onOutsideClick;
  var onStepActive = Props.onStepActive;
  var onTransition = Props.onTransition;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var simulateClick = Props.simulateClick;
  var stepType = Props.stepType;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var transitions = Props.transitions;
  var waitForId = Props.waitForId;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var match = InspectorIssuesStore.useIssues(true, /* Unresolved */0);
  var issues = match.issues;
  var onTransition$1 = Belt_Option.map(onTransition, (function (onTransition) {
          return Curry._1(onTransition, issues);
        }));
  var onStepActive$1 = Belt_Option.map(onStepActive, (function (onStepActive, param) {
          return Curry._1(onStepActive, issues);
        }));
  if (match.loadingState !== "success") {
    return null;
  }
  var tmp = {
    blockId: blockId,
    stepType: stepType,
    transitions: Curry._1(transitions, issues),
    children: children
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (borderRadius !== undefined) {
    tmp.borderRadius = Caml_option.valFromOption(borderRadius);
  }
  if (delay !== undefined) {
    tmp.delay = Caml_option.valFromOption(delay);
  }
  if (elementClickable !== undefined) {
    tmp.elementClickable = Caml_option.valFromOption(elementClickable);
  }
  if (elementPadding !== undefined) {
    tmp.elementPadding = Caml_option.valFromOption(elementPadding);
  }
  if (lockBackground !== undefined) {
    tmp.lockBackground = Caml_option.valFromOption(lockBackground);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onOutsideClick !== undefined) {
    tmp.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
  }
  if (onStepActive$1 !== undefined) {
    tmp.onStepActive = Caml_option.valFromOption(onStepActive$1);
  }
  if (onTransition$1 !== undefined) {
    tmp.onTransition = Caml_option.valFromOption(onTransition$1);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (positionDependencies !== undefined) {
    tmp.positionDependencies = Caml_option.valFromOption(positionDependencies);
  }
  if (simulateClick !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(simulateClick);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = Caml_option.valFromOption(transitionDuration);
  }
  if (waitForId !== undefined) {
    tmp.waitForId = Caml_option.valFromOption(waitForId);
  }
  if (withOutline !== undefined) {
    tmp.withOutline = Caml_option.valFromOption(withOutline);
  }
  return React.createElement(AvoProductTour__Step.make, tmp);
}

var IssuesWrapper = {
  make: InspectorIssuesOnboardingFlow$IssuesWrapper
};

function InspectorIssuesOnboardingFlow$ConditionalProductStep(Props) {
  var engagementId = Props.engagementId;
  var blockId = Props.blockId;
  var arrowOffset = Props.arrowOffset;
  var borderRadius = Props.borderRadius;
  var delay = Props.delay;
  var elementClickable = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var lockBackground = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var onOutsideClick = Props.onOutsideClick;
  var onStepActive = Props.onStepActive;
  var onTransition = Props.onTransition;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var simulateClick = Props.simulateClick;
  var stepType = Props.stepType;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var transitions = Props.transitions;
  var waitForId = Props.waitForId;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var match = AvoEngagement.Block.use(engagementId, blockId);
  var opened = match[0].state.active;
  if (!opened) {
    return null;
  }
  var tmp = {
    blockId: blockId,
    stepType: stepType,
    transitions: transitions,
    children: children
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = arrowOffset;
  }
  if (borderRadius !== undefined) {
    tmp.borderRadius = Caml_option.valFromOption(borderRadius);
  }
  if (delay !== undefined) {
    tmp.delay = delay;
  }
  if (elementClickable !== undefined) {
    tmp.elementClickable = elementClickable;
  }
  if (elementPadding !== undefined) {
    tmp.elementPadding = elementPadding;
  }
  if (lockBackground !== undefined) {
    tmp.lockBackground = lockBackground;
  }
  if (offset !== undefined) {
    tmp.offset = offset;
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onOutsideClick !== undefined) {
    tmp.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
  }
  if (onStepActive !== undefined) {
    tmp.onStepActive = Caml_option.valFromOption(onStepActive);
  }
  if (onTransition !== undefined) {
    tmp.onTransition = Caml_option.valFromOption(onTransition);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (positionDependencies !== undefined) {
    tmp.positionDependencies = Caml_option.valFromOption(positionDependencies);
  }
  if (simulateClick !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(simulateClick);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = transitionDuration;
  }
  if (waitForId !== undefined) {
    tmp.waitForId = waitForId;
  }
  if (withOutline !== undefined) {
    tmp.withOutline = withOutline;
  }
  return React.createElement(InspectorIssuesOnboardingFlow$IssuesWrapper, tmp);
}

var ConditionalProductStep = {
  make: InspectorIssuesOnboardingFlow$ConditionalProductStep
};

var engagementId = "inspector-issues-onboarding-flow-v2";

function InspectorIssuesOnboardingFlow$SavedViews(Props) {
  return React.createElement(ProductTour__Root.make, {
              tourId: engagementId,
              continuingFlow: true,
              showStepCount: false,
              withConfetti: true,
              children: React.createElement(ProductTour__Step.make, {
                    stepId: "step-10",
                    stepType: {
                      TAG: /* OnElementId */0,
                      _0: "saved-views-table"
                    },
                    borderRadius: 6,
                    elementClickable: false,
                    elementPadding: 7,
                    offset: -60,
                    onTransition: (function (transitionStep) {
                        if (transitionStep === "previous") {
                          return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                      NAME: "inspector",
                                      VAL: "issues"
                                    });
                        }
                        
                      }),
                    onStepActive: (function (param) {
                        return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                    NAME: "inspector",
                                    VAL: "savedViews"
                                  });
                      }),
                    position: "left",
                    transition: Mantine.Transition.subtleUpSlide,
                    transitions: [
                      {
                        key: "previous",
                        label: undefined,
                        icon: "arrowLeft"
                      },
                      {
                        key: "complete",
                        label: "Finish!",
                        icon: undefined
                      }
                    ],
                    transitionDuration: 400,
                    children: React.createElement(ProductTour__GenericStep.make, {
                          withExit: false
                        })
                  })
            });
}

var SavedViews = {
  make: InspectorIssuesOnboardingFlow$SavedViews
};

var key = "inspector-issues-onboarding-flow-v2-active";

function getWasInspectorOnboardingFlowActiveInSession(param) {
  return Belt_Option.mapWithDefault(Dom_storage.getItem(key, sessionStorage), false, Pervasives.bool_of_string);
}

function setWasInspectorOnboardingFlowActiveInSession(value) {
  return Dom_storage.setItem(key, Pervasives.string_of_bool(value), sessionStorage);
}

var SessionStorage = {
  key: key,
  getWasInspectorOnboardingFlowActiveInSession: getWasInspectorOnboardingFlowActiveInSession,
  setWasInspectorOnboardingFlowActiveInSession: setWasInspectorOnboardingFlowActiveInSession
};

var tourId = "actionable-issue-details";

function InspectorIssuesOnboardingFlow$ActionableIssueDetails(Props) {
  var for_ = Props.for_;
  var match = Dopt.Flow.use("inspector-issues-onboarding-flow-v2");
  var issueOnboardingFlow = match[0];
  var match$1 = Dopt.Flow.use(tourId);
  var intent = match$1[1];
  React.useEffect((function () {
          if (Caml_obj.caml_equal(Dopt.Flow.active(issueOnboardingFlow), true)) {
            Dopt.Flow.reset(undefined, intent);
          }
          
        }), []);
  var issuesOnboardingFlowWasActiveInSession = getWasInspectorOnboardingFlowActiveInSession(undefined);
  var match$2 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var match$3 = InspectorIssuesStore.useFilteredIssues(match$2[0], undefined, undefined);
  var filteredIssues = match$3.filteredIssues;
  var match$4 = Dopt.Flow.active(issueOnboardingFlow);
  var match$5 = Dopt.Flow.finished(issueOnboardingFlow);
  if (match$4 === undefined) {
    return null;
  }
  if (match$5 === undefined) {
    return null;
  }
  var tourMayShow = !match$4 && !match$5 && !issuesOnboardingFlowWasActiveInSession;
  return React.createElement(ProductTour__Root.make, {
              tourId: tourId,
              showStepCount: false,
              tourMayShow: tourMayShow,
              children: for_ === "details" ? React.createElement(React.Fragment, undefined, React.createElement(ProductTour__Step.make, {
                          stepId: "step-1",
                          stepType: {
                            TAG: /* OnElementId */0,
                            _0: "drawer-container"
                          },
                          delay: 550,
                          elementClickable: false,
                          position: "left",
                          transition: Mantine.Transition.subtleRightSlide,
                          transitions: [{
                              key: "next",
                              label: undefined,
                              icon: "arrowRight"
                            }],
                          transitionDuration: 400,
                          withOutline: false,
                          children: React.createElement(ProductTour__GenericStep.make, {})
                        }), React.createElement(ProductTour__Step.make, {
                          stepId: "step-2",
                          stepType: {
                            TAG: /* OnElementId */0,
                            _0: "issue-status-id-for-flows"
                          },
                          arrowOffset: 64,
                          borderRadius: 8,
                          delay: 150,
                          elementClickable: false,
                          elementPadding: 16,
                          position: "left-start",
                          transition: Mantine.Transition.subtleRightSlide,
                          transitions: [
                            {
                              key: "previous",
                              label: undefined,
                              icon: "arrowLeft"
                            },
                            {
                              key: "next",
                              label: undefined,
                              icon: "arrowRight"
                            }
                          ],
                          transitionDuration: 400,
                          withOutline: false,
                          children: React.createElement(ProductTour__GenericStep.make, {})
                        })) : React.createElement(ProductTour__Step.make, {
                      stepId: "step-3",
                      stepType: {
                        TAG: /* OnElementId */0,
                        _0: "issues-table-segment-control"
                      },
                      arrowOffset: 64,
                      borderRadius: 22,
                      delay: 550,
                      elementPadding: 8,
                      onTransition: (function (transitionName) {
                          if (!transitionName.startsWith("prev")) {
                            return ;
                          }
                          var match = Router.getStaticRoute(undefined).drawerItems;
                          if (match) {
                            var match$1 = match.hd;
                            if (typeof match$1 === "object" && match$1.NAME === "inspectorIssue") {
                              return ;
                            }
                            
                          }
                          return Belt_Option.forEach(Curry._2(Issues.getAtIndex, filteredIssues, 0), (function (param) {
                                        return Router.Schema.pushDrawerItem(undefined, undefined, {
                                                    NAME: "inspectorIssue",
                                                    VAL: [
                                                      param.id,
                                                      undefined
                                                    ]
                                                  });
                                      }));
                        }),
                      onStepActive: (function (_issues) {
                          return Router.Schema.clearDrawerItems(undefined, undefined);
                        }),
                      position: "bottom",
                      transition: Mantine.Transition.subtleRightSlide,
                      transitions: [
                        {
                          key: "previous",
                          label: undefined,
                          icon: "arrowLeft"
                        },
                        {
                          key: "complete",
                          label: "Finish!",
                          icon: undefined
                        }
                      ],
                      transitionDuration: 400,
                      withOutline: false,
                      children: React.createElement(ProductTour__GenericStep.make, {
                            withExit: false
                          })
                    })
            });
}

var ActionableIssueDetails = {
  tourId: tourId,
  make: InspectorIssuesOnboardingFlow$ActionableIssueDetails
};

function getFilterDropdownId(param) {
  return Belt_Option.flatMap(Belt_Option.flatMap($$Window.$$Document.getElementById("add-filter-for-issues"), (function (el) {
                    return Caml_option.nullable_to_opt(el.parentNode);
                  })), (function (el) {
                return Belt_Option.map(Caml_option.nullable_to_opt(el.id), (function (id) {
                              return id.replace("target", "dropdown");
                            }));
              }));
}

var engagementId$1 = "set-up-alerts";

function InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step1(Props) {
  var engagementIdOpt = Props.engagementId;
  var blockIdOpt = Props.blockId;
  var inspectorStats = Props.inspectorStats;
  var filterElements = Props.filterElements;
  var engagementId$2 = engagementIdOpt !== undefined ? engagementIdOpt : engagementId$1;
  var blockId = blockIdOpt !== undefined ? blockIdOpt : "step-1";
  var setFilters = InspectorIssuesFilterHooks.useSetFilters(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var match = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined, undefined);
  var appVersions = match[0];
  var model = ModelStore.useModel(undefined);
  var setFiltersOrDoNothing$1 = React.useCallback((function (param) {
          return function (param) {
            return setFiltersOrDoNothing(appVersions, inspectorStats, param, model, setFilters);
          };
        }), [
        appVersions,
        inspectorStats,
        model,
        setFilters
      ]);
  var match$1 = InspectorIssuesFilterHooks.useFilters(undefined);
  var match$2 = AvoEngagement.Block.use(engagementId$2, blockId);
  var transition = match$2[1];
  return React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
              engagementId: engagementId$2,
              blockId: blockId,
              arrowOffset: 16,
              borderRadius: 4,
              elementPadding: 4,
              onStepActive: (function (issues) {
                  if (Curry._1(Issues.size, issues) === 0 && engagementId$2 === "set-up-alerts") {
                    return Curry._1(transition, "no-filters");
                  } else {
                    Curry._1(setFilters, (function (param) {
                            return [];
                          }));
                    Router.Schema.clearDrawerItems(undefined, undefined);
                    if (Caml_obj.caml_notequal(schemaRoute, {
                            NAME: "inspector",
                            VAL: "issues"
                          })) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                  NAME: "inspector",
                                  VAL: "issues"
                                });
                    } else {
                      return ;
                    }
                  }
                }),
              onTransition: (function (issues, param) {
                  return Curry._2(setFiltersOrDoNothing$1, undefined, issues);
                }),
              position: "right-end",
              positionDependencies: Belt_Array.concat([String(filterElements)], Belt_Array.map(match$1.groupedFilters, InspectorIssuesFilter.GroupedFilter.toString)),
              simulateClick: {
                NAME: "right",
                VAL: Css.px(146)
              },
              stepType: {
                TAG: /* OnElementId */0,
                _0: "issues-filters-group"
              },
              transition: Mantine.Transition.subtleUpSlide,
              transitionDuration: 400,
              transitions: (function (param) {
                  return [
                          {
                            key: "previous",
                            label: undefined,
                            icon: "arrowLeft"
                          },
                          {
                            key: "next",
                            label: undefined,
                            icon: "arrowRight"
                          }
                        ];
                }),
              children: React.createElement(AvoProductTour__GenericStep.make, {})
            });
}

var Step1 = {
  make: InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step1
};

function InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step1a(Props) {
  var engagementIdOpt = Props.engagementId;
  var blockIdOpt = Props.blockId;
  var inspectorStats = Props.inspectorStats;
  var filterElements = Props.filterElements;
  var engagementId$2 = engagementIdOpt !== undefined ? engagementIdOpt : engagementId$1;
  var blockId = blockIdOpt !== undefined ? blockIdOpt : "step-1a";
  var setFilters = InspectorIssuesFilterHooks.useSetFilters(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var match = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined, undefined);
  var appVersions = match[0];
  var model = ModelStore.useModel(undefined);
  var setFiltersOrDoNothing$1 = React.useCallback((function (param) {
          return function (param) {
            return setFiltersOrDoNothing(appVersions, inspectorStats, param, model, setFilters);
          };
        }), [
        appVersions,
        inspectorStats,
        model,
        setFilters
      ]);
  var match$1 = InspectorIssuesFilterHooks.useFilters(undefined);
  var match$2 = AvoEngagement.Block.use(engagementId$2, blockId);
  var transition = match$2[1];
  return React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
              engagementId: engagementId$2,
              blockId: blockId,
              arrowOffset: 48,
              borderRadius: 4,
              elementPadding: 4,
              onStepActive: (function (issues) {
                  if (Curry._1(Issues.size, issues) > 0 && engagementId$2 === "set-up-alerts") {
                    return Curry._1(transition, "with-filters");
                  } else {
                    Curry._1(setFilters, (function (param) {
                            return [];
                          }));
                    Router.Schema.clearDrawerItems(undefined, undefined);
                    if (Caml_obj.caml_notequal(schemaRoute, {
                            NAME: "inspector",
                            VAL: "issues"
                          })) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                  NAME: "inspector",
                                  VAL: "issues"
                                });
                    } else {
                      return ;
                    }
                  }
                }),
              onTransition: (function (issues, param) {
                  return Curry._2(setFiltersOrDoNothing$1, undefined, issues);
                }),
              position: "right-end",
              positionDependencies: Belt_Array.concat([String(filterElements)], Belt_Array.map(match$1.groupedFilters, InspectorIssuesFilter.GroupedFilter.toString)),
              simulateClick: "center",
              stepType: {
                TAG: /* OnElementId */0,
                _0: "add-filter-for-issues"
              },
              transition: Mantine.Transition.subtleUpSlide,
              transitionDuration: 400,
              transitions: (function (param) {
                  return [
                          {
                            key: "previous",
                            label: undefined,
                            icon: "arrowLeft"
                          },
                          {
                            key: "next",
                            label: undefined,
                            icon: "arrowRight"
                          }
                        ];
                }),
              children: React.createElement(AvoProductTour__GenericStep.make, {})
            });
}

var Step1a = {
  make: InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step1a
};

function InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step2(Props) {
  var engagementIdOpt = Props.engagementId;
  var blockIdOpt = Props.blockId;
  var setupAlertsElementId = Props.setupAlertsElementId;
  var inspectorStats = Props.inspectorStats;
  var engagementId$2 = engagementIdOpt !== undefined ? engagementIdOpt : engagementId$1;
  var blockId = blockIdOpt !== undefined ? blockIdOpt : "step-2";
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var setFilters = InspectorIssuesFilterHooks.useSetFilters(undefined);
  var match = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined, undefined);
  var appVersions = match[0];
  var model = ModelStore.useModel(undefined);
  var setFiltersOrDoNothing$1 = React.useCallback((function (param) {
          return function (param) {
            return setFiltersOrDoNothing(appVersions, inspectorStats, param, model, setFilters);
          };
        }), [
        appVersions,
        inspectorStats,
        model,
        setFilters
      ]);
  var match$1 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var setStatus = match$1[1];
  var status = match$1[0];
  var globalState = GlobalStateContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var tmp = {
    engagementId: engagementId$2,
    blockId: blockId,
    arrowOffset: 60,
    borderRadius: setupAlertsElementId === "setup-alerts-modal" ? 12 : 4,
    elementPadding: setupAlertsElementId === "set-up-alerts" ? 4 : 16,
    onStepActive: (function (issues) {
        Curry._2(setFiltersOrDoNothing$1, undefined, issues);
        if (status !== /* Unresolved */0) {
          Curry._1(setStatus, /* Unresolved */0);
        }
        Router.Schema.clearDrawerItems(undefined, undefined);
        if (Caml_obj.caml_notequal(schemaRoute, {
                NAME: "inspector",
                VAL: "issues"
              })) {
          return Router.Schema.pushSchemaRoute(undefined, undefined, {
                      NAME: "inspector",
                      VAL: "issues"
                    });
        }
        
      }),
    onTransition: (function (param, param$1) {
        return Belt_Option.forEachU(Belt_List.head(globalState.modals), (function (param) {
                      return Curry._1(globalSend, {
                                  TAG: /* CloseModal */5,
                                  _0: param.id
                                });
                    }));
      }),
    position: setupAlertsElementId === "set-up-alerts" ? "left-start" : "left",
    positionDependencies: [setupAlertsElementId],
    stepType: {
      TAG: /* OnElementId */0,
      _0: setupAlertsElementId
    },
    transition: Mantine.Transition.subtleUpSlide,
    transitions: (function (issues) {
        return [
                {
                  key: Curry._1(Issues.size, issues) > 0 ? "previous" : "previous-no-issues",
                  label: undefined,
                  icon: "arrowLeft"
                },
                {
                  key: "next",
                  label: undefined,
                  icon: "arrowRight"
                }
              ];
      }),
    withOutline: setupAlertsElementId === "set-up-alerts",
    children: React.createElement(AvoProductTour__GenericStep.make, {}),
    key: setupAlertsElementId
  };
  var tmp$1 = setupAlertsElementId === "setup-alerts-modal" ? 200 : undefined;
  if (tmp$1 !== undefined) {
    tmp.delay = tmp$1;
  }
  var tmp$2 = setupAlertsElementId === "set-up-alerts" ? "center" : undefined;
  if (tmp$2 !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = setupAlertsElementId === "setup-alerts-modal" ? 0 : 400;
  if (tmp$3 !== undefined) {
    tmp.transitionDuration = tmp$3;
  }
  return React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, tmp);
}

var Step2 = {
  make: InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step2
};

function InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step3(Props) {
  var engagementIdOpt = Props.engagementId;
  var blockIdOpt = Props.blockId;
  var inspectorStats = Props.inspectorStats;
  var completeTransitionOpt = Props.completeTransition;
  var engagementId$2 = engagementIdOpt !== undefined ? engagementIdOpt : engagementId$1;
  var blockId = blockIdOpt !== undefined ? blockIdOpt : "step-3";
  var completeTransition = completeTransitionOpt !== undefined ? completeTransitionOpt : "complete";
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var setFilters = InspectorIssuesFilterHooks.useSetFilters(undefined);
  var match = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined, undefined);
  var appVersions = match[0];
  var model = ModelStore.useModel(undefined);
  var setFiltersOrDoNothing$1 = React.useCallback((function (param) {
          return function (param) {
            return setFiltersOrDoNothing(appVersions, inspectorStats, param, model, setFilters);
          };
        }), [
        appVersions,
        inspectorStats,
        model,
        setFilters
      ]);
  var match$1 = React.useState(function () {
        return "save-view-for-issues";
      });
  var setSaveViewElementId = match$1[1];
  var saveViewElementId = match$1[0];
  var tmp = {
    engagementId: engagementId$2,
    blockId: blockId,
    arrowOffset: 60,
    borderRadius: 4,
    elementPadding: 8,
    onClick: (function (transition, e) {
        if (e.target.closest("#save-filtered-view-confirm-button") !== null) {
          return Curry._1(transition, "complete");
        } else {
          setTimeout((function (param) {
                  var el = Belt_Option.flatMapU($$Window.$$Document.getElementById("save-filtered-view-dropdown"), (function (el) {
                          return Caml_option.nullable_to_opt(el.closest(".mantine-Menu-dropdown"));
                        }));
                  if (el === undefined) {
                    return Curry._1(setSaveViewElementId, (function (param) {
                                  return "save-view-for-issues";
                                }));
                  }
                  var el$1 = Caml_option.valFromOption(el);
                  return Curry._1(setSaveViewElementId, (function (param) {
                                return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(el$1.id), "save-view-for-issues");
                              }));
                }), 200);
          return ;
        }
      }),
    onOutsideClick: (function (param) {
        setTimeout((function (param) {
                var el = Belt_Option.flatMapU($$Window.$$Document.getElementById("save-filtered-view-dropdown"), (function (el) {
                        return Caml_option.nullable_to_opt(el.closest(".mantine-Menu-dropdown"));
                      }));
                if (el === undefined) {
                  return Curry._1(setSaveViewElementId, (function (param) {
                                return "save-view-for-issues";
                              }));
                }
                var el$1 = Caml_option.valFromOption(el);
                return Curry._1(setSaveViewElementId, (function (param) {
                              return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(el$1.id), "save-view-for-issues");
                            }));
              }), 200);
        
      }),
    onStepActive: (function (issues) {
        Curry._2(setFiltersOrDoNothing$1, undefined, issues);
        Router.Schema.clearDrawerItems(undefined, undefined);
        if (Caml_obj.caml_notequal(schemaRoute, {
                NAME: "inspector",
                VAL: "issues"
              })) {
          Router.Schema.pushSchemaRoute(undefined, undefined, {
                NAME: "inspector",
                VAL: "issues"
              });
        }
        return Curry._2(setFiltersOrDoNothing$1, undefined, issues);
      }),
    onTransition: (function (_issues, transitionName) {
        if (transitionName === "previous-no-issues") {
          return Curry._1(setFilters, (function (param) {
                        return [];
                      }));
        }
        
      }),
    position: saveViewElementId === "save-view-for-issues" ? "left-start" : "left",
    positionDependencies: [saveViewElementId],
    stepType: {
      TAG: /* OnElementIds */1,
      _0: [
        "save-view-for-issues",
        "save-filtered-view-dropdown"
      ]
    },
    transition: Mantine.Transition.subtleUpSlide,
    transitionDuration: 0,
    transitions: (function (_issues) {
        return [
                {
                  key: "previous",
                  label: undefined,
                  icon: "arrowLeft"
                },
                {
                  key: completeTransition,
                  label: "Finish!",
                  icon: undefined
                }
              ];
      }),
    withOutline: false,
    children: React.createElement(AvoProductTour__GenericStep.make, {})
  };
  var tmp$1 = saveViewElementId === "save-view-for-issues" ? "center" : undefined;
  if (tmp$1 !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, tmp);
}

var Step3 = {
  make: InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step3
};

function useSetupAlertsElements(engagementIdOpt, blockIds) {
  var engagementId$2 = engagementIdOpt !== undefined ? engagementIdOpt : engagementId$1;
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var globalState = GlobalStateContext.use(undefined);
  var setupAlertsElementId = React.useMemo((function () {
          var match = Belt_List.head(globalState.modals);
          if (match === undefined) {
            return "set-up-alerts";
          }
          var match$1 = match.modal;
          if (typeof match$1 === "object" && match$1.NAME === "IssueAlertsSetup") {
            return "setup-alerts-modal";
          } else {
            return "set-up-alerts";
          }
        }), [globalState.modals]);
  var match = React.useState(function () {
        return 0;
      });
  var setFilterElements = match[1];
  var match$1 = AvoEngagement.Block.use(engagementId$2, blockIds[0]);
  var filtersBlock = match$1[0];
  var match$2 = AvoEngagement.Block.use(engagementId$2, blockIds[1]);
  var filtersBlockA = match$2[0];
  React.useEffect((function () {
          var filterObserver = new MutationObserver((function (mutations) {
                  return Belt_Array.forEach(mutations, (function (mutation) {
                                if (mutation.type !== "childList") {
                                  return ;
                                }
                                var el = $$Window.$$Document.getElementById("issues-filters-group");
                                if (el === undefined) {
                                  return ;
                                }
                                var el$1 = Caml_option.valFromOption(el);
                                return Curry._1(setFilterElements, (function (param) {
                                              return Belt_Array.reduce(el$1.children, 0, (function (acc, el) {
                                                            return acc + el.clientWidth | 0;
                                                          }));
                                            }));
                              }));
                }));
          var el = $$Window.$$Document.getElementById("issues-filters-group");
          if (el !== undefined && (filtersBlock.state.active || filtersBlockA.state.active)) {
            filterObserver.observe(Caml_option.valFromOption(el), {
                  childList: true
                });
          }
          return (function (param) {
                    filterObserver.disconnect();
                    
                  });
        }), [schemaRoute]);
  var filterDropdownId = getFilterDropdownId(undefined);
  React.useEffect((function () {
          if (filterDropdownId !== undefined && (filtersBlock.state.active || filtersBlockA.state.active)) {
            Curry._2(Css.$$global, "#" + filterDropdownId, {
                  hd: Css.important(Css.zIndex(Styles.ZIndex.aboveAll + 100 | 0)),
                  tl: /* [] */0
                });
          }
          
        }), [
        filterDropdownId,
        filtersBlock.state.active,
        filtersBlockA.state.active
      ]);
  return [
          match[0],
          setupAlertsElementId
        ];
}

function InspectorIssuesOnboardingFlow$SetUpAlertsFlow(Props) {
  var completeTransition = Props.completeTransition;
  var inspectorStats = Props.inspectorStats;
  var confettiOnCompleteOpt = Props.confettiOnComplete;
  var confettiOnComplete = confettiOnCompleteOpt !== undefined ? confettiOnCompleteOpt : true;
  var match = useSetupAlertsElements(undefined, [
        "step-1",
        "step-1a"
      ]);
  var filterElements = match[0];
  var tmp = {
    inspectorStats: inspectorStats
  };
  if (completeTransition !== undefined) {
    tmp.completeTransition = Caml_option.valFromOption(completeTransition);
  }
  return React.createElement(AvoProductTour__Root.make, {
              confettiOnComplete: confettiOnComplete,
              engagementId: engagementId$1,
              showStepCount: true,
              children: null
            }, React.createElement(InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step1, {
                  inspectorStats: inspectorStats,
                  filterElements: filterElements
                }), React.createElement(InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step1a, {
                  inspectorStats: inspectorStats,
                  filterElements: filterElements
                }), React.createElement(InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step2, {
                  setupAlertsElementId: match[1],
                  inspectorStats: inspectorStats
                }), React.createElement(InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step3, tmp));
}

var SetUpAlertsFlow = {
  engagementId: engagementId$1,
  Step1: Step1,
  Step1a: Step1a,
  Step2: Step2,
  Step3: Step3,
  useSetupAlertsElements: useSetupAlertsElements,
  make: InspectorIssuesOnboardingFlow$SetUpAlertsFlow
};

function InspectorIssuesOnboardingFlow$Step1(Props) {
  return React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
              engagementId: engagementId,
              blockId: "step-1",
              borderRadius: 4,
              elementClickable: false,
              elementPadding: 8,
              offset: -80,
              onStepActive: (function (param) {
                  return Router.Schema.clearDrawerItems(undefined, undefined);
                }),
              position: "left",
              stepType: {
                TAG: /* Window */2,
                _0: {
                  left: Css.px(258),
                  top: Css.px(72),
                  width: {
                    NAME: "subtract",
                    VAL: [
                      Css.vw(100.0),
                      Css.px(266)
                    ]
                  },
                  height: {
                    NAME: "subtract",
                    VAL: [
                      Css.vh(100.0),
                      Css.px(80)
                    ]
                  }
                }
              },
              transition: Mantine.Transition.subtleUpSlide,
              transitionDuration: 400,
              transitions: (function (issues) {
                  return [{
                            key: Curry._1(Issues.size, issues) === 0 ? "next-no-issues" : "next-with-issues",
                            label: undefined,
                            icon: "arrowRight"
                          }];
                }),
              children: React.createElement(AvoProductTour__GenericStep.make, {})
            });
}

var Step1$1 = {
  make: InspectorIssuesOnboardingFlow$Step1
};

function InspectorIssuesOnboardingFlow$Step2(Props) {
  var setFilters = InspectorIssuesFilterHooks.useSetFilters(undefined);
  return React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
              engagementId: engagementId,
              blockId: "step-2",
              borderRadius: 4,
              elementPadding: 4,
              onClick: (function (transition, _e) {
                  return Curry._1(transition, "next");
                }),
              onStepActive: (function (issues) {
                  Curry._1(setFilters, (function (param) {
                          return [];
                        }));
                  return Router.Schema.clearDrawerItems(undefined, undefined);
                }),
              onTransition: (function (_issues, transitionName) {
                  if (transitionName === "previous") {
                    return Curry._1(setFilters, (function (param) {
                                  return [];
                                }));
                  }
                  
                }),
              simulateClick: {
                NAME: "left",
                VAL: Css.px(68)
              },
              stepType: {
                TAG: /* OnElementId */0,
                _0: "inspector-issues-onboarding-flow-v2.step-2"
              },
              transition: Mantine.Transition.subtleUpSlide,
              transitionDuration: 400,
              transitions: (function (_issues) {
                  return [
                          {
                            key: "previous",
                            label: undefined,
                            icon: "arrowLeft"
                          },
                          {
                            key: "next",
                            label: undefined,
                            icon: "arrowRight"
                          }
                        ];
                }),
              children: React.createElement(AvoProductTour__GenericStep.make, {})
            });
}

var Step2$1 = {
  make: InspectorIssuesOnboardingFlow$Step2
};

function InspectorIssuesOnboardingFlow$Step3(Props) {
  return React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
              engagementId: engagementId,
              blockId: "step-3",
              delay: 550,
              elementClickable: false,
              onStepActive: (function (issues) {
                  var match = Curry._2(Issues.getAtIndex, issues, 0);
                  if (match !== undefined) {
                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                NAME: "inspectorIssue",
                                VAL: [
                                  match.id,
                                  undefined
                                ]
                              });
                  }
                  
                }),
              position: "left",
              stepType: {
                TAG: /* OnElementId */0,
                _0: "drawer-container"
              },
              transition: Mantine.Transition.subtleRightSlide,
              transitionDuration: 400,
              transitions: (function (_issues) {
                  return [
                          {
                            key: "previous",
                            label: undefined,
                            icon: "arrowLeft"
                          },
                          {
                            key: "next",
                            label: undefined,
                            icon: "arrowRight"
                          }
                        ];
                }),
              withOutline: false,
              children: React.createElement(AvoProductTour__GenericStep.make, {})
            });
}

var Step3$1 = {
  make: InspectorIssuesOnboardingFlow$Step3
};

function InspectorIssuesOnboardingFlow$Step4(Props) {
  return React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
              engagementId: engagementId,
              blockId: "step-4",
              arrowOffset: 64,
              borderRadius: 8,
              delay: 150,
              elementClickable: false,
              elementPadding: 16,
              onStepActive: (function (issues) {
                  var match = Curry._2(Issues.getAtIndex, issues, 0);
                  if (match !== undefined) {
                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                NAME: "inspectorIssue",
                                VAL: [
                                  match.id,
                                  undefined
                                ]
                              });
                  }
                  
                }),
              position: "left-start",
              stepType: {
                TAG: /* OnElementId */0,
                _0: "issue-status-id-for-flows"
              },
              transition: Mantine.Transition.subtleRightSlide,
              transitionDuration: 400,
              transitions: (function (_issues) {
                  return [
                          {
                            key: "previous",
                            label: undefined,
                            icon: "arrowLeft"
                          },
                          {
                            key: "next",
                            label: undefined,
                            icon: "arrowRight"
                          }
                        ];
                }),
              withOutline: false,
              children: React.createElement(AvoProductTour__GenericStep.make, {})
            });
}

var Step4 = {
  make: InspectorIssuesOnboardingFlow$Step4
};

function InspectorIssuesOnboardingFlow$Step5(Props) {
  var match = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var setStatus = match[1];
  var status = match[0];
  return React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
              engagementId: engagementId,
              blockId: "step-5",
              arrowOffset: 64,
              borderRadius: 22,
              delay: 550,
              elementPadding: 8,
              onStepActive: (function (_issues) {
                  return Router.Schema.clearDrawerItems(undefined, undefined);
                }),
              onTransition: (function (param, param$1) {
                  if (status !== /* Unresolved */0) {
                    return Curry._1(setStatus, /* Unresolved */0);
                  }
                  
                }),
              position: "bottom",
              stepType: {
                TAG: /* OnElementId */0,
                _0: "issues-table-segment-control"
              },
              transition: Mantine.Transition.subtleRightSlide,
              transitionDuration: 400,
              transitions: (function (issues) {
                  return [
                          {
                            key: "previous",
                            label: undefined,
                            icon: "arrowLeft"
                          },
                          {
                            key: Curry._1(Issues.size, issues) > 0 ? "next" : "next-no-issues",
                            label: undefined,
                            icon: "arrowRight"
                          }
                        ];
                }),
              withOutline: false,
              children: React.createElement(AvoProductTour__GenericStep.make, {})
            });
}

var Step5 = {
  make: InspectorIssuesOnboardingFlow$Step5
};

function InspectorIssuesOnboardingFlow(Props) {
  var inspectorStats = Props.inspectorStats;
  var match = AvoEngagement.Engagement.use(engagementId);
  var flow = match[0];
  var match$1 = useSetupAlertsElements(engagementId, [
        "step-6",
        "step-6a"
      ]);
  var filterElements = match$1[0];
  React.useEffect(function () {
        var el = $$Window.$$Document.getElementById("issues-table-not-empty");
        if (el !== undefined) {
          Belt_Option.forEach(Curry._2($$Window.Dom.$$Element.querySelector, Caml_option.valFromOption(el), "[role=\"button\"]"), (function (el) {
                  el.setAttribute("id", "inspector-issues-onboarding-flow-v2.step-2");
                  
                }));
        }
        
      });
  React.useEffect((function () {
          if (flow.state.active) {
            setWasInspectorOnboardingFlowActiveInSession(true);
          }
          
        }), [flow.state]);
  return React.createElement(React.Fragment, undefined, React.createElement(AvoProductTour__Root.make, {
                  confettiOnComplete: true,
                  engagementId: engagementId,
                  showStepCount: true,
                  totalStepsOverride: 8,
                  children: null
                }, React.createElement(InspectorIssuesOnboardingFlow$Step1, {}), React.createElement(InspectorIssuesOnboardingFlow$Step2, {}), React.createElement(InspectorIssuesOnboardingFlow$Step3, {}), React.createElement(InspectorIssuesOnboardingFlow$Step4, {}), React.createElement(InspectorIssuesOnboardingFlow$Step5, {}), React.createElement(InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step1, {
                      engagementId: engagementId,
                      blockId: "step-6",
                      inspectorStats: inspectorStats,
                      filterElements: filterElements
                    }), React.createElement(InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step1a, {
                      engagementId: engagementId,
                      blockId: "step-6a",
                      inspectorStats: inspectorStats,
                      filterElements: filterElements
                    }), React.createElement(InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step2, {
                      engagementId: engagementId,
                      blockId: "step-7",
                      setupAlertsElementId: match$1[1],
                      inspectorStats: inspectorStats
                    }), React.createElement(InspectorIssuesOnboardingFlow$SetUpAlertsFlow$Step3, {
                      engagementId: engagementId,
                      blockId: "step-8",
                      inspectorStats: inspectorStats
                    })), React.createElement(InspectorIssuesOnboardingFlow$SetUpAlertsFlow, {
                  inspectorStats: inspectorStats
                }));
}

var Step6;

var Step6a;

var Step7;

var Step8;

var make = InspectorIssuesOnboardingFlow;

export {
  setFiltersOrDoNothing ,
  IssuesWrapper ,
  ConditionalProductStep ,
  engagementId ,
  SavedViews ,
  SessionStorage ,
  ActionableIssueDetails ,
  getFilterDropdownId ,
  SetUpAlertsFlow ,
  Step1$1 as Step1,
  Step2$1 as Step2,
  Step3$1 as Step3,
  Step4 ,
  Step5 ,
  Step6 ,
  Step6a ,
  Step7 ,
  Step8 ,
  make ,
  
}
/* Css Not a pure module */
