// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BranchBar from "./BranchBar.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as SandboxBanner from "./SandboxBanner.mjs";

function getTopOffset(workspace, branchBarVisible, schemaRoute) {
  return SandboxBanner.getHeight(workspace.isSandbox) + (
          branchBarVisible ? BranchBar.height + (
              typeof schemaRoute === "object" && schemaRoute.NAME === "diff" ? 14 : 0
            ) | 0 : 0
        ) | 0;
}

function getTopOffsetAndHeaderHeight(workspace, branchBarVisible, schemaRoute) {
  return getTopOffset(workspace, branchBarVisible, schemaRoute) + DrawerHeader.drawerHeaderHeight | 0;
}

export {
  getTopOffset ,
  getTopOffsetAndHeaderHeight ,
  
}
/* BranchBar Not a pure module */
