// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconError(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.orange;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: /* [] */0
                  }),
              width: String(size),
              fill: "none",
              viewBox: "0 0 12 11",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M11.9028 9.83384L6.72148 0.419769C6.41391 -0.139923 5.58608 -0.139923 5.27769 0.419769L0.0972177 9.83384C-0.193177 10.3618 0.201923 11 0.819523 11H11.1813C11.7981 11 12.1932 10.3618 11.9028 9.83384ZM6 9.41447C5.54845 9.41447 5.18198 9.05931 5.18198 8.62171C5.18198 8.1841 5.54845 7.82894 6 7.82894C6.45154 7.82894 6.81801 8.1841 6.81801 8.62171C6.81801 9.05931 6.45154 9.41447 6 9.41447ZM6.81801 7.03618H5.18198V3.86512H6.81801V7.03618Z"
                }));
}

var make = IconError;

export {
  make ,
  
}
/* Css Not a pure module */
