// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as LoadingCircle from "../LoadingCircle.mjs";
import * as InspectorStats from "./InspectorStats.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as InspectorEmptyState from "./InspectorEmptyState.mjs";
import * as InspectorViewHeader from "./InspectorViewHeader.mjs";
import * as InspectorViewWithStats__V2 from "./InspectorViewWithStats__V2.mjs";

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.minHeight({
                  NAME: "subtract",
                  VAL: [
                    Css.vh(100.0),
                    Css.px(InspectorViewHeader.headerHeight)
                  ]
                }),
            tl: {
              hd: Css.marginTop(Css.px(InspectorViewHeader.headerHeight)),
              tl: {
                hd: Css.padding(Css.px(40)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var loading = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: /* [] */0
        }
      }
    });

var Style = {
  content: content,
  loading: loading
};

function InspectorEventsView(Props) {
  var model = Props.model;
  var stats = InspectorStats.useStatsWithCache(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var hasSourcesWithoutInspector = Belt_List.some(model.sources, (function (source) {
          var match = InspectorStats.getSourceStatus(source, stats);
          if (typeof match === "number") {
            return match !== 0;
          } else {
            return false;
          }
        }));
  var hasSourcesWithInspector = Belt_List.some(model.sources, (function (source) {
          var match = InspectorStats.getSourceStatus(source, stats);
          if (typeof match === "number") {
            return match === 0;
          } else {
            return true;
          }
        }));
  if (typeof stats === "number") {
    if (stats >= 2) {
      return React.createElement("div", {
                  className: content
                }, React.createElement(InspectorViewHeader.Disabled.make, {
                      hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                      inspectorView: "events",
                      hasSourcesWithInspector: hasSourcesWithInspector
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light10,
                      children: "Something went wrong"
                    }));
    } else {
      return React.createElement("div", {
                  className: content
                }, React.createElement(InspectorViewHeader.Disabled.make, {
                      hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                      inspectorView: "events",
                      hasSourcesWithInspector: hasSourcesWithInspector
                    }), React.createElement("div", {
                      className: loading
                    }, React.createElement(LoadingCircle.make, {
                          color: Styles.Color.light07
                        })));
    }
  }
  var stats$1 = stats._0;
  if (Caml_obj.caml_notequal(stats$1, []) || workspace.isSandbox) {
    return React.createElement(InspectorViewWithStats__V2.make, {
                hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                hasSourcesWithInspector: hasSourcesWithInspector,
                stats: stats$1
              });
  } else {
    return React.createElement(InspectorEmptyState.make, {
                hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                hasSourcesWithInspector: hasSourcesWithInspector,
                inspectorView: "events"
              });
  }
}

var make = InspectorEventsView;

export {
  Style ,
  make ,
  
}
/* content Not a pure module */
