// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as ArrayExt from "../ArrayExt.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as InspectorIssueType from "../../../shared/models/InspectorIssueType.mjs";
import * as InspectorViewConfig from "./InspectorViewConfig.mjs";
import * as InspectorEventViewModelUtils from "./InspectorEventViewModelUtils.mjs";

function getSourceIdByColumnIndex(sources, columnIndex) {
  var sourceIndex = (columnIndex - InspectorViewConfig.fixedColumns | 0) / 2 | 0;
  return Belt_Array.getExn(sources, sourceIndex);
}

function getSourceIdByColumnIndex_(sources, columnIndex) {
  var sourceIndex = (columnIndex - InspectorViewConfig.fixedColumns | 0) / 2 | 0;
  return Belt_Array.getExn(sources, sourceIndex);
}

function getEventSourceBySourceId(sources, sourceId) {
  return Belt_Array.getByU(sources, (function (source) {
                return source.sourceId === sourceId;
              }));
}

function getEventSourceBySourceId_(sources, sourceId) {
  return Belt_Array.getByU(sources, (function (id) {
                return id === sourceId;
              }));
}

function getEventSourceByColumnIndex(sourceIds, sources, columnIndex) {
  var sourceId = getSourceIdByColumnIndex(sourceIds, columnIndex);
  return getEventSourceBySourceId(sources, sourceId);
}

function getEventSourceByColumnIndex_(sourceIds, sources, columnIndex) {
  var sourceId = getSourceIdByColumnIndex_(sourceIds, columnIndex);
  return getEventSourceBySourceId_(sources, sourceId);
}

function getPropertySourceByColumnIndex(sourceIds, sources, columnIndex) {
  var sourceId = getSourceIdByColumnIndex(sourceIds, columnIndex);
  return Belt_Array.getByU(sources, (function (source) {
                return source.sourceId === sourceId;
              }));
}

function getPropertySourceByColumnIndex_(sourceIds, sources, columnIndex) {
  var sourceId = getSourceIdByColumnIndex_(sourceIds, columnIndex);
  return Belt_Array.getByU(sources, (function (id) {
                return Caml_obj.caml_equal(id, sourceId);
              }));
}

function getCell(list, sources, row, column) {
  var row$1 = Belt_Array.get(list, row);
  if (row$1 === undefined) {
    return "NotFound";
  }
  if (typeof row$1 !== "object") {
    return "Loading";
  }
  var variant = row$1.NAME;
  if (variant === "Error") {
    var match = row$1.VAL;
    return {
            NAME: "Error",
            VAL: [
              match[0],
              match[1],
              match[2]
            ]
          };
  }
  if (variant === "Event") {
    var $$event = row$1.VAL;
    switch (column) {
      case 0 :
          return {
                  NAME: "EventName",
                  VAL: $$event
                };
      case 1 :
          return {
                  NAME: "EventVolume",
                  VAL: $$event
                };
      case 2 :
          return {
                  NAME: "EventIssues",
                  VAL: $$event
                };
      default:
        if (column % 2 === 0) {
          var source = getEventSourceByColumnIndex(sources, $$event.sources, column);
          return {
                  NAME: "EventSourceIssues",
                  VAL: [
                    $$event,
                    source
                  ]
                };
        }
        var source$1 = getEventSourceByColumnIndex(sources, $$event.sources, column);
        return {
                NAME: "EventSourceVolume",
                VAL: [
                  $$event,
                  source$1
                ]
              };
    }
  } else {
    var match$1 = row$1.VAL;
    var property = match$1[1];
    var $$event$1 = match$1[0];
    switch (column) {
      case 0 :
          return {
                  NAME: "PropertyName",
                  VAL: [
                    $$event$1,
                    property
                  ]
                };
      case 1 :
          return {
                  NAME: "PropertyVolume",
                  VAL: [
                    $$event$1,
                    property
                  ]
                };
      case 2 :
          return {
                  NAME: "PropertyIssues",
                  VAL: [
                    $$event$1,
                    property
                  ]
                };
      default:
        if (column % 2 === 0) {
          var source$2 = getPropertySourceByColumnIndex(sources, property.sources, column);
          return {
                  NAME: "PropertySourceIssues",
                  VAL: [
                    $$event$1,
                    property,
                    source$2
                  ]
                };
        }
        var source$3 = getPropertySourceByColumnIndex(sources, property.sources, column);
        return {
                NAME: "PropertySourceVolume",
                VAL: [
                  $$event$1,
                  property,
                  source$3
                ]
              };
    }
  }
}

function getCell_(list, sources, row, column) {
  var row$1 = Belt_Array.get(list, row);
  if (row$1 === undefined) {
    return "NotFound";
  }
  if (typeof row$1 !== "object") {
    return "Loading";
  }
  var variant = row$1.NAME;
  if (variant === "error") {
    var match = row$1.VAL;
    return {
            NAME: "Error",
            VAL: [
              match[0],
              match[1],
              match[2]
            ]
          };
  }
  if (variant === "event") {
    var $$event = row$1.VAL;
    switch (column) {
      case 0 :
          return {
                  NAME: "EventName",
                  VAL: $$event
                };
      case 1 :
          return {
                  NAME: "EventVolume",
                  VAL: $$event
                };
      case 2 :
          return {
                  NAME: "EventIssues",
                  VAL: $$event
                };
      default:
        if (column % 2 === 0) {
          var sourceId = getEventSourceByColumnIndex_(sources, InspectorEventViewModelUtils.$$Event.getSourceIds($$event), column);
          return {
                  NAME: "EventSourceIssues",
                  VAL: [
                    $$event,
                    sourceId
                  ]
                };
        }
        var sourceId$1 = getEventSourceByColumnIndex_(sources, InspectorEventViewModelUtils.$$Event.getSourceIds($$event), column);
        return {
                NAME: "EventSourceVolume",
                VAL: [
                  $$event,
                  sourceId$1
                ]
              };
    }
  } else if (variant === "property") {
    var match$1 = row$1.VAL;
    var property = match$1[1];
    var $$event$1 = match$1[0];
    switch (column) {
      case 0 :
          return {
                  NAME: "PropertyName",
                  VAL: [
                    $$event$1,
                    property
                  ]
                };
      case 1 :
          return {
                  NAME: "PropertyVolume",
                  VAL: [
                    $$event$1,
                    property
                  ]
                };
      case 2 :
          return {
                  NAME: "PropertyIssues",
                  VAL: [
                    $$event$1,
                    property
                  ]
                };
      default:
        if (column % 2 === 0) {
          var source = getPropertySourceByColumnIndex_(sources, InspectorEventViewModelUtils.$$Event.getSourceIds($$event$1), column);
          return {
                  NAME: "PropertySourceIssues",
                  VAL: [
                    $$event$1,
                    property,
                    source
                  ]
                };
        }
        var source$1 = getPropertySourceByColumnIndex_(sources, InspectorEventViewModelUtils.$$Event.getSourceIds($$event$1), column);
        return {
                NAME: "PropertySourceVolume",
                VAL: [
                  $$event$1,
                  property,
                  source$1
                ]
              };
    }
  } else {
    var match$2 = row$1.VAL;
    var property$1 = match$2[1];
    var $$event$2 = match$2[0];
    switch (column) {
      case 0 :
          return {
                  NAME: "PropertyName",
                  VAL: [
                    $$event$2,
                    property$1
                  ]
                };
      case 1 :
          return {
                  NAME: "PropertyVolume",
                  VAL: [
                    $$event$2,
                    property$1
                  ]
                };
      case 2 :
          return {
                  NAME: "PropertyIssues",
                  VAL: [
                    $$event$2,
                    property$1
                  ]
                };
      default:
        if (column % 2 === 0) {
          var source$2 = getPropertySourceByColumnIndex_(sources, InspectorEventViewModelUtils.$$Event.getSourceIds($$event$2), column);
          return {
                  NAME: "PropertySourceIssues",
                  VAL: [
                    $$event$2,
                    property$1,
                    source$2
                  ]
                };
        }
        var source$3 = getPropertySourceByColumnIndex_(sources, InspectorEventViewModelUtils.$$Event.getSourceIds($$event$2), column);
        return {
                NAME: "PropertySourceVolume",
                VAL: [
                  $$event$2,
                  property$1,
                  source$3
                ]
              };
    }
  }
}

function countPropertyIssuesTypes(sources) {
  return Belt_SetString.size(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(sources, (function (source) {
                                return source.issues;
                              }))), (function (issue) {
                        var variant = issue.NAME;
                        if (variant === "MissingExpectedProperty") {
                          return "MissingExpectedProperty";
                        } else if (variant === "InconsistentType") {
                          return "InconsistentType";
                        } else if (variant === "SometimesMissing") {
                          return "SometimesMissing";
                        } else if (variant === "UnexpectedProperty") {
                          return "UnexpectedProperty";
                        } else if (variant === "PropertyTypeInconsistentWithTrackingPlan") {
                          return "PropertyTypeInconsistentWithTrackingPlan";
                        } else {
                          return "PropertyNotInTrackingPlan";
                        }
                      }))));
}

function filterPropertySourcesBySourceIds(propertySources, sourceIds) {
  return Belt_Array.keepU(propertySources, (function (propertySource) {
                return Belt_Array.someU(sourceIds, (function (sourceId) {
                              return sourceId === propertySource.sourceId;
                            }));
              }));
}

function filterPropertySourcesBySourceIds_(issues, sourceIds) {
  return Belt_MapString.keepU(issues, (function (id, param) {
                return Belt_Array.someU(sourceIds, (function (sourceId) {
                              return sourceId === id;
                            }));
              }));
}

function countEventIssueTypes(sources, $$event) {
  var eventSourceIds = Belt_Option.mapU(sources, (function (sources) {
          return Belt_Array.mapU(sources, (function (source) {
                        return source.sourceId;
                      }));
        }));
  return Belt_Array.reduce(Belt_Array.map(Belt_Array.mapU($$event.properties, (function (property) {
                        var partial_arg = property.sources;
                        return Belt_Option.mapWithDefault(eventSourceIds, property.sources, (function (param) {
                                      return filterPropertySourcesBySourceIds(partial_arg, param);
                                    }));
                      })), countPropertyIssuesTypes), 0, (function (sum, acc) {
                return sum + acc | 0;
              }));
}

function getEventIssues(sources, $$event) {
  var eventSourceIds = Belt_Option.mapU(sources, (function (sources) {
          return Belt_Array.mapU(sources, (function (source) {
                        return source.sourceId;
                      }));
        }));
  return Belt_Array.concatMany(Belt_Array.mapU($$event.properties, (function (property) {
                    var partial_arg = property.sources;
                    return Belt_Array.concatMany(Belt_Array.mapU(Belt_Option.mapWithDefault(eventSourceIds, property.sources, (function (param) {
                                          return filterPropertySourcesBySourceIds(partial_arg, param);
                                        })), (function (source) {
                                      return Belt_Array.mapU(source.issues, (function (issue) {
                                                    return [
                                                            source.sourceId,
                                                            issue
                                                          ];
                                                  }));
                                    })));
                  })));
}

function getEventRelatedIssues(sourceIds, $$event) {
  var sourceIdsToKeep = Belt_SetString.fromArray(Belt_Option.getWithDefault(sourceIds, Object.keys($$event.eventShape.presenceBySource)));
  return Belt_Array.keepU(Belt_Array.concatMany(Belt_Array.mapU($$event.properties, (function (property) {
                        return Belt_Array.concatMany(Belt_MapString.valuesToArray(Belt_MapString.mapWithKeyU(property.issues, (function (sourceId, param) {
                                              return Belt_Array.mapU(param.issues, (function (issue) {
                                                            return [
                                                                    sourceId,
                                                                    property,
                                                                    issue
                                                                  ];
                                                          }));
                                            }))));
                      }))), (function (param) {
                return Belt_SetString.has(sourceIdsToKeep, param[0]);
              }));
}

function getPropertyIssues(sourceIds, property) {
  var sourceIdsToKeep = Belt_SetString.fromArray(Belt_Option.getWithDefault(sourceIds, Object.keys(property.propertyShape.presence.presenceBySource)));
  return Belt_Array.concatMany(Belt_Array.keepMapU(Belt_MapString.toArray(property.issues), (function (param) {
                    var sourceId = param[0];
                    if (Belt_SetString.has(sourceIdsToKeep, sourceId)) {
                      return Belt_Array.mapU(param[1].issues, (function (issue) {
                                    return [
                                            sourceId,
                                            property,
                                            issue
                                          ];
                                  }));
                    }
                    
                  })));
}

function countPropertyVolume(periodOpt, sources) {
  var period = periodOpt !== undefined ? periodOpt : "current";
  return Belt_Array.reduceU(sources, 0, (function (sum, source) {
                return Belt_Array.reduceU(source.propertyTypes, sum, (function (sum, propertyType) {
                              if (period === "history") {
                                return sum + propertyType.count.history.total | 0;
                              } else if (period === "comparison") {
                                return sum + propertyType.count.comparison.total | 0;
                              } else {
                                return sum + propertyType.count.current.total | 0;
                              }
                            }));
              }));
}

function prettyPrintListTypeForNow(type_) {
  if (type_.startsWith("list({") && type_.endsWith("})")) {
    return "multiple list types";
  } else {
    return type_;
  }
}

function getPropertyTypesFromIssues(issues) {
  return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(issues, (function (issue) {
                                if (issue.NAME === "InconsistentType") {
                                  return issue.VAL.types;
                                } else {
                                  return [];
                                }
                              }))), (function (propertyType) {
                        return prettyPrintListTypeForNow(propertyType.type_);
                      }))));
}

function getPropertyPresencesFromIssues(issues) {
  return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(issues, (function (issue) {
                                if (issue.NAME === "SometimesMissing") {
                                  return issue.VAL.presences;
                                } else {
                                  return [];
                                }
                              }))), (function (presence) {
                        return presence.presence;
                      }))));
}

function getIssuePropertyName(issue) {
  var variant = issue.NAME;
  if (variant === "SometimesMissing" || variant === "InconsistentType") {
    return issue.VAL.propertyName;
  } else {
    return issue.VAL.propertyName;
  }
}

function getIssueKey(issue) {
  var variant = issue.NAME;
  if (variant === "MissingExpectedProperty") {
    return "MissingExpectedProperty" + issue.VAL.propertyName;
  } else if (variant === "InconsistentType") {
    return "InconsistentType " + issue.VAL.propertyName;
  } else if (variant === "SometimesMissing") {
    return "SometimesMissing " + issue.VAL.propertyName;
  } else if (variant === "UnexpectedProperty") {
    return "UnexpectedProperty" + issue.VAL.propertyName;
  } else if (variant === "PropertyTypeInconsistentWithTrackingPlan") {
    return "PropertyTypeInconsistentWithTrackingPlan" + issue.VAL.propertyName;
  } else {
    return "PropertyNotInTrackingPlan" + issue.VAL.propertyName;
  }
}

function getIssueKey_(issue) {
  return InspectorIssueType.getIssueTypeAsText(issue) + Belt_Option.getWithDefault(InspectorIssueType.getPropertyName(issue), "Unknown property");
}

function groupIssuesFromMultipleSources(issues) {
  return ArrayExt.group((function (param, param$1) {
                return Caml.caml_string_compare(getIssueKey(param[1]), getIssueKey(param$1[1]));
              }), issues);
}

function groupIssuesFromMultipleSources_(issues) {
  return ArrayExt.group((function (param, param$1) {
                return Caml.caml_string_compare(getIssueKey_(param[3]), getIssueKey_(param$1[3]));
              }), issues);
}

function partitionTrackingPlanAndNonTrackingPlanIssues(issues) {
  return Belt_Array.partitionU(issues, (function (param) {
                var variant = param[1].NAME;
                return !(variant === "SometimesMissing" || variant === "InconsistentType");
              }));
}

function partitionTrackingPlanAndNonTrackingPlanIssues_(issues) {
  return Belt_Array.partitionU(issues, (function (param) {
                var tmp = param[3];
                if (typeof tmp === "number") {
                  return false;
                }
                switch (tmp.TAG | 0) {
                  case /* MissingExpectedProperty */0 :
                  case /* UnexpectedProperty */2 :
                      return true;
                  default:
                    return false;
                }
              }));
}

function sortAndGetFirstElement(array, dir) {
  var compareWithDirection = function (a, b) {
    if (dir === "LastSeen") {
      return Caml_obj.caml_compare(b, a);
    } else {
      return Caml_obj.caml_compare(a, b);
    }
  };
  return Belt_Array.get(Belt_Array.keepMapU(ArrayExt.group((function (param, param$1) {
                          return compareWithDirection(param[1], param$1[1]);
                        }), array), (function (typeGroup) {
                      return Belt_Array.get(typeGroup.slice().sort(function (param, param$1) {
                                      return compareWithDirection(param[2].date, param$1[2].date);
                                    }), 0);
                    })).sort(function (param, param$1) {
                  var seenVersionB = param$1[2];
                  var seenVersionA = param[2];
                  return compareWithDirection(seenVersionA.version + seenVersionA.date, seenVersionB.version + seenVersionB.date);
                }), 0);
}

function getFirstOrLastSeenPropertyMismatch(dir, issues) {
  return sortAndGetFirstElement(Belt_Array.concatMany(Belt_Array.mapU(issues, (function (param) {
                        var sourceId = param[0];
                        return Belt_Array.keepMapU(param[1].types, (function (type_) {
                                      return Belt_Option.map(Belt_Array.get((
                                                          dir === "LastSeen" ? type_.lastSeen.history.versions : type_.firstSeen.history.versions
                                                        ).slice().sort(function (seenVersionA, seenVersionB) {
                                                          var a = seenVersionA.version;
                                                          var b = seenVersionB.version;
                                                          if (dir === "LastSeen") {
                                                            return Caml_obj.caml_compare(b, a);
                                                          } else {
                                                            return Caml_obj.caml_compare(a, b);
                                                          }
                                                        }), 0), (function (version) {
                                                    return [
                                                            sourceId,
                                                            type_.type_,
                                                            version
                                                          ];
                                                  }));
                                    }));
                      }))), dir);
}

function getFirstOrLastSeenSometimesMissing(dir, issues) {
  return sortAndGetFirstElement(Belt_Array.concatMany(Belt_Array.mapU(issues, (function (param) {
                        var sourceId = param[0];
                        return Belt_Array.keepMapU(Belt_Array.keepU(param[1].presences, (function (param) {
                                          return param.presence !== "key-and-value-present";
                                        })), (function (presence) {
                                      return Belt_Option.mapU(Belt_Array.get((
                                                        dir === "LastSeen" ? presence.lastSeen.history.versions : presence.firstSeen.history.versions
                                                      ).sort(function (seenVersionA, seenVersionB) {
                                                          var a = seenVersionA.version;
                                                          var b = seenVersionB.version;
                                                          if (dir === "LastSeen") {
                                                            return Caml_obj.caml_compare(b, a);
                                                          } else {
                                                            return Caml_obj.caml_compare(a, b);
                                                          }
                                                        }), 0), (function (version) {
                                                    return [
                                                            sourceId,
                                                            presence.presence,
                                                            version
                                                          ];
                                                  }));
                                    }));
                      }))), dir);
}

function getFirstOrLastSeenPropertyTypeInconsistentWithTrackingPlan(dir, types) {
  return sortAndGetFirstElement(Belt_Array.keepMapU(types, (function (param) {
                    var type_ = param[1];
                    var sourceId = param[0];
                    return Belt_Option.mapU(Belt_Array.get((
                                        dir === "LastSeen" ? type_.lastSeen.history.versions : type_.firstSeen.history.versions
                                      ).slice().sort(function (seenVersionA, seenVersionB) {
                                        var a = seenVersionA.version;
                                        var b = seenVersionB.version;
                                        if (dir === "LastSeen") {
                                          return Caml_obj.caml_compare(b, a);
                                        } else {
                                          return Caml_obj.caml_compare(a, b);
                                        }
                                      }), 0), (function (version) {
                                  return [
                                          sourceId,
                                          type_.type_,
                                          version
                                        ];
                                }));
                  })), dir);
}

function getFirstOrLastSeenPropertyNotInTrackingPlan(dir, propertyTypes) {
  return sortAndGetFirstElement(Belt_Array.concatMany(Belt_Array.mapU(propertyTypes, (function (param) {
                        var sourceId = param[0];
                        return Belt_Array.keepMapU(param[1], (function (type_) {
                                      return Belt_Option.mapU(Belt_Array.get((
                                                          dir === "LastSeen" ? type_.lastSeen.history.versions : type_.firstSeen.history.versions
                                                        ).slice().sort(function (seenVersionA, seenVersionB) {
                                                          var a = seenVersionA.version;
                                                          var b = seenVersionB.version;
                                                          if (dir === "LastSeen") {
                                                            return Caml_obj.caml_compare(b, a);
                                                          } else {
                                                            return Caml_obj.caml_compare(a, b);
                                                          }
                                                        }), 0), (function (version) {
                                                    return [
                                                            sourceId,
                                                            type_.type_,
                                                            version
                                                          ];
                                                  }));
                                    }));
                      }))), dir);
}

function getFirstOrLastSeenIssue(dirOpt, issues) {
  var dir = dirOpt !== undefined ? dirOpt : "FirstSeen";
  var match = Belt_Array.get(issues, 0);
  if (match === undefined) {
    return Pervasives.failwith("get first or last seen issues of none");
  }
  var variant = match[1].NAME;
  if (variant === "MissingExpectedProperty") {
    if (dir === "LastSeen") {
      return getFirstOrLastSeenPropertyTypeInconsistentWithTrackingPlan(dir, Belt_Array.keepMapU(issues, (function (param) {
                        var issue = param[1];
                        if (typeof issue === "object" && issue.NAME === "MissingExpectedProperty") {
                          return [
                                  param[0],
                                  issue.VAL.propertyType
                                ];
                        }
                        
                      })));
    } else {
      return ;
    }
  } else if (variant === "InconsistentType") {
    return getFirstOrLastSeenPropertyMismatch(dir, Belt_Array.keepMapU(issues, (function (param) {
                      var issue = param[1];
                      if (typeof issue === "object" && issue.NAME === "InconsistentType") {
                        return [
                                param[0],
                                issue.VAL
                              ];
                      }
                      
                    })));
  } else if (variant === "SometimesMissing") {
    return getFirstOrLastSeenSometimesMissing(dir, Belt_Array.keepMapU(issues, (function (param) {
                      var issue = param[1];
                      if (typeof issue === "object" && issue.NAME === "SometimesMissing") {
                        return [
                                param[0],
                                issue.VAL
                              ];
                      }
                      
                    })));
  } else if (variant === "UnexpectedProperty") {
    return getFirstOrLastSeenPropertyNotInTrackingPlan(dir, Belt_Array.keepMapU(issues, (function (param) {
                      var issue = param[1];
                      if (typeof issue === "object" && issue.NAME === "UnexpectedProperty") {
                        return [
                                param[0],
                                issue.VAL.propertyTypes
                              ];
                      }
                      
                    })));
  } else if (variant === "PropertyTypeInconsistentWithTrackingPlan") {
    return getFirstOrLastSeenPropertyTypeInconsistentWithTrackingPlan(dir, Belt_Array.keepMapU(issues, (function (param) {
                      var issue = param[1];
                      if (typeof issue === "object" && issue.NAME === "PropertyTypeInconsistentWithTrackingPlan") {
                        return [
                                param[0],
                                issue.VAL.propertyType
                              ];
                      }
                      
                    })));
  } else {
    return getFirstOrLastSeenPropertyNotInTrackingPlan(dir, Belt_Array.keepMapU(issues, (function (param) {
                      var issue = param[1];
                      if (typeof issue === "object" && issue.NAME === "PropertyNotInTrackingPlan") {
                        return [
                                param[0],
                                issue.VAL.propertyTypes
                              ];
                      }
                      
                    })));
  }
}

function getFirstOrLastSeenIssue_($staropt$star, _issues) {
  $staropt$star !== undefined;
  
}

function getLatestTimestamp(events) {
  var lastSeens = Belt_Array.concatMany(Belt_Array.mapU(events, (function ($$event) {
              return Belt_Array.keepMapU($$event.sources, (function (source) {
                            return source.lastSeen.current.lastSeen;
                          }));
            })));
  var bigger = function (a, b) {
    if (Caml_obj.caml_compare(a, b) > 0) {
      return a;
    } else {
      return b;
    }
  };
  if (lastSeens.length === 0) {
    return ;
  }
  var first = Belt_Array.getExn(lastSeens, 0);
  var rest = ArrayExt.sliceToEndSafe(lastSeens, 1);
  return Caml_option.some(new Date(Belt_Array.reduce(rest, first, bigger)));
}

function getEventTrackingPlanStatus(sourceIds, $$event) {
  return Belt_Array.reduceU(Belt_Option.mapWithDefaultU(sourceIds, $$event.sources, (function (sourceIds) {
                    return Belt_Array.keepU($$event.sources, (function (eventSource) {
                                  return Belt_Array.someU(sourceIds, (function (sourceId) {
                                                return sourceId === eventSource.sourceId;
                                              }));
                                }));
                  })), undefined, (function (currentTrackingPlanStatus, source) {
                var match = source.trackingPlanStatus;
                if (currentTrackingPlanStatus !== undefined) {
                  if (typeof currentTrackingPlanStatus === "object") {
                    if (currentTrackingPlanStatus.NAME === "EventFoundInTrackingPlan") {
                      if (match !== undefined) {
                        if (typeof match === "object") {
                          if (match.NAME === "EventConflictWithTrackingPlan") {
                            return match;
                          } else {
                            return currentTrackingPlanStatus;
                          }
                        } else if (match === "EventNotInTrackingPlan") {
                          return match;
                        } else {
                          return currentTrackingPlanStatus;
                        }
                      } else {
                        return currentTrackingPlanStatus;
                      }
                    } else if (match === "EventNotInTrackingPlan") {
                      return match;
                    } else {
                      return currentTrackingPlanStatus;
                    }
                  } else {
                    return currentTrackingPlanStatus;
                  }
                } else {
                  return match;
                }
              }));
}

function getEventTrackingPlanStatus_(sourceIds, $$event) {
  return Belt_Array.reduceU(Belt_Option.mapWithDefaultU(sourceIds, Belt_MapString.valuesToArray($$event.issues), (function (sourceIds) {
                    return Belt_Array.keepMapU(sourceIds, (function (sourceId) {
                                  return Belt_MapString.get($$event.issues, sourceId);
                                }));
                  })), undefined, (function (_currentTrackingPlanStatus, param) {
                return Belt_Array.reduceU(param.issues, {
                            NAME: "EventFoundInTrackingPlan",
                            VAL: $$event.eventName
                          }, (function (currentTrackingPlanStatus, issue) {
                              var exit = 0;
                              if (currentTrackingPlanStatus !== undefined) {
                                if (currentTrackingPlanStatus === "EventNotInTrackingPlan") {
                                  return currentTrackingPlanStatus;
                                }
                                exit = 2;
                              } else {
                                exit = 2;
                              }
                              if (exit === 2) {
                                if (issue === 0) {
                                  return "EventNotInTrackingPlan";
                                }
                                if (currentTrackingPlanStatus !== undefined && typeof currentTrackingPlanStatus === "object" && currentTrackingPlanStatus.NAME === "EventConflictWithTrackingPlan") {
                                  return currentTrackingPlanStatus;
                                }
                                
                              }
                              if (typeof issue === "number") {
                                return {
                                        NAME: "EventConflictWithTrackingPlan",
                                        VAL: $$event.eventName
                                      };
                              } else {
                                return currentTrackingPlanStatus;
                              }
                            }));
              }));
}

function getEventTrackingPlanStatusFromCell(cell) {
  if (typeof cell !== "object") {
    return ;
  }
  var variant = cell.NAME;
  if (variant === "PropertyVolume" || variant === "PropertyName" || variant === "PropertyIssues") {
    return getEventTrackingPlanStatus(undefined, cell.VAL[0]);
  }
  if (variant === "PropertySourceVolume" || variant === "PropertySourceIssues") {
    var match = cell.VAL;
    return getEventTrackingPlanStatus(Belt_Option.mapU(match[2], (function (propertySource) {
                      return [propertySource.sourceId];
                    })), match[0]);
  }
  if (variant === "EventIssues" || variant === "EventName" || variant === "EventVolume") {
    return getEventTrackingPlanStatus(undefined, cell.VAL);
  }
  var match$1 = cell.VAL;
  return getEventTrackingPlanStatus(Belt_Option.mapU(match$1[1], (function (eventSource) {
                    return [eventSource.sourceId];
                  })), match$1[0]);
}

function getEventTrackingPlanStatusFromCell_(cell) {
  if (typeof cell !== "object") {
    return ;
  }
  var variant = cell.NAME;
  if (variant === "PropertyVolume" || variant === "PropertyName" || variant === "PropertyIssues") {
    return getEventTrackingPlanStatus_(undefined, cell.VAL[0]);
  }
  if (variant === "PropertySourceVolume" || variant === "PropertySourceIssues") {
    var match = cell.VAL;
    return getEventTrackingPlanStatus_(Belt_Option.mapU(match[2], (function (propertySourceId) {
                      return [propertySourceId];
                    })), match[0]);
  }
  if (variant === "EventIssues" || variant === "EventName" || variant === "EventVolume") {
    return getEventTrackingPlanStatus_(undefined, cell.VAL);
  }
  var match$1 = cell.VAL;
  return getEventTrackingPlanStatus_(Belt_Option.mapU(match$1[1], (function (eventSourceId) {
                    return [eventSourceId];
                  })), match$1[0]);
}

export {
  getSourceIdByColumnIndex ,
  getSourceIdByColumnIndex_ ,
  getEventSourceBySourceId ,
  getEventSourceBySourceId_ ,
  getEventSourceByColumnIndex ,
  getEventSourceByColumnIndex_ ,
  getPropertySourceByColumnIndex ,
  getPropertySourceByColumnIndex_ ,
  getCell ,
  getCell_ ,
  countPropertyIssuesTypes ,
  filterPropertySourcesBySourceIds ,
  filterPropertySourcesBySourceIds_ ,
  countEventIssueTypes ,
  getEventIssues ,
  getEventRelatedIssues ,
  getPropertyIssues ,
  countPropertyVolume ,
  prettyPrintListTypeForNow ,
  getPropertyTypesFromIssues ,
  getPropertyPresencesFromIssues ,
  getIssuePropertyName ,
  getIssueKey ,
  getIssueKey_ ,
  groupIssuesFromMultipleSources ,
  groupIssuesFromMultipleSources_ ,
  partitionTrackingPlanAndNonTrackingPlanIssues ,
  partitionTrackingPlanAndNonTrackingPlanIssues_ ,
  sortAndGetFirstElement ,
  getFirstOrLastSeenPropertyMismatch ,
  getFirstOrLastSeenSometimesMissing ,
  getFirstOrLastSeenPropertyTypeInconsistentWithTrackingPlan ,
  getFirstOrLastSeenPropertyNotInTrackingPlan ,
  getFirstOrLastSeenIssue ,
  getFirstOrLastSeenIssue_ ,
  getLatestTimestamp ,
  getEventTrackingPlanStatus ,
  getEventTrackingPlanStatus_ ,
  getEventTrackingPlanStatusFromCell ,
  getEventTrackingPlanStatusFromCell_ ,
  
}
/* InspectorEventViewModelUtils Not a pure module */
