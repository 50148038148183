// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as AvoConfigTypes from "../models/AvoConfigTypes.mjs";
import * as BranchStateFirebaseModel from "../models/BranchStateFirebaseModel.mjs";

function encode(t) {
  return Json_encode.object_({
              hd: [
                "shareId",
                t.shareId
              ],
              tl: /* [] */0
            });
}

function decode(json) {
  return {
          shareId: Json_decode.field("shareId", Json_decode.string, json)
        };
}

var $$Request = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  switch (t) {
    case /* New */0 :
        return "New";
    case /* Added */1 :
        return "Added";
    case /* Updated */2 :
        return "Updated";
    case /* Removed */3 :
        return "Removed";
    case /* Deleted */4 :
        return "Deleted";
    
  }
}

function decode$1(json) {
  var match = Json_decode.string(json);
  switch (match) {
    case "Added" :
        return /* Added */1;
    case "Deleted" :
        return /* Deleted */4;
    case "New" :
        return /* New */0;
    case "Removed" :
        return /* Removed */3;
    case "Updated" :
        return /* Updated */2;
    default:
      return Pervasives.failwith("Unexpected event status");
  }
}

var ImplementationEventStatus = {
  encode: encode$1,
  decode: decode$1
};

function encode$2(t) {
  var branchCodeSnippet = t.branchCodeSnippet;
  var mainCodeSnippet = t.mainCodeSnippet;
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "id",
                      t.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        t.name
                      ],
                      tl: {
                        hd: [
                          "status",
                          encode$1(t.status)
                        ],
                        tl: {
                          hd: [
                            "description",
                            t.description
                          ],
                          tl: {
                            hd: [
                              "includeInCodegen",
                              t.includeInCodegen
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  },
                  branchCodeSnippet !== undefined ? ({
                        hd: [
                          "branchCodeSnippet",
                          branchCodeSnippet
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  mainCodeSnippet !== undefined ? ({
                        hd: [
                          "mainCodeSnippet",
                          mainCodeSnippet
                        ],
                        tl: /* [] */0
                      }) : /* [] */0
                ]));
}

function decode$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          status: Json_decode.field("status", decode$1, json),
          description: Json_decode.field("description", Json_decode.string, json),
          includeInCodegen: Json_decode.field("includeInCodegen", Json_decode.bool, json),
          branchCodeSnippet: Json_decode.optional((function (param) {
                  return Json_decode.field("branchCodeSnippet", Json_decode.string, param);
                }), json),
          mainCodeSnippet: Json_decode.optional((function (param) {
                  return Json_decode.field("mainCodeSnippet", Json_decode.string, param);
                }), json)
        };
}

var ImplementationEvent = {
  encode: encode$2,
  decode: decode$2
};

function encode$3(t) {
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "id",
                      t.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        t.name
                      ],
                      tl: {
                        hd: [
                          "events",
                          Json_encode.array(encode$2, t.events)
                        ],
                        tl: /* [] */0
                      }
                    }
                  },
                  Belt_Option.mapWithDefault(t.platform, /* [] */0, (function (platform) {
                          return {
                                  hd: [
                                    "platform",
                                    AvoConfigTypes.devPlatformToJs(platform)
                                  ],
                                  tl: /* [] */0
                                };
                        })),
                  Belt_Option.mapWithDefault(t.language, /* [] */0, (function (language) {
                          return {
                                  hd: [
                                    "language",
                                    AvoConfigTypes.languageToJs(language)
                                  ],
                                  tl: /* [] */0
                                };
                        }))
                ]));
}

function decode$3(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          platform: Json_decode.optional((function (param) {
                  return Json_decode.field("platform", (function (json) {
                                return Belt_Option.getExn(AvoConfigTypes.devPlatformFromJs(Json_decode.string(json)));
                              }), param);
                }), json),
          language: Json_decode.optional((function (param) {
                  return Json_decode.field("language", (function (json) {
                                return Belt_Option.getExn(AvoConfigTypes.languageFromJs(Json_decode.string(json)));
                              }), param);
                }), json),
          name: Json_decode.field("name", Json_decode.string, json),
          events: Json_decode.field("events", (function (param) {
                  return Json_decode.array(decode$2, param);
                }), json)
        };
}

var ImplementationSource = {
  encode: encode$3,
  decode: decode$3
};

function encode$4(t) {
  return Json_encode.object_({
              hd: [
                "schemaId",
                t.schemaId
              ],
              tl: {
                hd: [
                  "schemaName",
                  t.schemaName
                ],
                tl: {
                  hd: [
                    "branchId",
                    t.branchId
                  ],
                  tl: {
                    hd: [
                      "branchName",
                      t.branchName
                    ],
                    tl: {
                      hd: [
                        "branchStatus",
                        BranchStateFirebaseModel.branchStatusToJs(t.branchStatus)
                      ],
                      tl: {
                        hd: [
                          "sources",
                          Json_encode.array(encode$3, t.sources)
                        ],
                        tl: {
                          hd: [
                            "approvalWorkflow",
                            t.approvalWorkflow
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function decode$4(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          schemaName: Json_decode.field("schemaName", Json_decode.string, json),
          branchId: Json_decode.field("branchId", Json_decode.string, json),
          branchName: Json_decode.field("branchName", Json_decode.string, json),
          branchStatus: Belt_Option.getExn(Json_decode.field("branchStatus", (function (json) {
                      return BranchStateFirebaseModel.branchStatusFromJs(Json_decode.string(json));
                    }), json)),
          sources: Json_decode.field("sources", (function (param) {
                  return Json_decode.array(decode$3, param);
                }), json),
          approvalWorkflow: Json_decode.field("approvalWorkflow", Json_decode.bool, json)
        };
}

var $$Response = {
  ImplementationEventStatus: ImplementationEventStatus,
  ImplementationEvent: ImplementationEvent,
  ImplementationSource: ImplementationSource,
  encode: encode$4,
  decode: decode$4
};

export {
  $$Request ,
  $$Response ,
  
}
/* BranchStateFirebaseModel Not a pure module */
