// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as DateFns from "../DateFns.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PlanLightning from "../PlanLightning.mjs";
import * as ContextBoxLazy from "../ContextBoxLazy.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as InspectorViewModel from "./InspectorViewModel.mjs";

var contextBoxStyle = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: {
                    hd: Css.boxShadow(Styles.Shadow.box),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function windowOptionStyle(isSelected) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.button),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.gap(Css.px(8)),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(10)),
                              tl: {
                                hd: Css.borderTop(Css.px(1), "solid", isSelected ? "transparent" : Styles.Color.light02),
                                tl: {
                                  hd: Css.color(isSelected ? Styles.Color.light12 : Styles.Color.light10),
                                  tl: {
                                    hd: Css_Legacy_Core.SVG.fill("transparent"),
                                    tl: {
                                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.transition({
                                                  NAME: "ms",
                                                  VAL: 150.0
                                                }, undefined, undefined, "all"),
                                            tl: {
                                              hd: Css.hover({
                                                    hd: Css.color(Styles.Color.light12),
                                                    tl: {
                                                      hd: Css.backgroundColor(Styles.Color.light01),
                                                      tl: /* [] */0
                                                    }
                                                  }),
                                              tl: {
                                                hd: Css.active({
                                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                                      tl: /* [] */0
                                                    }),
                                                tl: {
                                                  hd: Css.focus({
                                                        hd: Css.outlineStyle("none"),
                                                        tl: {
                                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                          tl: {
                                                            hd: Css.zIndex(2),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }),
                                                  tl: {
                                                    hd: Css.position("relative"),
                                                    tl: {
                                                      hd: Css.after({
                                                            hd: Css.display(isSelected ? "block" : "none"),
                                                            tl: {
                                                              hd: Css.position("absolute"),
                                                              tl: {
                                                                hd: Css.top(Css.pct(50.0)),
                                                                tl: {
                                                                  hd: Css.right(Css.px(12)),
                                                                  tl: {
                                                                    hd: Css.marginTop(Css.px(-8)),
                                                                    tl: {
                                                                      hd: Css.width(Css.px(16)),
                                                                      tl: {
                                                                        hd: Css.height(Css.px(16)),
                                                                        tl: {
                                                                          hd: Css.unsafe("content", "url(\"data:image/svg+xml,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%3E%3Cpath%20fill%3D%22%23FF0EB4%22%20d%3D%22m14.53%205.03-8%208a.75.75%200%200%201-1.06%200l-3.5-3.5a.75.75%200%201%201%201.06-1.06L6%2011.44l7.47-7.47a.75.75%200%200%201%201.06%201.06Z%22%2F%3E%3C%2Fsvg%3E\")"),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }),
                                                      tl: {
                                                        hd: Css.selector("& + button", isSelected ? ({
                                                                  hd: Css.borderColor("transparent"),
                                                                  tl: /* [] */0
                                                                }) : /* [] */0),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var lightningTooltipStyle = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(15)),
        tl: {
          hd: Css.top(Css.px(-4)),
          tl: {
            hd: Css.width(Css.px(130)),
            tl: {
              hd: Css.borderRadius(Styles.Border.radius),
              tl: {
                hd: Css.padding2(Css.px(5), Css.px(10)),
                tl: {
                  hd: Css.textAlign("center"),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.tiny),
                        tl: {
                          hd: Css.boxShadow(Styles.Shadow.box),
                          tl: {
                            hd: Css.pointerEvents("none"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var dropdownHeader = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: {
        hd: Css.paddingLeft(Css.px(10)),
        tl: {
          hd: Css.width(Css.px(168)),
          tl: {
            hd: Css.cursor("pointer"),
            tl: /* [] */0
          }
        }
      }
    });

function printInspectorTimeWindow(timeWindow) {
  if (timeWindow === "Last1Hour") {
    return "Last 1 hour";
  } else if (timeWindow === "Last24Hours") {
    return "Last 24 hours";
  } else if (timeWindow === "Last7Days") {
    return "Last 7 days";
  } else {
    return "Last 30 days";
  }
}

function clockTimeToString(time) {
  if (time < 10) {
    return "0" + time.toString();
  } else {
    return time.toString();
  }
}

function printUpdateTime(timeWindow, lastUpdated, environment) {
  if (!(timeWindow === "Last24Hours" || timeWindow === "Last1Hour")) {
    if (timeWindow === "Last7Days") {
      if (environment === "Production") {
        var date = new Date(new Date(Date.now()).setUTCHours(11, 45));
        var localhours = clockTimeToString(date.getHours() | 0);
        var localminutes = clockTimeToString(date.getMinutes() | 0);
        return Belt_Option.mapWithDefault(lastUpdated, "-", (function (param) {
                      return "Updates every day at " + localhours + ":" + localminutes;
                    }));
      }
      
    } else if (environment === "Production") {
      var date$1 = new Date(new Date(Date.now()).setUTCHours(12, 0));
      var localhours$1 = clockTimeToString(date$1.getHours() | 0);
      var localminutes$1 = clockTimeToString(date$1.getMinutes() | 0);
      return Belt_Option.mapWithDefault(lastUpdated, "-", (function (param) {
                    return "Updates every day at " + localhours$1 + ":" + localminutes$1;
                  }));
    }
    
  }
  return Belt_Option.mapWithDefault(lastUpdated, "-", (function (date) {
                return "Updated " + DateFns.formatDistanceToNow(undefined, undefined, date) + " ago";
              }));
}

function InspectorViewWindowPicker$MainButton(Props) {
  var disabledOpt = Props.disabled;
  var title = Props.title;
  var label = Props.label;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var tmp = {
    disabled: disabled,
    icon: "calendar",
    label: label,
    style: "outline",
    minWidth: 168
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.selector(" button", {
                          hd: Css.backgroundImage({
                                NAME: "url",
                                VAL: "'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4zIDMuNUwzLjggMWwyLjUgMi41TTEuMyA2LjVMMy44IDlsMi41LTIuNSIgc3Ryb2tlPSIjQUFCMkJEIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg=='"
                              }),
                          tl: {
                            hd: Css.backgroundRepeat("noRepeat"),
                            tl: {
                              hd: Css.backgroundPosition({
                                    NAME: "hv",
                                    VAL: [
                                      {
                                        NAME: "percent",
                                        VAL: 95.0
                                      },
                                      "center"
                                    ]
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: {
                      hd: Css.selector(" button > span", {
                            hd: Css.justifyContent("flexStart"),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(Button.make, tmp));
}

var MainButton = {
  make: InspectorViewWindowPicker$MainButton
};

function InspectorViewWindowPicker(Props) {
  var timeWindow = Props.timeWindow;
  var setTimeWindow = Props.setTimeWindow;
  var environment = Props.environment;
  var lastUpdated = Props.lastUpdated;
  var globalSend = Props.globalSend;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  return React.createElement(ContextBoxLazy.make, {
              button: InspectorViewWindowPicker$MainButton({
                    label: printInspectorTimeWindow(timeWindow),
                    disabled: undefined,
                    title: undefined
                  }),
              children: (function (close) {
                  return React.createElement("div", {
                              className: contextBoxStyle
                            }, React.createElement("div", {
                                  className: dropdownHeader,
                                  onClick: (function (param) {
                                      return Curry._1(close, undefined);
                                    })
                                }, React.createElement("span", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement(Icon.make, {
                                          type_: "calendar",
                                          size: "small",
                                          color: Styles.Color.light11
                                        }), React.createElement(Spacer.make, {
                                          width: 8
                                        }), React.createElement($$Text.make, {
                                          size: "Small",
                                          weight: "Semi",
                                          children: printInspectorTimeWindow(timeWindow)
                                        })), React.createElement(Spacer.make, {
                                      height: 4
                                    }), React.createElement($$Text.make, {
                                      size: "Tiny",
                                      color: Styles.Color.light08,
                                      children: printUpdateTime(timeWindow, lastUpdated, environment)
                                    })), Belt_Array.map(InspectorViewModel.timeWindowLadder, (function (lookback) {
                                    var availability = AvoLimits.InspectorLookback.computeAvailability(workspace, lookback);
                                    return React.createElement("button", {
                                                key: printInspectorTimeWindow(lookback),
                                                className: windowOptionStyle(lookback === timeWindow),
                                                onClick: (function (param) {
                                                    if (availability === "Unavailable") {
                                                      return Curry._1(globalSend, {
                                                                  TAG: /* OpenModal */4,
                                                                  _0: {
                                                                    NAME: "BillingPrompt",
                                                                    VAL: "InspectorTimeWindow"
                                                                  }
                                                                });
                                                    } else {
                                                      Curry._1(setTimeWindow, (function (param) {
                                                              return lookback;
                                                            }));
                                                      if (lookback === "Last7Days") {
                                                        Firebase.app(undefined).firestore().collection("schemas").doc(workspace.id).set({
                                                              onboardingV2: {
                                                                hasObservedSevenDaysInspector: true
                                                              }
                                                            }, {"merge": true});
                                                      }
                                                      return Curry._1(close, undefined);
                                                    }
                                                  })
                                              }, React.createElement("div", undefined, printInspectorTimeWindow(lookback)), availability === "Available" ? (
                                                  workspace.plan.name === /* Free */1 ? React.createElement("div", {
                                                          className: Curry._1(Css.style, {
                                                                hd: Css.position("relative"),
                                                                tl: {
                                                                  hd: Css.fontSize(Styles.FontSize.tiny),
                                                                  tl: {
                                                                    hd: Css.color(Styles.Color.green),
                                                                    tl: {
                                                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                      tl: {
                                                                        hd: Css.selector(" > div", {
                                                                              hd: Css.display("none"),
                                                                              tl: /* [] */0
                                                                            }),
                                                                        tl: {
                                                                          hd: Css.hover({
                                                                                hd: Css.selector(" > div", {
                                                                                      hd: Css.display("block"),
                                                                                      tl: /* [] */0
                                                                                    }),
                                                                                tl: /* [] */0
                                                                              }),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              })
                                                        }, React.createElement("div", {
                                                              className: lightningTooltipStyle
                                                            }, "Available on free plan"), "FREE") : null
                                                ) : (
                                                  availability === "AvailableDuringTrial" ? React.createElement(PlanLightning.make, {
                                                          tooltipText: "Available during trial"
                                                        }) : React.createElement(PlanLightning.make, {
                                                          tooltipText: "Requires plan upgrade"
                                                        })
                                                ));
                                  })));
                })
            });
}

var dropdownWidth = 168;

var make = InspectorViewWindowPicker;

export {
  contextBoxStyle ,
  windowOptionStyle ,
  lightningTooltipStyle ,
  dropdownWidth ,
  dropdownHeader ,
  printInspectorTimeWindow ,
  clockTimeToString ,
  printUpdateTime ,
  MainButton ,
  make ,
  
}
/* contextBoxStyle Not a pure module */
