// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var TimeoutException = /* @__PURE__ */Caml_exceptions.create("Errors.TimeoutException");

export {
  TimeoutException ,
  
}
/* No side effect */
