// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PropertyUtils from "../PropertyUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as ModelUtils_mapped from "../ModelUtils_mapped.mjs";
import * as InspectorViewModel from "./InspectorViewModel.mjs";
import * as Belt_MutableMapString from "rescript/lib/es6/belt_MutableMapString.js";
import * as InspectorTrackingPlanUtils from "../../../shared/InspectorTrackingPlanUtils.mjs";

function propertySeenInCurrentWindow(filterBySourceIdOpt, property) {
  var filterBySourceId = filterBySourceIdOpt !== undefined ? Caml_option.valFromOption(filterBySourceIdOpt) : undefined;
  return Belt_Array.someU(property.sources, (function (source) {
                if (Belt_Option.mapWithDefault(filterBySourceId, true, (function (sourceId) {
                          return source.sourceId === sourceId;
                        }))) {
                  return Belt_Array.someU(source.propertyTypes, (function (propertyType) {
                                return propertyType.count.current.total > 0;
                              }));
                } else {
                  return false;
                }
              }));
}

function getMissingProperties(inspectorEvent, modelEvent, modelProperties, source, model, mappedRulesByPropertyId) {
  var inspectorPropertyNames = Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.keepU(inspectorEvent.properties, (function (property) {
                  return propertySeenInCurrentWindow(Caml_option.some(source.sourceId), property);
                })), (function (property) {
              return property.propertyName;
            })));
  var requiredEventAndUserProps = Belt_List.toArray(Belt_List.keepU(modelProperties, (function (property) {
              return Belt_Option.getWithDefault(Belt_Option.map(modelEvent, (function ($$event) {
                                if (ModelUtils.isPropertyIncludedOnEventAndSource(undefined, property, $$event.id, source.sourceId, model)(undefined)) {
                                  return !ModelUtils.isPropertyOptionalOnEventAndSource(property, $$event.id, source.sourceId, model);
                                } else {
                                  return false;
                                }
                              })), false);
            })));
  return Belt_Array.keepMap(requiredEventAndUserProps, (function (property) {
                var possiblePropertyNames = ModelUtils_mapped.getPropertyPossibleNames(property, modelEvent, mappedRulesByPropertyId);
                if (Belt_List.some(possiblePropertyNames, (function (nameMappingName) {
                          return Belt_SetString.has(inspectorPropertyNames, nameMappingName);
                        }))) {
                  return ;
                }
                var count_comparison = source.count.comparison;
                var count_current = {
                  numVersions: source.count.current.numVersions,
                  total: 0,
                  versions: source.count.current.versions
                };
                var count_history = source.count.history;
                var count = {
                  comparison: count_comparison,
                  current: count_current,
                  history: count_history
                };
                var propertyType_type_ = property.type_;
                var propertyType_firstSeen = source.lastSeen;
                var propertyType_lastSeen = source.lastSeen;
                var propertyType = {
                  type_: propertyType_type_,
                  count: count,
                  firstSeen: propertyType_firstSeen,
                  lastSeen: propertyType_lastSeen
                };
                return {
                        propertyName: property.name,
                        propertyType: propertyType
                      };
              }));
}

function getTrackingPlanPropertyIssues(hasSeenSource, maybeModelEvent, model, $$event, source, modelProperty, property) {
  if (!hasSeenSource) {
    return [];
  }
  var modelEventsByName = ModelUtils.getModelEventsByName(model);
  var match = InspectorTrackingPlanUtils.getModelEventAndProperties($$event.eventName, modelEventsByName, model);
  var maybeEventId = Belt_Option.map(match[0], (function ($$event) {
          return $$event.id;
        }));
  return Belt_Array.concatMany(Belt_Array.mapU(source.propertyTypes, (function (propertyType) {
                    var optional = maybeEventId !== undefined ? ModelUtils.isPropertyOptionalOnEventAndSource(modelProperty, maybeEventId, source.sourceId, model) : true;
                    var isUnexpectedEvent = Belt_Option.getWithDefault(Belt_Option.map(maybeEventId, (function (eventId) {
                                return !ModelUtils.isEventIncludedInSource(undefined, model, source.sourceId, eventId);
                              })), false);
                    var isUnexpectedProperty = Belt_Option.getWithDefault(Belt_Option.map(maybeEventId, (function (eventId) {
                                return !ModelUtils.isPropertyIncludedOnEventAndSource(true, modelProperty, eventId, source.sourceId, model)(undefined);
                              })), false);
                    var matchesTrackingPlan = InspectorTrackingPlanUtils.receivedPropertyTypeMatchesTrackingPlan(modelProperty, propertyType.type_, optional);
                    if (isUnexpectedEvent) {
                      return [];
                    }
                    if (!isUnexpectedProperty && matchesTrackingPlan) {
                      return [];
                    }
                    if (matchesTrackingPlan) {
                      return [{
                                NAME: "UnexpectedProperty",
                                VAL: {
                                  propertyName: property.propertyName,
                                  propertyTypes: [propertyType]
                                }
                              }];
                    }
                    var modelTypeString = PropertyUtils.printPropertyTypeWithoutValidations(modelProperty, optional);
                    return [{
                              NAME: "PropertyTypeInconsistentWithTrackingPlan",
                              VAL: {
                                propertyName: property.propertyName,
                                propertyType: propertyType,
                                modelType: modelTypeString
                              }
                            }];
                  })));
}

function combinePropertySources(propertySources) {
  var dict = {};
  Belt_Array.forEach(propertySources, (function (propertySource) {
          var existingPropertySource = Js_dict.get(dict, propertySource.sourceId);
          if (existingPropertySource !== undefined) {
            var updatedSource_sourceId = existingPropertySource.sourceId;
            var updatedSource_propertyTypes = Belt_Array.concat(existingPropertySource.propertyTypes, propertySource.propertyTypes);
            var updatedSource_issues = Belt_Array.concat(existingPropertySource.issues, propertySource.issues);
            var updatedSource = {
              sourceId: updatedSource_sourceId,
              propertyTypes: updatedSource_propertyTypes,
              issues: updatedSource_issues
            };
            dict[propertySource.sourceId] = updatedSource;
            return ;
          }
          dict[propertySource.sourceId] = propertySource;
          
        }));
  return Js_dict.values(dict);
}

function combineProperties(properties) {
  var dict = {};
  Belt_Array.forEachU(properties, (function (property) {
          var existingProperty = Js_dict.get(dict, property.propertyName);
          if (existingProperty !== undefined) {
            var updatedProperty_propertyName = existingProperty.propertyName;
            var updatedProperty_sources = combinePropertySources(Belt_Array.concat(existingProperty.sources, property.sources));
            var updatedProperty = {
              propertyName: updatedProperty_propertyName,
              sources: updatedProperty_sources
            };
            dict[property.propertyName] = updatedProperty;
            return ;
          }
          dict[property.propertyName] = property;
          
        }));
  return Js_dict.values(dict);
}

function combineVersions(versions) {
  var dict = {};
  Belt_Array.forEachU(versions, (function (version) {
          var existingVersion = Js_dict.get(dict, version.version);
          if (existingVersion !== undefined) {
            var updatedVersion_version = version.version;
            var updatedVersion_count = existingVersion.count + version.count | 0;
            var updatedVersion = {
              version: updatedVersion_version,
              count: updatedVersion_count
            };
            dict[version.version] = updatedVersion;
            return ;
          }
          dict[version.version] = version;
          
        }));
  return Js_dict.values(dict);
}

function combineSeenVersions(versions) {
  var dict = {};
  Belt_Array.forEach(versions, (function (version) {
          var existingVersion = Js_dict.get(dict, version.version);
          if (existingVersion !== undefined) {
            var updatedVersion_version = version.version;
            var updatedVersion_date = existingVersion.date > version.date ? existingVersion.date : version.date;
            var updatedVersion = {
              version: updatedVersion_version,
              date: updatedVersion_date
            };
            dict[version.version] = updatedVersion;
            return ;
          }
          dict[version.version] = version;
          
        }));
  return Js_dict.values(dict);
}

function combineSources(sources) {
  var dict = {};
  var getFirstSeen = function (existingFirstSeen, firstSeen) {
    if (existingFirstSeen === undefined) {
      if (firstSeen !== undefined) {
        return Caml_option.some(Caml_option.valFromOption(firstSeen));
      } else {
        return ;
      }
    }
    var existing = Caml_option.valFromOption(existingFirstSeen);
    if (firstSeen === undefined) {
      return Caml_option.some(existing);
    }
    var firstSeen$1 = Caml_option.valFromOption(firstSeen);
    if (Caml_obj.caml_greaterthan(existing, firstSeen$1)) {
      return Caml_option.some(firstSeen$1);
    } else {
      return Caml_option.some(existing);
    }
  };
  var getLastSeen = function (existingLastSeen, lastSeen) {
    if (existingLastSeen === undefined) {
      if (lastSeen !== undefined) {
        return Caml_option.some(Caml_option.valFromOption(lastSeen));
      } else {
        return ;
      }
    }
    var existing = Caml_option.valFromOption(existingLastSeen);
    if (lastSeen === undefined) {
      return Caml_option.some(existing);
    }
    var lastSeen$1 = Caml_option.valFromOption(lastSeen);
    if (Caml_obj.caml_greaterthan(existing, lastSeen$1)) {
      return Caml_option.some(existing);
    } else {
      return Caml_option.some(lastSeen$1);
    }
  };
  Belt_Array.forEachU(sources, (function (source) {
          var existingSource = Js_dict.get(dict, source.sourceId);
          if (existingSource !== undefined) {
            var currentVersions = combineSeenVersions(Belt_Array.concat(existingSource.lastSeen.current.versions, source.lastSeen.current.versions));
            var comparisonVersions = combineSeenVersions(Belt_Array.concat(existingSource.lastSeen.comparison.versions, source.lastSeen.comparison.versions));
            var historyVersions = combineSeenVersions(Belt_Array.concat(existingSource.lastSeen.history.versions, source.lastSeen.history.versions));
            var match = existingSource.trackingPlanStatus;
            var match$1 = source.trackingPlanStatus;
            var tmp;
            var exit = 0;
            var exit$1 = 0;
            var exit$2 = 0;
            var exit$3 = 0;
            if (match !== undefined) {
              if (typeof match === "object" && match.NAME === "EventFoundInTrackingPlan") {
                tmp = match;
              } else {
                exit$3 = 4;
              }
            } else if (match$1 !== undefined) {
              exit$3 = 4;
            } else {
              tmp = undefined;
            }
            if (exit$3 === 4) {
              if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "EventFoundInTrackingPlan") {
                tmp = match$1;
              } else {
                exit$2 = 3;
              }
            }
            if (exit$2 === 3) {
              if (match !== undefined && typeof match === "object" && match.NAME === "EventConflictWithTrackingPlan") {
                tmp = match;
              } else {
                exit$1 = 2;
              }
            }
            if (exit$1 === 2) {
              if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "EventConflictWithTrackingPlan") {
                tmp = match$1;
              } else {
                exit = 1;
              }
            }
            if (exit === 1) {
              tmp = match === "EventNotInTrackingPlan" ? match : match$1;
            }
            var updatedSource_sourceId = existingSource.sourceId;
            var updatedSource_lastSeen = {
              current: {
                firstSeen: getFirstSeen(existingSource.lastSeen.current.firstSeen, source.lastSeen.current.firstSeen),
                lastSeen: getLastSeen(existingSource.lastSeen.current.lastSeen, source.lastSeen.current.lastSeen),
                versions: currentVersions,
                numVersions: currentVersions.length
              },
              comparison: {
                firstSeen: getFirstSeen(existingSource.lastSeen.comparison.firstSeen, source.lastSeen.comparison.firstSeen),
                lastSeen: getLastSeen(existingSource.lastSeen.comparison.lastSeen, source.lastSeen.comparison.lastSeen),
                versions: comparisonVersions,
                numVersions: comparisonVersions.length
              },
              history: {
                firstSeen: getFirstSeen(existingSource.lastSeen.history.firstSeen, source.lastSeen.history.firstSeen),
                lastSeen: getLastSeen(existingSource.lastSeen.history.lastSeen, source.lastSeen.history.lastSeen),
                versions: historyVersions,
                numVersions: historyVersions.length
              }
            };
            var updatedSource_count = {
              comparison: {
                numVersions: existingSource.count.comparison.numVersions + source.count.comparison.numVersions | 0,
                total: existingSource.count.comparison.total + source.count.comparison.total | 0,
                versions: Belt_Array.concat(existingSource.count.comparison.versions, source.count.comparison.versions)
              },
              current: {
                numVersions: existingSource.count.current.numVersions + source.count.current.numVersions | 0,
                total: existingSource.count.current.total + source.count.current.total | 0,
                versions: Belt_Array.concat(existingSource.count.current.versions, source.count.current.versions)
              },
              history: {
                numVersions: existingSource.count.history.numVersions + source.count.history.numVersions | 0,
                total: existingSource.count.history.total + source.count.history.total | 0,
                versions: Belt_Array.concat(existingSource.count.history.versions, source.count.history.versions)
              }
            };
            var updatedSource = {
              sourceId: updatedSource_sourceId,
              lastSeen: updatedSource_lastSeen,
              count: updatedSource_count,
              trackingPlanStatus: tmp
            };
            dict[source.sourceId] = updatedSource;
            return ;
          }
          dict[source.sourceId] = source;
          
        }));
  return Js_dict.values(dict);
}

function combineEvents(events) {
  var dict = {};
  Belt_Array.forEachU(events, (function ($$event) {
          var existingEvent = Js_dict.get(dict, $$event.eventName);
          if (existingEvent !== undefined) {
            var comparisonVersions = combineVersions(Belt_Array.concat(existingEvent.count.comparison.versions, $$event.count.comparison.versions));
            var currentVersions = combineVersions(Belt_Array.concat(existingEvent.count.current.versions, $$event.count.current.versions));
            var historyVersions = combineVersions(Belt_Array.concat(existingEvent.count.history.versions, $$event.count.history.versions));
            var updatedEvent_eventName = existingEvent.eventName;
            var updatedEvent_count = {
              comparison: {
                numVersions: comparisonVersions.length,
                total: existingEvent.count.comparison.total + $$event.count.comparison.total | 0,
                versions: comparisonVersions
              },
              current: {
                numVersions: currentVersions.length,
                total: existingEvent.count.current.total + $$event.count.current.total | 0,
                versions: currentVersions
              },
              history: {
                numVersions: historyVersions.length,
                total: existingEvent.count.history.total + $$event.count.history.total | 0,
                versions: historyVersions
              }
            };
            var updatedEvent_properties = combineProperties(Belt_Array.concat(existingEvent.properties, $$event.properties));
            var updatedEvent_sources = combineSources(Belt_Array.concat(existingEvent.sources, $$event.sources));
            var updatedEvent = {
              eventName: updatedEvent_eventName,
              count: updatedEvent_count,
              properties: updatedEvent_properties,
              sources: updatedEvent_sources
            };
            dict[$$event.eventName] = updatedEvent;
            return ;
          }
          dict[$$event.eventName] = $$event;
          
        }));
  return Js_dict.values(dict);
}

function enrichInspectorDashboardEventsWithTrackingPlan(events, model) {
  var mappedRulesByPropertyId = ModelUtils_mapped.mapRulesByPropertyId(model.rules);
  var modelEventsByNameAndMapping = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.mapU(model.events, (function ($$event) {
                      return Belt_List.map(ModelUtils.getEventPossibleNames($$event, model), (function (name) {
                                    return [
                                            name,
                                            $$event
                                          ];
                                  }));
                    })))));
  var events$1 = Belt_Array.mapU(events, (function ($$event) {
          var match = InspectorTrackingPlanUtils.getModelEventAndProperties($$event.eventName, modelEventsByNameAndMapping, model);
          var maybeModelEvent = match[0];
          return {
                  eventName: maybeModelEvent !== undefined ? maybeModelEvent.name : $$event.eventName,
                  count: $$event.count,
                  properties: $$event.properties,
                  sources: $$event.sources
                };
        }));
  var events$2 = combineEvents(events$1);
  return Belt_Array.mapU(events$2, (function ($$event) {
                var match = InspectorTrackingPlanUtils.getModelEventAndProperties($$event.eventName, modelEventsByNameAndMapping, model);
                var modelPropertiesFromModelEventFoundByEventName = match[1];
                var maybeModelEvent = match[0];
                var missingPropertyMap = Belt_MutableMapString.make(undefined);
                Belt_Array.forEachU($$event.sources, (function (source) {
                        var missingExpectedPropertyIssues = source.count.current.total > 0 ? getMissingProperties($$event, maybeModelEvent, modelPropertiesFromModelEventFoundByEventName, source, model, mappedRulesByPropertyId) : [];
                        return Belt_Array.forEach(missingExpectedPropertyIssues, (function (issue) {
                                      var source_sourceId = source.sourceId;
                                      var source_propertyTypes = [issue.propertyType];
                                      var source_issues = [{
                                          NAME: "MissingExpectedProperty",
                                          VAL: issue
                                        }];
                                      var source$1 = {
                                        sourceId: source_sourceId,
                                        propertyTypes: source_propertyTypes,
                                        issues: source_issues
                                      };
                                      var updatedSources = Belt_Option.getWithDefault(Belt_Option.map(Belt_MutableMapString.get(missingPropertyMap, issue.propertyName), (function (sources) {
                                                  return Belt_Array.concat(sources, [source$1]);
                                                })), [source$1]);
                                      return Belt_MutableMapString.set(missingPropertyMap, issue.propertyName, updatedSources);
                                    }));
                      }));
                var missingProperties = Belt_Array.map(Belt_MutableMapString.toArray(missingPropertyMap), (function (param) {
                        return {
                                propertyName: param[0],
                                sources: param[1]
                              };
                      }));
                var properties = Belt_Array.keepMapU($$event.properties, (function (property) {
                        var propertySeenInCurrentWindow$1 = propertySeenInCurrentWindow(undefined, property);
                        var maybeModelProperty = Belt_List.getByU(modelPropertiesFromModelEventFoundByEventName, (function (modelProperty) {
                                var possiblePropertyNames = ModelUtils_mapped.getPropertyPossibleNames(modelProperty, maybeModelEvent, mappedRulesByPropertyId);
                                return Belt_List.has(possiblePropertyNames, property.propertyName, (function (name, propertyName) {
                                              return name === propertyName;
                                            }));
                              }));
                        var property$1 = maybeModelProperty !== undefined ? (
                            propertySeenInCurrentWindow$1 ? ({
                                  propertyName: property.propertyName,
                                  sources: Belt_Array.mapU(property.sources, (function (source) {
                                          var filteredIssues = InspectorTrackingPlanUtils.filterInspectorDashboardIssuesWithTrackingPlan(source, maybeModelEvent, maybeModelProperty, model);
                                          var hasSeenSource = Belt_Option.mapWithDefaultU(Belt_Array.getByU($$event.sources, (function (eventSource) {
                                                      return eventSource.sourceId === source.sourceId;
                                                    })), false, (function (eventSource) {
                                                  return eventSource.count.history.total !== 0;
                                                }));
                                          var trackingPlanIssues = getTrackingPlanPropertyIssues(hasSeenSource, maybeModelEvent, model, $$event, source, maybeModelProperty, property);
                                          return {
                                                  sourceId: source.sourceId,
                                                  propertyTypes: source.propertyTypes,
                                                  issues: Belt_Array.concat(filteredIssues, trackingPlanIssues)
                                                };
                                        }))
                                }) : undefined
                          ) : (
                            propertySeenInCurrentWindow$1 ? (
                                maybeModelEvent !== undefined ? ({
                                      propertyName: property.propertyName,
                                      sources: Belt_Array.mapU(property.sources, (function (source) {
                                              var eventSource = Belt_Array.getBy($$event.sources, (function (eventSource) {
                                                      return eventSource.sourceId === source.sourceId;
                                                    }));
                                              if (eventSource === undefined) {
                                                return source;
                                              }
                                              if (eventSource.count.current.total <= 0) {
                                                return source;
                                              }
                                              if (source.propertyTypes.length === 0) {
                                                return source;
                                              }
                                              var trackingPlanIssues = [{
                                                  NAME: "UnexpectedProperty",
                                                  VAL: {
                                                    propertyName: property.propertyName,
                                                    propertyTypes: source.propertyTypes
                                                  }
                                                }];
                                              return {
                                                      sourceId: source.sourceId,
                                                      propertyTypes: source.propertyTypes,
                                                      issues: trackingPlanIssues
                                                    };
                                            }))
                                    }) : property
                              ) : undefined
                          );
                        var mergeSourceIssues = function (sourceA, sourceB) {
                          var mergedIssueSources = Belt_Array.mapU(sourceA, (function (source) {
                                  var bSource = Belt_Array.getByU(sourceB, (function (bSource) {
                                          return bSource.sourceId === source.sourceId;
                                        }));
                                  if (bSource !== undefined) {
                                    return {
                                            sourceId: source.sourceId,
                                            propertyTypes: source.propertyTypes,
                                            issues: Belt_Array.concat(source.issues, bSource.issues)
                                          };
                                  } else {
                                    return source;
                                  }
                                }));
                          var bSourceRest = Belt_Array.keepU(sourceB, (function (source) {
                                  return !Belt_Array.someU(sourceA, (function (aSource) {
                                                return aSource.sourceId === source.sourceId;
                                              }));
                                }));
                          return Belt_Array.concat(mergedIssueSources, bSourceRest);
                        };
                        if (property$1 === undefined) {
                          return ;
                        }
                        var updatedProperty = Belt_Option.map(Belt_Array.getBy(missingProperties, (function (missingProperty) {
                                    return property$1.propertyName === missingProperty.propertyName;
                                  })), (function (missingProperty) {
                                return {
                                        propertyName: property$1.propertyName,
                                        sources: mergeSourceIssues(property$1.sources, missingProperty.sources)
                                      };
                              }));
                        return Belt_Option.getWithDefault(updatedProperty, property$1);
                      }));
                var missingProperties$1 = Belt_Array.keep(missingProperties, (function (missingProperty) {
                        return !Belt_Array.some(properties, (function (property) {
                                      return property.propertyName === missingProperty.propertyName;
                                    }));
                      }));
                var properties$1 = Belt_Array.concat(properties, missingProperties$1);
                var sources = Belt_Array.mapU($$event.sources, (function (source) {
                        if (maybeModelEvent === undefined) {
                          return {
                                  sourceId: source.sourceId,
                                  lastSeen: source.lastSeen,
                                  count: source.count,
                                  trackingPlanStatus: "EventNotInTrackingPlan"
                                };
                        }
                        var hasPropertyTrackingPlanMismatch = Belt_Array.someU(properties$1, (function (property) {
                                return Belt_Option.mapWithDefaultU(Belt_Array.getByU(property.sources, (function (propertySource) {
                                                  return propertySource.sourceId === source.sourceId;
                                                })), false, (function (source) {
                                              return Belt_Array.someU(source.issues, (function (issue) {
                                                            if (typeof issue !== "object") {
                                                              return false;
                                                            }
                                                            var variant = issue.NAME;
                                                            if (variant === "PropertyTypeInconsistentWithTrackingPlan" || variant === "MissingExpectedProperty") {
                                                              return true;
                                                            } else {
                                                              return variant === "PropertyNotInTrackingPlan";
                                                            }
                                                          }));
                                            }));
                              }));
                        return {
                                sourceId: source.sourceId,
                                lastSeen: source.lastSeen,
                                count: source.count,
                                trackingPlanStatus: hasPropertyTrackingPlanMismatch ? ({
                                      NAME: "EventConflictWithTrackingPlan",
                                      VAL: maybeModelEvent.id
                                    }) : ({
                                      NAME: "EventFoundInTrackingPlan",
                                      VAL: maybeModelEvent.id
                                    })
                              };
                      }));
                return {
                        eventName: $$event.eventName,
                        count: $$event.count,
                        properties: properties$1,
                        sources: sources
                      };
              }));
}

function getWorstPropertyIssue(property) {
  var issues = Belt_Array.concatMany(Belt_Array.mapU(property.sources, (function (source) {
              return source.issues;
            })));
  return Belt_Array.reduceU(issues, [
                undefined,
                0
              ], (function (param, issue) {
                  var oldBadness = param[1];
                  var newBadness;
                  if (typeof issue === "object") {
                    var variant = issue.NAME;
                    newBadness = variant === "PropertyNotInTrackingPlan" ? 3 : (
                        variant === "UnexpectedProperty" ? 2 : (
                            variant === "PropertyTypeInconsistentWithTrackingPlan" ? 1 : 0
                          )
                      );
                  } else {
                    newBadness = 0;
                  }
                  if (newBadness > oldBadness) {
                    return [
                            issue,
                            newBadness
                          ];
                  } else {
                    return [
                            param[0],
                            oldBadness
                          ];
                  }
                }))[0];
}

function getWorstPropertyIssue_(property) {
  return Belt_Array.reduceU(Belt_Array.concatMany(Belt_Array.mapU(Belt_MapString.valuesToArray(property.issues), (function (param) {
                          return param.issues;
                        }))), [
                undefined,
                0
              ], (function (param, issue) {
                  var oldBadness = param[1];
                  var newBadness;
                  if (typeof issue === "number") {
                    newBadness = 0;
                  } else {
                    switch (issue.TAG | 0) {
                      case /* MissingExpectedProperty */0 :
                          newBadness = 3;
                          break;
                      case /* PropertyTypeInconsistentWithTrackingPlan */1 :
                          newBadness = 1;
                          break;
                      case /* UnexpectedProperty */2 :
                          newBadness = 2;
                          break;
                      case /* InconsistentType */3 :
                          newBadness = 0;
                          break;
                      
                    }
                  }
                  if (newBadness > oldBadness) {
                    return [
                            issue,
                            newBadness
                          ];
                  } else {
                    return [
                            param[0],
                            oldBadness
                          ];
                  }
                }))[0];
}

function isUnexpectedPropertyOnAllSources(property) {
  return Belt_Array.every(property.sources, (function (source) {
                return Belt_Array.someU(source.issues, (function (issue) {
                              if (typeof issue === "object") {
                                return issue.NAME === "UnexpectedProperty";
                              } else {
                                return false;
                              }
                            }));
              }));
}

function isUnexpectedPropertyOnAllSources_(property) {
  return Belt_MapString.everyU(property.issues, (function (_key, param) {
                return Belt_Array.someU(param.issues, (function (issue) {
                              if (typeof issue === "number" || issue.TAG !== /* UnexpectedProperty */2) {
                                return false;
                              } else {
                                return true;
                              }
                            }));
              }));
}

function useQueryEnvironment(highestEnvironmentWithData) {
  var getEnvironmentFromQuery = function (maybeEnvironment) {
    return Belt_Option.getWithDefault(Belt_Option.flatMap(maybeEnvironment, InspectorViewModel.environmentFromJs), highestEnvironmentWithData);
  };
  return Hooks.useUrlQueryParam("environment", getEnvironmentFromQuery, (function (environment) {
                return InspectorViewModel.environmentToJs(environment);
              }));
}

function useQueryTimeWindow(param) {
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var getTimeWindowFromQuery = function (maybeTimeWindow) {
    var maybeTimeWindowFromQuery = Belt_Option.flatMap(maybeTimeWindow, InspectorViewModel.timeWindowFromJs);
    var timeWindowAvailable = function (timeWindow) {
      return AvoLimits.InspectorLookback.computeAvailability(workspace, timeWindow) !== "Unavailable";
    };
    if (maybeTimeWindowFromQuery !== undefined) {
      if (timeWindowAvailable(maybeTimeWindowFromQuery)) {
        return maybeTimeWindowFromQuery;
      } else {
        return "Last1Hour";
      }
    } else if (timeWindowAvailable("Last24Hours")) {
      return "Last24Hours";
    } else {
      return "Last1Hour";
    }
  };
  return Hooks.useUrlQueryParam("timeWindow", getTimeWindowFromQuery, (function (timeWindow) {
                return InspectorViewModel.timeWindowToJs(timeWindow);
              }));
}

function getEnvsSeen(stats) {
  return Belt_Array.concatMany(Belt_Array.mapU(stats, (function (sourceStat) {
                    return Belt_Array.map(sourceStat.envs, (function (env) {
                                  return env.env;
                                }));
                  })));
}

function getHighestEnvironmentWithData(stats) {
  var envsSeen = getEnvsSeen(stats);
  if (Belt_Array.some(envsSeen, (function (env) {
            return env === "prod";
          }))) {
    return "Production";
  } else if (Belt_Array.some(envsSeen, (function (env) {
            return env === "dev";
          })) || !Belt_Array.some(envsSeen, (function (env) {
            return env === "staging";
          }))) {
    return "Development";
  } else {
    return "Staging";
  }
}

function hasInspectorData(stats, environment) {
  var envsSeen = getEnvsSeen(stats);
  if (environment === "Development") {
    return Belt_Array.some(envsSeen, (function (e) {
                  return e === "dev";
                }));
  } else if (environment === "Production") {
    return Belt_Array.some(envsSeen, (function (e) {
                  return e === "prod";
                }));
  } else {
    return Belt_Array.some(envsSeen, (function (e) {
                  return e === "staging";
                }));
  }
}

export {
  propertySeenInCurrentWindow ,
  getMissingProperties ,
  getTrackingPlanPropertyIssues ,
  combinePropertySources ,
  combineProperties ,
  combineVersions ,
  combineSeenVersions ,
  combineSources ,
  combineEvents ,
  enrichInspectorDashboardEventsWithTrackingPlan ,
  getWorstPropertyIssue ,
  getWorstPropertyIssue_ ,
  isUnexpectedPropertyOnAllSources ,
  isUnexpectedPropertyOnAllSources_ ,
  useQueryEnvironment ,
  useQueryTimeWindow ,
  getEnvsSeen ,
  getHighestEnvironmentWithData ,
  hasInspectorData ,
  
}
/* Hooks Not a pure module */
