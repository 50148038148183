// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as $$Number from "../Number.mjs";
import * as Styles from "../styles.mjs";
import * as Markdown from "../Markdown.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProductTour__Utils from "./ProductTour__Utils.mjs";
import ReactRichText from "@dopt/react-rich-text";
import * as ProductTour__StepProvider from "./ProductTour__StepProvider.mjs";

function ProductTour__GenericModal(Props) {
  var id = Props.id;
  var match = ProductTour__StepProvider.use(undefined);
  var transition = match.transition;
  var block = match.block;
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.padding(Css.px(48)),
          tl: {
            hd: Css.paddingTop(Css.px(72)),
            tl: {
              hd: Css.wordWrap("breakWord"),
              tl: /* [] */0
            }
          }
        })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("div", tmp, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingBottom(Css.px(8)),
                        tl: /* [] */0
                      })
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Huge",
                      color: Styles.Color.light11,
                      children: ProductTour__Utils.extractField(block, "title")
                    })), React.createElement($$Text.make, {
                  color: Styles.Color.light11,
                  children: Belt_Array.map(ProductTour__Utils.gatherBodyFields(block), (function (source) {
                          if (typeof source !== "object") {
                            return null;
                          }
                          var variant = source.NAME;
                          if (variant === "string") {
                            return React.createElement(Markdown.make, {
                                        source: source.VAL
                                      });
                          } else if (variant === "number") {
                            return $$Number.toLocaleString(source.VAL);
                          } else if (variant === "richText") {
                            return React.createElement(ReactRichText, {
                                        children: source.VAL
                                      });
                          } else {
                            return Pervasives.string_of_bool(source.VAL);
                          }
                        }))
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("row"),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: {
                              hd: Css.gap(Css.px(16)),
                              tl: {
                                hd: Css.paddingTop(Css.px(32)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, Belt_Array.map(match.transitions, (function (param) {
                        var key = param.key;
                        var tmp = {
                          onClick: (function (param) {
                              return Curry._1(transition, key);
                            }),
                          size: "large",
                          key: key
                        };
                        if (param.icon !== undefined) {
                          tmp.icon = Caml_option.valFromOption(param.icon);
                        }
                        if (param.label !== undefined) {
                          tmp.label = param.label;
                        }
                        return React.createElement(Button.make, tmp);
                      }))));
}

var Utils;

var StepProvider;

var make = ProductTour__GenericModal;

export {
  Utils ,
  StepProvider ,
  make ,
  
}
/* Css Not a pure module */
