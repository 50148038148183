// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconRetention(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: /* [] */0
                  }),
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", undefined, React.createElement("path", {
                      d: "M15.7,7.9L13.8,6c-0.4-0.4-1-0.4-1.4,0l-1.5,1.5l3.3,3.3l1.5-1.5C16.2,8.8,15.9,8.1,15.7,7.9z "
                    }), React.createElement("path", {
                      d: "M8.5,5.1L10,3.6c0.4-0.4,0.4-1,0-1.4L8.1,0.3c-0.4-0.4-1-0.4-1.4,0L5.2,1.8L8.5,5.1z"
                    }), React.createElement("path", {
                      d: "M9.5,8.9l-1.9,1.9c-0.7,0.7-1.7,0.7-2.4,0c-0.7-0.7-0.7-1.7,0-2.4l1.9-1.9L3.8,3.2L1.9,5.1 C0.7,6.3,0,7.9,0,9.6s0.7,3.3,1.9,4.5c1.2,1.2,5.6,3.5,9.1,0l1.9-1.9L9.5,8.9z"
                    })));
}

var make = IconRetention;

export {
  make ,
  
}
/* Css Not a pure module */
