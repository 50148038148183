// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "./IconX.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "date-fns";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Dom_storage from "rescript/lib/es6/dom_storage.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var expiryDate = new Date("2024-10-14T00:00:00.000Z");

var bannerColor = Styles.Color.darkGreen;

var path = "/blog/what-we-shipped-this-week-for-data-quality-at-scale";

var href = "https://www.avo.app/blog/what-we-shipped-this-week-for-data-quality-at-scale";

var rootStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(8)),
        tl: {
          hd: Css.left(Css.px(8)),
          tl: {
            hd: Css.right(Css.px(8)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.zIndex(Styles.ZIndex.aboveAll + 1 | 0),
                    tl: {
                      hd: Css.pointerEvents("none"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function announcementStyles(visibility) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.backgroundColor(bannerColor),
                    tl: {
                      hd: Css.borderRadius(Css.px(100)),
                      tl: {
                        hd: Css.paddingTop(Css.px(16)),
                        tl: {
                          hd: Css.paddingRight(Css.px(20)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(16)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(32)),
                              tl: {
                                hd: Css.pointerEvents("auto"),
                                tl: {
                                  hd: Css.opacity(visibility === /* Visible */2 ? 1.0 : 0.0),
                                  tl: {
                                    hd: Css.transform(Css.translateY(Css.px(visibility === /* Visible */2 ? 0 : -60))),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: 300.0
                                          }, undefined, visibility === /* Entering */1 ? ({
                                                NAME: "cubicBezier",
                                                VAL: [
                                                  0,
                                                  0.8,
                                                  0.9,
                                                  1
                                                ]
                                              }) : "easeIn", "all"),
                                      tl: {
                                        hd: Css.maxWidth(Css.px(600)),
                                        tl: {
                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(8), Css.px(32), undefined, undefined, Styles.Color.setAlpha(bannerColor, 0.5))),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var linkStyles = Curry._1(Css.style, {
      hd: Css.textDecoration("none"),
      tl: {
        hd: Css.color(Styles.Color.white),
        tl: /* [] */0
      }
    });

var underlinedLinkStyles = Curry._1(Css.style, {
      hd: Css.fontWeight($$Text.getFontWeight("Bold")),
      tl: {
        hd: Css.textDecoration("none"),
        tl: {
          hd: Css.textDecoration("underline"),
          tl: /* [] */0
        }
      }
    });

var closeButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.height(Css.px(32)),
                      tl: {
                        hd: Css.width(Css.px(32)),
                        tl: {
                          hd: Css.marginLeft(Css.px(8)),
                          tl: {
                            hd: Css.borderRadius(Css.px(16)),
                            tl: {
                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.white),
                              tl: {
                                hd: Css.backgroundColor("transparent"),
                                tl: {
                                  hd: Css.cursor("pointer"),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "background-color"),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.2)),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function Announcement(Props) {
  var match = React.useState(function () {
        return /* Hidden */0;
      });
  var setVisibility = match[1];
  var visibility = match[0];
  var schemaBundle = SchemaBundleContext.use(undefined);
  React.useEffect((function () {
          var hasDismissed = Dom_storage.getItem(AnalyticsRe.overlayItemNameToJs("LaunchWeekSeptember2024"), localStorage);
          var isNotExpired = DateFns.isFuture(expiryDate);
          if (!(Belt_Option.isNone(hasDismissed) && isNotExpired)) {
            return ;
          }
          AnalyticsRe.overlayItemDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Banner", "LaunchWeekSeptember2024", "Announcement", schemaBundle.branchId, schemaBundle.schemaId);
          Curry._1(setVisibility, (function (param) {
                  return /* Entering */1;
                }));
          var timeoutId = setTimeout((function (param) {
                  return Curry._1(setVisibility, (function (param) {
                                return /* Visible */2;
                              }));
                }), 300);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), []);
  React.useEffect((function () {
          if (visibility !== /* Leaving */3) {
            return ;
          }
          var timeoutId = setTimeout((function (param) {
                  return Curry._1(setVisibility, (function (param) {
                                return /* Hidden */0;
                              }));
                }), 300);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), [visibility]);
  if (visibility !== 0) {
    return React.createElement("div", {
                className: rootStyles
              }, React.createElement("aside", {
                    className: announcementStyles(visibility)
                  }, React.createElement("a", {
                        className: linkStyles,
                        href: href,
                        rel: "noopener",
                        target: "_blank",
                        onClick: (function (param) {
                            var linkName = "Announcement";
                            AnalyticsRe.overlayItemInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ClickLink", linkName, "Banner", "LaunchWeekSeptember2024", "Announcement", schemaBundle.branchId, schemaBundle.schemaId);
                            AnalyticsRe.promoBannerInteracted("InMinusapp", path, Router.getPathName(undefined), "ClickLink");
                            
                          })
                      }, React.createElement("div", undefined, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                children: "Missed our latest Launch Week updates?"
                              }), React.createElement(Spacer.make, {
                                height: 4
                              }), React.createElement("span", {
                                className: underlinedLinkStyles
                              }, React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Regular",
                                    children: "Catch up on everything new in Avo ->"
                                  }))), React.createElement(Spacer.make, {
                            width: 16
                          })), React.createElement("button", {
                        className: closeButtonStyles,
                        onClick: (function (param) {
                            AnalyticsRe.overlayItemInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Dismiss", undefined, "Banner", "LaunchWeekSeptember2024", "Announcement", schemaBundle.branchId, schemaBundle.schemaId);
                            AnalyticsRe.promoBannerInteracted("InMinusapp", path, Router.getPathName(undefined), "Dismiss");
                            Dom_storage.setItem(AnalyticsRe.overlayItemNameToJs("LaunchWeekSeptember2024"), "dismissed", localStorage);
                            return Curry._1(setVisibility, (function (param) {
                                          return /* Leaving */3;
                                        }));
                          })
                      }, React.createElement(IconX.make, {}))));
  } else {
    return null;
  }
}

var announcementName = "LaunchWeekSeptember2024";

var make = Announcement;

export {
  announcementName ,
  expiryDate ,
  bannerColor ,
  path ,
  href ,
  rootStyles ,
  announcementStyles ,
  linkStyles ,
  underlinedLinkStyles ,
  closeButtonStyles ,
  make ,
  
}
/* expiryDate Not a pure module */
