// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function getValueInOrderOfImportance(a, b) {
  if (a !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(a));
  } else if (b !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(b));
  } else {
    return ;
  }
}

function getNameOrEmail(member) {
  return getValueInOrderOfImportance(member.name, member.email);
}

export {
  getValueInOrderOfImportance ,
  getNameOrEmail ,
  
}
/* No side effect */
