// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PlatformIcon from "./PlatformIcon.mjs";
import * as PlanLightning from "./PlanLightning.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light03),
            tl: {
              hd: Css.paddingTop(Css.px(8)),
              tl: {
                hd: Css.paddingRight(Css.px(16)),
                tl: {
                  hd: Css.paddingBottom(Css.px(8)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(12)),
                    tl: {
                      hd: Css.lastChild({
                            hd: Css.borderBottomWidth(Css.px(0)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(10)),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function DestinationSource(Props) {
  var source = Props.source;
  var destination = Props.destination;
  var globalSend = Props.globalSend;
  var onChange = Props.onChange;
  var availability = Props.availability;
  var destinationType = destination.type_;
  var isSupported = destinationType !== undefined ? Belt_Array.some(AvoConfig.getSupportedDestinations(source.platform, source.language), (function (destination) {
            return destination === destinationType;
          })) : false;
  var platform = source.platform;
  var tmp;
  if (isSupported) {
    tmp = availability === "AvailableDuringTrial" || availability === "Unavailable" ? React.createElement("span", {
            className: Curry._1(Css.style, {
                  hd: Css.cursor("pointer"),
                  tl: {
                    hd: Css_Legacy_Core.SVG.fill("transparent"),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                      tl: /* [] */0
                    }
                  }
                }),
            onClick: (function (param) {
                return Curry._1(globalSend, {
                            TAG: /* OpenModal */4,
                            _0: {
                              NAME: "BillingPrompt",
                              VAL: "ConfigureEventsPerPlatform"
                            }
                          });
              })
          }, React.createElement(PlanLightning.make, {
                tooltipText: availability === "Unavailable" ? "Upgrade Plan to unlock" : "Available during trial"
              })) : null;
  } else {
    var match = source.platform;
    var match$1 = source.language;
    var match$2 = destination.type_;
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.marginLeft(Css.px(8)),
                tl: /* [] */0
              })
        }, React.createElement($$Text.make, {
              size: "Small",
              color: Styles.Color.light10,
              children: match$2 !== undefined ? (
                  match$1 !== undefined && match !== undefined ? AvoConfig.analyticsToolsLabel(match$2) + " is currently not supported on " + AvoConfig.languageLabel(match$1) + " " + AvoConfig.devPlatformLabel(match) : "Finish setting up this source to configure it"
                ) : "You must select the analytics tool before configuring sources"
            }));
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(PlatformIcon.make, {
                  platform: source.platform,
                  sizeWithBorder: 32,
                  backgroundColor: Styles.Color.white,
                  borderColor: Styles.Color.light04
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(12)),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(Link.make, {
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "source",
                            VAL: [
                              source.id,
                              "overview"
                            ]
                          }),
                      className: Curry._1(Css.style, {
                            hd: Css.color(Styles.Color.light12),
                            tl: {
                              hd: Css.whiteSpace("nowrap"),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.color(Styles.Color.light11),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }),
                      children: React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            children: AvoConfig.getSourceName(source)
                          })
                    }), platform !== undefined ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.marginRight(Css.px(8)),
                              tl: /* [] */0
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light10,
                            children: AvoConfig.devPlatformLabel(platform)
                          })) : null), tmp, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(12)),
                        tl: /* [] */0
                      })
                }), React.createElement(Switch.make, {
                  value: Belt_List.has(Belt_List.mapU(source.destinations, (function (param) {
                              return param.destinationId;
                            })), destination.id, (function (prim0, prim1) {
                          return prim0 === prim1;
                        })) && isSupported,
                  onChange: (function (isSelected) {
                      if (availability === "AvailableDuringTrial" || availability === "Available") {
                        return Curry._1(onChange, isSelected);
                      } else {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: {
                                      NAME: "BillingPrompt",
                                      VAL: "ConfigureEventsPerPlatform"
                                    }
                                  });
                      }
                    }),
                  faded: availability === "Unavailable",
                  disabled: !isSupported
                }));
}

var make = DestinationSource;

export {
  rootStyles ,
  make ,
  
}
/* rootStyles Not a pure module */
