// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Input from "./Input.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as IconLock from "./IconLock.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";

function parseTokenResponse(json) {
  return {
          ok: Json_decode.field("ok", Json_decode.bool, json),
          token: Json_decode.field("token", Json_decode.string, json)
        };
}

function AccessTokenModal(Props) {
  var model = Props.model;
  var integrationId = Props.integrationId;
  var onClose = Props.onClose;
  var onEncryptedToken = Props.onEncryptedToken;
  var workspace = WorkspaceContext.use(undefined);
  var match = React.useState(function () {
        return "";
      });
  var setToken = match[1];
  var token = match[0];
  var match$1 = React.useState(function () {
        return "Initial";
      });
  var setStatus = match$1[1];
  var integrationConfig = ModelUtils.getIntegrationConfigById(integrationId, model);
  var tmp;
  if (integrationConfig !== undefined) {
    switch (integrationConfig.TAG | 0) {
      case /* Lexicon */2 :
          tmp = "Secret";
          break;
      case /* MParticleDataMaster */5 :
          tmp = "Client Secret";
          break;
      default:
        tmp = "Access Token";
    }
  } else {
    tmp = "Access Token";
  }
  var tmp$1;
  if (integrationConfig !== undefined) {
    switch (integrationConfig.TAG | 0) {
      case /* Lexicon */2 :
          tmp$1 = "Secret...";
          break;
      case /* MParticleDataMaster */5 :
          tmp$1 = "client-secret...";
          break;
      default:
        tmp$1 = "access-token...";
    }
  } else {
    tmp$1 = "access-token...";
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(300)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.padding4(Css.px(35), Css.px(30), Css.px(10), Css.px(30)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.cursor("default"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin4(Css.px(-100), "auto", Css.px(5), "auto"),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(IconLock.make, {
                      size: 100
                    })), React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      Curry._1(setStatus, (function (param) {
                              return "Loading";
                            }));
                      $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (idToken) {
                                      return fetch(Firebase.apiUrl + "/integration/token", Fetch.RequestInit.make(/* Post */2, [
                                                        [
                                                          "Accept",
                                                          "application/json"
                                                        ],
                                                        [
                                                          "Content-Type",
                                                          "application/json"
                                                        ],
                                                        [
                                                          "Authorization",
                                                          "Bearer " + idToken
                                                        ]
                                                      ], Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                                                    [
                                                                      "schemaId",
                                                                      workspace.id
                                                                    ],
                                                                    [
                                                                      "integrationId",
                                                                      integrationId
                                                                    ],
                                                                    [
                                                                      "token",
                                                                      token
                                                                    ]
                                                                  ]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                                    }).then(function (prim) {
                                    return prim.json();
                                  }).then(function (json) {
                                  return Promise.resolve(parseTokenResponse(json));
                                }).then(function (param) {
                                Curry._1(onClose, undefined);
                                return Promise.resolve(Curry._1(onEncryptedToken, param.token));
                              }), (function (param) {
                              return Promise.resolve(Curry._1(setStatus, (function (param) {
                                                return "Error";
                                              })));
                            }));
                      
                    })
                }, React.createElement("label", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.color(Styles.Color.light10),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.small),
                                tl: /* [] */0
                              }
                            }
                          }),
                      htmlFor: "accessToken"
                    }, tmp), React.createElement(Input.make, {
                      value: token,
                      onChange: (function (token) {
                          return Curry._1(setToken, (function (param) {
                                        return token;
                                      }));
                        }),
                      id: "accessToken",
                      autoFocus: true,
                      placeholder: tmp$1
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement(Button.make, {
                      disabled: token === "",
                      label: "Update",
                      loading: match$1[0] === "Loading"
                    })));
}

var make = AccessTokenModal;

export {
  parseTokenResponse ,
  make ,
  
}
/* Css Not a pure module */
