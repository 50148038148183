// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Zustand from "../Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as InspectorViewApi from "./InspectorViewApi.mjs";
import * as InspectorViewModel from "./InspectorViewModel.mjs";
import * as InspectorEventViewModel from "./InspectorEventViewModel.mjs";
import * as InspectorEventViewModelUtils from "./InspectorEventViewModelUtils.mjs";

function makeNewRequestConfig(schemaId, environment, timeWindow) {
  return {
          schemaId: schemaId,
          timestamp: Date.now(),
          environment: environment,
          timeWindow: timeWindow
        };
}

function makeRequestId(param) {
  return param.schemaId + "--#--" + InspectorViewModel.environmentToJs(param.environment) + "--#--" + InspectorViewModel.timeWindowToJs(param.timeWindow) + "--#--" + String(param.timestamp);
}

var Utils = {
  makeNewRequestConfig: makeNewRequestConfig,
  makeRequestId: makeRequestId
};

function cmp(a, b) {
  return Caml.caml_string_compare(makeRequestId(a), makeRequestId(b));
}

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

var empty_eventShapes = [];

var empty = {
  loadingStatus: "initial",
  events: InspectorEventViewModel.empty,
  eventShapes: empty_eventShapes,
  enrichedWithActionId: "",
  isCurrentView: false
};

function empty$1(param) {
  return Belt_Map.make(Comparator);
}

var $$Map = {
  Int: Belt_Map.Int,
  $$String: Belt_Map.$$String,
  Dict: Belt_Map.Dict,
  make: Belt_Map.make,
  isEmpty: Belt_Map.isEmpty,
  has: Belt_Map.has,
  cmpU: Belt_Map.cmpU,
  cmp: Belt_Map.cmp,
  eqU: Belt_Map.eqU,
  eq: Belt_Map.eq,
  findFirstByU: Belt_Map.findFirstByU,
  findFirstBy: Belt_Map.findFirstBy,
  forEachU: Belt_Map.forEachU,
  forEach: Belt_Map.forEach,
  reduceU: Belt_Map.reduceU,
  reduce: Belt_Map.reduce,
  everyU: Belt_Map.everyU,
  every: Belt_Map.every,
  someU: Belt_Map.someU,
  some: Belt_Map.some,
  size: Belt_Map.size,
  toArray: Belt_Map.toArray,
  toList: Belt_Map.toList,
  fromArray: Belt_Map.fromArray,
  keysToArray: Belt_Map.keysToArray,
  valuesToArray: Belt_Map.valuesToArray,
  minKey: Belt_Map.minKey,
  minKeyUndefined: Belt_Map.minKeyUndefined,
  maxKey: Belt_Map.maxKey,
  maxKeyUndefined: Belt_Map.maxKeyUndefined,
  minimum: Belt_Map.minimum,
  minUndefined: Belt_Map.minUndefined,
  maximum: Belt_Map.maximum,
  maxUndefined: Belt_Map.maxUndefined,
  get: Belt_Map.get,
  getUndefined: Belt_Map.getUndefined,
  getWithDefault: Belt_Map.getWithDefault,
  getExn: Belt_Map.getExn,
  remove: Belt_Map.remove,
  removeMany: Belt_Map.removeMany,
  set: Belt_Map.set,
  updateU: Belt_Map.updateU,
  update: Belt_Map.update,
  mergeMany: Belt_Map.mergeMany,
  mergeU: Belt_Map.mergeU,
  merge: Belt_Map.merge,
  keepU: Belt_Map.keepU,
  keep: Belt_Map.keep,
  partitionU: Belt_Map.partitionU,
  partition: Belt_Map.partition,
  split: Belt_Map.split,
  mapU: Belt_Map.mapU,
  map: Belt_Map.map,
  mapWithKeyU: Belt_Map.mapWithKeyU,
  mapWithKey: Belt_Map.mapWithKey,
  getData: Belt_Map.getData,
  getId: Belt_Map.getId,
  packIdData: Belt_Map.packIdData,
  checkInvariantInternal: Belt_Map.checkInvariantInternal,
  empty: empty$1
};

var $$Request = {
  Utils: Utils,
  Comparator: Comparator,
  empty: empty,
  $$Map: $$Map
};

function makeListenerId(param) {
  return param.schemaId + "--#--" + InspectorViewModel.environmentToJs(param.environment) + "--#--" + InspectorViewModel.timeWindowToJs(param.timeWindow) + "--#--";
}

function cmp$1(a, b) {
  return Caml.caml_string_compare(makeListenerId(a), makeListenerId(b));
}

var Comparator$1 = Belt_Id.MakeComparable({
      cmp: cmp$1
    });

function empty$2(param) {
  return Belt_Map.make(Comparator$1);
}

var $$Map$1 = {
  Int: Belt_Map.Int,
  $$String: Belt_Map.$$String,
  Dict: Belt_Map.Dict,
  make: Belt_Map.make,
  isEmpty: Belt_Map.isEmpty,
  has: Belt_Map.has,
  cmpU: Belt_Map.cmpU,
  cmp: Belt_Map.cmp,
  eqU: Belt_Map.eqU,
  eq: Belt_Map.eq,
  findFirstByU: Belt_Map.findFirstByU,
  findFirstBy: Belt_Map.findFirstBy,
  forEachU: Belt_Map.forEachU,
  forEach: Belt_Map.forEach,
  reduceU: Belt_Map.reduceU,
  reduce: Belt_Map.reduce,
  everyU: Belt_Map.everyU,
  every: Belt_Map.every,
  someU: Belt_Map.someU,
  some: Belt_Map.some,
  size: Belt_Map.size,
  toArray: Belt_Map.toArray,
  toList: Belt_Map.toList,
  fromArray: Belt_Map.fromArray,
  keysToArray: Belt_Map.keysToArray,
  valuesToArray: Belt_Map.valuesToArray,
  minKey: Belt_Map.minKey,
  minKeyUndefined: Belt_Map.minKeyUndefined,
  maxKey: Belt_Map.maxKey,
  maxKeyUndefined: Belt_Map.maxKeyUndefined,
  minimum: Belt_Map.minimum,
  minUndefined: Belt_Map.minUndefined,
  maximum: Belt_Map.maximum,
  maxUndefined: Belt_Map.maxUndefined,
  get: Belt_Map.get,
  getUndefined: Belt_Map.getUndefined,
  getWithDefault: Belt_Map.getWithDefault,
  getExn: Belt_Map.getExn,
  remove: Belt_Map.remove,
  removeMany: Belt_Map.removeMany,
  set: Belt_Map.set,
  updateU: Belt_Map.updateU,
  update: Belt_Map.update,
  mergeMany: Belt_Map.mergeMany,
  mergeU: Belt_Map.mergeU,
  merge: Belt_Map.merge,
  keepU: Belt_Map.keepU,
  keep: Belt_Map.keep,
  partitionU: Belt_Map.partitionU,
  partition: Belt_Map.partition,
  split: Belt_Map.split,
  mapU: Belt_Map.mapU,
  map: Belt_Map.map,
  mapWithKeyU: Belt_Map.mapWithKeyU,
  mapWithKey: Belt_Map.mapWithKey,
  getData: Belt_Map.getData,
  getId: Belt_Map.getId,
  packIdData: Belt_Map.packIdData,
  checkInvariantInternal: Belt_Map.checkInvariantInternal,
  empty: empty$2
};

var Listener = {
  Comparator: Comparator$1,
  $$Map: $$Map$1
};

function cmp$2(param, param$1) {
  return Caml_obj.caml_compare([
              param.environment,
              param.timeWindow
            ], [
              param$1.environment,
              param$1.timeWindow
            ]);
}

var Comparator$2 = Belt_Id.MakeComparable({
      cmp: cmp$2
    });

function empty$3(param) {
  return Belt_Map.make(Comparator$2);
}

var $$Map$2 = {
  Int: Belt_Map.Int,
  $$String: Belt_Map.$$String,
  Dict: Belt_Map.Dict,
  make: Belt_Map.make,
  isEmpty: Belt_Map.isEmpty,
  has: Belt_Map.has,
  cmpU: Belt_Map.cmpU,
  cmp: Belt_Map.cmp,
  eqU: Belt_Map.eqU,
  eq: Belt_Map.eq,
  findFirstByU: Belt_Map.findFirstByU,
  findFirstBy: Belt_Map.findFirstBy,
  forEachU: Belt_Map.forEachU,
  forEach: Belt_Map.forEach,
  reduceU: Belt_Map.reduceU,
  reduce: Belt_Map.reduce,
  everyU: Belt_Map.everyU,
  every: Belt_Map.every,
  someU: Belt_Map.someU,
  some: Belt_Map.some,
  size: Belt_Map.size,
  toArray: Belt_Map.toArray,
  toList: Belt_Map.toList,
  fromArray: Belt_Map.fromArray,
  keysToArray: Belt_Map.keysToArray,
  valuesToArray: Belt_Map.valuesToArray,
  minKey: Belt_Map.minKey,
  minKeyUndefined: Belt_Map.minKeyUndefined,
  maxKey: Belt_Map.maxKey,
  maxKeyUndefined: Belt_Map.maxKeyUndefined,
  minimum: Belt_Map.minimum,
  minUndefined: Belt_Map.minUndefined,
  maximum: Belt_Map.maximum,
  maxUndefined: Belt_Map.maxUndefined,
  get: Belt_Map.get,
  getUndefined: Belt_Map.getUndefined,
  getWithDefault: Belt_Map.getWithDefault,
  getExn: Belt_Map.getExn,
  remove: Belt_Map.remove,
  removeMany: Belt_Map.removeMany,
  set: Belt_Map.set,
  updateU: Belt_Map.updateU,
  update: Belt_Map.update,
  mergeMany: Belt_Map.mergeMany,
  mergeU: Belt_Map.mergeU,
  merge: Belt_Map.merge,
  keepU: Belt_Map.keepU,
  keep: Belt_Map.keep,
  partitionU: Belt_Map.partitionU,
  partition: Belt_Map.partition,
  split: Belt_Map.split,
  mapU: Belt_Map.mapU,
  map: Belt_Map.map,
  mapWithKeyU: Belt_Map.mapWithKeyU,
  mapWithKey: Belt_Map.mapWithKey,
  getData: Belt_Map.getData,
  getId: Belt_Map.getId,
  packIdData: Belt_Map.packIdData,
  checkInvariantInternal: Belt_Map.checkInvariantInternal,
  empty: empty$3
};

var Retry = {
  Comparator: Comparator$2,
  $$Map: $$Map$2
};

function getRequestConfigsWithSameParameters(schemaId, environment, timeWindow, requestMap) {
  return Belt_Array.keepU(Belt_Map.keysToArray(requestMap), (function (requestConfig) {
                if (requestConfig.schemaId === schemaId && requestConfig.environment === environment) {
                  return requestConfig.timeWindow === timeWindow;
                } else {
                  return false;
                }
              }));
}

function getLatestRequestConfig(current, schemaId, environment, timeWindow, requestMap) {
  var requestConfigsWithSameParameters = Belt_SortArray.stableSortByU(getRequestConfigsWithSameParameters(schemaId, environment, timeWindow, requestMap), (function (a, b) {
          return Caml.caml_float_compare(b.timestamp, a.timestamp);
        }));
  if (current !== undefined && !current) {
    return Belt_Array.get(requestConfigsWithSameParameters, 0);
  }
  return Belt_Array.getByU(requestConfigsWithSameParameters, (function (requestConfig) {
                return Belt_Option.mapWithDefaultU(Belt_Map.get(requestMap, requestConfig), false, (function (param) {
                              return param.isCurrentView;
                            }));
              }));
}

function getLatestRequest(current, schemaId, environment, timeWindow, requestMap) {
  var request = getLatestRequestConfig(current, schemaId, environment, timeWindow, requestMap);
  if (request !== undefined) {
    return Belt_Map.get(requestMap, request);
  }
  
}

function getLatestSuccessfulRequest(current, includeErroredRequestsOpt, environment, timeWindow, requestMap) {
  var includeErroredRequests = includeErroredRequestsOpt !== undefined ? includeErroredRequestsOpt : false;
  var arg = Belt_Map.keepU(requestMap, (function (_requestConfig, request) {
          var match = request.loadingStatus;
          if (typeof match !== "object") {
            return false;
          }
          var variant = match.NAME;
          if (variant === "error") {
            return includeErroredRequests;
          } else {
            return variant === "success";
          }
        }));
  return function (param) {
    return getLatestRequest(current, param, environment, timeWindow, arg);
  };
}

function addRetry(retries, requestConfig) {
  return Belt_Map.updateU(retries, requestConfig, (function (maybeRetries) {
                return Belt_Option.getWithDefault(maybeRetries, -1) + 1 | 0;
              }));
}

function trimRequests(schemaId, environment, timeWindow, requestMap) {
  var latestSuccessfulCurrentRequest = getLatestSuccessfulRequest(true, undefined, environment, timeWindow, requestMap)(schemaId);
  var latestSuccessfulRequest = getLatestSuccessfulRequest(false, undefined, environment, timeWindow, requestMap)(schemaId);
  if (latestSuccessfulCurrentRequest === undefined) {
    return requestMap;
  }
  var match = latestSuccessfulCurrentRequest.loadingStatus;
  if (typeof match !== "object") {
    return requestMap;
  }
  if (match.NAME !== "success") {
    return requestMap;
  }
  if (latestSuccessfulRequest === undefined) {
    return requestMap;
  }
  var match$1 = latestSuccessfulRequest.loadingStatus;
  if (typeof match$1 !== "object") {
    return requestMap;
  }
  if (match$1.NAME !== "success") {
    return requestMap;
  }
  if (latestSuccessfulRequest.isCurrentView) {
    return requestMap;
  }
  var latestTimestamp = match$1.VAL.timestamp;
  var latestSuccessfulRequestConfig = match.VAL;
  return Belt_Map.keepU(requestMap, (function (requestConfig, request) {
                if (requestConfig.environment === environment && requestConfig.timeWindow === timeWindow && !Caml_obj.caml_equal(requestConfig, latestSuccessfulRequestConfig)) {
                  if (request.isCurrentView) {
                    return false;
                  } else {
                    return requestConfig.timestamp >= latestTimestamp;
                  }
                } else {
                  return true;
                }
              }));
}

var StoreConfig = {};

function updateLoadingStatus(state, requestConfig, newloadingStatus) {
  return {
          requests: Belt_Map.updateU(state.requests, requestConfig, (function (maybeRequest) {
                  var init = Belt_Option.getWithDefault(maybeRequest, empty);
                  return {
                          loadingStatus: newloadingStatus,
                          events: init.events,
                          eventShapes: init.eventShapes,
                          enrichedWithActionId: init.enrichedWithActionId,
                          isCurrentView: init.isCurrentView
                        };
                })),
          retries: state.retries,
          listeners: state.listeners,
          fetching: state.fetching,
          registerListener: state.registerListener,
          unregisterListener: state.unregisterListener,
          updateOnModelChange: state.updateOnModelChange,
          refreshCurrentViewData: state.refreshCurrentViewData,
          fetch: state.fetch
        };
}

var ZustandStore = Zustand.MakeStore(StoreConfig);

function registerListener(set, schemaId, environment, timeWindow, listenerId) {
  return set(function (state) {
              return {
                      requests: state.requests,
                      retries: state.retries,
                      listeners: Belt_Map.updateU(state.listeners, {
                            schemaId: schemaId,
                            environment: environment,
                            timeWindow: timeWindow
                          }, (function (maybeListeners) {
                              return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concat(Belt_Option.getWithDefault(maybeListeners, []), [listenerId])));
                            })),
                      fetching: state.fetching,
                      registerListener: state.registerListener,
                      unregisterListener: state.unregisterListener,
                      updateOnModelChange: state.updateOnModelChange,
                      refreshCurrentViewData: state.refreshCurrentViewData,
                      fetch: state.fetch
                    };
            });
}

function unregisterListener(set, get, schemaId, environment, timeWindow, listenerId) {
  var listeners = Curry._1(get, undefined).listeners;
  var newListeners = Belt_Map.updateU(listeners, {
        schemaId: schemaId,
        environment: environment,
        timeWindow: timeWindow
      }, (function (maybeListeners) {
          if (maybeListeners === undefined) {
            return ;
          }
          var newListeners = Belt_SetString.remove(Belt_SetString.fromArray(maybeListeners), listenerId);
          if (Belt_SetString.isEmpty(newListeners)) {
            return ;
          } else {
            return Belt_SetString.toArray(newListeners);
          }
        }));
  var shouldUpdateCurrentRequest = Belt_Option.isNone(Belt_Map.get(newListeners, {
            schemaId: schemaId,
            environment: environment,
            timeWindow: timeWindow
          }));
  return set(function (state) {
              var tmp;
              if (shouldUpdateCurrentRequest) {
                var match = getLatestSuccessfulRequest(false, undefined, environment, timeWindow, state.requests)(schemaId);
                var exit = 0;
                if (match !== undefined) {
                  var match$1 = match.loadingStatus;
                  if (typeof match$1 === "object" && match$1.NAME === "success") {
                    tmp = Belt_Map.updateU(state.requests, match$1.VAL, (function (maybeRequest) {
                            return Belt_Option.mapU(maybeRequest, (function (request) {
                                          return {
                                                  loadingStatus: request.loadingStatus,
                                                  events: request.events,
                                                  eventShapes: request.eventShapes,
                                                  enrichedWithActionId: request.enrichedWithActionId,
                                                  isCurrentView: true
                                                };
                                        }));
                          }));
                  } else {
                    exit = 1;
                  }
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  tmp = trimRequests(schemaId, environment, timeWindow, state.requests);
                }
                
              } else {
                tmp = state.requests;
              }
              return {
                      requests: tmp,
                      retries: state.retries,
                      listeners: newListeners,
                      fetching: state.fetching,
                      registerListener: state.registerListener,
                      unregisterListener: state.unregisterListener,
                      updateOnModelChange: state.updateOnModelChange,
                      refreshCurrentViewData: state.refreshCurrentViewData,
                      fetch: state.fetch
                    };
            });
}

function updateOnModelChange(set, requestConfig, latestActionId, mappedModel) {
  return set(function (state) {
              return {
                      requests: Belt_Map.updateU(state.requests, requestConfig, (function (maybeRequest) {
                              if (maybeRequest === undefined) {
                                return ;
                              }
                              if (maybeRequest.enrichedWithActionId === latestActionId) {
                                return maybeRequest;
                              }
                              var updatedEvents = InspectorEventViewModelUtils.convertShapesToEventViewModel(mappedModel, maybeRequest.eventShapes);
                              return {
                                      loadingStatus: maybeRequest.loadingStatus,
                                      events: updatedEvents,
                                      eventShapes: maybeRequest.eventShapes,
                                      enrichedWithActionId: latestActionId,
                                      isCurrentView: maybeRequest.isCurrentView
                                    };
                            })),
                      retries: state.retries,
                      listeners: state.listeners,
                      fetching: state.fetching,
                      registerListener: state.registerListener,
                      unregisterListener: state.unregisterListener,
                      updateOnModelChange: state.updateOnModelChange,
                      refreshCurrentViewData: state.refreshCurrentViewData,
                      fetch: state.fetch
                    };
            });
}

function refreshCurrentViewData(set, get, schemaId, environment, timeWindow, latestActionId, mappedModel) {
  var latestSuccessfulRequest = getLatestSuccessfulRequest(true, undefined, environment, timeWindow, Curry._1(get, undefined).requests)(schemaId);
  if (latestSuccessfulRequest === undefined) {
    return ;
  }
  var match = latestSuccessfulRequest.loadingStatus;
  if (typeof match !== "object") {
    return ;
  }
  if (match.NAME !== "success") {
    return ;
  }
  var requestConfig = match.VAL;
  return set(function (state) {
              return {
                      requests: Belt_Map.updateU(state.requests, requestConfig, (function (_maybeRequest) {
                              return {
                                      loadingStatus: latestSuccessfulRequest.loadingStatus,
                                      events: Caml_obj.caml_notequal(latestSuccessfulRequest.enrichedWithActionId, latestActionId) ? InspectorEventViewModelUtils.convertShapesToEventViewModel(mappedModel, latestSuccessfulRequest.eventShapes) : latestSuccessfulRequest.events,
                                      eventShapes: latestSuccessfulRequest.eventShapes,
                                      enrichedWithActionId: latestSuccessfulRequest.enrichedWithActionId,
                                      isCurrentView: true
                                    };
                            })),
                      retries: state.retries,
                      listeners: state.listeners,
                      fetching: state.fetching,
                      registerListener: state.registerListener,
                      unregisterListener: state.unregisterListener,
                      updateOnModelChange: state.updateOnModelChange,
                      refreshCurrentViewData: state.refreshCurrentViewData,
                      fetch: state.fetch
                    };
            });
}

function $$fetch(set, get, schemaId, environment, timeWindow, latestActionId, mappedModel) {
  var requestConfig = makeNewRequestConfig(schemaId, environment, timeWindow);
  var match = getLatestRequest(false, schemaId, environment, timeWindow, Curry._1(get, undefined).requests);
  if (match !== undefined && match.loadingStatus === "loading") {
    return ;
  }
  var latestSuccessfulRequest = getLatestSuccessfulRequest(true, undefined, environment, timeWindow, Curry._1(get, undefined).requests)(schemaId);
  set(function (state) {
        var shouldBeCurrentView = Belt_Option.mapWithDefaultU(latestSuccessfulRequest, true, (function (param) {
                return Curry._1(InspectorEventViewModel.size, param.events) < 30;
              }));
        return {
                requests: Belt_Map.set(state.requests, requestConfig, {
                      loadingStatus: "loading",
                      events: InspectorEventViewModel.empty,
                      eventShapes: empty_eventShapes,
                      enrichedWithActionId: "",
                      isCurrentView: shouldBeCurrentView
                    }),
                retries: state.retries,
                listeners: state.listeners,
                fetching: state.fetching,
                registerListener: state.registerListener,
                unregisterListener: state.unregisterListener,
                updateOnModelChange: state.updateOnModelChange,
                refreshCurrentViewData: state.refreshCurrentViewData,
                fetch: state.fetch
              };
      });
  $$Promise.$$catch(InspectorViewApi.getAllEventsShapes(timeWindow, environment).then(function (eventShapes) {
              return [
                      eventShapes,
                      latestSuccessfulRequest
                    ];
            }).then(function (param) {
            var maybeLatestSuccessfulRequest = param[1];
            var eventShapes = param[0];
            var events = InspectorEventViewModelUtils.convertShapesToEventViewModel(mappedModel, eventShapes);
            return set(function (state) {
                        var shouldUpdateLatestRequest = {
                          contents: false
                        };
                        var newRequests = Belt_Map.updateU(state.requests, requestConfig, (function (maybeRequest) {
                                return {
                                        loadingStatus: {
                                          NAME: "success",
                                          VAL: requestConfig
                                        },
                                        events: events,
                                        eventShapes: eventShapes,
                                        enrichedWithActionId: Belt_Option.getWithDefault(latestActionId, ""),
                                        isCurrentView: maybeRequest !== undefined ? (shouldUpdateLatestRequest.contents = !maybeRequest.isCurrentView, maybeRequest.isCurrentView) : false
                                      };
                              }));
                        var tmp;
                        if (maybeLatestSuccessfulRequest !== undefined) {
                          var match = maybeLatestSuccessfulRequest.loadingStatus;
                          if (typeof match === "object" && match.NAME === "success" && shouldUpdateLatestRequest.contents) {
                            var eventShapesByName = Js_dict.fromArray(Belt_Array.mapU(maybeLatestSuccessfulRequest.eventShapes, (function (eventShape) {
                                        return [
                                                eventShape.eventName,
                                                eventShape
                                              ];
                                      })));
                            Belt_Array.forEachU(eventShapes, (function (eventShape) {
                                    var eventName = eventShape.eventName;
                                    var match = Js_dict.get(eventShapesByName, eventName);
                                    if (match !== undefined) {
                                      eventShapesByName[eventName] = eventShape;
                                      return ;
                                    }
                                    
                                  }));
                            var newEvents = Curry._3(InspectorEventViewModel.updateMany, maybeLatestSuccessfulRequest.events, Curry._1(InspectorEventViewModel.keys, events), (function (maybeOldEvent) {
                                    if (maybeOldEvent !== undefined) {
                                      return Belt_Option.mapWithDefaultU(Curry._2(InspectorEventViewModel.get, events, maybeOldEvent.eventName), maybeOldEvent, (function (newEvent) {
                                                    return newEvent;
                                                  }));
                                    }
                                    
                                  }));
                            tmp = Belt_Map.updateU(newRequests, match.VAL, (function (maybeRequest) {
                                    if (maybeRequest !== undefined) {
                                      return {
                                              loadingStatus: maybeRequest.loadingStatus,
                                              events: newEvents,
                                              eventShapes: Js_dict.values(eventShapesByName),
                                              enrichedWithActionId: maybeRequest.enrichedWithActionId,
                                              isCurrentView: maybeRequest.isCurrentView
                                            };
                                    }
                                    
                                  }));
                          } else {
                            tmp = newRequests;
                          }
                        } else {
                          tmp = newRequests;
                        }
                        return {
                                requests: trimRequests(schemaId, environment, timeWindow, tmp),
                                retries: Belt_Map.set(state.retries, requestConfig, 0),
                                listeners: state.listeners,
                                fetching: false,
                                registerListener: state.registerListener,
                                unregisterListener: state.unregisterListener,
                                updateOnModelChange: state.updateOnModelChange,
                                refreshCurrentViewData: state.refreshCurrentViewData,
                                fetch: state.fetch
                              };
                      });
          }), (function (error) {
          set(function (state) {
                return {
                        requests: trimRequests(schemaId, environment, timeWindow, updateLoadingStatus(state, requestConfig, {
                                  NAME: "error",
                                  VAL: requestConfig
                                }).requests),
                        retries: addRetry(state.retries, requestConfig),
                        listeners: state.listeners,
                        fetching: false,
                        registerListener: state.registerListener,
                        unregisterListener: state.unregisterListener,
                        updateOnModelChange: state.updateOnModelChange,
                        refreshCurrentViewData: state.refreshCurrentViewData,
                        fetch: state.fetch
                      };
              });
          return Promise.resolve(undefined);
        }));
  
}

var store = Zustand$1.create(function (set, get) {
      return {
              requests: Belt_Map.make(Comparator),
              retries: Belt_Map.make(Comparator$2),
              listeners: Belt_Map.make(Comparator$1),
              fetching: false,
              registerListener: (function (param, param$1, param$2, param$3) {
                  return registerListener(set, param, param$1, param$2, param$3);
                }),
              unregisterListener: (function (param, param$1, param$2, param$3) {
                  return unregisterListener(set, get, param, param$1, param$2, param$3);
                }),
              updateOnModelChange: (function (param, param$1, param$2) {
                  return updateOnModelChange(set, param, param$1, param$2);
                }),
              refreshCurrentViewData: (function (param, param$1, param$2, param$3, param$4) {
                  return refreshCurrentViewData(set, get, param, param$1, param$2, param$3, param$4);
                }),
              fetch: (function (param, param$1, param$2, param$3, param$4) {
                  return $$fetch(set, get, param, param$1, param$2, param$3, param$4);
                })
            };
    });

export {
  $$Request ,
  Listener ,
  Retry ,
  getRequestConfigsWithSameParameters ,
  getLatestRequestConfig ,
  getLatestRequest ,
  getLatestSuccessfulRequest ,
  addRetry ,
  trimRequests ,
  StoreConfig ,
  updateLoadingStatus ,
  ZustandStore ,
  registerListener ,
  unregisterListener ,
  updateOnModelChange ,
  refreshCurrentViewData ,
  $$fetch ,
  store ,
  
}
/* Comparator Not a pure module */
