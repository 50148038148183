// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as DateFns from "../DateFns.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PromiseExt from "../externals/PromiseExt.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FeatureFlag from "../../../shared/FeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as BeltListExtensions from "../BeltListExtensions.mjs";
import * as ImplementationStatusContext from "../ImplementationStatusContext.mjs";
import * as InspectorImplementationStatus from "../../../inspector-implementation-status/src/InspectorImplementationStatus.mjs";
import * as FetchImplementationStatusModel from "../../../shared/models/FetchImplementationStatusModel.mjs";
import * as MergeInspectorImplementationStatus from "./MergeInspectorImplementationStatus.mjs";
import * as InspectorImplementationStatusResponse from "../../../inspector-implementation-status/src/implementationStatus/InspectorImplementationStatusResponse.mjs";

var FetchImplementationError = /* @__PURE__ */Caml_exceptions.create("FetchImplementationStatus.FetchImplementationError");

function fetchCodegenImplementationStatus(schemaId, branchId, param) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                return fetch(Firebase.apiUrl + "/trackingPlan/invocations", Fetch.RequestInit.make(/* Post */2, [
                                  [
                                    "Accept",
                                    "application/json"
                                  ],
                                  [
                                    "Content-Type",
                                    "application/json"
                                  ],
                                  [
                                    "Authorization",
                                    "Bearer " + token
                                  ]
                                ], Caml_option.some(Belt_Option.getWithDefault(JSON.stringify({
                                              schemaId: schemaId,
                                              branchId: branchId
                                            }), "{}")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
              }).then(function (response) {
              if (response.status >= 400) {
                return Promise.reject({
                            RE_EXN_ID: FetchImplementationError,
                            _1: response.status
                          });
              } else {
                return response.json();
              }
            });
}

function fetchCodegenImplementationStatusUpdate(schemaId, branchId, fromDate, param) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                return fetch(Firebase.apiUrl + "/trackingPlan/invocations/poll", Fetch.RequestInit.make(/* Post */2, [
                                  [
                                    "Accept",
                                    "application/json"
                                  ],
                                  [
                                    "Content-Type",
                                    "application/json"
                                  ],
                                  [
                                    "Authorization",
                                    "Bearer " + token
                                  ]
                                ], Caml_option.some(Belt_Option.getWithDefault(JSON.stringify({
                                              schemaId: schemaId,
                                              branchId: branchId,
                                              fromDate: fromDate.toISOString()
                                            }), "{}")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
              }).then(function (prim) {
              return prim.json();
            });
}

function processCodegenImplementationStatusInitialResponse(response, model) {
  var now = Date.now();
  var response$1 = FetchImplementationStatusModel.Res.parse(response);
  var __x = Belt_List.mapU(Belt_List.fromArray(response$1.invocations), (function (param) {
          return [
                  param[0],
                  param[1],
                  param[2],
                  param[3],
                  now
                ];
        }));
  var events = Js_dict.fromArray(Belt_List.toArray(Belt_List.mapU(BeltListExtensions.group((function (param, param$1) {
                      return Caml.caml_string_compare(param[0], param$1[0]);
                    }), __x), (function (invocations) {
                  var match = Belt_List.headExn(invocations);
                  return [
                          match[0],
                          invocations
                        ];
                }))));
  var sourceInitDict = Js_dict.fromArray(Belt_List.toArray(Belt_List.mapU(model.sources, (function (source) {
                  return [
                          source.id,
                          Belt_MapString.fromArray(Belt_Array.keepMapU(response$1.sources, (function (param) {
                                      if (param.sourceId === source.id) {
                                        return [
                                                param.branchId,
                                                param.lastSeen
                                              ];
                                      }
                                      
                                    })))
                        ];
                }))));
  return [
          events,
          sourceInitDict
        ];
}

function processCodegenImplementationStatusPollEvents(currentEventDict, newInvocations) {
  Belt_Array.forEachU(newInvocations, (function (incomingGroup) {
          var eventInvocations = Belt_Option.getWithDefault(Js_dict.get(currentEventDict, incomingGroup.eventId), /* [] */0);
          var match = Belt_Option.getWithDefault(Belt_List.getByU(eventInvocations, (function (param) {
                      if (incomingGroup.eventId === param[0]) {
                        return incomingGroup.sourceId === param[1];
                      } else {
                        return false;
                      }
                    })), [
                incomingGroup.eventId,
                incomingGroup.sourceId,
                incomingGroup.hash,
                [],
                Date.now()
              ]);
          var invocationGroups = match[3];
          var hash = match[2];
          var sourceId = match[1];
          var eventId = match[0];
          var updatedInvocationGroup = Belt_Option.mapWithDefault(Belt_Array.getByU(invocationGroups, (function (group) {
                      if (group.valid === incomingGroup.valid && group.origin === incomingGroup.origin) {
                        return group.hash === incomingGroup.hash;
                      } else {
                        return false;
                      }
                    })), incomingGroup, (function (group) {
                  return {
                          eventId: eventId,
                          sourceId: sourceId,
                          firstSeen: group.firstSeen,
                          lastSeen: incomingGroup.lastSeen,
                          valid: group.valid,
                          origin: group.origin,
                          count: group.count + incomingGroup.count | 0,
                          hash: group.hash
                        };
                }));
          var updatedEventSourceVersion_3 = Belt_Array.concat(Belt_Array.keepU(invocationGroups, (function (invocationGroup) {
                      return !(invocationGroup.valid === updatedInvocationGroup.valid && invocationGroup.origin === updatedInvocationGroup.origin && invocationGroup.hash === updatedInvocationGroup.hash);
                    })), [updatedInvocationGroup]);
          var updatedEventSourceVersion_4 = Date.now();
          var updatedEventSourceVersion = [
            eventId,
            sourceId,
            hash,
            updatedEventSourceVersion_3,
            updatedEventSourceVersion_4
          ];
          var updatedEventSourceVersions = Belt_List.add(Belt_List.keepU(eventInvocations, (function (param) {
                      return !(eventId === param[0] && sourceId === param[1] && hash === param[2]);
                    })), updatedEventSourceVersion);
          currentEventDict[eventId] = updatedEventSourceVersions;
          
        }));
  return Object.assign({}, currentEventDict);
}

function processCodegenImplementationStatusPollSources(currentSourceDict, newSources) {
  Belt_Array.forEachU(newSources, (function (param) {
          var sourceId = param.sourceId;
          var nextStatus = Belt_MapString.set(Belt_Option.getWithDefault(Js_dict.get(currentSourceDict, sourceId), undefined), param.branchId, param.lastSeen);
          currentSourceDict[sourceId] = nextStatus;
          
        }));
  return Object.assign({}, currentSourceDict);
}

function envToString(env) {
  switch (env) {
    case /* Prod */0 :
        return "prod";
    case /* Dev */1 :
        return "dev";
    case /* Staging */2 :
        return "staging";
    
  }
}

function fetchInspectorImplementationStatus(schemaId, branchId, env, timeWindow, param) {
  var env$1 = envToString(env);
  return $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                        return fetch("/api/inspector/v1/getImplementationStatus?schemaId=" + schemaId + "&branchId=" + branchId + "&env=" + env$1 + "&timeWindow=" + timeWindow, Fetch.RequestInit.make(/* Get */0, [
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + token
                                          ]
                                        ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                      }).then(function (prim) {
                      return prim.json();
                    }).then(InspectorImplementationStatusResponse.decode).then(function (resp) {
                  return {
                          TAG: /* Ok */0,
                          _0: resp
                        };
                }), (function (err) {
                console.error("/getImplementationStatus fetch error", PromiseExt.getErrorMessage(err));
                return Promise.resolve({
                            TAG: /* Error */1,
                            _0: "Insufficient permissions"
                          });
              }));
}

function fetchInspectorImplementationStatusUpdate(schemaId, branchId, env, timeWindow, lastUpdated, currentImplementationStatus, param) {
  var env$1 = envToString(env);
  var since = lastUpdated.valueOf();
  return $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                        return fetch("/api/inspector/v1/getImplementationStatus?schemaId=" + schemaId + "&branchId=" + branchId + "&env=" + env$1 + "&timeWindow=" + timeWindow + "&since=" + String(since), Fetch.RequestInit.make(/* Get */0, [
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + token
                                          ]
                                        ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                      }).then(function (prim) {
                      return prim.json();
                    }).then(InspectorImplementationStatusResponse.decode).then(function (newInspectorResponse) {
                  var currentEvents = currentImplementationStatus ? currentImplementationStatus._0 : InspectorImplementationStatus.empty;
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            env: newInspectorResponse.env,
                            status: Belt_Option.map(newInspectorResponse.status, (function (status) {
                                    return {
                                            lastUpdated: status.lastUpdated,
                                            implementationStatus: {
                                              events: MergeInspectorImplementationStatus.mergeStatus(currentEvents.events, status.implementationStatus.events)
                                            }
                                          };
                                  }))
                          }
                        };
                }), (function (err) {
                console.error("/getImplementationStatus fetch error", PromiseExt.getErrorMessage(err));
                return Promise.resolve({
                            TAG: /* Error */1,
                            _0: "Insufficient permissions"
                          });
              }));
}

function FetchImplementationStatus(Props) {
  var schema = Props.schema;
  var model = Props.model;
  var branchId = Props.branchId;
  var children = Props.children;
  var match = React.useState(function () {
        return "Loading";
      });
  var setStatus = match[1];
  var match$1 = React.useState(function () {
        return {};
      });
  var setEventDict = match$1[1];
  var match$2 = React.useState(function () {
        return {};
      });
  var setSourceDict = match$2[1];
  var lastCodegenFetchTimestamp = React.useRef(undefined);
  var match$3 = React.useState(function () {
        return /* Loading */0;
      });
  var setInspectorImplementationProdStatus = match$3[1];
  var match$4 = React.useState(function () {
        return /* Loading */0;
      });
  var setInspectorImplementationDevStatus = match$4[1];
  var inspectorImplementationDevStatus = match$4[0];
  var match$5 = React.useState(function () {
        return /* Loading */0;
      });
  var setInspectorImplementationStagingStatus = match$5[1];
  var inspectorImplementationStagingStatus = match$5[0];
  var lastInspectorProdFetchTimestamp = React.useRef(undefined);
  var lastInspectorDevFetchTimestamp = React.useRef(undefined);
  var lastInspectorStagingFetchTimestamp = React.useRef(undefined);
  var debouncedModel = Hooks.useDebounced((function (param) {
          return model;
        }), 10000);
  var debouncedModelChange = Hooks.useDebounced((function (param) {
          return model;
        }), 2000);
  var documentVisibility = Hooks.useDocumentVisibility(undefined);
  var hasDemoSpeedBost = FeatureFlag.featureEnabled(schema, "DemoSpeedBoost");
  React.useEffect((function () {
          Curry._1(setStatus, (function (param) {
                  return "Loading";
                }));
          
        }), [debouncedModelChange]);
  React.useEffect((function () {
          Curry._1(setStatus, (function (param) {
                  return "Loading";
                }));
          lastCodegenFetchTimestamp.current = undefined;
          lastInspectorProdFetchTimestamp.current = undefined;
          lastInspectorDevFetchTimestamp.current = undefined;
          lastInspectorStagingFetchTimestamp.current = undefined;
          $$Promise.$$catch(fetchCodegenImplementationStatus(schema.id, branchId, undefined).then(function (response) {
                    var match = processCodegenImplementationStatusInitialResponse(response, model);
                    var sourceInitDict = match[1];
                    var events = match[0];
                    Curry._1(setEventDict, (function (param) {
                            return events;
                          }));
                    Curry._1(setSourceDict, (function (param) {
                            return sourceInitDict;
                          }));
                    Curry._1(setStatus, (function (param) {
                            return "Ready";
                          }));
                    lastCodegenFetchTimestamp.current = Caml_option.some(new Date(response.timestamp));
                    return Promise.resolve(undefined);
                  }), (function (error) {
                  if (error.RE_EXN_ID === FetchImplementationError && error._1 >= 500) {
                    return Promise.resolve(undefined);
                  } else {
                    return Promise.reject(error);
                  }
                }));
          Promise.all([
                  fetchInspectorImplementationStatus(schema.id, branchId, /* Prod */0, ImplementationStatusContext.inspectorProdTimeWindow, undefined),
                  fetchInspectorImplementationStatus(schema.id, branchId, /* Dev */1, ImplementationStatusContext.inspectorDevTimeWindow, undefined),
                  fetchInspectorImplementationStatus(schema.id, branchId, /* Staging */2, ImplementationStatusContext.inspectorDevTimeWindow, undefined)
                ]).then(function (param) {
                var stagingResponse = param[2];
                var devResponse = param[1];
                var prodResponse = param[0];
                if (prodResponse.TAG === /* Ok */0) {
                  var prodResponse$1 = prodResponse._0;
                  Curry._1(setInspectorImplementationProdStatus, (function (param) {
                          return /* Ready */{
                                  _0: Belt_Option.getWithDefault(Belt_Option.map(prodResponse$1.status, (function (status) {
                                              return status.implementationStatus;
                                            })), InspectorImplementationStatus.empty)
                                };
                        }));
                  lastInspectorProdFetchTimestamp.current = Belt_Option.map(prodResponse$1.status, (function (status) {
                          return status.lastUpdated;
                        }));
                } else {
                  Curry._1(setInspectorImplementationProdStatus, (function (implementationStatus) {
                          if (implementationStatus === /* Loading */0) {
                            return /* Ready */{
                                    _0: InspectorImplementationStatus.empty
                                  };
                          } else {
                            return implementationStatus;
                          }
                        }));
                }
                if (devResponse.TAG === /* Ok */0) {
                  var devResponse$1 = devResponse._0;
                  Curry._1(setInspectorImplementationDevStatus, (function (param) {
                          return /* Ready */{
                                  _0: Belt_Option.getWithDefault(Belt_Option.map(devResponse$1.status, (function (status) {
                                              return status.implementationStatus;
                                            })), InspectorImplementationStatus.empty)
                                };
                        }));
                  lastInspectorDevFetchTimestamp.current = Belt_Option.map(devResponse$1.status, (function (status) {
                          return status.lastUpdated;
                        }));
                } else {
                  Curry._1(setInspectorImplementationDevStatus, (function (implementationStatus) {
                          if (implementationStatus === /* Loading */0) {
                            return /* Ready */{
                                    _0: InspectorImplementationStatus.empty
                                  };
                          } else {
                            return implementationStatus;
                          }
                        }));
                }
                if (stagingResponse.TAG === /* Ok */0) {
                  var stagingResponse$1 = stagingResponse._0;
                  Curry._1(setInspectorImplementationStagingStatus, (function (param) {
                          return /* Ready */{
                                  _0: Belt_Option.getWithDefault(Belt_Option.map(stagingResponse$1.status, (function (status) {
                                              return status.implementationStatus;
                                            })), InspectorImplementationStatus.empty)
                                };
                        }));
                  lastInspectorStagingFetchTimestamp.current = Belt_Option.map(stagingResponse$1.status, (function (status) {
                          return status.lastUpdated;
                        }));
                } else {
                  Curry._1(setInspectorImplementationStagingStatus, (function (implementationStatus) {
                          if (implementationStatus === /* Loading */0) {
                            return /* Ready */{
                                    _0: InspectorImplementationStatus.empty
                                  };
                          } else {
                            return implementationStatus;
                          }
                        }));
                }
                return Promise.resolve(undefined);
              });
          
        }), [
        debouncedModel,
        branchId
      ]);
  Hooks.useInterval(hasDemoSpeedBost ? 3000 : (
          documentVisibility === "hidden" ? 900000 : 15000
        ), (function (param) {
          var lastTimestamp = lastCodegenFetchTimestamp.current;
          if (lastTimestamp === undefined) {
            return ;
          }
          var lastTimestamp$1 = Caml_option.valFromOption(lastTimestamp);
          lastCodegenFetchTimestamp.current = undefined;
          $$Promise.$$catch(fetchCodegenImplementationStatusUpdate(schema.id, branchId, lastTimestamp$1, undefined).then(function (response) {
                    var response$1 = FetchImplementationStatusModel.PollRes.parse(response);
                    lastCodegenFetchTimestamp.current = Caml_option.some(response$1.timestamp);
                    Curry._1(setEventDict, (function (eventDict) {
                            return processCodegenImplementationStatusPollEvents(eventDict, response$1.invocations);
                          }));
                    Curry._1(setSourceDict, (function (sourceDict) {
                            return processCodegenImplementationStatusPollSources(sourceDict, response$1.sources);
                          }));
                    return Promise.resolve(undefined);
                  }), (function (error) {
                  console.log("Could not fetch invocations update", error);
                  lastCodegenFetchTimestamp.current = Caml_option.some(lastTimestamp$1);
                  return Promise.resolve(undefined);
                }));
          
        }));
  var match$6 = lastInspectorProdFetchTimestamp.current;
  Hooks.useInterval(hasDemoSpeedBost ? 10000 : (
          documentVisibility === "hidden" ? 1800000 : (
              match$6 !== undefined ? (
                  DateFns.isAfter(Caml_option.valFromOption(match$6), DateFns.subMinutes(30, new Date())) ? 60000 : 300000
                ) : 120000
            )
        ), (function (param) {
          fetchInspectorImplementationStatus(schema.id, branchId, /* Prod */0, ImplementationStatusContext.inspectorProdTimeWindow, undefined).then(function (response) {
                if (response.TAG === /* Ok */0) {
                  var response$1 = response._0;
                  Curry._1(setInspectorImplementationProdStatus, (function (param) {
                          return /* Ready */{
                                  _0: Belt_Option.getWithDefault(Belt_Option.map(response$1.status, (function (status) {
                                              return status.implementationStatus;
                                            })), InspectorImplementationStatus.empty)
                                };
                        }));
                  lastInspectorProdFetchTimestamp.current = Belt_Option.map(response$1.status, (function (status) {
                          return status.lastUpdated;
                        }));
                }
                return Promise.resolve(undefined);
              });
          
        }));
  var match$7 = lastInspectorDevFetchTimestamp.current;
  Hooks.useInterval(hasDemoSpeedBost ? 10000 : (
          documentVisibility === "hidden" ? 900000 : (
              match$7 !== undefined ? 90000 : 120000
            )
        ), (function (param) {
          var lastUpdated = lastInspectorDevFetchTimestamp.current;
          var lastUpdated$1 = lastInspectorStagingFetchTimestamp.current;
          Promise.all([
                  lastUpdated !== undefined ? fetchInspectorImplementationStatusUpdate(schema.id, branchId, /* Dev */1, ImplementationStatusContext.inspectorDevTimeWindow, Caml_option.valFromOption(lastUpdated), inspectorImplementationDevStatus, undefined) : Promise.resolve({
                          TAG: /* Error */1,
                          _0: "Initial dev status fetch has not completed yet"
                        }),
                  lastUpdated$1 !== undefined ? fetchInspectorImplementationStatusUpdate(schema.id, branchId, /* Staging */2, ImplementationStatusContext.inspectorDevTimeWindow, Caml_option.valFromOption(lastUpdated$1), inspectorImplementationStagingStatus, undefined) : Promise.resolve({
                          TAG: /* Error */1,
                          _0: "Initial staging status fetch has not completed yet"
                        })
                ]).then(function (param) {
                var stagingResponse = param[1];
                var devResponse = param[0];
                if (devResponse.TAG === /* Ok */0) {
                  var devResponse$1 = devResponse._0;
                  Curry._1(setInspectorImplementationDevStatus, (function (param) {
                          return /* Ready */{
                                  _0: Belt_Option.getWithDefault(Belt_Option.map(devResponse$1.status, (function (status) {
                                              return status.implementationStatus;
                                            })), InspectorImplementationStatus.empty)
                                };
                        }));
                  lastInspectorDevFetchTimestamp.current = Belt_Option.map(devResponse$1.status, (function (status) {
                          return status.lastUpdated;
                        }));
                }
                if (stagingResponse.TAG === /* Ok */0) {
                  var stagingResponse$1 = stagingResponse._0;
                  Curry._1(setInspectorImplementationStagingStatus, (function (param) {
                          return /* Ready */{
                                  _0: Belt_Option.getWithDefault(Belt_Option.map(stagingResponse$1.status, (function (status) {
                                              return status.implementationStatus;
                                            })), InspectorImplementationStatus.empty)
                                };
                        }));
                  lastInspectorStagingFetchTimestamp.current = Belt_Option.map(stagingResponse$1.status, (function (status) {
                          return status.lastUpdated;
                        }));
                }
                return Promise.resolve(undefined);
              });
          
        }));
  return React.createElement(ImplementationStatusContext.make, {
              implementationStatus: {
                codegen: [
                  match[0],
                  match$1[0],
                  match$2[0]
                ],
                inspector: {
                  implementationProdStatus: match$3[0],
                  implementationDevStatus: inspectorImplementationDevStatus,
                  implementationStagingStatus: inspectorImplementationStagingStatus
                }
              },
              children: children
            });
}

var make = FetchImplementationStatus;

export {
  FetchImplementationError ,
  fetchCodegenImplementationStatus ,
  fetchCodegenImplementationStatusUpdate ,
  processCodegenImplementationStatusInitialResponse ,
  processCodegenImplementationStatusPollEvents ,
  processCodegenImplementationStatusPollSources ,
  envToString ,
  fetchInspectorImplementationStatus ,
  fetchInspectorImplementationStatusUpdate ,
  make ,
  
}
/* Hooks Not a pure module */
