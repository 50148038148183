// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Number from "../Number.mjs";
import * as Styles from "../styles.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as AppVersionsTypes from "../../../shared/models/AppVersionsTypes.mjs";
import * as InspectorIssuesTypes from "../InspectorIssuesTypes.mjs";

function IssuesTableConfig$Pill(Props) {
  var label = Props.label;
  var customStyleOpt = Props.style;
  var customStyle = customStyleOpt !== undefined ? customStyleOpt : "normal";
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.display("inlineFlex"),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
                      tl: {
                        hd: Css.borderRadius(Css.px(100)),
                        tl: {
                          hd: Css.paddingTop(Css.px(2)),
                          tl: {
                            hd: Css.paddingRight(Css.px(8)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(3)),
                              tl: {
                                hd: Css.marginTop(Css.px(1)),
                                tl: {
                                  hd: Css.marginBottom(Css.px(1)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(8)),
                                    tl: {
                                      hd: Css.marginRight(Css.px(3)),
                                      tl: {
                                        hd: Css.width("maxContent"),
                                        tl: {
                                          hd: Css.maxWidth(Css.pct(100.0)),
                                          tl: {
                                            hd: customStyle === "red" ? Css.backgroundColor(Styles.Color.lightRed) : Styles.emptyStyle,
                                            tl: {
                                              hd: customStyle === "red" ? Css.borderColor(Styles.Color.lightRed) : Styles.emptyStyle,
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Tiny",
                  singleLine: true,
                  title: label,
                  children: label
                }));
}

var Pill = {
  make: IssuesTableConfig$Pill
};

function IssuesTableConfig$TableTooltip(Props) {
  var maxWidthOpt = Props.maxWidth;
  var offset = Props.offset;
  var tooltip = Props.tooltip;
  var position = Props.position;
  var arrowSizeOpt = Props.arrowSize;
  var children = Props.children;
  var maxWidth = maxWidthOpt !== undefined ? maxWidthOpt : 280;
  var arrowSize = arrowSizeOpt !== undefined ? arrowSizeOpt : 12;
  var tmp = {
    arrowSize: arrowSize,
    delayIn: 350,
    maxWidth: maxWidth,
    title: "",
    tooltipPadding: 8,
    withAskUsAnything: false,
    withDismiss: false,
    withTitle: false,
    children: null
  };
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  return React.createElement(Tooltip.make, tmp, React.createElement(Tooltip.Target.make, {
                  children: children
                }), React.createElement(Tooltip.Content.make, {
                  children: React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        children: tooltip
                      })
                }));
}

var TableTooltip = {
  make: IssuesTableConfig$TableTooltip
};

function getLatestReleaseImpacted(issue, appVersions) {
  var match = AppVersionsTypes.getLatestVersion(undefined, appVersions, issue.source.id);
  var match$1 = issue.source.impactedVersions;
  if (match$1.length === 0) {
    return ;
  }
  if (match === undefined) {
    return ;
  }
  var latestIssueVersion = Belt_Option.getExn(Belt_Array.get(match$1, 0));
  if (AppVersionsTypes.print(match) === latestIssueVersion.release) {
    return latestIssueVersion.release;
  }
  
}

function getLatestReleasesImpacted(keepUnimpactedVersionsOpt, appVersions, releaseFilter, issue) {
  var keepUnimpactedVersions = keepUnimpactedVersionsOpt !== undefined ? keepUnimpactedVersionsOpt : false;
  if (releaseFilter !== undefined) {
    var latestReleasesByReleaseFilter = Belt_Array.map(AppVersionsTypes.getLatestVersions(releaseFilter === "threeLatestReleases" ? 3 : (
                releaseFilter === "twoLatestReleases" ? 2 : 1
              ), appVersions, issue.source.id), AppVersionsTypes.print);
    var latestRelease = Belt_Array.get(latestReleasesByReleaseFilter, 0);
    var latestReleasesByReleaseFilter$1 = Belt_SetString.fromArray(latestReleasesByReleaseFilter);
    var latestReleaseImpacted = {
      contents: undefined
    };
    var firstVersions = Belt_Array.keep(keepUnimpactedVersions ? issue.source.versions : issue.source.impactedVersions, (function (version) {
            if (Belt_SetString.has(latestReleasesByReleaseFilter$1, version.release)) {
              if (Caml_obj.caml_equal(latestRelease, version.release)) {
                latestReleaseImpacted.contents = version.release;
              }
              return true;
            } else {
              return false;
            }
          }));
    return [
            firstVersions,
            [],
            latestReleaseImpacted.contents
          ];
  }
  var latestReleaseImpacted$1 = getLatestReleaseImpacted(issue, appVersions);
  var firstCount = latestReleaseImpacted$1 !== undefined ? 1 : 3;
  var firstVersions$1 = Belt_Array.slice(issue.source.impactedVersions, 0, firstCount);
  var laterVersions = firstVersions$1.length < issue.source.impactedVersions.length ? Belt_Array.sliceToEnd(issue.source.impactedVersions, firstCount) : [];
  return [
          firstVersions$1,
          laterVersions,
          latestReleaseImpacted$1
        ];
}

function IssuesTableConfig$Releases(Props) {
  var issue = Props.issue;
  var appVersions = Props.appVersions;
  var releaseFilter = Props.releaseFilter;
  var match = getLatestReleasesImpacted(undefined, appVersions, releaseFilter, issue);
  var latestReleaseImpacted = match[2];
  var laterVersions = match[1];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginLeft(Css.px(-8)),
                    tl: {
                      hd: Css.maxWidth(Css.pct(100.0)),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("span", {
                  className: Curry._1(Css.style, Styles.maxLines(2))
                }, Belt_Array.map(match[0], (function (version) {
                        return React.createElement(IssuesTableConfig$Pill, {
                                    label: Caml_obj.caml_equal(latestReleaseImpacted, version.release) ? version.release + " (latest)" : version.release,
                                    key: version.release
                                  });
                      })), Caml_obj.caml_equal(laterVersions, []) ? null : React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("inlineFlex"),
                              tl: /* [] */0
                            })
                      }, React.createElement(IssuesTableConfig$TableTooltip, {
                            maxWidth: 140,
                            tooltip: Belt_Array.map(laterVersions, (function (version) {
                                      return version.release;
                                    })).join(", "),
                            children: React.createElement($$Text.make, {
                                  element: "Span",
                                  size: "Tiny",
                                  singleLine: true,
                                  color: Styles.Color.light09,
                                  children: "+" + String(laterVersions.length) + " more"
                                })
                          }))));
}

var Releases = {
  getLatestReleaseImpacted: getLatestReleaseImpacted,
  getLatestReleasesImpacted: getLatestReleasesImpacted,
  make: IssuesTableConfig$Releases
};

function IssuesTableConfig$Count(Props) {
  var count = Props.count;
  var tooltip = Props.tooltip;
  return React.createElement(IssuesTableConfig$TableTooltip, {
              maxWidth: 140,
              offset: -6,
              tooltip: tooltip,
              children: React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding2(Css.px(8), Css.px(2)),
                          tl: {
                            hd: Css.display("flex"),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        children: $$Number.shortFormat(count)
                      }))
            });
}

var Count = {
  make: IssuesTableConfig$Count
};

function getRowId(issue) {
  return issue.id;
}

var defaultOrder = InspectorIssuesTypes.defaultOrder;

var tableName = "issues-table";

export {
  Pill ,
  TableTooltip ,
  Releases ,
  Count ,
  getRowId ,
  defaultOrder ,
  tableName ,
  
}
/* Css Not a pure module */
