// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as GroupType from "./GroupType.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as PropertyType from "./PropertyType.mjs";
import * as CodegenCommons from "../../codegen/src/CodegenCommons.mjs";

function ImplementEventDoc(Props) {
  var enrichedModel = Props.enrichedModel;
  var eventId = Props.eventId;
  var validSource = Props.validSource;
  var $$event = ModelUtils.getEventById(eventId, enrichedModel);
  if ($$event === undefined) {
    return null;
  }
  var match = validSource.language;
  var inputGroups = match === "swift" || match === "kt" || match === "re_v2" || match === "objc" ? AvoModel.resolvePropertyBundleRefs(enrichedModel.propertyBundles, $$event.propertyBundles) : /* [] */0;
  var inputProperties = CodegenCommons.filterPinnedProperties($$event.directPropertyRefs, enrichedModel.properties, enrichedModel);
  var inputProperties$1 = Belt_List.keepU(AvoModel.resolveProperties(enrichedModel, inputProperties), (function (property) {
          return !Belt_List.someU(inputGroups, (function (group) {
                        return Belt_List.someU(group.properties, (function (propertyId) {
                                      return property.id === propertyId;
                                    }));
                      }));
        }));
  var functionArguments = CodegenCommons.resolveFunctionArguments(enrichedModel, inputProperties$1, $$event);
  var renderProperty = function (uniqueId, propertyName, propertyTypeReactElement, description) {
    var tmp = description === "" ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.color(Styles.Color.light07),
                  tl: /* [] */0
                })
          }, "No description") : React.createElement("div", undefined, description);
    return React.createElement("div", {
                key: uniqueId,
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.minWidth(Css.px(400)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.textAlign("left"),
                          tl: {
                            hd: Css.padding2(Css.px(10), Css.px(15)),
                            tl: {
                              hd: Css.width(Css.px(200)),
                              tl: {
                                hd: Css.flexShrink(0.0),
                                tl: /* [] */0
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.wordBreak("breakAll"),
                              tl: {
                                hd: Css.fontFamily(Styles.FontFamily.polar),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.medium),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, propertyName)), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding2(Css.px(10), Css.px(15)),
                          tl: {
                            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light02),
                            tl: {
                              hd: Css.minWidth(Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexWrap("wrap"),
                                tl: {
                                  hd: Css.alignItems("baseline"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, propertyTypeReactElement), tmp));
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(12), Css.px(4)),
                    tl: /* [] */0
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontFamily(Styles.FontFamily.polar),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.regular),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.medium),
                            tl: /* [] */0
                          }
                        }
                      })
                }, "Avo." + CodegenCommons.eventName(validSource.language, $$event)), (Belt_List.length(inputGroups) + functionArguments.length | 0) > 0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light01),
                            tl: {
                              hd: Css.padding2(Css.px(8), Css.px(0)),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.small),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        })
                  }, "Attributes") : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.margin2(Css.px(16), Css.px(0)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, Belt_List.toArray(Belt_List.mapU(inputGroups, (function (group) {
                            return React.createElement("div", {
                                        key: group.id,
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("flexStart"),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.textAlign("left"),
                                                  tl: {
                                                    hd: Css.padding2(Css.px(10), Css.px(0)),
                                                    tl: {
                                                      hd: Css.width(Css.px(200)),
                                                      tl: {
                                                        hd: Css.flexShrink(0.0),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                })
                                          }, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.wordBreak("breakAll"),
                                                      tl: {
                                                        hd: Css.fontFamily(Styles.FontFamily.polar),
                                                        tl: {
                                                          hd: Css.fontWeight(Styles.FontWeight.medium),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })
                                              }, CodegenCommons.className(validSource.language, group.name)), React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.light11),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })
                                              }, "Property Bundle")), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.padding(Css.px(15)),
                                                  tl: {
                                                    hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light02),
                                                    tl: {
                                                      hd: Css.minWidth(Css.px(0)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })
                                          }, React.createElement("div", undefined, group.description), React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.marginTop(Css.px(10)),
                                                      tl: {
                                                        hd: Css.marginBottom(Css.px(15)),
                                                        tl: /* [] */0
                                                      }
                                                    })
                                              }, Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(Belt_List.keepMapU(group.properties, (function (propertyId) {
                                                                  return ModelUtils.getPropertyById(propertyId, enrichedModel);
                                                                })), (function (property) {
                                                              if (property.TAG === /* PropertyRef */0) {
                                                                return ;
                                                              } else {
                                                                return property._0;
                                                              }
                                                            })), (function (property) {
                                                          return renderProperty(property.id, CodegenCommons.propertyName(validSource.language, property), React.createElement(PropertyType.make, {
                                                                          property: property,
                                                                          event: $$event
                                                                        }), property.description);
                                                        }))))));
                          }))), Belt_Array.mapU(functionArguments, (function (argument) {
                        switch (argument.TAG | 0) {
                          case /* Property */0 :
                              var property = argument._0;
                              return renderProperty(property.id, CodegenCommons.propertyName(validSource.language, property), React.createElement(PropertyType.make, {
                                              property: property,
                                              event: $$event
                                            }), property.description);
                          case /* GroupProperty */1 :
                              var groupProperty = argument._0;
                              return renderProperty(groupProperty.id, CodegenCommons.propertyName(validSource.language, groupProperty), React.createElement(PropertyType.make, {
                                              property: groupProperty,
                                              language: validSource.language,
                                              event: $$event
                                            }), groupProperty.description);
                          case /* AssociateUserGroup */2 :
                              var userGroupType = argument._0;
                              return renderProperty(userGroupType.id, CodegenCommons.groupTypeVarName(validSource.language, "associateUserWith", userGroupType, enrichedModel), React.createElement(GroupType.make, {
                                              model: enrichedModel,
                                              groupType: userGroupType,
                                              idDescription: "to associate current user with"
                                            }), ModelUtils.getGroupTypeDescription(userGroupType.id, enrichedModel));
                          case /* AssociateEventGroup */3 :
                              var eventGroupType = argument._0;
                              return renderProperty(eventGroupType.id, CodegenCommons.groupTypeVarName(validSource.language, "associateEventWith", eventGroupType, enrichedModel), React.createElement(GroupType.make, {
                                              model: enrichedModel,
                                              groupType: eventGroupType,
                                              idDescription: "to associate current log event call with"
                                            }), ModelUtils.getGroupTypeDescription(eventGroupType.id, enrichedModel));
                          case /* UpdateGroup */4 :
                              var groupType = argument._0;
                              return renderProperty(groupType.id, CodegenCommons.groupTypeVarName(validSource.language, "updateGroupPropertiesFor", groupType, enrichedModel), React.createElement(GroupType.make, {
                                              model: enrichedModel,
                                              groupType: groupType,
                                              idDescription: "to update group properties for"
                                            }), ModelUtils.getGroupTypeDescription(groupType.id, enrichedModel));
                          
                        }
                      }))));
}

var make = ImplementEventDoc;

export {
  make ,
  
}
/* Css Not a pure module */
