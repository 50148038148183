// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Workspace from "../../model/src/Workspace.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(t) {
  return Json_encode.object_({
              hd: [
                "schemaId",
                t.schemaId
              ],
              tl: {
                hd: [
                  "plan",
                  Workspace.Instances.planNameToString(t.plan)
                ],
                tl: {
                  hd: [
                    "editors",
                    t.editors
                  ],
                  tl: {
                    hd: [
                      "stripeToken",
                      t.stripeToken
                    ],
                    tl: {
                      hd: [
                        "monthlyInspectorVolume",
                        t.monthlyInspectorVolume
                      ],
                      tl: {
                        hd: [
                          "monthlyImplementers",
                          t.monthlyImplementers
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function decode(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          plan: Json_decode.field("plan", (function (json) {
                  return Workspace.Instances.planNameFromStringExn(Json_decode.string(json));
                }), json),
          stripeToken: Json_decode.field("stripeToken", Json_decode.string, json),
          editors: Json_decode.field("editors", Json_decode.$$int, json),
          monthlyInspectorVolume: Json_decode.field("monthlyInspectorVolume", Json_decode.$$float, json),
          monthlyImplementers: Json_decode.field("monthlyImplementers", Json_decode.$$int, json)
        };
}

var $$Request = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "ok",
                t.ok
              ],
              tl: /* [] */0
            });
}

function decode$1(json) {
  return {
          ok: Json_decode.field("ok", Json_decode.bool, json)
        };
}

var $$Response = {
  encode: encode$1,
  decode: decode$1
};

export {
  $$Request ,
  $$Response ,
  
}
/* Workspace Not a pure module */
