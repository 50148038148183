// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Tag from "./Tag.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "./Markdown.mjs";
import * as Property from "./Property.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as Validate from "../../api/functions/src/Validate.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ContextBox from "./ContextBox.mjs";
import * as GlobalPill from "./GlobalPill.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as PortalMenu from "./PortalMenu.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Collapsible from "./Collapsible.mjs";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as EventTypeIcon from "./EventTypeIcon.mjs";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as PortalTooltip from "./PortalTooltip.mjs";
import * as PropertyInput from "./PropertyInput.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as GroupTypeInput from "./GroupTypeInput.mjs";
import * as SideEffectUtils from "./SideEffectUtils.mjs";
import * as IconQuestionMark from "./IconQuestionMark.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as CollapsedAutoProperties from "./CollapsedAutoProperties.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as ViewerCanEditEventOrEventVariantChildUseCase from "./eventVariants/ViewerCanEditEventOrEventVariantChildUseCase.mjs";

function getJumpTo(sideEffects, nextSideEffects) {
  if (Caml_obj.caml_notequal(sideEffects, nextSideEffects)) {
    return Belt_List.head(Belt_List.keep(nextSideEffects, (function (nextSideEffect) {
                      return !Belt_List.some(sideEffects, (function (currentSideEffect) {
                                    if (typeof currentSideEffect === "object" && currentSideEffect.NAME === "UserProperties" && typeof nextSideEffect === "object" && nextSideEffect.NAME === "UserProperties") {
                                      return true;
                                    } else {
                                      return Caml_obj.caml_equal(currentSideEffect, nextSideEffect);
                                    }
                                  }));
                    })));
  }
  
}

function eventTypesToAnalyticsActionsIncluded(newEventActionsOpt, newUserGroupTypeIdsOpt, newEventGroupTypeIdsOpt, model, $$event) {
  var newEventActions = newEventActionsOpt !== undefined ? Caml_option.valFromOption(newEventActionsOpt) : undefined;
  var newUserGroupTypeIds = newUserGroupTypeIdsOpt !== undefined ? Caml_option.valFromOption(newUserGroupTypeIdsOpt) : undefined;
  var newEventGroupTypeIds = newEventGroupTypeIdsOpt !== undefined ? Caml_option.valFromOption(newEventGroupTypeIdsOpt) : undefined;
  var newEventActions$1 = Belt_Option.getWithDefault(newEventActions, $$event.types);
  var newUserGroupTypeIds$1 = Belt_Option.getWithDefault(newUserGroupTypeIds, Belt_Array.map(AvoModel.resolveGroupTypes($$event.userGroupTypeIdsWithArchive, model.groupTypes), (function (param) {
              return param.id;
            })));
  var newEventGroupTypeIds$1 = Belt_Option.getWithDefault(newEventGroupTypeIds, Belt_Array.map(AvoModel.resolveGroupTypes($$event.eventGroupTypeIdsWithArchive, model.groupTypes), (function (param) {
              return param.id;
            })));
  var resultArray = [];
  var match = AvoModel.getProperties(model, $$event.directPropertyRefs);
  var groupProperties = match.groupProperties;
  if (!Belt_List.hasU(newEventActions$1, "SkipLogEvent", (function (a, b) {
            return a === b;
          }))) {
    resultArray.push("LogEvent");
    if (Caml_obj.caml_notequal(newEventGroupTypeIds$1, [])) {
      resultArray.push("AssociateEventWithGroup");
    }
    
  }
  return Belt_Array.concat(resultArray, Belt_List.toArray(BeltListExtensions.flatMapU(newEventActions$1, (function (eventType) {
                        if (eventType === "Revenue") {
                          return {
                                  hd: "Revenue",
                                  tl: /* [] */0
                                };
                        } else if (eventType === "SkipLogEvent") {
                          return /* [] */0;
                        } else if (eventType === "Identify") {
                          return {
                                  hd: "Identify",
                                  tl: /* [] */0
                                };
                        } else if (eventType === "Alias") {
                          return {
                                  hd: "Alias",
                                  tl: /* [] */0
                                };
                        } else if (eventType === "Page") {
                          return {
                                  hd: "Page",
                                  tl: /* [] */0
                                };
                        } else if (eventType === "Unidentify") {
                          return {
                                  hd: "Unidentify",
                                  tl: /* [] */0
                                };
                        } else {
                          return Belt_List.concat(Caml_obj.caml_notequal(newUserGroupTypeIds$1, []) ? ({
                                          hd: "AssociateUserWithGroup",
                                          tl: /* [] */0
                                        }) : /* [] */0, groupProperties !== /* [] */0 ? ({
                                          hd: "UpdateGroupProperties",
                                          tl: /* [] */0
                                        }) : /* [] */0);
                        }
                      }))));
}

function getSideEffectTooltip(sideEffect) {
  var tooltipSource = typeof sideEffect === "object" ? Tooltips.updateUserProperties : (
      sideEffect === "Revenue" ? Tooltips.trackRevenue : (
          sideEffect === "Identify" ? Tooltips.identifyUser : (
              sideEffect === "Alias" ? "TBD" : (
                  sideEffect === "Page" ? Tooltips.trackPageView : (
                      sideEffect === "Unidentify" ? Tooltips.unidentify : (
                          sideEffect === "LogEvent" ? Tooltips.trackEvent : Tooltips.updateGroups
                        )
                    )
                )
            )
        )
    );
  return React.createElement(Markdown.make, {
              source: tooltipSource
            });
}

function EventSideEffects$UpdateGroups(Props) {
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var $$event = Props.event;
  var model = Props.model;
  var viewerCanEdit = Props.viewerCanEdit;
  var variant = Props.variant;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = AvoModel.getProperties(model, $$event.directPropertyRefs);
  var groupProperties = match.groupProperties;
  var match$1 = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match$1.expectedEventCase;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, $$event.name);
  var namingConvention = ValidationConfigV2.EventsV2.hasBasicNameCasing(match$1.config.events) ? Case.toAnalytics(expectedEventCase) : "Advanced";
  var resolvedUserGroupTypeIds = React.useMemo((function () {
          return Belt_Array.map(AvoModel.resolveGroupTypes($$event.userGroupTypeIdsWithArchive, model.groupTypes), (function (param) {
                        return param.id;
                      }));
        }), [
        AvoModel.resolveGroupTypes($$event.userGroupTypeIdsWithArchive, model.groupTypes),
        model.groupTypes
      ]);
  var sendsToSegment = Belt_List.some(AvoModel.destinationsReceivingEvent(model, $$event), (function (param) {
          return Belt_Option.mapWithDefault(param.type_, false, (function (destinationType) {
                        return destinationType === "Segment";
                      }));
        }));
  var explicitGroupTypeIdsSet = Belt_SetString.fromArray(resolvedUserGroupTypeIds);
  var allGroupTypeIdsFromEventSet = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(groupProperties, (function (param) {
                  var sendAs = param.sendAs;
                  if (typeof sendAs === "number") {
                    return ;
                  } else {
                    return sendAs._0;
                  }
                }))));
  var implicitGroupTypeIds = Belt_SetString.toArray(Belt_SetString.diff(allGroupTypeIdsFromEventSet, explicitGroupTypeIdsSet));
  var fromItem_itemId = $$event.id;
  var fromItem_itemName = $$event.name;
  var fromItem = {
    itemId: fromItem_itemId,
    itemName: fromItem_itemName,
    itemType: "Event"
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding4(Css.px(15), Css.px(15), Css.px(15), Css.px(42)),
                    tl: {
                      hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                      tl: {
                        hd: Css.borderBottomRightRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.flexGrow(1.0),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.paddingBottom(Css.px(5)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.tiny),
                                    tl: {
                                      hd: Css.color(Styles.Color.light10),
                                      tl: {
                                        hd: Css.textTransform("uppercase"),
                                        tl: {
                                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                          tl: {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, "Add User to Groups", React.createElement(Tooltip.WithAnalytics.make, {
                              fromItem: fromItem,
                              size: "small",
                              title: "Add User to Groups",
                              children: React.createElement(Tooltip.Content.make, {
                                    children: React.createElement(Markdown.make, {
                                          source: Tooltips.addUserToGroups
                                        })
                                  })
                            })), React.createElement(Spacer.make, {
                          height: 4
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.width(Css.pct(100)),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexWrap("wrap"),
                                    tl: {
                                      hd: Css.children({
                                            hd: Css.important(Css.margin("zero")),
                                            tl: {
                                              hd: Css.important(Css.marginRight(Css.px(6))),
                                              tl: {
                                                hd: Css.important(Css.marginTop(Css.px(8))),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      tl: {
                                        hd: Css.lastChild({
                                              hd: Css.marginRight("zero"),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, Belt_Array.map(resolvedUserGroupTypeIds, (function (groupTypeId) {
                                return React.createElement(Tag.make, {
                                            tag: Belt_Option.getWithDefault(ModelUtils.getGroupTypeName(groupTypeId, model), groupTypeId).toUpperCase(),
                                            onRemove: (function (param) {
                                                return Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            (function (branch) {
                                                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                return AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", "RemoveAssociateUserWithGroup", undefined, undefined, Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, namingConvention, "ItemDetailsView", $$event.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id, eventTypesToAnalyticsActionsIncluded(undefined, Caml_option.some(Belt_Array.keep(resolvedUserGroupTypeIds, (function (t) {
                                                                                          return t !== groupTypeId;
                                                                                        }))), undefined, model, $$event), $$event.name);
                                                              }),
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "RemoveUserFromGroup",
                                                                  VAL: [
                                                                    $$event.id,
                                                                    groupTypeId
                                                                  ]
                                                                },
                                                                {
                                                                  eventId: $$event.id,
                                                                  eventQuery: $$event.id,
                                                                  groupTypeId: groupTypeId,
                                                                  groupTypeQuery: groupTypeId
                                                                }
                                                              ]]
                                                          ]);
                                              }),
                                            hue: "blue",
                                            key: groupTypeId
                                          });
                              })), sendsToSegment ? Belt_Array.map(implicitGroupTypeIds, (function (groupTypeId) {
                                  return React.createElement(Tag.make, {
                                              tag: Belt_Option.getWithDefault(ModelUtils.getGroupTypeName(groupTypeId, model), groupTypeId).toUpperCase(),
                                              hue: "blue",
                                              tooltip: "Segment always explicitly links users with groups when updating Group Properties. For other destinations the user will not be linked.",
                                              key: groupTypeId
                                            });
                                })) : null))), viewerCanEdit ? React.createElement(GroupTypeInput.make, {
                    currentGroups: Belt_Array.keepMap(resolvedUserGroupTypeIds, (function (groupTypeId) {
                            return Belt_Array.getBy(model.groupTypes, (function (groupType) {
                                          return groupType.id === groupTypeId;
                                        }));
                          })),
                    onCreate: (function (groupTypeId, _groupTypeName) {
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [[
                                        {
                                          NAME: "AddUserToGroup",
                                          VAL: [
                                            $$event.id,
                                            groupTypeId
                                          ]
                                        },
                                        {
                                          eventId: $$event.id,
                                          eventQuery: $$event.id,
                                          groupTypeId: groupTypeId,
                                          groupTypeQuery: groupTypeId
                                        }
                                      ]]
                                  ]);
                      }),
                    onSelect: (function (item, param) {
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                        return AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", "AddAssociateUserWithGroup", undefined, undefined, Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, namingConvention, "ItemDetailsView", $$event.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id, eventTypesToAnalyticsActionsIncluded(undefined, Caml_option.some(Belt_Array.concat(resolvedUserGroupTypeIds, [item.id])), undefined, model, $$event), $$event.name);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "AddUserToGroup",
                                          VAL: [
                                            $$event.id,
                                            item.id
                                          ]
                                        },
                                        {
                                          eventId: $$event.id,
                                          eventQuery: $$event.id,
                                          groupTypeId: item.id,
                                          groupTypeQuery: item.id
                                        }
                                      ]]
                                  ]);
                      }),
                    options: model.groupTypes,
                    renderButton: (function (onClick, buttonRef, _isOpen) {
                        return React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.textAlign("left"),
                                          tl: {
                                            hd: Css.marginTop(Css.px(10)),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement(TextButton.make, {
                                        onClick: onClick,
                                        style: "Blue",
                                        size: "Medium",
                                        children: "+ Add Group Type",
                                        ref: buttonRef
                                      }));
                      }),
                    sendActions: sendActions
                  }) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.flexGrow(1.0),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(10)),
                                tl: {
                                  hd: Css.paddingBottom(Css.px(5)),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.tiny),
                                      tl: {
                                        hd: Css.color(Styles.Color.light10),
                                        tl: {
                                          hd: Css.textTransform("uppercase"),
                                          tl: {
                                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                            tl: {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, "Group Properties", React.createElement(Tooltip.WithAnalytics.make, {
                              fromItem: fromItem,
                              size: "small",
                              title: "Group Properties",
                              children: React.createElement(Tooltip.Content.make, {
                                    children: React.createElement(Markdown.make, {
                                          source: Tooltips.groupProperties
                                        })
                                  })
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.width(Css.pct(100)),
                                tl: /* [] */0
                              })
                        }, Belt_List.toArray(Belt_List.map(Belt_List.sort(groupProperties, (function (a, b) {
                                        var match = a.sendAs;
                                        var match$1 = b.sendAs;
                                        if (typeof match === "number" || typeof match$1 === "number") {
                                          return 1;
                                        } else {
                                          return Caml.caml_string_compare(match._0, match$1._0);
                                        }
                                      })), (function (property) {
                                    return React.createElement(Property.make, {
                                                property: property,
                                                event: $$event,
                                                variant: variant,
                                                slowModelMaybeFlattened: model,
                                                viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant),
                                                key: property.id
                                              });
                                  })))))), viewerCanEdit ? React.createElement(PropertyInput.make, {
                    isGroupProperty: true,
                    currentProperties: $$event.directPropertyRefs,
                    eventName: $$event.name,
                    eventId: $$event.id,
                    options: [[
                        undefined,
                        Belt_List.toArray(Belt_List.keepMapU(model.properties, (function (property) {
                                    if (property.TAG === /* PropertyRef */0) {
                                      return ;
                                    }
                                    var item = property._0;
                                    var groupTypeId = item.sendAs;
                                    if (typeof groupTypeId === "number") {
                                      return ;
                                    } else {
                                      return Belt_Option.map(ModelUtils.getGroupType(groupTypeId._0, model), (function (param) {
                                                    return {
                                                            NAME: "Property",
                                                            VAL: item
                                                          };
                                                  }));
                                    }
                                  })))
                      ]],
                    onSelect: (function (item, param) {
                        if (typeof item !== "object") {
                          return ;
                        }
                        if (item.NAME !== "Property") {
                          return ;
                        }
                        var property = item.VAL;
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [[
                                        {
                                          NAME: "AddPropertyRef",
                                          VAL: [
                                            $$event.id,
                                            property.id
                                          ]
                                        },
                                        {
                                          eventId: $$event.id,
                                          eventQuery: $$event.id,
                                          propertyId: property.id,
                                          propertyQuery: property.id
                                        }
                                      ]]
                                  ]);
                      }),
                    currentFilters: currentFilters,
                    renderButton: (function (onClick, buttonRef, _isOpen) {
                        return React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.textAlign("left"),
                                            tl: {
                                              hd: Css.marginTop(Css.px(10)),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, React.createElement(TextButton.make, {
                                        onClick: onClick,
                                        style: "Blue",
                                        size: "Medium",
                                        disabled: model.groupTypes.length === 0,
                                        children: "+ Add Group Property",
                                        ref: buttonRef
                                      }), React.createElement("span", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, model.groupTypes.length === 0 ? React.createElement(PortalTooltip.make, {
                                              renderTooltip: (function (param) {
                                                  return React.createElement("span", {
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.color(Styles.Color.white),
                                                                    tl: {
                                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                                      tl: {
                                                                        hd: Css.fontWeight(Styles.FontWeight.medium),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  })
                                                            }, "To create Group Properties you must first create a Group Type for the Property, accessible from the Properties screen.");
                                                }),
                                              position: "Bottom",
                                              children: React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.paddingLeft(Css.px(6)),
                                                          tl: {
                                                            hd: Css.marginBottom(Css.px(-4)),
                                                            tl: /* [] */0
                                                          }
                                                        })
                                                  }, React.createElement(IconQuestionMark.make, {
                                                        size: 14,
                                                        color: Styles.Color.light07
                                                      }))
                                            }) : null));
                      }),
                    parent: {
                      NAME: "Event",
                      VAL: $$event.id
                    },
                    sendActions: sendActions,
                    filters: filters,
                    propertyLocation: "EventDetails"
                  }) : null);
}

var UpdateGroups = {
  make: EventSideEffects$UpdateGroups
};

var container = Curry._1(Css.style, {
      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.margin4(Css.px(5), Css.px(0), Css.px(0), Css.px(0)),
          tl: {
            hd: Css.overflow("hidden"),
            tl: /* [] */0
          }
        }
      }
    });

var button = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.textAlign("left"),
              tl: {
                hd: Css.flexGrow(1.0),
                tl: {
                  hd: Css.width(Css.pct(100)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(27)),
                    tl: {
                      hd: Css.paddingTop(Css.px(10)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(10)),
                        tl: {
                          hd: Css.transition({
                                NAME: "ms",
                                VAL: Styles.Duration.$$default
                              }, undefined, undefined, "background-color"),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.backgroundColor(Styles.Color.light02),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function chevron(collapsed) {
  return Curry._1(Css.style, {
              hd: Css.paddingRight(Css.px(1)),
              tl: {
                hd: Css.marginBottom(Css.px(-4)),
                tl: {
                  hd: Css.marginLeft(Css.px(-10)),
                  tl: {
                    hd: collapsed ? Styles.emptyStyle : Css.transform({
                            NAME: "rotateZ",
                            VAL: Css.deg(90.0)
                          }),
                    tl: {
                      hd: Css.transformOrigin(Css.pct(45.0), Css.pct(43.0)),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "transform"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var groupName = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.semi),
      tl: {
        hd: Css.fontSize(Styles.FontSize.regular),
        tl: {
          hd: Css.color(Styles.Color.light12),
          tl: {
            hd: Css.hover({
                  hd: Css.color(Styles.Color.darkBlue),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.selector(" > div:last-child", {
              hd: Css.borderBottomWidth(Css.px(0)),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.paddingLeft(Css.px(27)),
            tl: {
              hd: Css.paddingTop(Css.px(0)),
              tl: {
                hd: Css.paddingBottom(Css.px(4)),
                tl: {
                  hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var Style = {
  container: container,
  button: button,
  chevron: chevron,
  groupName: groupName,
  content: content
};

function EventSideEffects$PropertyBundle(Props) {
  var $$event = Props.event;
  var bundle = Props.bundle;
  var model = Props.model;
  var variant = Props.variant;
  var viewerCanEdit = Props.viewerCanEdit;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        return true;
      });
  var setCollapsed = match[1];
  var collapsed = match[0];
  var globalPropertyBundle = Curry._1(ModelStore.Mapped.GlobalRequirements.useGlobalPropertyBundle, bundle.id);
  return React.createElement("div", {
              className: container
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: /* [] */0
                      })
                }, React.createElement("button", {
                      "aria-label": "Property Bundle: " + bundle.name,
                      "aria-expanded": !collapsed,
                      "aria-controls": bundle.id + "-collapsible",
                      className: button,
                      onClick: (function (param) {
                          return Curry._1(setCollapsed, (function (state) {
                                        return !state;
                                      }));
                        })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.marginLeft(Css.px(-12)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: chevron(collapsed)
                            }, React.createElement(Icon.make, {
                                  type_: "chevronRight",
                                  size: "medium",
                                  color: Styles.Color.light10
                                })), React.createElement(Link.make, {
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "propertyGroup",
                                    VAL: [
                                      bundle.id,
                                      undefined
                                    ]
                                  }),
                              className: groupName,
                              children: bundle.name
                            }), Belt_Option.isSome(globalPropertyBundle) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 8
                                  }), React.createElement(GlobalPill.make, {})) : null, React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light09,
                              children: "Bundle of " + String(Belt_List.length(bundle.properties)) + " Properties"
                            })), React.createElement(Spacer.make, {
                          height: 2
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: bundle.description === "" ? Styles.Color.light08 : Styles.Color.light12,
                          children: bundle.description === "" ? "No description" : bundle.description
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.position("absolute"),
                            tl: {
                              hd: Css.top(Css.px(8)),
                              tl: {
                                hd: Css.right(Css.px(8)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(ContextMenu.make, {
                          options: Belt_Array.concatMany([
                                [{
                                    NAME: "Option",
                                    VAL: {
                                      label: viewerCanEdit ? "Edit Bundle" : "View Bundle",
                                      onClick: (function (param) {
                                          return Router.Schema.pushDrawerItem(undefined, undefined, {
                                                      NAME: "propertyGroup",
                                                      VAL: [
                                                        bundle.id,
                                                        undefined
                                                      ]
                                                    });
                                        })
                                    }
                                  }],
                                viewerCanEdit ? [{
                                      NAME: "Option",
                                      VAL: {
                                        label: "Remove Bundle",
                                        onClick: (function (param) {
                                            return Curry.app(sendActions, [
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        [[
                                                            {
                                                              NAME: "RemovePropertyGroupFromEvent",
                                                              VAL: [
                                                                $$event.id,
                                                                bundle.id
                                                              ]
                                                            },
                                                            {
                                                              eventId: $$event.id,
                                                              eventQuery: $$event.id,
                                                              propertyGroupId: bundle.id,
                                                              propertyGroupQuery: bundle.id
                                                            }
                                                          ]]
                                                      ]);
                                          })
                                      }
                                    }] : []
                              ])
                        }))), React.createElement(Collapsible.make, {
                  id: bundle.id + "-collapsible",
                  collapsed: collapsed,
                  onChange: (function (param) {
                      return AnalyticsRe.collapsibleItemInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, collapsed ? "Collapsed" : "Expanded", "PropertyGroup", "Event");
                    }),
                  children: React.createElement("div", {
                        className: content
                      }, Belt_List.toArray(Belt_List.mapU(Belt_List.keepMap(Belt_List.keepMapU(bundle.properties, (function (bundlePropertyId) {
                                          return Belt_List.getByU(model.properties, (function (modelProperty) {
                                                        if (modelProperty.TAG === /* PropertyRef */0) {
                                                          return false;
                                                        } else {
                                                          return modelProperty._0.id === bundlePropertyId;
                                                        }
                                                      }));
                                        })), (function (wrappedProperty) {
                                      if (wrappedProperty.TAG === /* PropertyRef */0) {
                                        return ;
                                      } else {
                                        return wrappedProperty._0;
                                      }
                                    })), (function (bundleProperty) {
                                  return React.createElement(Property.make, {
                                              property: bundleProperty,
                                              event: $$event,
                                              variant: variant,
                                              slowModelMaybeFlattened: model,
                                              inBundle: true,
                                              viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant),
                                              key: bundleProperty.id
                                            });
                                }))))
                }));
}

var PropertyBundle = {
  Style: Style,
  make: EventSideEffects$PropertyBundle
};

function EventSideEffects(Props) {
  var currentFilters = Props.currentFilters;
  var $$event = Props.event;
  var filters = Props.filters;
  var viewerCanEdit = Props.viewerCanEdit;
  var variant = Props.variant;
  var model = Props.model;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, $$event.name);
  var namingConvention = ValidationConfigV2.EventsV2.hasBasicNameCasing(match.config.events) ? Case.toAnalytics(expectedEventCase) : "Advanced";
  var user = ViewerContext.use(undefined);
  var hidePropertiesAlreadyInBundle = AppFeatureFlag.useFeatureFlag("HidePropertiesAlreadyInBundle");
  var match$1 = React.useState(function () {
        return false;
      });
  var setAddingUserProperties = match$1[1];
  var addingUserProperties = match$1[0];
  var match$2 = React.useState(function () {
        return SideEffectUtils.getEventSideEffects(false, $$event, model);
      });
  var setSideEffects = match$2[1];
  var sideEffects = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setJumpTo = match$3[1];
  var jumpTo = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setShowGlobalNamespacePopup = match$4[1];
  var showGlobalNamespacePopup = match$4[0];
  var lastPropertyRef = React.useRef(null);
  var csharpPlainObjectsAndNullabilityImprovements = AppFeatureFlag.useFeatureFlag("CSharpPlainObjectsAndNullabilityImprovements");
  var handleCloseJustCreatedPopup = function (param) {
    AnalyticsRe.tooltipInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.id, $$event.name, "Event", "Global Namescape Info", "ProductTip", "Automatic", "Dismiss", "Workspace", "Authenticated", schemaBundle.schemaId);
    FirebaseUtils.setGlobalNamespacePopupSeen(undefined);
    return Curry._1(setShowGlobalNamespacePopup, (function (param) {
                  
                }));
  };
  var resolvedUserGroupTypeIds = React.useMemo((function () {
          return Belt_Array.map(AvoModel.resolveGroupTypes($$event.userGroupTypeIdsWithArchive, model.groupTypes), (function (param) {
                        return param.id;
                      }));
        }), [
        AvoModel.resolveGroupTypes($$event.userGroupTypeIdsWithArchive, model.groupTypes),
        model.groupTypes
      ]);
  var resolvedEventGroupTypeIds = React.useMemo((function () {
          return Belt_Array.map(AvoModel.resolveGroupTypes($$event.eventGroupTypeIdsWithArchive, model.groupTypes), (function (param) {
                        return param.id;
                      }));
        }), [
        AvoModel.resolveGroupTypes($$event.eventGroupTypeIdsWithArchive, model.groupTypes),
        model.groupTypes
      ]);
  React.useEffect((function () {
          var nextSideEffects = SideEffectUtils.getEventSideEffects(addingUserProperties, $$event, model);
          Curry._1(setSideEffects, (function (param) {
                  return nextSideEffects;
                }));
          Curry._1(setJumpTo, (function (param) {
                  return getJumpTo(sideEffects, nextSideEffects);
                }));
          
        }), [
        $$event,
        model
      ]);
  var handleAddUserProperties = function (addingUserProperties) {
    Curry._1(setAddingUserProperties, (function (param) {
            return addingUserProperties;
          }));
    var nextSideEffects = SideEffectUtils.getEventSideEffects(addingUserProperties, $$event, model);
    Curry._1(setSideEffects, (function (param) {
            return nextSideEffects;
          }));
    return Curry._1(setJumpTo, (function (param) {
                  return getJumpTo(sideEffects, nextSideEffects);
                }));
  };
  var handleAdd = function (sideEffect) {
    if (typeof sideEffect === "object") {
      if (sideEffect.NAME === "UserProperties") {
        return handleAddUserProperties(true);
      }
      
    } else {
      if (sideEffect === "UpdateGroups") {
        return Curry.app(sendActions, [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    [[
                        {
                          NAME: "AddEventType",
                          VAL: [
                            $$event.id,
                            "UpdateGroups"
                          ]
                        },
                        {
                          eventId: $$event.id,
                          eventQuery: $$event.id
                        }
                      ]]
                  ]);
      }
      if (sideEffect === "LogEvent") {
        return Curry.app(sendActions, [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    (function (branch) {
                        var newEventActions = Belt_List.keep($$event.types, (function (t) {
                                return t !== "SkipLogEvent";
                              }));
                        var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                        return AnalyticsRe.eventTypeUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, $$event.id, $$event.name, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, Belt_List.toArray($$event.types), Belt_List.toArray(newEventActions), $$event.id, NamedBranch.getId(branch), schemaBundle$1.schemaId, $$event.id, eventTypesToAnalyticsActionsIncluded(Caml_option.some(newEventActions), undefined, undefined, model, $$event));
                      }),
                    undefined,
                    [[
                        {
                          NAME: "RemoveEventType",
                          VAL: [
                            $$event.id,
                            "SkipLogEvent"
                          ]
                        },
                        {
                          eventId: $$event.id,
                          eventQuery: $$event.id
                        }
                      ]]
                  ]);
      }
      
    }
    var type_ = SideEffectUtils.sideEffectToEventType(sideEffect);
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    var newEventActions = Belt_List.concat($$event.types, {
                          hd: type_,
                          tl: /* [] */0
                        });
                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                    return AnalyticsRe.eventTypeUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, $$event.id, $$event.name, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, Belt_List.toArray($$event.types), Belt_List.toArray(newEventActions), $$event.id, NamedBranch.getId(branch), schemaBundle$1.schemaId, $$event.id, eventTypesToAnalyticsActionsIncluded(Caml_option.some(newEventActions), undefined, undefined, model, $$event));
                  }),
                undefined,
                [[
                    {
                      NAME: "AddEventType",
                      VAL: [
                        $$event.id,
                        type_
                      ]
                    },
                    {
                      eventId: $$event.id,
                      eventQuery: $$event.id
                    }
                  ]]
              ]);
  };
  var match$5 = Belt_List.partition(sideEffects, (function (x) {
          if (x === "LogEvent" || x === "Page") {
            return true;
          } else {
            return x === "Revenue";
          }
        }));
  var showObjectsSupportWarning = function (sideEffect) {
    var codegenEventSources = Belt_List.toArray(ModelUtils.codegenSorcesForEvent(model, $$event.id));
    var eventObjectErrors = Belt_List.keepU(Belt_List.flatten(Belt_List.mapU(AvoModel.resolveProperties(model, $$event.directPropertyRefs), (function (property) {
                    return Belt_List.fromArray(Validate.validateObjectSupportForProperty(property, codegenEventSources, csharpPlainObjectsAndNullabilityImprovements, model));
                  }))), (function (param) {
            var error = param[1];
            switch (error.TAG | 0) {
              case /* ObjectPropertyNotSupported */12 :
              case /* NameMappingInObjectsNotSupported */15 :
              case /* PinningInObjectsNotSupported */16 :
              case /* NameMappingInListsOfObjectsNotSupported */17 :
              case /* PinningInListsOfObjectsNotSupported */18 :
              case /* NameMappingInNestedObjectsNotSupported */19 :
              case /* PinningInNestedObjectsNotSupported */20 :
                  break;
              default:
                return false;
            }
            var propertySendAs = error.propertySendAs;
            if (typeof propertySendAs === "number") {
              if (propertySendAs >= 2) {
                if (typeof sideEffect === "object") {
                  return sideEffect.NAME === "UserProperties";
                } else {
                  return false;
                }
              } else {
                return sideEffect === "LogEvent";
              }
            } else {
              return sideEffect === "UpdateGroups";
            }
          }));
    if (Belt_List.length(eventObjectErrors) > 0) {
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.color(Styles.Color.orange),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.padding2(Css.px(8), Css.px(0)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexDirection("column"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, "Codegen warning. The following sources don't support some object related functionality in the Codegen:", Belt_List.toArray(Belt_List.mapU(eventObjectErrors, (function (param) {
                            var error = param[1];
                            var source = param[0];
                            var sourceDesription = "- Source \"" + Belt_Option.getWithDefault(source.name, "Untitled source with id " + source.id) + "\"";
                            switch (error.TAG | 0) {
                              case /* ObjectPropertyNotSupported */12 :
                                  return React.createElement("div", {
                                              key: error.propertyId
                                            }, sourceDesription + ": The property \"" + error.propertyName + "\" is of type 'object'. Codegen is currently not supported.");
                              case /* NameMappingInObjectsNotSupported */15 :
                                  return React.createElement("div", {
                                              key: error.nestedPropertyId
                                            }, sourceDesription + ": The property \"" + error.propertyName + "\" is an 'object' and has a nested property \"" + error.nestedPropertyName + "\" name mapping. Codegen is currently not supported.");
                              case /* PinningInObjectsNotSupported */16 :
                                  return React.createElement("div", {
                                              key: error.nestedPropertyId
                                            }, sourceDesription + ": The property \"" + error.propertyName + "\" is an 'object' and has a nested property \"" + error.nestedPropertyName + "\" with a pinned value. Codegen is currently not supported.");
                              case /* NameMappingInListsOfObjectsNotSupported */17 :
                                  return React.createElement("div", {
                                              key: error.nestedPropertyId
                                            }, sourceDesription + ": The property \"" + error.propertyName + "\" is of type 'object' and has a nested property \"" + error.nestedPropertyName + "\" that is a 'list' with name mapping. Codegen is currently not supported.");
                              case /* PinningInListsOfObjectsNotSupported */18 :
                                  return React.createElement("div", {
                                              key: error.nestedPropertyId
                                            }, sourceDesription + ": The property \"" + error.propertyName + "\" is of type 'object' and has a nested property \"" + error.nestedPropertyName + "\" that is a 'list' with a pinned value. Codegen is currently not supported.");
                              case /* NameMappingInNestedObjectsNotSupported */19 :
                                  return React.createElement("div", {
                                              key: error.nestedNestedNameMappedPropertyId
                                            }, sourceDesription + ": The property \"" + error.propertyName + "\" is an 'object' and has a nested 'object' property \"" + error.nestedObjectPropertyName + "\" with nested property \"" + error.nestedNestedNameMappedPropertyName + "\" with name mapping. Codegen is currently not supported.");
                              case /* PinningInNestedObjectsNotSupported */20 :
                                  return React.createElement("div", {
                                              key: error.nestedNestedPinnedPropertyId
                                            }, sourceDesription + ": The property \"" + error.propertyName + "\" is an 'object' and has a nested 'object' property \"" + error.nestedObjectPropertyName + "\" with nested property \"" + error.nestedNestedPinnedPropertyName + "\" with a pinned value. Codegen is currently not supported.");
                              default:
                                return null;
                            }
                          }))));
    } else {
      return null;
    }
  };
  var showGroupAnalyticsWarning = function (sideEffect) {
    var isGroupPropertiesGroup = sideEffect === "UpdateGroups";
    var isLogEventWithGroups = sideEffect === "LogEvent" && Caml_obj.caml_notequal(resolvedEventGroupTypeIds, []);
    if (!(isGroupPropertiesGroup || isLogEventWithGroups)) {
      return null;
    }
    var eventSourcesToDestinations = AvoModel.sourcesSendingToDestinationsForEvent(model, $$event);
    var allUnsupportedDestinations = BeltListExtensions.flatMapU(Belt_List.fromArray(eventSourcesToDestinations), (function (param) {
            var source = param[0];
            return Belt_List.keepMapU(param[1], (function (destination) {
                          var match = source.language;
                          var match$1 = source.platform;
                          var match$2 = destination.type_;
                          if (match !== undefined && match$1 !== undefined && match$2 !== undefined && AvoConfig.doesLanguagePlatformDestinationSupportGroups(match, match$1, match$2, ModelUtils.isDestinationInterfaceEnabledForSourceDestination(source, destination.id))) {
                            return ;
                          } else {
                            return [
                                    source,
                                    destination
                                  ];
                          }
                        }));
          }));
    var allUnsupportedDestinationsGroupedBySourceName = Belt_MapString.toArray(Belt_List.reduceU(allUnsupportedDestinations, undefined, (function (sourceNameToDestinations, param) {
                var destination = param[1];
                var source = param[0];
                return Belt_MapString.updateU(sourceNameToDestinations, source.id, (function (maybeValue) {
                              if (maybeValue !== undefined) {
                                return [
                                        maybeValue[0],
                                        Belt_Array.concat(maybeValue[1], [destination])
                                      ];
                              }
                              var sourceName = Belt_Option.getWithDefault(source.name, "Source" + source.id);
                              return [
                                      sourceName,
                                      [destination]
                                    ];
                            }));
              })));
    if (allUnsupportedDestinations !== /* [] */0) {
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.color(Styles.Color.orange),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.padding2(Css.px(8), Css.px(0)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexDirection("column"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, "The following sources and destinations don't support Group analytics in Codegen:", Belt_Array.map(allUnsupportedDestinationsGroupedBySourceName, (function (param) {
                        var label = AvoConfig.languagePlatformDestinationLabel(param[1]);
                        return React.createElement("div", {
                                    key: param[0]
                                  }, label);
                      })));
    } else {
      return null;
    }
  };
  var sideEffects$1 = Belt_List.keep({
        hd: match$5[0],
        tl: Belt_List.map(match$5[1], (function (sideEffect) {
                return {
                        hd: sideEffect,
                        tl: /* [] */0
                      };
              }))
      }, (function (sideEffectGroup) {
          return sideEffectGroup !== /* [] */0;
        }));
  var fromItem_itemId = $$event.id;
  var fromItem_itemName = $$event.name;
  var fromItem = {
    itemId: fromItem_itemId,
    itemName: fromItem_itemName,
    itemType: "Event"
  };
  var options = SideEffectUtils.getEventSideEffectOptions(addingUserProperties, $$event, model);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(0), Css.px(10)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: /* [] */0
                    }
                  })
            }, Belt_List.toArray(Belt_List.map(SideEffectUtils.sortSideEffects(sideEffects$1), (function (sideEffectGroup) {
                        var userProp = Belt_List.keep(sideEffectGroup, (function (x) {
                                if (typeof x === "object") {
                                  return x.NAME === "UserProperties";
                                } else {
                                  return false;
                                }
                              }));
                        var hasEventProps = Belt_List.some(sideEffectGroup, (function (x) {
                                if (x === "LogEvent" || x === "Page") {
                                  return true;
                                } else {
                                  return x === "Revenue";
                                }
                              }));
                        var isGroupPropertiesGroup = Belt_List.has(sideEffectGroup, "UpdateGroups", Caml_obj.caml_equal);
                        var tmp;
                        var exit = 0;
                        if (userProp) {
                          var match = userProp.hd;
                          if (typeof match === "object" && match.NAME === "UserProperties" && !userProp.tl) {
                            tmp = React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.padding4(Css.px(15), Css.px(15), Css.px(15), Css.px(42)),
                                        tl: {
                                          hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                                          tl: {
                                            hd: Css.borderBottomRightRadius(Styles.Border.radius),
                                            tl: {
                                              hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.white),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.paddingBottom(Css.px(5)),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.tiny),
                                                tl: {
                                                  hd: Css.color(Styles.Color.light10),
                                                  tl: {
                                                    hd: Css.textTransform("uppercase"),
                                                    tl: {
                                                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                                      tl: {
                                                        hd: Css.display("flex"),
                                                        tl: {
                                                          hd: Css.alignItems("center"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    }, "User Properties", React.createElement(Tooltip.WithAnalytics.make, {
                                          fromItem: fromItem,
                                          size: "small",
                                          title: "User Properties",
                                          children: React.createElement(Tooltip.Content.make, {
                                                children: React.createElement(Markdown.make, {
                                                      source: Tooltips.userProperties
                                                    })
                                              })
                                        })), Belt_List.toArray(Belt_List.map(match.VAL, (function (property) {
                                            return React.createElement(Property.make, {
                                                        property: property,
                                                        event: $$event,
                                                        variant: variant,
                                                        slowModelMaybeFlattened: model,
                                                        viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant),
                                                        key: property.id
                                                      });
                                          }))), ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, true, variant) ? React.createElement("div", undefined, React.createElement(PropertyInput.make, {
                                            propertyType: /* UserProperty */2,
                                            currentProperties: $$event.directPropertyRefs,
                                            currentGroups: Belt_List.toArray($$event.propertyBundles),
                                            eventName: $$event.name,
                                            eventId: $$event.id,
                                            options: [[
                                                undefined,
                                                Belt_Array.mapU(Belt_List.toArray(AvoModel.splitProperties(model.properties).props), (function (item) {
                                                        return {
                                                                NAME: "Property",
                                                                VAL: item
                                                              };
                                                      }))
                                              ]],
                                            onSelect: (function (item, param) {
                                                if (item.NAME === "Group") {
                                                  return Pervasives.failwith("User property bundles not supported yet.");
                                                }
                                                var property = item.VAL;
                                                if (variant !== undefined) {
                                                  return Curry.app(sendActions, [
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              (function (branch) {
                                                                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                  return AnalyticsRe.eventVariantUpdatedProperty(variant.baseEventId, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "AddProperty", variant.id, property.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), variant.baseEventId);
                                                                }),
                                                              undefined,
                                                              [[
                                                                  {
                                                                    NAME: "AddPropertyToEventVariant",
                                                                    VAL: [
                                                                      TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                                                      property.id
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: variant.baseEventId,
                                                                    eventQuery: variant.baseEventId,
                                                                    propertyId: property.id,
                                                                    propertyQuery: property.id,
                                                                    eventVariantId: variant.id,
                                                                    eventVariantQuery: variant.id
                                                                  }
                                                                ]]
                                                            ]);
                                                } else {
                                                  return Curry.app(sendActions, [
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              [[
                                                                  {
                                                                    NAME: "AddPropertyRef",
                                                                    VAL: [
                                                                      $$event.id,
                                                                      property.id
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: $$event.id,
                                                                    eventQuery: $$event.id,
                                                                    propertyId: property.id,
                                                                    propertyQuery: property.id
                                                                  }
                                                                ]]
                                                            ]);
                                                }
                                              }),
                                            currentFilters: currentFilters,
                                            renderButton: (function (onClick, buttonRef, _isOpen) {
                                                return React.createElement("div", {
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.textAlign("left"),
                                                                  tl: {
                                                                    hd: Css.marginTop(Css.px(10)),
                                                                    tl: /* [] */0
                                                                  }
                                                                })
                                                          }, React.createElement(TextButton.make, {
                                                                onClick: onClick,
                                                                style: "Blue",
                                                                size: "Medium",
                                                                children: null,
                                                                ref: buttonRef
                                                              }, "+ Add User Property", Belt_Option.isSome(variant) ? " to Variant" : null));
                                              }),
                                            parent: {
                                              NAME: "Event",
                                              VAL: $$event.id
                                            },
                                            sendActions: sendActions,
                                            filters: filters,
                                            propertyLocation: "EventDetails"
                                          })) : null);
                          } else {
                            exit = 1;
                          }
                        } else {
                          exit = 1;
                        }
                        if (exit === 1) {
                          if (isGroupPropertiesGroup) {
                            tmp = React.createElement(EventSideEffects$UpdateGroups, {
                                  currentFilters: currentFilters,
                                  filters: filters,
                                  event: $$event,
                                  model: model,
                                  viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant),
                                  variant: variant
                                });
                          } else if (hasEventProps) {
                            var match$1 = AvoModel.getProperties(model, $$event.directPropertyRefs);
                            var eventProperties = match$1.eventProperties;
                            var removedVariantProperties = variant !== undefined ? Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Events.get, events, variant.baseEventId), [], (function ($$event) {
                                      return Belt_List.toArray(Belt_List.keepMapU(Belt_List.keepU($$event.directPropertyRefs, (function (propRef) {
                                                            var eventPropertyId = ModelUtils.getPropertyId(propRef);
                                                            return Belt_MapString.someU(variant.propertyOverrides, (function (propertyId, overrideType) {
                                                                          if (overrideType) {
                                                                            return false;
                                                                          } else {
                                                                            return eventPropertyId === propertyId;
                                                                          }
                                                                        }));
                                                          })), (function (propRef) {
                                                        return ModelUtils.resolveProperty(model, propRef);
                                                      })));
                                    })) : [];
                            var properties = Belt_List.keepU(eventProperties, (function (property) {
                                    if (property.builtIn === "NotBuiltIn") {
                                      return !property.auto;
                                    } else {
                                      return false;
                                    }
                                  }));
                            var propertiesCount = Belt_List.length(properties);
                            var isLast = function (index) {
                              return index === (propertiesCount - 1 | 0);
                            };
                            var eventPropertyBundlePropIds = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.map(ModelUtils.resolvePropertyBundleRefs(model, $$event.propertyBundles), (function (group) {
                                                return group.properties;
                                              })))));
                            var properties$1 = hidePropertiesAlreadyInBundle ? Belt_List.keepU(properties, (function (property) {
                                      return !Belt_SetString.has(eventPropertyBundlePropIds, property.id);
                                    })) : properties;
                            tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.paddingLeft(Css.px(42)),
                                            tl: {
                                              hd: Css.marginTop(Css.px(-10)),
                                              tl: {
                                                hd: Css.paddingBottom(Css.px(10)),
                                                tl: {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.flexWrap("wrap"),
                                                    tl: {
                                                      hd: Css.alignItems("center"),
                                                      tl: {
                                                        hd: Css.children({
                                                              hd: Css.marginRight(Css.px(6)),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: {
                                                          hd: Css.lastChild({
                                                                hd: Css.marginRight(Css.px(0)),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    }, Belt_Array.map(resolvedEventGroupTypeIds, (function (groupTypeId) {
                                            return React.createElement(Tag.make, {
                                                        tag: Belt_Option.getWithDefault(ModelUtils.getGroupTypeName(groupTypeId, model), groupTypeId).toUpperCase(),
                                                        onRemove: (function (param) {
                                                            return Curry.app(sendActions, [
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        (function (branch) {
                                                                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                            return AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", "RemoveAssociateEventWithGroup", undefined, undefined, Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, namingConvention, "ItemDetailsView", $$event.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id, eventTypesToAnalyticsActionsIncluded(undefined, undefined, Caml_option.some(Belt_Array.keep(resolvedEventGroupTypeIds, (function (t) {
                                                                                                      return t !== groupTypeId;
                                                                                                    }))), model, $$event), $$event.name);
                                                                          }),
                                                                        undefined,
                                                                        [[
                                                                            {
                                                                              NAME: "RemoveGroupTypeFromLogEvent",
                                                                              VAL: [
                                                                                $$event.id,
                                                                                groupTypeId
                                                                              ]
                                                                            },
                                                                            {
                                                                              eventId: $$event.id,
                                                                              eventQuery: $$event.id,
                                                                              groupTypeId: groupTypeId,
                                                                              groupTypeQuery: groupTypeId
                                                                            }
                                                                          ]]
                                                                      ]);
                                                          }),
                                                        hue: "orange",
                                                        tooltip: "This Event will be associated with this Group.",
                                                        key: groupTypeId
                                                      });
                                          })), ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant) && model.groupTypes.length !== 0 ? React.createElement(GroupTypeInput.make, {
                                            currentGroups: Belt_Array.keepMap(resolvedEventGroupTypeIds, (function (groupTypeId) {
                                                    return Belt_Array.getBy(model.groupTypes, (function (groupType) {
                                                                  return groupType.id === groupTypeId;
                                                                }));
                                                  })),
                                            onCreate: (function (groupTypeId, _groupTypeName) {
                                                return Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "AddGroupTypeToLogEvent",
                                                                  VAL: [
                                                                    $$event.id,
                                                                    groupTypeId
                                                                  ]
                                                                },
                                                                {
                                                                  eventId: $$event.id,
                                                                  eventQuery: $$event.id,
                                                                  groupTypeId: groupTypeId,
                                                                  groupTypeQuery: groupTypeId
                                                                }
                                                              ]]
                                                          ]);
                                              }),
                                            onSelect: (function (item, param) {
                                                return Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            (function (branch) {
                                                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                return AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", "AddAssociateEventWithGroup", undefined, undefined, Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, namingConvention, "ItemDetailsView", $$event.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id, eventTypesToAnalyticsActionsIncluded(undefined, undefined, Caml_option.some(Belt_Array.concat(resolvedEventGroupTypeIds, [item.id])), model, $$event), $$event.name);
                                                              }),
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "AddGroupTypeToLogEvent",
                                                                  VAL: [
                                                                    $$event.id,
                                                                    item.id
                                                                  ]
                                                                },
                                                                {
                                                                  eventId: $$event.id,
                                                                  eventQuery: $$event.id,
                                                                  groupTypeId: item.id,
                                                                  groupTypeQuery: item.id
                                                                }
                                                              ]]
                                                          ]);
                                              }),
                                            options: model.groupTypes,
                                            renderButton: (function (onClick, buttonRef, _isOpen) {
                                                return React.createElement("div", {
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.textAlign("left"),
                                                                  tl: {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.alignItems("center"),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                })
                                                          }, React.createElement(TextButton.make, {
                                                                onClick: onClick,
                                                                style: "Blue",
                                                                size: "Medium",
                                                                children: "+ Add Group",
                                                                ref: buttonRef
                                                              }));
                                              }),
                                            sendActions: sendActions
                                          }) : null), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.padding4(Css.px(15), Css.px(15), Css.px(15), Css.px(42)),
                                            tl: {
                                              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                                              tl: {
                                                hd: Css.borderBottomRightRadius(Styles.Border.radius),
                                                tl: {
                                                  hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                                                  tl: {
                                                    hd: Css.backgroundColor(Styles.Color.white),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    }, React.createElement("div", undefined, React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.display("flex"),
                                                    tl: /* [] */0
                                                  })
                                            }, React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.flexGrow(1.0),
                                                        tl: {
                                                          hd: Css.width(Css.pct(100)),
                                                          tl: /* [] */0
                                                        }
                                                      })
                                                }, React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.paddingBottom(Css.px(5)),
                                                            tl: {
                                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                                              tl: {
                                                                hd: Css.fontSize(Styles.FontSize.tiny),
                                                                tl: {
                                                                  hd: Css.color(Styles.Color.light10),
                                                                  tl: {
                                                                    hd: Css.textTransform("uppercase"),
                                                                    tl: {
                                                                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                                                      tl: {
                                                                        hd: Css.display("flex"),
                                                                        tl: {
                                                                          hd: Css.alignItems("center"),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          })
                                                    }, "Event Properties", React.createElement(Tooltip.WithAnalytics.make, {
                                                          fromItem: fromItem,
                                                          size: "small",
                                                          title: "Event Properties",
                                                          children: React.createElement(Tooltip.Content.make, {
                                                                children: React.createElement(Markdown.make, {
                                                                      source: Tooltips.eventProperties
                                                                    })
                                                              })
                                                        })), React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.width(Css.pct(100)),
                                                            tl: /* [] */0
                                                          })
                                                    }, Belt_List.toArray(Belt_List.keepMapU(eventProperties, (function (property) {
                                                                if (property.builtIn !== "NotBuiltIn" && !property.auto) {
                                                                  return Caml_option.some(React.createElement(Property.make, {
                                                                                  property: property,
                                                                                  event: $$event,
                                                                                  variant: variant,
                                                                                  slowModelMaybeFlattened: model,
                                                                                  viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant),
                                                                                  key: property.id
                                                                                }));
                                                                }
                                                                
                                                              })))), React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.width(Css.pct(100)),
                                                            tl: /* [] */0
                                                          })
                                                    }, Belt_Array.keepMapU(removedVariantProperties, (function (property) {
                                                            return Caml_option.some(React.createElement(Property.make, {
                                                                            property: property,
                                                                            event: $$event,
                                                                            variant: variant,
                                                                            slowModelMaybeFlattened: model,
                                                                            viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, true, variant),
                                                                            key: property.id
                                                                          }));
                                                          }))))), React.createElement(CollapsedAutoProperties.make, {
                                              properties: Belt_List.keep(eventProperties, (function (property) {
                                                      return property.auto;
                                                    })),
                                              fromItem: fromItem,
                                              children: Belt_List.toArray(Belt_List.keepMapU(eventProperties, (function (property) {
                                                          if (property.auto) {
                                                            return Caml_option.some(React.createElement(Property.make, {
                                                                            property: property,
                                                                            event: $$event,
                                                                            variant: variant,
                                                                            slowModelMaybeFlattened: model,
                                                                            viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant),
                                                                            key: property.id
                                                                          }));
                                                          }
                                                          
                                                        })))
                                            }), Belt_List.toArray(Belt_List.mapU(ModelUtils.resolvePropertyBundleRefs(model, $$event.propertyBundles), (function (bundle) {
                                                    return React.createElement(EventSideEffects$PropertyBundle, {
                                                                event: $$event,
                                                                bundle: bundle,
                                                                model: model,
                                                                variant: variant,
                                                                viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant),
                                                                key: bundle.id
                                                              });
                                                  }))), Belt_List.toArray(Belt_List.mapWithIndexU(properties$1, (function (index, property) {
                                                    var tmp = {
                                                      key: property.id
                                                    };
                                                    var tmp$1 = isLast(index) ? Caml_option.some(lastPropertyRef) : undefined;
                                                    if (tmp$1 !== undefined) {
                                                      tmp.ref = Caml_option.valFromOption(tmp$1);
                                                    }
                                                    return React.createElement("div", tmp, React.createElement(Property.make, {
                                                                    property: property,
                                                                    event: $$event,
                                                                    variant: variant,
                                                                    unnecessary: Belt_SetString.has(eventPropertyBundlePropIds, property.id),
                                                                    slowModelMaybeFlattened: model,
                                                                    viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, true, variant)
                                                                  }));
                                                  }))), React.createElement(PortalMenu.make, {
                                              position: "Left",
                                              placement: "Start",
                                              offset: {
                                                top: 20,
                                                left: -8
                                              },
                                              visible: Belt_Option.isSome(showGlobalNamespacePopup),
                                              onClose: handleCloseJustCreatedPopup,
                                              recalculateOnBoundingRectChanges: true,
                                              referenceItemRef: lastPropertyRef,
                                              children: React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.backgroundColor(Styles.Color.light11),
                                                          tl: {
                                                            hd: Css.paddingTop(Css.px(12)),
                                                            tl: {
                                                              hd: Css.paddingRight(Css.px(16)),
                                                              tl: {
                                                                hd: Css.paddingBottom(Css.px(12)),
                                                                tl: {
                                                                  hd: Css.paddingLeft(Css.px(16)),
                                                                  tl: {
                                                                    hd: Css.borderRadius(Styles.Border.radius),
                                                                    tl: {
                                                                      hd: Css.borderTopRightRadius(Css.px(0)),
                                                                      tl: {
                                                                        hd: Css.display("flex"),
                                                                        tl: {
                                                                          hd: Css.flexDirection("column"),
                                                                          tl: {
                                                                            hd: Css.alignItems("flexEnd"),
                                                                            tl: {
                                                                              hd: Css.maxWidth(Css.px(200)),
                                                                              tl: {
                                                                                hd: Css.position("relative"),
                                                                                tl: {
                                                                                  hd: Css.after({
                                                                                        hd: Css.unsafe("content", ""),
                                                                                        tl: {
                                                                                          hd: Css.position("absolute"),
                                                                                          tl: {
                                                                                            hd: Css.left(Css.pct(100.0)),
                                                                                            tl: {
                                                                                              hd: Css.top(Css.px(0)),
                                                                                              tl: {
                                                                                                hd: Css.width(Css.px(0)),
                                                                                                tl: {
                                                                                                  hd: Css.height(Css.px(0)),
                                                                                                  tl: {
                                                                                                    hd: Css.borderRight(Css.px(20), "solid", "transparent"),
                                                                                                    tl: {
                                                                                                      hd: Css.borderTop(Css.px(20), "solid", Styles.Color.light11),
                                                                                                      tl: /* [] */0
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }),
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        })
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        color: Styles.Color.white,
                                                        children: Belt_Option.mapWithDefault(showGlobalNamespacePopup, "The new property", (function (name) {
                                                                return "\"" + name + "\"";
                                                              })) + " was added to your property library. You can now re-use it on any event."
                                                      }), React.createElement(Spacer.make, {
                                                        height: 4
                                                      }), React.createElement(TextButton.make, {
                                                        onClick: handleCloseJustCreatedPopup,
                                                        style: "White",
                                                        size: "Small",
                                                        children: "Close"
                                                      }))
                                            }), ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, true, variant) ? React.createElement("div", undefined, React.createElement(PropertyInput.make, {
                                                    propertyType: /* EventProperty */1,
                                                    currentProperties: $$event.directPropertyRefs,
                                                    currentGroups: Belt_List.toArray($$event.propertyBundles),
                                                    eventName: $$event.name,
                                                    eventId: $$event.id,
                                                    options: [[
                                                        undefined,
                                                        Belt_Array.concat(Belt_Option.isSome(variant) ? [] : Belt_List.toArray(Belt_List.mapU(model.propertyBundles, (function (group) {
                                                                          return {
                                                                                  NAME: "Group",
                                                                                  VAL: group
                                                                                };
                                                                        }))), Belt_List.toArray(Belt_List.keepMapU(model.properties, (function (property) {
                                                                        if (property.TAG === /* PropertyRef */0) {
                                                                          return ;
                                                                        } else {
                                                                          return {
                                                                                  NAME: "Property",
                                                                                  VAL: property._0
                                                                                };
                                                                        }
                                                                      }))))
                                                      ]],
                                                    onSelect: (function (item, param) {
                                                        if (item.NAME === "Group") {
                                                          var group = item.VAL;
                                                          if (variant !== undefined) {
                                                            return ;
                                                          } else {
                                                            return Curry.app(sendActions, [
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        (function (branch) {
                                                                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                            return AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", "AddPropertyGroup", group.id, group.name, Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, namingConvention, "ItemDetailsView", $$event.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                                                          }),
                                                                        undefined,
                                                                        [[
                                                                            {
                                                                              NAME: "AddPropertyGroupToEvent",
                                                                              VAL: [
                                                                                $$event.id,
                                                                                group.id
                                                                              ]
                                                                            },
                                                                            {
                                                                              eventId: $$event.id,
                                                                              eventQuery: $$event.id,
                                                                              propertyGroupId: group.id,
                                                                              propertyGroupQuery: group.id
                                                                            }
                                                                          ]]
                                                                      ]);
                                                          }
                                                        }
                                                        var property = item.VAL;
                                                        if (variant !== undefined) {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      (function (branch) {
                                                                          var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                          return AnalyticsRe.eventVariantUpdatedProperty(variant.baseEventId, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "AddProperty", variant.id, property.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), variant.baseEventId);
                                                                        }),
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "AddPropertyToEventVariant",
                                                                            VAL: [
                                                                              TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                                                              property.id
                                                                            ]
                                                                          },
                                                                          {
                                                                            eventId: variant.baseEventId,
                                                                            eventQuery: variant.baseEventId,
                                                                            propertyId: property.id,
                                                                            propertyQuery: property.id,
                                                                            eventVariantId: variant.id,
                                                                            eventVariantQuery: variant.id
                                                                          }
                                                                        ]]
                                                                    ]);
                                                        } else {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "AddPropertyRef",
                                                                            VAL: [
                                                                              $$event.id,
                                                                              property.id
                                                                            ]
                                                                          },
                                                                          {
                                                                            eventId: $$event.id,
                                                                            eventQuery: $$event.id,
                                                                            propertyId: property.id,
                                                                            propertyQuery: property.id
                                                                          }
                                                                        ]]
                                                                    ]);
                                                        }
                                                      }),
                                                    canCreate: true,
                                                    onCreateSuccess: (function (_id, name, branch) {
                                                        var hasSeenPopup = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.globalNamespacePopupSeen), false);
                                                        if (!hasSeenPopup) {
                                                          setTimeout((function (param) {
                                                                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                  AnalyticsRe.tooltipDisplayed($$event.id, $$event.name, "Event", schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Global Namespace Info", "ProductTip", "Automatic", "Workspace", "Authenticated", schemaBundle$1.schemaId);
                                                                  return Curry._1(setShowGlobalNamespacePopup, (function (param) {
                                                                                return name;
                                                                              }));
                                                                }), 500);
                                                          return ;
                                                        }
                                                        
                                                      }),
                                                    currentFilters: currentFilters,
                                                    renderButton: (function (onClick, buttonRef, _isOpen) {
                                                        return React.createElement("div", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.textAlign("left"),
                                                                          tl: {
                                                                            hd: Css.marginTop(Css.px(10)),
                                                                            tl: /* [] */0
                                                                          }
                                                                        })
                                                                  }, React.createElement(TextButton.make, {
                                                                        onClick: onClick,
                                                                        id: "editable-cells-tour",
                                                                        style: "Blue",
                                                                        size: "Medium",
                                                                        children: null,
                                                                        ref: buttonRef
                                                                      }, "+ Add Event Property", Belt_Option.isSome(variant) ? " to Variant" : null));
                                                      }),
                                                    parent: {
                                                      NAME: "Event",
                                                      VAL: $$event.id
                                                    },
                                                    sendActions: sendActions,
                                                    filters: filters,
                                                    propertyLocation: "EventDetails"
                                                  })) : null, React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.marginTop(Css.px(20)),
                                                    tl: /* [] */0
                                                  })
                                            }, React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.paddingBottom(Css.px(5)),
                                                        tl: {
                                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                                          tl: {
                                                            hd: Css.fontSize(Styles.FontSize.tiny),
                                                            tl: {
                                                              hd: Css.color(Styles.Color.light10),
                                                              tl: {
                                                                hd: Css.textTransform("uppercase"),
                                                                tl: {
                                                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                                                  tl: {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.alignItems("center"),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      })
                                                }, "System Properties", React.createElement(Tooltip.WithAnalytics.make, {
                                                      fromItem: fromItem,
                                                      size: "small",
                                                      title: "System Properties",
                                                      children: React.createElement(Tooltip.Content.make, {
                                                            children: React.createElement(Markdown.make, {
                                                                  source: Tooltips.systemProperties
                                                                })
                                                          })
                                                    })), Belt_List.toArray(Belt_List.mapU(match$1.systemProperties, (function (property) {
                                                        return React.createElement(Property.make, {
                                                                    property: property,
                                                                    event: $$event,
                                                                    variant: variant,
                                                                    slowModelMaybeFlattened: model,
                                                                    viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant),
                                                                    key: property.id
                                                                  });
                                                      })))), ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant) ? React.createElement("div", undefined, React.createElement(PropertyInput.make, {
                                                    propertyType: /* SystemProperty */0,
                                                    currentProperties: model.properties,
                                                    currentGroups: [],
                                                    eventName: $$event.name,
                                                    eventId: $$event.id,
                                                    options: [],
                                                    onSelect: (function (param, param$1) {
                                                        
                                                      }),
                                                    currentFilters: currentFilters,
                                                    renderButton: (function (onClick, buttonRef, _isOpen) {
                                                        return React.createElement("div", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.textAlign("left"),
                                                                          tl: {
                                                                            hd: Css.marginTop(Css.px(10)),
                                                                            tl: /* [] */0
                                                                          }
                                                                        })
                                                                  }, React.createElement(TextButton.make, {
                                                                        onClick: onClick,
                                                                        id: "editable-cells-tour",
                                                                        style: "Blue",
                                                                        size: "Medium",
                                                                        children: "+ Add System Property",
                                                                        ref: buttonRef
                                                                      }));
                                                      }),
                                                    parent: {
                                                      NAME: "Event",
                                                      VAL: $$event.id
                                                    },
                                                    sendActions: sendActions,
                                                    filters: filters,
                                                    propertyLocation: "EventDetails"
                                                  })) : null)));
                          } else {
                            tmp = null;
                          }
                        }
                        return React.createElement("div", {
                                    key: Belt_List.toArray(Belt_List.map(sideEffectGroup, SideEffectUtils.getSideEffectLabel)).join("-"),
                                    className: Curry._1(Css.style, {
                                          hd: Css.borderRadius(Styles.Border.radius),
                                          tl: {
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                            tl: {
                                              hd: Css.width(Css.pct(100)),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: {
                                                  hd: Css.marginBottom(Css.px(8)),
                                                  tl: {
                                                    hd: Css.backgroundColor(Styles.Color.light02),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, Belt_List.toArray(Belt_List.mapWithIndex(sideEffectGroup, (function (index, sideEffect) {
                                              return React.createElement("div", {
                                                          key: SideEffectUtils.getSideEffectLabel(sideEffect),
                                                          ref: (function (elementRef) {
                                                              if (elementRef == null) {
                                                                return ;
                                                              }
                                                              if (jumpTo === undefined) {
                                                                return ;
                                                              }
                                                              if (typeof jumpTo === "object" && jumpTo.NAME === "UserProperties" && typeof sideEffect === "object" && sideEffect.NAME === "UserProperties") {
                                                                elementRef.scrollIntoView({
                                                                      block: "nearest",
                                                                      behavior: "smooth"
                                                                    });
                                                                return ;
                                                              }
                                                              if (Caml_obj.caml_equal(jumpTo, sideEffect)) {
                                                                elementRef.scrollIntoView({
                                                                      block: "nearest",
                                                                      behavior: "smooth"
                                                                    });
                                                                return ;
                                                              }
                                                              
                                                            }),
                                                          className: Curry._1(Css.style, {
                                                                hd: Css.display("flex"),
                                                                tl: {
                                                                  hd: Css.justifyContent("spaceBetween"),
                                                                  tl: {
                                                                    hd: Css.padding(Css.px(16)),
                                                                    tl: {
                                                                      hd: Css.borderTop(Css.px(index > 0 ? 1 : 0), "solid", Styles.Color.light04),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              })
                                                        }, React.createElement("div", {
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.alignItems("flexStart"),
                                                                      tl: {
                                                                        hd: Css.justifyContent("flexStart"),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  })
                                                            }, React.createElement("div", {
                                                                  className: Curry._1(Css.style, {
                                                                        hd: Css.marginTop(Css.px(1)),
                                                                        tl: /* [] */0
                                                                      })
                                                                }, React.createElement(EventTypeIcon.make, {
                                                                      sideEffect: sideEffect,
                                                                      size: 12,
                                                                      color: Styles.Color.light10
                                                                    })), React.createElement(Spacer.make, {
                                                                  width: 16
                                                                }), React.createElement("div", undefined, React.createElement("div", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.display("flex"),
                                                                            tl: {
                                                                              hd: Css.alignItems("center"),
                                                                              tl: /* [] */0
                                                                            }
                                                                          })
                                                                    }, React.createElement($$Text.make, {
                                                                          size: "Medium",
                                                                          weight: "Semi",
                                                                          color: Styles.Color.light12,
                                                                          children: SideEffectUtils.getSideEffectLabel(sideEffect)
                                                                        }), React.createElement("div", {
                                                                          className: Curry._1(Css.style, {
                                                                                hd: Css.marginTop(Css.px(-2)),
                                                                                tl: /* [] */0
                                                                              })
                                                                        }, React.createElement(Tooltip.WithAnalytics.make, {
                                                                              fromItem: fromItem,
                                                                              title: SideEffectUtils.getSideEffectLabel(sideEffect),
                                                                              children: React.createElement(Tooltip.Content.make, {
                                                                                    children: getSideEffectTooltip(sideEffect)
                                                                                  })
                                                                            }))), React.createElement(Spacer.make, {
                                                                      height: 2
                                                                    }), React.createElement($$Text.make, {
                                                                      size: "Small",
                                                                      color: Styles.Color.light10,
                                                                      children: SideEffectUtils.getSideEffectDescription(sideEffect)
                                                                    }), showGroupAnalyticsWarning(sideEffect), showObjectsSupportWarning(sideEffect))), ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                                                    width: 8
                                                                  }), React.createElement(ContextMenu.make, {
                                                                    options: [{
                                                                        NAME: "Option",
                                                                        VAL: {
                                                                          label: "Remove",
                                                                          onClick: (function (param) {
                                                                              if (typeof sideEffect === "object") {
                                                                                var properties = sideEffect.VAL;
                                                                                if (properties === /* [] */0) {
                                                                                  return handleAddUserProperties(false);
                                                                                } else if (Belt_List.length(properties) === 1 || window.confirm("Are you sure you would like to remove all " + String(Belt_List.length(properties)) + " user properties from this event?")) {
                                                                                  handleAddUserProperties(false);
                                                                                  return Curry.app(sendActions, [
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              (function (branch) {
                                                                                                  return Belt_List.forEachU(properties, (function (property) {
                                                                                                                var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
                                                                                                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                                                                AnalyticsRe.propertyRemoved(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, $$event.id, $$event.name, "EventDetails", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                                                                                var arg = property.id;
                                                                                                                var arg$1 = $$event.id;
                                                                                                                return function (param) {
                                                                                                                  return function (param$1) {
                                                                                                                    return Property.offerArchivingPropertyIfUnused(events, param, param$1, arg, arg$1, schemaBundle$1, sendActions);
                                                                                                                  };
                                                                                                                };
                                                                                                              }));
                                                                                                }),
                                                                                              undefined,
                                                                                              Belt_Array.map(Belt_List.toArray(properties), (function (property) {
                                                                                                      return [
                                                                                                              {
                                                                                                                NAME: "RemovePropertyRefV3",
                                                                                                                VAL: [
                                                                                                                  $$event.id,
                                                                                                                  property.id
                                                                                                                ]
                                                                                                              },
                                                                                                              {
                                                                                                                eventId: $$event.id,
                                                                                                                eventQuery: $$event.id,
                                                                                                                propertyId: property.id,
                                                                                                                propertyQuery: property.id
                                                                                                              }
                                                                                                            ];
                                                                                                    }))
                                                                                            ]);
                                                                                } else {
                                                                                  return ;
                                                                                }
                                                                              }
                                                                              if (sideEffect === "Unidentify" || sideEffect === "Page" || sideEffect === "Alias" || sideEffect === "Identify" || sideEffect === "Revenue") {
                                                                                var type_ = SideEffectUtils.sideEffectToEventType(sideEffect);
                                                                                return Curry.app(sendActions, [
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            (function (branch) {
                                                                                                var newEventActions = Belt_List.keep($$event.types, (function (t) {
                                                                                                        return t !== type_;
                                                                                                      }));
                                                                                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                                                return AnalyticsRe.eventTypeUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, $$event.id, $$event.name, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, Belt_List.toArray($$event.types), Belt_List.toArray(newEventActions), $$event.id, NamedBranch.getId(branch), schemaBundle$1.schemaId, $$event.id, eventTypesToAnalyticsActionsIncluded(Caml_option.some(newEventActions), undefined, undefined, model, $$event));
                                                                                              }),
                                                                                            undefined,
                                                                                            [[
                                                                                                {
                                                                                                  NAME: "RemoveEventType",
                                                                                                  VAL: [
                                                                                                    $$event.id,
                                                                                                    type_
                                                                                                  ]
                                                                                                },
                                                                                                {
                                                                                                  eventId: $$event.id,
                                                                                                  eventQuery: $$event.id
                                                                                                }
                                                                                              ]]
                                                                                          ]);
                                                                              }
                                                                              if (sideEffect === "LogEvent") {
                                                                                return Curry.app(sendActions, [
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            (function (branch) {
                                                                                                var newEventActions = Belt_List.concat($$event.types, {
                                                                                                      hd: "SkipLogEvent",
                                                                                                      tl: /* [] */0
                                                                                                    });
                                                                                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                                                return AnalyticsRe.eventTypeUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, $$event.id, $$event.name, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, Belt_List.toArray($$event.types), Belt_List.toArray(newEventActions), $$event.id, NamedBranch.getId(branch), schemaBundle$1.schemaId, $$event.id, eventTypesToAnalyticsActionsIncluded(Caml_option.some(newEventActions), undefined, undefined, model, $$event));
                                                                                              }),
                                                                                            undefined,
                                                                                            [[
                                                                                                {
                                                                                                  NAME: "AddEventType",
                                                                                                  VAL: [
                                                                                                    $$event.id,
                                                                                                    "SkipLogEvent"
                                                                                                  ]
                                                                                                },
                                                                                                {
                                                                                                  eventId: $$event.id,
                                                                                                  eventQuery: $$event.id
                                                                                                }
                                                                                              ]]
                                                                                          ]);
                                                                              }
                                                                              var context = {};
                                                                              var match = AvoModel.getProperties(model, $$event.directPropertyRefs);
                                                                              var groupProperties = match.groupProperties;
                                                                              var hasGroupProperties = Belt_List.length(groupProperties) > 0;
                                                                              var hasAssociations = resolvedUserGroupTypeIds.length !== 0;
                                                                              var noNeedToConfirm = !hasGroupProperties && !hasAssociations;
                                                                              var n = Belt_List.length(groupProperties);
                                                                              var n$1 = resolvedUserGroupTypeIds.length;
                                                                              var confirmationText = "Are you sure you would like to remove " + (
                                                                                n !== 0 ? (
                                                                                    n !== 1 ? String(n) + " group properties" : "one group property"
                                                                                  ) : ""
                                                                              ) + (
                                                                                groupProperties !== /* [] */0 && resolvedUserGroupTypeIds.length !== 0 ? " and " : ""
                                                                              ) + (
                                                                                n$1 !== 0 ? (
                                                                                    n$1 !== 1 ? "all " + String(n$1) + " group associations" : "one group association"
                                                                                  ) : ""
                                                                              ) + " from this event?";
                                                                              var mayRemove = noNeedToConfirm || window.confirm(confirmationText);
                                                                              if (mayRemove) {
                                                                                return Curry.app(sendActions, [
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            (function (branch) {
                                                                                                Belt_List.forEach(groupProperties, (function (property) {
                                                                                                        var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                                                        var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
                                                                                                        return AnalyticsRe.propertyRemoved(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, $$event.id, $$event.name, "EventDetails", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                                                                      }));
                                                                                                return Belt_Array.forEach(resolvedUserGroupTypeIds, (function (groupTypeId) {
                                                                                                              return AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "N/A", "N/A", "RemoveAssociateUserWithGroup", undefined, undefined, Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources)), undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, namingConvention, "ItemDetailsView", $$event.id, schemaBundle.schemaId, NamedBranch.getId(branch), $$event.id, eventTypesToAnalyticsActionsIncluded(undefined, Caml_option.some(Belt_Array.keep(resolvedUserGroupTypeIds, (function (t) {
                                                                                                                                        return t !== groupTypeId;
                                                                                                                                      }))), undefined, model, $$event), $$event.name);
                                                                                                            }));
                                                                                              }),
                                                                                            undefined,
                                                                                            Belt_Array.concatMany([
                                                                                                  [[
                                                                                                      {
                                                                                                        NAME: "RemoveEventType",
                                                                                                        VAL: [
                                                                                                          $$event.id,
                                                                                                          "UpdateGroups"
                                                                                                        ]
                                                                                                      },
                                                                                                      context
                                                                                                    ]],
                                                                                                  Belt_Array.map(Belt_List.toArray(groupProperties), (function (p) {
                                                                                                          return [
                                                                                                                  {
                                                                                                                    NAME: "RemovePropertyRefV3",
                                                                                                                    VAL: [
                                                                                                                      $$event.id,
                                                                                                                      p.id
                                                                                                                    ]
                                                                                                                  },
                                                                                                                  context
                                                                                                                ];
                                                                                                        })),
                                                                                                  Belt_Array.map(resolvedUserGroupTypeIds, (function (groupTypeId) {
                                                                                                          return [
                                                                                                                  {
                                                                                                                    NAME: "RemoveUserFromGroup",
                                                                                                                    VAL: [
                                                                                                                      $$event.id,
                                                                                                                      groupTypeId
                                                                                                                    ]
                                                                                                                  },
                                                                                                                  context
                                                                                                                ];
                                                                                                        }))
                                                                                                ])
                                                                                          ]);
                                                                              }
                                                                              
                                                                            })
                                                                        }
                                                                      }]
                                                                  })) : null);
                                            }))), tmp);
                      }))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: /* [] */0
                          })
                    }, Belt_List.length(sideEffects$1) === 0 ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(10), Css.px(15)),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.border(Css.px(1), "solid", Styles.Color.red),
                                      tl: {
                                        hd: Css.color(Styles.Color.red),
                                        tl: {
                                          hd: Css.width(Css.pct(100)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: /* [] */0
                                      }
                                    })
                              }, "No Actions"), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontSize(Styles.FontSize.small),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.regular),
                                        tl: {
                                          hd: Css.paddingTop(Css.px(2)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, "Nothing will happen when this Avo function is called since no actions have been defined.")) : null, ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant) && Belt_List.length(options) > 0 ? React.createElement(ContextBox.make, {
                            children: (function (param, param$1, close, buttonRef) {
                                return [
                                        React.createElement(TextButton.make, {
                                              style: "Blue",
                                              size: "Medium",
                                              children: null,
                                              ref: buttonRef
                                            }, React.createElement(Spacer.make, {
                                                  height: 10
                                                }), "+ Add Action"),
                                        React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.position("absolute"),
                                                    tl: {
                                                      hd: Css.left(Css.px(0)),
                                                      tl: {
                                                        hd: Css.bottom(Css.px(0)),
                                                        tl: {
                                                          hd: Css.transform(Css.translateY(Css.pct(100))),
                                                          tl: {
                                                            hd: Css.padding(Css.px(4)),
                                                            tl: {
                                                              hd: Css.borderRadius(Styles.Border.radius),
                                                              tl: {
                                                                hd: Css.backgroundColor(Styles.Color.white),
                                                                tl: {
                                                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                                  tl: {
                                                                    hd: Css.width(Css.pct(100)),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  })
                                            }, Belt_List.toArray(Belt_List.map(options, (function (sideEffect) {
                                                        return React.createElement("button", {
                                                                    key: SideEffectUtils.getSideEffectLabel(sideEffect),
                                                                    className: Curry._1(Css.merge, {
                                                                          hd: Curry._1(Css.style, Styles.button),
                                                                          tl: {
                                                                            hd: Curry._1(Css.style, {
                                                                                  hd: Css.padding2(Css.px(10), Css.px(15)),
                                                                                  tl: {
                                                                                    hd: Css.width(Css.pct(100)),
                                                                                    tl: {
                                                                                      hd: Css.textAlign("left"),
                                                                                      tl: {
                                                                                        hd: Css.borderRadius(Styles.Border.radius),
                                                                                        tl: {
                                                                                          hd: Css.hover({
                                                                                                hd: Css.backgroundColor(Styles.Color.light02),
                                                                                                tl: {
                                                                                                  hd: Css.color(Styles.Color.darkBlue),
                                                                                                  tl: /* [] */0
                                                                                                }
                                                                                              }),
                                                                                          tl: /* [] */0
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }),
                                                                    onClick: (function (param) {
                                                                        handleAdd(sideEffect);
                                                                        return Curry._1(close, undefined);
                                                                      })
                                                                  }, React.createElement("div", {
                                                                        className: Curry._1(Css.style, {
                                                                              hd: Css.display("flex"),
                                                                              tl: {
                                                                                hd: Css.alignItems("flexStart"),
                                                                                tl: {
                                                                                  hd: Css.justifyContent("flexStart"),
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }
                                                                            })
                                                                      }, React.createElement("div", {
                                                                            className: Curry._1(Css.style, {
                                                                                  hd: Css.marginTop(Css.px(1)),
                                                                                  tl: /* [] */0
                                                                                })
                                                                          }, React.createElement(EventTypeIcon.make, {
                                                                                sideEffect: sideEffect,
                                                                                size: 12,
                                                                                color: Styles.Color.light10
                                                                              })), React.createElement(Spacer.make, {
                                                                            width: 16
                                                                          }), React.createElement("div", undefined, React.createElement($$Text.make, {
                                                                                size: "Medium",
                                                                                weight: "Semi",
                                                                                color: Styles.Color.light12,
                                                                                children: SideEffectUtils.getSideEffectLabel(sideEffect)
                                                                              }), React.createElement(Spacer.make, {
                                                                                height: 2
                                                                              }), React.createElement($$Text.make, {
                                                                                size: "Small",
                                                                                color: Styles.Color.light10,
                                                                                children: SideEffectUtils.getSideEffectDescription(sideEffect)
                                                                              }))));
                                                      }))))
                                      ];
                              })
                          }) : null)));
}

var make = EventSideEffects;

export {
  getJumpTo ,
  eventTypesToAnalyticsActionsIncluded ,
  getSideEffectTooltip ,
  UpdateGroups ,
  PropertyBundle ,
  make ,
  
}
/* container Not a pure module */
