// Generated by ReScript, PLEASE EDIT WITH CARE


function isSandbox(schemaId) {
  return schemaId.startsWith("sandbox-");
}

export {
  isSandbox ,
  
}
/* No side effect */
