// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconArrowDownEast(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light07;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.stroke(color),
                    tl: /* [] */0
                  }),
              width: String(size),
              fill: "none",
              viewBox: "0 0 15 14",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M14.5 8.5H0.5V0.5",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeMiterlimit: "10"
                }), React.createElement("path", {
                  d: "M9.5 13.5L14.5 8.5L9.5 3.5",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeMiterlimit: "10"
                }));
}

var make = IconArrowDownEast;

export {
  make ,
  
}
/* Css Not a pure module */
