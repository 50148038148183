// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import Shortid from "shortid";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import QuillDelta from "quill-delta";
import * as Collaborators from "./Collaborators.mjs";
import * as NameDuplicator from "../../model/src/NameDuplicator.mjs";

function getActionsAndContext(modelSources, branchId, branchName, hasEventSpecificAllowedPropertyValuesMigration) {
  var goalId = Shortid();
  var signupCompletedId = Shortid();
  var onboardingStartedId = Shortid();
  var onboardingStepCompletedId = Shortid();
  var onboardingCompletedId = Shortid();
  var authenticationMethodId = Shortid();
  var onboardingStepCountId = Shortid();
  var onboardingStepIndexId = Shortid();
  var onboardingStepNameId = Shortid();
  var onboardingFunnelId = Shortid();
  var conversionMetricId = Shortid();
  var funnelItemId_1 = Shortid();
  var funnelItemId_2 = Shortid();
  var funnelItemId_3 = Shortid();
  var funnelItemId_4 = Shortid();
  var funnelItemId_5 = Shortid();
  var funnelItemId_6 = Shortid();
  var conversionItemId_1 = Shortid();
  var conversionItemId_2 = Shortid();
  var onboardingSeenCountId = Shortid();
  var onboardingVersionId = Shortid();
  var onboardingInitiatedFromScreenId = Shortid();
  var propertyBundleId = Shortid();
  var hasSources = modelSources !== /* [] */0;
  var sourceId = Belt_Option.mapWithDefault(Belt_List.head(modelSources), Shortid(), (function (source) {
          return source.id;
        }));
  var context = {
    eventIds: [
      signupCompletedId,
      onboardingStartedId,
      onboardingStepCompletedId,
      onboardingCompletedId
    ],
    eventsQuery: [
      signupCompletedId,
      onboardingStartedId,
      onboardingStepCompletedId,
      onboardingCompletedId
    ],
    sourceId: sourceId,
    sourceQuery: sourceId,
    goalId: goalId,
    goalQuery: goalId,
    branchId: branchId,
    branchQuery: branchId,
    propertyIds: [
      authenticationMethodId,
      onboardingStepCountId,
      onboardingStepIndexId,
      onboardingStepNameId,
      onboardingSeenCountId,
      onboardingVersionId,
      onboardingInitiatedFromScreenId
    ],
    propertiesQuery: [
      authenticationMethodId,
      onboardingStepCountId,
      onboardingStepIndexId,
      onboardingStepNameId,
      onboardingSeenCountId,
      onboardingVersionId,
      onboardingInitiatedFromScreenId
    ],
    propertyGroupIds: [propertyBundleId],
    propertyGroupsQuery: [propertyBundleId],
    metricIds: [
      onboardingFunnelId,
      conversionMetricId
    ],
    metricsQuery: [
      onboardingFunnelId,
      conversionMetricId
    ]
  };
  var actions = Belt_Array.concatMany([
        [{
            NAME: "BranchInit",
            VAL: [
              branchId,
              branchName
            ]
          }],
        hasSources ? [] : [
            {
              NAME: "CreateSource",
              VAL: sourceId
            },
            {
              NAME: "UpdateSourceName",
              VAL: [
                sourceId,
                "Demo Source"
              ]
            },
            {
              NAME: "UpdateSourcePlatformV3",
              VAL: [
                sourceId,
                "Web"
              ]
            },
            {
              NAME: "UpdateProgrammingLanguage",
              VAL: [
                sourceId,
                "ts"
              ]
            }
          ],
        [
          {
            NAME: "AddEvent",
            VAL: [
              signupCompletedId,
              "Signup Completed (Demo)"
            ]
          },
          {
            NAME: "AddEvent",
            VAL: [
              onboardingStartedId,
              "Onboarding Started (Demo)"
            ]
          },
          {
            NAME: "AddEvent",
            VAL: [
              onboardingStepCompletedId,
              "Onboarding Step Completed (Demo)"
            ]
          },
          {
            NAME: "AddEvent",
            VAL: [
              onboardingCompletedId,
              "Onboarding Completed (Demo)"
            ]
          },
          {
            NAME: "UpdateEventDescription",
            VAL: [
              signupCompletedId,
              "Event sent when a user has successfully signed up"
            ]
          },
          {
            NAME: "UpdateEventDescription",
            VAL: [
              onboardingStartedId,
              "Event sent when a user starts the onboarding journey"
            ]
          },
          {
            NAME: "UpdateEventDescription",
            VAL: [
              onboardingStepCompletedId,
              "Event sent when a user completes a step in their onboarding journey"
            ]
          },
          {
            NAME: "UpdateEventDescription",
            VAL: [
              onboardingCompletedId,
              "Event sent when a user successfully completes their onboarding journey"
            ]
          },
          {
            NAME: "IncludeEventInSourceV2",
            VAL: [
              signupCompletedId,
              sourceId,
              /* [] */0,
              true
            ]
          },
          {
            NAME: "IncludeEventInSourceV2",
            VAL: [
              onboardingStartedId,
              sourceId,
              /* [] */0,
              true
            ]
          },
          {
            NAME: "IncludeEventInSourceV2",
            VAL: [
              onboardingStepCompletedId,
              sourceId,
              /* [] */0,
              true
            ]
          },
          {
            NAME: "IncludeEventInSourceV2",
            VAL: [
              onboardingCompletedId,
              sourceId,
              /* [] */0,
              true
            ]
          },
          {
            NAME: "AddProperty",
            VAL: [
              onboardingCompletedId,
              onboardingStepCountId,
              "Onboarding Step Count (Demo)",
              /* EventProperty */1
            ]
          },
          {
            NAME: "AddProperty",
            VAL: [
              onboardingStepCompletedId,
              onboardingStepNameId,
              "Onboarding Step Name (Demo)",
              /* EventProperty */1
            ]
          },
          {
            NAME: "AddProperty",
            VAL: [
              onboardingStepCompletedId,
              onboardingStepIndexId,
              "Onboarding Step Index (Demo)",
              /* EventProperty */1
            ]
          },
          {
            NAME: "AddProperty",
            VAL: [
              signupCompletedId,
              authenticationMethodId,
              "Authentication Method (Demo)",
              /* EventProperty */1
            ]
          },
          {
            NAME: "UpdatePropertyDescription",
            VAL: [
              authenticationMethodId,
              "The method the user used to sign up or log in"
            ]
          },
          {
            NAME: "UpdatePropertyDescription",
            VAL: [
              onboardingStepCountId,
              "The number of onboarding steps that the user completed"
            ]
          },
          {
            NAME: "UpdatePropertyDescription",
            VAL: [
              onboardingStepIndexId,
              "The number of the onboarding step the user just completed"
            ]
          },
          {
            NAME: "UpdatePropertyDescription",
            VAL: [
              onboardingStepNameId,
              "The name of the onboarding step the user just completed"
            ]
          },
          {
            NAME: "UpdatePropertyType",
            VAL: [
              authenticationMethodId,
              "string"
            ]
          },
          {
            NAME: "UpdatePropertyType",
            VAL: [
              onboardingStepCountId,
              "int"
            ]
          },
          {
            NAME: "UpdatePropertyType",
            VAL: [
              onboardingStepIndexId,
              "int"
            ]
          },
          {
            NAME: "UpdatePropertyType",
            VAL: [
              onboardingStepNameId,
              "string"
            ]
          },
          {
            NAME: "CreateProperty",
            VAL: [
              onboardingSeenCountId,
              "Onboarding Seen Count (Demo)",
              /* EventProperty */1
            ]
          },
          {
            NAME: "CreateProperty",
            VAL: [
              onboardingVersionId,
              "Onboarding Version (Demo)",
              /* EventProperty */1
            ]
          },
          {
            NAME: "CreateProperty",
            VAL: [
              onboardingInitiatedFromScreenId,
              "Onboarding Initiated From Screen (Demo)",
              /* EventProperty */1
            ]
          },
          {
            NAME: "UpdatePropertyType",
            VAL: [
              onboardingSeenCountId,
              "int"
            ]
          },
          {
            NAME: "UpdatePropertyType",
            VAL: [
              onboardingVersionId,
              "string"
            ]
          },
          {
            NAME: "UpdatePropertyType",
            VAL: [
              onboardingInitiatedFromScreenId,
              "string"
            ]
          },
          hasEventSpecificAllowedPropertyValuesMigration ? ({
                NAME: "AddEventSpecificPropertyValueForAllEvents",
                VAL: [
                  onboardingInitiatedFromScreenId,
                  {
                    NAME: "StringLit",
                    VAL: "Dashboard"
                  }
                ]
              }) : ({
                NAME: "AddPropertyValidation",
                VAL: [
                  onboardingInitiatedFromScreenId,
                  {
                    NAME: "Matches",
                    VAL: {
                      hd: [
                        {
                          NAME: "Literal",
                          VAL: {
                            NAME: "StringLit",
                            VAL: "Dashboard"
                          }
                        },
                        /* [] */0
                      ],
                      tl: /* [] */0
                    }
                  }
                ]
              }),
          hasEventSpecificAllowedPropertyValuesMigration ? ({
                NAME: "AddEventSpecificPropertyValueForAllEvents",
                VAL: [
                  onboardingInitiatedFromScreenId,
                  {
                    NAME: "StringLit",
                    VAL: "Product Screen"
                  }
                ]
              }) : ({
                NAME: "AddPropertyValidation",
                VAL: [
                  onboardingInitiatedFromScreenId,
                  {
                    NAME: "Matches",
                    VAL: {
                      hd: [
                        {
                          NAME: "Literal",
                          VAL: {
                            NAME: "StringLit",
                            VAL: "Product Screen"
                          }
                        },
                        /* [] */0
                      ],
                      tl: /* [] */0
                    }
                  }
                ]
              }),
          {
            NAME: "UpdatePropertyDescription",
            VAL: [
              onboardingSeenCountId,
              "The number of times the user has seen the onboarding"
            ]
          },
          {
            NAME: "UpdatePropertyDescription",
            VAL: [
              onboardingVersionId,
              "The version of the onboarding"
            ]
          },
          {
            NAME: "UpdatePropertyDescription",
            VAL: [
              onboardingInitiatedFromScreenId,
              "The screen from which the onboarding was initiated from"
            ]
          },
          {
            NAME: "CreatePropertyGroup",
            VAL: [
              propertyBundleId,
              "Onboarding (Demo)"
            ]
          },
          {
            NAME: "AddPropertyToGroup",
            VAL: [
              propertyBundleId,
              onboardingSeenCountId
            ]
          },
          {
            NAME: "AddPropertyToGroup",
            VAL: [
              propertyBundleId,
              onboardingVersionId
            ]
          },
          {
            NAME: "AddPropertyToGroup",
            VAL: [
              propertyBundleId,
              onboardingInitiatedFromScreenId
            ]
          },
          {
            NAME: "AddPropertyGroupToEvent",
            VAL: [
              onboardingStartedId,
              propertyBundleId
            ]
          },
          {
            NAME: "AddPropertyGroupToEvent",
            VAL: [
              onboardingStepCompletedId,
              propertyBundleId
            ]
          },
          {
            NAME: "AddPropertyGroupToEvent",
            VAL: [
              onboardingCompletedId,
              propertyBundleId
            ]
          },
          {
            NAME: "AddGoal",
            VAL: goalId
          },
          {
            NAME: "UpdateGoalName",
            VAL: [
              goalId,
              "Onboarding (Demo)"
            ]
          },
          {
            NAME: "AddEventToGoal",
            VAL: [
              goalId,
              onboardingStartedId
            ]
          },
          {
            NAME: "AddEventToGoal",
            VAL: [
              goalId,
              onboardingStepCompletedId
            ]
          },
          {
            NAME: "AddEventToGoal",
            VAL: [
              goalId,
              onboardingCompletedId
            ]
          },
          {
            NAME: "AddEventToGoal",
            VAL: [
              goalId,
              signupCompletedId
            ]
          },
          {
            NAME: "AddTag",
            VAL: [
              onboardingStartedId,
              "Demo"
            ]
          },
          {
            NAME: "AddTag",
            VAL: [
              onboardingStepCompletedId,
              "Demo"
            ]
          },
          {
            NAME: "AddTag",
            VAL: [
              onboardingCompletedId,
              "Demo"
            ]
          },
          {
            NAME: "AddTag",
            VAL: [
              signupCompletedId,
              "Demo"
            ]
          },
          {
            NAME: "AddMetric",
            VAL: onboardingFunnelId
          },
          {
            NAME: "AddMetric",
            VAL: conversionMetricId
          },
          {
            NAME: "UpdateMetricName",
            VAL: [
              onboardingFunnelId,
              "Detailed Onboarding funnel (Demo)"
            ]
          },
          {
            NAME: "UpdateMetricName",
            VAL: [
              conversionMetricId,
              "Conversion: Start Onboarding -> Complete Onboarding (Demo)"
            ]
          },
          {
            NAME: "UpdateMetricDescription",
            VAL: [
              onboardingFunnelId,
              JSON.stringify(new QuillDelta(JSON.parse("{\"ops\":[{\"insert\":\"A detailed onboarding funnel from signing up to triggering the onboarding journey to completing it is useful to identify where exactly users are dropping off\"}]}")))
            ]
          },
          {
            NAME: "UpdateMetricDescription",
            VAL: [
              conversionMetricId,
              JSON.stringify(new QuillDelta(JSON.parse("{\"ops\":[{\"insert\":\"A conversion rate from starting onboarding to completing onboarding is useful to monitor the performance of your onboarding journey.\"}]}")))
            ]
          },
          {
            NAME: "UpdateMetricType",
            VAL: [
              onboardingFunnelId,
              "Funnel"
            ]
          },
          {
            NAME: "UpdateMetricType",
            VAL: [
              conversionMetricId,
              "Funnel"
            ]
          },
          {
            NAME: "AddMetricToGoal",
            VAL: [
              goalId,
              onboardingFunnelId
            ]
          },
          {
            NAME: "AddMetricToGoal",
            VAL: [
              goalId,
              conversionMetricId
            ]
          },
          {
            NAME: "AddEventToMetric",
            VAL: [
              onboardingFunnelId,
              funnelItemId_1,
              signupCompletedId
            ]
          },
          {
            NAME: "AddEventToMetric",
            VAL: [
              onboardingFunnelId,
              funnelItemId_2,
              onboardingStartedId
            ]
          },
          {
            NAME: "AddEventToMetric",
            VAL: [
              onboardingFunnelId,
              funnelItemId_3,
              onboardingStepCompletedId
            ]
          },
          {
            NAME: "AddEventToMetric",
            VAL: [
              onboardingFunnelId,
              funnelItemId_4,
              onboardingStepCompletedId
            ]
          },
          {
            NAME: "AddEventToMetric",
            VAL: [
              onboardingFunnelId,
              funnelItemId_5,
              onboardingStepCompletedId
            ]
          },
          {
            NAME: "AddEventToMetric",
            VAL: [
              onboardingFunnelId,
              funnelItemId_6,
              onboardingCompletedId
            ]
          },
          {
            NAME: "AddEventToMetric",
            VAL: [
              conversionMetricId,
              conversionItemId_1,
              onboardingStartedId
            ]
          },
          {
            NAME: "AddEventToMetric",
            VAL: [
              conversionMetricId,
              conversionItemId_2,
              onboardingCompletedId
            ]
          },
          {
            NAME: "SetMetricWhereV2",
            VAL: [
              onboardingFunnelId,
              funnelItemId_3,
              onboardingStepCompletedId,
              Shortid(),
              onboardingStepNameId,
              "EQ",
              {
                hd: {
                  NAME: "StringLit",
                  VAL: "A"
                },
                tl: /* [] */0
              }
            ]
          },
          {
            NAME: "SetMetricWhereV2",
            VAL: [
              onboardingFunnelId,
              funnelItemId_4,
              onboardingStepCompletedId,
              Shortid(),
              onboardingStepNameId,
              "EQ",
              {
                hd: {
                  NAME: "StringLit",
                  VAL: "B"
                },
                tl: /* [] */0
              }
            ]
          },
          {
            NAME: "SetMetricWhereV2",
            VAL: [
              onboardingFunnelId,
              funnelItemId_5,
              onboardingStepCompletedId,
              Shortid(),
              onboardingStepNameId,
              "EQ",
              {
                hd: {
                  NAME: "StringLit",
                  VAL: "C"
                },
                tl: /* [] */0
              }
            ]
          }
        ]
      ]);
  return [
          Belt_List.fromArray(actions),
          context
        ];
}

function getName(prim) {
  return prim[1];
}

var BranchNameDuplicator = NameDuplicator.Make({
      getName: getName
    });

function create(user, hasCreatedDemoBranch, modelSources, openBranches, schemaBundle, sendActions, onSuccess, onError, hasEventSpecificAllowedPropertyValuesMigration) {
  var branchId = hasCreatedDemoBranch ? Shortid() : "demo-" + user.id;
  var match = user.name;
  var match$1 = user.email;
  var name = (match == null) ? (
      (match$1 == null) ? AvoConfig.anonymousUserDisplayName : Caml_array.get(match$1.split("@"), 0)
    ) : Caml_array.get(match.split(" "), 0);
  var demoBranchName = name + "'s Avo Demo Branch 🌳";
  var demoBranchName$1 = hasCreatedDemoBranch ? Curry._2(BranchNameDuplicator.duplicateNameFromList, demoBranchName, openBranches) : demoBranchName;
  var match$2 = getActionsAndContext(modelSources, branchId, demoBranchName$1, hasEventSpecificAllowedPropertyValuesMigration);
  Curry.app(sendActions, [
        {
          NAME: "Branch",
          VAL: branchId
        },
        false,
        undefined,
        undefined,
        undefined,
        true,
        undefined,
        onSuccess,
        onError,
        [[
            {
              NAME: "CreateDemoBranch",
              VAL: match$2[0]
            },
            match$2[1]
          ]]
      ]);
  Curry.app(sendActions, [
        "Master",
        false,
        undefined,
        undefined,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        [[
            {
              NAME: "OpenBranch",
              VAL: [
                branchId,
                demoBranchName$1
              ]
            },
            {
              branchId: branchId,
              branchQuery: branchId
            }
          ]]
      ]);
  return Collaborators.addCollaborator(schemaBundle, user.id, {
              NAME: "Branch",
              VAL: branchId
            }, "Branch", branchId, Caml_option.nullable_to_opt(user.email), "NewBranch", sendActions, undefined, undefined, user.id);
}

export {
  BranchNameDuplicator ,
  create ,
  
}
/* BranchNameDuplicator Not a pure module */
