// Generated by ReScript, PLEASE EDIT WITH CARE


function init(t, apiKey) {
  var options = {
    api_host: "https://app.posthog.com"
  };
  t.init(apiKey, options);
  
}

function convertToUserProp(property) {
  return {
          $set: property
        };
}

export {
  init ,
  convertToUserProp ,
  
}
/* No side effect */
