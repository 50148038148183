// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Semver from "semver";
import * as Version from "./version.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LoggedIn from "./LoggedIn.mjs";
import * as FetchModel from "./FetchModel.mjs";
import * as UpdatePrompt from "./UpdatePrompt.mjs";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as SsoAllowedChecker from "./SsoAllowedChecker.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as UnauthenticatedSchemaAccessWall from "./UnauthenticatedSchemaAccessWall.mjs";

function LoggedInForceUpdateWrapper(Props) {
  var userId = Props.userId;
  var authState = Props.authState;
  var baseRoute = Props.baseRoute;
  var settingsQuery = Props.settingsQuery;
  var forceReloadUser = Props.forceReloadUser;
  var config = FirebaseFetcherHooks.useAvoConfig(undefined);
  if (typeof config !== "object") {
    if (config === "NotFound") {
      return React.createElement(FetchModel.$$Error.make, {
                  message: "Could not fetch your workspace"
                });
    } else {
      return React.createElement(LoadingFullscreen.make, {
                  message: "Checking for updates..."
                });
    }
  }
  var config$1 = config.VAL;
  if (!Semver.satisfies(config$1.requiredVersion, "<= " + Version.current)) {
    return React.createElement(UpdatePrompt.make, {
                toVersion: config$1.requiredVersion
              });
  }
  if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
    var schemaId = baseRoute.VAL;
    return React.createElement(SsoAllowedChecker.make, {
                schemaId: schemaId,
                children: (function (state) {
                    if (state === "NotAllowed") {
                      return React.createElement(UnauthenticatedSchemaAccessWall.make, {
                                  schemaId: schemaId,
                                  forceReloadUser: forceReloadUser
                                });
                    } else if (state === "Allowed") {
                      return React.createElement(LoggedIn.make, {
                                  userId: userId,
                                  authState: authState,
                                  baseRoute: baseRoute,
                                  settingsQuery: settingsQuery
                                });
                    } else {
                      return React.createElement(LoadingFullscreen.make, {
                                  message: "Authenticating..."
                                });
                    }
                  })
              });
  }
  return React.createElement(LoggedIn.make, {
              userId: userId,
              authState: authState,
              baseRoute: baseRoute,
              settingsQuery: settingsQuery
            });
}

var make = React.memo(LoggedInForceUpdateWrapper, (function (props, nextProps) {
        if (props.userId === nextProps.userId && Caml_obj.caml_equal(props.authState, nextProps.authState) && Caml_obj.caml_equal(props.baseRoute, nextProps.baseRoute)) {
          return Caml_obj.caml_equal(props.settingsQuery.value, nextProps.settingsQuery.value);
        } else {
          return false;
        }
      }));

export {
  make ,
  
}
/* make Not a pure module */
