// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as CopyButton from "../CopyButton.mjs";

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, copyButtonBoxStyles, apiKey, language) {
  if (language === "java") {
    return React.createElement("div", {
                className: docsCodeBoxStyles
              }, React.createElement("pre", {
                    className: docsCodeStyles
                  }, "AvoInspector avoInspector = ", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, "new"), " AvoInspector(", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, "\"" + apiKey + "\""), React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ","), " \"1.0.0\"", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ","), " \"My App Name\"", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ","), " AvoInspectorEnv.", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, "Dev"), ")", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ";")), React.createElement("div", {
                    className: copyButtonBoxStyles
                  }, React.createElement(CopyButton.make, {
                        copyString: "AvoInspector avoInspector = new AvoInspector(\"" + apiKey + "\", \"1.0.0\", \"My App Name\", AvoInspectorEnv.Dev);"
                      })));
  } else {
    return null;
  }
}

export {
  code ,
  
}
/* react Not a pure module */
