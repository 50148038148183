// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function makeOption(param) {
  if (clearbit == null) {
    return ;
  } else {
    return Caml_option.some(clearbit);
  }
}

export {
  makeOption ,
  
}
/* No side effect */
