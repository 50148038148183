// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as CopyButton from "../CopyButton.mjs";
import * as InspectorViewInstallDocs from "./InspectorViewInstallDocs.mjs";

var docsCodeBoxStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.fontWeight("normal"),
        tl: /* [] */0
      }
    });

var docsCodeStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.marginTop(Css.px(12)),
          tl: {
            hd: Css.marginBottom(Css.px(0)),
            tl: {
              hd: Css.display("block"),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light02),
                tl: {
                  hd: Css.paddingTop(Css.px(12)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(8)),
                    tl: {
                      hd: Css.paddingRight(Css.px(70)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(12)),
                        tl: {
                          hd: Css.fontFamily(Styles.FontFamily.polar),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.medium),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.color(Styles.Color.light12),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                  tl: {
                                    hd: Css.borderRadius(Css.px(6)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var copyButtonBoxStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(7)),
        tl: {
          hd: Css.top(Css.px(7)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

function InspectorViewInstallDocsSegment(Props) {
  var apiKey = Props.apiKey;
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: "Follow the steps below to set up Avo as a Segment destination."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: "Once you’ve enabled the destination, the schemas of all events seen in Segment will automatically be sent to Inspector!"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement(InspectorViewInstallDocs.Link.make, {
                  path: "workspace/connect-inspector-to-segment",
                  label: "Inspector Segment integration docs"
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "How to set up Inspector with Segment:"
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 1,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: "You finished this step by creating this source. Well done! 🎉"
                      })
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 2,
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: null
                    }, "Copy this ", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "API key"
                        })), React.createElement("div", {
                      className: docsCodeBoxStyles
                    }, React.createElement("pre", {
                          className: docsCodeStyles
                        }, apiKey), React.createElement("div", {
                          className: copyButtonBoxStyles
                        }, React.createElement(CopyButton.make, {
                              copyString: apiKey
                            })))), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 3,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: null
                      }, "Set up the Segment Destination using the API key you copied in step 2.", React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement(Button.make, {
                            icon: "segment",
                            label: "Connect to Segment",
                            onClick: (function (param) {
                                window.open("https://app.segment.com/goto-my-workspace/destinations/catalog/actions-avo", "_blank");
                                
                              }),
                            style: {
                              NAME: "custom",
                              VAL: {
                                customColor: Styles.Color.white,
                                hoverColor: Styles.Color.white,
                                customBackgroundColor: Css.hex("52BD95"),
                                customBackgroundHoverColor: Styles.Color.darken(Css.hex("52BD95"), 8),
                                optionalCustomOutlineColor: undefined,
                                optionalCustomOutlineHoverColor: undefined
                              }
                            }
                          }))
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 4,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: null
                      }, React.createElement($$Text.make, {
                            element: "Span",
                            weight: "Semi",
                            children: "Enable"
                          }), " the destination, click ", React.createElement($$Text.make, {
                            element: "Span",
                            weight: "Semi",
                            children: "Save"
                          }), " and wait for Avo to receive events!")
                }));
}

var make = InspectorViewInstallDocsSegment;

export {
  docsCodeBoxStyles ,
  docsCodeStyles ,
  copyButtonBoxStyles ,
  make ,
  
}
/* docsCodeBoxStyles Not a pure module */
