// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "./Auth.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Share from "./Share.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Process from "./Process.mjs";
import * as Version from "./version.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Redirect from "./Redirect.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as LoggedOut from "./LoggedOut.mjs";
import * as FetchModel from "./FetchModel.mjs";
import * as Onboarding from "./Onboarding.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DemoSandbox from "./DemoSandbox.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as VerifyEmail from "./VerifyEmail.mjs";
import * as AuthorizeCli from "./AuthorizeCli.mjs";
import * as SandboxUtils from "../../shared/utils/SandboxUtils.mjs";
import * as Core from "@mantine/core";
import * as React$1 from "@sentry/react";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as LoggedInForceUpdateWrapper from "./LoggedInForceUpdateWrapper.mjs";
import * as UnauthenticatedSchemaAccessWall from "./UnauthenticatedSchemaAccessWall.mjs";

function App$DefaultSchema(Props) {
  var userId = Props.userId;
  var userSchemasState = FirebaseFetcherHooks.useUserSchemas(userId);
  var userSchemaStateExcludingSandbox = typeof userSchemasState === "object" ? ({
        NAME: "Loaded",
        VAL: Belt_List.keep(userSchemasState.VAL, (function (schema) {
                return !SandboxUtils.isSandbox(schema.id);
              }))
      }) : userSchemasState;
  if (typeof userSchemaStateExcludingSandbox !== "object") {
    if (userSchemaStateExcludingSandbox === "Initial") {
      return React.createElement(LoadingFullscreen.make, {
                  message: "Finding default workspace..."
                });
    } else {
      return React.createElement(FetchModel.$$Error.make, {
                  message: "Could not fetch your workspace"
                });
    }
  }
  var match = userSchemaStateExcludingSandbox.VAL;
  if (!match) {
    return React.createElement(Redirect.make, {
                path: "/welcome"
              });
  }
  var route = Router.getStaticRoute(undefined);
  var search = Router.getSearch(undefined);
  var schemaId = match.hd.id;
  var path = Curry._4(Router.Link.get, {
        NAME: "schema",
        VAL: schemaId
      }, route.branch, route.schemaRoute, /* [] */0) + search;
  return React.createElement(Redirect.make, {
              path: path
            });
}

var DefaultSchema = {
  make: App$DefaultSchema
};

function App$App(Props) {
  React.useEffect((function () {
          AnalyticsRe.appOpened(undefined);
          var fromVersion = Belt_Option.getWithDefault(Caml_option.null_to_opt(localStorage.getItem("avo.version")), "None");
          if (fromVersion !== Version.current) {
            AnalyticsRe.versionUpdated(fromVersion);
          }
          localStorage.setItem("avo.version", Version.current);
          
        }), []);
  var auth = Firebase.app(undefined).auth();
  var baseRoute = Router.useBaseRoute(undefined);
  var settingsQuery = Router.QueryParams.useStringKey("settings");
  var match = Auth.useUser(auth);
  var forceReloadUser = match[1];
  var user = match[0];
  var tmp;
  if (user !== undefined) {
    var exit = 0;
    var exit$1 = 0;
    var exit$2 = 0;
    if (typeof baseRoute === "object") {
      if (baseRoute.NAME === "share") {
        tmp = React.createElement(Share.make, {
              id: baseRoute.VAL,
              authState: user
            });
      } else {
        exit$2 = 3;
      }
    } else if (baseRoute === "sandbox") {
      tmp = React.createElement(DemoSandbox.make, {
            authState: user
          });
    } else {
      exit$2 = 3;
    }
    if (exit$2 === 3) {
      if (typeof user === "object") {
        var variant = user.NAME;
        if (variant === "Anonymous") {
          if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
            var schemaId = baseRoute.VAL;
            tmp = schemaId.startsWith("sandbox-") ? React.createElement(LoggedInForceUpdateWrapper.make, {
                    userId: user.VAL.uid,
                    authState: user,
                    baseRoute: baseRoute,
                    settingsQuery: settingsQuery,
                    forceReloadUser: forceReloadUser
                  }) : React.createElement(UnauthenticatedSchemaAccessWall.make, {
                    schemaId: schemaId,
                    forceReloadUser: forceReloadUser
                  });
          } else {
            exit$1 = 2;
          }
        } else if (variant === "Authenticated") {
          var user$1 = user.VAL;
          if (typeof baseRoute === "object") {
            var variant$1 = baseRoute.NAME;
            if (variant$1 === "auth") {
              tmp = React.createElement(AuthorizeCli.make, {
                    user: user$1,
                    cliRoute: baseRoute.VAL
                  });
            } else if (variant$1 === "schema" && baseRoute.VAL === "default") {
              tmp = React.createElement(App$DefaultSchema, {
                    userId: user$1.uid
                  });
            } else {
              exit$1 = 2;
            }
          } else {
            exit$1 = 2;
          }
        } else if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
          tmp = React.createElement(VerifyEmail.make, {
                user: user.VAL,
                schemaId: baseRoute.VAL
              });
        } else {
          exit$1 = 2;
        }
      } else if (typeof baseRoute === "object") {
        if (baseRoute.NAME === "schema") {
          tmp = React.createElement(UnauthenticatedSchemaAccessWall.make, {
                schemaId: baseRoute.VAL,
                forceReloadUser: forceReloadUser
              });
        } else {
          exit$1 = 2;
        }
      } else if (baseRoute === "index" || baseRoute === "notFound") {
        tmp = React.createElement(Redirect.make, {
              path: "/login"
            });
      } else {
        exit$1 = 2;
      }
    }
    if (exit$1 === 2) {
      if (typeof baseRoute === "object" && baseRoute.NAME === "onboarding") {
        tmp = React.createElement(Onboarding.make, {
              onboardingRoute: baseRoute.VAL,
              forceReloadUser: forceReloadUser
            });
      } else {
        exit = 1;
      }
    }
    if (exit === 1) {
      var exit$3 = 0;
      if (typeof user === "object") {
        var variant$2 = user.NAME;
        if (variant$2 === "Anonymous") {
          exit$3 = 2;
        } else {
          tmp = variant$2 === "Authenticated" ? React.createElement(LoggedInForceUpdateWrapper.make, {
                  userId: user.VAL.uid,
                  authState: user,
                  baseRoute: baseRoute,
                  settingsQuery: settingsQuery,
                  forceReloadUser: forceReloadUser
                }) : React.createElement(VerifyEmail.make, {
                  user: user.VAL
                });
        }
      } else {
        exit$3 = 2;
      }
      if (exit$3 === 2) {
        tmp = baseRoute === "signUp" || baseRoute === "invite" || baseRoute === "passwordReset" || baseRoute === "login" ? React.createElement(LoggedOut.make, {
                forceReloadUser: forceReloadUser
              }) : (
            typeof user === "object" ? React.createElement(Redirect.make, {
                    path: "/login?redirect=" + Router.buildRedirectPath(undefined)
                  }) : React.createElement(Redirect.make, {
                    path: "/login?redirect=" + Router.buildRedirectPath(undefined)
                  })
          );
      }
      
    }
    
  } else {
    tmp = null;
  }
  return React.createElement(Core.MantineProvider, {
              theme: Mantine.Utils.getTheme(undefined),
              children: null
            }, React.createElement(RouterStore.Watcher.make, {}), tmp);
}

var App = {
  make: App$App
};

function App$1(Props) {
  React.useEffect((function () {
          var match = Process.getVercelEnv(undefined);
          var match$1 = Process.getEnv(undefined);
          var sentryEnv = match === "preview" ? "Preview" : (
              match$1 === "development" ? "Development" : "Production"
            );
          Sentry.init(sentryEnv);
          
        }), []);
  return React.createElement(React$1.withProfiler(App$App), {});
}

var make = App$1;

export {
  DefaultSchema ,
  App ,
  make ,
  
}
/* Auth Not a pure module */
