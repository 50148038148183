// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Styles from "../styles.mjs";
import * as Switch from "../Switch.mjs";
import * as Actions from "../actions.mjs";
import * as AvoModel from "../avoModel.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CommentInput from "../CommentInput.mjs";
import * as DrawerHeader from "../detailviews/DrawerHeader.mjs";
import * as IconInfinity from "../IconInfinity.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as EntityLightning from "../EntityLightning.mjs";
import * as CollapsedContent from "../CollapsedContent.mjs";
import * as IntegrationUtils from "../../../api/functions/src/IntegrationUtils.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as IntegrationConfig from "./IntegrationConfig.mjs";
import * as IntegrationExport from "./IntegrationExport.mjs";
import * as IntegrationHeader from "./IntegrationHeader.mjs";
import * as ObjectActivityLog from "../ObjectActivityLog.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as IntegrationAnalytics from "./IntegrationAnalytics.mjs";
import * as IntegrationEventsFilter from "./IntegrationEventsFilter.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.width(Css.pct(100)),
          tl: {
            hd: Css.height(Css.pct(100.0)),
            tl: {
              hd: Css.marginLeft(Css.px(-2)),
              tl: {
                hd: Css.marginRight(Css.px(16)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var contentStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.paddingTop(Css.px(20)),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.paddingLeft(Css.px(52)),
            tl: {
              hd: Css.overflowY("auto"),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var filterStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingTop(Css.px(4)),
          tl: {
            hd: Css.paddingRight(Css.px(12)),
            tl: {
              hd: Css.paddingBottom(Css.px(4)),
              tl: {
                hd: Css.paddingLeft(Css.px(8)),
                tl: {
                  hd: Css.marginTop(Css.px(6)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.background(Styles.Color.light02),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var labelBoxStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: {
        hd: Css.marginBottom(Css.px(6)),
        tl: {
          hd: Css.marginLeft(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

var eventPillStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(2), Css.px(8)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light04),
        tl: {
          hd: Css.borderRadius(Css.px(100)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: {
              hd: Css.marginTop(Css.px(6)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var switchRowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.backgroundColor(Styles.Color.light02),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.padding2(Css.px(8), Css.px(10)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var commentBoxStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: {
        hd: Css.marginLeft(Css.px(-52)),
        tl: {
          hd: Css.marginRight(Css.px(-16)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light02),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: /* [] */0
            }
          }
        }
      }
    });

var activityLogBoxStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(-37)),
      tl: /* [] */0
    });

function updateFilters(integrations, integration, schemaBundle, sendActions, id, filters) {
  return Curry.app(sendActions, [
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              (function (branch) {
                  return IntegrationAnalytics.trackIntegrationConfigured(integrations, {
                              id: integration.id,
                              name: integration.name,
                              config: integration.config,
                              filters: filters,
                              autoPublish: integration.autoPublish
                            }, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "AutoPublish");
                }),
              undefined,
              [[
                  {
                    NAME: "UpdateIntegrationFilters",
                    VAL: [
                      id,
                      filters
                    ]
                  },
                  {
                    integrationId: id,
                    integrationQuery: id
                  }
                ]]
            ]);
}

function Integration(Props) {
  var integrationId = Props.integrationId;
  var goToCommentId = Props.goToCommentId;
  var integrations = Props.integrations;
  var openBranches = Props.openBranches;
  var currentBranch = Props.currentBranch;
  var model = Props.model;
  var role = Props.role;
  var addToast = Toast.useAddToast(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  var match = AvoLimits.Integration.entityStatus(workspaceLimits, integrationId);
  var planHint = match[1];
  var availability = match[0];
  var handleDeletePress = function (param) {
    if (window.confirm("Are you sure you want to delete this integration?")) {
      Router.Schema.replaceDrawerItems(undefined, Belt_List.keep(Router.getStaticRoute(undefined).drawerItems, (function (item) {
                  if (typeof item === "object" && item.NAME === "integration") {
                    return item.VAL[0] !== integrationId;
                  } else {
                    return true;
                  }
                })));
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [[
                {
                  NAME: "RemoveIntegration",
                  VAL: integrationId
                },
                {
                  integrationId: integrationId,
                  integrationQuery: integrationId
                }
              ]]
          ]);
      return Curry._1(addToast, {
                  message: "The integration has been deleted"
                });
    }
    
  };
  var integration = Belt_List.getBy(integrations, (function (integration) {
          return integration.id === integrationId;
        }));
  if (integration === undefined) {
    return "Integration not found.";
  }
  var events = integration.filters === /* [] */0 ? AvoModel.filterExcludedEventsBySourcesFromModel(model, IntegrationUtils.findSources(IntegrationUtils.findDestinations(integration, model), model)).events : AvoModel.filterExcludedEvents(model.events, integration.filters);
  var eventCount = Belt_List.length(events);
  var events$1 = integration.filters === /* [] */0 ? AvoModel.filterExcludedEventsBySourcesFromModel(model, IntegrationUtils.findSources(IntegrationUtils.findDestinations(integration, model), model)).events : AvoModel.filterExcludedEvents(model.events, integration.filters);
  var tmp = {
    object_: {
      NAME: "Integration",
      VAL: [
        integrationId,
        AvoConfig.getIntegrationName(integration)
      ]
    },
    objectId: integrationId,
    currentBranch: currentBranch,
    showComments: true,
    openBranches: openBranches
  };
  if (goToCommentId !== undefined) {
    tmp.goToCommentId = Caml_option.valFromOption(goToCommentId);
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(DrawerHeader.make, {
                  kind: "Integration",
                  options: [{
                      NAME: "Option",
                      VAL: {
                        label: "Delete Integration",
                        onClick: handleDeletePress
                      }
                    }],
                  children: React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        color: Styles.Color.light12,
                        children: AvoConfig.getIntegrationName(integration)
                      })
                }), React.createElement("div", {
                  className: contentStyles
                }, React.createElement(IntegrationHeader.make, {
                      role: role,
                      currentBranch: currentBranch,
                      integrations: integrations,
                      integration: integration,
                      availability: availability
                    }), React.createElement(IntegrationConfig.make, {
                      integrations: integrations,
                      integration: integration,
                      role: role
                    }), React.createElement("div", {
                      className: labelBoxStyles
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: "Events Filter"
                        })), React.createElement("div", {
                      className: filterStyles
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.flexGrow(1.0),
                                tl: /* [] */0
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.darkBlue,
                              children: (
                                Belt_List.length(model.events) === eventCount ? "All " : ""
                              ) + AvoUtils.plural(undefined, undefined, eventCount, "event") + " included"
                            })), React.createElement(IntegrationEventsFilter.make, {
                          model: model,
                          filters: integration.filters,
                          onRemove: (function (filterBy) {
                              return updateFilters(integrations, integration, schemaBundle, sendActions, integration.id, Belt_List.keepU(integration.filters, (function (item) {
                                                return Caml_obj.caml_notequal(item, filterBy);
                                              })));
                            }),
                          onAdd: (function (filterBy) {
                              var match = Belt_List.getBy(integration.filters, (function (item) {
                                      return Caml_obj.caml_equal(item, filterBy);
                                    }));
                              if (match !== undefined) {
                                return ;
                              } else {
                                return updateFilters(integrations, integration, schemaBundle, sendActions, integration.id, {
                                            hd: filterBy,
                                            tl: integration.filters
                                          });
                              }
                            })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.height(Css.px(8)),
                            tl: /* [] */0
                          })
                    }), React.createElement(CollapsedContent.make, {
                      collapseLabel: "Hide events names",
                      expandLabel: "Show all event names",
                      children: (function (param) {
                          return React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.flexWrap("wrap"),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, Belt_List.toArray(Belt_List.mapU(events$1, (function ($$event) {
                                                return React.createElement("div", {
                                                            className: eventPillStyles
                                                          }, React.createElement($$Text.make, {
                                                                size: "Small",
                                                                weight: "Semi",
                                                                color: Styles.Color.light11,
                                                                children: $$event.name
                                                              }));
                                              }))));
                        })
                    }), React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: labelBoxStyles
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: null
                            }, "Auto Publishing", React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginLeft(Css.px(4)),
                                        tl: {
                                          hd: Css.display("inlineFlex"),
                                          tl: {
                                            hd: Css.verticalAlign("top"),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement(EntityLightning.make, {
                                      availability: availability,
                                      planHint: planHint,
                                      tooltipDirection: "right",
                                      stroke: Css.transparent,
                                      fill: Styles.Color.light10
                                    })))), React.createElement("div", {
                          className: switchRowStyles
                        }, React.createElement(IconInfinity.make, {}), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.flexGrow(1.0),
                                    tl: {
                                      hd: Css.marginLeft(Css.px(8)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.light11,
                                  children: "Automatically publish changes when branches are merged?"
                                })), React.createElement(Switch.make, {
                              value: integration.autoPublish,
                              onChange: (function (autoPublish) {
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  return IntegrationAnalytics.trackIntegrationConfigured(integrations, {
                                                              id: integration.id,
                                                              name: integration.name,
                                                              config: integration.config,
                                                              filters: integration.filters,
                                                              autoPublish: autoPublish
                                                            }, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "AutoPublish");
                                                }),
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "UpdateIntegrationAutoPublish",
                                                    VAL: [
                                                      integration.id,
                                                      autoPublish
                                                    ]
                                                  },
                                                  {
                                                    integrationId: integration.id,
                                                    integrationQuery: integration.id
                                                  }
                                                ]]
                                            ]);
                                }),
                              disabled: availability === "Unavailable"
                            }))), React.createElement("div", {
                      className: labelBoxStyles
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: null
                        }, "Payload preview", React.createElement("span", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginLeft(Css.px(4)),
                                    tl: {
                                      hd: Css.display("inlineFlex"),
                                      tl: {
                                        hd: Css.verticalAlign("top"),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement(EntityLightning.make, {
                                  availability: availability,
                                  planHint: planHint,
                                  tooltipDirection: "right",
                                  stroke: Css.transparent,
                                  fill: Styles.Color.light10
                                })))), React.createElement(IntegrationExport.make, {
                      schemaId: workspace.id,
                      branchId: Actions.branchToId(currentBranch),
                      integration: integration,
                      availability: availability
                    }), React.createElement("div", {
                      className: labelBoxStyles
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: "Activity Log"
                        })), React.createElement("div", {
                      className: activityLogBoxStyles
                    }, React.createElement(ObjectActivityLog.make, tmp)), React.createElement("div", {
                      className: commentBoxStyles
                    }, React.createElement(CommentInput.make, {
                          threadId: integration.id,
                          itemType: "Integration",
                          itemName: AvoConfig.getIntegrationName(integration),
                          mini: true,
                          currentBranch: currentBranch,
                          role: role,
                          location: "Integration"
                        }))));
}

var make = Integration;

export {
  rootStyles ,
  contentStyles ,
  filterStyles ,
  labelBoxStyles ,
  eventPillStyles ,
  switchRowStyles ,
  commentBoxStyles ,
  activityLogBoxStyles ,
  updateFilters ,
  make ,
  
}
/* rootStyles Not a pure module */
