// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function getFontWeight(x) {
  if (x === "regular") {
    return Styles.FontWeight.regular;
  } else {
    return Styles.FontWeight.medium;
  }
}

function Monospace(Props) {
  var customColor = Props.color;
  var customWeight = Props.weight;
  var children = Props.children;
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Belt_Option.mapWithDefault(customWeight, Styles.emptyStyle, (function (w) {
                            return Css.fontWeight(getFontWeight(w));
                          })),
                    tl: {
                      hd: Belt_Option.mapWithDefault(customColor, Styles.emptyStyle, Css.color),
                      tl: {
                        hd: Css.fontFamily(Styles.FontFamily.polar),
                        tl: /* [] */0
                      }
                    }
                  })
            }, children);
}

var make = Monospace;

export {
  getFontWeight ,
  make ,
  
}
/* Css Not a pure module */
