// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as NameDuplicator from "../../model/src/NameDuplicator.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function getName(name) {
  return name;
}

var NameDuplicator$1 = NameDuplicator.Make({
      getName: getName
    });

function getUniqueName(events, eventId, eventName) {
  var hasUniqueName = {
    contents: true
  };
  var eventNames = Belt_Array.concat(Curry._2(TrackingPlanMappedModel.Events.keepMap, events, (function (param) {
              if (param.id === eventId) {
                return ;
              }
              var name = param.name;
              if (name === eventName) {
                hasUniqueName.contents = false;
              }
              return name;
            })), Curry._2(TrackingPlanMappedModel.Events.keepMap, events, (function (param) {
              if (param.id === eventId) {
                return ;
              }
              var uniqueName = param.uniqueName;
              if (Caml_obj.caml_equal(eventName, uniqueName)) {
                hasUniqueName.contents = false;
              }
              return uniqueName;
            })));
  if (hasUniqueName.contents) {
    return ;
  } else {
    return Curry._2(NameDuplicator$1.duplicateNameFromArray, eventName, eventNames);
  }
}

export {
  NameDuplicator$1 as NameDuplicator,
  getUniqueName ,
  
}
/* NameDuplicator Not a pure module */
