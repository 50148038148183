// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function trimTrailingNewlines(str) {
  return str.replace(/\n+$/, "");
}

function quillToPlainText(quill) {
  try {
    var quillOperations = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(JSON.parse(quill)), (function (item) {
                    return Js_dict.get(item, "ops");
                  })), Js_json.decodeArray), []);
    return trimTrailingNewlines(Belt_Array.reduce(quillOperations, "", (function (description, descriptionQuillOperationJson) {
                      var descriptionQuillOperation = Belt_Option.getWithDefault(Js_json.decodeObject(descriptionQuillOperationJson), {});
                      var insertText = Belt_Option.flatMapU(Js_dict.get(descriptionQuillOperation, "insert"), Js_json.decodeString);
                      if (insertText !== undefined) {
                        return description + insertText;
                      } else {
                        return description;
                      }
                    })));
  }
  catch (_error){
    return quill;
  }
}

export {
  trimTrailingNewlines ,
  quillToPlainText ,
  
}
/* No side effect */
