// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Hooks from "./Hooks.mjs";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Process from "./Process.mjs";
import * as Sidebar from "./Sidebar.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Markdown from "./Markdown.mjs";
import * as Mixpanel from "./externals/Mixpanel.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Timestamp from "./Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Dom_storage from "rescript/lib/es6/dom_storage.js";
import * as IconSources from "./IconSources.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as SnippetTypes from "./detailviews/SnippetTypes.mjs";
import * as SnippetUtils from "./detailviews/SnippetUtils.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as DiffViewTypeDropdown from "./branchImplementation/DiffViewTypeDropdown.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as SourceSelectDropdown from "./branchImplementation/SourceSelectDropdown.mjs";
import * as BranchImplementationHooks from "./branchImplementation/BranchImplementationHooks.mjs";
import * as BranchImplementationTypes from "./branchImplementation/BranchImplementationTypes.mjs";
import * as BranchImplementationUtils from "./branchImplementation/BranchImplementationUtils.mjs";
import * as PublicCodeChangesOverview from "./branchImplementation/public/PublicCodeChangesOverview.mjs";
import * as EventImplementationSnippet from "./detailviews/EventImplementationSnippet.mjs";
import * as ImplementationShareAnalyticsEndpoint from "../../shared/endpoints/ImplementationShareAnalyticsEndpoint.mjs";

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.marginLeft(Css.px(Sidebar.sidebarWidth)),
          tl: {
            hd: Css.paddingTop(Css.px(65)),
            tl: {
              hd: Css.paddingBottom(Css.px(20)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var sidebar = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.position("fixed"),
          tl: {
            hd: Css.justifyContent("stretch"),
            tl: {
              hd: Css.top(Css.px(0)),
              tl: {
                hd: Css.bottom(Css.px(0)),
                tl: {
                  hd: Css.left(Css.px(0)),
                  tl: {
                    hd: Css.padding(Css.px(16)),
                    tl: {
                      hd: Css.width(Css.px(Sidebar.sidebarWidth)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.light12),
                        tl: {
                          hd: Css.zIndex(Styles.ZIndex.sidebar),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var sidebarWorkspace = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(-8)),
      tl: {
        hd: Css.marginRight(Css.px(-8)),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.light11),
          tl: {
            hd: Css.borderRadius(Css.px(10)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light12),
              tl: {
                hd: Css.padding(Css.px(8)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var sidebarWorkspaceDivider = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "add",
            VAL: [
              Css.pct(100.0),
              Css.px(16)
            ]
          }),
      tl: {
        hd: Css.height(Css.px(1)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.light11),
          tl: {
            hd: Css.marginLeft(Css.px(-8)),
            tl: {
              hd: Css.marginRight(Css.px(-8)),
              tl: {
                hd: Css.marginTop(Css.px(8)),
                tl: {
                  hd: Css.marginBottom(Css.px(8)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var sidebarWorkspaceBottom = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(Sidebar.sidebarWidth)),
            tl: {
              hd: Css.padding(Css.px(20)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(20), undefined, undefined, Css.rgba(0, 0, 0, {
                                NAME: "num",
                                VAL: 0.05
                              }))),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.gap(Css.px(8)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var $$event = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(936)),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.padding(Css.px(16)),
              tl: {
                hd: Css.marginTop(Css.px(16)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var eventHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.gap(Css.px(8)),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

function eventLifecycle(status) {
  return Curry._1(Css.style, {
              hd: Css.borderRadius(Css.px(10)),
              tl: {
                hd: Css.padding2(Css.px(2), Css.px(8)),
                tl: {
                  hd: Css.backgroundColor(status === "added" || status === "new" ? Styles.Color.lightGreen : (
                          status === "updated" ? Styles.Color.lightPurple : Styles.Color.lightRed
                        )),
                  tl: {
                    hd: Css.color(status === "added" || status === "new" ? Styles.Color.darkGreen : (
                            status === "updated" ? Styles.Color.primaryPurple : Styles.Color.deepRed
                          )),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function statusPillStyles(customColor) {
  return Curry._1(Css.style, {
              hd: Css.marginRight(Css.px(4)),
              tl: {
                hd: Css.borderRadius(Css.px(32)),
                tl: {
                  hd: Css.paddingLeft(Css.px(6)),
                  tl: {
                    hd: Css.paddingRight(Css.px(6)),
                    tl: {
                      hd: Css.paddingTop(Css.px(2)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(1)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.setAlpha(customColor, 0.1)),
                          tl: {
                            hd: Css.selector(" p", {
                                  hd: Css.fontSize(Css.px(9)),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.color(customColor),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var viewControls = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.gap(Css.px(8)),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.maxWidth(Css.px(936)),
            tl: {
              hd: Css.paddingTop(Css.px(8)),
              tl: {
                hd: Css.paddingBottom(Css.px(8)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var viewControlsContainer = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(65)),
        tl: {
          hd: Css.paddingLeft(Css.px(20)),
          tl: {
            hd: Css.zIndex(1),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light02, 0.93)),
              tl: {
                hd: Css.backdropFilter({
                      hd: {
                        NAME: "blur",
                        VAL: Css.px(3)
                      },
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Style = {
  root: root,
  sidebar: sidebar,
  sidebarWorkspace: sidebarWorkspace,
  sidebarWorkspaceDivider: sidebarWorkspaceDivider,
  sidebarWorkspaceBottom: sidebarWorkspaceBottom,
  header: header,
  $$event: $$event,
  eventHeader: eventHeader,
  eventLifecycle: eventLifecycle,
  statusPillStyles: statusPillStyles,
  viewControls: viewControls,
  viewControlsContainer: viewControlsContainer
};

function ShareBranchImplement$WorkspaceLinkButton$WithUserId(Props) {
  var userId = Props.userId;
  var branchId = Props.branchId;
  var schemaId = Props.schemaId;
  var maybeSourceId = Props.maybeSourceId;
  var memberState = FirebaseFetcherHooks.useMember(schemaId, userId, true);
  return React.createElement(Button.make, {
              label: typeof memberState === "object" && memberState.NAME === "Success" ? "Open in Avo" : "Log in to Avo",
              onClick: (function (param) {
                  return Router.push(undefined, Router.Link.getSchemaLink({
                                  NAME: "schema",
                                  VAL: schemaId
                                }, {
                                  NAME: "branch",
                                  VAL: branchId
                                }, {
                                  NAME: "diff",
                                  VAL: {
                                    NAME: "implementation",
                                    VAL: maybeSourceId !== undefined ? ({
                                          NAME: "source",
                                          VAL: [
                                            maybeSourceId,
                                            undefined
                                          ]
                                        }) : "overview"
                                  }
                                }, /* [] */0));
                }),
              minWidth: Sidebar.sidebarWidth - 32 | 0
            });
}

var WithUserId = {
  make: ShareBranchImplement$WorkspaceLinkButton$WithUserId
};

function ShareBranchImplement$WorkspaceLinkButton(Props) {
  var maybeUserId = Props.userId;
  var branchId = Props.branchId;
  var schemaId = Props.schemaId;
  var maybeSourceId = Props.maybeSourceId;
  if (maybeUserId !== undefined) {
    return React.createElement(ShareBranchImplement$WorkspaceLinkButton$WithUserId, {
                userId: maybeUserId,
                branchId: branchId,
                schemaId: schemaId,
                maybeSourceId: maybeSourceId
              });
  } else {
    return React.createElement(Button.make, {
                label: "Log in to Avo",
                onClick: (function (param) {
                    return Router.push(undefined, Router.Link.getSchemaLink({
                                    NAME: "schema",
                                    VAL: schemaId
                                  }, {
                                    NAME: "branch",
                                    VAL: branchId
                                  }, {
                                    NAME: "diff",
                                    VAL: {
                                      NAME: "implementation",
                                      VAL: maybeSourceId !== undefined ? ({
                                            NAME: "source",
                                            VAL: [
                                              maybeSourceId,
                                              undefined
                                            ]
                                          }) : "overview"
                                    }
                                  }, /* [] */0));
                  }),
                minWidth: Sidebar.sidebarWidth - 32 | 0
              });
  }
}

var WorkspaceLinkButton = {
  WithUserId: WithUserId,
  make: ShareBranchImplement$WorkspaceLinkButton
};

function sendEventWithFetch(headers, body) {
  return fetch(Firebase.apiUrl + "/share/e", Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(JSON.stringify(body)), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
}

function sendEvent(eventName, eventProperties, shareId) {
  var mixpanel$1 = Mixpanel.make(undefined);
  if (mixpanel$1 === undefined) {
    if (Process.getEnv(undefined) === "development") {
      console.warn("ShareBranchImplement" + ", line " + (246).toString() + ":\n", [
            "Mixpanel not present, not sending event!: " + BranchImplementationTypes.eventToJs(eventName) + "'",
            eventProperties,
            mixpanel
          ]);
      return ;
    } else {
      return ;
    }
  }
  var headerArray = [
    [
      "Accept",
      "application/json"
    ],
    [
      "Content-Type",
      "application/json"
    ]
  ];
  var body = ImplementationShareAnalyticsEndpoint.$$Request.encode({
        shareId: shareId,
        distinctId: Caml_option.valFromOption(mixpanel$1).get_distinct_id(),
        eventName: BranchImplementationTypes.eventToJs(eventName),
        eventProperties: eventProperties
      });
  var user = Firebase.app(undefined).auth().currentUser;
  if (user == null) {
    if (Process.getEnv(undefined) === "development") {
      console.log("ShareBranchImplement" + ", line " + (215).toString() + ":\n", [
            "Sending event unauthenticated: " + BranchImplementationTypes.eventToJs(eventName) + "'",
            eventProperties
          ]);
    }
    sendEventWithFetch(headerArray, body);
  } else {
    user.getIdToken().then(function (idToken) {
          if (Process.getEnv(undefined) === "development") {
            console.log("ShareBranchImplement" + ", line " + (229).toString() + ":\n", [
                  "Sending event authenticated: " + BranchImplementationTypes.eventToJs(eventName) + "'",
                  eventProperties
                ]);
          }
          return sendEventWithFetch(Belt_Array.concat(headerArray, [[
                            "Authorization",
                            "Bearer " + idToken
                          ]]), body);
        });
  }
  
}

function branchImplementationInteraction(diffType, branchImplementationInteractionType, shareId) {
  var eventProperties = Json_encode.object_({
        hd: [
          "diffType",
          AnalyticsRe.diffTypeToJs(diffType)
        ],
        tl: {
          hd: [
            "branchImplementationInteractionType",
            AnalyticsRe.branchImplementationInteractionTypeToJs(branchImplementationInteractionType)
          ],
          tl: /* [] */0
        }
      });
  return sendEvent("Branch Implementation Interaction", eventProperties, shareId);
}

function branchImplementationPubliceViewOpened(diffType, shareId) {
  var eventProperties = Json_encode.object_({
        hd: [
          "diffType",
          AnalyticsRe.diffTypeToJs(diffType)
        ],
        tl: /* [] */0
      });
  return sendEvent("Branch Implementation Public View Opened", eventProperties, shareId);
}

function branchImplementViewConfigured(branchImplementationConfiguredAction, fromDiffType, diffType, fromSourceName, sourceName, shareId) {
  var eventProperties = Json_encode.object_(Belt_List.concat({
            hd: [
              "branchImplementationConfiguredAction",
              AnalyticsRe.branchImplementationConfiguredActionToJs(branchImplementationConfiguredAction)
            ],
            tl: {
              hd: [
                "fromDiffType",
                AnalyticsRe.diffTypeToJs(fromDiffType)
              ],
              tl: {
                hd: [
                  "diffType",
                  AnalyticsRe.diffTypeToJs(diffType)
                ],
                tl: {
                  hd: [
                    "sourceName",
                    sourceName
                  ],
                  tl: /* [] */0
                }
              }
            }
          }, Belt_Option.mapWithDefault(fromSourceName, /* [] */0, (function (fromSourceName) {
                  return {
                          hd: [
                            "fromSourceName",
                            fromSourceName
                          ],
                          tl: /* [] */0
                        };
                }))));
  return sendEvent("Branch Implement View Configured", eventProperties, shareId);
}

function eventImplementationSnippetInteraction(eventId, eventName, eventImplementationSnippetInteractionType, shareId) {
  var eventProperties = Json_encode.object_({
        hd: [
          "eventId",
          eventId
        ],
        tl: {
          hd: [
            "eventName",
            eventName
          ],
          tl: {
            hd: [
              "eventImplementationSnippetInteractionType",
              AnalyticsRe.eventImplementationSnippetInteractionTypeToJs(eventImplementationSnippetInteractionType)
            ],
            tl: /* [] */0
          }
        }
      });
  return sendEvent("Event Implementation Snippet Interaction", eventProperties, shareId);
}

function tooltipDisplayed(tooltipName, tooltipPurpose, tooltipDisplayMethod, shareId) {
  var eventProperties = Json_encode.object_({
        hd: [
          "tooltipName",
          tooltipName
        ],
        tl: {
          hd: [
            "tooltipPurpose",
            AnalyticsRe.tooltipPurposeToJs(tooltipPurpose)
          ],
          tl: {
            hd: [
              "tooltipDisplayMethod",
              AnalyticsRe.tooltipDisplayMethodToJs(tooltipDisplayMethod)
            ],
            tl: /* [] */0
          }
        }
      });
  return sendEvent("Tooltip Displayed", eventProperties, shareId);
}

function tooltipInteracted(tooltipName, tooltipPurpose, tooltipDisplayMethod, tooltipInteractionType, shareId) {
  var eventProperties = Json_encode.object_({
        hd: [
          "tooltipName",
          tooltipName
        ],
        tl: {
          hd: [
            "tooltipPurpose",
            AnalyticsRe.tooltipPurposeToJs(tooltipPurpose)
          ],
          tl: {
            hd: [
              "tooltipDisplayMethod",
              AnalyticsRe.tooltipDisplayMethodToJs(tooltipDisplayMethod)
            ],
            tl: {
              hd: [
                "tooltipInteractionType",
                AnalyticsRe.tooltipInteractionTypeToJs(tooltipInteractionType)
              ],
              tl: /* [] */0
            }
          }
        }
      });
  return sendEvent("Tooltip Interacted", eventProperties, shareId);
}

var ServerAnalytics = {
  branchImplementationInteraction: branchImplementationInteraction,
  branchImplementationPubliceViewOpened: branchImplementationPubliceViewOpened,
  branchImplementViewConfigured: branchImplementViewConfigured,
  eventImplementationSnippetInteraction: eventImplementationSnippetInteraction,
  tooltipDisplayed: tooltipDisplayed,
  tooltipInteracted: tooltipInteracted
};

function ShareBranchImplement$Sidebar(Props) {
  var userId = Props.userId;
  var schemaId = Props.schemaId;
  var schemaName = Props.schemaName;
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var maybeSourceId = Props.maybeSourceId;
  var lastRefreshed = React.useMemo((function () {
          return new Date();
        }), []);
  var timestamp = Timestamp.use(lastRefreshed);
  return React.createElement("div", {
              className: sidebar
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("h1", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin("zero"),
                            tl: {
                              hd: Css.lineHeight(Css.px(21)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Icons.AvoLogo.make, {
                          width: 60,
                          fill: Styles.Color.white
                        })), userId !== undefined ? React.createElement(ProfilePhoto.FromUserId.make, {
                        id: userId,
                        size: 32,
                        withBorder: false
                      }) : null), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: sidebarWorkspace
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.white,
                      children: schemaName
                    }), React.createElement("div", {
                      className: sidebarWorkspaceDivider
                    }), React.createElement("div", {
                      className: sidebarWorkspaceBottom
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.flexShrink(0.0),
                                tl: /* [] */0
                              })
                        }, React.createElement(Icon.make, {
                              type_: "branch",
                              size: {
                                NAME: "int",
                                VAL: 12
                              },
                              color: Styles.Color.light08
                            })), React.createElement(Spacer.make, {
                          width: 4
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.light08,
                          children: branchName
                        }))), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.width("fitContent"),
                        tl: /* [] */0
                      })
                }, timestamp !== "less than a minute ago" ? React.createElement(Tooltip.make, {
                        position: "right",
                        title: "Refresh Info",
                        tooltipPadding: 12,
                        withAskUsAnything: false,
                        withDismiss: false,
                        withTitle: false,
                        children: null
                      }, React.createElement(Tooltip.Target.make, {
                            children: React.createElement($$Text.make, {
                                  size: "Tiny",
                                  weight: "Semi",
                                  color: Styles.Color.light08,
                                  children: "Loaded " + timestamp
                                })
                          }), React.createElement(Tooltip.Content.make, {
                            children: React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light02,
                                  children: "The Avo web app updates in real time but this public view needs to be manually refreshed to fetch the tracking plan changes that might have happened since the page was loaded."
                                })
                          })) : null), React.createElement(Spacer.make, {
                  height: 12,
                  grow: 1.0
                }), React.createElement(ShareBranchImplement$WorkspaceLinkButton, {
                  userId: userId,
                  branchId: branchId,
                  schemaId: schemaId,
                  maybeSourceId: maybeSourceId
                }));
}

var Sidebar$1 = {
  make: ShareBranchImplement$Sidebar
};

function ShareBranchImplement$BranchHeader(Props) {
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var approvalWorkflow = Props.approvalWorkflow;
  return React.createElement("header", {
              className: header
            }, React.createElement(Icon.make, {
                  type_: "branch",
                  size: "small",
                  color: Styles.Color.light09
                }), React.createElement($$Text.make, {
                  size: "Large",
                  weight: "Semi",
                  singleLine: true,
                  color: Styles.Color.light12,
                  children: branchName
                }), branchStatus !== "Unknown" ? React.createElement("div", {
                    className: statusPillStyles(branchStatus === "ChangesRequested" || branchStatus === "Closed" ? Styles.Color.red : (
                            branchStatus === "Approved" ? Styles.Color.darkGreen : (
                                branchStatus === "Open" || branchStatus === "Unknown" || branchStatus === "Draft" ? (
                                    approvalWorkflow ? Styles.Color.light11 : Styles.Color.light12
                                  ) : (
                                    branchStatus === "Merged" ? Styles.Color.darkBlue : Styles.Color.orange
                                  )
                              )
                          ))
                  }, React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Semi",
                        lines: 1,
                        children: branchStatus === "Closed" ? "DELETED" : (
                            branchStatus === "ChangesRequested" ? "CHANGES REQUESTED" : (
                                branchStatus === "Approved" ? "APPROVED" : (
                                    branchStatus === "Open" || branchStatus === "Draft" ? (
                                        approvalWorkflow ? "DRAFT" : "OPEN"
                                      ) : (
                                        branchStatus === "Unknown" ? null : (
                                            branchStatus === "Merged" ? "MERGED" : "READY FOR REVIEW"
                                          )
                                      )
                                  )
                              )
                          )
                      })) : null);
}

var BranchHeader = {
  make: ShareBranchImplement$BranchHeader
};

function ShareBranchImplement$CodeChangesHeader(Props) {
  var shareId = Props.shareId;
  var eventsAffected = Props.eventsAffected;
  var sourcesCount = Props.sourcesCount;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100.0)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.padding(Css.px(20)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.gap(Css.px(4)),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Branch Code Changes"
                    }), React.createElement(Tooltip.make, {
                      onDismiss: (function (param) {
                          return tooltipInteracted("Code Changes", "Documentation", "OnHover", "Dismiss", shareId);
                        }),
                      onDisplay: (function (param) {
                          return tooltipDisplayed("Code Changes", "Documentation", "OnHover", shareId);
                        }),
                      onInteraction: (function (tooltipInteractionType) {
                          return tooltipInteracted("Code Changes", "Documentation", "OnHover", tooltipInteractionType, shareId);
                        }),
                      size: "small",
                      title: "Code Changes",
                      children: React.createElement(Tooltip.Content.make, {
                            children: React.createElement(Markdown.make, {
                                  source: Tooltips.codeSnippets
                                })
                          })
                    })), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light10,
                  children: (
                    eventsAffected !== 1 ? String(eventsAffected) + " events" : "1 event"
                  ) + " on " + (
                    sourcesCount !== 1 ? String(sourcesCount) + " sources" : "1 source"
                  ) + " " + (
                    eventsAffected !== 1 ? "are" : "is"
                  ) + " affected by this branch"
                }));
}

var CodeChangesHeader = {
  make: ShareBranchImplement$CodeChangesHeader
};

function ShareBranchImplement$ViewControls(Props) {
  var diffViewType = Props.diffViewType;
  var diffViewTypeOptions = Props.diffViewTypeOptions;
  var eventCount = Props.eventCount;
  var onDiffViewTypeSelect = Props.onDiffViewTypeSelect;
  var onSourceSelect = Props.onSourceSelect;
  var selectedSourceId = Props.selectedSourceId;
  var sourceOptions = Props.sourceOptions;
  var tmp = {
    data: sourceOptions,
    zIndex: 900,
    eventCount: eventCount,
    onChange: onSourceSelect
  };
  var tmp$1 = selectedSourceId !== undefined ? selectedSourceId : "Overview";
  if (tmp$1 !== undefined) {
    tmp.value = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", {
              className: viewControlsContainer
            }, React.createElement("div", {
                  className: viewControls
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Source"
                    }), React.createElement(SourceSelectDropdown.make, tmp), React.createElement(Spacer.make, {
                      width: 0,
                      grow: 1.0
                    }), selectedSourceId !== undefined ? React.createElement(DiffViewTypeDropdown.make, {
                        data: diffViewTypeOptions,
                        icon: React.createElement(Icon.make, {
                              type_: diffViewType === "noDiff" ? "noDiff" : (
                                  diffViewType === "unified" ? "unifiedDiff" : "splitDiff"
                                ),
                              size: "small",
                              color: Styles.Color.light10
                            }),
                        value: diffViewType,
                        onChange: onDiffViewTypeSelect
                      }) : null));
}

var ViewControls = {
  make: ShareBranchImplement$ViewControls
};

function ShareBranchImplement$EventSnippet(Props) {
  var branchStatus = Props.branchStatus;
  var branchName = Props.branchName;
  var hasCodegenForSource = Props.hasCodegenForSource;
  var mockedSource = Props.mockedSource;
  var lifecycle = Props.lifecycle;
  var diff = Props.diff;
  var eventId = Props.eventId;
  var eventName = Props.eventName;
  var eventDescription = Props.eventDescription;
  var diffViewType = Props.diffViewType;
  var shareId = Props.shareId;
  var containerRef = React.useRef(null);
  SnippetUtils.useScrollIntoView(eventId, "PublicBranchImplementation", 66 + BranchImplementationUtils.codeChangesHeaderHeight | 0, containerRef);
  var tmp = {
    title: React.createElement(EventImplementationSnippet.SingleSource.make, {
          diff: diff
        }),
    diff: diff,
    snippetLink: SnippetUtils.getSnippetLink(eventId, mockedSource.id, "PublicBranchImplementation"),
    enableCollapse: true,
    diffViewType: diffViewType,
    eventDiffState: lifecycle,
    onLinkCopied: (function (param) {
        return eventImplementationSnippetInteraction(eventId, eventName, "LinkToSnippetCopied", shareId);
      }),
    onCodeCopied: (function (param) {
        return eventImplementationSnippetInteraction(eventId, eventName, "CodeSnippetCopied", shareId);
      }),
    onToggleCollapse: (function (becameExpanded) {
        return eventImplementationSnippetInteraction(eventId, eventName, becameExpanded ? "SnippetExpanded" : "SnippetCollapsed", shareId);
      }),
    onCodeManuallyCopied: (function (param) {
        return eventImplementationSnippetInteraction(eventId, eventName, "CodeSnippetCopiedManually", shareId);
      }),
    key: eventId
  };
  var tmp$1 = branchStatus === "Merged" || branchStatus === "Closed" || !hasCodegenForSource ? undefined : Caml_option.some(React.createElement(EventImplementationSnippet.Footer.make, {
              branchName: branchName,
              onCliLinkClicked: (function (param) {
                  return branchImplementationInteraction(SnippetTypes.DiffViewType.toAnalyticsDiffType(diffViewType), "AvoCLIDocsLinkClicked", shareId);
                }),
              onCopyClick: (function (param) {
                  return branchImplementationInteraction(SnippetTypes.DiffViewType.toAnalyticsDiffType(diffViewType), "CLIStringCopied", shareId);
                }),
              onTooltipDismiss: (function (tooltipName) {
                  return tooltipInteracted(tooltipName, "Documentation", "OnHover", "Dismiss", shareId);
                }),
              onTooltipDisplay: (function (tooltipName) {
                  return tooltipDisplayed(tooltipName, "Documentation", "OnHover", shareId);
                }),
              onTooltipInteraction: (function (tooltipName, tooltipInteractionType) {
                  return tooltipInteracted(tooltipName, "Documentation", "OnHover", tooltipInteractionType, shareId);
                }),
              sourceName: AvoConfig.getSourceName(mockedSource)
            }));
  if (tmp$1 !== undefined) {
    tmp.footer = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("article", {
              key: eventId,
              ref: containerRef,
              className: $$event
            }, React.createElement("header", {
                  className: eventHeader
                }, React.createElement("span", {
                      className: eventLifecycle(lifecycle)
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          children: lifecycle === "new" ? "New" : (
                              lifecycle === "deleted" ? "Deleted" : (
                                  lifecycle === "added" ? "Added" : (
                                      lifecycle === "updated" ? "Updated" : "Removed"
                                    )
                                )
                            )
                        })), React.createElement($$Text.make, {
                      element: "Span",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: eventName
                    })), eventDescription.trim().length > 0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginTop(Css.px(8)),
                          tl: {
                            hd: Css.marginLeft(Css.px(4)),
                            tl: {
                              hd: Css.marginRight(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light09,
                        children: eventDescription
                      })) : null, React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement(EventImplementationSnippet.Simple.make, tmp));
}

var EventSnippet = {
  make: ShareBranchImplement$EventSnippet
};

function ShareBranchImplement$CodeChanges(Props) {
  var data = Props.data;
  var diffViewType = Props.diffViewType;
  var implementedManually = Props.implementedManually;
  var implementedWithCodegen = Props.implementedWithCodegen;
  var selectedSourceId = Props.selectedSourceId;
  var shareId = Props.shareId;
  var tmp;
  if (Caml_obj.caml_notequal(implementedWithCodegen, [])) {
    var count = implementedWithCodegen.length;
    tmp = React.createElement($$Text.make, {
          size: "Small",
          color: Styles.Color.light10,
          children: (
            count !== 1 ? String(count) + " events" : "1 event"
          ) + " can been implemented using Codegen."
        });
  } else {
    tmp = null;
  }
  var tmp$1;
  if (Caml_obj.caml_notequal(implementedManually, [])) {
    var count$1 = implementedManually.length;
    tmp$1 = React.createElement($$Text.make, {
          size: "Small",
          color: Styles.Color.light10,
          children: (
            count$1 !== 1 ? String(count$1) + " events need" : "1 event needs"
          ) + " manual implementation and validation."
        });
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingLeft(Css.px(20)),
                    tl: /* [] */0
                  })
            }, React.createElement("section", undefined, tmp, tmp$1), React.createElement(Spacer.make, {
                  height: 8
                }), Belt_Option.mapWithDefault(Belt_Array.getBy(data.sources, (function (source) {
                        return Caml_obj.caml_equal(source.id, selectedSourceId);
                      })), null, (function (source) {
                    return Belt_Array.map(source.events.slice().sort(function (a, b) {
                                    return Caml.caml_string_compare(a.name, b.name);
                                  }), (function ($$event) {
                                  var mockedSource_id = source.id;
                                  var mockedSource_name = source.name;
                                  var mockedSource_language = source.language;
                                  var mockedSource_platform = source.platform;
                                  var mockedSource = {
                                    id: mockedSource_id,
                                    name: mockedSource_name,
                                    language: mockedSource_language,
                                    platform: mockedSource_platform,
                                    destinations: /* [] */0,
                                    filename: "",
                                    path: "",
                                    segmentDestinationOptions: /* [] */0,
                                    config: /* [] */0
                                  };
                                  var match = $$event.status;
                                  var lifecycle;
                                  switch (match) {
                                    case /* New */0 :
                                        lifecycle = "new";
                                        break;
                                    case /* Added */1 :
                                        lifecycle = "added";
                                        break;
                                    case /* Updated */2 :
                                        lifecycle = "updated";
                                        break;
                                    case /* Removed */3 :
                                        lifecycle = "removed";
                                        break;
                                    case /* Deleted */4 :
                                        lifecycle = "deleted";
                                        break;
                                    
                                  }
                                  var oldSnippet = $$event.includeInCodegen ? ({
                                        NAME: "codegen",
                                        VAL: {
                                          snippet: Belt_Option.getWithDefault($$event.mainCodeSnippet, ""),
                                          source: mockedSource
                                        }
                                      }) : ({
                                        NAME: "pseudocode",
                                        VAL: {
                                          snippet: Belt_Option.getWithDefault($$event.mainCodeSnippet, ""),
                                          source: mockedSource
                                        }
                                      });
                                  var newSnippet = $$event.includeInCodegen ? ({
                                        NAME: "codegen",
                                        VAL: {
                                          snippet: Belt_Option.getWithDefault($$event.branchCodeSnippet, ""),
                                          source: mockedSource
                                        }
                                      }) : ({
                                        NAME: "pseudocode",
                                        VAL: {
                                          snippet: Belt_Option.getWithDefault($$event.branchCodeSnippet, ""),
                                          source: mockedSource
                                        }
                                      });
                                  var diff = SnippetUtils.getSnippetDiffWrapper(oldSnippet, newSnippet, diffViewType);
                                  return React.createElement(ShareBranchImplement$EventSnippet, {
                                              branchStatus: data.branchStatus,
                                              branchName: data.branchName,
                                              hasCodegenForSource: $$event.includeInCodegen,
                                              mockedSource: mockedSource,
                                              lifecycle: lifecycle,
                                              diff: diff,
                                              eventId: $$event.id,
                                              eventName: $$event.name,
                                              eventDescription: $$event.description,
                                              diffViewType: diffViewType,
                                              shareId: shareId,
                                              key: $$event.id
                                            });
                                }));
                  })));
}

var CodeChanges = {
  make: ShareBranchImplement$CodeChanges
};

function ShareBranchImplement(Props) {
  var data = Props.data;
  var userId = Props.userId;
  var shareId = Props.shareId;
  var match = React.useState(function () {
        
      });
  var setSelectedSourceId_useWithHook = match[1];
  var selectedSourceId = match[0];
  var previousSelectedSourceId = Hooks.usePrevious1(selectedSourceId);
  var match$1 = React.useState(function () {
        return Belt_Option.getWithDefault(Belt_Option.flatMap(Dom_storage.getItem("branchImplementationDiffViewType", localStorage), SnippetTypes.DiffViewType.tFromJs), "unified");
      });
  var setDiffViewType = match$1[1];
  var diffViewType = match$1[0];
  var previousDiffViewType = Hooks.usePrevious1(diffViewType);
  React.useEffect((function () {
          branchImplementationPubliceViewOpened(SnippetTypes.DiffViewType.toAnalyticsDiffType(diffViewType), shareId);
          
        }), []);
  var totalEvents = Belt_Array.concatMany(Belt_Array.map(data.sources, (function (param) {
              return param.events;
            }))).length;
  var setSelectedSourceId = BranchImplementationHooks.useManageSelectedSourceId_useOnce(selectedSourceId, (function (selectedSourceId) {
          return Curry._1(setSelectedSourceId_useWithHook, (function (param) {
                        return selectedSourceId;
                      }));
        }), (function (sourceId) {
          var tmp;
          var exit = 0;
          if (previousSelectedSourceId === "Overview") {
            tmp = "Overview";
          } else {
            exit = 1;
          }
          if (exit === 1) {
            tmp = Belt_Option.map(Belt_Array.getBy(data.sources, (function (param) {
                        return Caml_obj.caml_equal(param.id, previousSelectedSourceId);
                      })), (function (param) {
                    return param.name;
                  }));
          }
          return branchImplementViewConfigured("SourceSwitched", SnippetTypes.DiffViewType.toAnalyticsDiffType(previousDiffViewType), SnippetTypes.DiffViewType.toAnalyticsDiffType(diffViewType), tmp, Belt_Option.mapWithDefault(Belt_Array.getBy(data.sources, (function (param) {
                                return Caml_obj.caml_equal(param.id, selectedSourceId);
                              })), "OverView", (function (param) {
                            return param.name;
                          })), shareId);
        }));
  BranchImplementationHooks.useManageDiffViewType_useOnce(diffViewType, (function (diffViewType) {
          return Curry._1(setDiffViewType, (function (param) {
                        return diffViewType;
                      }));
        }), (function (param) {
          var tmp;
          var exit = 0;
          if (previousSelectedSourceId === "Overview") {
            tmp = "Overview";
          } else {
            exit = 1;
          }
          if (exit === 1) {
            tmp = Belt_Option.map(Belt_Array.getBy(data.sources, (function (param) {
                        return Caml_obj.caml_equal(param.id, previousSelectedSourceId);
                      })), (function (param) {
                    return param.name;
                  }));
          }
          return branchImplementViewConfigured("DiffTypeChanged", SnippetTypes.DiffViewType.toAnalyticsDiffType(previousDiffViewType), SnippetTypes.DiffViewType.toAnalyticsDiffType(diffViewType), tmp, Belt_Option.mapWithDefault(Belt_Array.getBy(data.sources, (function (param) {
                                return Caml_obj.caml_equal(param.id, selectedSourceId);
                              })), "OverView", (function (param) {
                            return param.name;
                          })), shareId);
        }));
  var sourceOptions = Belt_Array.map(data.sources, (function (source) {
          var count = source.events.length;
          var tmp = {
            label: source.name,
            value: source.id,
            description: count !== 1 ? String(count) + " events" : "1 event"
          };
          var tmp$1 = Belt_Option.map(source.platform, (function (platform) {
                  return React.createElement(IconSources.make, {
                              platform: platform,
                              size: 40
                            });
                }));
          if (tmp$1 !== undefined) {
            tmp.icon = Caml_option.valFromOption(tmp$1);
          }
          return tmp;
        }));
  var sourceOptionsWithOverview = Belt_Array.concat([{
          label: "Overview",
          value: "Overview",
          description: String(totalEvents) + " events",
          icon: React.createElement(Icon.make, {
                type_: "code",
                size: {
                  NAME: "int",
                  VAL: 16
                },
                color: Styles.Color.light10
              })
        }], sourceOptions);
  var match$2 = Belt_Array.partition(Belt_Option.mapWithDefault(Belt_Array.getBy(data.sources, (function (param) {
                  return Caml_obj.caml_equal(selectedSourceId, param.id);
                })), [], (function (param) {
              return param.events;
            })), (function (param) {
          return param.includeInCodegen;
        }));
  var eventsAffected = Belt_SetString.size(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(data.sources, (function (param) {
                      return Belt_Array.map(param.events, (function (param) {
                                    return param.id;
                                  }));
                    })))));
  var eventCount = selectedSourceId !== undefined ? Belt_Option.mapWithDefault(Belt_Array.getBy(data.sources, (function (param) {
                return Caml_obj.caml_equal(param.id, selectedSourceId);
              })), 0, (function (param) {
            return param.events.length;
          })) : totalEvents;
  var diffViewTypeOptions = [
    {
      label: "Unified",
      value: "unified",
      icon: React.createElement(Icon.make, {
            type_: "unifiedDiff",
            size: "small",
            color: Styles.Color.light09
          })
    },
    {
      label: "Split",
      value: "split",
      icon: React.createElement(Icon.make, {
            type_: "splitDiff",
            size: "small",
            color: Styles.Color.light09
          })
    },
    {
      label: "No diff",
      value: "noDiff",
      icon: React.createElement(Icon.make, {
            type_: "noDiff",
            size: "small",
            color: Styles.Color.light09
          })
    }
  ];
  var onSourceSelect = function (maybeSourceId) {
    return Belt_Option.forEach(maybeSourceId, setSelectedSourceId);
  };
  var onDiffViewTypeSelect = function (maybeDiffViewType) {
    return Belt_Option.forEach(maybeDiffViewType, (function (diffViewType) {
                  return Curry._1(setDiffViewType, (function (param) {
                                return diffViewType;
                              }));
                }));
  };
  return React.createElement("div", {
              className: root
            }, React.createElement(ShareBranchImplement$Sidebar, {
                  userId: userId,
                  schemaId: data.schemaId,
                  schemaName: data.schemaName,
                  branchId: data.branchId,
                  branchName: data.branchName,
                  maybeSourceId: selectedSourceId
                }), React.createElement(ShareBranchImplement$BranchHeader, {
                  branchName: data.branchName,
                  branchStatus: data.branchStatus,
                  approvalWorkflow: data.approvalWorkflow
                }), React.createElement(ShareBranchImplement$CodeChangesHeader, {
                  shareId: shareId,
                  eventsAffected: eventsAffected,
                  sourcesCount: data.sources.length
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(ShareBranchImplement$ViewControls, {
                  diffViewType: diffViewType,
                  diffViewTypeOptions: diffViewTypeOptions,
                  eventCount: eventCount,
                  onDiffViewTypeSelect: onDiffViewTypeSelect,
                  onSourceSelect: onSourceSelect,
                  selectedSourceId: selectedSourceId,
                  sourceOptions: sourceOptionsWithOverview
                }), selectedSourceId !== undefined ? React.createElement(ShareBranchImplement$CodeChanges, {
                    data: data,
                    diffViewType: diffViewType,
                    implementedManually: match$2[1],
                    implementedWithCodegen: match$2[0],
                    selectedSourceId: selectedSourceId,
                    shareId: shareId
                  }) : React.createElement(PublicCodeChangesOverview.make, {
                    data: data,
                    onSourceSelect: onSourceSelect
                  }));
}

var sidebarPadding = 16;

var make = ShareBranchImplement;

export {
  sidebarPadding ,
  Style ,
  WorkspaceLinkButton ,
  ServerAnalytics ,
  Sidebar$1 as Sidebar,
  BranchHeader ,
  CodeChangesHeader ,
  ViewControls ,
  EventSnippet ,
  CodeChanges ,
  make ,
  
}
/* root Not a pure module */
