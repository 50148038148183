// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Router from "./Router.mjs";
import * as $$Comment from "./Comment.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ObjectActivityLogAction from "./ObjectActivityLogAction.mjs";

function BranchActivityItem(Props) {
  var model = Props.model;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var branchName = Props.branchName;
  var showComments = Props.showComments;
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match === "object") {
    var variant = match.NAME;
    if (variant === "SetBranchStatus") {
      var branchStatus = match.VAL[2];
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: branchStatus === "ChangesRequested" ? "requested changes to the branch" : (
                      branchStatus === "Approved" ? "approved the branch" : (
                          branchStatus === "Draft" ? React.createElement(React.Fragment, undefined, "marked the branch as ", React.createElement("em", undefined, "a draft")) : React.createElement(React.Fragment, undefined, "marked the branch as ", React.createElement("em", undefined, "ready for review"))
                        )
                    )
                });
    }
    if (variant !== "ImportDeprecated") {
      if (variant === "CreateDemoBranch") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "opened a demo branch"
                  });
      }
      if (variant !== "BranchComment") {
        if (variant === "OpenBranch") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "opened the branch"
                    });
        } else if (variant === "DeletePublicBranchImplementation") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "deleted a public branch implementation instructions link"
                    });
        } else if (variant === "PullMaster") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "pulled main into branch"
                    });
        } else if (variant === "CreatePublicBranchImplementation") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "created a public branch implementation instructions link"
                    });
        } else if (variant === "ConfigureInspectorValidation") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "configured the inspector validation"
                    });
        } else if (variant === "CodeGenerated") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "pulled changes from this branch into ", React.createElement("b", undefined, Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(match.VAL[1], (function (sourceId) {
                                          return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                                        })), AvoConfig.getSourceName)).join(", ")));
        } else if (variant === "CloseBranch") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "closed the branch"
                    });
        } else if (variant === "CompletedImport") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "Successfully imported a tracking plan"
                    });
        } else {
          return null;
        }
      }
      if (!showComments) {
        return null;
      }
      var match$1 = match.VAL;
      return React.createElement($$Comment.make, {
                  threadId: match$1[0],
                  itemType: "Branch",
                  itemName: branchName,
                  commentId: match$1[1],
                  events: model.events,
                  userId: userId,
                  goToId: goToId,
                  action: action,
                  link: (function (commentId) {
                      return Router.Link.getSchemaRouteLink(undefined, {
                                  NAME: "diff",
                                  VAL: {
                                    NAME: "comment",
                                    VAL: commentId
                                  }
                                });
                    }),
                  openBranches: openBranches
                });
    }
    
  } else if (match !== "StartedImport") {
    return null;
  }
  return React.createElement(ObjectActivityLogAction.Content.make, {
              action: action,
              users: users$1,
              openBranches: openBranches,
              children: "Started importing a tracking plan"
            });
}

var make = BranchActivityItem;

export {
  make ,
  
}
/* react Not a pure module */
