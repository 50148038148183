// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as IconEmail from "./IconEmail.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AuthenticationMethods from "./AuthenticationMethods.mjs";

function getRedirectUrl(schemaId) {
  return "https://avo.app/" + (
          schemaId !== undefined ? "schemas/" + schemaId + "/events/list" : "welcome"
        );
}

function actionCodeSettings(schemaId) {
  return {
          url: getRedirectUrl(schemaId)
        };
}

function setVerificationTrigger(user, schemaId) {
  var now = new Date();
  var dateKey = String(now.getFullYear()) + String(now.getMonth()) + String(now.getDate()) + String(now.getHours()) + String(now.getMinutes());
  var verifyTriggerRef = Firebase.app(undefined).firestore().collection("users").doc(user.uid).collection("verificationTriggers").doc(dateKey);
  return verifyTriggerRef.set({
              redirectUrl: getRedirectUrl(schemaId)
            });
}

function VerifyEmail(Props) {
  var user = Props.user;
  var schemaId = Props.schemaId;
  var auth = Firebase.getAuth(undefined);
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setState = match[1];
  var state = match[0];
  var handleEmailVerification = function (ignoreErrorOpt, param) {
    var ignoreError = ignoreErrorOpt !== undefined ? ignoreErrorOpt : false;
    Curry._1(setState, (function (param) {
            return /* Loading */0;
          }));
    $$Promise.$$catch(setVerificationTrigger(user, schemaId).then(function (param) {
              AnalyticsRe.signUpVerifyRequested(undefined);
              Curry._1(setState, (function (param) {
                      return /* Success */1;
                    }));
              return Promise.resolve(undefined);
            }), (function (_error) {
            if (ignoreError) {
              
            } else {
              Curry._1(setState, (function (param) {
                      return /* Error */{
                              _0: /* TooSoon */1
                            };
                    }));
            }
            return Promise.resolve(undefined);
          }));
    
  };
  React.useEffect((function () {
          handleEmailVerification(true, undefined);
          
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.minHeight(Css.vh(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingTop(Css.px(50)),
                        tl: /* [] */0
                      })
                }, React.createElement(Icons.AvoLogo.make, {
                      width: 100,
                      fill: Styles.Color.light04
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding2(Css.px(35), Css.px(50)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.maxWidth(Css.px(500)),
                                tl: {
                                  hd: Css.textAlign("center"),
                                  tl: {
                                    hd: Css.marginTop(Css.px(80)),
                                    tl: {
                                      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(-110)),
                                tl: /* [] */0
                              })
                        }, React.createElement(IconEmail.make, {})), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding2(Css.px(15), Css.px(0)),
                                tl: /* [] */0
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.huge),
                                      tl: {
                                        hd: Css.paddingBottom(Css.px(10)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, "Please verify your email address"), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.regular),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.medium),
                                      tl: {
                                        hd: Css.padding2(Css.px(5), Css.px(0)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, "We need to verify that ", React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: /* [] */0
                                      })
                                }, Belt_Option.getExn(Caml_option.nullable_to_opt(user.email))), " is your email address before continuing.")), typeof state === "number" ? null : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.red,
                                children: state._0 ? "It looks like an email might already be on the way. Try again in 1 minute if you haven't received it." : "Hmm... we could not send the verification email."
                              }), React.createElement(Spacer.make, {
                                height: 8
                              }), React.createElement(Button.make, {
                                label: "Retry",
                                onClick: (function (param) {
                                    return handleEmailVerification(undefined, undefined);
                                  })
                              }))), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.textAlign("center"),
                            tl: {
                              hd: Css.marginTop(Css.px(25)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.color(Styles.Color.light10),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("span", undefined, "Did not receive the email? "), React.createElement("a", {
                              href: "#",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  return handleEmailVerification(undefined, undefined);
                                })
                            }, "Resend")), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.color(Styles.Color.light10),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, Belt_Option.getExn(Caml_option.nullable_to_opt(user.email)) + " not your email address? ", React.createElement("a", {
                              href: "#",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  AuthenticationMethods.signOut(auth);
                                  
                                })
                            }, "Log out"), " or ", React.createElement("a", {
                              href: "#",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  return Intercom.showNewMessage(undefined);
                                })
                            }, "contact us"), "."))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(100)),
                        tl: /* [] */0
                      })
                }));
}

var make = VerifyEmail;

export {
  getRedirectUrl ,
  actionCodeSettings ,
  setVerificationTrigger ,
  make ,
  
}
/* Css Not a pure module */
