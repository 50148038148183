// Generated by ReScript, PLEASE EDIT WITH CARE


function get(property) {
  var name = property.uniqueName;
  var name$1 = name !== undefined && name !== "" ? name : property.name;
  return name$1.replace(/[^\w\s\-]/ig, "");
}

export {
  get ,
  
}
/* No side effect */
