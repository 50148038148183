// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ObjectActivityLogAction from "./ObjectActivityLogAction.mjs";

function WorkspaceSettingsActivityItem(Props) {
  var action = Props.action;
  var userIds = Props.userIds;
  var openBranches = Props.openBranches;
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "Archive") {
    return null;
  }
  if (variant === "Unarchive") {
    return null;
  }
  if (variant !== "UpdateTrackingPlanAuditConfig") {
    return null;
  }
  var match$1 = match.VAL;
  var configItem = match$1[0];
  if (configItem === "ConsistentEventNameCasing" || configItem === "ConsistentPropertyNameCasing") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                hideBranchInfo: true,
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, "updated ", React.createElement("b", undefined, ValidationConfigV2.ConfigItem.toRuleName(configItem)));
  }
  var pointOfBlock = match$1[2];
  var ruleSeverity = match$1[1];
  var tmp;
  var exit = 0;
  if (ruleSeverity !== undefined || pointOfBlock !== undefined) {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = pointOfBlock !== undefined ? React.createElement(React.Fragment, undefined, "set ", React.createElement("b", undefined, ValidationConfigV2.ConfigItem.toRuleName(configItem)), " to ", pointOfBlock === "beforeRequestForReview" ? React.createElement("b", undefined, "Request for review") : React.createElement("b", undefined, "Branch merge")) : React.createElement(React.Fragment, undefined, ruleSeverity === "enforce" ? "enforced " : (
              ruleSeverity === "warning" ? "enabled " : "disabled "
            ), React.createElement("b", undefined, ValidationConfigV2.ConfigItem.toRuleName(configItem)));
  }
  return React.createElement(ObjectActivityLogAction.Content.make, {
              hideBranchInfo: true,
              action: action,
              users: users$1,
              openBranches: openBranches,
              children: tmp
            });
}

var make = WorkspaceSettingsActivityItem;

export {
  make ,
  
}
/* react Not a pure module */
