// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconQuestionMark(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: /* [] */0
                  }),
              width: String(size),
              fill: "none",
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8.33301 5.75C8.95433 5.75 9.45801 5.24632 9.45801 4.625C9.45801 4.00368 8.95433 3.5 8.33301 3.5C7.71169 3.5 7.20801 4.00368 7.20801 4.625C7.20801 5.24632 7.71169 5.75 8.33301 5.75ZM6.996 12.324C7.20133 12.5293 7.502 12.632 7.898 12.632C8.23533 12.632 8.558 12.533 8.866 12.335C9.18133 12.137 9.54433 11.818 9.955 11.378L10.725 10.564L9.955 9.87101L8.228 11.532L8.019 11.466L8.91 6.82401H5.687L5.5 7.92401H7.326L6.787 10.729C6.75033 10.905 6.72467 11.048 6.71 11.158C6.69533 11.268 6.688 11.3743 6.688 11.477C6.688 11.829 6.79067 12.1113 6.996 12.324Z",
                  fillRule: "evenodd"
                }));
}

var make = IconQuestionMark;

export {
  make ,
  
}
/* Css Not a pure module */
