// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Mantine from "./Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as AvoEngagement from "./avoEngagement/AvoEngagement.mjs";
import * as InspectorStats from "./inspector/InspectorStats.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as AvoProductTour__Root from "./productTour/AvoProductTour__Root.mjs";
import * as AvoProductTour__Step from "./productTour/AvoProductTour__Step.mjs";
import * as GettingStarted__Types from "./GettingStarted__Types.mjs";
import * as AvoProductTour__GenericStep from "./productTour/AvoProductTour__GenericStep.mjs";
import * as GettingStarted__ProgressStore from "./GettingStarted__ProgressStore.mjs";

var tourId = "demo-branch-product-tour";

function DemoBranchProductTour(Props) {
  var startTourQueryParam = Router.QueryParams.useStringKey("start_tour");
  var schemaBundle = SchemaBundleContext.use(undefined);
  var branch = RouterStore.Schema.useBranch(undefined);
  var workspaceInspectorInstallStatus = InspectorStats.useHasStats(undefined) ? "EventsReceived" : undefined;
  var match = AvoEngagement.Engagement.use(tourId);
  var intent = match[1];
  var flow = match[0];
  var match$1 = GettingStarted__ProgressStore.use(undefined);
  var handleCompleteStep = match$1.handleCompleteStep;
  var completedSteps = match$1.completedSteps;
  var onboardingChoice = match$1.onboardingChoice;
  var handleCompleteStep$1 = function (param) {
    Curry._1(handleCompleteStep, "completeDemoBranchTour");
    var stepCompleted = function (stepId) {
      return Belt_Option.getWithDefault(Js_dict.get(completedSteps, GettingStarted__Types.StepId.tToJs(stepId)), false);
    };
    var stepsByChoice = onboardingChoice === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps;
    var stepsCompletedCount = Belt_Array.keep(stepsByChoice, stepCompleted).length;
    AnalyticsRe.onboardingChecklistProgressed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, stepsCompletedCount / stepsByChoice.length, stepsByChoice.length - stepsCompletedCount | 0, GettingStarted__Types.StepId.toAnalytics("completeDemoBranchTour"), Belt_Array.map(Belt_Array.keep(stepsByChoice, (function (stepId) {
                    return !stepCompleted(stepId);
                  })), GettingStarted__Types.StepId.toAnalytics), "GettingStarted", schemaBundle.branchId, schemaBundle.schemaId, schemaBundle.schemaId, workspaceInspectorInstallStatus);
    
  };
  React.useEffect((function () {
          if (Caml_obj.caml_equal(startTourQueryParam.value, tourId)) {
            Curry._1(startTourQueryParam.removeByReplace, undefined);
            Curry._1(intent.start, undefined);
          }
          
        }), [startTourQueryParam.value]);
  React.useEffect((function () {
          var isDemoBranch = typeof branch === "object" && branch.NAME === "branch" ? branch.VAL.startsWith("demo-") : false;
          if (!flow.state.completed && !flow.state.active && isDemoBranch) {
            Curry._1(intent.start, undefined);
          }
          
        }), []);
  var events = ModelStore.useEvents(undefined);
  var maybeDemoEvent = React.useMemo((function () {
          return Belt_List.getBy(events, (function (param) {
                        return param.name === "Onboarding Started (Demo)";
                      }));
        }), [events]);
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  return React.createElement(AvoProductTour__Root.make, {
              confettiOnComplete: true,
              engagementId: flow.id,
              onComplete: (function (param) {
                  handleCompleteStep$1(undefined);
                  return Router.Schema.pushSchemaRoute(undefined, undefined, "dashboard");
                }),
              offerReplayAtEnd: true,
              showStepCount: true,
              children: null
            }, React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-1",
                  arrowOffset: 80,
                  borderRadius: 5,
                  delay: 500,
                  elementClickable: false,
                  position: "right-start",
                  stepType: {
                    TAG: /* OnElementId */0,
                    _0: "sidebar-tour-branches"
                  },
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitionDuration: 400,
                  transitions: [{
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }],
                  children: React.createElement(AvoProductTour__GenericStep.make, {})
                }), React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-2",
                  borderRadius: 5,
                  offset: -180,
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                    }),
                  position: "bottom",
                  stepType: {
                    TAG: /* Window */2,
                    _0: {
                      left: Css.px(260),
                      top: Css.px(113),
                      width: {
                        NAME: "subtract",
                        VAL: [
                          Css.vw(100.0),
                          Css.px(270)
                        ]
                      },
                      height: Css.px(580)
                    }
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  children: React.createElement(AvoProductTour__GenericStep.make, {})
                }), React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-3",
                  arrowOffset: 80,
                  borderRadius: 5,
                  onClick: (function (transition, _e) {
                      return Curry._1(transition, "next");
                    }),
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                    }),
                  position: "bottom-start",
                  simulateClick: {
                    NAME: "left",
                    VAL: Css.px(85)
                  },
                  stepType: {
                    TAG: /* OnElementId */0,
                    _0: "events-sheet-row-onboarding-started-demo"
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  children: React.createElement(AvoProductTour__GenericStep.make, {})
                }), React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-4",
                  arrowOffset: 80,
                  delay: 550,
                  onStepActive: (function (param) {
                      if (drawerItems) {
                        var match = drawerItems.hd;
                        if (typeof match === "object" && match.NAME === "event") {
                          if (maybeDemoEvent === undefined) {
                            return ;
                          }
                          if (match.VAL[0] === maybeDemoEvent.id) {
                            return ;
                          }
                          
                        }
                        
                      }
                      if (maybeDemoEvent !== undefined) {
                        return Router.Schema.pushDrawerItem(undefined, undefined, {
                                    NAME: "event",
                                    VAL: [
                                      maybeDemoEvent.id,
                                      undefined,
                                      undefined,
                                      false
                                    ]
                                  });
                      }
                      
                    }),
                  position: "left",
                  stepType: {
                    TAG: /* OnElementId */0,
                    _0: "drawer-container"
                  },
                  transition: Mantine.Transition.subtleRightSlide,
                  transitionDuration: 400,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  children: React.createElement(AvoProductTour__GenericStep.make, {}),
                  key: Belt_Option.mapWithDefault(maybeDemoEvent, "not-found", (function (param) {
                          return param.id;
                        }))
                }), React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-5",
                  arrowOffset: 80,
                  borderRadius: 5,
                  elementPadding: 6,
                  onClick: (function (transition, _e) {
                      return Curry._1(transition, "next");
                    }),
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                    }),
                  position: "right-start",
                  simulateClick: {
                    NAME: "left",
                    VAL: Css.pct(40.0)
                  },
                  stepType: {
                    TAG: /* OnElementId */0,
                    _0: "branch-tour-review"
                  },
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitionDuration: 400,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  children: React.createElement(AvoProductTour__GenericStep.make, {})
                }), React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-6",
                  offset: -70,
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                  NAME: "diff",
                                  VAL: "index"
                                });
                    }),
                  position: "right",
                  stepType: {
                    TAG: /* Window */2,
                    _0: {
                      left: Css.px(260),
                      top: Css.px(100),
                      width: Css.px(826),
                      height: {
                        NAME: "subtract",
                        VAL: [
                          Css.vh(100.0),
                          Css.px(108)
                        ]
                      }
                    }
                  },
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitionDuration: 400,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  waitForId: "branch-tour-changes",
                  children: React.createElement(AvoProductTour__GenericStep.make, {})
                }), React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-7",
                  borderRadius: 5,
                  elementPadding: 6,
                  onClick: (function (transition, _e) {
                      return Curry._1(transition, "next");
                    }),
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                  NAME: "diff",
                                  VAL: "index"
                                });
                    }),
                  position: "bottom",
                  simulateClick: "center",
                  stepType: {
                    TAG: /* OnElementId */0,
                    _0: "code-changes-tab"
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  children: React.createElement(AvoProductTour__GenericStep.make, {})
                }), React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-8",
                  borderRadius: 5,
                  elementClickable: false,
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                  NAME: "diff",
                                  VAL: {
                                    NAME: "implementation",
                                    VAL: "overview"
                                  }
                                });
                    }),
                  position: "bottom",
                  stepType: {
                    TAG: /* Window */2,
                    _0: {
                      left: Css.px(260),
                      top: Css.px(92),
                      width: {
                        NAME: "subtract",
                        VAL: [
                          Css.vw(100.0),
                          Css.px(268)
                        ]
                      },
                      height: Css.px(340)
                    }
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  waitForId: "branch-implementation-view",
                  children: React.createElement(AvoProductTour__GenericStep.make, {})
                }), React.createElement(AvoProductTour__Step.make, {
                  blockId: "step-9",
                  borderRadius: 5,
                  onClick: (function (transition, _e) {
                      return Curry._1(transition, "complete");
                    }),
                  position: "right",
                  simulateClick: "center",
                  stepType: {
                    TAG: /* Window */2,
                    _0: {
                      left: Css.px(12),
                      top: Css.px(12),
                      width: Css.px(76),
                      height: Css.px(38)
                    }
                  },
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitionDuration: 400,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "complete",
                      label: "Finish!",
                      icon: undefined
                    }
                  ],
                  children: React.createElement(AvoProductTour__GenericStep.make, {
                        withExit: false
                      })
                }));
}

var make = DemoBranchProductTour;

export {
  make ,
  
}
/* Css Not a pure module */
