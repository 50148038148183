// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(t) {
  return Json_encode.object_({
              hd: [
                "schemaId",
                t.schemaId
              ],
              tl: {
                hd: [
                  "serviceAccountName",
                  t.serviceAccountName
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          serviceAccountName: Json_decode.field("serviceAccountName", Json_decode.string, json)
        };
}

var $$Request = {
  encode: encode,
  decode: decode
};

export {
  $$Request ,
  
}
/* No side effect */
