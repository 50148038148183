// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as DoptMigration from "./DoptMigration.mjs";
import * as LearnHub__DefaultTourButton from "./LearnHub__DefaultTourButton.mjs";
import * as LearnHub__DemoBranchTourButton from "./LearnHub__DemoBranchTourButton.mjs";
import * as LearnHub__InspectorIssuesTourButton from "./LearnHub__InspectorIssuesTourButton.mjs";

function getContent(schemaRoute) {
  var exit = 0;
  if (typeof schemaRoute !== "object") {
    if (schemaRoute === "gettingStarted" || schemaRoute === "auditLog" || schemaRoute === "billing") {
      return ;
    } else if (schemaRoute === "properties") {
      return {
              title: "Tracking Plan Properties",
              body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "All event, user and group properties are defined in the Properties section of your Tracking plan, as well as your property bundles."
                      }), React.createElement("br", undefined), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "You can re-use properties across events for consistency, and create property bundles to quickly add a set of properties commonly used together to your events."
                      })),
              docsLink: "https://www.avo.app/docs/data-design/avo-tracking-plan/properties",
              tour: undefined
            };
    } else if (schemaRoute === "settings") {
      return {
              title: "Sources",
              body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "Sources in Avo represent your platforms and products that have event tracking."
                      }), React.createElement("br", undefined), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "In the tracking plan, sources help you document which platforms and products should send particular events, allow you to configure source-specific event shapes, and help you understand how tracking plan changes translate to source-specific tracking code changes."
                      })),
              docsLink: "https://www.avo.app/docs/data-design/define-sources-and-destinations",
              tour: undefined
            };
    } else if (schemaRoute === "dashboard") {
      return {
              title: "Branches",
              body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "Create a branch to safely draft tracking plan changes in your tracking plan without disrupting your source of truth."
                      }), React.createElement("br", undefined), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "When the branch has been peer-reviewed, approved and implemented, merge it to the main branch."
                      })),
              docsLink: "https://www.avo.app/docs/data-design/branches",
              tour: (function (onTourStart) {
                  return React.createElement(LearnHub__DemoBranchTourButton.make, {
                              onTourStart: onTourStart
                            });
                })
            };
    } else if (schemaRoute === "integrations") {
      return {
              title: "Publishing",
              body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "Publishing is a way to push your tracking plan specs from Avo into your other schema registries and downstream tools, including analytics platforms like Mixpanel or Amplitude, ingestion time validation tools like Segment Protocols, RudderStack or Snowplow, or connect to any tool or internal system via webhook."
                      })),
              docsLink: "https://www.avo.app/docs/publishing/publishing/overview",
              tour: undefined
            };
    } else if (schemaRoute === "events") {
      return {
              title: "Tracking Plan Events",
              body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "All event structures are defined in the Events section of your Tracking Plan."
                      }), React.createElement("br", undefined), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "You can re-use events across platforms, and properties across events to ensure consistent data structures."
                      }), React.createElement("br", undefined), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: null
                      }, "We recommend updating your tracking plan through the ", React.createElement("a", {
                            href: "https://www.avo.app/docs/workflow/overview",
                            rel: "noopener",
                            target: "_blank"
                          }, "Branched Workflow"), ".")),
              docsLink: "https://www.avo.app/docs/data-design/avo-tracking-plan/events",
              tour: (function (onTourStart) {
                  return React.createElement(DoptMigration.Hidden.make, {
                              children: React.createElement(LearnHub__DemoBranchTourButton.make, {
                                    onTourStart: onTourStart
                                  })
                            });
                })
            };
    } else if (schemaRoute === "metrics") {
      return {
              title: "Tracking Plan Metrics",
              body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "All metrics are defined in the Metrics section of your Tracking plan."
                      }), React.createElement("br", undefined), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "Metrics help you decide what to track, so we recommend starting by defining the metrics and designing events and properties from there."
                      })),
              docsLink: "https://www.avo.app/docs/data-design/start-data-design",
              tour: undefined
            };
    } else if (schemaRoute === "implement") {
      return {
              title: "Codegen",
              body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "Explore Codegen if your developers are interested in improved developer experience."
                      }), React.createElement("br", undefined), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "Codegen provides a file in your programming language with type-safe tracking wrappers for your existing data destinations."
                      }), React.createElement("br", undefined), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "Codegen allows developers to implement faster with autocompletion and compile-time and runtime validation."
                      })),
              docsLink: "https://www.avo.app/docs/implementation/avo-codegen-overview",
              tour: undefined
            };
    } else {
      return {
              title: "Event Property Bundles",
              body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "Event property bundles are a way to bundle two or more related event properties to quickly and consistently add them to all related events."
                      })),
              docsLink: "https://www.avo.app/docs/data-design/avo-tracking-plan/properties#event-property-bundles",
              tour: undefined
            };
    }
  }
  var variant = schemaRoute.NAME;
  if (variant === "inspector") {
    var match = schemaRoute.VAL;
    if (typeof match === "object") {
      exit = 1;
    } else {
      if (match !== "issues") {
        if (match === "events") {
          return {
                  title: "Inspector Events",
                  body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Regular",
                            color: Styles.Color.light11,
                            children: "Inspector Events provides a report on all event tracking Inspector has seen in your development, staging and production environments."
                          }), React.createElement("br", undefined), React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Regular",
                            color: Styles.Color.light11,
                            children: "You can build your tracking plan from live data through Inspector, instead of manually building or importing a stale document."
                          }), React.createElement("br", undefined), React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Regular",
                            color: Styles.Color.light11,
                            children: "Head to Inspector Issues to set up alerts for when crucial events break."
                          })),
                  docsLink: "https://www.avo.app/docs/inspector/inspector-events-view",
                  tour: undefined
                };
        } else {
          return {
                  title: "Inspector Saved Views",
                  body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Regular",
                            color: Styles.Color.light11,
                            children: "Save filtered views for your most critical events and connect issue alerts to relevant Slack channels to never miss a data bug again."
                          })),
                  docsLink: "https://www.avo.app/docs/inspector/inspector-issues-view#saving-a-filtered-issues-view",
                  tour: (function (onTourStart) {
                      return React.createElement(LearnHub__DefaultTourButton.make, {
                                  startTour: (function (param) {
                                      Curry._1(onTourStart, undefined);
                                      return Router.Schema.pushSchemaRoute(undefined, "start_tour=set-up-alerts", {
                                                  NAME: "inspector",
                                                  VAL: "issues"
                                                });
                                    })
                                });
                    })
                };
        }
      }
      exit = 1;
    }
  } else {
    if (variant === "domain" || variant === "import") {
      return ;
    }
    var match$1 = schemaRoute.VAL;
    if (typeof match$1 === "object") {
      if (match$1.NAME !== "comment") {
        return {
                title: "Code Changes",
                body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light11,
                          children: "The code changes screen helps your team quickly and accurately implement tracking plan changes."
                        }), React.createElement("br", undefined), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light11,
                          children: "Event code snippets highlight what should be changed in the implementation of the event and makes it fast and easy for developers to understand how to successfully implement the branch."
                        })),
                docsLink: "https://www.avo.app/docs/data-design/branches/code-changes",
                tour: undefined
              };
      }
      exit = 2;
    } else {
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        return {
                title: "Inspector Issues",
                body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light11,
                          children: "Inspector Issues summarizes the anomalies and discrepancies seen in your production data for the past 24 hours."
                        }), React.createElement("br", undefined), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light11,
                          children: "Easily import unexpected events to build your tracking plan from live data, instead of manually building or importing a stale document."
                        }), React.createElement("br", undefined), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light11,
                          children: "Filter down to your most critical data and set up Slack alerts to never miss a data bug again."
                        })),
                docsLink: "https://www.avo.app/docs/inspector/inspector-issues-view",
                tour: (function (onTourStart) {
                    return React.createElement(DoptMigration.Hidden.make, {
                                children: React.createElement(LearnHub__InspectorIssuesTourButton.make, {
                                      onTourStart: onTourStart
                                    })
                              });
                  })
              };
    case 2 :
        return {
                title: "Approval Workflows",
                body: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light11,
                          children: "Review and get feedback from your team on suggested tracking plan changes, to build alignment and ensure consistent data design."
                        }), React.createElement("br", undefined), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light11,
                          children: "Head into Code Changes to see what the change would look like in your code."
                        }), React.createElement("br", undefined), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light11,
                          children: "Mark the branch as ready for review, and @-mention a coworker to pull them in."
                        })),
                docsLink: "https://www.avo.app/docs/data-design/branches/approval-workflows",
                tour: undefined
              };
    
  }
}

export {
  getContent ,
  
}
/* Text Not a pure module */
