// Generated by ReScript, PLEASE EDIT WITH CARE


function notWindow(param) {
  return typeof window === "undefined";
}

function track(eventName, eventProperties) {
  if (typeof window === "undefined") {
    return ;
  } else {
    window.analytics.track(eventName, eventProperties);
    return ;
  }
}

function identify(userId) {
  if (typeof window === "undefined") {
    return ;
  } else {
    window.analytics.identify(userId);
    return ;
  }
}

function setUserProperties(userProperties) {
  if (typeof window === "undefined") {
    return ;
  } else {
    window.analytics.identify(userProperties);
    return ;
  }
}

function unidentify(param) {
  if (typeof window === "undefined") {
    return ;
  } else {
    window.analytics.identify(null);
    return ;
  }
}

function page(pageName, eventProperties) {
  if (typeof window === "undefined") {
    return ;
  } else {
    window.analytics.page(pageName, eventProperties);
    return ;
  }
}

function group(groupId, groupProperties) {
  if (typeof window === "undefined") {
    return ;
  } else {
    window.analytics.group(groupId, groupProperties);
    return ;
  }
}

export {
  notWindow ,
  track ,
  identify ,
  setUserProperties ,
  unidentify ,
  page ,
  group ,
  
}
/* No side effect */
