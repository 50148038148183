// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Collapsible from "./Collapsible.mjs";

var root = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.lightOrange),
      tl: {
        hd: Css.borderRadius(Css.px(5)),
        tl: {
          hd: Css.padding(Css.px(2)),
          tl: /* [] */0
        }
      }
    });

var header = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.width(Css.pct(100)),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.textAlign("left"),
                    tl: {
                      hd: Css.padding(Css.px(6)),
                      tl: {
                        hd: Css.paddingRight(Css.px(14)),
                        tl: {
                          hd: Css.borderRadius(Css.px(3)),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$short
                                }, undefined, undefined, "background-color"),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.backgroundColor(Styles.Color.lighten(Styles.Color.lightOrange, 5)),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var headerIcon = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.width(Css.px(40)),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: /* [] */0
        }
      }
    });

function headerChevronContainer(isOpen) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.transform({
                      NAME: "rotate",
                      VAL: isOpen ? ({
                            NAME: "deg",
                            VAL: 0.0
                          }) : ({
                            NAME: "deg",
                            VAL: 180.0
                          })
                    }),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$default
                      }, undefined, {
                        NAME: "cubicBezier",
                        VAL: [
                          0,
                          0.8,
                          0.9,
                          1
                        ]
                      }, "transform"),
                  tl: /* [] */0
                }
              }
            });
}

var content = Curry._1(Css.style, {
      hd: Css.paddingRight(Css.px(14)),
      tl: {
        hd: Css.paddingLeft(Css.px(46)),
        tl: {
          hd: Css.borderBottomRightRadius(Css.px(5)),
          tl: {
            hd: Css.borderBottomLeftRadius(Css.px(5)),
            tl: /* [] */0
          }
        }
      }
    });

function Callout(Props) {
  var title = Props.title;
  var body = Props.body;
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var isOpen = match[0];
  return React.createElement("div", {
              className: root
            }, React.createElement("button", {
                  className: header,
                  onClick: (function (param) {
                      return Curry._1(setIsOpen, (function (isOpen) {
                                    return !isOpen;
                                  }));
                    })
                }, React.createElement("div", {
                      className: headerIcon
                    }, React.createElement(Icon.make, {
                          type_: "warningFilled",
                          size: "large",
                          color: Styles.Color.orange
                        })), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.deepOrange,
                      children: title
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement("div", {
                      className: headerChevronContainer(isOpen)
                    }, React.createElement(Icon.make, {
                          type_: "chevronUp",
                          size: "large",
                          color: Styles.Color.deepOrange
                        }))), React.createElement(Collapsible.make, {
                  collapsed: !isOpen,
                  containerStyles: content,
                  loadingStyle: "eager",
                  withFade: false,
                  children: null
                }, React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.deepOrange,
                      children: body
                    }), React.createElement(Spacer.make, {
                      height: 10
                    })));
}

var make = Callout;

export {
  make ,
  
}
/* root Not a pure module */
