// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as SheetUtils from "./SheetUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SheetHeader from "./SheetHeader.mjs";
import * as ExportButton from "./ExportButton.mjs";
import * as ReactWindow from "react-window";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as StateFilterUtils from "./StateFilterUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as PropertiesSheetRow from "./PropertiesSheetRow.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as PropertiesSheet__Header from "./PropertiesSheet__Header.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as PropertyAbsenceViewHelpers from "./PropertyAbsenceViewHelpers.mjs";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import * as GetPropertiesSheetConstraintsUseCase from "./propertiesSheet/GetPropertiesSheetConstraintsUseCase.mjs";

var _map = {"propertyName":"Property Name","propertyType":"Property Type","propertyValueType":"Property Value Type","valueIsList":"Value is list","events":"Events","valueConstraints":"Value Constraints","presence":"Presence","eventPropertyBundle":"Event Property Bundle","nameMapping":"Name Mapping"};

var _revMap = {"Property Name":"propertyName","Property Type":"propertyType","Property Value Type":"propertyValueType","Value is list":"valueIsList","Events":"events","Value Constraints":"valueConstraints","Presence":"presence","Event Property Bundle":"eventPropertyBundle","Name Mapping":"nameMapping"};

function columnToJs(param) {
  return _map[param];
}

function columnFromJs(param) {
  return _revMap[param];
}

function getColumns(param) {
  return [
          "propertyName",
          "propertyType",
          "propertyValueType",
          "valueIsList",
          "events",
          "valueConstraints",
          "presence",
          "eventPropertyBundle",
          "nameMapping"
        ];
}

function getCell(model, column, property) {
  if (column === "presence") {
    return Belt_Option.getWithDefault(PropertyAbsenceViewHelpers.getPropertyAbsenceString(model, undefined, property, undefined), "Always sent");
  }
  if (column === "eventPropertyBundle") {
    return Belt_Option.mapWithDefaultU(Belt_List.getByU(model.propertyBundles, (function (propertyBundle) {
                      return Belt_List.hasU(propertyBundle.properties, property.id, (function (a, b) {
                                    return a === b;
                                  }));
                    })), "", (function (bundle) {
                  return bundle.name;
                }));
  }
  if (column === "propertyValueType") {
    return property.type_;
  }
  if (column === "events") {
    return Belt_Array.mapU(Belt_List.toArray(ModelUtils.eventsSendingProperty(model, undefined, property.id)), (function (param) {
                    return param.name;
                  })).join(",");
  }
  if (column === "propertyName") {
    return property.name;
  }
  if (column !== "propertyType") {
    if (column === "nameMapping") {
      return Belt_Array.keepMapU(Belt_List.toArray(ModelUtils.getPropertyNameMappingsOnAllEvents(model, property.id, undefined, undefined)), (function (rule) {
                      var match = rule.definition;
                      if (typeof match !== "object") {
                        return ;
                      }
                      if (match.NAME !== "NameMapping") {
                        return ;
                      }
                      var match$1 = match.VAL;
                      var destination = match$1.destination;
                      if (destination === undefined) {
                        return ;
                      }
                      var name = match$1.name;
                      if (name === undefined) {
                        return ;
                      }
                      var destinationName = typeof destination === "object" ? ModelUtils.getDestinationNameById(model, destination.VAL) : "all destinations";
                      return "sent as \"" + name + "\" to " + destinationName;
                    })).join(",");
    } else if (column === "valueConstraints") {
      return GetPropertiesSheetConstraintsUseCase.get(model, property);
    } else {
      return Pervasives.string_of_bool(property.list);
    }
  }
  var groupId = property.sendAs;
  if (typeof groupId === "number") {
    switch (groupId) {
      case /* SystemProperty */0 :
          return "System";
      case /* EventProperty */1 :
          return "Event";
      case /* UserProperty */2 :
          return "User";
      
    }
  } else {
    var groupId$1 = groupId._0;
    return Belt_Option.mapWithDefault(Belt_Array.getBy(model.groupTypes, (function (param) {
                      return param.id === groupId$1;
                    })), "Unknown Group", (function (param) {
                  return param.name + " (Group)";
                }));
  }
}

var ExportButton$1 = ExportButton.Make({});

function PropertiesSheet$CSVExport(Props) {
  var properties = Curry._1(TrackingPlanMappedModel.Properties.toArray, ModelStore.Mapped.useProperties(undefined));
  var model = ModelStore.useModel(undefined);
  return React.createElement(ExportButton$1.make, {
              getCell: (function (param, param$1) {
                  return getCell(model, param, param$1);
                }),
              getColumnName: columnToJs,
              getColumns: getColumns,
              items: properties,
              name: "PropertiesSheet",
              tooltip: "Export properties to CSV"
            });
}

var CSVExport = {
  columnToJs: columnToJs,
  columnFromJs: columnFromJs,
  getColumns: getColumns,
  getCell: getCell,
  ExportButton: ExportButton$1,
  make: PropertiesSheet$CSVExport
};

var headerStyles = Curry._1(Css.style, {
      hd: Css.height(Css.px(64)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingLeft(Css.px(30)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function sheet(withSandboxBanner) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.height({
                      NAME: "subtract",
                      VAL: [
                        Css.vh(100),
                        Css.px(104 + SandboxBanner.getHeight(withSandboxBanner) | 0)
                      ]
                    }),
                tl: {
                  hd: Css.paddingLeft(Css.px(16)),
                  tl: {
                    hd: Css.position("relative"),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Style = {
  headerHeight: 64,
  columnsHeaderHeight: 40,
  itemHeight: 50,
  headerStyles: headerStyles,
  sheet: sheet
};

function printSegmentQueryParam(segmentation, groupTypes) {
  if (segmentation === undefined) {
    return ;
  }
  if (typeof segmentation === "number") {
    switch (segmentation) {
      case /* SystemProperty */0 :
          return "system";
      case /* EventProperty */1 :
          return "event";
      case /* UserProperty */2 :
          return "user";
      
    }
  } else {
    var groupTypeId = segmentation._0;
    return Belt_Option.mapU(Belt_Array.getByU(groupTypes, (function (param) {
                      return param.id === groupTypeId;
                    })), (function (param) {
                  return param.name;
                }));
  }
}

function parseSegmentQueryParam(value, groupTypes) {
  if (value === undefined) {
    return ;
  }
  switch (value) {
    case "event" :
        return /* EventProperty */1;
    case "system" :
        return /* SystemProperty */0;
    case "user" :
        return /* UserProperty */2;
    default:
      return Belt_Option.mapU(Belt_Array.getByU(groupTypes, (function (param) {
                        return param.name === value;
                      })), (function (param) {
                    return /* GroupProperty */{
                            _0: param.id
                          };
                  }));
  }
}

function getEventsSendingPropertiesMap(events, properties, propertyBundles) {
  return Curry._2(TrackingPlanMappedModel.Properties.mapToMap, properties, (function (param) {
                return Curry._1(TrackingPlanMappedModel.Events.toArray, ModelUtils_mapped.eventsSendingProperty(events, propertyBundles, undefined, param.id));
              }));
}

function PropertiesSheet(Props) {
  var currentBranch = Props.currentBranch;
  var globalSend = Props.globalSend;
  var model = Props.model;
  var user = Props.user;
  var viewerCanEdit = Props.viewerCanEdit;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = GlobalStateContext.use(undefined);
  var globalStateFilters = match.filters;
  var workspace = WorkspaceContext.use(undefined);
  var currentFilters = AnalyticsUtils.currentFilters(model, globalStateFilters, match.eventSort);
  var filters = Belt_List.fromArray(Belt_Array.keepMapU(globalStateFilters, StateFilterUtils.toModel));
  var sourceFilterNames = StateFilterUtils.getSourceFilterNames(globalStateFilters, model);
  var match$1 = SheetUtils.Properties.usePropertySheetColumns(user);
  var columns = match$1[0];
  var match$2 = SheetUtils.Properties.useScrollSync(undefined);
  var sheetRef = match$2.sheetRef;
  var handleReactWindowRef = match$2.handleReactWindowRef;
  var handleOuterRef = match$2.handleOuterRef;
  var segmentQueryParam = Router.QueryParams.useStringKey("segment");
  var match$3 = React.useState(function () {
        return PropertiesSheet__Header.parseSegmentQueryParam(segmentQueryParam.value, model.groupTypes);
      });
  var segmentation = match$3[0];
  var items = SheetUtils.Properties.getPropertiesRows(model.properties, model.groupTypes, segmentation);
  var hasEmptyState = model.properties === /* [] */0;
  React.useEffect((function () {
          if (hasEmptyState) {
            AnalyticsRe.emptyStateDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Properties", schemaBundle.schemaId);
          }
          
        }), []);
  var mappedEvents = ModelStore.Mapped.useEvents(undefined);
  var mappedProperties = ModelStore.Mapped.useProperties(undefined);
  var mappedPropertyBundles = ModelStore.Mapped.usePropertyBundles(undefined);
  var eventsSendingPropertiesMap = React.useMemo((function () {
          return getEventsSendingPropertiesMap(mappedEvents, mappedProperties, mappedPropertyBundles);
        }), [
        mappedEvents,
        mappedProperties,
        mappedPropertyBundles
      ]);
  var makeItem = function (item) {
    return {
            item: item,
            columns: columns,
            currentBranch: currentBranch,
            model: model,
            globalSend: globalSend,
            sendActions: sendActions,
            schemaBundle: schemaBundle,
            currentFilters: currentFilters,
            viewerCanEdit: viewerCanEdit,
            filters: filters,
            sourceFilterNames: sourceFilterNames,
            eventsSendingPropertiesMap: eventsSendingPropertiesMap
          };
  };
  var itemData = React.useMemo((function () {
          if (hasEmptyState) {
            return [makeItem(/* EmptyState */1)];
          } else {
            return Belt_Array.map(items, makeItem);
          }
        }), [
        columns,
        currentBranch,
        currentFilters,
        items,
        model,
        viewerCanEdit
      ]);
  React.useEffect((function () {
          Belt_Option.forEach(Caml_option.nullable_to_opt(sheetRef.current), (function (r) {
                  r.resetAfterIndex(0, false);
                  
                }));
          
        }), [itemData]);
  return React.createElement(React.Fragment, undefined, React.createElement(PropertiesSheet__Header.make, {
                  segmentation: segmentation,
                  setSegmentation: match$3[1]
                }), React.createElement(SheetHeader.make, {
                  columns: columns,
                  setColumns: match$1[1],
                  view: /* ByCategory */0,
                  onWidthChange: (function (direction, param, columnType) {
                      return AnalyticsRe.propertiesViewConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, direction === "Increase" ? "IncreaseColumnWidth" : "DecreaseColumnWidth", undefined, undefined, AnalyticsUtils.columnTypeToPropertyColumnsName(columnType), schemaBundle.branchId, schemaBundle.schemaId);
                    }),
                  ref: match$2.handleSheetHeaderRef
                }), React.createElement("div", {
                  className: sheet(workspace.isSandbox)
                }, React.createElement(ReactVirtualizedAutoSizer, {
                      children: (function (size) {
                          return React.createElement(ReactWindow.VariableSizeList, {
                                      estimatedItemSize: 50,
                                      height: size.height,
                                      itemCount: hasEmptyState ? itemData.length : items.length,
                                      itemData: itemData,
                                      itemKey: (function (index) {
                                          return index.toString();
                                        }),
                                      itemSize: (function (index) {
                                          return Belt_Option.mapWithDefault(Belt_Array.get(itemData, index), 50, (function (param) {
                                                        var item = param.item;
                                                        if (typeof item === "number") {
                                                          return 50;
                                                        }
                                                        switch (item.TAG | 0) {
                                                          case /* Empty */4 :
                                                              return item._0;
                                                          case /* Info */5 :
                                                              return item._1;
                                                          default:
                                                            return 50;
                                                        }
                                                      }));
                                        }),
                                      outerRef: handleOuterRef,
                                      overscanCount: 30,
                                      ref: handleReactWindowRef,
                                      width: size.width,
                                      children: PropertiesSheetRow.make
                                    });
                        })
                    })));
}

var make = PropertiesSheet;

export {
  CSVExport ,
  Style ,
  printSegmentQueryParam ,
  parseSegmentQueryParam ,
  getEventsSendingPropertiesMap ,
  make ,
  
}
/* ExportButton Not a pure module */
