// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Dopt from "./externals/Dopt.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";

function DoptMigration$Hidden(Props) {
  var children = Props.children;
  var showsDoptAnyway = AppFeatureFlag.useFeatureFlag("ShowExpiredDopt");
  if (Dopt.hasExpired) {
    if (showsDoptAnyway) {
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin(Css.px(-4)),
                        tl: {
                          hd: Css.padding(Css.px(4)),
                          tl: {
                            hd: Css.borderRadius(Css.px(6)),
                            tl: {
                              hd: Css.outline(Css.px(3), "dashed", Styles.Color.avoPink),
                              tl: {
                                hd: Css.position("relative"),
                                tl: {
                                  hd: Css.after({
                                        hd: Css.unsafe("content", "'DOPT content'"),
                                        tl: {
                                          hd: Css.position("absolute"),
                                          tl: {
                                            hd: Css.top(Css.px(-24)),
                                            tl: {
                                              hd: Css.left(Css.px(16)),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.white),
                                                tl: {
                                                  hd: Css.color(Styles.Color.avoPink),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.tiny),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.bold),
                                                      tl: {
                                                        hd: Css.padding(Css.px(2)),
                                                        tl: {
                                                          hd: Css.outline(Css.px(3), "solid", Styles.Color.avoPink),
                                                          tl: {
                                                            hd: Css.borderRadius(Css.px(4)),
                                                            tl: {
                                                              hd: Css.opacity(0.7),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, children);
    } else {
      return null;
    }
  } else {
    return children;
  }
}

var Hidden = {
  make: DoptMigration$Hidden
};

export {
  Hidden ,
  
}
/* Css Not a pure module */
