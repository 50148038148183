// Generated by ReScript, PLEASE EDIT WITH CARE


var _map = {"EventName":"Name","PropertyName":"Property Name","Category":"Category","PropertyGroups":"Property Bundles","EventProperties":"Event Properties","Sources":"Sources","Actions":"Actions","Tags":"Tags","Destinations":"Destinations","Metrics":"Metrics","GroupProperties":"Group Properties","PropertyType":"Property Type","PropertyIsList":"Value is List","PropertyEvents":"Events","PropertyConstraints":"Value Constraints","PropertyEvents":"Events","EventPropertyGroup":"Event Property Bundle","PropertyAbsence":"Presence","PropertyNameMapping":"Name Mapping","Stakeholders":"Stakeholders","Description":"Description"};

var _revMap = {"Name":"EventName","Property Name":"PropertyName","Category":"Category","Property Bundles":"PropertyGroups","Event Properties":"EventProperties","Sources":"Sources","Actions":"Actions","Tags":"Tags","Destinations":"Destinations","Metrics":"Metrics","Group Properties":"GroupProperties","Property Type":"PropertyType","Value is List":"PropertyIsList","Events":"PropertyEvents","Value Constraints":"PropertyConstraints","Events":"PropertyEvents","Event Property Bundle":"EventPropertyGroup","Presence":"PropertyAbsence","Name Mapping":"PropertyNameMapping","Stakeholders":"Stakeholders","Description":"Description"};

function columnTypeToJs(param) {
  return _map[param];
}

function columnTypeFromJs(param) {
  return _revMap[param];
}

function columnTypeToString(isGlobalWorkspace, columnType) {
  if (columnType === "Sources" && isGlobalWorkspace) {
    return "Workspaces";
  } else {
    return columnTypeToJs(columnType);
  }
}

var Row = {};

var Events = {
  Row: Row
};

var Row$1 = {};

var Properties = {
  Row: Row$1
};

export {
  columnTypeToJs ,
  columnTypeFromJs ,
  columnTypeToString ,
  Events ,
  Properties ,
  
}
/* No side effect */
