// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as CopyButton from "./CopyButton.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.justifyContent("stretch"),
            tl: {
              hd: Css.maxHeight(Css.pct(90)),
              tl: {
                hd: Css.boxShadow(Styles.Shadow.standard),
                tl: {
                  hd: Css.borderRadius(Css.px(10)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.width(Css.px(400)),
                        tl: {
                          hd: Css.padding(Css.px(48)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var codeBoxStyles = Curry._1(Css.style, {
      hd: Css.height(Css.px(48)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light02),
            tl: {
              hd: Css.paddingRight(Css.px(4)),
              tl: {
                hd: Css.paddingLeft(Css.px(8)),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.marginLeft(Css.px(-9)),
                      tl: {
                        hd: Css.marginRight(Css.px(-9)),
                        tl: {
                          hd: Css.overflowX("auto"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var codeStyles = Curry._1(Css.style, {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: {
            hd: Css.fontWeight(Styles.FontWeight.medium),
            tl: {
              hd: Css.color(Styles.Color.light12),
              tl: {
                hd: Css.marginRight(Css.px(24)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var codeCopyBoxStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(0)),
        tl: {
          hd: Css.top(Css.pct(50.0)),
          tl: {
            hd: Css.marginTop(Css.px(-14)),
            tl: /* [] */0
          }
        }
      }
    });

function ServiceAccountSecretModal(Props) {
  var onClose = Props.onClose;
  var serviceAccount = Props.serviceAccount;
  return React.createElement("div", {
              className: rootStyles,
              onClick: (function (prim) {
                  prim.stopPropagation();
                  
                })
            }, React.createElement($$Text.make, {
                  element: "H1",
                  size: "Huge",
                  color: Styles.Color.light11,
                  children: "Service Account Created"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light10,
                  children: "Service account name"
                }), React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: codeBoxStyles
                    }, React.createElement("p", {
                          className: codeStyles
                        }, serviceAccount.name)), React.createElement("div", {
                      className: codeCopyBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: serviceAccount.name
                        }))), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light10,
                  children: "Service account secret"
                }), React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: codeBoxStyles
                    }, React.createElement("p", {
                          className: codeStyles
                        }, serviceAccount.secret)), React.createElement("div", {
                      className: codeCopyBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: serviceAccount.secret
                        }))), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light11,
                  children: "Make sure to note down the secret key, you won't be able to see it in Avo again."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(Button.make, {
                  label: "Close, I saved the secret",
                  onClick: (function (param) {
                      return Curry._1(onClose, undefined);
                    })
                }));
}

var make = ServiceAccountSecretModal;

export {
  rootStyles ,
  codeBoxStyles ,
  codeStyles ,
  codeCopyBoxStyles ,
  make ,
  
}
/* rootStyles Not a pure module */
