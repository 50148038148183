// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Make(C) {
  var context = React.createContext(C.initial);
  var provider = context.Provider;
  var use = function (param) {
    return React.useContext(context);
  };
  var StateContext$Make$State = function (Props) {
    var state = Props.state;
    var children = Props.children;
    return React.createElement(provider, {
                value: state,
                children: children
              });
  };
  var State = {
    context: context,
    provider: provider,
    use: use,
    make: StateContext$Make$State
  };
  var context$1 = React.createContext(function (param) {
        
      });
  var provider$1 = context$1.Provider;
  var use$1 = function (param) {
    return React.useContext(context$1);
  };
  var StateContext$Make$SetState = function (Props) {
    var setState = Props.setState;
    var children = Props.children;
    return React.createElement(provider$1, {
                value: setState,
                children: children
              });
  };
  var SetState = {
    context: context$1,
    provider: provider$1,
    use: use$1,
    make: StateContext$Make$SetState
  };
  var StateContext$Make = function (Props) {
    var children = Props.children;
    var match = React.useState(function () {
          return C.initial;
        });
    return React.createElement(StateContext$Make$State, {
                state: match[0],
                children: React.createElement(StateContext$Make$SetState, {
                      setState: match[1],
                      children: children
                    })
              });
  };
  return {
          State: State,
          SetState: SetState,
          make: StateContext$Make
        };
}

export {
  Make ,
  
}
/* react Not a pure module */
