// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "../Link.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "../Icons.mjs";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as CreateButtonIcon from "./CreateButtonIcon.mjs";
import * as EmptyStatesStyles from "./EmptyStatesStyles.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";

function PropertyGroupsEmpty(Props) {
  var viewerCanEdit = Props.viewerCanEdit;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  return React.createElement("main", {
              className: EmptyStatesStyles.rootStyles
            }, React.createElement(Link.make, {
                  path: Router.Link.getSchemaRouteLink(undefined, "properties"),
                  children: React.createElement($$Text.make, {
                        element: "Span",
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.darkBlue,
                        children: null
                      }, React.createElement(Icons.ArrowLeft.make, {
                            size: 9,
                            color: Styles.Color.darkBlue
                          }), " View All Properties")
                }), React.createElement(Title.make, {
                  children: "Tame the chaos with Event Property Bundles",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(460),
                  children: null
                }, "Event property bundles are a way to bundle two or more related event properties to quickly and consistently add them to all related events. Read more about Event Property Bundles in the ", React.createElement("a", {
                      className: EmptyStatesStyles.linkStyles,
                      href: "https://www.avo.app/docs/workspace/tracking-plan/properties#a-nameproperty-bundlesa-event-property-bundles",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "Avo docs"
                        })), "."), viewerCanEdit ? React.createElement("div", {
                    className: EmptyStatesStyles.actionsStyles
                  }, React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "PropertyGroups", "Setup", undefined);
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "NewPropertyGroup",
                                          VAL: [
                                            undefined,
                                            undefined
                                          ]
                                        }
                                      });
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            children: "Create a Property Bundle"
                          }))) : null);
}

var make = PropertyGroupsEmpty;

export {
  make ,
  
}
/* Css Not a pure module */
