// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as RoleUtils from "./RoleUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as ExportModal from "./ExportModal.mjs";
import * as IconSettings from "./IconSettings.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function openMembersModal(schemaBundle, globalSend) {
  AnalyticsRe.membersClicked(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.branchId, schemaBundle.schemaId);
  return Curry._1(globalSend, {
              TAG: /* OpenModal */4,
              _0: {
                NAME: "Settings",
                VAL: undefined
              }
            });
}

var buttonStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(0)),
      tl: {
        hd: Css.marginTop(Css.px(2)),
        tl: {
          hd: Css.margin(Css.px(0)),
          tl: {
            hd: Css.backgroundColor(Css.transparent),
            tl: {
              hd: Css.borderWidth(Css.px(0)),
              tl: {
                hd: Css.cursor("pointer"),
                tl: {
                  hd: Css.active({
                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.focus({
                          hd: Css.outlineStyle("none"),
                          tl: {
                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                            tl: /* [] */0
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function SidebarSettingsCog(Props) {
  var globalSend = Props.globalSend;
  var model = Props.model;
  var role = Props.role;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var tmp;
  if (role === "Admin") {
    var tmp$1 = {
      label: "Audit log",
      onClick: (function (param) {
          if (AvoLimits.AuditLog.computeAvailability(workspace) === "Unavailable") {
            return Curry._1(globalSend, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "BillingPrompt",
                          VAL: "OpenAuditLog"
                        }
                      });
          } else {
            return Router.Schema.pushSchemaRoute(undefined, undefined, "auditLog");
          }
        })
    };
    var tmp$2 = AvoLimits.AuditLog.computeAvailability(workspace) === "Available" ? undefined : Caml_option.some(React.createElement(Icon.make, {
                type_: "lightning",
                size: "small",
                color: Styles.Color.orange
              }));
    if (tmp$2 !== undefined) {
      tmp$1.rightSection = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(ContextMenu.V2.Item.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(ContextMenu.V2.make, {
              position: "right-start",
              offset: {
                NAME: "y",
                VAL: -14
              },
              button: React.createElement("button", {
                    className: buttonStyles,
                    id: "sidebar-tour-settings"
                  }, React.createElement(IconSettings.make, {
                        hoverColor: Styles.Color.white
                      })),
              children: null
            }, React.createElement(ContextMenu.V2.Item.make, {
                  label: "Workspace settings",
                  onClick: (function (param) {
                      return openMembersModal(schemaBundle, globalSend);
                    })
                }), role === "Admin" || role === "BillingOnly" ? React.createElement(ContextMenu.V2.Item.make, {
                    label: "Billing",
                    onClick: (function (param) {
                        return Router.Schema.pushSchemaRoute(undefined, undefined, "billing");
                      })
                  }) : null, RoleUtils.canEdit(role) ? React.createElement(ContextMenu.V2.Item.make, {
                    label: "Import",
                    onClick: (function (param) {
                        return Router.Schema.pushImportRoute(undefined, "SidebarSettingsCog", {
                                    NAME: "import",
                                    VAL: "index"
                                  });
                      })
                  }) : null, React.createElement(ContextMenu.V2.Item.make, {
                  label: "Export",
                  onClick: (function (param) {
                      return Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "GenericModal",
                                    VAL: (function (onClose) {
                                        return React.createElement(ExportModal.make, {
                                                    onClose: onClose,
                                                    model: model,
                                                    schemaBundle: schemaBundle
                                                  });
                                      })
                                  }
                                });
                    })
                }), tmp, React.createElement(ContextMenu.V2.Divider.make, {}), React.createElement(ContextMenu.V2.Item.make, {
                  label: "Switch workspace",
                  onClick: (function (param) {
                      return Router.push(undefined, Router.Link.getBaseRoute(undefined, "allSchemas"));
                    })
                }), React.createElement(ContextMenu.V2.Divider.make, {}), React.createElement(ContextMenu.V2.Item.make, {
                  label: "Submit a feature request",
                  onClick: (function (param) {
                      window.open("https://feedback.avo.app/feature-requests", "_blank");
                      
                    })
                }), React.createElement(ContextMenu.V2.Item.make, {
                  label: "Report a bug",
                  onClick: (function (param) {
                      window.open("https://feedback.avo.app/bug-reports", "_blank");
                      
                    })
                }));
}

var make = SidebarSettingsCog;

export {
  openMembersModal ,
  buttonStyles ,
  make ,
  
}
/* buttonStyles Not a pure module */
