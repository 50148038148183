// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconDocumentChanges(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 48;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 48 48",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", undefined, React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.light07),
                            tl: /* [] */0
                          }),
                      d: "M26,27H2c-0.553,0-1-0.448-1-1V2c0-0.552,0.447-1,1-1h24c0.553,0,1,0.448,1,1v24C27,26.552,26.553,27,26,27z "
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.light08),
                            tl: /* [] */0
                          }),
                      d: "M46,47H22c-0.553,0-1-0.448-1-1V22c0-0.552,0.447-1,1-1h24c0.553,0,1,0.448,1,1v24 C47,46.552,46.553,47,46,47z"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                            tl: /* [] */0
                          }),
                      d: "M38,9h-5.586l4.293-4.293l-1.414-1.414l-6,6c-0.391,0.391-0.391,1.023,0,1.414l6,6l1.414-1.414L32.414,11H38 c2.757,0,5,2.243,5,5h2C45,12.14,41.859,9,38,9z"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                            tl: /* [] */0
                          }),
                      d: "M12.707,31.293l-1.414,1.414L15.586,37H10c-2.757,0-5-2.243-5-5H3c0,3.86,3.141,7,7,7h5.586l-4.293,4.293 l1.414,1.414l6-6c0.391-0.391,0.391-1.023,0-1.414L12.707,31.293z"
                    })));
}

var make = IconDocumentChanges;

export {
  make ,
  
}
/* Css Not a pure module */
