// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

function getVariantsSendingLiteral(propertyId, propertyEvents, eventsSendingLiteral, value) {
  return Belt_Array.reduceU(propertyEvents, [], (function (acc, propertyEvent) {
                var isAllowedOnEvent = Belt_Array.someU(eventsSendingLiteral, (function (eventSendingLiteral) {
                        return eventSendingLiteral.id === propertyEvent.id;
                      }));
                var eventVariants = propertyEvent.variants;
                var eventVariantsWithAllowedValue = Belt_Array.keepU(eventVariants, (function (variant) {
                        var propertyOverride = Belt_MapString.get(variant.propertyOverrides, propertyId);
                        var addsAllowedValue = Belt_Option.mapWithDefault(propertyOverride, false, (function (overrideType) {
                                if (!overrideType) {
                                  return false;
                                }
                                var allowedValuesOverride = overrideType._0.allowedValues;
                                if (!allowedValuesOverride) {
                                  return false;
                                }
                                var valueOverride = Belt_MapString.get(allowedValuesOverride._0, value);
                                if (valueOverride === undefined) {
                                  return false;
                                }
                                if (!valueOverride) {
                                  return false;
                                }
                                var eventIncludeSourcesSet = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(propertyEvent.includeSources, (function (param) {
                                                return param.id;
                                              }))));
                                return !Belt_SetString.eq(Belt_SetString.intersect(valueOverride._0, eventIncludeSourcesSet), eventIncludeSourcesSet);
                              }));
                        var removesAllowedValue = Belt_Option.mapWithDefault(propertyOverride, false, (function (overrideType) {
                                if (!overrideType) {
                                  return false;
                                }
                                var allowedValuesOverride = overrideType._0.allowedValues;
                                if (!allowedValuesOverride) {
                                  return false;
                                }
                                var valueOverride = Belt_MapString.get(allowedValuesOverride._0, value);
                                if (valueOverride === undefined) {
                                  return false;
                                }
                                if (!valueOverride) {
                                  return true;
                                }
                                var eventIncludeSourcesSet = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(propertyEvent.includeSources, (function (param) {
                                                return param.id;
                                              }))));
                                return Belt_SetString.eq(Belt_SetString.intersect(valueOverride._0, eventIncludeSourcesSet), eventIncludeSourcesSet);
                              }));
                        if (isAllowedOnEvent && !removesAllowedValue) {
                          return true;
                        } else if (isAllowedOnEvent) {
                          return false;
                        } else {
                          return addsAllowedValue;
                        }
                      }));
                return Belt_Array.concat(acc, eventVariantsWithAllowedValue);
              }));
}

export {
  getVariantsSendingLiteral ,
  
}
/* No side effect */
