// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as Input from "../Input.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Domains from "../../../model/src/domains/Domains.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as DocsLink from "../DocsLink.mjs";
import * as Markdown from "../Markdown.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as TextButton from "../TextButton.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ContextMenu from "../ContextMenu.mjs";
import * as DomainStore from "../DomainStore.mjs";
import * as DomainUtils from "../DomainUtils.mjs";
import * as LoadingCircle from "../LoadingCircle.mjs";
import * as ViewerContext from "../ViewerContext.mjs";
import * as AddEventsModal from "./AddEventsModal.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as VariantEventIdUseCase from "../../../model/src/variants/variantEventIdUseCase.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";
import * as DomainDetails__Components from "./DomainDetails__Components.mjs";
import * as CreateEventForVariantUseCase from "../../../model/src/variants/createEventForVariantUseCase.mjs";

var rowStyles = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gridGap(Css.px(8)),
          tl: {
            hd: Css.gridTemplateColumns({
                  hd: {
                    NAME: "fr",
                    VAL: 1.0
                  },
                  tl: {
                    hd: "maxContent",
                    tl: /* [] */0
                  }
                }),
            tl: {
              hd: Css.padding2(Css.px(10), Css.px(32)),
              tl: {
                hd: Css.paddingRight(Css.px(16)),
                tl: {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
                  tl: {
                    hd: Css.lastChild({
                          hd: Css.borderBottomWidth(Css.px(0)),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.maxHeight(Css.px(45)),
                      tl: {
                        hd: Css.selector(" p", {
                              hd: Css.overflow("visible"),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var nameColumnStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

function DomainDetails__Events$Event(Props) {
  var domain = Props.domain;
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  var $$event = Props.event;
  var variant = Props.variant;
  var schemaId = WorkspaceContext.use(undefined).id;
  var viewerId = ViewerContext.use(undefined).id;
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var schemaBundle = SchemaBundleContext.use(undefined);
  var domains = DomainStore.useDomains(undefined);
  var viewerRole = WorkspaceContext.useMemberRole(undefined);
  var canManage = (viewerRole === "Admin" || viewerIsDomainMember) && !domain.archived;
  var handleRemoveEvent = function (param) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    DomainUtils.Firebase.removeDomainItems([Belt_Option.mapWithDefault(variant, {
                NAME: "event",
                VAL: $$event.id
              }, (function (variant) {
                  return {
                          NAME: "eventVariant",
                          VAL: VariantEventIdUseCase.variantEventId({
                                baseEventId: $$event.id,
                                variantId: variant.id
                              })
                        };
                }))], (function (param) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            return Curry._1(addToast, {
                        message: "Error removing event" + Belt_Option.mapWithDefault(variant, "", (function (_variant) {
                                return " variant";
                              })) + " from domain",
                        toastType: /* Error */1
                      });
          }), (function (param) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            Curry._1(addToast, {
                  message: React.createElement(React.Fragment, undefined, "Removed \"" + Belt_Option.mapWithDefault(variant, $$event.name, (function (variant) {
                              return CreateEventForVariantUseCase.variantEventDisplayName($$event, variant.nameSuffix);
                            })) + "\" from ", React.createElement("i", undefined, domain.name)),
                  toastType: /* Success */0
                });
            return AnalyticsRe.domainItemRemovedSingleItem(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domain.name, Curry._1(Domains.size, domains), "DomainSettings", viewerIsDomainMember ? "DomainTeamMember" : "Admin", "Stakeholder", Belt_Option.mapWithDefault(variant, "Event", (function (param) {
                              return "EventVariant";
                            })), Belt_Option.mapWithDefault(variant, $$event.name, (function (variant) {
                              return CreateEventForVariantUseCase.variantEventDisplayName($$event, variant.nameSuffix);
                            })), Belt_Option.mapWithDefault(variant, $$event.id, (function (variant) {
                              return VariantEventIdUseCase.variantEventId({
                                          baseEventId: $$event.id,
                                          variantId: variant.id
                                        });
                            })), schemaId);
          }), schemaId, sendActions, viewerId, domain.id);
    
  };
  return React.createElement("div", {
              className: rowStyles
            }, React.createElement(React.Fragment, undefined, React.createElement("span", {
                      className: nameColumnStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          singleLine: true,
                          children: React.createElement(TextButton.make, {
                                onClick: (function (param) {
                                    return Router.Schema.pushDrawerItem(undefined, undefined, Belt_Option.mapWithDefault(variant, {
                                                    NAME: "event",
                                                    VAL: [
                                                      $$event.id,
                                                      undefined,
                                                      undefined,
                                                      false
                                                    ]
                                                  }, (function (variant) {
                                                      return {
                                                              NAME: "eventVariant",
                                                              VAL: [
                                                                {
                                                                  baseEventId: variant.baseEventId,
                                                                  variantId: variant.id
                                                                },
                                                                undefined
                                                              ]
                                                            };
                                                    })));
                                  }),
                                style: "Black",
                                children: Belt_Option.mapWithDefault(variant, $$event.name, (function (variant) {
                                        return CreateEventForVariantUseCase.variantEventDisplayName($$event, variant.nameSuffix);
                                      }))
                              })
                        }), Belt_Option.mapWithDefault(variant, null, (function (param) {
                            return React.createElement(DomainDetails__Components.VariantPill.make, {});
                          }))), canManage ? (
                    match[0] ? React.createElement(LoadingCircle.make, {
                            size: 24,
                            color: Styles.Color.light10
                          }) : React.createElement(ContextMenu.V2.make, {
                            position: "bottom-end",
                            children: React.createElement(ContextMenu.V2.Item.make, {
                                  label: "Remove event" + Belt_Option.mapWithDefault(variant, "", (function (_variant) {
                                          return " variant";
                                        })) + " from domain",
                                  onClick: handleRemoveEvent
                                })
                          })
                  ) : null));
}

function DomainDetails__Events$EventsEmptyState(Props) {
  var canManage = Props.canManage;
  var domain = Props.domain;
  var onClickAdd = Props.onClickAdd;
  return React.createElement("section", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.textAlign("center"),
                            tl: {
                              hd: Css.padding(Css.pct(15.0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Semi",
                  children: "Attach events to your domain"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  textAlign: "center",
                  color: Styles.Color.light11,
                  children: "Keep track of the events you care about and make it visible to all users in the workspace which events are being leveraged by your domain."
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement(DocsLink.make, {
                  path: "data-design/avo-tracking-plan/stakeholder-domains",
                  label: "Learn more",
                  display: "inlineFlex"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(Button.make, {
                  disabled: !canManage || domain.archived,
                  label: "Attach events",
                  onClick: onClickAdd
                }));
}

function DomainDetails__Events(Props) {
  var domain = Props.domain;
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  var sendActions = SendActionsContext.use(undefined);
  var schemaId = WorkspaceContext.use(undefined).id;
  var viewerId = ViewerContext.use(undefined).id;
  var events = ModelStore.Mapped.useEvents(undefined);
  var role = WorkspaceContext.useMemberRole(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var domains = DomainStore.useDomains(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var canManage = (role === "Admin" || viewerIsDomainMember) && !domain.archived;
  var match = Hooks.useDisclosure(false);
  var addEventsModalActions = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchFilter = match$1[1];
  var searchFilter = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setLoading = match$2[1];
  var domainEvents = React.useMemo((function () {
          return Belt_MapString.fromArray(Belt_Array.keepMapU(domain.items.events, (function (eventId) {
                            return Belt_Option.map(Curry._2(TrackingPlanMappedModel.Events.get, events, eventId), (function ($$event) {
                                          return [
                                                  $$event.id,
                                                  $$event
                                                ];
                                        }));
                          })));
        }), [
        domain.items.events,
        events
      ]);
  var domainEventVariants = React.useMemo((function () {
          return Belt_MapString.fromArray(Belt_Array.keepMapU(domain.items.eventVariants, (function (eventVariantId) {
                            return Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Events.get, events, VariantEventIdUseCase.baseEventIdFromVariantEventId(eventVariantId)), (function ($$event) {
                                          var maybeVariant = Belt_Array.getBy($$event.variants, (function (variant) {
                                                  return variant.id === VariantEventIdUseCase.variantIdFromVariantEventId(eventVariantId);
                                                }));
                                          return Belt_Option.map(maybeVariant, (function (variant) {
                                                        return [
                                                                eventVariantId,
                                                                [
                                                                  $$event,
                                                                  variant
                                                                ]
                                                              ];
                                                      }));
                                        }));
                          })));
        }), [
        domain.items.eventVariants,
        events
      ]);
  var domainEventsAndEventVariantIds = React.useMemo((function () {
          var domainEventIds = Belt_MapString.keysToArray(domainEvents);
          var domainEventVariantIds = Belt_MapString.keysToArray(domainEventVariants);
          return Belt_Array.concat(domainEventIds, domainEventVariantIds);
        }), [
        domain.items.events,
        domain.items.eventVariants,
        events
      ]);
  var handleRemoveEvents = function (param) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    DomainUtils.Firebase.removeDomainItems(Belt_Array.concat(Belt_Array.mapU(domain.items.events, (function (eventId) {
                    return {
                            NAME: "event",
                            VAL: eventId
                          };
                  })), Belt_Array.mapU(domain.items.eventVariants, (function (eventVariantId) {
                    return {
                            NAME: "eventVariant",
                            VAL: eventVariantId
                          };
                  }))), (function (param) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            return Curry._1(addToast, {
                        message: "Error removing all events from domain",
                        toastType: /* Error */1
                      });
          }), (function (param) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            Curry._1(addToast, {
                  message: "Removed all events from domain",
                  toastType: /* Success */0
                });
            if (domain.items.eventVariants.length <= 1) {
              Belt_Array.forEach(domain.items.eventVariants, (function (eventVariantId) {
                      return AnalyticsRe.domainItemRemovedSingleItem(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domain.name, Curry._1(Domains.size, domains), "DomainSettings", viewerIsDomainMember ? "DomainTeamMember" : "Admin", "Stakeholder", "EventVariant", Belt_Option.mapWithDefault(Belt_MapString.get(domainEventVariants, eventVariantId), "", (function (param) {
                                        return CreateEventForVariantUseCase.variantEventDisplayName(param[0], param[1].nameSuffix);
                                      })), eventVariantId, schemaId);
                    }));
            } else {
              AnalyticsRe.domainItemRemovedMultipleItems(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domain.name, Curry._1(Domains.size, domains), "DomainSettings", viewerIsDomainMember ? "DomainTeamMember" : "Admin", "Stakeholder", domain.items.eventVariants.length, "EventVariant", schemaId);
            }
            if (domain.items.events.length <= 1) {
              return Belt_Array.forEach(domain.items.events, (function (eventId) {
                            return AnalyticsRe.domainItemRemovedSingleItem(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domain.name, Curry._1(Domains.size, domains), "DomainSettings", viewerIsDomainMember ? "DomainTeamMember" : "Admin", "Stakeholder", "Event", Belt_Option.getWithDefault(TrackingPlanMappedModel.Events.getName(events, eventId), ""), eventId, schemaId);
                          }));
            } else {
              return AnalyticsRe.domainItemRemovedMultipleItems(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domain.name, Curry._1(Domains.size, domains), "DomainSettings", viewerIsDomainMember ? "DomainTeamMember" : "Admin", "Stakeholder", domain.items.events.length, "Event", schemaId);
            }
          }), schemaId, sendActions, viewerId, domain.id);
    
  };
  var onClickAdd = function (param) {
    return Curry._1(addEventsModalActions.open_, undefined);
  };
  var filteredDomainEventIds = React.useMemo((function () {
          if (searchFilter.trim() === "") {
            return Belt_MapString.keysToArray(domainEvents);
          } else {
            return Belt_Array.map(Fuzzaldrin.filter(Belt_MapString.valuesToArray(domainEvents), searchFilter, {
                            key: "name",
                            maxResults: 1000
                          }), (function (param) {
                          return param.id;
                        }));
          }
        }), [
        domain.items.events,
        events,
        searchFilter
      ]);
  var filteredDomainEventVariantIds = React.useMemo((function () {
          if (searchFilter.trim() === "") {
            return Belt_MapString.keysToArray(domainEventVariants);
          } else {
            return Belt_Array.mapU(Fuzzaldrin.filter(Belt_Array.mapU(Belt_MapString.toArray(domainEventVariants), (function (param) {
                                  var match = param[1];
                                  var $$event = match[0];
                                  return {
                                          id: param[0],
                                          name: CreateEventForVariantUseCase.variantEventDisplayName($$event, match[1].nameSuffix),
                                          uniqueName: $$event.uniqueName,
                                          description: $$event.description,
                                          directPropertyRefs: $$event.directPropertyRefs,
                                          propertyBundles: $$event.propertyBundles,
                                          variants: $$event.variants,
                                          types: $$event.types,
                                          tags: $$event.tags,
                                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                          includeSources: $$event.includeSources,
                                          includeDestinations: $$event.includeDestinations,
                                          hashes: $$event.hashes,
                                          propertyWhitelist: $$event.propertyWhitelist,
                                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                          triggers: $$event.triggers,
                                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                        };
                                })), searchFilter, {
                            key: "name",
                            maxResults: 1000
                          }), (function (param) {
                          return param.id;
                        }));
          }
        }), [
        domain.items.eventVariants,
        events,
        searchFilter
      ]);
  var domainEventAndVariantsRenderArray = React.useMemo((function () {
          var events = Belt_Array.keepMapU(filteredDomainEventIds, (function (eventId) {
                  return Belt_Option.map(Belt_MapString.get(domainEvents, eventId), (function ($$event) {
                                return [
                                        $$event.name,
                                        {
                                          NAME: "Event",
                                          VAL: $$event
                                        }
                                      ];
                              }));
                }));
          var variants = Belt_Array.keepMapU(filteredDomainEventVariantIds, (function (eventVariantId) {
                  return Belt_Option.map(Belt_MapString.get(domainEventVariants, eventVariantId), (function (param) {
                                var variant = param[1];
                                var $$event = param[0];
                                return [
                                        CreateEventForVariantUseCase.variantEventDisplayName($$event, variant.nameSuffix),
                                        {
                                          NAME: "EventVariant",
                                          VAL: [
                                            $$event,
                                            variant
                                          ]
                                        }
                                      ];
                              }));
                }));
          var items = Belt_Array.concat(events, variants);
          return Belt_SortArray.stableSortBy(items, (function (param, param$1) {
                        return Caml.caml_string_compare(param[0], param$1[0]);
                      }));
        }), [
        domain.items.events,
        domain.items.eventVariants,
        filteredDomainEventIds,
        filteredDomainEventVariantIds
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement(DomainDetails__Components.Card.make, {
                  children: null
                }, React.createElement(DomainDetails__Components.CardSection.make, {
                      className: Curry._1(Css.style, {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
                            tl: {
                              hd: Css.paddingRight(Css.px(16)),
                              tl: /* [] */0
                            }
                          }),
                      children: React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("grid"),
                                  tl: {
                                    hd: Css.gridTemplateColumns({
                                          hd: {
                                            NAME: "fr",
                                            VAL: 1.0
                                          },
                                          tl: {
                                            hd: {
                                              NAME: "px",
                                              VAL: 240
                                            },
                                            tl: {
                                              hd: "minContent",
                                              tl: {
                                                hd: "minContent",
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.gridGap(Css.px(8)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Medium",
                                    weight: "Semi",
                                    children: AvoUtils.plural(undefined, undefined, domainEventsAndEventVariantIds.length, "event") + " attached"
                                  }), React.createElement(Tooltip.make, {
                                    title: "Attached events",
                                    children: React.createElement(Tooltip.Content.make, {
                                          children: React.createElement(Markdown.make, {
                                                source: "This section contains a list of all events and event variants that are attached to this domain. Only domain team members and workspace admins can add or remove events on a domain."
                                              })
                                        })
                                  })), React.createElement(Input.make, {
                                value: searchFilter,
                                onChange: (function (v) {
                                    return Curry._1(setSearchFilter, (function (param) {
                                                  return v;
                                                }));
                                  }),
                                placeholder: "Search...",
                                className: {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: /* [] */0
                                },
                                disabled: domainEventsAndEventVariantIds.length === 0,
                                type_: "search"
                              }), React.createElement(Button.make, {
                                disabled: !canManage || domain.archived,
                                icon: "plus",
                                label: "Add",
                                onClick: onClickAdd
                              }), match$2[0] ? React.createElement(LoadingCircle.make, {
                                  size: 24,
                                  color: Styles.Color.light10
                                }) : React.createElement(ContextMenu.V2.make, {
                                  position: "bottom-end",
                                  children: null
                                }, React.createElement(ContextMenu.V2.Item.make, {
                                      label: "View events in tracking plan",
                                      onClick: (function (param) {
                                          Curry._1(globalSend, {
                                                TAG: /* UpdateFilters */0,
                                                _0: {
                                                  TAG: /* SetDomain */14,
                                                  _0: domain.id
                                                },
                                                _1: "Events"
                                              });
                                          return Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                                        }),
                                      disabled: domain.items.events.length === 0
                                    }), React.createElement(ContextMenu.V2.Item.make, {
                                      label: "Remove all events from domain",
                                      onClick: handleRemoveEvents,
                                      style: "Warning",
                                      disabled: role !== "Admin" && !viewerIsDomainMember || DomainUtils.getItemCount(domain) === 0
                                    })))
                    }), React.createElement(DomainDetails__Components.CardSection.make, {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingTop(Css.px(8)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(8)),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light03, 0.7)),
                                tl: {
                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }),
                      children: React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Event name"
                          })
                    }), domainEventsAndEventVariantIds.length !== 0 ? (
                    domainEventAndVariantsRenderArray.length !== 0 ? React.createElement(React.Fragment, undefined, Belt_Array.mapU(domainEventAndVariantsRenderArray, (function (param) {
                                  var item = param[1];
                                  if (item.NAME === "EventVariant") {
                                    var match = item.VAL;
                                    var variant = match[1];
                                    var $$event = match[0];
                                    return React.createElement(DomainDetails__Events$Event, {
                                                domain: domain,
                                                viewerIsDomainMember: viewerIsDomainMember,
                                                event: $$event,
                                                variant: variant,
                                                key: $$event.id + variant.id
                                              });
                                  }
                                  var $$event$1 = item.VAL;
                                  return React.createElement(DomainDetails__Events$Event, {
                                              domain: domain,
                                              viewerIsDomainMember: viewerIsDomainMember,
                                              event: $$event$1,
                                              key: $$event$1.id
                                            });
                                }))) : React.createElement("div", {
                            className: Curry._1(Css.merge, {
                                  hd: rowStyles,
                                  tl: {
                                    hd: Curry._1(Css.style, {
                                          hd: Css.minHeight(Css.px(44)),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                element: "Em",
                                size: "Medium",
                                weight: "Semi",
                                children: "No events found"
                              }))
                  ) : React.createElement(DomainDetails__Events$EventsEmptyState, {
                        canManage: canManage,
                        domain: domain,
                        onClickAdd: onClickAdd
                      })), React.createElement(AddEventsModal.make, {
                  viewerIsDomainMember: viewerIsDomainMember,
                  domain: domain,
                  opened: match[0],
                  onClose: (function (param) {
                      return Curry._1(addEventsModalActions.close, undefined);
                    })
                }));
}

var make = DomainDetails__Events;

export {
  make ,
  
}
/* rowStyles Not a pure module */
