// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as TextButton from "../TextButton.mjs";

function ExpandImplementationStatusListButton(Props) {
  var expanded = Props.expanded;
  var setExpanded = Props.setExpanded;
  return React.createElement(TextButton.make, {
              onClick: (function (param) {
                  return Curry._1(setExpanded, (function (param) {
                                return !expanded;
                              }));
                }),
              style: "Blue",
              size: "Small",
              noFocus: true,
              children: null
            }, expanded ? React.createElement(React.Fragment, undefined, React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.px(12)),
                              tl: /* [] */0
                            })
                      }, "-"), "Show ") : React.createElement(React.Fragment, undefined, React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.px(12)),
                              tl: /* [] */0
                            })
                      }, "+"), "Show "), "other environments");
}

var make = ExpandImplementationStatusListButton;

export {
  make ,
  
}
/* Css Not a pure module */
