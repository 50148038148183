// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

var context = React.createContext({
      selectedEventIds: undefined,
      toggleEventIds: (function (param) {
          
        }),
      selectEventIds: (function (param) {
          
        }),
      clearAll: (function (param) {
          
        }),
      maxHeight: window.innerHeight - 302 | 0
    });

var provider = context.Provider;

function use(param) {
  return React.useContext(context);
}

function BulkContext(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        
      });
  var setSelectedEventIds = match[1];
  var selectedEventIds = match[0];
  var match$1 = React.useState(function () {
        return window.innerHeight;
      });
  var setWindowHeight = match$1[1];
  var windowHeight = match$1[0];
  React.useEffect((function () {
          var resizeHandler = function (param) {
            return Curry._1(setWindowHeight, (function (param) {
                          return window.innerHeight;
                        }));
          };
          window.addEventListener("resize", resizeHandler);
          return (function (param) {
                    window.removeEventListener("resize", resizeHandler);
                    
                  });
        }), []);
  var maxHeight = React.useMemo((function () {
          return windowHeight - 302 | 0;
        }), [windowHeight]);
  var value = React.useMemo((function () {
          return {
                  selectedEventIds: selectedEventIds,
                  toggleEventIds: (function (eventIds) {
                      return Curry._1(setSelectedEventIds, (function (currentSelection) {
                                    return Belt_Array.reduceU(eventIds, currentSelection, (function (newSelection, eventId) {
                                                  if (Belt_SetString.has(newSelection, eventId)) {
                                                    return Belt_SetString.remove(newSelection, eventId);
                                                  } else {
                                                    return Belt_SetString.add(newSelection, eventId);
                                                  }
                                                }));
                                  }));
                    }),
                  selectEventIds: (function (eventIds) {
                      return Curry._1(setSelectedEventIds, (function (currentSelection) {
                                    return Belt_SetString.mergeMany(currentSelection, eventIds);
                                  }));
                    }),
                  clearAll: (function (param) {
                      return Curry._1(setSelectedEventIds, (function (param) {
                                    
                                  }));
                    }),
                  maxHeight: maxHeight
                };
        }), [
        selectedEventIds,
        maxHeight
      ]);
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var make = BulkContext;

export {
  context ,
  provider ,
  use ,
  make ,
  
}
/* context Not a pure module */
