// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function getInspectorStatusPriority(isEventModified, statusOfEnv) {
  if (!isEventModified) {
    return statusOfEnv + 1 | 0;
  }
  switch (statusOfEnv) {
    case /* Prod */0 :
        return 3;
    case /* Dev */1 :
        return 1;
    case /* Staging */2 :
        return 2;
    
  }
}

function compareInspectorLoadingStatus(loadingStatusA, loadingStatusB) {
  if (typeof loadingStatusA === "number" && loadingStatusA !== 0) {
    if (typeof loadingStatusB === "number" && loadingStatusB !== 0) {
      return 0;
    } else {
      return 1;
    }
  }
  if (typeof loadingStatusB === "number" && loadingStatusB !== 0) {
    return -1;
  } else {
    return 0;
  }
}

function orderInspectorStatusByPriority(isEventModified, statusArray) {
  return statusArray.slice().sort(function (a, b) {
              if (a.TAG !== /* Inspector */0) {
                return 999;
              }
              if (b.TAG !== /* Inspector */0) {
                return 999;
              }
              var sortLoadingStatus = compareInspectorLoadingStatus(a._1, b._1);
              if (sortLoadingStatus === 0) {
                return Caml.caml_int_compare(getInspectorStatusPriority(isEventModified, a._0), getInspectorStatusPriority(isEventModified, b._0));
              } else {
                return sortLoadingStatus;
              }
            });
}

function shouldShowCodegenFirst(isEventModified, inspectorStatuses, codegenStatus) {
  var knowsCodegenStatus;
  if (codegenStatus.TAG === /* Inspector */0) {
    knowsCodegenStatus = false;
  } else {
    var match = codegenStatus._0;
    knowsCodegenStatus = typeof match === "number" ? match === 0 : true;
  }
  var knowsDevInspectorStatus = Belt_Array.someU(inspectorStatuses, (function (status) {
          if (status.TAG !== /* Inspector */0) {
            return false;
          }
          if (status._0 !== 1) {
            return false;
          }
          var match = status._1;
          if (typeof match === "number") {
            return match === 0;
          } else {
            return true;
          }
        }));
  var knowsProdInspectorStatus = Belt_Array.someU(inspectorStatuses, (function (status) {
          if (status.TAG !== /* Inspector */0) {
            return false;
          }
          if (status._0 !== 0) {
            return false;
          }
          var match = status._1;
          if (typeof match === "number") {
            return match === 0;
          } else {
            return true;
          }
        }));
  var eventModifiedAndCodegenStatusKnown = isEventModified && knowsCodegenStatus;
  var eventModifiedAndInspectorStatusUnknown = isEventModified && !knowsDevInspectorStatus;
  var knowsCodegenStatusButNotInspectorStatus = knowsCodegenStatus && !knowsDevInspectorStatus && !knowsProdInspectorStatus;
  if (eventModifiedAndCodegenStatusKnown || eventModifiedAndInspectorStatusUnknown) {
    return true;
  } else {
    return knowsCodegenStatusButNotInspectorStatus;
  }
}

var orderInspectorStatusesBasedOnIsEventModified = orderInspectorStatusByPriority;

function highlightedStatus(isEventModified, codegenStatus, inspectorStatuses) {
  var shouldShowCodegenFirst$1 = shouldShowCodegenFirst(isEventModified, inspectorStatuses, codegenStatus);
  if (shouldShowCodegenFirst$1) {
    return codegenStatus;
  } else {
    return Belt_Option.getWithDefault(Belt_Array.get(orderInspectorStatusByPriority(isEventModified, inspectorStatuses), 0), {
                TAG: /* Inspector */0,
                _0: /* Prod */0,
                _1: /* Unknown */1
              });
  }
}

export {
  getInspectorStatusPriority ,
  compareInspectorLoadingStatus ,
  orderInspectorStatusByPriority ,
  shouldShowCodegenFirst ,
  orderInspectorStatusesBasedOnIsEventModified ,
  highlightedStatus ,
  
}
/* No side effect */
