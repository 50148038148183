// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_MutableMap from "rescript/lib/es6/belt_MutableMap.js";
import * as InspectorPropertyType from "../../../../../shared/models/InspectorPropertyType.mjs";

function cmp(arrayA, arrayB) {
  return Belt_Array.cmpU(arrayA, arrayB, $$String.compare);
}

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function empty(param) {
  return Belt_MutableMap.make(Comparator);
}

var MutableMap = {
  Int: Belt_MutableMap.Int,
  $$String: Belt_MutableMap.$$String,
  make: Belt_MutableMap.make,
  clear: Belt_MutableMap.clear,
  isEmpty: Belt_MutableMap.isEmpty,
  has: Belt_MutableMap.has,
  cmpU: Belt_MutableMap.cmpU,
  cmp: Belt_MutableMap.cmp,
  eqU: Belt_MutableMap.eqU,
  eq: Belt_MutableMap.eq,
  forEachU: Belt_MutableMap.forEachU,
  forEach: Belt_MutableMap.forEach,
  reduceU: Belt_MutableMap.reduceU,
  reduce: Belt_MutableMap.reduce,
  everyU: Belt_MutableMap.everyU,
  every: Belt_MutableMap.every,
  someU: Belt_MutableMap.someU,
  some: Belt_MutableMap.some,
  size: Belt_MutableMap.size,
  toList: Belt_MutableMap.toList,
  toArray: Belt_MutableMap.toArray,
  fromArray: Belt_MutableMap.fromArray,
  keysToArray: Belt_MutableMap.keysToArray,
  valuesToArray: Belt_MutableMap.valuesToArray,
  minKey: Belt_MutableMap.minKey,
  minKeyUndefined: Belt_MutableMap.minKeyUndefined,
  maxKey: Belt_MutableMap.maxKey,
  maxKeyUndefined: Belt_MutableMap.maxKeyUndefined,
  minimum: Belt_MutableMap.minimum,
  minUndefined: Belt_MutableMap.minUndefined,
  maximum: Belt_MutableMap.maximum,
  maxUndefined: Belt_MutableMap.maxUndefined,
  get: Belt_MutableMap.get,
  getUndefined: Belt_MutableMap.getUndefined,
  getWithDefault: Belt_MutableMap.getWithDefault,
  getExn: Belt_MutableMap.getExn,
  checkInvariantInternal: Belt_MutableMap.checkInvariantInternal,
  remove: Belt_MutableMap.remove,
  removeMany: Belt_MutableMap.removeMany,
  set: Belt_MutableMap.set,
  updateU: Belt_MutableMap.updateU,
  update: Belt_MutableMap.update,
  mergeMany: Belt_MutableMap.mergeMany,
  mapU: Belt_MutableMap.mapU,
  map: Belt_MutableMap.map,
  mapWithKeyU: Belt_MutableMap.mapWithKeyU,
  mapWithKey: Belt_MutableMap.mapWithKey,
  empty: empty
};

var InspectorPropertiesFlattenedByNamesMap = {
  Comparator: Comparator,
  MutableMap: MutableMap
};

function get(inspectorObjectsEnabled, properties) {
  var map = Belt_MutableMap.make(Comparator);
  Belt_Array.forEach(properties, (function (param) {
          var propertyName = param[0];
          var consolidatedPropertyType = InspectorPropertyType.V2.consolidatePropertyType(param[1]);
          if (consolidatedPropertyType === undefined) {
            return ;
          }
          Belt_MutableMap.set(map, [propertyName], consolidatedPropertyType);
          var match = consolidatedPropertyType.rawType;
          if (typeof match === "number" || !inspectorObjectsEnabled) {
            return ;
          } else {
            return Belt_Array.forEach(Js_dict.entries(match._0), (function (param) {
                          var childType = param[1];
                          var childName = param[0];
                          Belt_MutableMap.set(map, [
                                propertyName,
                                childName
                              ], childType);
                          var grandChildren = childType.rawType;
                          if (typeof grandChildren === "number") {
                            return ;
                          } else {
                            return Belt_Array.forEach(Js_dict.entries(grandChildren._0), (function (param) {
                                          return Belt_MutableMap.set(map, [
                                                      propertyName,
                                                      childName,
                                                      param[0]
                                                    ], param[1]);
                                        }));
                          }
                        }));
          }
        }));
  return map;
}

export {
  InspectorPropertiesFlattenedByNamesMap ,
  get ,
  
}
/* Comparator Not a pure module */
