// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FramerMotion from "framer-motion";
import * as ModalCloseButton from "./ModalCloseButton.mjs";

function modalStyles(fixedHeight, fixedWidth) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.justifyContent("stretch"),
                        tl: {
                          hd: Css.minHeight(Css.px(350)),
                          tl: {
                            hd: Css.maxHeight(Css.pct(90)),
                            tl: {
                              hd: Css.boxShadow(Styles.Shadow.standard),
                              tl: {
                                hd: Css.borderRadius(Css.px(10)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.white),
                                  tl: {
                                    hd: Css.width(Css.px(664)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: fixedHeight !== undefined ? Curry._1(Css.style, {
                        hd: Css.height(Css.px(fixedHeight)),
                        tl: /* [] */0
                      }) : Curry._1(Css.style, /* [] */0),
                tl: {
                  hd: fixedWidth !== undefined ? Curry._1(Css.style, {
                          hd: Css.width(Css.px(fixedWidth)),
                          tl: /* [] */0
                        }) : Curry._1(Css.style, /* [] */0),
                  tl: /* [] */0
                }
              }
            });
}

var titleStyles = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(24)),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.bold),
        tl: {
          hd: Css.lineHeight(Css.pct(140.0)),
          tl: {
            hd: Css.letterSpacing(Css.em(-0.019)),
            tl: {
              hd: Css.margin(Css.px(0)),
              tl: {
                hd: Css.color(Styles.Color.light12),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var imageBoxStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("stretch"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light04),
              tl: {
                hd: Css.borderTopLeftRadius(Css.px(10)),
                tl: {
                  hd: Css.borderBottomLeftRadius(Css.px(10)),
                  tl: {
                    hd: Css.overflow("hidden"),
                    tl: {
                      hd: Css.minWidth(Css.px(264)),
                      tl: {
                        hd: Css.flexGrow(1.0),
                        tl: {
                          hd: Css.flexBasis(Css.px(264)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var imageBoxBackgroundStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var artworkStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.right("auto"),
            tl: {
              hd: Css.bottom("auto"),
              tl: {
                hd: Css.width(Css.pct(100.0)),
                tl: {
                  hd: Css.height(Css.pct(100.0)),
                  tl: {
                    hd: Css.selector(" > svg", {
                          hd: Css.height(Css.pct(100.0)),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function contentStyles(fixedWidth) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("flexStart"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.paddingTop(Css.px(56)),
                      tl: {
                        hd: Css.paddingRight(Css.px(48)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(40)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(48)),
                            tl: {
                              hd: Css.width(Css.px(fixedWidth !== undefined ? fixedWidth - 264 | 0 : 400)),
                              tl: {
                                hd: Css.flexShrink(0.0),
                                tl: {
                                  hd: Css.flexGrow(0.0),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function ModalWithArtwork(Props) {
  var artwork = Props.artwork;
  var artworkKeyOpt = Props.artworkKey;
  var contentKeyOpt = Props.contentKey;
  var id = Props.id;
  var noDismissOpt = Props.noDismiss;
  var onClose = Props.onClose;
  var title = Props.title;
  var fixedHeight = Props.fixedHeight;
  var fixedWidth = Props.fixedWidth;
  var children = Props.children;
  var artworkKey = artworkKeyOpt !== undefined ? artworkKeyOpt : "artworkKey";
  var contentKey = contentKeyOpt !== undefined ? contentKeyOpt : "contentKey";
  var noDismiss = noDismissOpt !== undefined ? noDismissOpt : false;
  var tmp = {
    className: modalStyles(fixedHeight, fixedWidth),
    onClick: (function (prim) {
        prim.stopPropagation();
        
      })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("div", tmp, noDismiss ? null : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.top(Css.px(16)),
                            tl: {
                              hd: Css.right(Css.px(16)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement(ModalCloseButton.make, {
                        onClick: (function (param) {
                            return Curry._1(onClose, undefined);
                          })
                      })), React.createElement("div", {
                  className: imageBoxStyles
                }, React.createElement(Icons.BlurBackground.make, {
                      className: imageBoxBackgroundStyles
                    }), React.createElement(FramerMotion.AnimatePresence, {
                      exitBeforeEnter: true,
                      initial: false,
                      children: React.createElement(FramerMotion.motion.div, {
                            animate: {
                              opacity: 1
                            },
                            transition: {
                              duration: 0.2
                            },
                            initial: {
                              opacity: 0
                            },
                            exit: {
                              opacity: 0
                            },
                            className: artworkStyles,
                            key: artworkKey,
                            children: artwork
                          })
                    })), React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: React.createElement(FramerMotion.motion.div, {
                        animate: {
                          opacity: 1,
                          x: 0
                        },
                        transition: {
                          duration: 0.2
                        },
                        initial: {
                          opacity: 0,
                          x: 40
                        },
                        exit: {
                          opacity: 0,
                          x: -40
                        },
                        className: contentStyles(fixedWidth),
                        key: contentKey,
                        children: null
                      }, React.createElement("h1", {
                            className: titleStyles
                          }, title), React.createElement(Spacer.make, {
                            height: 8
                          }), children)
                }));
}

var make = ModalWithArtwork;

export {
  modalStyles ,
  titleStyles ,
  imageBoxStyles ,
  imageBoxBackgroundStyles ,
  artworkStyles ,
  contentStyles ,
  make ,
  
}
/* titleStyles Not a pure module */
