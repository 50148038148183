// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "./styles.mjs";

function IconTrackingPlan(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light08;
  return React.createElement("svg", {
              height: "12",
              width: "14",
              fill: "none",
              viewBox: "0 0 14 12",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M0 0.809017C0 0.437326 0.391156 0.195578 0.723607 0.361803L3.72361 1.8618C3.893 1.9465 4 2.11963 4 2.30902V11.191C4 11.5627 3.60884 11.8044 3.27639 11.6382L0.276393 10.1382C0.107001 10.0535 0 9.88037 0 9.69098V0.809017Z",
                  fill: Styles.Color.toString(color)
                }), React.createElement("path", {
                  d: "M5 2.30902C5 2.11963 5.107 1.9465 5.27639 1.8618L8.27639 0.361803C8.60884 0.195578 9 0.437326 9 0.809017V9.69098C9 9.88037 8.893 10.0535 8.72361 10.1382L5.72361 11.6382C5.39116 11.8044 5 11.5627 5 11.191V2.30902Z",
                  fill: Styles.Color.toString(color)
                }), React.createElement("path", {
                  d: "M10 0.809017C10 0.437326 10.3912 0.195578 10.7236 0.361803L13.7236 1.8618C13.893 1.9465 14 2.11963 14 2.30902V11.191C14 11.5627 13.6088 11.8044 13.2764 11.6382L10.2764 10.1382C10.107 10.0535 10 9.88037 10 9.69098V0.809017Z",
                  fill: Styles.Color.toString(color)
                }));
}

var make = IconTrackingPlan;

export {
  make ,
  
}
/* react Not a pure module */
