// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as IntegrationUtils from "../../../api/functions/src/IntegrationUtils.mjs";
import * as WebhookPayloadFormat from "../../../shared/models/WebhookPayloadFormat.mjs";

function trackIntegrationConfigured(integrations, integration, schemaBundle, configureType) {
  var match = integration.config;
  var tmp;
  tmp = match !== undefined && match.TAG === /* Webhook */3 ? WebhookPayloadFormat.toAnalytics(match._0.payloadFormat) : undefined;
  return AnalyticsRe.integrationConfigured(IntegrationUtils.configToAnalyticsType(integration.config), integration.filters === /* [] */0 ? ["None"] : Belt_Array.concatMany([
                    Belt_Option.mapWithDefault(Belt_List.getByU(integration.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME === "Source";
                                } else {
                                  return false;
                                }
                              })), [], (function (param) {
                            return ["Sources"];
                          })),
                    Belt_Option.mapWithDefault(Belt_List.getByU(integration.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME === "Destination";
                                } else {
                                  return false;
                                }
                              })), [], (function (param) {
                            return ["Destinations"];
                          })),
                    Belt_Option.mapWithDefault(Belt_List.getByU(integration.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME === "Tag";
                                } else {
                                  return false;
                                }
                              })), [], (function (param) {
                            return ["Tags"];
                          }))
                  ]), AvoConfig.getIntegrationName(integration), integration.id, tmp, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Created", configureType, Belt_List.length(integrations), schemaBundle.branchId, schemaBundle.schemaId);
}

export {
  trackIntegrationConfigured ,
  
}
/* AvoConfig Not a pure module */
