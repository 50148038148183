// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as LoadingCircle from "../LoadingCircle.mjs";
import * as ImplementationStatusStates from "./ImplementationStatusStates.mjs";

function ImplementationStatusLight(Props) {
  var implementationStatus = Props.implementationStatus;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 12;
  var statusStyles = ImplementationStatusStates.statusStyles(implementationStatus);
  var envIndicator;
  var exit = 0;
  if (implementationStatus.TAG === /* Inspector */0) {
    var exit$1 = 0;
    switch (implementationStatus._0) {
      case /* Prod */0 :
          if (typeof implementationStatus._1 === "number") {
            exit$1 = 2;
          } else {
            envIndicator = "P";
          }
          break;
      case /* Dev */1 :
          if (typeof implementationStatus._1 === "number") {
            exit$1 = 2;
          } else {
            envIndicator = "D";
          }
          break;
      case /* Staging */2 :
          if (typeof implementationStatus._1 === "number") {
            exit$1 = 2;
          } else {
            envIndicator = "S";
          }
          break;
      
    }
    if (exit$1 === 2) {
      var tmp = implementationStatus._1;
      if (typeof tmp === "number") {
        if (tmp === /* Loading */0) {
          exit = 1;
        } else {
          envIndicator = "?";
        }
      }
      
    }
    
  } else {
    var match = implementationStatus._0;
    if (typeof match === "number") {
      if (match !== 0) {
        envIndicator = "?";
      } else {
        exit = 1;
      }
    } else {
      envIndicator = "D";
    }
  }
  if (exit === 1) {
    envIndicator = React.createElement(LoadingCircle.make, {
          size: (size << 1) / 3 | 0,
          color: Styles.Color.light07
        });
  }
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: Curry._1(Css.style, statusStyles),
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.width(Css.px(size)),
                                  tl: {
                                    hd: Css.height(Css.px(size)),
                                    tl: {
                                      hd: Css.borderRadius(Css.px(size / 2 | 0)),
                                      tl: {
                                        hd: Css.fontSize(Css.px((size << 1) / 3 | 0)),
                                        tl: {
                                          hd: Css.flexShrink(0.0),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement($$Text.make, {
                  weight: "Bold",
                  children: envIndicator
                }));
}

var make = ImplementationStatusLight;

export {
  make ,
  
}
/* Css Not a pure module */
