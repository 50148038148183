// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as Link from "../Link.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as Prism from "../externals/Prism.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as $$Window from "../externals/window.mjs";
import * as Tooltip from "../Tooltip.mjs";
import Shortid from "shortid";
import * as Intercom from "../externals/intercom.mjs";
import * as Markdown from "../Markdown.mjs";
import * as Tooltips from "../markdown/tooltips/tooltips.mjs";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as CopyButton from "../CopyButton.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Collapsible from "../Collapsible.mjs";
import * as KeyListener from "../KeyListener.mjs";
import * as SnippetUtils from "./SnippetUtils.mjs";
import * as AppFeatureFlag from "../AppFeatureFlag.mjs";
import * as ResizingSelect from "../ResizingSelect.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as RescriptReactErrorBoundary from "@rescript/react/src/RescriptReactErrorBoundary.mjs";

var SourceNotFoundException = /* @__PURE__ */Caml_exceptions.create("EventImplementationSnippet.SourceNotFoundException");

var warningContainer = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(16), Css.px(20), Css.px(0), Css.px(20)),
      tl: {
        hd: Css.marginBottom(Css.px(-8)),
        tl: {
          hd: Css.color(Styles.Color.light01),
          tl: /* [] */0
        }
      }
    });

var footerContainer = Curry._1(Css.style, {
      hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light11),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.position("relative"),
            tl: {
              hd: Css.after({
                    hd: Css.position("absolute"),
                    tl: {
                      hd: Css.unsafe("content", "' '"),
                      tl: {
                        hd: Css.width(Css.px(22)),
                        tl: {
                          hd: Css.height(Css.pct(100.0)),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(34)),
                              tl: {
                                hd: Css.background({
                                      NAME: "linearGradient",
                                      VAL: [
                                        {
                                          NAME: "deg",
                                          VAL: 90.0
                                        },
                                        [
                                          [
                                            Css.pct(0.0),
                                            Styles.Color.setAlpha(Styles.Color.light12, 0.0)
                                          ],
                                          [
                                            Css.pct(100.0),
                                            Styles.Color.setAlpha(Styles.Color.light12, 1.0)
                                          ]
                                        ]
                                      ]
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var codegenDocsInfo = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingRight(Css.px(16)),
        tl: {
          hd: Css.paddingTop(Css.px(8)),
          tl: {
            hd: Css.paddingBottom(Css.px(12)),
            tl: {
              hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light11),
              tl: {
                hd: Css.whiteSpace("nowrap"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var pullString = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.paddingLeft(Css.px(16)),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.paddingBottom(Css.px(2)),
            tl: {
              hd: Css.fontWeight(Styles.FontWeight.regular),
              tl: {
                hd: Css.fontSize(Styles.FontSize.small),
                tl: {
                  hd: Css.color(Styles.Color.white),
                  tl: {
                    hd: Css.overflow("auto"),
                    tl: {
                      hd: Css.whiteSpace("nowrap"),
                      tl: {
                        hd: Css.selector("::-webkit-scrollbar", {
                              hd: Css.display("none"),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.unsafe("scrollbarWidth", "none"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var prismContainer = Curry._1(Css.style, {
      hd: Css.padding("zero"),
      tl: {
        hd: Css.paddingTop(Css.px(10)),
        tl: {
          hd: Css.position("relative"),
          tl: {
            hd: Css.selector(":has(.token.prefix.inserted):not(:has(.token.prefix.unchanged, .token.prefix.deleted))", {
                  hd: Styles.backgroundColorWithOverlayGuarantee(Styles.Color.light12, Styles.Color.setAlpha(Styles.Color.green, 0.1)),
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.selector(":has(.token.prefix.deleted):not(:has(.token.prefix.unchanged, .token.prefix.inserted))", {
                    hd: Styles.backgroundColorWithOverlayGuarantee(Styles.Color.light12, Styles.Color.setAlpha(Styles.Color.red, 0.1)),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.selector(":has(.token.prefix.unchanged) span[class*='language-'].deleted,\n         :has(.token.prefix.inserted) span[class*='language-'].deleted", {
                      hd: Css.display("block"),
                      tl: {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.width("fitContent"),
                          tl: {
                            hd: Css.minWidth(Css.pct(100.0)),
                            tl: {
                              hd: Css.after({
                                    hd: Css.position("absolute"),
                                    tl: {
                                      hd: Css.unsafe("content", "' '"),
                                      tl: {
                                        hd: Css.left(Css.px(-20)),
                                        tl: {
                                          hd: Css.right(Css.px(-20)),
                                          tl: {
                                            hd: Css.top(Css.px(0)),
                                            tl: {
                                              hd: Css.height(Css.pct(100.0)),
                                              tl: {
                                                hd: Css.pointerEvents("none"),
                                                tl: {
                                                  hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.red, 0.1)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: Css.selector(":has(.token.prefix.unchanged) span[class*='language-'].inserted,\n         :has(.token.prefix.deleted) span[class*='language-'].inserted", {
                        hd: Css.display("block"),
                        tl: {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.width("fitContent"),
                            tl: {
                              hd: Css.minWidth(Css.pct(100.0)),
                              tl: {
                                hd: Css.after({
                                      hd: Css.position("absolute"),
                                      tl: {
                                        hd: Css.unsafe("content", "' '"),
                                        tl: {
                                          hd: Css.left(Css.px(-20)),
                                          tl: {
                                            hd: Css.right(Css.px(-20)),
                                            tl: {
                                              hd: Css.top(Css.px(0)),
                                              tl: {
                                                hd: Css.height(Css.pct(100.0)),
                                                tl: {
                                                  hd: Css.pointerEvents("none"),
                                                  tl: {
                                                    hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.green, 0.1)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var prism = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.important(Css.fontSize(Styles.FontSize.regular)),
            tl: {
              hd: Css.padding4(Css.px(6), Css.px(20), Css.px(24), Css.px(20)),
              tl: {
                hd: Css.selector("::-webkit-scrollbar", {
                      hd: Css.display("none"),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.unsafe("msOverflowStyle", "none"),
                  tl: {
                    hd: Css.unsafe("scrollbarWidth", "none"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }),
      tl: {
        hd: "code-snippet",
        tl: /* [] */0
      }
    });

var Style = {
  warningContainer: warningContainer,
  footerContainer: footerContainer,
  codegenDocsInfo: codegenDocsInfo,
  pullString: pullString,
  prismContainer: prismContainer,
  prism: prism
};

var includeInCodegenTooltip = React.createElement(Tooltip.make, {
      title: "Include in Codegen",
      tooltipPadding: 8,
      withAskUsAnything: false,
      withDismiss: false,
      withTitle: false,
      children: React.createElement(Tooltip.Content.make, {
            children: React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding4(Css.px(4), Css.px(8), Css.px(4), Css.px(16)),
                        tl: /* [] */0
                      })
                }, React.createElement(Markdown.make, {
                      source: "This event is configured to be sent with the Avo generated code. Uncheck the \"Include in Codegen\" checkbox in the Sources section above if you are not using Codegen for this event."
                    }))
          })
    });

function EventImplementationSnippet$SingleSource(Props) {
  var diff = Props.diff;
  var exit = 0;
  var source;
  var source$1;
  var source$2;
  var variant = diff.NAME;
  if (variant === "unifiedDiff") {
    var match = diff.VAL[0];
    var variant$1 = match.NAME;
    if (variant$1 === "pseudocode") {
      source$1 = match.VAL.source;
      exit = 2;
    } else if (variant$1 === "invalid") {
      source$2 = match.VAL.source;
      exit = 3;
    } else {
      source = match.VAL.source;
      exit = 1;
    }
  } else if (variant === "noDiff") {
    var match$1 = diff.VAL;
    var variant$2 = match$1.NAME;
    if (variant$2 === "pseudocode") {
      source$1 = match$1.VAL.source;
      exit = 2;
    } else if (variant$2 === "invalid") {
      source$2 = match$1.VAL.source;
      exit = 3;
    } else {
      source = match$1.VAL.source;
      exit = 1;
    }
  } else {
    var match$2 = diff.VAL[1];
    var variant$3 = match$2.NAME;
    if (variant$3 === "pseudocode") {
      source$1 = match$2.VAL.source;
      exit = 2;
    } else if (variant$3 === "invalid") {
      source$2 = match$2.VAL.source;
      exit = 3;
    } else {
      source = match$2.VAL.source;
      exit = 1;
    }
  }
  switch (exit) {
    case 1 :
        return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light04,
                        children: "Track with Avo Codegen: " + (AvoConfig.getSourceName(source) + " - " + SnippetUtils.sourceLangToString(source.language))
                      }), includeInCodegenTooltip);
    case 2 :
        return React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light04,
                    children: AvoConfig.getSourceName(source$1) + " - Pseudocode"
                  });
    case 3 :
        return React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light04,
                    children: AvoConfig.getSourceName(source$2)
                  });
    
  }
}

var SingleSource = {
  make: EventImplementationSnippet$SingleSource
};

function EventImplementationSnippet$OptionalCollapser(Props) {
  var lines = Props.lines;
  var enabled = Props.enabled;
  var children = Props.children;
  var onToggled = Props.onToggled;
  var collapsed = Props.collapsed;
  var onCollapse = Props.onCollapse;
  var timeout = React.useRef(undefined);
  var initialState = Belt_Option.getWithDefault(collapsed, true);
  var match = React.useReducer((function (state, action) {
          if (action) {
            return {
                    collapsed: state.collapsed,
                    buttonText: state.collapsed ? "View all " + lines.toString() + " lines" : "Collapse",
                    icon: state.collapsed ? "arrowDown" : "arrowTop",
                    isAnimating: false
                  };
          } else {
            return {
                    collapsed: !state.collapsed,
                    buttonText: state.buttonText,
                    icon: state.icon,
                    isAnimating: true
                  };
          }
        }), {
        collapsed: initialState,
        buttonText: initialState ? "View all " + lines.toString() + " lines" : "Collapse",
        icon: initialState ? "arrowDown" : "arrowTop",
        isAnimating: false
      });
  var send = match[1];
  var state = match[0];
  Hooks.useDidUpdate1((function (param) {
          Curry._1(onToggled, state.collapsed);
          timeout.current = Caml_option.some(setTimeout((function (param) {
                      return Curry._1(send, /* SwitchVisibility */1);
                    }), 500));
          return (function (param) {
                    return Belt_Option.forEach(timeout.current, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [state.collapsed]);
  var id = React.useMemo((function () {
          return Shortid();
        }), []);
  if (lines > 16 && enabled) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.borderBottomLeftRadius(Css.px(10)),
                      tl: {
                        hd: Css.borderBottomRightRadius(Css.px(10)),
                        tl: {
                          hd: Css.overflow("hidden"),
                          tl: {
                            hd: Css.position("relative"),
                            tl: {
                              hd: Css.selector(" > div[class$='expand-button']", {
                                    hd: Css.transitions({
                                          hd: Css_Legacy_Core.Transition.shorthand(state.collapsed ? ({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$short
                                                  }) : ({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$long
                                                  }), {
                                                NAME: "ms",
                                                VAL: state.collapsed ? 0.0 : 750.0
                                              }, undefined, "opacity"),
                                          tl: {
                                            hd: Css_Legacy_Core.Transition.shorthand({
                                                  NAME: "ms",
                                                  VAL: Styles.Duration.$$short
                                                }, undefined, undefined, "bottom"),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Icon.color(Styles.Color.light07),
                                      tl: {
                                        hd: Css.opacity(state.collapsed || state.isAnimating ? 1.0 : 0.0),
                                        tl: {
                                          hd: Css.bottom(state.isAnimating ? Css.px(-30) : Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.selector(" > div[class$='expand-button']", {
                                            hd: Css.transitions({
                                                  hd: Css_Legacy_Core.Transition.shorthand({
                                                        NAME: "ms",
                                                        VAL: state.collapsed ? Styles.Duration.$$short : Styles.Duration.$$long
                                                      }, undefined, undefined, "opacity"),
                                                  tl: {
                                                    hd: Css_Legacy_Core.Transition.shorthand({
                                                          NAME: "ms",
                                                          VAL: Styles.Duration.$$short
                                                        }, undefined, undefined, "bottom"),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: {
                                              hd: Css.opacity(1.0),
                                              tl: {
                                                hd: Icon.color(Styles.Color.light07),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    })
              }, React.createElement(Collapsible.make, {
                    id: id,
                    collapsed: state.collapsed,
                    collapsedHeight: 210,
                    duration: "default",
                    faderColor: Styles.Color.light12,
                    transition: "spring",
                    children: children
                  }), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.label("expand-button"),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css.width(Css.pct(100.0)),
                                tl: {
                                  hd: Css.position("absolute"),
                                  tl: {
                                    hd: Css.selector(" button", {
                                          hd: Css.transition({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$short
                                              }, undefined, undefined, "all"),
                                          tl: {
                                            hd: Css.paddingTop(Css.px(3)),
                                            tl: {
                                              hd: Css.paddingBottom(Css.px(3)),
                                              tl: {
                                                hd: Css.borderRadius(Css.px(24)),
                                                tl: {
                                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light11),
                                                  tl: {
                                                    hd: Css.backgroundColor(Styles.Color.light12),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.light08),
                                                      tl: {
                                                        hd: Css.selector(":hover:not([disabled])", {
                                                              hd: Css.borderColor(Styles.Color.light10),
                                                              tl: {
                                                                hd: Css.color(Styles.Color.light02),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Button.make, {
                        icon: state.icon,
                        label: state.buttonText,
                        onClick: (function (param) {
                            return Belt_Option.mapWithDefault(onCollapse, Curry._1(send, /* Toggle */0), (function (onCollapse) {
                                          return Curry._1(onCollapse, !state.collapsed);
                                        }));
                          }),
                        style: "ghost"
                      })));
  } else {
    return children;
  }
}

var OptionalCollapser = {
  make: EventImplementationSnippet$OptionalCollapser
};

function EventImplementationSnippet$SnippetWithCopyButton(Props) {
  var footer = Props.footer;
  var copyString = Props.copyString;
  var children = Props.children;
  var onCopied = Props.onCopied;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.selector(" .snippet-copy-button", {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$default
                                }, {
                                  NAME: "ms",
                                  VAL: 790.0
                                }, undefined, "opacity"),
                            tl: {
                              hd: Css.selector(" button", {
                                    hd: Css.backgroundColor(Styles.Color.light11),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.selector(" button", {
                                            hd: Css.backgroundColor(Styles.Color.light10),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: {
                        hd: Css.hover({
                              hd: Css.selector(" .snippet-copy-button", {
                                    hd: Css.opacity(1.0),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$default
                                          }, {
                                            NAME: "ms",
                                            VAL: 105.0
                                          }, undefined, "opacity"),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  })
            }, children, copyString === "" ? null : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.opacity(0.0),
                            tl: {
                              hd: Css.right(Css.px(8)),
                              tl: {
                                hd: Css.top(Css.px(10)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }) + " snippet-copy-button"
                  }, React.createElement(CopyButton.make, {
                        copyString: copyString,
                        icon: "copy",
                        onClick: onCopied
                      })), footer !== undefined ? Caml_option.valFromOption(footer) : null);
}

var SnippetWithCopyButton = {
  make: EventImplementationSnippet$SnippetWithCopyButton
};

function EventImplementationSnippet$CodegenError(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: warningContainer,
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.marginBottom(Css.px(16)),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("flexStart"),
                          tl: {
                            hd: Css.selector(" p", {
                                  hd: Css.important(Css.wordBreak("normal")),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginTop(Css.px(2)),
                            tl: /* [] */0
                          })
                    }, React.createElement(Icon.make, {
                          type_: "warning",
                          color: Styles.Color.orange
                        })), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      children: null
                    }, "Couldn't generate code for this source. Please ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              return Intercom.showNewMessage("");
                            }),
                          style: "Magenta",
                          children: "contact us"
                        }), " if the issue persists.")));
}

var CodegenError = {
  make: EventImplementationSnippet$CodegenError
};

function EventImplementationSnippet$FinishSettingUpSource(Props) {
  var sourceId = Props.sourceId;
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: warningContainer,
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.marginBottom(Css.px(16)),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.selector(" p", {
                                  hd: Css.important(Css.wordBreak("normal")),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(Icon.make, {
                      type_: "warning",
                      color: Styles.Color.orange
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      children: null
                    }, "Can't generate code. ", React.createElement(Link.make, {
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "source",
                                VAL: [
                                  sourceId,
                                  "codegenSetup"
                                ]
                              }),
                          className: Curry._1(Css.style, {
                                hd: Css.display("inlineBlock"),
                                tl: {
                                  hd: Css.selector(" p", {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$short
                                            }, undefined, undefined, "color"),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.selector(" p", {
                                                hd: Css.color(Styles.Color.avoPink),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          children: React.createElement($$Text.make, {
                                weight: "Semi",
                                color: Styles.Color.pink,
                                children: "Finish setting up source"
                              })
                        }), " to enable codegen.")));
}

var FinishSettingUpSource = {
  make: EventImplementationSnippet$FinishSettingUpSource
};

function EventImplementationSnippet$NoCodegenForFlutter(Props) {
  var snippet = Props.snippet;
  var withDiffHighlightingOpt = Props.withDiffHighlighting;
  var withDiffHighlighting = withDiffHighlightingOpt !== undefined ? withDiffHighlightingOpt : false;
  var tmp;
  if (typeof snippet === "object" && snippet.NAME === "invalid") {
    var code = snippet.VAL.snippet;
    tmp = code !== undefined ? React.createElement("div", {
            className: Curry._1(Css.merge, {
                  hd: prismContainer,
                  tl: {
                    hd: Curry._1(Css.style, {
                          hd: Css.selector(" pre, code", {
                                hd: Css.lineHeight({
                                      NAME: "em",
                                      VAL: 1.4
                                    }),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                })
          }, React.createElement(Prism.make, {
                snippet: code,
                language: SnippetUtils.getHighlightLang(withDiffHighlighting, snippet),
                className: prism
              })) : null;
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: warningContainer
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Icon.make, {
                          type_: "warning",
                          color: Styles.Color.orange
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          children: "Codegen is not supported for Flutter yet"
                        }))), tmp);
}

var NoCodegenForFlutter = {
  make: EventImplementationSnippet$NoCodegenForFlutter
};

function prismWrapperStyles(diffViewType) {
  return Curry._1(Css.merge, {
              hd: prismContainer,
              tl: {
                hd: diffViewType !== "unified" ? Curry._1(Css.style, {
                        hd: Css.selector(" code", {
                              hd: Css.display("block"),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }) : "",
                tl: /* [] */0
              }
            });
}

function EventImplementationSnippet$Snippet(Props) {
  var collapsed = Props.collapsed;
  var enableCollapse = Props.enableCollapse;
  var onCollapse = Props.onCollapse;
  var snippet = Props.snippet;
  var withDiffHighlightingOpt = Props.withDiffHighlighting;
  var diffViewType = Props.diffViewType;
  var onCopied = Props.onCopied;
  var onToggled = Props.onToggled;
  var withDiffHighlighting = withDiffHighlightingOpt !== undefined ? withDiffHighlightingOpt : false;
  if (snippet.NAME === "invalid") {
    var match = snippet.VAL;
    var validity = match.validity;
    if (validity === "error") {
      return React.createElement(EventImplementationSnippet$CodegenError, {});
    } else if (validity === "finishSettingUpSource") {
      return React.createElement(EventImplementationSnippet$FinishSettingUpSource, {
                  sourceId: match.source.id
                });
    } else {
      return React.createElement(EventImplementationSnippet$NoCodegenForFlutter, {
                  snippet: snippet,
                  withDiffHighlighting: withDiffHighlighting
                });
    }
  }
  var code = snippet.VAL.snippet;
  var lines = code.split("\n").length;
  var tmp = {
    lines: lines,
    enabled: enableCollapse,
    children: React.createElement("div", {
          className: prismWrapperStyles(diffViewType)
        }, React.createElement(Prism.make, {
              snippet: code,
              language: SnippetUtils.getHighlightLang(withDiffHighlighting, snippet),
              className: prism
            })),
    onToggled: onToggled
  };
  if (collapsed !== undefined) {
    tmp.collapsed = Caml_option.valFromOption(collapsed);
  }
  if (onCollapse !== undefined) {
    tmp.onCollapse = Caml_option.valFromOption(onCollapse);
  }
  return React.createElement(EventImplementationSnippet$SnippetWithCopyButton, {
              copyString: code,
              children: React.createElement(EventImplementationSnippet$OptionalCollapser, tmp),
              onCopied: onCopied
            });
}

var Snippet = {
  prismWrapperStyles: prismWrapperStyles,
  make: EventImplementationSnippet$Snippet
};

function EventImplementationSnippet$SplitSnippet(Props) {
  var collapsed = Props.collapsed;
  var copyString = Props.copyString;
  var oldSnippet = Props.oldSnippet;
  var newSnippet = Props.newSnippet;
  var onCollapse = Props.onCollapse;
  var onCopied = Props.onCopied;
  var onToggled = Props.onToggled;
  var enableCollapse = Props.enableCollapse;
  var match = SnippetUtils.usePaneScrollSync(undefined);
  var oldLines = (
      oldSnippet.NAME === "invalid" ? Belt_Option.getWithDefault(oldSnippet.VAL.snippet, "") : oldSnippet.VAL.snippet
    ).split("\n").length;
  var newLines = (
      newSnippet.NAME === "invalid" ? Belt_Option.getWithDefault(newSnippet.VAL.snippet, "") : newSnippet.VAL.snippet
    ).split("\n").length;
  var rightStyle = Curry._1(Css.style, {
        hd: Css.width(Css.pct(50.0)),
        tl: {
          hd: Css.overflow("auto"),
          tl: /* [] */0
        }
      });
  var leftStyle = Curry._1(Css.merge, {
        hd: rightStyle,
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light11),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      });
  var tmp;
  if (oldSnippet.NAME === "invalid") {
    var match$1 = oldSnippet.VAL;
    var validity = match$1.validity;
    tmp = React.createElement("div", {
          className: "code-snippet"
        }, validity === "error" ? React.createElement(EventImplementationSnippet$CodegenError, {}) : (
            validity === "finishSettingUpSource" ? React.createElement(EventImplementationSnippet$FinishSettingUpSource, {
                    sourceId: match$1.source.id
                  }) : React.createElement(EventImplementationSnippet$NoCodegenForFlutter, {
                    snippet: oldSnippet,
                    withDiffHighlighting: true
                  })
          ));
  } else {
    tmp = React.createElement("div", {
          className: prismContainer
        }, React.createElement(Prism.make, {
              snippet: oldSnippet.VAL.snippet,
              language: SnippetUtils.getHighlightLang(true, oldSnippet),
              className: prism
            }));
  }
  var tmp$1;
  if (newSnippet.NAME === "invalid") {
    var match$2 = newSnippet.VAL;
    var validity$1 = match$2.validity;
    tmp$1 = React.createElement("div", {
          className: "code-snippet"
        }, validity$1 === "error" ? React.createElement(EventImplementationSnippet$CodegenError, {}) : (
            validity$1 === "finishSettingUpSource" ? React.createElement(EventImplementationSnippet$FinishSettingUpSource, {
                    sourceId: match$2.source.id
                  }) : React.createElement(EventImplementationSnippet$NoCodegenForFlutter, {
                    snippet: newSnippet,
                    withDiffHighlighting: true
                  })
          ));
  } else {
    tmp$1 = React.createElement("div", {
          className: prismContainer
        }, React.createElement(Prism.make, {
              snippet: newSnippet.VAL.snippet,
              language: SnippetUtils.getHighlightLang(true, newSnippet),
              className: prism
            }));
  }
  var tmp$2 = {
    lines: oldLines > newLines ? oldLines : newLines,
    enabled: enableCollapse,
    children: React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.selector(" .token.prefix", {
                        hd: Css.marginRight(Css.px(7)),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              })
        }, React.createElement("div", {
              ref: match[0],
              className: leftStyle
            }, React.createElement("div", undefined, tmp)), React.createElement("div", {
              ref: match[1],
              className: rightStyle
            }, tmp$1)),
    onToggled: onToggled
  };
  if (collapsed !== undefined) {
    tmp$2.collapsed = Caml_option.valFromOption(collapsed);
  }
  if (onCollapse !== undefined) {
    tmp$2.onCollapse = Caml_option.valFromOption(onCollapse);
  }
  return React.createElement(EventImplementationSnippet$SnippetWithCopyButton, {
              copyString: copyString,
              children: React.createElement(EventImplementationSnippet$OptionalCollapser, tmp$2),
              onCopied: onCopied
            });
}

var SplitSnippet = {
  make: EventImplementationSnippet$SplitSnippet
};

var snippetContainer = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light12),
        tl: {
          hd: Css.borderRadius(Css.px(10)),
          tl: {
            hd: Css.overflow("hidden"),
            tl: /* [] */0
          }
        }
      }
    });

var buttonsContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.gap(Css.px(4)),
        tl: {
          hd: Css.selector(" button", {
                hd: Css.backgroundColor(Styles.Color.light11),
                tl: {
                  hd: Css.selector(":hover:not([disabled])", {
                        hd: Css.backgroundColor(Styles.Color.light10),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingLeft(Css.px(20)),
            tl: {
              hd: Css.paddingRight(Css.px(8)),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingBottom(Css.px(8)),
                  tl: {
                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light11),
                    tl: {
                      hd: Css.minHeight(Css.px(45)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Style$1 = {
  snippetContainer: snippetContainer,
  buttonsContainer: buttonsContainer,
  header: header
};

function EventImplementationSnippet$Simple(Props) {
  var title = Props.title;
  var footerOpt = Props.footer;
  var diff = Props.diff;
  var snippetLink = Props.snippetLink;
  var enableCollapse = Props.enableCollapse;
  var diffViewType = Props.diffViewType;
  var eventDiffState = Props.eventDiffState;
  var onLinkCopied = Props.onLinkCopied;
  var onCodeCopied = Props.onCodeCopied;
  var onToggleCollapse = Props.onToggleCollapse;
  var onCodeManuallyCopied = Props.onCodeManuallyCopied;
  var forwardRef = Props.forwardRef;
  var footer = footerOpt !== undefined ? Caml_option.valFromOption(footerOpt) : null;
  var copyRef = React.useRef(null);
  var onPressHandler = function (param) {
    var el = $$Window.$$Selection.get(undefined).focusNode;
    var hasCodeSelected;
    if (el !== undefined) {
      var el$1 = Caml_option.valFromOption(el);
      hasCodeSelected = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(copyRef.current), false, (function (copyEl) {
              return copyEl.contains(el$1);
            }));
    } else {
      hasCodeSelected = false;
    }
    if (hasCodeSelected) {
      return Curry._1(onCodeManuallyCopied, undefined);
    }
    
  };
  var match = React.useState(function () {
        return true;
      });
  var setCollapsed = match[1];
  var collapsed = match[0];
  var tmp = {
    className: snippetContainer
  };
  var tmp$1 = Belt_Option.map(forwardRef, (function (prim) {
          return prim;
        }));
  if (tmp$1 !== undefined) {
    tmp.ref = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2;
  var exit = 0;
  var snippet;
  var variant = diff.NAME;
  if (variant === "unifiedDiff") {
    snippet = diff.VAL[0];
    exit = 1;
  } else if (variant === "noDiff") {
    snippet = diff.VAL;
    exit = 1;
  } else {
    var match$1 = diff.VAL;
    tmp$2 = React.createElement(EventImplementationSnippet$SplitSnippet, {
          collapsed: collapsed,
          copyString: match$1[2],
          oldSnippet: match$1[0],
          newSnippet: match$1[1],
          onCollapse: (function (collapsed) {
              return Curry._1(setCollapsed, (function (param) {
                            return collapsed;
                          }));
            }),
          onCopied: onCodeCopied,
          onToggled: onToggleCollapse,
          enableCollapse: enableCollapse
        });
  }
  if (exit === 1) {
    tmp$2 = React.createElement(EventImplementationSnippet$Snippet, {
          collapsed: collapsed,
          enableCollapse: enableCollapse,
          onCollapse: (function (collapsed) {
              return Curry._1(setCollapsed, (function (param) {
                            return collapsed;
                          }));
            }),
          snippet: snippet,
          withDiffHighlighting: diffViewType !== "noDiff" || eventDiffState === "deleted",
          diffViewType: diffViewType,
          onCopied: onCodeCopied,
          onToggled: onToggleCollapse
        });
  }
  return React.createElement("section", tmp, React.createElement(KeyListener.WithOperatingSystemModKey.make, {
                  keyName: "c",
                  onPress: onPressHandler
                }), React.createElement("header", {
                  className: header
                }, title, React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement("span", {
                      className: buttonsContainer
                    }, React.createElement(CopyButton.Icon.make, {
                          copyString: snippetLink,
                          color: Styles.Color.light02,
                          icon: "link",
                          onClick: onLinkCopied
                        }))), React.createElement("div", {
                  ref: copyRef
                }, tmp$2), footer);
}

var Simple = {
  Style: Style$1,
  make: EventImplementationSnippet$Simple
};

function EventImplementationSnippet$Footer(Props) {
  var branchName = Props.branchName;
  var onCliLinkClicked = Props.onCliLinkClicked;
  var onCopyClick = Props.onCopyClick;
  var onTooltipDismiss = Props.onTooltipDismiss;
  var onTooltipDisplay = Props.onTooltipDisplay;
  var onTooltipInteraction = Props.onTooltipInteraction;
  var sourceName = Props.sourceName;
  var pullString$1 = "avo pull --branch " + branchName + " \"" + sourceName + "\"";
  var tooltipTitle = "Get the Avo file";
  return React.createElement("footer", {
              className: footerContainer
            }, React.createElement(Tooltip.make, {
                  onDismiss: (function (param) {
                      return Curry._1(onTooltipDismiss, tooltipTitle);
                    }),
                  onDisplay: (function (param) {
                      return Curry._1(onTooltipDisplay, tooltipTitle);
                    }),
                  onInteraction: (function (interactionType) {
                      return Curry._2(onTooltipInteraction, tooltipTitle, interactionType);
                    }),
                  size: "small",
                  title: tooltipTitle,
                  children: null
                }, React.createElement(Tooltip.Target.make, {
                      children: React.createElement("div", {
                            className: codegenDocsInfo
                          }, React.createElement($$Text.make, {
                                element: "Span",
                                size: "Small",
                                color: Styles.Color.light08,
                                children: null
                              }, "Codegen using ", React.createElement("a", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("inlineFlex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.color(Styles.Color.purple),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.textDecoration("none"),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.small),
                                                    tl: {
                                                      hd: Css.marginTop(Css.px(2)),
                                                      tl: {
                                                        hd: Css.selector(" path", {
                                                              hd: Css_Legacy_Core.SVG.fill(Styles.Color.purple),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.color(Styles.Color.palePurple),
                                                                tl: {
                                                                  hd: Css.selector(" path", {
                                                                        hd: Css_Legacy_Core.SVG.fill(Styles.Color.palePurple),
                                                                        tl: /* [] */0
                                                                      }),
                                                                  tl: /* [] */0
                                                                }
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    href: "https://www.avo.app/docs/implementation/cli",
                                    target: "_blank",
                                    onClick: onCliLinkClicked
                                  }, "Avo CLI", React.createElement("svg", {
                                        height: "15",
                                        width: "11",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }, React.createElement("path", {
                                            d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                                          })))))
                    }), React.createElement(Tooltip.Content.make, {
                      children: React.createElement(Markdown.make, {
                            source: Tooltips.getTheAvoFile
                          })
                    })), React.createElement("div", {
                  className: pullString
                }, "$ " + pullString$1), React.createElement(Spacer.make, {
                  width: 0,
                  grow: 1.0
                }), React.createElement(CopyButton.Icon.make, {
                  copyString: pullString$1,
                  color: Styles.Color.light04,
                  onClick: onCopyClick
                }), React.createElement(Spacer.make, {
                  width: 6
                }));
}

var Footer = {
  make: EventImplementationSnippet$Footer
};

function EventImplementationSnippet$Internal(Props) {
  var branchStatus = Props.branchStatus;
  var eventDiffState = Props.eventDiffState;
  var enrichedFromModel = Props.enrichedFromModel;
  var enrichedToModel = Props.enrichedToModel;
  var $$event = Props.event;
  var fromModel = Props.fromModel;
  var toModel = Props.toModel;
  var pageName = Props.pageName;
  var viewType = Props.viewType;
  var diffViewTypeOpt = Props.diffViewType;
  var controlledSource = Props.source;
  var onSourceChange = Props.onSourceChange;
  var forwardRef = Props.forwardRef;
  var diffViewType = diffViewTypeOpt !== undefined ? diffViewTypeOpt : "unified";
  var schemaBundle = SchemaBundleContext.use(undefined);
  var serverTrackingWithDeviceId = AppFeatureFlag.useFeatureFlag("ServerTrackingWithDeviceId");
  var eventSpecificPropertyValues = AppFeatureFlag.useFeatureFlag("EventSpecificPropertyValuesInCode");
  var match = SnippetUtils.QueryParams.useSourceId(undefined);
  var preSelectedSourceId = match.value;
  var match$1 = React.useState(function () {
        var sourceId;
        var exit = 0;
        var exit$1 = 0;
        if (controlledSource !== undefined) {
          return controlledSource;
        }
        if (viewType && pageName === "BranchImplementation") {
          sourceId = viewType._0;
        } else {
          exit$1 = 3;
        }
        if (exit$1 === 3) {
          if (preSelectedSourceId !== undefined && pageName === "Event") {
            sourceId = preSelectedSourceId;
          } else {
            exit = 2;
          }
        }
        if (exit === 2) {
          if (viewType) {
            sourceId = viewType._0;
          } else {
            var source = SnippetUtils.getFirstSource($$event, toModel);
            if (source !== undefined) {
              return source;
            }
            throw {
                  RE_EXN_ID: SourceNotFoundException,
                  _1: "No source found",
                  Error: new Error()
                };
          }
        }
        var source$1 = ModelUtils.getSourceById(sourceId, toModel);
        if (source$1 !== undefined) {
          return source$1;
        }
        throw {
              RE_EXN_ID: SourceNotFoundException,
              _1: "Source with id " + sourceId + " not found",
              Error: new Error()
            };
      });
  var setSelectedSource = match$1[1];
  var selectedSource = match$1[0];
  React.useEffect((function () {
          Belt_Option.forEach(onSourceChange, (function (cb) {
                  return Curry._1(cb, selectedSource);
                }));
          
        }), []);
  SnippetUtils.useSnippetOpenedExternallyListener(pageName, $$event.id, $$event.name, schemaBundle);
  var existingSources = Belt_List.keepMap($$event.includeSources, (function (param) {
          return ModelUtils.getSourceById(param.id, toModel);
        }));
  var diff = React.useMemo((function () {
          if (fromModel !== undefined) {
            return SnippetUtils.generateSnippetDiffWrapper(diffViewType, enrichedFromModel, enrichedToModel, eventDiffState, fromModel, serverTrackingWithDeviceId, eventSpecificPropertyValues, selectedSource, toModel, $$event);
          } else {
            return {
                    NAME: "noDiff",
                    VAL: SnippetUtils.getSnippet(enrichedToModel, $$event, toModel, serverTrackingWithDeviceId, eventSpecificPropertyValues, selectedSource)
                  };
          }
        }), [
        fromModel,
        toModel,
        selectedSource,
        diffViewType
      ]);
  var handleCodeCopy = function (param) {
    return AnalyticsRe.eventImplementationSnippetInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.id, $$event.name, "CodeSnippetCopied", pageName, "Authenticated", "Workspace", schemaBundle.branchId, schemaBundle.schemaId);
  };
  var handleToggleCollapse = function (becameExpanded) {
    return AnalyticsRe.eventImplementationSnippetInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.id, $$event.name, becameExpanded ? "SnippetCollapsed" : "SnippetExpanded", pageName, "Authenticated", "Workspace", schemaBundle.branchId, schemaBundle.schemaId);
  };
  var handleLinkCopied = function (param) {
    return AnalyticsRe.eventImplementationSnippetInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.id, $$event.name, "LinkToSnippetCopied", pageName, "Authenticated", "Workspace", schemaBundle.branchId, schemaBundle.schemaId);
  };
  var handleCliLinkClicked = function (param) {
    return AnalyticsRe.eventImplementationSnippetInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.id, $$event.name, "AvoCLIDocsLinkClicked", pageName, "Authenticated", "Workspace", schemaBundle.branchId, schemaBundle.schemaId);
  };
  var handleCodeManuallyCopied = function (param) {
    return AnalyticsRe.eventImplementationSnippetInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.id, $$event.name, "CodeSnippetCopiedManually", pageName, "Authenticated", "Workspace", schemaBundle.branchId, schemaBundle.schemaId);
  };
  var handlePullStringCopied = function (param) {
    return AnalyticsRe.eventImplementationSnippetInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.id, $$event.name, "PullSnippetCopied", pageName, "Authenticated", "Workspace", schemaBundle.branchId, schemaBundle.schemaId);
  };
  var onPullstringTooltipDisplay = function (title) {
    return AnalyticsRe.tooltipDisplayed(undefined, undefined, "BranchImplementation", schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, title, "Documentation", "OnHover", "Workspace", "Authenticated", schemaBundle.schemaId);
  };
  var onPullstringTooltipDismiss = function (title) {
    return AnalyticsRe.tooltipInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, undefined, undefined, "BranchImplementation", title, "Documentation", "OnHover", "Dismiss", "Workspace", "Authenticated", schemaBundle.schemaId);
  };
  var onPullstringTooltipInteraction = function (title, tooltipInteractionType) {
    return AnalyticsRe.tooltipInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, undefined, undefined, "BranchImplementation", title, "Documentation", "OnHover", tooltipInteractionType, "Workspace", "Authenticated", schemaBundle.schemaId);
  };
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (typeof branchStatus === "number") {
    exit$1 = 2;
  } else {
    switch (branchStatus.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          tmp = null;
          break;
      default:
        exit$1 = 2;
    }
  }
  if (exit$1 === 2) {
    var variant = diff.NAME;
    if (variant === "unifiedDiff") {
      var match$2 = diff.VAL[0];
      if (typeof match$2 === "object" && match$2.NAME === "codegen") {
        exit = 1;
      } else {
        tmp = null;
      }
    } else if (variant === "noDiff") {
      var match$3 = diff.VAL;
      if (typeof match$3 === "object" && match$3.NAME === "codegen") {
        exit = 1;
      } else {
        tmp = null;
      }
    } else {
      var match$4 = diff.VAL;
      var match$5 = match$4[0];
      var exit$2 = 0;
      if (typeof match$5 === "object" && match$5.NAME === "codegen") {
        exit = 1;
      } else {
        exit$2 = 3;
      }
      if (exit$2 === 3) {
        var match$6 = match$4[1];
        if (typeof match$6 === "object" && match$6.NAME === "codegen") {
          exit = 1;
        } else {
          tmp = null;
        }
      }
      
    }
  }
  if (exit === 1) {
    tmp = React.createElement(EventImplementationSnippet$Footer, {
          branchName: schemaBundle.branchName,
          onCliLinkClicked: handleCliLinkClicked,
          onCopyClick: handlePullStringCopied,
          onTooltipDismiss: onPullstringTooltipDismiss,
          onTooltipDisplay: onPullstringTooltipDisplay,
          onTooltipInteraction: onPullstringTooltipInteraction,
          sourceName: AvoConfig.getSourceName(selectedSource)
        });
  }
  var tmp$1 = {
    title: viewType ? React.createElement(EventImplementationSnippet$SingleSource, {
            diff: diff
          }) : (
        Belt_List.length(existingSources) === 1 ? React.createElement(EventImplementationSnippet$SingleSource, {
                diff: diff
              }) : React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css.marginLeft(Css.px(-10)),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement(ResizingSelect.make, {
                    options: Belt_List.toArray(Belt_List.map(Belt_List.map(Belt_List.reverse(existingSources), (function (source) {
                                    return SnippetUtils.getSnippetTypeWithSource($$event, source, toModel);
                                  })), (function (snippetTypeWithSource) {
                                var variant = snippetTypeWithSource.NAME;
                                if (variant === "pseudocode") {
                                  var source = snippetTypeWithSource.VAL;
                                  return [
                                          {
                                            NAME: "Label",
                                            VAL: AvoConfig.getSourceName(source) + " - Pseudocode"
                                          },
                                          source.id
                                        ];
                                }
                                if (variant === "invalid") {
                                  var source$1 = snippetTypeWithSource.VAL[1];
                                  return [
                                          {
                                            NAME: "Label",
                                            VAL: AvoConfig.getSourceName(source$1) + " "
                                          },
                                          source$1.id
                                        ];
                                }
                                var source$2 = snippetTypeWithSource.VAL[0];
                                return [
                                        {
                                          NAME: "Label",
                                          VAL: "Track with Avo Codegen: " + (AvoConfig.getSourceName(source$2) + " - " + SnippetUtils.sourceLangToString(source$2.language))
                                        },
                                        source$2.id
                                      ];
                              }))),
                    value: selectedSource.id,
                    size: "small",
                    style: "dark",
                    maxWidth: 470,
                    onSelect: (function (sourceId) {
                        Curry._1(setSelectedSource, (function (param) {
                                var source = ModelUtils.getSourceById(sourceId, toModel);
                                if (source !== undefined) {
                                  Belt_Option.forEach(onSourceChange, (function (cb) {
                                          return Curry._1(cb, source);
                                        }));
                                  return source;
                                }
                                throw {
                                      RE_EXN_ID: SourceNotFoundException,
                                      _1: "Source with id " + sourceId + " not found",
                                      Error: new Error()
                                    };
                              }));
                        return AnalyticsRe.eventImplementationSnippetInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, undefined, undefined, "SourceSwitched", pageName, "Authenticated", "Workspace", schemaBundle.branchId, schemaBundle.schemaId);
                      })
                  }), includeInCodegenTooltip)
      ),
    footer: tmp,
    diff: diff,
    snippetLink: SnippetUtils.getSnippetLink($$event.id, selectedSource.id, pageName),
    enableCollapse: pageName === "BranchImplementation",
    diffViewType: diffViewType,
    eventDiffState: eventDiffState,
    onLinkCopied: handleLinkCopied,
    onCodeCopied: handleCodeCopy,
    onToggleCollapse: handleToggleCollapse,
    onCodeManuallyCopied: handleCodeManuallyCopied
  };
  if (forwardRef !== undefined) {
    tmp$1.forwardRef = Caml_option.valFromOption(forwardRef);
  }
  return React.createElement(EventImplementationSnippet$Simple, tmp$1);
}

var Internal = {
  make: EventImplementationSnippet$Internal
};

function EventImplementationSnippet(Props) {
  var branchStatus = Props.branchStatus;
  var eventDiffState = Props.eventDiffState;
  var enrichedFromModel = Props.enrichedFromModel;
  var enrichedToModel = Props.enrichedToModel;
  var $$event = Props.event;
  var fromModel = Props.fromModel;
  var toModel = Props.toModel;
  var pageName = Props.pageName;
  var viewType = Props.viewType;
  var diffViewType = Props.diffViewType;
  var source = Props.source;
  var onSourceChange = Props.onSourceChange;
  var forwardRef = Props.forwardRef;
  var tmp = {
    branchStatus: branchStatus,
    eventDiffState: eventDiffState,
    event: $$event,
    toModel: toModel,
    pageName: pageName,
    viewType: viewType,
    diffViewType: diffViewType
  };
  if (enrichedFromModel !== undefined) {
    tmp.enrichedFromModel = Caml_option.valFromOption(enrichedFromModel);
  }
  if (enrichedToModel !== undefined) {
    tmp.enrichedToModel = Caml_option.valFromOption(enrichedToModel);
  }
  if (fromModel !== undefined) {
    tmp.fromModel = Caml_option.valFromOption(fromModel);
  }
  if (source !== undefined) {
    tmp.source = Caml_option.valFromOption(source);
  }
  if (onSourceChange !== undefined) {
    tmp.onSourceChange = Caml_option.valFromOption(onSourceChange);
  }
  if (forwardRef !== undefined) {
    tmp.forwardRef = Caml_option.valFromOption(forwardRef);
  }
  return React.createElement(RescriptReactErrorBoundary.make, {
              children: React.createElement(EventImplementationSnippet$Internal, tmp),
              fallback: (function (_params) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.backgroundColor(Styles.Color.light12),
                                    tl: {
                                      hd: Css.borderRadius(Css.px(10)),
                                      tl: {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement(EventImplementationSnippet$CodegenError, {}));
                })
            });
}

var make = EventImplementationSnippet;

export {
  SourceNotFoundException ,
  Style ,
  includeInCodegenTooltip ,
  SingleSource ,
  OptionalCollapser ,
  SnippetWithCopyButton ,
  CodegenError ,
  FinishSettingUpSource ,
  NoCodegenForFlutter ,
  Snippet ,
  SplitSnippet ,
  Simple ,
  Footer ,
  Internal ,
  make ,
  
}
/* warningContainer Not a pure module */
