// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as TextButton from "./TextButton.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DrawerUtils from "./DrawerUtils.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as CodegenCommons from "../../codegen/src/CodegenCommons.mjs";
import * as ImplementEvent from "./ImplementEvent.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as MembersInviteForm from "./MembersInviteForm.mjs";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.mjs";
import * as ImplementationStatusContext from "./ImplementationStatusContext.mjs";
import * as FetchImplementationStatusUtils from "../../shared/utils/FetchImplementationStatusUtils.mjs";

function CodegenSourceDetails$InviteDev(Props) {
  var source = Props.source;
  var model = Props.model;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.light02),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.light06),
                          tl: {
                            hd: Css.padding2(Css.px(12), Css.px(12)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginBottom(Css.px(8)),
                        tl: /* [] */0
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Send these instructions to a developer"
                    })), React.createElement(MembersInviteForm.make, {
                  redirectUrl: Router.Link.addDrawerItem(undefined, {
                        NAME: "codegenSource",
                        VAL: source.id
                      }),
                  model: model
                }));
}

var InviteDev = {
  make: CodegenSourceDetails$InviteDev
};

var setupStatusBox = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(24)),
      tl: {
        hd: Css.marginLeft(Css.px(12)),
        tl: /* [] */0
      }
    });

var statusLoading = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.height(Css.px(28)),
            tl: {
              hd: Css.width(Css.px(190)),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.marginLeft(Css.px(-14)),
                  tl: /* [] */0
                }
              }
            }
          }),
      tl: {
        hd: Curry._1(Css.style, Styles.shimmer),
        tl: /* [] */0
      }
    });

var initializedPill = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: {
            hd: Css.width("fitContent"),
            tl: {
              hd: Css.background(Styles.Color.paleGreen),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.paddingTop(Css.px(4)),
                  tl: {
                    hd: Css.paddingRight(Css.px(12)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(5)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var waitingPill = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.width("fitContent"),
            tl: {
              hd: Css.background(Styles.Color.light04),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.paddingTop(Css.px(4)),
                  tl: {
                    hd: Css.paddingRight(Css.px(12)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(5)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(6)),
                        tl: {
                          hd: Css.marginLeft(Css.px(-14)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Style = {
  setupStatusBox: setupStatusBox,
  statusLoading: statusLoading,
  initializedPill: initializedPill,
  waitingPill: waitingPill
};

function CodegenSourceDetails(Props) {
  var model = Props.model;
  var sourceId = Props.sourceId;
  var rawModel = Props.rawModel;
  var role = Props.role;
  var hash = React.useRef(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).hash);
  var eventSpecificPropertyValues = AppFeatureFlag.useFeatureFlag("EventSpecificPropertyValuesInCode");
  var branchBarVisible = RouterStore.useBranchBarVisible(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var match = React.useContext(ImplementationStatusContext.context).codegen;
  var source = Belt_List.getBy(model.sources, (function (source) {
          return source.id === sourceId;
        }));
  var tmp;
  if (source !== undefined) {
    var sourceEvents = Belt_List.sort(ModelUtils.getSourceEvents(true, model, sourceId), (function (a, b) {
            if (a.name > b.name) {
              return 1;
            } else if (a.name < b.name) {
              return -1;
            } else {
              return 0;
            }
          }));
    var errors = AvoConfig.validateSource(source, model);
    if (errors.TAG === /* Ok */0) {
      var validSource = errors._0;
      var match$1 = CodegenCommons.enrich(undefined, eventSpecificPropertyValues, undefined, undefined, undefined, rawModel, validSource, AvoConfig.getValidDestinations(rawModel.sources, rawModel.destinations));
      var enrichedModel = match$1[0];
      var tmp$1;
      if (AvoConfig.supportsInvocations(source)) {
        var tmp$2;
        if (match[0] === "Ready") {
          var initialized = Belt_Option.mapWithDefault(Js_dict.get(match[2], source.id), false, (function (invocations) {
                  return !Belt_MapString.isEmpty(invocations);
                }));
          tmp$2 = initialized ? React.createElement("div", {
                  className: initializedPill
                }, React.createElement(Icons.CheckmarkRounded.make, {
                      size: 12,
                      color: Styles.Color.deepGreen
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.deepGreen,
                      children: "Successfully Initialized"
                    })) : React.createElement("div", {
                  className: waitingPill
                }, React.createElement(LoadingCircle.make, {
                      size: 18,
                      color: Styles.Color.light11
                    }), React.createElement(Spacer.make, {
                      width: 6
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Listening for the first initialization…"
                    }));
        } else {
          tmp$2 = React.createElement("div", {
                className: statusLoading
              });
        }
        tmp$1 = React.createElement("div", {
              className: setupStatusBox
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light10,
                  children: "Setup Status"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, tmp$2, React.createElement(Spacer.make, {
                      height: 8,
                      grow: 1.0
                    }), React.createElement(TextButton.make, {
                      onClick: (function (param) {
                          return Router.Schema.pushDrawerItem(undefined, undefined, {
                                      NAME: "source",
                                      VAL: [
                                        source.id,
                                        "codegenSetup"
                                      ]
                                    });
                        }),
                      style: "Blue",
                      size: "Small",
                      children: "View Setup Instructions"
                    })));
      } else {
        tmp$1 = null;
      }
      tmp = React.createElement(React.Fragment, {
            children: null,
            key: source.id
          }, React.createElement(DrawerHeader.make, {
                kind: "Implementation Instructions",
                children: React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      textAlign: "left",
                      children: AvoConfig.getSourceName(source)
                    })
              }), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.padding(Css.px(15)),
                      tl: {
                        hd: Css.overflowY("scroll"),
                        tl: {
                          hd: Css.marginBottom(Css.px(15)),
                          tl: {
                            hd: Css.maxHeight({
                                  NAME: "subtract",
                                  VAL: [
                                    Css.vh(100),
                                    Css.px(DrawerUtils.getTopOffsetAndHeaderHeight(workspace, branchBarVisible, schemaRoute))
                                  ]
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                id: ImplementEvent.scrollContainerId
              }, role === "Admin" ? React.createElement(CodegenSourceDetails$InviteDev, {
                      source: source,
                      model: model
                    }) : null, tmp$1, Caml_obj.caml_notequal(source.platform, "JsonSchema") ? React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.large),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding4(Css.px(24), Css.px(12), Css.px(0), Css.px(12)),
                                tl: /* [] */0
                              })
                        }, React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.light12,
                              children: "Event documentation"
                            })), Belt_List.toArray(Belt_List.mapU(sourceEvents, (function ($$event) {
                                return React.createElement("div", {
                                            key: $$event.id,
                                            id: $$event.id
                                          }, React.createElement(ImplementationStatusContext.EventSubscription.make, {
                                                eventId: $$event.id,
                                                children: (function (invocations) {
                                                    var invocation = typeof invocations === "object" ? FetchImplementationStatusUtils.reduceInvocations(FetchImplementationStatusUtils.getInvocationsFromList(source.id, undefined, undefined, undefined, invocations.VAL)) : undefined;
                                                    return React.createElement(ImplementEvent.make, {
                                                                event: $$event,
                                                                source: source,
                                                                model: model,
                                                                enrichedModel: enrichedModel,
                                                                completed: Belt_Option.isSome(invocation),
                                                                lastSeen: Belt_Option.map(invocation, (function (invocation) {
                                                                        return new Date(invocation.lastSeen.value);
                                                                      })),
                                                                validSource: validSource,
                                                                isActive: hash.current === $$event.id,
                                                                key: $$event.id
                                                              });
                                                  })
                                              }));
                              })))) : null, role === "Admin" ? React.createElement(CodegenSourceDetails$InviteDev, {
                      source: source,
                      model: model
                    }) : null));
    } else {
      tmp = React.createElement("div", {
            key: source.id
          }, React.createElement(DrawerHeader.make, {
                kind: "Implementation Instructions",
                children: React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      textAlign: "left",
                      children: AvoConfig.getSourceName(source)
                    })
              }), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.padding(Css.px(60)),
                      tl: /* [] */0
                    })
              }, React.createElement($$Text.make, {
                    size: "Medium",
                    color: Styles.Color.light11,
                    children: null
                  }, "It looks like this source has not been fully set up: ", React.createElement($$Text.make, {
                        element: "Span",
                        weight: "Semi",
                        children: Belt_Array.map(errors._0, (function (error) {
                                  if (typeof error === "object") {
                                    return "missing api key";
                                  } else if (error === "AnalyticsPlatformMissing") {
                                    return "analytics platform hasn't been set";
                                  } else if (error === "NameMissing") {
                                    return "missing name";
                                  } else if (error === "LanguageMissing") {
                                    return "programming language hasn't been set";
                                  } else if (error === "DestinationsMissing") {
                                    return "not sending to any destinations";
                                  } else {
                                    return "development platform has't been set";
                                  }
                                })).join(", ")
                      })), React.createElement(Spacer.make, {
                    height: 12
                  }), React.createElement(Button.make, {
                    label: "Go to Source Settings",
                    onClick: (function (param) {
                        return Router.Schema.pushDrawerItem(undefined, undefined, {
                                    NAME: "source",
                                    VAL: [
                                      source.id,
                                      "overview"
                                    ]
                                  });
                      }),
                    size: "large"
                  })));
    }
  } else {
    tmp = "source not found";
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.flexGrow(1.0),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.minWidth(Css.px(0)),
                        tl: {
                          hd: Css.height(Css.pct(100.0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, tmp);
}

var make = CodegenSourceDetails;

export {
  InviteDev ,
  Style ,
  make ,
  
}
/* setupStatusBox Not a pure module */
