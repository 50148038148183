// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

function getMostSpecificRegex(propertyRegexValidation, eventId) {
  var defaultRegex = Belt_Option.flatMapU(propertyRegexValidation, (function (validation) {
          return Belt_Option.mapU(validation.propertyRule, (function (propertyRule) {
                        return propertyRule.regex;
                      }));
        }));
  var eventSpecificRegex = Belt_Option.flatMapU(propertyRegexValidation, (function (validation) {
          return Belt_MapString.get(validation.eventOverrides, eventId);
        }));
  if (eventSpecificRegex !== undefined) {
    return eventSpecificRegex.regex;
  } else {
    return defaultRegex;
  }
}

export {
  getMostSpecificRegex ,
  
}
/* No side effect */
