// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "./styles.mjs";

function IconDescription(Props) {
  var sizeOpt = Props.size;
  var fillOpt = Props.fill;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var fill = fillOpt !== undefined ? fillOpt : Styles.Color.light07;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  fill: "none",
                  stroke: Styles.Color.toString(fill),
                  strokeLinecap: "round"
                }, React.createElement("path", {
                      d: "M1 4h15M1 9h15M1 14h8"
                    })));
}

var make = IconDescription;

export {
  make ,
  
}
/* react Not a pure module */
