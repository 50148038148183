// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "./Title.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as InspectorStats from "./inspector/InspectorStats.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as IconCheckmarkCircle from "./IconCheckmarkCircle.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as SourceConfigOverview from "./SourceConfigOverview.mjs";
import * as SourceConfigCodegenSetup from "./SourceConfigCodegenSetup.mjs";
import * as SourceConfigInspectorSetup from "./SourceConfigInspectorSetup.mjs";
import * as ImplementationStatusContext from "./ImplementationStatusContext.mjs";

var root = Curry._1(Css.style, {
      hd: Css.height(Css.pct(100.0)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: /* [] */0
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
      tl: /* [] */0
    });

var tabs = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.paddingLeft(Css.px(60)),
        tl: /* [] */0
      }
    });

function tab(isCurrent) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.paddingBottom(Css.px(5)),
                            tl: {
                              hd: Css.color(isCurrent ? Styles.Color.avoPink : Styles.Color.light10),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$short
                                    }, undefined, undefined, "colora"),
                                tl: {
                                  hd: Css.marginRight(Css.px(32)),
                                  tl: {
                                    hd: Css.lastChild({
                                          hd: Css.marginRight(Css.px(0)),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.color(Styles.Color.avoPink),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.selector(":hover path", {
                                              hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.backgroundColor("transparent"),
                                          tl: {
                                            hd: Css.after({
                                                  hd: Css.unsafe("content", "' '"),
                                                  tl: {
                                                    hd: Css.position("absolute"),
                                                    tl: {
                                                      hd: Css.bottom(Css.px(-1)),
                                                      tl: {
                                                        hd: Css.right(Css.px(0)),
                                                        tl: {
                                                          hd: Css.left(Css.px(0)),
                                                          tl: {
                                                            hd: Css.height(Css.px(2)),
                                                            tl: {
                                                              hd: Css.backgroundColor(isCurrent ? Styles.Color.avoPink : "transparent"),
                                                              tl: {
                                                                hd: Css.transition({
                                                                      NAME: "ms",
                                                                      VAL: Styles.Duration.$$short
                                                                    }, undefined, undefined, "background-color"),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var tabBubble = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(1)),
        tl: {
          hd: Css.right(Css.px(-4)),
          tl: {
            hd: Css.height(Css.px(6)),
            tl: {
              hd: Css.width(Css.px(6)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.avoPink),
                tl: {
                  hd: Css.borderRadius(Css.px(3)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.overflowY("auto"),
        tl: {
          hd: Css.width(Css.pct(100.0)),
          tl: /* [] */0
        }
      }
    });

var Style = {
  root: root,
  header: header,
  tabs: tabs,
  tab: tab,
  tabBubble: tabBubble,
  content: content
};

function SourceConfig(Props) {
  var currentBranch = Props.currentBranch;
  var tab$1 = Props.tab;
  var model = Props.model;
  var mainModel = Props.mainModel;
  var sourceId = Props.sourceId;
  var viewerRole = Props.viewerRole;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useState(function () {
        return tab$1;
      });
  var setCurrentTab = match[1];
  var currentTab = match[0];
  var sendActions = SendActionsContext.use(undefined);
  var match$1 = React.useContext(ImplementationStatusContext.context).codegen;
  var inspectorStats = InspectorStats.useStatsWithCache(undefined);
  React.useEffect((function () {
          Curry._1(setCurrentTab, (function (param) {
                  return tab$1;
                }));
          
        }), [tab$1]);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var source = AvoConfig.sourceById(model, sourceId);
  if (source === undefined) {
    return React.createElement("div", undefined, "Error loading source");
  }
  var inspectorStatus = InspectorStats.getSourceStatus(source, inspectorStats);
  var tmp = {
    kind: isGlobalWorkspace ? "Workspace" : "Source",
    withBorder: false,
    children: React.createElement(Title.make, {
          children: AvoConfig.getSourceName(source),
          size: "Tiny"
        })
  };
  var tmp$1 = RoleUtils.canEdit(viewerRole) && !isGlobalWorkspace ? [{
        NAME: "WarningOption",
        VAL: {
          label: "Archive Source",
          onClick: (function (param) {
              if (window.confirm("Are you sure you want to archive this source?")) {
                var match = Router.getStaticRoute(undefined);
                var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                        if (typeof item === "object" && item.NAME === "source") {
                          return item.VAL[0] !== source.id;
                        } else {
                          return true;
                        }
                      }));
                Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
                return Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (branch) {
                                Curry._1(addToast, {
                                      message: "The source has been archived"
                                    });
                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                return AnalyticsRe.dataSourceUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, Belt_Option.getWithDefault(source.name, ""), undefined, source.platform, undefined, AnalyticsUtils.analyticsLanguage(source.language), undefined, source.filename, undefined, source.path, undefined, "SourceDeleted", undefined, SourceConfigOverview.modelSourceConfigToAnalyticsSourceConfig(source.config), undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                              }),
                            undefined,
                            [[
                                {
                                  NAME: "Archive",
                                  VAL: {
                                    NAME: "Source",
                                    VAL: sourceId
                                  }
                                },
                                {
                                  sourceId: sourceId,
                                  sourceQuery: sourceId
                                }
                              ]]
                          ]);
              }
              
            })
        }
      }] : undefined;
  if (tmp$1 !== undefined) {
    tmp.options = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("section", {
              className: root
            }, React.createElement("header", {
                  className: header
                }, React.createElement(DrawerHeader.make, tmp), React.createElement("div", {
                      className: tabs
                    }, React.createElement("button", {
                          className: tab(currentTab === "overview"),
                          onClick: (function (param) {
                              return Router.Schema.swapTopDrawerItem({
                                          NAME: "source",
                                          VAL: [
                                            source.id,
                                            "overview"
                                          ]
                                        });
                            })
                        }, React.createElement($$Text.make, {
                              element: "Span",
                              size: "Medium",
                              weight: "Semi",
                              children: "Overview"
                            })), isGlobalWorkspace ? null : React.createElement(React.Fragment, undefined, React.createElement("button", {
                                className: tab(currentTab === "inspectorSetup"),
                                onClick: (function (param) {
                                    return Router.Schema.swapTopDrawerItem({
                                                NAME: "source",
                                                VAL: [
                                                  source.id,
                                                  "inspectorSetup"
                                                ]
                                              });
                                  })
                              }, typeof inspectorStatus === "number" ? (
                                  inspectorStatus !== 0 ? React.createElement("div", {
                                          className: tabBubble
                                        }) : null
                                ) : React.createElement(React.Fragment, undefined, React.createElement(IconCheckmarkCircle.make, {
                                          size: 12,
                                          color: currentTab === "inspectorSetup" ? Styles.Color.avoPink : Styles.Color.light10
                                        }), React.createElement(Spacer.make, {
                                          width: 4
                                        })), React.createElement($$Text.make, {
                                    element: "Span",
                                    size: "Medium",
                                    weight: "Semi",
                                    children: "Inspector Setup"
                                  })), React.createElement("button", {
                                className: tab(currentTab === "codegenSetup"),
                                onClick: (function (param) {
                                    return Router.Schema.swapTopDrawerItem({
                                                NAME: "source",
                                                VAL: [
                                                  source.id,
                                                  "codegenSetup"
                                                ]
                                              });
                                  })
                              }, match$1[0] === "Ready" ? (
                                  Belt_Option.mapWithDefault(Js_dict.get(match$1[2], source.id), false, (function (invocations) {
                                          return !Belt_MapString.isEmpty(invocations);
                                        })) ? React.createElement(React.Fragment, undefined, React.createElement(IconCheckmarkCircle.make, {
                                              size: 12,
                                              color: currentTab === "codegenSetup" ? Styles.Color.avoPink : Styles.Color.light10
                                            }), React.createElement(Spacer.make, {
                                              width: 4
                                            })) : React.createElement("div", {
                                          className: tabBubble
                                        })
                                ) : null, React.createElement($$Text.make, {
                                    element: "Span",
                                    size: "Medium",
                                    weight: "Semi",
                                    children: "Avo Codegen Setup"
                                  }))))), React.createElement("div", {
                  className: content
                }, currentTab === "overview" ? React.createElement(SourceConfigOverview.make, {
                        source: source,
                        model: model,
                        sendActions: sendActions,
                        schemaBundle: schemaBundle
                      }) : (
                    currentTab === "codegenSetup" ? React.createElement(SourceConfigCodegenSetup.make, {
                            currentBranch: currentBranch,
                            handleTabChange: (function (tab) {
                                return Curry._1(setCurrentTab, (function (param) {
                                              return tab;
                                            }));
                              }),
                            model: model,
                            sendActions: sendActions,
                            source: source,
                            mainBranchSource: AvoConfig.sourceById(mainModel, sourceId),
                            viewerRole: viewerRole
                          }) : React.createElement(SourceConfigInspectorSetup.make, {
                            currentBranch: currentBranch,
                            onClose: (function (param) {
                                return Router.Schema.clearDrawerItems(undefined, undefined);
                              }),
                            source: source,
                            inspectorStats: inspectorStats,
                            handleTabChange: (function (tab) {
                                return Curry._1(setCurrentTab, (function (param) {
                                              return tab;
                                            }));
                              })
                          })
                  )));
}

var make = SourceConfig;

export {
  Style ,
  make ,
  
}
/* root Not a pure module */
