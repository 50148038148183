// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dopt from "../externals/Dopt.mjs";
import * as React from "react";

var defaultContext_block = Dopt.Block.emptyBlock;

function defaultContext_transition(param) {
  
}

var defaultContext_transitions = [];

var defaultContext = {
  advanceByCTA: true,
  block: defaultContext_block,
  intent: undefined,
  stepNumber: 0,
  transition: defaultContext_transition,
  transitions: defaultContext_transitions
};

var context = React.createContext(defaultContext);

var provider = context.Provider;

function use(param) {
  return React.useContext(context);
}

function ProductTour__StepProvider(Props) {
  var advanceByCTA = Props.advanceByCTA;
  var block = Props.block;
  var intent = Props.intent;
  var stepNumber = Props.stepNumber;
  var transition = Props.transition;
  var transitions = Props.transitions;
  var children = Props.children;
  return React.createElement(provider, {
              value: {
                advanceByCTA: advanceByCTA,
                block: block,
                intent: intent,
                stepNumber: stepNumber,
                transition: transition,
                transitions: transitions
              },
              children: children
            });
}

var make = ProductTour__StepProvider;

export {
  defaultContext ,
  context ,
  provider ,
  use ,
  make ,
  
}
/* context Not a pure module */
