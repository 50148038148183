// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Case from "../../bs-case/src/case.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Icons from "./Icons.mjs";
import * as Title from "./Title.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as Actions from "./actions.mjs";
import * as BulkBar from "./BulkBar.mjs";
import * as Changes from "../../shared/Changes.mjs";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Mantine from "./Mantine.mjs";
import * as ArrayExt from "./ArrayExt.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as PortalMenu from "./PortalMenu.mjs";
import * as SheetTypes from "./SheetTypes.mjs";
import * as SheetUtils from "./SheetUtils.mjs";
import * as SourcePill from "./SourcePill.mjs";
import * as StateTypes from "./StateTypes.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as BulkContext from "./BulkContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DomainStore from "./DomainStore.mjs";
import * as EventsEmpty from "./emptystates/EventsEmpty.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as SheetHeader from "./SheetHeader.mjs";
import * as EditableCell from "./EditableCell.mjs";
import * as ExportButton from "./ExportButton.mjs";
import * as ReactWindow from "react-window";
import * as CellEditPopup from "./CellEditPopup.mjs";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as NewEventModal from "./NewEventModal.mjs";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as FramerMotion from "framer-motion";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as SegmentControl from "./SegmentControl.mjs";
import * as SheetContainer from "./SheetContainer.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SideEffectUtils from "./SideEffectUtils.mjs";
import ReactDraggable from "react-draggable";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as StateFilterUtils from "./StateFilterUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FilterQueryParams from "./FilterQueryParams.mjs";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as EventsSheetFilters from "./EventsSheetFilters.mjs";
import * as EventsSheet__Utils from "./EventsSheet__Utils.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SheetSectionFooter from "./SheetSectionFooter.mjs";
import * as SheetSectionHeader from "./SheetSectionHeader.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as EventSheet_NewEvent from "./EventSheet_NewEvent.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as EditableCellListItem from "./EditableCellListItem.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as Belt_MutableMapString from "rescript/lib/es6/belt_MutableMapString.js";
import * as Belt_MutableSetString from "rescript/lib/es6/belt_MutableSetString.js";
import * as EventsSheet__NameCell from "./EventsSheet__NameCell.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as ImplementationStatusContext from "./ImplementationStatusContext.mjs";
import * as EventNameComponentCollection from "./EventNameComponentCollection.mjs";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import * as EventsSheet__EditableNameCellContext from "./EventsSheet__EditableNameCellContext.mjs";
import * as EventsSheet__EventNameComponentsContext from "./EventsSheet__EventNameComponentsContext.mjs";

var _map = {"eventName":"Event Name","sources":"Sources","categories":"Categories","propertyBundles":"Property Bundles","eventProperties":"Event Properties","groupProperties":"Group Properties","tags":"Tags","actions":"Actions","metrics":"Metrics","destinations":"Destinations"};

var _revMap = {"Event Name":"eventName","Sources":"sources","Categories":"categories","Property Bundles":"propertyBundles","Event Properties":"eventProperties","Group Properties":"groupProperties","Tags":"tags","Actions":"actions","Metrics":"metrics","Destinations":"destinations"};

function columnToJs(param) {
  return _map[param];
}

function columnFromJs(param) {
  return _revMap[param];
}

function getColumns(param) {
  return [
          "eventName",
          "sources",
          "categories",
          "propertyBundles",
          "eventProperties",
          "groupProperties",
          "tags",
          "actions",
          "metrics",
          "destinations"
        ];
}

function getCell(mappedModel, model, column, $$event) {
  if (column === "actions") {
    return Belt_Array.map(Belt_List.toArray(SideEffectUtils.getEventSideEffects(false, $$event, model)), SideEffectUtils.getSideEffectTableLabel).join(",");
  }
  if (column === "eventName") {
    return $$event.name;
  }
  if (column === "categories") {
    return Curry._2(TrackingPlanMappedModel.Categories.mapToArray, Curry._2(TrackingPlanMappedModel.Categories.keep, mappedModel.categories, (function (param) {
                        return Belt_List.has(param.events, $$event.id, (function (prim0, prim1) {
                                      return prim0 === prim1;
                                    }));
                      })), (function (param) {
                    return param.name;
                  })).join(",");
  }
  if (column === "eventProperties") {
    var match = AvoModel.getProperties(model, $$event.directPropertyRefs);
    return Belt_Array.mapU(Belt_List.toArray(match.eventProperties), (function (param) {
                    return param.name;
                  })).join(",");
  }
  if (column === "propertyBundles") {
    return Belt_Array.mapU(Belt_List.toArray(Belt_List.keepMapU($$event.propertyBundles, (function (propertyBundle) {
                            return Curry._2(TrackingPlanMappedModel.PropertyBundles.get, mappedModel.propertyBundles, propertyBundle.id);
                          }))), (function (param) {
                    return param.name;
                  })).join(",");
  }
  if (column === "groupProperties") {
    return Belt_Array.keepMapU(Belt_List.toArray(AvoModel.getProperties(model, $$event.directPropertyRefs).groupProperties), (function (groupProperty) {
                    var groupTypeId = groupProperty.sendAs;
                    if (typeof groupTypeId === "number") {
                      return ;
                    }
                    var groupTypeName = ModelUtils.getGroupTypeName(groupTypeId._0, model);
                    return Belt_Option.getWithDefault(groupTypeName, "Unknown Group Type") + ":" + groupProperty.name;
                  })).join(",");
  }
  if (column !== "destinations") {
    if (column === "metrics") {
      return Curry._2(TrackingPlanMappedModel.Metrics.mapToArray, Curry._2(TrackingPlanMappedModel.Metrics.keep, mappedModel.metrics, (function (param) {
                          return Belt_List.someU(param.items, (function (metricItem) {
                                        return metricItem.VAL.eventId === $$event.id;
                                      }));
                        })), (function (param) {
                      var name = param.name;
                      if (name === "") {
                        return "Untitled Metric";
                      } else {
                        return name;
                      }
                    })).join(",");
    } else if (column === "sources") {
      return Belt_List.toArray(Belt_List.mapU($$event.includeSources, (function (param) {
                          return TrackingPlanMappedModel.Sources.getName(mappedModel.sources, param.id);
                        }))).join(",");
    } else {
      return Belt_List.toArray($$event.tags).join(",");
    }
  }
  var match$1 = AvoConfig.determineSendingToSources(model.sources, $$event);
  var sourcesSendingEvent = typeof match$1 === "object" ? match$1.VAL : (
      match$1 === "None" ? /* [] */0 : model.sources
    );
  var sourceIdsSendingEvent = Belt_List.mapU(sourcesSendingEvent, (function (source) {
          return source.id;
        }));
  return Belt_Array.keepMapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.mapU($$event.includeDestinations, (function (destination) {
                                      var match = destination.VAL;
                                      if (Belt_List.has(sourceIdsSendingEvent, match[0], (function (prim0, prim1) {
                                                return prim0 === prim1;
                                              }))) {
                                        return match[1];
                                      } else {
                                        return /* [] */0;
                                      }
                                    })))))), (function (destinationId) {
                  return Belt_Option.map(Curry._2(TrackingPlanMappedModel.Destinations.get, mappedModel.destinations, destinationId), ModelUtils.getDestinationName);
                })).join(",");
}

var ExportButton$1 = ExportButton.Make({});

function EventsSheet$CSVExport(Props) {
  var events = Props.events;
  var mappedModel = ModelStore.Mapped.use(undefined);
  var model = ModelStore.useModel(undefined);
  return React.createElement(ExportButton$1.make, {
              getCell: (function (param, param$1) {
                  return getCell(mappedModel, model, param, param$1);
                }),
              getColumnName: columnToJs,
              getColumns: getColumns,
              items: events,
              name: "EventsSheet",
              tooltip: "Export events to CSV"
            });
}

var CSVExport = {
  columnToJs: columnToJs,
  columnFromJs: columnFromJs,
  getColumns: getColumns,
  getCell: getCell,
  ExportButton: ExportButton$1,
  make: EventsSheet$CSVExport
};

function renderModelFromBranchModel(branchModel) {
  return branchModel._0;
}

function defaultColumns(hasInlineCreationFeatureFlag) {
  if (hasInlineCreationFeatureFlag) {
    return [
            {
              columnType: "EventName",
              width: 320,
              order: 0,
              visible: true
            },
            {
              columnType: "Description",
              width: 200,
              order: 1,
              visible: true
            },
            {
              columnType: "Stakeholders",
              width: 200,
              order: 2,
              visible: true
            },
            {
              columnType: "Category",
              width: 200,
              order: 3,
              visible: true
            },
            {
              columnType: "PropertyGroups",
              width: 200,
              order: 4,
              visible: true
            },
            {
              columnType: "EventProperties",
              width: 200,
              order: 5,
              visible: true
            },
            {
              columnType: "GroupProperties",
              width: 200,
              order: 6,
              visible: true
            },
            {
              columnType: "Sources",
              width: 200,
              order: 7,
              visible: true
            },
            {
              columnType: "Actions",
              width: 200,
              order: 8,
              visible: true
            },
            {
              columnType: "Tags",
              width: 200,
              order: 9,
              visible: true
            },
            {
              columnType: "Destinations",
              width: 200,
              order: 10,
              visible: false
            },
            {
              columnType: "Metrics",
              width: 200,
              order: 11,
              visible: false
            }
          ];
  } else {
    return [
            {
              columnType: "EventName",
              width: 320,
              order: 0,
              visible: true
            },
            {
              columnType: "Stakeholders",
              width: 200,
              order: 1,
              visible: true
            },
            {
              columnType: "Category",
              width: 200,
              order: 2,
              visible: true
            },
            {
              columnType: "PropertyGroups",
              width: 200,
              order: 3,
              visible: true
            },
            {
              columnType: "EventProperties",
              width: 200,
              order: 4,
              visible: true
            },
            {
              columnType: "GroupProperties",
              width: 200,
              order: 5,
              visible: true
            },
            {
              columnType: "Sources",
              width: 200,
              order: 6,
              visible: true
            },
            {
              columnType: "Actions",
              width: 200,
              order: 7,
              visible: true
            },
            {
              columnType: "Tags",
              width: 200,
              order: 8,
              visible: true
            },
            {
              columnType: "Destinations",
              width: 200,
              order: 9,
              visible: false
            },
            {
              columnType: "Metrics",
              width: 200,
              order: 10,
              visible: false
            }
          ];
  }
}

function getGetNewAndChangedRowCounts(rows) {
  return Belt_Array.reduceU(rows, [
              [],
              []
            ], (function (param, row) {
                var changedEventIds = param[1];
                var newEventIds = param[0];
                if (typeof row === "number") {
                  return [
                          newEventIds,
                          changedEventIds
                        ];
                }
                if (row.TAG !== /* WorkbenchEvent */1) {
                  return [
                          newEventIds,
                          changedEventIds
                        ];
                }
                var id = row._1.id;
                switch (row._2) {
                  case /* Unmodified */0 :
                      return [
                              newEventIds,
                              changedEventIds
                            ];
                  case /* Modified */1 :
                      return [
                              newEventIds,
                              Belt_Array.concat(changedEventIds, [id])
                            ];
                  case /* New */2 :
                      return [
                              Belt_Array.concat(newEventIds, [id]),
                              changedEventIds
                            ];
                  
                }
              }));
}

function rootStyles(selected, withDetails, groupedByCategory, outerBorderColor, customBackgroundColor, disabled) {
  var customBackgroundColor$1 = groupedByCategory ? Css.transparent : customBackgroundColor;
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.borderRight(Css.px(groupedByCategory ? 1 : 0), "solid", outerBorderColor),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.height(Css.pct(100)),
                          tl: {
                            hd: Css.textAlign("left"),
                            tl: {
                              hd: selected ? Styles.backgroundColorWithOverlayGuarantee(customBackgroundColor$1, Styles.Color.setAlpha(Styles.Color.light12, 0.05)) : Styles.backgroundColorGuarantee(customBackgroundColor$1),
                              tl: {
                                hd: Css.focusWithin({
                                      hd: Styles.backgroundColorWithOverlayGuarantee(customBackgroundColor$1, Styles.Color.setAlpha(Styles.Color.light12, 0.02)),
                                      tl: {
                                        hd: Css.selector("[class$='bulk-checkbox']", {
                                              hd: Css.opacity(1.0),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.selector("[class$='sticky-cell']", {
                                                hd: Styles.backgroundColorWithOverlayGuarantee(customBackgroundColor$1, Styles.Color.setAlpha(Styles.Color.light12, 0.02)),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }),
                                tl: {
                                  hd: Css.minWidth(groupedByCategory && !withDetails ? ({
                                            NAME: "subtract",
                                            VAL: [
                                              Css.pct(100),
                                              Css.px(16)
                                            ]
                                          }) : Css.pct(100.0)),
                                  tl: {
                                    hd: disabled ? Css.cursor("default") : Styles.emptyStyle,
                                    tl: {
                                      hd: Css.hover(disabled ? /* [] */0 : ({
                                                hd: Css.selector(" > div", {
                                                      hd: Styles.backgroundColorWithOverlayGuarantee(customBackgroundColor$1, Styles.Color.setAlpha(Styles.Color.light12, 0.02)),
                                                      tl: /* [] */0
                                                    }),
                                                tl: {
                                                  hd: Css.selector("[class$='bulk-checkbox']", {
                                                        hd: Css.opacity(1.0),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: {
                                                    hd: Css.selector("[class$='event-details-chevron']", {
                                                          hd: Css.opacity(1.0),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              })),
                                      tl: {
                                        hd: Css.position("relative"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function stickyCellStyles(customWidth, groupedByCategory, selected, innerBorderColor, outerBorderColor, customBackgroundColor) {
  var customBackgroundColor$1 = groupedByCategory ? Css.transparent : customBackgroundColor;
  return Curry._1(Css.style, {
              hd: Css.label("sticky-cell"),
              tl: {
                hd: selected ? Styles.backgroundColorWithOverlayGuarantee(customBackgroundColor$1, Styles.Color.setAlpha(Styles.Color.light12, 0.06)) : Styles.backgroundColorGuarantee(customBackgroundColor$1),
                tl: {
                  hd: Css.justifyContent("flexStart"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.position("sticky"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.left(Css.px(0)),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.width(Css.px(customWidth)),
                                tl: {
                                  hd: Css.height(Css.pct(100)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(4)),
                                    tl: {
                                      hd: Css.paddingRight(Css.px(16)),
                                      tl: {
                                        hd: Css.borderRight(Css.px(1), "solid", innerBorderColor),
                                        tl: {
                                          hd: Css.borderBottom(Css.px(1), "solid", innerBorderColor),
                                          tl: {
                                            hd: Css.borderLeft(Css.px(groupedByCategory ? 1 : 0), "solid", outerBorderColor),
                                            tl: {
                                              hd: Css.marginRight(Css.px(-1)),
                                              tl: {
                                                hd: Css.zIndex(1),
                                                tl: {
                                                  hd: Css.outlineWidth(Css.px(0)),
                                                  tl: {
                                                    hd: Css.focus({
                                                          hd: Css.border(Css.pxFloat(1.5), "solid", Styles.Color.darkBlue),
                                                          tl: {
                                                            hd: Css.borderRadius(Css.px(3)),
                                                            tl: {
                                                              hd: Css.boxShadow(Styles.Shadow.insideGlow(Styles.Color.setAlpha(Styles.Color.darkBlue, 0.1))),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }),
                                                    tl: {
                                                      hd: Css.selector(":focus:not(:focus-visible)", {
                                                            hd: Css.borderWidth(Css.px(0)),
                                                            tl: {
                                                              hd: Css.boxShadow("none"),
                                                              tl: /* [] */0
                                                            }
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function cellStylesForGroupedByCategory(withDetails, editable, customWidth, innerBorderColor, outerBorderColor) {
  return Curry._1(Css.style, {
              hd: Css.hover(editable ? ({
                        hd: Css.important(Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.darkBlue, 0.1))),
                        tl: /* [] */0
                      }) : /* [] */0),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("flexStart"),
                  tl: {
                    hd: Css.height(Css.pct(100)),
                    tl: {
                      hd: Css.width(Css.px(customWidth)),
                      tl: {
                        hd: Css.backgroundColor("transparent"),
                        tl: {
                          hd: Css.flexShrink(0.0),
                          tl: {
                            hd: Css.overflow("hidden"),
                            tl: {
                              hd: Css.borderLeft(Css.px(1), "solid", innerBorderColor),
                              tl: {
                                hd: Css.borderBottom(Css.px(1), "solid", innerBorderColor),
                                tl: {
                                  hd: Css.paddingTop(Css.px(2)),
                                  tl: {
                                    hd: Css.paddingRight(Css.px(2)),
                                    tl: {
                                      hd: Css.paddingBottom(Css.px(2)),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(12)),
                                        tl: {
                                          hd: Css.lastChild(withDetails ? ({
                                                    hd: Css.flexGrow(1.0),
                                                    tl: {
                                                      hd: Css.width(Css.px(customWidth + 1 | 0)),
                                                      tl: {
                                                        hd: Css.marginRight(Css.px(716)),
                                                        tl: {
                                                          hd: Css.borderRight(Css.px(1), "solid", outerBorderColor),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }) : ({
                                                    hd: Css.flexGrow(1.0),
                                                    tl: /* [] */0
                                                  })),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function cellStyles(customWidth, withDetails, editable, groupedByCategory, innerBorderColor, outerBorderColor) {
  if (groupedByCategory) {
    return cellStylesForGroupedByCategory(withDetails, editable, customWidth, innerBorderColor, outerBorderColor);
  } else {
    return Curry._1(Css.style, {
                hd: Css.hover(editable ? ({
                          hd: Css.important(Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.darkBlue, 0.1))),
                          tl: /* [] */0
                        }) : /* [] */0),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("flexStart"),
                    tl: {
                      hd: Css.height(Css.pct(100)),
                      tl: {
                        hd: Css.width(Css.px(customWidth)),
                        tl: {
                          hd: Css.maxWidth(Css.px(customWidth)),
                          tl: {
                            hd: Css.backgroundColor("transparent"),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.overflow("hidden"),
                                tl: {
                                  hd: Css.borderLeft(Css.px(1), "solid", innerBorderColor),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), "solid", innerBorderColor),
                                    tl: {
                                      hd: Css.paddingTop(Css.px(2)),
                                      tl: {
                                        hd: Css.paddingRight(Css.px(2)),
                                        tl: {
                                          hd: Css.paddingBottom(Css.px(2)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(12)),
                                            tl: {
                                              hd: Css.lastChild(withDetails ? ({
                                                        hd: Css.width(Css.px(customWidth + 600 | 0)),
                                                        tl: {
                                                          hd: Css.paddingRight(Css.px(customWidth + 600 | 0)),
                                                          tl: /* [] */0
                                                        }
                                                      }) : ({
                                                        hd: Css.minWidth(Css.px(customWidth)),
                                                        tl: {
                                                          hd: Css.width("unset"),
                                                          tl: {
                                                            hd: Css.flexGrow(1.0),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      })),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
  }
}

function cellContentStyles(wrapOpt, param) {
  var wrap = wrapOpt !== undefined ? wrapOpt : "wrap";
  return Curry._1(Css.style, {
              hd: Css.flexWrap(wrap),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.minHeight(Css.pct(100.0)),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function isColumnVisibleByType(columns, columnType) {
  return Belt_Option.mapWithDefault(Belt_Array.getByU(columns, (function (column) {
                    return column.columnType === columnType;
                  })), true, (function (param) {
                return param.visible;
              }));
}

var EditableCategoriesCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.Categories,
      make: EditableCellListItem.Category.make
    });

function EventsSheet$EventRow$CategoryCell(Props) {
  var allCategories = Props.allCategories;
  var columns = Props.columns;
  var eventId = Props.eventId;
  var eventLocation = Props.eventLocation;
  var eventName = Props.eventName;
  var globalSend = Props.globalSend;
  var groupedByCategory = Props.groupedByCategory;
  var viewerCanEdit = Props.viewerCanEdit;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var schemaBundle = Props.schemaBundle;
  var sendActions = Props.sendActions;
  var selectedItems = Belt_Array.keepU(allCategories, (function (category) {
          return Belt_List.has(category.events, eventId, (function (prim0, prim1) {
                        return prim0 === prim1;
                      }));
        }));
  var onToggle = function (param, categoryId, toggled) {
    AnalyticsRe.eventsViewPopupInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, toggled ? "ItemAdded" : "ItemRemoved", "Category", "Category", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
    var context = {
      eventId: eventId,
      eventQuery: eventId,
      goalId: categoryId,
      goalQuery: categoryId
    };
    var categoryName = Belt_Option.mapWithDefault(Belt_Array.getByU(allCategories, (function (category) {
                return category.id === categoryId;
              })), "", (function (param) {
            return param.name;
          }));
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                    return AnalyticsRe.categoryUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, categoryName, categoryId, toggled ? "EventAdded" : "EventRemoved", "N/A", undefined, eventName, eventId, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                  }),
                undefined,
                toggled ? [[
                      {
                        NAME: "AddEventToGoal",
                        VAL: [
                          categoryId,
                          eventId
                        ]
                      },
                      context
                    ]] : [[
                      {
                        NAME: "RemoveEventFromGoal",
                        VAL: [
                          categoryId,
                          eventId
                        ]
                      },
                      context
                    ]]
              ]);
  };
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.eventsViewPopupInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, action, "Category", "Category", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
        }), [schemaBundle]);
  return React.createElement(EditableCategoriesCell.make, {
              allItems: allCategories,
              children: React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, React.createElement($$Text.make, {
                        size: "Tiny",
                        color: Styles.Color.light11,
                        children: Belt_Array.mapU(Belt_Array.keepU(allCategories, (function (category) {
                                      return Belt_List.has(category.events, eventId, (function (prim0, prim1) {
                                                    return prim0 === prim1;
                                                  }));
                                    })), (function (category) {
                                  return category.name;
                                })).join(", ")
                      })),
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "Category"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              onCreates: (function (hidePopup) {
                  return [[
                            (function (searchTerm) {
                                Curry._1(hidePopup, undefined);
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "CreateCategory",
                                              VAL: [
                                                searchTerm,
                                                (function (categoryId, categoryName) {
                                                    AnalyticsRe.categoryUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, categoryName, categoryId, "EventAdded", "N/A", undefined, eventName, eventId, schemaBundle.branchId, schemaBundle.schemaId);
                                                    return [[
                                                              {
                                                                NAME: "AddEventToGoal",
                                                                VAL: [
                                                                  categoryId,
                                                                  eventId
                                                                ]
                                                              },
                                                              {
                                                                eventId: eventId,
                                                                eventQuery: eventId,
                                                                goalId: categoryId,
                                                                goalQuery: categoryId
                                                              }
                                                            ]];
                                                  })
                                              ]
                                            }
                                          });
                              }),
                            (function (searchTerm) {
                                return "+ Create " + searchTerm;
                              })
                          ]];
                }),
              disabled: withDetails,
              onToggle: onToggle,
              viewerCanEdit: viewerCanEdit,
              selectedItems: selectedItems,
              onItemClicked: (function (param) {
                  return Curry._1(onInteraction, "ItemClicked");
                }),
              onHover: (function (param) {
                  return AnalyticsRe.eventsViewInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Category", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
                }),
              onSearch: (function (_value) {
                  return Curry._1(onInteraction, "SearchedForItem");
                }),
              onCreateInitiated: (function (_searchTerm) {
                  return Curry._1(onInteraction, "ItemCreationInitiated");
                })
            });
}

var CategoryCell = {
  make: EventsSheet$EventRow$CategoryCell
};

var EditablePropertyGroupsCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.PropertyGroups,
      make: EditableCellListItem.PropertyGroup.make
    });

function EventsSheet$EventRow$PropertyGroupsCell(Props) {
  var allPropertyGroups = Props.allPropertyGroups;
  var columns = Props.columns;
  var eventId = Props.eventId;
  var eventLocation = Props.eventLocation;
  var eventName = Props.eventName;
  var eventIncludeSources = Props.eventIncludeSources;
  var globalSend = Props.globalSend;
  var groupedByCategory = Props.groupedByCategory;
  var modelEvents = Props.modelEvents;
  var numTriggersOnEvent = Props.numTriggersOnEvent;
  var viewerCanEdit = Props.viewerCanEdit;
  var selectedItems = Props.selectedItems;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var schemaBundle = Props.schemaBundle;
  var sendActions = Props.sendActions;
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, eventName);
  var namingConvention = ValidationConfigV2.EventsV2.hasBasicNameCasing(match.config.events) ? Case.toAnalytics(expectedEventCase) : "Advanced";
  var onToggle = React.useCallback((function (_searchTerm) {
          return function (propertyGroupId, toggled) {
            AnalyticsRe.eventsViewPopupInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, toggled ? "ItemAdded" : "ItemRemoved", "PropertyGroups", "PropertyGroup", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
            var context = {
              eventId: eventId,
              eventQuery: eventId,
              propertyGroupId: propertyGroupId,
              propertyGroupQuery: propertyGroupId
            };
            return Curry.app(sendActions, [
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        (function (branch) {
                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                            return AnalyticsRe.eventUpdated(eventId, eventName, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", toggled ? "AddPropertyGroup" : "RemovePropertyGroup", propertyGroupId, Belt_Option.map(Belt_Array.getByU(allPropertyGroups, (function (param) {
                                                  return propertyGroupId === param.id;
                                                })), (function (param) {
                                              return param.name;
                                            })), Belt_List.toArray(ModelUtils.getIncludedSourceIds(eventIncludeSources)), Belt_List.toArray(ModelUtils.getIncludedSourceIds(eventIncludeSources)), undefined, undefined, undefined, undefined, Belt_List.length(modelEvents), undefined, undefined, undefined, undefined, "Events", numTriggersOnEvent, correctCase, namingConvention, "Table", eventId, schemaBundle$1.schemaId, NamedBranch.getId(branch), eventId, undefined, eventName);
                          }),
                        undefined,
                        toggled ? [[
                              {
                                NAME: "AddPropertyGroupToEvent",
                                VAL: [
                                  eventId,
                                  propertyGroupId
                                ]
                              },
                              context
                            ]] : [[
                              {
                                NAME: "RemovePropertyGroupFromEvent",
                                VAL: [
                                  eventId,
                                  propertyGroupId
                                ]
                              },
                              context
                            ]]
                      ]);
          };
        }), [
        allPropertyGroups,
        eventIncludeSources,
        schemaBundle
      ]);
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.eventsViewPopupInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, action, "PropertyGroups", "PropertyGroup", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
        }), [schemaBundle]);
  return React.createElement(EditablePropertyGroupsCell.make, {
              allItems: allPropertyGroups,
              children: React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, React.createElement($$Text.make, {
                        size: "Tiny",
                        lines: 3,
                        color: Styles.Color.light11,
                        children: Belt_Array.mapU(selectedItems, (function (group) {
                                  return group.name;
                                })).join(", ")
                      })),
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "PropertyGroups"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              onCreates: (function (hidePopup) {
                  return [[
                            (function (searchTerm) {
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "NewPropertyGroup",
                                              VAL: [
                                                searchTerm,
                                                (function (propertyGroupId) {
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "AddPropertyGroupToEvent",
                                                                VAL: [
                                                                  eventId,
                                                                  propertyGroupId
                                                                ]
                                                              },
                                                              {
                                                                eventId: eventId,
                                                                eventQuery: eventId,
                                                                propertyGroupId: propertyGroupId,
                                                                propertyGroupQuery: propertyGroupId
                                                              }
                                                            ]]
                                                        ]);
                                                    return Curry._1(hidePopup, undefined);
                                                  })
                                              ]
                                            }
                                          });
                              }),
                            (function (searchTerm) {
                                return "+ Create " + searchTerm;
                              })
                          ]];
                }),
              disabled: withDetails,
              onToggle: onToggle,
              viewerCanEdit: viewerCanEdit,
              selectedItems: selectedItems,
              onItemClicked: (function (param) {
                  return Curry._1(onInteraction, "ItemClicked");
                }),
              onHover: (function (param) {
                  return AnalyticsRe.eventsViewInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "PropertyGroups", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
                }),
              onSearch: (function (_value) {
                  return Curry._1(onInteraction, "SearchedForItem");
                }),
              onCreateInitiated: (function (_searchTerm) {
                  return Curry._1(onInteraction, "ItemCreationInitiated");
                })
            });
}

var PropertyGroupsCell = {
  make: EventsSheet$EventRow$PropertyGroupsCell
};

var EditableEventPropertiesCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.EventProperties,
      make: EditableCellListItem.EventProperty.make
    });

function EventsSheet$EventRow$EventPropertiesCell(Props) {
  var allEventProperties = Props.allEventProperties;
  var columns = Props.columns;
  var currentFilters = Props.currentFilters;
  var eventId = Props.eventId;
  var eventLocation = Props.eventLocation;
  var eventName = Props.eventName;
  var eventProperties = Props.eventProperties;
  var globalSend = Props.globalSend;
  var groupedByCategory = Props.groupedByCategory;
  var model = Props.model;
  var propertyGroups = Props.propertyGroups;
  var viewerCanEdit = Props.viewerCanEdit;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var schemaBundle = Props.schemaBundle;
  var sendActions = Props.sendActions;
  var properties = React.useMemo((function () {
          var match = AvoModel.getProperties(model, eventProperties);
          return Belt_List.toArray(match.eventProperties);
        }), [
        model.properties,
        eventProperties
      ]);
  var onToggle = function (searchTerm, propertyId, toggled) {
    AnalyticsRe.eventsViewPopupInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, toggled ? "ItemAdded" : "ItemRemoved", "EventProperties", "EventProperty", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
    var match = ModelUtils.getPropertyById(propertyId, model);
    if (match === undefined) {
      return ;
    }
    if (match.TAG === /* PropertyRef */0) {
      return ;
    }
    var property = match._0;
    var propertyGroup = AnalyticsUtils.getAnalyticsPropertyGroupFromCommonCase(undefined, property);
    var nameWordCount = Case.to_("Sentence", property.name).split(" ").length;
    var context = {
      eventId: eventId,
      eventQuery: eventId,
      propertyId: propertyId,
      propertyQuery: propertyId
    };
    if (toggled) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                      return AnalyticsRe.propertyAdded(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, eventId, eventName, propertyGroup.propertyId, Belt_Option.getWithDefault(propertyGroup.propertyName, ""), propertyGroup.namingConvention, propertyGroup.propertyType, propertyGroup.correctCase, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, property.name.length, "Existing", nameWordCount, searchTerm.length, "EventsView", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "AddPropertyRef",
                        VAL: [
                          eventId,
                          propertyId
                        ]
                      },
                      context
                    ]]
                ]);
    } else {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                      return AnalyticsRe.propertyRemoved(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyGroup.propertyId, property.name, propertyGroup.namingConvention, propertyGroup.propertyType, propertyGroup.correctCase, eventId, eventName, "EventsView", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "RemovePropertyRefV3",
                        VAL: [
                          eventId,
                          propertyId
                        ]
                      },
                      context
                    ]]
                ]);
    }
  };
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.eventsViewPopupInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, action, "EventProperties", "EventProperty", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
        }), [schemaBundle]);
  var tmp;
  var exit = 0;
  if (propertyGroups.length !== 0 || properties.length !== 0) {
    exit = 1;
  } else {
    tmp = React.createElement($$Text.make, {
          size: "Tiny",
          weight: "Semi",
          color: Styles.Color.orange,
          children: "No event properties"
        });
  }
  if (exit === 1) {
    tmp = properties.length !== 0 ? React.createElement($$Text.make, {
            size: "Tiny",
            lines: 3,
            color: Styles.Color.light11,
            children: null
          }, React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: /* [] */0
                    })
              }, String(properties.length)), ": " + Belt_Array.mapU(properties, (function (param) {
                    return param.name;
                  })).join(", ")) : null;
  }
  return React.createElement(EditableEventPropertiesCell.make, {
              allItems: allEventProperties,
              children: React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, tmp),
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "EventProperties"), withDetails, !withDetails, groupedByCategory, innerBorderColor, outerBorderColor),
              onCreates: (function (hidePopup) {
                  return [[
                            (function (searchTerm) {
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "NewProperty",
                                              VAL: [
                                                searchTerm,
                                                /* EventProperty */1,
                                                {
                                                  NAME: "Event",
                                                  VAL: eventId
                                                },
                                                (function (propertyId, _propertyName) {
                                                    Curry._1(hidePopup, undefined);
                                                    return Router.Schema.pushDrawerItem(undefined, undefined, {
                                                                NAME: "property",
                                                                VAL: [
                                                                  propertyId,
                                                                  undefined
                                                                ]
                                                              });
                                                  }),
                                                "EventsView"
                                              ]
                                            }
                                          });
                              }),
                            (function (searchTerm) {
                                return "+ Create " + searchTerm;
                              })
                          ]];
                }),
              disabled: withDetails,
              onViewItem: (function (propertyId) {
                  return Router.Schema.pushDrawerItem(undefined, undefined, {
                              NAME: "property",
                              VAL: [
                                propertyId,
                                undefined
                              ]
                            });
                }),
              onToggle: onToggle,
              viewerCanEdit: viewerCanEdit,
              selectedItems: properties,
              onItemClicked: (function (param) {
                  return Curry._1(onInteraction, "ItemClicked");
                }),
              onHover: (function (param) {
                  return AnalyticsRe.eventsViewInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "EventProperties", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
                }),
              onSearch: (function (_value) {
                  return Curry._1(onInteraction, "SearchedForItem");
                }),
              onCreateInitiated: (function (_searchTerm) {
                  return Curry._1(onInteraction, "ItemCreationInitiated");
                })
            });
}

var EventPropertiesCell = {
  make: EventsSheet$EventRow$EventPropertiesCell
};

var EditableGroupPropertiesCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.GroupProperties,
      make: EditableCellListItem.GroupProperty.make
    });

function EventsSheet$EventRow$GroupPropertiesCell(Props) {
  var allGroupPropertiesWithGroupTypeNames = Props.allGroupPropertiesWithGroupTypeNames;
  var columns = Props.columns;
  var currentFilters = Props.currentFilters;
  var eventId = Props.eventId;
  var eventLocation = Props.eventLocation;
  var eventName = Props.eventName;
  var eventProperties = Props.eventProperties;
  var globalSend = Props.globalSend;
  var groupedByCategory = Props.groupedByCategory;
  var model = Props.model;
  var viewerCanEdit = Props.viewerCanEdit;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var schemaBundle = Props.schemaBundle;
  var sendActions = Props.sendActions;
  var propertiesWithGroupTypeNames = React.useMemo((function () {
          var match = AvoModel.getProperties(model, eventProperties);
          return Belt_Array.map(Belt_List.toArray(match.groupProperties), (function (groupProperty) {
                        var groupTypeId = groupProperty.sendAs;
                        return [
                                groupProperty,
                                typeof groupTypeId === "number" ? undefined : ModelUtils.getGroupTypeName(groupTypeId._0, model)
                              ];
                      }));
        }), [
        model.properties,
        eventProperties
      ]);
  var onToggle = function (searchTerm, propertyId, toggled) {
    AnalyticsRe.eventsViewPopupInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, toggled ? "ItemAdded" : "ItemRemoved", "GroupProperties", "GroupProperty", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
    var match = ModelUtils.getPropertyById(propertyId, model);
    if (match === undefined) {
      return ;
    }
    if (match.TAG === /* PropertyRef */0) {
      return ;
    }
    var property = match._0;
    var propertyGroup = AnalyticsUtils.getAnalyticsPropertyGroupFromCommonCase(undefined, property);
    var nameWordCount = Case.to_("Sentence", property.name).split(" ").length;
    var context = {
      eventId: eventId,
      eventQuery: eventId,
      propertyId: propertyId,
      propertyQuery: propertyId
    };
    if (toggled) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                      return AnalyticsRe.propertyAdded(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, schema.branchId, schema.branchName, schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, eventId, eventName, propertyGroup.propertyId, property.name, propertyGroup.namingConvention, propertyGroup.propertyType, propertyGroup.correctCase, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, property.name.length, "Existing", nameWordCount, searchTerm.length, "EventsView", NamedBranch.getId(branch), schemaBundle.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "AddPropertyRef",
                        VAL: [
                          eventId,
                          propertyId
                        ]
                      },
                      context
                    ]]
                ]);
    } else {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                      return AnalyticsRe.propertyRemoved(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, schema.branchId, schema.branchName, schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, propertyGroup.propertyId, property.name, propertyGroup.namingConvention, propertyGroup.propertyType, propertyGroup.correctCase, eventId, eventName, "EventsView", NamedBranch.getId(branch), schemaBundle.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "RemovePropertyRefV3",
                        VAL: [
                          eventId,
                          propertyId
                        ]
                      },
                      context
                    ]]
                ]);
    }
  };
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.eventsViewPopupInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, action, "GroupProperties", "GroupProperty", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
        }), [schemaBundle]);
  return React.createElement(EditableGroupPropertiesCell.make, {
              allItems: allGroupPropertiesWithGroupTypeNames,
              children: React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, propertiesWithGroupTypeNames.length !== 0 ? React.createElement($$Text.make, {
                          size: "Tiny",
                          lines: 3,
                          color: Styles.Color.light11,
                          children: null
                        }, React.createElement("span", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: /* [] */0
                                  })
                            }, String(propertiesWithGroupTypeNames.length)), ": " + Belt_Array.mapU(propertiesWithGroupTypeNames, (function (param) {
                                  return Belt_Option.getWithDefault(param[1], "Unknown Group Type") + "/" + param[0].name;
                                })).join(", ")) : null),
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "GroupProperties"), withDetails, !withDetails, groupedByCategory, innerBorderColor, outerBorderColor),
              onCreates: (function (param) {
                  return Belt_Array.map(model.groupTypes, (function (param) {
                                var groupTypeName = param.name;
                                var groupTypeId = param.id;
                                return [
                                        (function (param) {
                                            return Curry._1(globalSend, {
                                                        TAG: /* OpenModal */4,
                                                        _0: {
                                                          NAME: "NewProperty",
                                                          VAL: [
                                                            param,
                                                            /* GroupProperty */{
                                                              _0: groupTypeId
                                                            },
                                                            {
                                                              NAME: "Event",
                                                              VAL: eventId
                                                            },
                                                            (function (propertyId, _propertyName) {
                                                                return Router.Schema.pushDrawerItem(undefined, undefined, {
                                                                            NAME: "property",
                                                                            VAL: [
                                                                              propertyId,
                                                                              undefined
                                                                            ]
                                                                          });
                                                              }),
                                                            "EventsView"
                                                          ]
                                                        }
                                                      });
                                          }),
                                        (function (param) {
                                            return "+ Create " + param + " in " + groupTypeName;
                                          })
                                      ];
                              }));
                }),
              disabled: withDetails,
              onViewItem: (function (propertyId) {
                  return Router.Schema.pushDrawerItem(undefined, undefined, {
                              NAME: "property",
                              VAL: [
                                propertyId,
                                undefined
                              ]
                            });
                }),
              onToggle: onToggle,
              viewerCanEdit: viewerCanEdit,
              selectedItems: propertiesWithGroupTypeNames,
              onItemClicked: (function (param) {
                  return Curry._1(onInteraction, "ItemClicked");
                }),
              onHover: (function (param) {
                  return AnalyticsRe.eventsViewInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "GroupProperties", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
                }),
              onSearch: (function (_value) {
                  return Curry._1(onInteraction, "SearchedForItem");
                }),
              onCreateInitiated: (function (_searchTerm) {
                  return Curry._1(onInteraction, "ItemCreationInitiated");
                })
            });
}

var GroupPropertiesCell = {
  make: EventsSheet$EventRow$GroupPropertiesCell
};

function EventsSheet$EventRow$SourcesCell(Props) {
  var columns = Props.columns;
  var withDetails = Props.withDetails;
  var groupedByCategory = Props.groupedByCategory;
  var $$event = Props.event;
  var isEventModified = Props.isEventModified;
  var sources = Props.sources;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var match = AvoConfig.determineSendingToSources(sources, $$event);
  var sourcesSendingEvent = typeof match === "object" ? match.VAL : (
      match === "None" ? /* [] */0 : sources
    );
  var match$1 = ImplementationStatusContext.useEventWithRefetchStatusSubscription($$event.id);
  var eventInvocations = match$1[1];
  var codegenInvocationsFetcherState = match$1[0];
  return React.createElement("div", {
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "Sources"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor)
            }, React.createElement("div", {
                  className: cellContentStyles(undefined, undefined)
                }, sourcesSendingEvent === /* [] */0 ? React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Semi",
                        color: Styles.Color.orange,
                        children: "No sources"
                      }) : Belt_List.toArray(Belt_List.mapWithIndexU(sourcesSendingEvent, (function (index, source) {
                              if (index === 0) {
                                return React.createElement("div", {
                                            key: $$event.id + source.id,
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.paddingRight(Css.px(2)),
                                                      tl: /* [] */0
                                                    })
                                              }, React.createElement($$Text.make, {
                                                    size: "Tiny",
                                                    weight: "Semi",
                                                    color: Styles.Color.light11,
                                                    children: null
                                                  }, React.createElement("span", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                                              tl: /* [] */0
                                                            })
                                                      }, String(Belt_List.size(sourcesSendingEvent))), ":")), React.createElement(SourcePill.make, {
                                                event: $$event,
                                                isEventModified: isEventModified,
                                                eventInvocations: eventInvocations,
                                                codegenInvocationsFetcherState: codegenInvocationsFetcherState,
                                                source: source,
                                                _timeWindowStart: undefined
                                              }));
                              } else {
                                return React.createElement(SourcePill.make, {
                                            event: $$event,
                                            isEventModified: isEventModified,
                                            eventInvocations: eventInvocations,
                                            codegenInvocationsFetcherState: codegenInvocationsFetcherState,
                                            source: source,
                                            _timeWindowStart: undefined,
                                            key: $$event.id + source.id
                                          });
                              }
                            })))));
}

var SourcesCell = {
  make: EventsSheet$EventRow$SourcesCell
};

function EventsSheet$EventRow$ActionsCell(Props) {
  var columns = Props.columns;
  var withDetails = Props.withDetails;
  var groupedByCategory = Props.groupedByCategory;
  var $$event = Props.event;
  var model = Props.model;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var actions = SideEffectUtils.getEventSideEffects(false, $$event, model);
  return React.createElement("div", {
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "Actions"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              title: Belt_List.toArray(Belt_List.keepMapU(actions, (function (action) {
                            if (typeof action === "object" && action.NAME === "UserProperties") {
                              return "User properties:\n" + Belt_List.toArray(Belt_List.mapU(action.VAL, (function (property) {
                                                  return "- " + property.name;
                                                }))).join("\n");
                            }
                            
                          }))).join("\n")
            }, React.createElement("div", {
                  className: cellContentStyles(undefined, undefined)
                }, actions === /* [] */0 ? React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Semi",
                        color: Styles.Color.red,
                        children: "No actions"
                      }) : React.createElement($$Text.make, {
                        size: "Tiny",
                        lines: 3,
                        color: Styles.Color.light11,
                        children: Belt_Array.map(Belt_List.toArray(actions), SideEffectUtils.getSideEffectTableLabel).join(", ")
                      })));
}

var ActionsCell = {
  make: EventsSheet$EventRow$ActionsCell
};

var EditableTagsCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.Tags,
      make: EditableCellListItem.Tag.make
    });

function EventsSheet$EventRow$TagsCell(Props) {
  var allTags = Props.allTags;
  var columns = Props.columns;
  var eventId = Props.eventId;
  var eventLocation = Props.eventLocation;
  var eventName = Props.eventName;
  var groupedByCategory = Props.groupedByCategory;
  var viewerCanEdit = Props.viewerCanEdit;
  var tags = Props.tags;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var schemaBundle = Props.schemaBundle;
  var sendActions = Props.sendActions;
  var onToggle = function (_searchTerm, tag, toggled) {
    AnalyticsRe.eventsViewPopupInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, toggled ? "ItemAdded" : "ItemRemoved", "Tags", "Tag", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                    if (toggled) {
                      return AnalyticsRe.eventTagsUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Add", tag, Belt_Array.concat(Belt_List.toArray(tags), [tag]), eventId, eventName, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                    } else {
                      return AnalyticsRe.eventTagsUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Remove", tag, Belt_Array.keep(Belt_List.toArray(tags), (function (existingTag) {
                                        return existingTag !== tag;
                                      })), eventId, eventName, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                    }
                  }),
                undefined,
                [[
                    toggled ? ({
                          NAME: "AddTag",
                          VAL: [
                            eventId,
                            tag
                          ]
                        }) : ({
                          NAME: "RemoveTag",
                          VAL: [
                            eventId,
                            tag
                          ]
                        }),
                    {
                      eventId: eventId,
                      eventQuery: eventId
                    }
                  ]]
              ]);
  };
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.eventsViewPopupInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, action, "Tags", "Tag", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
        }), [schemaBundle]);
  return React.createElement(EditableTagsCell.make, {
              allItems: allTags,
              children: React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, tags === /* [] */0 ? null : React.createElement($$Text.make, {
                          size: "Tiny",
                          lines: 3,
                          color: Styles.Color.light11,
                          children: Belt_List.toArray(tags).join(", ")
                        })),
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "Tags"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              onCreates: (function (param) {
                  return [[
                            (function (newTag) {
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                return AnalyticsRe.eventTagsUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Create", newTag, Belt_Array.concat(allTags, [newTag]), eventId, eventName, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddTag",
                                                  VAL: [
                                                    eventId,
                                                    newTag
                                                  ]
                                                },
                                                {
                                                  eventId: eventId,
                                                  eventQuery: eventId
                                                }
                                              ]]
                                          ]);
                              }),
                            (function (searchTerm) {
                                return "+ Create " + searchTerm;
                              })
                          ]];
                }),
              disabled: withDetails,
              onToggle: onToggle,
              viewerCanEdit: viewerCanEdit,
              selectedItems: Belt_List.toArray(tags),
              onItemClicked: (function (param) {
                  return Curry._1(onInteraction, "ItemClicked");
                }),
              onHover: (function (param) {
                  return AnalyticsRe.eventsViewInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Tags", eventLocation, schemaBundle.branchId, schemaBundle.schemaId);
                }),
              onSearch: (function (_value) {
                  return Curry._1(onInteraction, "SearchedForItem");
                }),
              onCreateInitiated: (function (_searchTerm) {
                  return Curry._1(onInteraction, "ItemCreationInitiated");
                })
            });
}

var TagsCell = {
  make: EventsSheet$EventRow$TagsCell
};

var iconStyles = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(6)),
      tl: /* [] */0
    });

function overflowedDestinationsContainerStyles(customWidth) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(customWidth)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: /* [] */0
                }
              }
            });
}

var overflowedStyles = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "dotted", Styles.Color.light08),
      tl: /* [] */0
    });

function EventsSheet$EventRow$DestinationsCell(Props) {
  var columns = Props.columns;
  var withDetails = Props.withDetails;
  var groupedByCategory = Props.groupedByCategory;
  var sources = Props.sources;
  var $$event = Props.event;
  var modelDestinations = Props.modelDestinations;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var match = AvoConfig.determineSendingToSources(sources, $$event);
  var sourcesSendingEvent = typeof match === "object" ? match.VAL : (
      match === "None" ? /* [] */0 : sources
    );
  var sourceIdsSendingEvent = Belt_List.mapU(sourcesSendingEvent, (function (source) {
          return source.id;
        }));
  var eventDestinations = Belt_Array.keepMapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.mapU($$event.includeDestinations, (function (destination) {
                              var match = destination.VAL;
                              if (Belt_List.has(sourceIdsSendingEvent, match[0], (function (prim0, prim1) {
                                        return prim0 === prim1;
                                      }))) {
                                return match[1];
                              } else {
                                return /* [] */0;
                              }
                            })))))), (function (destinationId) {
          return Belt_List.getByU(modelDestinations, (function (destination) {
                        return destination.id === destinationId;
                      }));
        }));
  var iconsFitInWidth = EventsSheet__Utils.getColumnWidthByType(columns, "Destinations") / 32 | 0;
  var destinations = Belt_Array.keepMapU(eventDestinations, (function (destination) {
          var type_ = destination.type_;
          if (type_ !== undefined) {
            return [
                    type_,
                    ModelUtils.getDestinationName(destination),
                    destination.id
                  ];
          }
          
        }));
  var overflow = destinations.length > iconsFitInWidth;
  var shownDestinations = overflow ? Belt_Array.slice(destinations, 0, iconsFitInWidth - 1 | 0) : destinations;
  var truncatedDestinations = overflow ? Belt_Array.sliceToEnd(destinations, iconsFitInWidth - 1 | 0) : [];
  return React.createElement("div", {
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "Destinations"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor)
            }, React.createElement("div", {
                  className: cellContentStyles("nowrap", undefined)
                }, Caml_obj.caml_equal(destinations, []) ? React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Semi",
                        color: Styles.Color.orange,
                        children: "No destinations"
                      }) : React.createElement(React.Fragment, undefined, Belt_Array.mapU(shownDestinations, (function (param) {
                              return React.createElement("div", {
                                          key: param[2],
                                          className: iconStyles,
                                          title: param[1]
                                        }, React.createElement(Icons.Destinations.make, {
                                              destination: param[0],
                                              size: 24
                                            }));
                            })), Caml_obj.caml_equal(truncatedDestinations, []) ? null : React.createElement("div", {
                              className: overflowedDestinationsContainerStyles(24),
                              title: Belt_Array.mapU(truncatedDestinations, (function (param) {
                                        return param[1];
                                      })).join(", ")
                            }, React.createElement("span", {
                                  className: overflowedStyles
                                }, "+" + String(truncatedDestinations.length))))));
}

var DestinationsCell = {
  iconStyles: iconStyles,
  overflowedDestinationsContainerStyles: overflowedDestinationsContainerStyles,
  overflowedStyles: overflowedStyles,
  make: EventsSheet$EventRow$DestinationsCell
};

function EventsSheet$EventRow$MetricsCell(Props) {
  var columns = Props.columns;
  var eventId = Props.eventId;
  var groupedByCategory = Props.groupedByCategory;
  var modelMetrics = Props.modelMetrics;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var metricNames = Belt_List.toArray(Belt_List.mapU(Belt_List.keepU(modelMetrics, (function (param) {
                  return Belt_List.someU(param.items, (function (metricItem) {
                                return metricItem.VAL.eventId === eventId;
                              }));
                })), (function (metric) {
              if (metric.name === "") {
                return "Untitled Metric";
              } else {
                return metric.name;
              }
            })));
  return React.createElement("div", {
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "Metrics"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              title: metricNames.join("\n")
            }, React.createElement("div", {
                  className: cellContentStyles(undefined, undefined)
                }, metricNames.length !== 0 ? React.createElement($$Text.make, {
                        size: "Tiny",
                        lines: 3,
                        color: Styles.Color.light11,
                        children: null
                      }, React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                })
                          }, String(metricNames.length)), ": " + metricNames.join(", ")) : null));
}

var MetricsCell = {
  make: EventsSheet$EventRow$MetricsCell
};

function EventsSheet$EventRow$StakeholdersCell(Props) {
  var columns = Props.columns;
  var eventId = Props.eventId;
  var groupedByCategory = Props.groupedByCategory;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var domainsForEvent = DomainStore.useEventDomains(eventId);
  var domainNames = React.useMemo((function () {
          return Curry._2(Domains.mapToArray, domainsForEvent, (function (param) {
                        return param.name;
                      }));
        }), [domainsForEvent]);
  return React.createElement("div", {
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "Stakeholders"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              title: domainNames.join("\n")
            }, React.createElement("div", {
                  className: cellContentStyles(undefined, undefined)
                }, domainNames.length !== 0 ? React.createElement($$Text.make, {
                        size: "Tiny",
                        lines: 3,
                        color: Styles.Color.light11,
                        children: null
                      }, React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                })
                          }, String(domainNames.length)), ": " + domainNames.join(", ")) : null));
}

var StakeholdersCell = {
  make: EventsSheet$EventRow$StakeholdersCell
};

function EventsSheet$EventRow$DescriptionCell(Props) {
  var columns = Props.columns;
  var groupedByCategory = Props.groupedByCategory;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var description = Props.description;
  return React.createElement("div", {
              className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "Description"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              title: description
            }, React.createElement("div", {
                  className: cellContentStyles(undefined, undefined)
                }, description.trim() === "" ? React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Semi",
                        color: Styles.Color.orange,
                        children: "No description"
                      }) : React.createElement($$Text.make, {
                        size: "Tiny",
                        lines: 3,
                        color: Styles.Color.light11,
                        children: description
                      })));
}

var DescriptionCell = {
  make: EventsSheet$EventRow$DescriptionCell
};

function EventsSheet$EventRow$Empty(Props) {
  var eventNameOpt = Props.eventName;
  var groupedByCategory = Props.groupedByCategory;
  var columns = Props.columns;
  var innerBorderColorOpt = Props.innerBorderColor;
  var outerBorderColorOpt = Props.outerBorderColor;
  var backgroundColorOpt = Props.backgroundColor;
  var eventName = eventNameOpt !== undefined ? eventNameOpt : "Empty Event";
  var innerBorderColor = innerBorderColorOpt !== undefined ? innerBorderColorOpt : Styles.Color.light04;
  var outerBorderColor = outerBorderColorOpt !== undefined ? outerBorderColorOpt : Styles.Color.light07;
  var backgroundColor = backgroundColorOpt !== undefined ? backgroundColorOpt : Styles.Color.white;
  return React.createElement("button", {
              className: rootStyles(false, false, groupedByCategory, outerBorderColor, backgroundColor, true)
            }, Belt_Array.mapU(columns, (function (column) {
                    var columnType = column.columnType;
                    if (columnType === "EventName") {
                      return React.createElement("div", {
                                  key: "EventName",
                                  className: stickyCellStyles(EventsSheet__Utils.getColumnWidthByType(columns, "EventName"), groupedByCategory, false, innerBorderColor, outerBorderColor, backgroundColor)
                                }, React.createElement($$Text.make, {
                                      size: "Medium",
                                      weight: "Medium",
                                      singleLine: true,
                                      color: Styles.Color.light07,
                                      maxWidth: Css.pct(100),
                                      children: eventName
                                    }));
                    } else {
                      return React.createElement("div", {
                                  key: SheetTypes.columnTypeToJs(columnType),
                                  className: cellStyles(EventsSheet__Utils.getColumnWidthByType(columns, columnType), false, false, groupedByCategory, innerBorderColor, outerBorderColor)
                                });
                    }
                  })));
}

var Empty = {
  make: EventsSheet$EventRow$Empty
};

function EventsSheet$EventRow(Props) {
  var allCategories = Props.allCategories;
  var allEventProperties = Props.allEventProperties;
  var allGroupPropertiesWithGroupTypeNames = Props.allGroupPropertiesWithGroupTypeNames;
  var allPropertyGroups = Props.allPropertyGroups;
  var allResolvedPropertyGroups = Props.allResolvedPropertyGroups;
  var allTags = Props.allTags;
  var columns = Props.columns;
  var currentFilters = Props.currentFilters;
  var $$event = Props.event;
  var isEventModified = Props.isEventModified;
  var eventLocation = Props.eventLocation;
  var globalSend = Props.globalSend;
  var groupedByCategory = Props.groupedByCategory;
  var model = Props.model;
  var modificationState = Props.modificationState;
  var onClick = Props.onClick;
  var viewerCanEdit = Props.viewerCanEdit;
  var schemaBundle = Props.schemaBundle;
  var selected = Props.selected;
  var sendActions = Props.sendActions;
  var withDetails = Props.withDetails;
  var innerBorderColorOpt = Props.innerBorderColor;
  var outerBorderColorOpt = Props.outerBorderColor;
  var backgroundColorOpt = Props.backgroundColor;
  var innerBorderColor = innerBorderColorOpt !== undefined ? innerBorderColorOpt : Styles.Color.light04;
  var outerBorderColor = outerBorderColorOpt !== undefined ? outerBorderColorOpt : Styles.Color.light07;
  var backgroundColor = backgroundColorOpt !== undefined ? backgroundColorOpt : Styles.Color.white;
  var addToast = Toast.useAddToast(undefined);
  var globalEvent = Curry._1(ModelStore.Mapped.GlobalRequirements.useGlobalEvent, $$event.id);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var hasBasicNameCasing = ValidationConfigV2.EventsV2.hasBasicNameCasing(match.config.events);
  var correctCase = React.useMemo((function () {
          if (hasBasicNameCasing) {
            return NameUtils.isCorrectCase(expectedEventCase, $$event.name);
          }
          
        }), [
        hasBasicNameCasing,
        expectedEventCase,
        $$event.name
      ]);
  var namingConvention = React.useMemo((function () {
          if (hasBasicNameCasing) {
            return Case.toAnalytics(Case.getCasingWithWorkspaceCase($$event.name, expectedEventCase));
          } else {
            return "Advanced";
          }
        }), [
        hasBasicNameCasing,
        $$event.name,
        expectedEventCase
      ]);
  var selectedPropertyGroups = React.useMemo((function () {
          return Belt_List.toArray(Belt_List.keepMapU($$event.propertyBundles, (function (group) {
                            return Belt_Array.getByU(allResolvedPropertyGroups, (function (modelGroup) {
                                          return modelGroup.id === group.id;
                                        }));
                          })));
        }), [
        $$event.propertyBundles,
        allPropertyGroups
      ]);
  var handleArchive = function (param) {
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (_branch) {
                    var match = Router.getStaticRoute(undefined);
                    var newDrawerItems = Belt_List.keep(match.drawerItems, (function (drawerItem) {
                            if (typeof drawerItem === "object" && drawerItem.NAME === "event") {
                              return drawerItem.VAL[0] !== $$event.id;
                            } else {
                              return true;
                            }
                          }));
                    return Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
                  }),
                (function (branch) {
                    Curry._1(addToast, {
                          message: "The event has been archived"
                        });
                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                    var partial_arg = $$event.name;
                    var partial_arg$1 = $$event.id;
                    var partial_arg$2 = schemaBundle$1.schemaType;
                    var partial_arg$3 = schemaBundle$1.schemaTrialPlanSlug;
                    var partial_arg$4 = schemaBundle$1.schemaSubscriptionPlanSlug;
                    var partial_arg$5 = schemaBundle$1.authenticationConfig;
                    var partial_arg$6 = schemaBundle$1.schemaTrialPlan;
                    var partial_arg$7 = schemaBundle$1.schemaSubscriptionPlan;
                    var partial_arg$8 = schemaBundle$1.branchName;
                    var partial_arg$9 = schemaBundle$1.branchId;
                    var partial_arg$10 = schemaBundle$1.schemaBillingStatus;
                    var partial_arg$11 = schemaBundle$1.schemaName;
                    var partial_arg$12 = schemaBundle$1.schemaId;
                    var func = function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12) {
                      return AnalyticsRe.eventRemoved(partial_arg$12, partial_arg$11, partial_arg$10, partial_arg$9, partial_arg$8, partial_arg$7, partial_arg$6, partial_arg$5, partial_arg$4, partial_arg$3, partial_arg$2, partial_arg$1, partial_arg, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12);
                    };
                    var arg = Belt_List.length($$event.directPropertyRefs);
                    var arg$1 = namingConvention;
                    var arg$2 = NamedBranch.getId(branch);
                    var arg$3 = schemaBundle$1.schemaId;
                    return AnalyticsUtils.withCurrentFilters((function (param) {
                                  var func$1 = Curry._1(func, param);
                                  return function (param) {
                                    var func$2 = Curry._1(func$1, param);
                                    return function (param) {
                                      var func$3 = Curry._1(func$2, param);
                                      return function (param) {
                                        var func$4 = Curry._1(func$3, param);
                                        return function (param) {
                                          var func$5 = Curry._1(func$4, param);
                                          return function (param) {
                                            var func$6 = Curry._1(func$5, param);
                                            return function (param) {
                                              var func$7 = Curry._1(func$6, param);
                                              return function (param) {
                                                return Curry._6(func$7, param, arg, correctCase, arg$1, arg$2, arg$3);
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                }), currentFilters);
                  }),
                undefined,
                [[
                    {
                      NAME: "Archive",
                      VAL: {
                        NAME: "Event",
                        VAL: $$event.id
                      }
                    },
                    {
                      eventId: $$event.id,
                      eventQuery: $$event.id
                    }
                  ]]
              ]);
  };
  return React.createElement("button", {
              className: rootStyles(selected, withDetails, groupedByCategory, outerBorderColor, backgroundColor, false),
              id: "events-sheet-row-" + Case.kebab($$event.name),
              tabIndex: -1,
              onClick: onClick
            }, Belt_Array.mapU(columns, (function (column) {
                    var columnType = column.columnType;
                    if (isColumnVisibleByType(columns, columnType)) {
                      if (columnType === "Description") {
                        return React.createElement(EventsSheet$EventRow$DescriptionCell, {
                                    columns: columns,
                                    groupedByCategory: groupedByCategory,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    description: globalEvent !== undefined && $$event.description === "" ? globalEvent.description : $$event.description,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "EventProperties") {
                        return React.createElement(EventsSheet$EventRow$EventPropertiesCell, {
                                    allEventProperties: allEventProperties,
                                    columns: columns,
                                    currentFilters: currentFilters,
                                    eventId: $$event.id,
                                    eventLocation: eventLocation,
                                    eventName: $$event.name,
                                    eventProperties: $$event.directPropertyRefs,
                                    globalSend: globalSend,
                                    groupedByCategory: groupedByCategory,
                                    model: model,
                                    propertyGroups: selectedPropertyGroups,
                                    viewerCanEdit: viewerCanEdit,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    schemaBundle: schemaBundle,
                                    sendActions: sendActions,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "Destinations") {
                        return React.createElement(EventsSheet$EventRow$DestinationsCell, {
                                    columns: columns,
                                    withDetails: withDetails,
                                    groupedByCategory: groupedByCategory,
                                    sources: model.sources,
                                    event: $$event,
                                    modelDestinations: model.destinations,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "PropertyGroups") {
                        return React.createElement(EventsSheet$EventRow$PropertyGroupsCell, {
                                    allPropertyGroups: allResolvedPropertyGroups,
                                    columns: columns,
                                    eventId: $$event.id,
                                    eventLocation: eventLocation,
                                    eventName: $$event.name,
                                    eventIncludeSources: $$event.includeSources,
                                    globalSend: globalSend,
                                    groupedByCategory: groupedByCategory,
                                    modelEvents: model.events,
                                    numTriggersOnEvent: $$event.triggers.length,
                                    viewerCanEdit: viewerCanEdit,
                                    selectedItems: selectedPropertyGroups,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    schemaBundle: schemaBundle,
                                    sendActions: sendActions,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "Metrics") {
                        return React.createElement(EventsSheet$EventRow$MetricsCell, {
                                    columns: columns,
                                    eventId: $$event.id,
                                    groupedByCategory: groupedByCategory,
                                    modelMetrics: model.metrics,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "Stakeholders") {
                        return React.createElement(EventsSheet$EventRow$StakeholdersCell, {
                                    columns: columns,
                                    eventId: $$event.id,
                                    groupedByCategory: groupedByCategory,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "Sources") {
                        return React.createElement(EventsSheet$EventRow$SourcesCell, {
                                    columns: columns,
                                    withDetails: withDetails,
                                    groupedByCategory: groupedByCategory,
                                    event: $$event,
                                    isEventModified: isEventModified,
                                    sources: model.sources,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "GroupProperties") {
                        return React.createElement(EventsSheet$EventRow$GroupPropertiesCell, {
                                    allGroupPropertiesWithGroupTypeNames: allGroupPropertiesWithGroupTypeNames,
                                    columns: columns,
                                    currentFilters: currentFilters,
                                    eventId: $$event.id,
                                    eventLocation: eventLocation,
                                    eventName: $$event.name,
                                    eventProperties: $$event.directPropertyRefs,
                                    globalSend: globalSend,
                                    groupedByCategory: groupedByCategory,
                                    model: model,
                                    viewerCanEdit: viewerCanEdit,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    schemaBundle: schemaBundle,
                                    sendActions: sendActions,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "Category") {
                        return React.createElement(EventsSheet$EventRow$CategoryCell, {
                                    allCategories: allCategories,
                                    columns: columns,
                                    eventId: $$event.id,
                                    eventLocation: eventLocation,
                                    eventName: $$event.name,
                                    globalSend: globalSend,
                                    groupedByCategory: groupedByCategory,
                                    viewerCanEdit: viewerCanEdit,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    schemaBundle: schemaBundle,
                                    sendActions: sendActions,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "EventName") {
                        return React.createElement(EventsSheet__NameCell.make, {
                                    currentFilters: currentFilters,
                                    columns: columns,
                                    eventDescription: globalEvent !== undefined && $$event.description === "" ? globalEvent.description : $$event.description,
                                    eventId: $$event.id,
                                    eventName: $$event.name,
                                    isGlobal: globalEvent !== undefined,
                                    groupedByCategory: groupedByCategory,
                                    modificationState: modificationState,
                                    selected: selected,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    backgroundColor: backgroundColor,
                                    schemaBundle: schemaBundle,
                                    viewerCanEdit: viewerCanEdit,
                                    styles: stickyCellStyles,
                                    handleArchive: handleArchive,
                                    sendActions: sendActions,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "Actions") {
                        return React.createElement(EventsSheet$EventRow$ActionsCell, {
                                    columns: columns,
                                    withDetails: withDetails,
                                    groupedByCategory: groupedByCategory,
                                    event: $$event,
                                    model: model,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else if (columnType === "Tags") {
                        return React.createElement(EventsSheet$EventRow$TagsCell, {
                                    allTags: allTags,
                                    columns: columns,
                                    eventId: $$event.id,
                                    eventLocation: eventLocation,
                                    eventName: $$event.name,
                                    groupedByCategory: groupedByCategory,
                                    viewerCanEdit: viewerCanEdit,
                                    tags: $$event.tags,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    schemaBundle: schemaBundle,
                                    sendActions: sendActions,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else {
                        return null;
                      }
                    } else {
                      return null;
                    }
                  })));
}

var EventRow = {
  rootStyles: rootStyles,
  stickyCellStyles: stickyCellStyles,
  cellStylesForGroupedByCategory: cellStylesForGroupedByCategory,
  cellStyles: cellStyles,
  cellContentStyles: cellContentStyles,
  isColumnVisibleByType: isColumnVisibleByType,
  EditableCategoriesCell: EditableCategoriesCell,
  CategoryCell: CategoryCell,
  EditablePropertyGroupsCell: EditablePropertyGroupsCell,
  PropertyGroupsCell: PropertyGroupsCell,
  EditableEventPropertiesCell: EditableEventPropertiesCell,
  EventPropertiesCell: EventPropertiesCell,
  EditableGroupPropertiesCell: EditableGroupPropertiesCell,
  GroupPropertiesCell: GroupPropertiesCell,
  SourcesCell: SourcesCell,
  ActionsCell: ActionsCell,
  EditableTagsCell: EditableTagsCell,
  TagsCell: TagsCell,
  DestinationsCell: DestinationsCell,
  MetricsCell: MetricsCell,
  StakeholdersCell: StakeholdersCell,
  DescriptionCell: DescriptionCell,
  Empty: Empty,
  make: EventsSheet$EventRow
};

var categoryLinkStyles = Curry._1(Css.style, {
      hd: Css.minWidth("zero"),
      tl: {
        hd: Css.color(Styles.Color.light11),
        tl: {
          hd: Css.transition({
                NAME: "ms",
                VAL: Styles.Duration.$$short
              }, undefined, undefined, "color"),
          tl: {
            hd: Css.hover({
                  hd: Css.color(Styles.Color.darkBlue),
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.paddingRight(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function EventsSheet$CategoryHeader(Props) {
  var category = Props.category;
  var columns = Props.columns;
  var sectionCount = Props.sectionCount;
  var schemaBundle = Props.schemaBundle;
  var match = BulkContext.use(undefined);
  var selectEventIds = match.selectEventIds;
  var toggleEventIds = match.toggleEventIds;
  var selectedEventIds = match.selectedEventIds;
  var name = category.name.trim();
  var categoryName = name === "" ? undefined : name;
  var categorySelectionSize = React.useMemo((function () {
          return Belt_SetString.size(Belt_SetString.intersect(selectedEventIds, Belt_SetString.fromArray(Belt_List.toArray(category.events))));
        }), [
        selectedEventIds,
        category.events
      ]);
  var tmp = {
    columns: columns,
    label: "CATEGORY",
    pillText: AvoUtils.plural(undefined, undefined, sectionCount, "event"),
    children: React.createElement(Link.make, {
          path: Router.Link.addDrawerItem(undefined, {
                NAME: "category",
                VAL: [
                  category.id,
                  undefined
                ]
              }),
          className: categoryLinkStyles,
          children: React.createElement($$Text.make, {
                element: Belt_Option.isSome(categoryName) ? "P" : "Em",
                size: "Medium",
                weight: "Semi",
                singleLine: true,
                color: Belt_Option.isSome(categoryName) ? Styles.Color.light11 : Styles.Color.light09,
                children: Belt_Option.getWithDefault(categoryName, "Untitled")
              })
        })
  };
  var tmp$1 = Belt_List.size(category.events) > 0 ? Caml_option.some(React.createElement(Mantine.Checkbox.make, {
              checked: categorySelectionSize === Belt_List.size(category.events) && categorySelectionSize !== 0,
              indeterminate: categorySelectionSize !== 0 && categorySelectionSize !== Belt_List.size(category.events),
              onChange: (function (param) {
                  var partial_arg = schemaBundle.schemaType;
                  var partial_arg$1 = schemaBundle.schemaTrialPlanSlug;
                  var partial_arg$2 = schemaBundle.schemaSubscriptionPlanSlug;
                  var partial_arg$3 = schemaBundle.authenticationConfig;
                  var partial_arg$4 = schemaBundle.schemaTrialPlan;
                  var partial_arg$5 = schemaBundle.schemaSubscriptionPlan;
                  var partial_arg$6 = schemaBundle.branchName;
                  var partial_arg$7 = schemaBundle.branchId;
                  var partial_arg$8 = schemaBundle.schemaBillingStatus;
                  var partial_arg$9 = schemaBundle.schemaName;
                  var partial_arg$10 = schemaBundle.schemaId;
                  var func = function (param, param$1, param$2, param$3) {
                    return AnalyticsRe.bulkEditingInteraction(partial_arg$10, partial_arg$9, partial_arg$8, partial_arg$7, partial_arg$6, partial_arg$5, partial_arg$4, partial_arg$3, partial_arg$2, partial_arg$1, partial_arg, param, param$1, param$2, param$3);
                  };
                  var arg = schemaBundle.schemaId;
                  var arg$1 = schemaBundle.branchId;
                  var track = function (param) {
                    var func$1 = Curry._1(func, param);
                    return function (param) {
                      return Curry._3(func$1, param, arg, arg$1);
                    };
                  };
                  if (categorySelectionSize === Belt_List.size(category.events)) {
                    Curry._1(toggleEventIds, Belt_List.toArray(category.events));
                    return track("MultiMinusdeMinusselectFromCategory")(Belt_SetString.size(Belt_SetString.removeMany(selectedEventIds, Belt_List.toArray(category.events))));
                  } else {
                    Curry._1(selectEventIds, Belt_List.toArray(category.events));
                    return track("MultiMinusselectFromWorkbench")(Belt_SetString.size(Belt_SetString.union(Belt_SetString.fromArray(Belt_List.toArray(category.events)), selectedEventIds)));
                  }
                }),
              size: "xs"
            })) : undefined;
  if (tmp$1 !== undefined) {
    tmp.checkbox = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(SheetSectionHeader.make, tmp);
}

var CategoryHeader = {
  categoryLinkStyles: categoryLinkStyles,
  make: EventsSheet$CategoryHeader
};

var containerStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(270)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: {
            hd: Css.boxShadow(Styles.Shadow.subtle),
            tl: /* [] */0
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.padding(Css.px(16)),
          tl: {
            hd: Css.paddingLeft(Css.px(32)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: /* [] */0
            }
          }
        }
      }
    });

var headerWrapperStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.marginBottom(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

function listStyles(itemsCount) {
  return Curry._1(Css.style, {
              hd: Css.padding("zero"),
              tl: {
                hd: Css.paddingTop(Css.px(16)),
                tl: {
                  hd: Css.margin("zero"),
                  tl: {
                    hd: Css.listStyleType("none"),
                    tl: {
                      hd: Css.textIndent("zero"),
                      tl: {
                        hd: Css.height(Css.px((16 + Math.imul(itemsCount, 28) | 0) + 16 | 0)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function listItemStyles(index, itemsCount, drag) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.height(Css.px(28)),
                  tl: {
                    hd: Css.userSelect("none"),
                    tl: {
                      hd: Css.paddingLeft(Css.px(14)),
                      tl: {
                        hd: Css.paddingRight(Css.px(16)),
                        tl: {
                          hd: Css.transitions({
                                hd: Css_Legacy_Core.Transition.shorthand({
                                      NAME: "ms",
                                      VAL: Belt_Option.isSome(drag) ? Styles.Duration.$$short : 0.0
                                    }, undefined, undefined, "transform"),
                                tl: {
                                  hd: Css_Legacy_Core.Transition.shorthand({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$short
                                      }, undefined, undefined, "background-color"),
                                  tl: {
                                    hd: Css_Legacy_Core.Transition.shorthand({
                                          NAME: "ms",
                                          VAL: Belt_Option.isSome(drag) ? Styles.Duration.$$short : 0.0
                                        }, undefined, undefined, "margin-top"),
                                    tl: {
                                      hd: Css_Legacy_Core.Transition.shorthand({
                                            NAME: "ms",
                                            VAL: Belt_Option.isSome(drag) ? Styles.Duration.$$short : 0.0
                                          }, undefined, undefined, "margin-bottom"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }),
                          tl: {
                            hd: Css.selector(" path", {
                                  hd: Css.transition({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$short
                                      }, undefined, undefined, "fill"),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.selector(" svg", {
                                    hd: Css.opacity(index === 0 ? 0.2 : 1.0),
                                    tl: {
                                      hd: Css.cursor(index === 0 ? "notAllowed" : "grab"),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: Css.backgroundColor(Belt_Option.mapWithDefault(drag, "transparent", (function (drag) {
                                            if (drag.index === index) {
                                              return Styles.Color.light02;
                                            } else {
                                              return "transparent";
                                            }
                                          }))),
                                tl: {
                                  hd: Css.active({
                                        hd: Css.backgroundColor(Styles.Color.light02),
                                        tl: {
                                          hd: Css.cursor(index === 0 ? "notAllowed" : "grabbing"),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: {
                                    hd: Css.marginTop(Css.px(Belt_Option.mapWithDefault(drag, 0, (function (drag) {
                                                    var match = drag.direction;
                                                    if (index === 0 || match !== 1) {
                                                      return 0;
                                                    } else if (index === (drag.index + 1 | 0) && drag.toPos >= index) {
                                                      return -28;
                                                    } else if (index !== drag.index && index === (drag.toPos + 1 | 0)) {
                                                      return 28;
                                                    } else {
                                                      return 0;
                                                    }
                                                  })))),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.selector(" path", index === 0 ? /* [] */0 : ({
                                                      hd: Css_Legacy_Core.SVG.fill(Styles.Color.light08),
                                                      tl: /* [] */0
                                                    })),
                                            tl: {
                                              hd: Css.cursor(index === 0 ? "notAllowed" : "grab"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.marginBottom(Css.px(Belt_Option.mapWithDefault(drag, 0, (function (drag) {
                                                        var match = drag.direction;
                                                        switch (match) {
                                                          case /* Up */0 :
                                                              if (index === (drag.index - 1 | 0) && drag.toPos <= index) {
                                                                return -28;
                                                              } else if (index !== drag.index && index === (drag.toPos - 1 | 0)) {
                                                                return 28;
                                                              } else {
                                                                return 0;
                                                              }
                                                          case /* Down */1 :
                                                              if (drag.toPos === (itemsCount - 1 | 0) && index === (itemsCount - 1 | 0)) {
                                                                return 28;
                                                              } else {
                                                                return 0;
                                                              }
                                                          case /* Same */2 :
                                                              return 0;
                                                          
                                                        }
                                                      })))),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var resetButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("inlineFlex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.color(Styles.Color.darkBlue),
                    tl: {
                      hd: Css.fontSize(Css.px(10)),
                      tl: {
                        hd: Css.letterSpacing(Css.em(0.01)),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.color(Styles.Color.deepBlue),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.active({
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.cursor("pointer"),
                                  tl: {
                                    hd: Css.focus({
                                          hd: Css.outlineStyle("none"),
                                          tl: {
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Css.backgroundColor("transparent"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function EventsSheet$CustomizeButtonWithMenu(Props) {
  var columns = Props.columns;
  var setColumns = Props.setColumns;
  var resetColumns = Props.resetColumns;
  var schemaBundle = Props.schemaBundle;
  var buttonRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setShowMenu = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setDrag = match$1[1];
  var drag = match$1[0];
  var hasInlineCreationFeatureFlag = AppFeatureFlag.useFeatureFlag("InlineEventCreation");
  var onVisibilitySwitch = React.useCallback((function (column) {
          return function (value) {
            return Curry._1(setColumns, (function (columns) {
                          var newColumns = Belt_Array.map(columns, (function (c) {
                                  if (c.columnType === column.columnType) {
                                    return {
                                            columnType: c.columnType,
                                            width: c.width,
                                            order: c.order,
                                            visible: value
                                          };
                                  } else {
                                    return c;
                                  }
                                }));
                          AnalyticsRe.eventsViewConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ColumnVisibility", column.visible ? "ShowColumn" : "HideColumn", AnalyticsUtils.columnTypeToEventColumnsName(column.columnType), Belt_Array.keepMapU(Belt_Array.keepU(newColumns, (function (column) {
                                          return column.visible;
                                        })), (function (column) {
                                      return AnalyticsUtils.columnTypeToEventColumnsName(column.columnType);
                                    })), Belt_Array.keepU(newColumns, (function (column) {
                                      return column.visible;
                                    })).length, schemaBundle.branchId, schemaBundle.schemaId);
                          return newColumns;
                        }));
          };
        }), [columns]);
  var onStop = function (param, param$1) {
    var node = param$1.node;
    Belt_Option.forEach(drag, (function (drag) {
            var match = drag.direction;
            var reorderedColumns = match >= 2 ? columns : Belt_Array.mapWithIndexU(ArrayExt.reinsertIndex(columns, drag.index, drag.toPos), (function (index, c) {
                      return {
                              columnType: c.columnType,
                              width: c.width,
                              order: index,
                              visible: c.visible
                            };
                    }));
            node.style.transform = "translate(0px, 0px)";
            AnalyticsRe.eventsViewConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ColumnVisibility", "Reorder", Belt_Option.flatMap(Belt_Array.get(columns, drag.index), (function (column) {
                        return AnalyticsUtils.columnTypeToEventColumnsName(column.columnType);
                      })), Belt_Array.keepMapU(Belt_Array.keepU(reorderedColumns, (function (column) {
                            return column.visible;
                          })), (function (column) {
                        return AnalyticsUtils.columnTypeToEventColumnsName(column.columnType);
                      })), Belt_Array.keepU(reorderedColumns, (function (column) {
                        return column.visible;
                      })).length, schemaBundle.branchId, schemaBundle.schemaId);
            return Curry._1(setColumns, (function (param) {
                          return reorderedColumns;
                        }));
          }));
    return Curry._1(setDrag, (function (param) {
                  
                }));
  };
  return React.createElement("span", {
              id: "sheetview-tour-customize"
            }, React.createElement(Button.make, {
                  icon: "table",
                  label: "Customize",
                  onClick: (function (param) {
                      return Curry._1(setShowMenu, (function (state) {
                                    return !state;
                                  }));
                    }),
                  style: "ghost",
                  ref: buttonRef
                }), React.createElement(PortalMenu.make, {
                  position: "Bottom",
                  visible: match[0],
                  onClose: (function (param) {
                      return Curry._1(setShowMenu, (function (param) {
                                    return false;
                                  }));
                    }),
                  referenceItemRef: buttonRef,
                  children: React.createElement("aside", {
                        className: containerStyles
                      }, React.createElement("header", {
                            className: headerStyles
                          }, React.createElement("span", {
                                className: headerWrapperStyles
                              }, React.createElement($$Text.make, {
                                    element: "H1",
                                    size: "Medium",
                                    weight: "Medium",
                                    color: Styles.Color.light12,
                                    children: "Customize Columns"
                                  }), React.createElement(FramerMotion.AnimatePresence, {
                                    children: Caml_obj.caml_notequal(defaultColumns(hasInlineCreationFeatureFlag), columns) ? React.createElement(FramerMotion.motion.div, {
                                            animate: {
                                              opacity: 1
                                            },
                                            transition: {
                                              duration: 0.2
                                            },
                                            initial: {
                                              opacity: 0
                                            },
                                            exit: {
                                              opacity: 0
                                            },
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: /* [] */0
                                                }),
                                            key: "reset",
                                            children: React.createElement("button", {
                                                  className: resetButtonStyles,
                                                  onClick: resetColumns
                                                }, "RESET")
                                          }) : null
                                  })), React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.light10,
                                children: "Personalize the columns configuration to highlight what you think is most important"
                              })), React.createElement("ul", {
                            className: listStyles(columns.length)
                          }, Belt_Array.mapWithIndexU(columns, (function (index, column) {
                                  var tmp = {
                                    axis: "y",
                                    onDrag: (function (param, param$1) {
                                        var toPos = index + (param$1.y / 28 | 0) | 0;
                                        var direction = index < toPos ? /* Down */1 : (
                                            index > toPos ? /* Up */0 : /* Same */2
                                          );
                                        var newDrag = {
                                          index: index,
                                          toPos: toPos,
                                          direction: direction
                                        };
                                        if (Caml_obj.caml_notequal(drag, newDrag)) {
                                          return Curry._1(setDrag, (function (param) {
                                                        return newDrag;
                                                      }));
                                        }
                                        
                                      }),
                                    onStop: onStop,
                                    defaultClassNameDragging: Curry._1(Css.style, {
                                          hd: Css.hover({
                                                hd: Css.cursor("grabbing"),
                                                tl: /* [] */0
                                              }),
                                          tl: {
                                            hd: Css.active({
                                                  hd: Css.cursor("grabbing"),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    bounds: {
                                      left: 0,
                                      top: Math.imul(Math.max(0, index - 1 | 0), -28),
                                      right: 0,
                                      bottom: Math.imul((columns.length - 1 | 0) - index | 0, 28)
                                    },
                                    grid: [
                                      28,
                                      28
                                    ],
                                    disabled: column.columnType === "EventName",
                                    children: React.createElement("li", {
                                          className: listItemStyles(index, columns.length, drag)
                                        }, React.createElement(Icons.Dragger.make, {}), React.createElement(Spacer.make, {
                                              width: 11
                                            }), React.createElement($$Text.make, {
                                              size: "Medium",
                                              weight: "Medium",
                                              singleLine: true,
                                              color: Styles.Color.light10,
                                              children: SheetTypes.columnTypeToJs(column.columnType)
                                            }), React.createElement(Spacer.make, {
                                              width: 8,
                                              grow: 1.0
                                            }), React.createElement(Switch.make, {
                                              value: column.visible,
                                              onChange: Curry._1(onVisibilitySwitch, column),
                                              size: "Small",
                                              disabled: column.columnType === "EventName"
                                            })),
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  };
                                  var tmp$1 = Belt_Option.isSome(drag) ? undefined : ({
                                        x: undefined,
                                        y: 0
                                      });
                                  if (tmp$1 !== undefined) {
                                    tmp.position = Caml_option.valFromOption(tmp$1);
                                  }
                                  return React.createElement(ReactDraggable, tmp);
                                }))))
                }));
}

var CustomizeButtonWithMenu = {
  listItemHeight: 28,
  containerStyles: containerStyles,
  headerStyles: headerStyles,
  headerWrapperStyles: headerWrapperStyles,
  listStyles: listStyles,
  listItemStyles: listItemStyles,
  resetButtonStyles: resetButtonStyles,
  make: EventsSheet$CustomizeButtonWithMenu
};

var headerStyles$1 = Curry._1(Css.style, {
      hd: Css.height(Css.px(64)),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.paddingLeft(Css.px(30)),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var emptyFilteredEvents = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.px(64)),
        tl: {
          hd: Css.top(Css.pct(40.0)),
          tl: {
            hd: Css.zIndex(1),
            tl: /* [] */0
          }
        }
      }
    });

function stringCompare(a, b) {
  var x = a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
  if (x > 0.0) {
    return 1;
  } else if (x < 0.0) {
    return -1;
  } else {
    return 0;
  }
}

function getEventModificationStatus(directEventChanges, eventId) {
  if (directEventChanges === undefined) {
    return /* Unmodified */0;
  }
  var match = Belt_MapString.get(directEventChanges.new, eventId);
  var match$1 = Belt_MapString.get(directEventChanges.changed, eventId);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return /* Unmodified */0;
    } else {
      return /* New */2;
    }
  } else if (match$1 !== undefined) {
    return /* Modified */1;
  } else {
    return /* Unmodified */0;
  }
}

function isEventModified(directEventChanges, eventId) {
  return getEventModificationStatus(directEventChanges, eventId) !== /* Unmodified */0;
}

function organizeEventsAsList(directEventChanges, branchModel, events, workbenchLoaded, hasInlineCreationFeatureFlag, workbenchEventOrder) {
  var allEventRows = [];
  var modifiedEventRowsMap = Belt_MutableMapString.fromArray([]);
  Belt_Array.forEachWithIndexU(events, (function (index, $$event) {
          var modificationStatus = getEventModificationStatus(directEventChanges, $$event.id);
          if (modificationStatus !== /* Unmodified */0) {
            Belt_MutableMapString.set(modifiedEventRowsMap, $$event.id, {
                  TAG: /* WorkbenchEvent */1,
                  _0: index,
                  _1: $$event,
                  _2: modificationStatus
                });
          }
          allEventRows.push({
                TAG: /* Event */2,
                _0: index,
                _1: undefined,
                _2: $$event,
                _3: modificationStatus
              });
          
        }));
  var modifiedEventRows = workbenchEventOrder !== undefined ? Belt_Array.keepMapU(workbenchEventOrder, (function (eventId) {
            var modifiedEvent = Belt_MutableMapString.get(modifiedEventRowsMap, eventId);
            if (modifiedEvent !== undefined) {
              Belt_MutableMapString.remove(modifiedEventRowsMap, eventId);
              return modifiedEvent;
            }
            
          })) : Belt_SortArray.stableSortByU(Belt_MutableMapString.valuesToArray(modifiedEventRowsMap), (function (a, b) {
            if (typeof a === "number" || !(a.TAG === /* WorkbenchEvent */1 && !(typeof b === "number" || b.TAG !== /* WorkbenchEvent */1))) {
              return 0;
            } else {
              return $$String.compare(a._1.name, b._1.name);
            }
          }));
  if (Belt_MutableMapString.size(modifiedEventRowsMap) > 0) {
    Caml_splice_call.spliceObjApply(modifiedEventRows, "push", [Belt_MutableMapString.valuesToArray(modifiedEventRowsMap)]);
  }
  var workbenchSection;
  if (branchModel.TAG === /* Main */0) {
    workbenchSection = undefined;
  } else {
    var match = getGetNewAndChangedRowCounts(modifiedEventRows);
    workbenchSection = Belt_Array.concatMany([
          [{
              TAG: /* WorkbenchHeader */0,
              _0: match[0],
              _1: match[1],
              _2: workbenchLoaded
            }],
          modifiedEventRows,
          hasInlineCreationFeatureFlag ? [/* NewEvent */2] : []
        ]);
  }
  return Belt_Array.concat(Belt_Option.getWithDefault(workbenchSection, []), allEventRows);
}

function organizeEventsByCategory(directEventChanges, model, branchModel, events, filters, workbenchLoaded, hasInlineCreationFeatureFlag, workbenchEventOrder) {
  var allEventsMap = Belt_MutableMapString.fromArray([]);
  var modifiedEventRowsMap = Belt_MutableMapString.fromArray([]);
  Belt_Array.forEachWithIndexU(events, (function (index, $$event) {
          var modificationStatus = getEventModificationStatus(directEventChanges, $$event.id);
          if (modificationStatus !== /* Unmodified */0) {
            Belt_MutableMapString.set(modifiedEventRowsMap, $$event.id, {
                  TAG: /* WorkbenchEvent */1,
                  _0: index,
                  _1: $$event,
                  _2: modificationStatus
                });
          }
          return Belt_MutableMapString.set(allEventsMap, $$event.id, {
                      TAG: /* Event */2,
                      _0: index,
                      _1: undefined,
                      _2: $$event,
                      _3: modificationStatus
                    });
        }));
  var workbenchSection;
  if (branchModel.TAG === /* Main */0) {
    workbenchSection = undefined;
  } else {
    var modifiedEventRows = workbenchEventOrder !== undefined ? Belt_Array.keepMapU(workbenchEventOrder, (function (eventId) {
              var modifiedEvent = Belt_MutableMapString.get(modifiedEventRowsMap, eventId);
              if (modifiedEvent !== undefined) {
                Belt_MutableMapString.remove(modifiedEventRowsMap, eventId);
                return modifiedEvent;
              }
              
            })) : Belt_SortArray.stableSortByU(Belt_MutableMapString.valuesToArray(modifiedEventRowsMap), (function (a, b) {
              if (typeof a === "number" || !(a.TAG === /* WorkbenchEvent */1 && !(typeof b === "number" || b.TAG !== /* WorkbenchEvent */1))) {
                return 0;
              } else {
                return $$String.compare(a._1.name, b._1.name);
              }
            }));
    if (workbenchEventOrder !== undefined && Belt_MutableMapString.size(modifiedEventRowsMap) > 0) {
      Caml_splice_call.spliceObjApply(modifiedEventRows, "push", [Belt_MutableMapString.valuesToArray(modifiedEventRowsMap)]);
    }
    var match = getGetNewAndChangedRowCounts(modifiedEventRows);
    workbenchSection = Belt_Array.concatMany([
          [{
              TAG: /* WorkbenchHeader */0,
              _0: match[0],
              _1: match[1],
              _2: workbenchLoaded
            }],
          modifiedEventRows,
          hasInlineCreationFeatureFlag ? [/* NewEvent */2] : [],
          [/* WorkbenchFooter */0]
        ]);
  }
  var uncategorizedEventsSet = Belt_MutableSetString.fromArray(Belt_MutableMapString.keysToArray(allEventsMap));
  var categorizedEventSections = Belt_Array.concatMany(Belt_Array.keepMapU(Belt_SortArray.stableSortByU(Belt_List.toArray(model.goals), (function (param, param$1) {
                  return $$String.compare(param.name, param$1.name);
                })), (function (category) {
              if (Belt_List.size(category.events) <= 0) {
                return ;
              }
              var eventIds = [];
              var events = Belt_Array.keepMapU(Belt_List.toArray(category.events), (function (eventId) {
                      Belt_MutableSetString.remove(uncategorizedEventsSet, eventId);
                      var $$event = Belt_MutableMapString.get(allEventsMap, eventId);
                      if ($$event !== undefined) {
                        eventIds.push(eventId);
                        return $$event;
                      }
                      
                    }));
              return Belt_Array.concatMany([
                          [{
                              TAG: /* CategoryHeader */4,
                              _0: {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: category.metrics,
                                events: Belt_List.fromArray(eventIds)
                              },
                              _1: events.length
                            }],
                          events,
                          [/* CategoryFooter */1]
                        ]);
            })));
  var uncategorizedEvents = filters.length === 0 ? Belt_SortArray.stableSortByU(Belt_Array.keepMapU(Belt_MutableSetString.toArray(uncategorizedEventsSet), (function (eventId) {
                return Belt_MutableMapString.get(allEventsMap, eventId);
              })), (function (a, b) {
            if (typeof a === "number" || !(a.TAG === /* Event */2 && !(typeof b === "number" || b.TAG !== /* Event */2))) {
              return 0;
            } else {
              return $$String.compare(a._2.name, b._2.name);
            }
          })) : [];
  var uncategorizedEventsSection = uncategorizedEvents.length === 0 ? undefined : Belt_Array.concatMany([
          [{
              TAG: /* UncategorizedHeader */3,
              _0: Belt_MutableSetString.toArray(uncategorizedEventsSet)
            }],
          uncategorizedEvents,
          [/* CategoryFooter */1]
        ]);
  return Belt_Array.concatMany([
              Belt_Option.getWithDefault(workbenchSection, []),
              Belt_Option.getWithDefault(uncategorizedEventsSection, []),
              categorizedEventSections
            ]);
}

function deriveColumnsFromServer(hasInlineCreationFeatureFlag, fromServer) {
  if (fromServer === undefined) {
    return defaultColumns(hasInlineCreationFeatureFlag);
  }
  var columnSettingsFromServer = Caml_option.valFromOption(fromServer);
  return Belt_List.toArray(Belt_List.sort(Belt_List.map(Belt_List.fromArray(defaultColumns(hasInlineCreationFeatureFlag)), (function (defaultColumn) {
                        return Belt_Option.mapWithDefault(Js_dict.get(columnSettingsFromServer, SheetTypes.columnTypeToJs(defaultColumn.columnType)), defaultColumn, (function (columnFromSettingsFromServer) {
                                      return {
                                              columnType: defaultColumn.columnType,
                                              width: columnFromSettingsFromServer.width,
                                              order: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(columnFromSettingsFromServer.order), defaultColumn.order),
                                              visible: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(columnFromSettingsFromServer.visible), true)
                                            };
                                    }));
                      })), (function (a, b) {
                    return Caml.caml_int_compare(a.order, b.order);
                  })));
}

function updateColumnSettingsOnServer(viewerId, columns) {
  var columnSettings = Js_dict.fromArray(Belt_Array.mapU(columns, (function (column) {
              return [
                      SheetTypes.columnTypeToJs(column.columnType),
                      {
                        width: column.width,
                        visible: Js_null_undefined.fromOption(column.visible),
                        order: Js_null_undefined.fromOption(column.order)
                      }
                    ];
            })));
  return FirebaseUtils.setColumnSettings(viewerId, columnSettings);
}

function EventsSheet$WorkbenchHeader(Props) {
  var newEventIds = Props.newEventIds;
  var modifiedEventIds = Props.modifiedEventIds;
  var loaded = Props.loaded;
  var columns = Props.columns;
  var branchName = Props.branchName;
  var view = Props.view;
  var schemaBundle = Props.schemaBundle;
  var match = BulkContext.use(undefined);
  var selectEventIds = match.selectEventIds;
  var toggleEventIds = match.toggleEventIds;
  var selectedEventIds = match.selectedEventIds;
  var pillText;
  var exit = 0;
  if (loaded) {
    var len = newEventIds.length;
    if (len !== 1) {
      if (len !== 0) {
        exit = 1;
      } else {
        var len$1 = modifiedEventIds.length;
        if (len$1 !== 1) {
          if (len$1 !== 0) {
            exit = 1;
          } else {
            pillText = "0 new or changed events";
          }
        } else {
          pillText = "1 changed event";
        }
      }
    } else if (modifiedEventIds.length !== 0) {
      exit = 1;
    } else {
      pillText = "1 new event";
    }
  } else {
    pillText = "Loading...";
  }
  if (exit === 1) {
    pillText = modifiedEventIds.length !== 0 ? (
        newEventIds.length !== 0 ? (
            modifiedEventIds.length !== 1 ? String(newEventIds.length) + " new and " + String(modifiedEventIds.length) + " changed events" : String(newEventIds.length) + " new and 1 changed event"
          ) : String(modifiedEventIds.length) + " changed events"
      ) : String(newEventIds.length) + " new events";
  }
  var allEventIds = React.useMemo((function () {
          return Belt_Array.concat(newEventIds, modifiedEventIds);
        }), [
        newEventIds,
        modifiedEventIds
      ]);
  var workbenchSelectionSize = React.useMemo((function () {
          return Belt_SetString.size(Belt_SetString.intersect(selectedEventIds, Belt_SetString.fromArray(allEventIds)));
        }), [
        selectedEventIds,
        allEventIds
      ]);
  var tmp = {
    columns: columns,
    label: GlobalRequirementsUtils.getNormalizedName(branchName),
    pillText: pillText,
    view: view,
    tint: "Purple",
    children: React.createElement("span", {
          className: Curry._1(Css.style, {
                hd: Css.paddingRight(Css.px(8)),
                tl: /* [] */0
              })
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              singleLine: true,
              color: Styles.Color.primaryPurpleShade2,
              children: "Workbench"
            }))
  };
  var tmp$1 = allEventIds.length !== 0 ? Caml_option.some(React.createElement(Mantine.Checkbox.make, {
              checked: workbenchSelectionSize === allEventIds.length && workbenchSelectionSize !== 0,
              indeterminate: workbenchSelectionSize !== 0 && workbenchSelectionSize !== allEventIds.length,
              onChange: (function (param) {
                  var partial_arg = schemaBundle.schemaType;
                  var partial_arg$1 = schemaBundle.schemaTrialPlanSlug;
                  var partial_arg$2 = schemaBundle.schemaSubscriptionPlanSlug;
                  var partial_arg$3 = schemaBundle.authenticationConfig;
                  var partial_arg$4 = schemaBundle.schemaTrialPlan;
                  var partial_arg$5 = schemaBundle.schemaSubscriptionPlan;
                  var partial_arg$6 = schemaBundle.branchName;
                  var partial_arg$7 = schemaBundle.branchId;
                  var partial_arg$8 = schemaBundle.schemaBillingStatus;
                  var partial_arg$9 = schemaBundle.schemaName;
                  var partial_arg$10 = schemaBundle.schemaId;
                  var func = function (param, param$1, param$2, param$3) {
                    return AnalyticsRe.bulkEditingInteraction(partial_arg$10, partial_arg$9, partial_arg$8, partial_arg$7, partial_arg$6, partial_arg$5, partial_arg$4, partial_arg$3, partial_arg$2, partial_arg$1, partial_arg, param, param$1, param$2, param$3);
                  };
                  var arg = schemaBundle.schemaId;
                  var arg$1 = schemaBundle.branchId;
                  var track = function (param) {
                    var func$1 = Curry._1(func, param);
                    return function (param) {
                      return Curry._3(func$1, param, arg, arg$1);
                    };
                  };
                  if (workbenchSelectionSize === allEventIds.length) {
                    Curry._1(toggleEventIds, allEventIds);
                    return track("MultiMinusdeMinusselectFromWorkbench")(Belt_SetString.size(Belt_SetString.removeMany(selectedEventIds, allEventIds)));
                  } else {
                    Curry._1(selectEventIds, allEventIds);
                    return track("MultiMinusselectFromCategory")(Belt_SetString.size(Belt_SetString.union(Belt_SetString.fromArray(allEventIds), selectedEventIds)));
                  }
                }),
              size: "xs"
            })) : undefined;
  if (tmp$1 !== undefined) {
    tmp.checkbox = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(SheetSectionHeader.make, tmp);
}

var WorkbenchHeader = {
  make: EventsSheet$WorkbenchHeader
};

function EventsSheet$UncategorizedHeader(Props) {
  var columns = Props.columns;
  var sectionEventIds = Props.sectionEventIds;
  var schemaBundle = Props.schemaBundle;
  var match = BulkContext.use(undefined);
  var selectEventIds = match.selectEventIds;
  var toggleEventIds = match.toggleEventIds;
  var selectedEventIds = match.selectedEventIds;
  var selectionSize = React.useMemo((function () {
          return Belt_SetString.size(Belt_SetString.intersect(selectedEventIds, Belt_SetString.fromArray(sectionEventIds)));
        }), [
        selectedEventIds,
        sectionEventIds
      ]);
  var tmp = {
    columns: columns,
    label: "CATEGORY",
    pillText: AvoUtils.plural(undefined, undefined, sectionEventIds.length, "event"),
    children: React.createElement("span", {
          className: Curry._1(Css.style, {
                hd: Css.paddingRight(Css.px(8)),
                tl: /* [] */0
              })
        }, React.createElement($$Text.make, {
              element: "Em",
              size: "Medium",
              weight: "Semi",
              singleLine: true,
              color: Styles.Color.light08,
              children: "Uncategorized"
            }))
  };
  var tmp$1 = sectionEventIds.length !== 0 ? Caml_option.some(React.createElement(Mantine.Checkbox.make, {
              checked: selectionSize === sectionEventIds.length && selectionSize !== 0,
              indeterminate: selectionSize !== 0 && selectionSize !== sectionEventIds.length,
              onChange: (function (param) {
                  var partial_arg = schemaBundle.schemaType;
                  var partial_arg$1 = schemaBundle.schemaTrialPlanSlug;
                  var partial_arg$2 = schemaBundle.schemaSubscriptionPlanSlug;
                  var partial_arg$3 = schemaBundle.authenticationConfig;
                  var partial_arg$4 = schemaBundle.schemaTrialPlan;
                  var partial_arg$5 = schemaBundle.schemaSubscriptionPlan;
                  var partial_arg$6 = schemaBundle.branchName;
                  var partial_arg$7 = schemaBundle.branchId;
                  var partial_arg$8 = schemaBundle.schemaBillingStatus;
                  var partial_arg$9 = schemaBundle.schemaName;
                  var partial_arg$10 = schemaBundle.schemaId;
                  var func = function (param, param$1, param$2, param$3) {
                    return AnalyticsRe.bulkEditingInteraction(partial_arg$10, partial_arg$9, partial_arg$8, partial_arg$7, partial_arg$6, partial_arg$5, partial_arg$4, partial_arg$3, partial_arg$2, partial_arg$1, partial_arg, param, param$1, param$2, param$3);
                  };
                  var arg = schemaBundle.schemaId;
                  var arg$1 = schemaBundle.branchId;
                  var track = function (param) {
                    var func$1 = Curry._1(func, param);
                    return function (param) {
                      return Curry._3(func$1, param, arg, arg$1);
                    };
                  };
                  if (selectionSize === sectionEventIds.length) {
                    Curry._1(toggleEventIds, sectionEventIds);
                    return track("MultiMinusdeMinusselectFromCategory")(Belt_SetString.size(Belt_SetString.removeMany(selectedEventIds, sectionEventIds)));
                  } else {
                    Curry._1(selectEventIds, sectionEventIds);
                    return track("MultiMinusselectFromCategory")(Belt_SetString.size(Belt_SetString.union(Belt_SetString.fromArray(sectionEventIds), selectedEventIds)));
                  }
                }),
              size: "xs"
            })) : undefined;
  if (tmp$1 !== undefined) {
    tmp.checkbox = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(SheetSectionHeader.make, tmp);
}

var UncategorizedHeader = {
  make: EventsSheet$UncategorizedHeader
};

function EventsSheet$ReactWindowRow(Props) {
  var index = Props.index;
  var style = Props.style;
  var data = Props.data;
  var id = index === 0 ? "sheetview-tour-row" : undefined;
  var match = Belt_Array.getExn(data, index);
  var view = match.view;
  var sendActions = match.sendActions;
  var schemaBundle = match.schemaBundle;
  var viewerCanEdit = match.viewerCanEdit;
  var branchModel = match.branchModel;
  var globalSend = match.globalSend;
  var details = match.details;
  var currentFilters = match.currentFilters;
  var columns = match.columns;
  var item = match.item;
  var allTags = match.allTags;
  var allResolvedPropertyGroups = match.allResolvedPropertyGroups;
  var allPropertyGroups = match.allPropertyGroups;
  var allGroupPropertiesWithGroupTypeNames = match.allGroupPropertiesWithGroupTypeNames;
  var allEventProperties = match.allEventProperties;
  var allCategories = match.allCategories;
  var onEventClickRow = React.useCallback((function (eventId) {
          return function (param) {
            if (details !== undefined && details === eventId) {
              return Router.Schema.popDrawerItem(undefined, undefined);
            }
            return Router.Schema.pushDrawerItem(undefined, undefined, {
                        NAME: "event",
                        VAL: [
                          eventId,
                          undefined,
                          undefined,
                          false
                        ]
                      });
          };
        }), [details]);
  var tmp = {
    style: {
      height: style.height,
      left: style.left,
      position: style.position,
      top: (style.top + (
            view === /* ByCategory */0 ? 16 : 0
          ) | 0).toString() + "px",
      width: style.width
    }
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  var tmp$1;
  if (typeof item === "number") {
    switch (item) {
      case /* WorkbenchFooter */0 :
          tmp$1 = React.createElement(SheetSectionFooter.make, {
                columns: columns,
                tints: [
                  Styles.Color.palePurple,
                  Styles.Color.lightPurple
                ]
              });
          break;
      case /* CategoryFooter */1 :
          tmp$1 = React.createElement(SheetSectionFooter.make, {
                columns: columns
              });
          break;
      case /* NewEvent */2 :
          tmp$1 = React.createElement(EventSheet_NewEvent.make, {
                columns: columns,
                currentFilters: currentFilters
              });
          break;
      case /* EmptyState */3 :
          tmp$1 = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.overflow("hidden"),
                      tl: /* [] */0
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: /* [] */0
                        })
                  }, view === /* ByCategory */0 ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.height(Css.px(50)),
                                tl: /* [] */0
                              })
                        }, React.createElement(SheetSectionHeader.make, {
                              columns: columns,
                              children: React.createElement("span", {
                                    className: categoryLinkStyles
                                  }, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        singleLine: true,
                                        color: Styles.Color.light08,
                                        children: "Category"
                                      }))
                            })) : null, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(EventsSheet$EventRow$Empty, {
                            eventName: "Event No. 1",
                            groupedByCategory: view === /* ByCategory */0,
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(EventsSheet$EventRow$Empty, {
                            eventName: "Event No. 2",
                            groupedByCategory: view === /* ByCategory */0,
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(EventsSheet$EventRow$Empty, {
                            eventName: "Event No. 3",
                            groupedByCategory: view === /* ByCategory */0,
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.background({
                                      NAME: "linearGradient",
                                      VAL: [
                                        {
                                          NAME: "deg",
                                          VAL: 180.0
                                        },
                                        [
                                          [
                                            Css.pct(0.0),
                                            Styles.Color.setAlpha(Styles.Color.light02, 0.0)
                                          ],
                                          [
                                            Css.pct(98.0),
                                            Styles.Color.setAlpha(Styles.Color.light02, 1.0)
                                          ],
                                          [
                                            Css.pct(100.0),
                                            Styles.Color.setAlpha(Styles.Color.light02, 1.0)
                                          ]
                                        ]
                                      ]
                                    }),
                                tl: {
                                  hd: Css.bottom(Css.px(0)),
                                  tl: {
                                    hd: Css.width(Css.pct(100.0)),
                                    tl: {
                                      hd: Css.height(Css.px(70)),
                                      tl: {
                                        hd: Css.zIndex(2),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      })), React.createElement(EventsEmpty.make, {
                    viewerCanEdit: viewerCanEdit,
                    schemaBundle: schemaBundle,
                    propertiesCount: 0
                  }));
          break;
      
    }
  } else {
    switch (item.TAG | 0) {
      case /* WorkbenchHeader */0 :
          tmp$1 = React.createElement(EventsSheet$WorkbenchHeader, {
                newEventIds: item._0,
                modifiedEventIds: item._1,
                loaded: item._2,
                columns: columns,
                branchName: match.branchName,
                view: view,
                schemaBundle: schemaBundle
              });
          break;
      case /* WorkbenchEvent */1 :
          var modificationState = item._2;
          var $$event = item._1;
          tmp$1 = React.createElement(EventsSheet$EventRow, {
                allCategories: allCategories,
                allEventProperties: allEventProperties,
                allGroupPropertiesWithGroupTypeNames: allGroupPropertiesWithGroupTypeNames,
                allPropertyGroups: allPropertyGroups,
                allResolvedPropertyGroups: allResolvedPropertyGroups,
                allTags: allTags,
                columns: columns,
                currentFilters: currentFilters,
                event: $$event,
                isEventModified: modificationState !== /* Unmodified */0,
                eventLocation: "Workbench",
                globalSend: globalSend,
                groupedByCategory: view === /* ByCategory */0,
                model: branchModel._0,
                modificationState: modificationState,
                onClick: Curry._1(onEventClickRow, $$event.id),
                viewerCanEdit: viewerCanEdit,
                schemaBundle: schemaBundle,
                selected: Belt_Option.mapWithDefaultU(details, false, (function (detailsId) {
                        return detailsId === $$event.id;
                      })),
                sendActions: sendActions,
                withDetails: Belt_Option.isSome(details),
                innerBorderColor: Styles.Color.lightPurple,
                outerBorderColor: Styles.Color.palePurple,
                backgroundColor: Styles.Color.lightPurple
              });
          break;
      case /* Event */2 :
          var modificationState$1 = item._3;
          var $$event$1 = item._2;
          tmp$1 = React.createElement(EventsSheet$EventRow, {
                allCategories: allCategories,
                allEventProperties: allEventProperties,
                allGroupPropertiesWithGroupTypeNames: allGroupPropertiesWithGroupTypeNames,
                allPropertyGroups: allPropertyGroups,
                allResolvedPropertyGroups: allResolvedPropertyGroups,
                allTags: allTags,
                columns: columns,
                currentFilters: currentFilters,
                event: $$event$1,
                isEventModified: modificationState$1 !== /* Unmodified */0,
                eventLocation: "Catalog",
                globalSend: globalSend,
                groupedByCategory: view === /* ByCategory */0,
                model: branchModel._0,
                modificationState: modificationState$1,
                onClick: Curry._1(onEventClickRow, $$event$1.id),
                viewerCanEdit: viewerCanEdit,
                schemaBundle: schemaBundle,
                selected: Belt_Option.mapWithDefaultU(details, false, (function (detailsId) {
                        return detailsId === $$event$1.id;
                      })),
                sendActions: sendActions,
                withDetails: Belt_Option.isSome(details)
              });
          break;
      case /* UncategorizedHeader */3 :
          tmp$1 = React.createElement(EventsSheet$UncategorizedHeader, {
                columns: columns,
                sectionEventIds: item._0,
                schemaBundle: schemaBundle
              });
          break;
      case /* CategoryHeader */4 :
          tmp$1 = React.createElement(EventsSheet$CategoryHeader, {
                category: item._0,
                columns: columns,
                sectionCount: item._1,
                schemaBundle: schemaBundle
              });
          break;
      
    }
  }
  return React.createElement("div", tmp, tmp$1);
}

var ReactWindowRow = {
  make: EventsSheet$ReactWindowRow
};

function useDirectEventChanges(param) {
  var fromAndToModels = ModelStore.useFromAndToModels(undefined);
  var branch = RouterStore.Schema.useBranch(undefined);
  var getChangesFromModels = function (fromAndToModels) {
    if (fromAndToModels !== undefined) {
      return Changes.getDirectEventChanges(fromAndToModels.from.model, fromAndToModels.to.model);
    }
    
  };
  var match = React.useReducer((function (_state, action) {
          if (action !== "update") {
            return {
                    loaded: false,
                    directEventChanges: undefined
                  };
          }
          var directEventChanges = getChangesFromModels(fromAndToModels);
          return {
                  loaded: Belt_Option.isSome(directEventChanges),
                  directEventChanges: directEventChanges
                };
        }), {
        loaded: false,
        directEventChanges: undefined
      });
  var reduce = match[1];
  React.useEffect((function () {
          if (fromAndToModels !== undefined) {
            Curry._1(reduce, "update");
          }
          
        }), [fromAndToModels]);
  Hooks.useDidUpdate1((function (param) {
          Curry._1(reduce, "reset");
          
        }), [branch]);
  return match[0];
}

function EventsSheet$SheetItself(Props) {
  var currentBranch = Props.currentBranch;
  var globalSend = Props.globalSend;
  var branchName = Props.branchName;
  var branchModel = Props.branchModel;
  var viewerCanEdit = Props.viewerCanEdit;
  var user = Props.user;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = GlobalStateContext.use(undefined);
  var sorting = match.eventSort;
  var filters = match.filters;
  var workspace = WorkspaceContext.use(undefined);
  var domainEvents = DomainStore.useDomainEvents(undefined);
  var match$1 = React.useContext(DiscrepancyContext.context);
  var eventNameComponents = EventNameComponentCollection.useEventNameComponents(schemaBundle.schemaId, ValidationConfigV2.NameComponent.getNameConfigAsString(ValidationConfigV2.EventsV2.getNameComponents(match$1.config.events)));
  var tmp;
  tmp = branchModel.TAG === /* Main */0 ? "Skip" : "Fetch";
  var branchState = FirebaseFetcherHooks.useBranchStateWithProgress(tmp, schemaBundle.schemaId, schemaBundle.branchId);
  var workbenchEventOrder = React.useMemo((function () {
          if (typeof branchState !== "object") {
            return ;
          }
          var match = branchState.VAL;
          if (match !== undefined) {
            return match.eventWorkbenchOrder;
          } else {
            return [];
          }
        }), [branchState]);
  var hasInlineCreationFeatureFlag = AppFeatureFlag.useFeatureFlag("InlineEventCreation");
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  FilterQueryParams.useSetFilterQueryParams(filters, sorting);
  var model = branchModel._0;
  var currentFilters = AnalyticsUtils.currentFilters(model, filters, sorting);
  var storedView = Belt_Option.flatMap(Caml_option.nullable_to_opt(user.eventsView), (function (viewString) {
          switch (viewString) {
            case "ByCategory" :
                return /* ByCategory */0;
            case "List" :
                return /* List */1;
            default:
              return ;
          }
        }));
  var match$2 = React.useState(function () {
        return Belt_Option.getWithDefault(storedView, /* ByCategory */0);
      });
  var setView = match$2[1];
  var view = match$2[0];
  var match$3 = useDirectEventChanges(undefined);
  var directEventChanges = match$3.directEventChanges;
  var workbenchLoaded = match$3.loaded;
  var events = React.useMemo((function () {
          return Belt_List.toArray(StateFilterUtils.filterExcludedEvents(Belt_List.sortU(model.events, (function (a, b) {
                                return stringCompare(a.name, b.name);
                              })), domainEvents, model, filters));
        }), [
        model.events,
        model.properties,
        model.goals,
        domainEvents,
        filters
      ]);
  var eventsLookup = React.useMemo((function () {
          if (view) {
            return organizeEventsAsList(directEventChanges, branchModel, events, workbenchLoaded, hasInlineCreationFeatureFlag, workbenchEventOrder);
          } else {
            return organizeEventsByCategory(directEventChanges, model, branchModel, events, filters, workbenchLoaded, hasInlineCreationFeatureFlag, workbenchEventOrder);
          }
        }), [
        events,
        directEventChanges,
        view,
        workbenchEventOrder,
        Belt_Array.map(filters, StateTypes.filterToString).join(";")
      ]);
  var match$4 = React.useState(function () {
        return false;
      });
  var setMounted = match$4[1];
  var mounted = match$4[0];
  var details = Belt_List.head(Belt_List.keepMap(drawerItems, (function (item) {
              if (typeof item === "object" && item.NAME === "event") {
                return item.VAL[0];
              }
              
            })));
  var match$5 = SheetUtils.Events.useScrollSync(undefined);
  var sheetRef = match$5.sheetRef;
  var handleReactWindowRef = match$5.handleReactWindowRef;
  var handleOuterRef = match$5.handleOuterRef;
  React.useEffect((function () {
          if (mounted) {
            var match = sheetRef.current;
            if (details !== undefined && !(match == null)) {
              var eventsIndex = Belt_Array.getIndexByU(events, (function ($$event) {
                      return $$event.id === details;
                    }));
              var indexOfFirstInstanceInLookup = Belt_Array.getIndexByU(eventsLookup, (function (row) {
                      if (typeof row === "number" || !(row.TAG === /* Event */2 && eventsIndex !== undefined)) {
                        return false;
                      } else {
                        return row._0 === eventsIndex;
                      }
                    }));
              Belt_Option.forEach(indexOfFirstInstanceInLookup, (function (index) {
                      match.scrollToItem(index, "center");
                      
                    }));
            }
            
          } else {
            Curry._1(setMounted, (function (param) {
                    return true;
                  }));
          }
          
        }), [
        mounted,
        view
      ]);
  var itemSize = React.useCallback((function (_index) {
          return 50;
        }), []);
  var itemKey = React.useCallback((function (index) {
          var match = Belt_Array.get(eventsLookup, index);
          if (match === undefined) {
            return String(index);
          }
          if (typeof match === "number") {
            switch (match) {
              case /* WorkbenchFooter */0 :
                  return "WorkbenchFooter";
              case /* CategoryFooter */1 :
                  return String(index);
              case /* NewEvent */2 :
                  return "NewEvent";
              case /* EmptyState */3 :
                  return "EmptyState";
              
            }
          } else {
            switch (match.TAG | 0) {
              case /* WorkbenchHeader */0 :
                  return "WorkbenchHeader";
              case /* WorkbenchEvent */1 :
                  return "Modified" + match._1.id;
              case /* Event */2 :
                  return Belt_Option.getWithDefault(match._1, "") + match._2.id;
              case /* UncategorizedHeader */3 :
                  return "UncategorizedHeader";
              case /* CategoryHeader */4 :
                  return match._0.id;
              
            }
          }
        }), [eventsLookup]);
  var match$6 = React.useState(function () {
        return deriveColumnsFromServer(hasInlineCreationFeatureFlag, Caml_option.nullable_to_opt(user.columnSettings));
      });
  var setColumns = match$6[1];
  var columns = match$6[0];
  React.useEffect((function () {
          var shouldUpdate = Caml_obj.caml_notequal(deriveColumnsFromServer(hasInlineCreationFeatureFlag, Caml_option.nullable_to_opt(user.columnSettings)), columns);
          if (shouldUpdate) {
            updateColumnSettingsOnServer(user.id, columns);
          }
          
        }), [columns]);
  var handleViewChange = function (newView) {
    FirebaseUtils.setEventsViewPreference(user.id, newView ? "List" : "ByCategory");
    Curry._1(setView, (function (param) {
            return newView;
          }));
    return AnalyticsRe.eventsViewConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "View", newView === /* ByCategory */0 ? "SetViewAsGroupedByCategory" : "SetViewAsList", undefined, Belt_Array.keepMapU(Belt_Array.keepU(columns, (function (column) {
                          return column.visible;
                        })), (function (column) {
                      return AnalyticsUtils.columnTypeToEventColumnsName(column.columnType);
                    })), Belt_Array.keepU(columns, (function (column) {
                      return column.visible;
                    })).length, schemaBundle.branchId, schemaBundle.schemaId);
  };
  var allCategories = React.useMemo((function () {
          return Belt_List.toArray(model.goals);
        }), [model.goals]);
  var allTags = React.useMemo((function () {
          return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_List.toArray(Belt_List.mapU(model.events, (function (param) {
                                        return Belt_List.toArray(param.tags);
                                      }))))));
        }), [model.events]);
  var allProperties = React.useMemo((function () {
          return Belt_List.toArray(Belt_List.keepMapU(model.properties, (function (p) {
                            var tmp;
                            tmp = p._0.id;
                            return ModelUtils.resolvePropertyById(tmp, model);
                          })));
        }), [model.properties]);
  var allPropertyGroups = React.useMemo((function () {
          return Belt_List.toArray(model.propertyBundles);
        }), [model.propertyBundles]);
  var allResolvedPropertyGroups = React.useMemo((function () {
          return Belt_Array.mapU(allPropertyGroups, (function (modelPropertyGroup) {
                        return {
                                id: modelPropertyGroup.id,
                                name: modelPropertyGroup.name,
                                properties: Belt_List.toArray(Belt_List.keepMapU(modelPropertyGroup.properties, (function (propertyId) {
                                            return Belt_Array.getByU(allProperties, (function (param) {
                                                          return param.id === propertyId;
                                                        }));
                                          })))
                              };
                      }));
        }), [allPropertyGroups]);
  var allGroupPropertiesWithGroupTypeNames = Belt_List.toArray(Belt_List.keepMapU(model.properties, (function (property) {
              if (property.TAG === /* PropertyRef */0) {
                return ;
              }
              var property$1 = property._0;
              var groupTypeId = property$1.sendAs;
              if (typeof groupTypeId === "number") {
                return ;
              } else {
                return Belt_Option.map(ModelUtils.getGroupType(groupTypeId._0, model), (function (param) {
                              return [
                                      property$1,
                                      param.name
                                    ];
                            }));
              }
            })));
  var allEventProperties = Belt_List.toArray(Belt_List.keepMapU(model.properties, (function (property) {
              if (property.TAG === /* PropertyRef */0) {
                return ;
              }
              var property$1 = property._0;
              if (property$1.sendAs === /* EventProperty */1) {
                return property$1;
              }
              
            })));
  var hasEmptyState = model.events === /* [] */0;
  React.useEffect((function () {
          if (hasEmptyState) {
            AnalyticsRe.emptyStateDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Events", schemaBundle.schemaId);
          }
          
        }), []);
  var emptyItem = function (item) {
    return {
            allCategories: [],
            allEventProperties: [],
            allGroupPropertiesWithGroupTypeNames: [],
            allPropertyGroups: [],
            allResolvedPropertyGroups: [],
            allTags: [],
            item: item,
            branchId: Actions.branchToId(currentBranch),
            branchName: branchName,
            columns: columns,
            currentFilters: currentFilters,
            details: details,
            globalSend: globalSend,
            branchModel: branchModel,
            viewerCanEdit: viewerCanEdit,
            schemaBundle: schemaBundle,
            sendActions: sendActions,
            view: view
          };
  };
  var itemData = React.useMemo((function () {
          if (hasEmptyState) {
            return [emptyItem(/* EmptyState */3)];
          } else {
            return Belt_Array.map(eventsLookup, (function (item) {
                          return {
                                  allCategories: allCategories,
                                  allEventProperties: allEventProperties,
                                  allGroupPropertiesWithGroupTypeNames: allGroupPropertiesWithGroupTypeNames,
                                  allPropertyGroups: allPropertyGroups,
                                  allResolvedPropertyGroups: allResolvedPropertyGroups,
                                  allTags: allTags,
                                  item: item,
                                  branchId: Actions.branchToId(currentBranch),
                                  branchName: branchName,
                                  columns: columns,
                                  currentFilters: currentFilters,
                                  details: details,
                                  globalSend: globalSend,
                                  branchModel: branchModel,
                                  viewerCanEdit: viewerCanEdit,
                                  schemaBundle: schemaBundle,
                                  sendActions: sendActions,
                                  view: view
                                };
                        }));
          }
        }), [
        events,
        directEventChanges,
        details,
        columns,
        view,
        model.goals
      ]);
  var hasCSVExporting = AppFeatureFlag.useFeatureFlag("ExportSheetsToCSV");
  var openNewEventModal = NewEventModal.useOpen("EventsView");
  return React.createElement(EventsSheet__EditableNameCellContext.Provider.make, {
              children: React.createElement(EventsSheet__EventNameComponentsContext.make, {
                    eventNameComponents: eventNameComponents,
                    children: null
                  }, React.createElement("header", {
                        className: headerStyles$1
                      }, React.createElement(Title.make, {
                            children: null,
                            shrink: 0.0,
                            size: "Tiny"
                          }, "Events ", React.createElement("span", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("inlineBlock"),
                                      tl: {
                                        hd: Css.minWidth(Css.px(48)),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    element: "Span",
                                    size: "Medium",
                                    color: Styles.Color.light10,
                                    children: "(" + String(events.length) + ")"
                                  }))), viewerCanEdit ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                  width: 4
                                }), React.createElement(Button.make, {
                                  icon: "plus",
                                  label: "New Event",
                                  onClick: (function (param) {
                                      if (Belt_List.length(model.events) === 0) {
                                        AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Events", "Setup", undefined);
                                      }
                                      return Curry._1(openNewEventModal, undefined);
                                    })
                                }), React.createElement(Spacer.make, {
                                  width: 8
                                }), React.createElement(Button.make, {
                                  icon: "plus",
                                  label: "New Category",
                                  onClick: (function (param) {
                                      Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "CreateCategory",
                                              VAL: [
                                                undefined,
                                                undefined
                                              ]
                                            }
                                          });
                                      return AnalyticsRe.categoryInitiated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.branchId, schemaBundle.schemaId);
                                    }),
                                  style: "outline"
                                })) : null, React.createElement(Spacer.make, {
                            width: 16
                          }), React.createElement(SegmentControl.make, {
                            options: [
                              [
                                "Category",
                                /* ByCategory */0
                              ],
                              [
                                "List",
                                /* List */1
                              ]
                            ],
                            onSelect: handleViewChange,
                            selected: view
                          }), React.createElement(Spacer.make, {
                            width: 16
                          }), React.createElement(EventsSheet$CustomizeButtonWithMenu, {
                            columns: columns,
                            setColumns: setColumns,
                            resetColumns: (function (param) {
                                return Curry._1(setColumns, (function (param) {
                                              AnalyticsRe.eventsViewConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ColumnWidth", "Reset", undefined, Belt_Array.keepMapU(Belt_Array.keepU(defaultColumns(hasInlineCreationFeatureFlag), (function (column) {
                                                              return column.visible;
                                                            })), (function (column) {
                                                          return AnalyticsUtils.columnTypeToEventColumnsName(column.columnType);
                                                        })), Belt_Array.keepU(defaultColumns(hasInlineCreationFeatureFlag), (function (column) {
                                                          return column.visible;
                                                        })).length, schemaBundle.branchId, schemaBundle.schemaId);
                                              return defaultColumns(hasInlineCreationFeatureFlag);
                                            }));
                              }),
                            schemaBundle: schemaBundle
                          }), React.createElement(EventsSheetFilters.make, {
                            model: model,
                            globalSend: globalSend,
                            schemaBundle: schemaBundle,
                            filters: filters
                          }), hasCSVExporting ? React.createElement(EventsSheet$CSVExport, {
                              events: events
                            }) : null), React.createElement(SheetHeader.make, {
                        columns: columns,
                        setColumns: setColumns,
                        view: view,
                        onWidthChange: (function (direction, newColumns, columnType) {
                            return AnalyticsRe.eventsViewConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ColumnWidth", direction === "Increase" ? "IncreaseColumnWidth" : "DecreaseColumnWidth", AnalyticsUtils.columnTypeToEventColumnsName(columnType), Belt_Array.keepMapU(Belt_Array.keepU(newColumns, (function (param) {
                                                  return param.visible;
                                                })), (function (param) {
                                              return AnalyticsUtils.columnTypeToEventColumnsName(param.columnType);
                                            })), Belt_Array.keepU(newColumns, (function (param) {
                                              return param.visible;
                                            })).length, schemaBundle.branchId, schemaBundle.schemaId);
                          }),
                        ref: match$5.handleSheetHeaderRef
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.height({
                                      NAME: "subtract",
                                      VAL: [
                                        Css.vh(100),
                                        Css.px((64 + SheetHeader.columnsHeaderHeight | 0) + SandboxBanner.getHeight(workspace.isSandbox) | 0)
                                      ]
                                    }),
                                tl: {
                                  hd: view === /* ByCategory */0 ? Css.paddingLeft(Css.px(16)) : Css.padding("zero"),
                                  tl: {
                                    hd: Css.position("relative"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }),
                        id: "sheetview-tour-table"
                      }, !hasEmptyState && events.length === 0 ? React.createElement("div", {
                              className: emptyFilteredEvents
                            }, React.createElement(Title.make, {
                                  children: "Found no matching events",
                                  size: "Medium"
                                }), React.createElement(Spacer.make, {
                                  height: 12
                                }), React.createElement($$Text.make, {
                                  size: "Large",
                                  color: Styles.Color.light10,
                                  maxWidth: Css.px(360),
                                  children: null
                                }, "It looks like none of your events matched your filters. Try ", React.createElement(TextButton.make, {
                                      onClick: (function (param) {
                                          return Curry._1(globalSend, {
                                                      TAG: /* UpdateFilters */0,
                                                      _0: /* Reset */8,
                                                      _1: "Events"
                                                    });
                                        }),
                                      style: "Blue",
                                      children: "resetting them"
                                    }), ".")) : null, React.createElement(ReactVirtualizedAutoSizer, {
                            children: (function (size) {
                                return React.createElement(ReactWindow.VariableSizeList, {
                                            estimatedItemSize: 50,
                                            height: size.height,
                                            innerElementType: SheetContainer.make,
                                            itemCount: hasEmptyState ? itemData.length : eventsLookup.length,
                                            itemData: itemData,
                                            itemKey: itemKey,
                                            itemSize: itemSize,
                                            outerRef: handleOuterRef,
                                            overscanCount: 30,
                                            ref: handleReactWindowRef,
                                            width: size.width,
                                            children: EventsSheet$ReactWindowRow
                                          });
                              })
                          })))
            });
}

var SheetItself = {
  make: EventsSheet$SheetItself
};

function EventsSheet(Props) {
  var currentBranch = Props.currentBranch;
  var globalSend = Props.globalSend;
  var branchName = Props.branchName;
  var branchModel = Props.branchModel;
  var viewerCanEdit = Props.viewerCanEdit;
  var user = Props.user;
  return React.createElement(BulkContext.make, {
              children: null
            }, React.createElement(BulkBar.make, {}), React.createElement(EventsSheet$SheetItself, {
                  currentBranch: currentBranch,
                  globalSend: globalSend,
                  branchName: branchName,
                  branchModel: branchModel,
                  viewerCanEdit: viewerCanEdit,
                  user: user
                }));
}

var itemHeight = 50;

var headerHeight = 64;

var make = EventsSheet;

export {
  CSVExport ,
  renderModelFromBranchModel ,
  defaultColumns ,
  getGetNewAndChangedRowCounts ,
  itemHeight ,
  EventRow ,
  CategoryHeader ,
  CustomizeButtonWithMenu ,
  headerHeight ,
  headerStyles$1 as headerStyles,
  emptyFilteredEvents ,
  stringCompare ,
  getEventModificationStatus ,
  isEventModified ,
  organizeEventsAsList ,
  organizeEventsByCategory ,
  deriveColumnsFromServer ,
  updateColumnSettingsOnServer ,
  WorkbenchHeader ,
  UncategorizedHeader ,
  ReactWindowRow ,
  useDirectEventChanges ,
  SheetItself ,
  make ,
  
}
/* ExportButton Not a pure module */
