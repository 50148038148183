// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Spacer from "./Spacer.mjs";
import * as Stripe from "./Stripe.mjs";
import * as Styles from "./styles.mjs";
import * as $$Window from "./externals/window.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as StripeCouponInput from "./StripeCouponInput.mjs";

function createTeamV2Subscription(schemaId, token, annual, promoCode) {
  return Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                  return fetch(Firebase.apiUrl + ("/createSubscription?plan=" + ((
                                  annual ? "teamv2Annual" : "teamv2"
                                ) + (
                                  process.env.NODE_ENV === "development" ? "&dev=true" : ""
                                ))), Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + userToken
                                    ]
                                  ], Caml_option.some(JSON.stringify(Js_dict.fromArray(Belt_Array.concat([
                                                    [
                                                      "token",
                                                      token.id
                                                    ],
                                                    [
                                                      "schemaId",
                                                      schemaId
                                                    ]
                                                  ], promoCode !== undefined ? [[
                                                        "promotionCode",
                                                        promoCode
                                                      ]] : [])))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (prim) {
                return prim.json();
              }).then(function (resJson) {
              if (Json_decode.field("ok", Json_decode.bool, resJson)) {
                return Promise.resolve({
                            TAG: /* Ok */0,
                            _0: undefined
                          });
              } else {
                return Promise.resolve({
                            TAG: /* Error */1,
                            _0: Json_decode.field("error", (function (json) {
                                    return Json_decode.field("message", Json_decode.string, json);
                                  }), resJson)
                          });
              }
            });
}

function updatePayingMethod(schemaId, token, subscription, promoCode) {
  return Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
              return fetch(Firebase.apiUrl + "/updateSubscriptionBillingDetails" + (
                          process.env.NODE_ENV === "development" ? "?dev=true" : ""
                        ), Fetch.RequestInit.make(/* Post */2, [
                                [
                                  "Accept",
                                  "application/json"
                                ],
                                [
                                  "Content-Type",
                                  "application/json"
                                ],
                                [
                                  "Authorization",
                                  "Bearer " + userToken
                                ]
                              ], Caml_option.some(JSON.stringify(Js_dict.fromArray(Belt_Array.concat([
                                                [
                                                  "token",
                                                  token.id
                                                ],
                                                [
                                                  "schemaId",
                                                  schemaId
                                                ],
                                                [
                                                  "customerId",
                                                  subscription.customer
                                                ],
                                                [
                                                  "subscriptionId",
                                                  subscription.id
                                                ]
                                              ], promoCode !== undefined ? [[
                                                    "promotionCode",
                                                    promoCode
                                                  ]] : [])))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
            });
}

function cancelSubscription(schemaId, subscriptionId) {
  return Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                  return fetch(Firebase.apiUrl + "/cancelSubscription" + (
                              process.env.NODE_ENV === "development" ? "?dev=true" : ""
                            ), Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + userToken
                                    ]
                                  ], Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                                [
                                                  "subscriptionId",
                                                  subscriptionId
                                                ],
                                                [
                                                  "schemaId",
                                                  schemaId
                                                ]
                                              ]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (prim) {
                return prim.json();
              }).then(function (param) {
              return Promise.resolve(undefined);
            });
}

function StripeBilling$PaymentInput(Props) {
  var schemaId = Props.schemaId;
  var onSubmitStart = Props.onSubmitStart;
  var onSubmitError = Props.onSubmitError;
  var onSubmitSuccess = Props.onSubmitSuccess;
  var isLoading = Props.isLoading;
  var label = Props.label;
  var couponInputOpt = Props.couponInput;
  var onBack = Props.onBack;
  var couponInput = couponInputOpt !== undefined ? couponInputOpt : true;
  var promoCode = React.useRef(undefined);
  React.useEffect((function () {
          var stripe = window.Stripe(Stripe.getApiKey(undefined));
          var elements = stripe.elements();
          var style = {
            base: {
              color: Styles.Color.toString(Styles.Color.light12),
              fontSmoothing: "antialiased",
              fontSize: "20px",
              "::placeholder": {
                color: Styles.Color.toString(Styles.Color.light11)
              }
            },
            invalid: {
              color: Styles.Color.toString(Styles.Color.darkRed),
              iconColor: Styles.Color.toString(Styles.Color.red)
            }
          };
          var card = elements.create("card", Object.assign(style, {
                    hidePostalCode: true
                  }));
          card.mount("#card-element");
          card.addEventListener("change", (function ($$event) {
                  var displayError = $$Window.$$Document.getElementById("card-errors");
                  var match = $$event.error;
                  if (match == null) {
                    if (displayError !== undefined) {
                      Caml_option.valFromOption(displayError).textContent = "";
                      return ;
                    } else {
                      return ;
                    }
                  } else if (displayError !== undefined) {
                    Caml_option.valFromOption(displayError).textContent = match.message;
                    return ;
                  } else {
                    return ;
                  }
                }));
          var form = $$Window.$$Document.getElementById("payment-form");
          var eventHandler = function ($$event) {
            $$event.preventDefault();
            Curry._1(onSubmitStart, undefined);
            $$Promise.$$catch(stripe.createToken(card), (function (param) {
                      Curry._1(onSubmitError, undefined);
                      return Promise.reject(Js_exn.raiseError("Token creation unsuccessful"));
                    })).then(function (result) {
                  var error = result.error;
                  if (error !== undefined) {
                    Curry._1(onSubmitError, undefined);
                    var displayError = $$Window.$$Document.getElementById("card-errors");
                    Belt_Option.forEach(displayError, (function (element) {
                            element.textContent = error.message;
                            
                          }));
                    return Promise.resolve(undefined);
                  }
                  var token = result.token;
                  var hiddenInput = document.createElement("input");
                  hiddenInput.setAttribute("type", "hidden");
                  hiddenInput.setAttribute("name", "stripeToken");
                  hiddenInput.setAttribute("value", token.id);
                  Belt_Option.forEach(form, (function (element) {
                          element.appendChild(hiddenInput);
                          
                        }));
                  return Curry._2(onSubmitSuccess, token, promoCode.current);
                });
            
          };
          if (form !== undefined) {
            Caml_option.valFromOption(form).addEventListener("submit", eventHandler);
          }
          return (function (param) {
                    return Belt_Option.forEach(form, (function (element) {
                                  element.removeEventListener("submit", eventHandler);
                                  
                                }));
                  });
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginBottom(Css.px(50)),
                    tl: {
                      hd: Css.maxWidth(Css.px(450)),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("form", {
                  id: "payment-form",
                  method: "post"
                }, React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("label", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("block"),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.color(Styles.Color.light12),
                                      tl: {
                                        hd: Css.paddingTop(Css.px(12)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }),
                          htmlFor: "card-element"
                        }, "Credit or debit card"), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.marginTop(Css.px(8)),
                                  tl: {
                                    hd: Css.marginBottom(Css.px(8)),
                                    tl: {
                                      hd: Css.borderRadius(Styles.Border.radius),
                                      tl: {
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                        tl: {
                                          hd: Css.paddingTop(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingRight(Css.px(16)),
                                            tl: {
                                              hd: Css.paddingBottom(Css.px(12)),
                                              tl: {
                                                hd: Css.paddingLeft(Css.px(16)),
                                                tl: {
                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          id: "card-element"
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.color(Styles.Color.darkRed),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.margin4(Css.px(8), Css.px(15), Css.px(4), Css.px(15)),
                                      tl: {
                                        hd: Css.textAlign("center"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }),
                          id: "card-errors",
                          role: "alert"
                        })), couponInput ? React.createElement(StripeCouponInput.make, {
                        schemaId: schemaId,
                        onValidPromoCode: (function (promoCodeId) {
                            promoCode.current = promoCodeId;
                            
                          })
                      }) : null, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.marginBottom(Css.px(16)),
                                tl: {
                                  hd: Css.textAlign("center"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, React.createElement(Icons.Lock.make, {}), React.createElement(Spacer.make, {
                          width: 4
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light10,
                          children: "Payments are processed securely via"
                        }), React.createElement("a", {
                          className: Curry._1(Css.style, {
                                hd: Css.paddingLeft(Css.px(4)),
                                tl: {
                                  hd: Css.color(Styles.Color.darkBlue),
                                  tl: {
                                    hd: Css.display("inlineFlex"),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          href: "https://stripe.com/",
                          rel: "noopener",
                          target: "_blank"
                        }, React.createElement(Icons.Stripe.make, {}))), onBack !== undefined ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.gap(Css.px(16)),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement(Button.make, {
                            icon: "arrowLeft",
                            label: "Back",
                            onClick: (function ($$event) {
                                $$event.preventDefault();
                                return Curry._1(onBack, undefined);
                              }),
                            size: "large",
                            style: "outline"
                          }), React.createElement(Button.make, {
                            label: label,
                            loading: isLoading,
                            size: "large"
                          })) : React.createElement(Button.make, {
                        label: label,
                        loading: isLoading
                      })));
}

var PaymentInput = {
  make: StripeBilling$PaymentInput
};

export {
  createTeamV2Subscription ,
  updatePayingMethod ,
  cancelSubscription ,
  PaymentInput ,
  
}
/* Css Not a pure module */
