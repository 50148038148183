// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../../bs-case/src/case.mjs";
import * as Dopt from "../externals/Dopt.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProductTour__Modal from "./ProductTour__Modal.mjs";
import * as ProductTour__Utils from "./ProductTour__Utils.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as ProductTour__Windowed from "./ProductTour__Windowed.mjs";
import * as ProductTour__OnElement from "./ProductTour__OnElement.mjs";
import * as ProductTour__OnElements from "./ProductTour__OnElements.mjs";
import * as ProductTour__StepProvider from "./ProductTour__StepProvider.mjs";
import * as ProductTour__TourProvider from "./ProductTour__TourProvider.mjs";

function ProductTour__Step(Props) {
  var stepId = Props.stepId;
  var stepType = Props.stepType;
  var arrowOffset = Props.arrowOffset;
  var borderRadius = Props.borderRadius;
  var delay = Props.delay;
  var disableCmdKOpt = Props.disableCmdK;
  var elementClickable = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var lockBackground = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var onOutsideClick = Props.onOutsideClick;
  var onTransition = Props.onTransition;
  var onStepActive = Props.onStepActive;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var simulateClick = Props.simulateClick;
  var transition = Props.transition;
  var transitions = Props.transitions;
  var transitionDuration = Props.transitionDuration;
  var waitForId = Props.waitForId;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var disableCmdK = disableCmdKOpt !== undefined ? disableCmdKOpt : true;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = ProductTour__TourProvider.use(undefined);
  var tourIdOverride = match.tourIdOverride;
  var tourId = match.tourId;
  var match$1 = Dopt.Block.use(tourId + "." + stepId);
  var blockTransition = match$1[1];
  var block = match$1[0];
  var match$2 = Dopt.Flow.use(tourId);
  var intent = match$2[1];
  var match$3 = Dopt.Flow.use(Belt_Option.getWithDefault(tourIdOverride, ""));
  var opened = block.state.active && match.tourMayShow;
  var stepNumber = Belt_Option.getWithDefault(Curry._1(match.getStepNumber, stepId), 0);
  var flowId = tourIdOverride !== undefined ? tourIdOverride : tourId;
  var flowVersion = tourIdOverride !== undefined ? match$3[0].version : match$2[0].version;
  var transitionCallback = React.useCallback((function (transitionName) {
          Belt_Option.forEach(onTransition, (function (cb) {
                  return Curry._1(cb, transitionName);
                }));
          if (!transitionName.startsWith("prev")) {
            AnalyticsRe.productFlowStepCompleted(Case.title(flowId), flowId, String(flowVersion), schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, block.sid, stepNumber, schemaBundle.schemaId);
          }
          return Curry._1(blockTransition, transitionName);
        }), [
        blockTransition,
        onTransition
      ]);
  var onClick$1 = Belt_Option.map(onClick, (function (onClick, e) {
          return Curry._2(onClick, transitionCallback, e);
        }));
  var advanceByCTA = simulateClick !== undefined ? !(Caml_obj.caml_equal(elementClickable, true) || Belt_Option.isNone(elementClickable)) : true;
  ProductTour__Utils.Hook.useCmdKHandler(opened && disableCmdK);
  var tmp;
  var exit = 0;
  if (typeof stepType === "number") {
    exit = 1;
  } else {
    switch (stepType.TAG | 0) {
      case /* OnElementId */0 :
          if (opened) {
            var tmp$1 = {
              forId: stepType._0,
              opened: opened,
              children: children
            };
            if (arrowOffset !== undefined) {
              tmp$1.arrowOffset = Caml_option.valFromOption(arrowOffset);
            }
            if (borderRadius !== undefined) {
              tmp$1.borderRadius = Caml_option.valFromOption(borderRadius);
            }
            if (delay !== undefined) {
              tmp$1.delay = Caml_option.valFromOption(delay);
            }
            if (elementClickable !== undefined) {
              tmp$1.elementClickable = Caml_option.valFromOption(elementClickable);
            }
            if (elementPadding !== undefined) {
              tmp$1.elementPadding = Caml_option.valFromOption(elementPadding);
            }
            if (lockBackground !== undefined) {
              tmp$1.lockBackground = Caml_option.valFromOption(lockBackground);
            }
            if (offset !== undefined) {
              tmp$1.offset = Caml_option.valFromOption(offset);
            }
            if (onClick$1 !== undefined) {
              tmp$1.onClick = Caml_option.valFromOption(onClick$1);
            }
            if (onOutsideClick !== undefined) {
              tmp$1.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
            }
            if (position !== undefined) {
              tmp$1.position = Caml_option.valFromOption(position);
            }
            if (positionDependencies !== undefined) {
              tmp$1.positionDependencies = Caml_option.valFromOption(positionDependencies);
            }
            if (onStepActive !== undefined) {
              tmp$1.onStepActive = Caml_option.valFromOption(onStepActive);
            }
            if (simulateClick !== undefined) {
              tmp$1.simulateClick = Caml_option.valFromOption(simulateClick);
            }
            if (transition !== undefined) {
              tmp$1.transition = Caml_option.valFromOption(transition);
            }
            if (transitionDuration !== undefined) {
              tmp$1.transitionDuration = Caml_option.valFromOption(transitionDuration);
            }
            if (waitForId !== undefined) {
              tmp$1.waitForId = Caml_option.valFromOption(waitForId);
            }
            if (withOutline !== undefined) {
              tmp$1.withOutline = Caml_option.valFromOption(withOutline);
            }
            tmp = React.createElement(ProductTour__StepProvider.make, {
                  advanceByCTA: advanceByCTA,
                  block: block,
                  intent: Caml_option.some(intent),
                  stepNumber: stepNumber,
                  transition: transitionCallback,
                  transitions: transitions,
                  children: React.createElement(ProductTour__OnElement.make, tmp$1),
                  key: block.uid
                });
          } else {
            tmp = null;
          }
          break;
      case /* OnElementIds */1 :
          if (opened) {
            var tmp$2 = {
              forIds: stepType._0,
              opened: opened,
              children: children
            };
            if (arrowOffset !== undefined) {
              tmp$2.arrowOffset = Caml_option.valFromOption(arrowOffset);
            }
            if (borderRadius !== undefined) {
              tmp$2.borderRadius = Caml_option.valFromOption(borderRadius);
            }
            if (delay !== undefined) {
              tmp$2.delay = Caml_option.valFromOption(delay);
            }
            if (elementClickable !== undefined) {
              tmp$2.elementClickable = Caml_option.valFromOption(elementClickable);
            }
            if (elementPadding !== undefined) {
              tmp$2.elementPadding = Caml_option.valFromOption(elementPadding);
            }
            if (lockBackground !== undefined) {
              tmp$2.lockBackground = Caml_option.valFromOption(lockBackground);
            }
            if (onClick$1 !== undefined) {
              tmp$2.onClick = Caml_option.valFromOption(onClick$1);
            }
            if (onOutsideClick !== undefined) {
              tmp$2.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
            }
            if (offset !== undefined) {
              tmp$2.offset = Caml_option.valFromOption(offset);
            }
            if (position !== undefined) {
              tmp$2.position = Caml_option.valFromOption(position);
            }
            if (positionDependencies !== undefined) {
              tmp$2.positionDependencies = Caml_option.valFromOption(positionDependencies);
            }
            if (onStepActive !== undefined) {
              tmp$2.onStepActive = Caml_option.valFromOption(onStepActive);
            }
            if (simulateClick !== undefined) {
              tmp$2.simulateClick = Caml_option.valFromOption(simulateClick);
            }
            if (transition !== undefined) {
              tmp$2.transition = Caml_option.valFromOption(transition);
            }
            if (transitionDuration !== undefined) {
              tmp$2.transitionDuration = Caml_option.valFromOption(transitionDuration);
            }
            if (waitForId !== undefined) {
              tmp$2.waitForId = Caml_option.valFromOption(waitForId);
            }
            if (withOutline !== undefined) {
              tmp$2.withOutline = Caml_option.valFromOption(withOutline);
            }
            tmp = React.createElement(ProductTour__StepProvider.make, {
                  advanceByCTA: advanceByCTA,
                  block: block,
                  intent: Caml_option.some(intent),
                  stepNumber: stepNumber,
                  transition: transitionCallback,
                  transitions: transitions,
                  children: React.createElement(ProductTour__OnElements.make, tmp$2),
                  key: block.uid
                });
          } else {
            tmp = null;
          }
          break;
      case /* Window */2 :
          if (opened) {
            var match$4 = stepType._0;
            var tmp$3 = {
              height: match$4.height,
              left: match$4.left,
              opened: opened,
              top: match$4.top,
              width: match$4.width,
              children: children
            };
            if (arrowOffset !== undefined) {
              tmp$3.arrowOffset = Caml_option.valFromOption(arrowOffset);
            }
            if (borderRadius !== undefined) {
              tmp$3.borderRadius = Caml_option.valFromOption(borderRadius);
            }
            if (delay !== undefined) {
              tmp$3.delay = Caml_option.valFromOption(delay);
            }
            if (elementClickable !== undefined) {
              tmp$3.elementClickable = Caml_option.valFromOption(elementClickable);
            }
            if (lockBackground !== undefined) {
              tmp$3.lockBackground = Caml_option.valFromOption(lockBackground);
            }
            if (onClick$1 !== undefined) {
              tmp$3.onClick = Caml_option.valFromOption(onClick$1);
            }
            if (onOutsideClick !== undefined) {
              tmp$3.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
            }
            if (offset !== undefined) {
              tmp$3.offset = Caml_option.valFromOption(offset);
            }
            if (position !== undefined) {
              tmp$3.position = Caml_option.valFromOption(position);
            }
            if (positionDependencies !== undefined) {
              tmp$3.positionDependencies = Caml_option.valFromOption(positionDependencies);
            }
            if (onStepActive !== undefined) {
              tmp$3.onStepActive = Caml_option.valFromOption(onStepActive);
            }
            if (simulateClick !== undefined) {
              tmp$3.simulateClick = Caml_option.valFromOption(simulateClick);
            }
            if (transition !== undefined) {
              tmp$3.transition = Caml_option.valFromOption(transition);
            }
            if (transitionDuration !== undefined) {
              tmp$3.transitionDuration = Caml_option.valFromOption(transitionDuration);
            }
            if (waitForId !== undefined) {
              tmp$3.waitForId = Caml_option.valFromOption(waitForId);
            }
            if (withOutline !== undefined) {
              tmp$3.withOutline = Caml_option.valFromOption(withOutline);
            }
            tmp = React.createElement(ProductTour__StepProvider.make, {
                  advanceByCTA: advanceByCTA,
                  block: block,
                  intent: Caml_option.some(intent),
                  stepNumber: stepNumber,
                  transition: transitionCallback,
                  transitions: transitions,
                  children: React.createElement(ProductTour__Windowed.make, tmp$3),
                  key: block.uid
                });
          } else {
            tmp = null;
          }
          break;
      case /* Modal */3 :
          if (opened) {
            var tmp$4 = {
              opened: opened,
              children: children
            };
            if (lockBackground !== undefined) {
              tmp$4.closeOnClickOutside = Caml_option.valFromOption(lockBackground);
            }
            if (delay !== undefined) {
              tmp$4.delay = Caml_option.valFromOption(delay);
            }
            if (onStepActive !== undefined) {
              tmp$4.onStepActive = Caml_option.valFromOption(onStepActive);
            }
            if (stepType._0 !== undefined) {
              tmp$4.size = Caml_option.valFromOption(stepType._0);
            }
            tmp = React.createElement(ProductTour__StepProvider.make, {
                  advanceByCTA: advanceByCTA,
                  block: block,
                  intent: Caml_option.some(intent),
                  stepNumber: stepNumber,
                  transition: transitionCallback,
                  transitions: transitions,
                  children: React.createElement(ProductTour__Modal.make, tmp$4),
                  key: block.uid
                });
          } else {
            tmp = null;
          }
          break;
      
    }
  }
  if (exit === 1) {
    tmp = opened ? React.createElement(ProductTour__StepProvider.make, {
            advanceByCTA: advanceByCTA,
            block: block,
            intent: Caml_option.some(intent),
            stepNumber: stepNumber,
            transition: transitionCallback,
            transitions: transitions,
            children: children,
            key: block.uid
          }) : null;
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var StepProvider;

var TourProvider;

var OnElement;

var OnElements;

var Windowed;

var Modal;

var make = ProductTour__Step;

export {
  StepProvider ,
  TourProvider ,
  OnElement ,
  OnElements ,
  Windowed ,
  Modal ,
  make ,
  
}
/* Case Not a pure module */
