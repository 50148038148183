// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconTerminal(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 48;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 48 48",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  className: "nc-icon-wrapper"
                }, React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.light10),
                            tl: /* [] */0
                          }),
                      d: "M45,45H3c-1.105,0-2-0.895-2-2V5c0-1.105,0.895-2,2-2h42c1.105,0,2,0.895,2,2v38C47,44.105,46.105,45,45,45z "
                    }), React.createElement("rect", {
                      height: "33",
                      width: "42",
                      fill: "#FFFFFF",
                      x: "3",
                      y: "10"
                    }), React.createElement("circle", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.light02),
                            tl: /* [] */0
                          }),
                      cx: "4.5",
                      cy: "6.5",
                      r: "1.5"
                    }), React.createElement("circle", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.light02),
                            tl: /* [] */0
                          }),
                      cx: "9.5",
                      cy: "6.5",
                      r: "1.5"
                    }), React.createElement("circle", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.light02),
                            tl: /* [] */0
                          }),
                      cx: "14.5",
                      cy: "6.5",
                      r: "1.5"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                            tl: /* [] */0
                          }),
                      d: "M10,31c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414L12.586,26l-3.293-3.293 c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0l4,4c0.391,0.391,0.391,1.023,0,1.414l-4,4C10.512,30.902,10.256,31,10,31z"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                            tl: /* [] */0
                          }),
                      d: "M28,31h-8c-0.552,0-1-0.448-1-1s0.448-1,1-1h8c0.552,0,1,0.448,1,1S28.552,31,28,31z"
                    })));
}

var make = IconTerminal;

export {
  make ,
  
}
/* Css Not a pure module */
