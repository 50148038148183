// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(request) {
  return Json_encode.object_({
              hd: [
                "schemaId",
                request.schemaId
              ],
              tl: {
                hd: [
                  "integrationId",
                  request.integrationId
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          integrationId: Json_decode.field("integrationId", Json_decode.string, json)
        };
}

var $$Request = {
  encode: encode,
  decode: decode
};

function encode$1(resp) {
  return Json_encode.object_({
              hd: [
                "key",
                resp.key
              ],
              tl: /* [] */0
            });
}

function decode$1(json) {
  return {
          key: Json_decode.field("key", Json_decode.string, json)
        };
}

var $$Response = {
  encode: encode$1,
  decode: decode$1
};

export {
  $$Request ,
  $$Response ,
  
}
/* No side effect */
