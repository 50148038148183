// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconIdentifier(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light07;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  }),
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  strokeWidth: "1"
                }, React.createElement("polygon", {
                      fill: "none",
                      points: "1.5,15.5 1.5,6.5 8,0.5 14.5,6.5 14.5,15.5 ",
                      strokeLinecap: "round"
                    }), React.createElement("circle", {
                      cx: "8",
                      cy: "7",
                      fill: "none",
                      r: "1.5",
                      strokeLinecap: "round"
                    }), React.createElement("line", {
                      fill: "none",
                      strokeLinecap: "round",
                      x1: "4.5",
                      x2: "11.5",
                      y1: "11.5",
                      y2: "11.5"
                    })));
}

var make = IconIdentifier;

export {
  make ,
  
}
/* Css Not a pure module */
