// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../app/src/ModelUtils.mjs";
import * as ComputeUnionAllowedValuesUseCase from "../../model/src/eventSpecificPropertyValues/ComputeUnionAllowedValuesUseCase.mjs";

function copyAllowedValues(model, sourceId) {
  if (ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues")) {
    return {
            types: model.types,
            properties: Belt_List.mapU(model.properties, (function (property) {
                    if (property.TAG === /* PropertyRef */0) {
                      return {
                              TAG: /* PropertyRef */0,
                              _0: property._0
                            };
                    }
                    var property$1 = property._0;
                    var allowedValues = ComputeUnionAllowedValuesUseCase.computeWithSlowModel(model, [sourceId], property$1);
                    var newMatches = Belt_List.fromArray(Belt_Array.mapU(allowedValues, (function (value) {
                                return [
                                        {
                                          NAME: "Literal",
                                          VAL: {
                                            NAME: "StringLit",
                                            VAL: value
                                          }
                                        },
                                        /* [] */0
                                      ];
                              })));
                    var existingValidationsWithoutMatches = Belt_List.keepU(property$1.validations, (function (item) {
                            if (typeof item === "object") {
                              return item.NAME !== "Matches";
                            } else {
                              return true;
                            }
                          }));
                    var validations = Belt_List.concat(existingValidationsWithoutMatches, Belt_List.length(newMatches) > 0 ? ({
                              hd: {
                                NAME: "Matches",
                                VAL: newMatches
                              },
                              tl: /* [] */0
                            }) : /* [] */0);
                    var newrecord = Caml_obj.caml_obj_dup(property$1);
                    return {
                            TAG: /* Property */1,
                            _0: (newrecord.validations = validations, newrecord)
                          };
                  })),
            propertyBundles: model.propertyBundles,
            events: model.events,
            migrations: model.migrations,
            sources: model.sources,
            destinations: model.destinations,
            groupTypes: model.groupTypes,
            goals: model.goals,
            metrics: model.metrics,
            archive: model.archive,
            openBranches: model.openBranches,
            branchPointer: model.branchPointer,
            rules: model.rules,
            integrations: model.integrations,
            globalRequirements: model.globalRequirements
          };
  } else {
    return model;
  }
}

export {
  copyAllowedValues ,
  
}
/* ModelUtils Not a pure module */
