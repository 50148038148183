// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

function getEventsSendingLiteral(propertyEvents, disallowedEvents) {
  return Belt_Array.keep(propertyEvents, (function ($$event) {
                var eventIncludeSourcesSet = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map($$event.includeSources, (function (param) {
                                return param.id;
                              }))));
                var match = Belt_MapString.get(disallowedEvents, $$event.id);
                if (match !== undefined && !(match && !Belt_SetString.eq(Belt_SetString.intersect(match._0, eventIncludeSourcesSet), eventIncludeSourcesSet))) {
                  return false;
                } else {
                  return true;
                }
              }));
}

export {
  getEventsSendingLiteral ,
  
}
/* No side effect */
