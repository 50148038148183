// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dopt from "../externals/Dopt.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Issues from "../../../shared/models/Issues.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as FirebaseUtils from "../firebaseUtils.mjs";
import * as SimpleTooltip from "../SimpleTooltip.mjs";
import * as ViewerContext from "../ViewerContext.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as ConnectToSlackButton from "./ConnectToSlackButton.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as InspectorIssuesStore from "./InspectorIssuesStore.mjs";
import * as InspectorIssuesFilterHooks from "./InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesFilterUtils from "../../../shared/utils/InspectorIssuesFilterUtils.mjs";

function handleDisconnect(channel, eventType, globalSend, schemaId, viewName, savedView, sendActions, viewerId, onConfirm, onSuccess) {
  return Curry._1(globalSend, {
              TAG: /* OpenModal */4,
              _0: {
                NAME: "ConfirmModal",
                VAL: [
                  "Disconnect Slack alerts for " + viewName + "?",
                  "This will stop delivery of alerts on new tracking issues detected by Avo Inspector. Are you sure you want to disconnect?",
                  "Disconnect",
                  (function (param) {
                      Curry._1(onConfirm, undefined);
                      ConnectToSlackButton.ConnectedToSlack.removeEventType(schemaId, channel, eventType).then(function (param) {
                            Belt_Option.forEach(savedView, (function (savedView) {
                                    return FirebaseUtils.updateSavedView(sendActions, schemaId, savedView, viewerId, (function (param) {
                                                  
                                                }), (function (param) {
                                                  
                                                }));
                                  }));
                            return Curry._1(onSuccess, undefined);
                          });
                      
                    }),
                  (function (param) {
                      
                    })
                ]
              }
            });
}

function useChannelToasts(channels) {
  var addToast = Toast.useAddToast(undefined);
  var previousChannels = Hooks.usePrevious1(channels);
  var visibility = Hooks.useDocumentVisibility(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setShouldCompleteOnVisibilityChange = match[1];
  var shouldCompleteOnVisibilityChange = match[0];
  var match$1 = Dopt.Block.use("set-up-alerts.step-2");
  var alertsStepTransition = match$1[1];
  var alertsStep = match$1[0];
  React.useEffect((function () {
          if (typeof previousChannels === "object" && previousChannels.NAME === "Loaded") {
            var match = previousChannels.VAL;
            if (match) {
              if (typeof channels === "object" && channels.NAME === "Loaded" && !channels.VAL) {
                Curry._1(addToast, {
                      message: "Disconnected channel " + match.hd.metadata.channelName,
                      toastType: /* Success */0
                    });
              }
              
            } else if (typeof channels === "object" && channels.NAME === "Loaded") {
              var match$1 = channels.VAL;
              if (match$1) {
                if (visibility === "visible") {
                  if (alertsStep.state.active) {
                    Curry._1(alertsStepTransition, "complete");
                  }
                  
                } else {
                  Curry._1(setShouldCompleteOnVisibilityChange, (function (param) {
                          return true;
                        }));
                }
                Curry._1(addToast, {
                      message: "Receiving alerts in " + match$1.hd.metadata.channelName,
                      toastType: /* Success */0
                    });
              }
              
            }
            
          }
          
        }), [channels]);
  React.useEffect((function () {
          if (shouldCompleteOnVisibilityChange && visibility === "visible") {
            if (alertsStep.state.active) {
              Curry._1(alertsStepTransition, "complete");
            }
            Curry._1(setShouldCompleteOnVisibilityChange, (function (param) {
                    return false;
                  }));
          }
          
        }), [
        shouldCompleteOnVisibilityChange,
        visibility
      ]);
  
}

function ConfigureAlertsButton$ConnectedButton(Props) {
  var schemaId = Props.schemaId;
  var globalSend = Props.globalSend;
  var channel = Props.channel;
  var viewName = Props.viewName;
  var eventType = Props.eventType;
  var styleOpt = Props.style;
  var savedView = Props.savedView;
  var style = styleOpt !== undefined ? styleOpt : "outline";
  var match = React.useState(function () {
        return false;
      });
  var setDisconnecting = match[1];
  var disconnecting = match[0];
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match$1 = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match$1.orderedBy;
  var filters = match$1.filters;
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match$2 = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var status = match$2[0];
  var match$3 = InspectorIssuesStore.useFilteredIssues(status, undefined, undefined);
  var filteredIssues = match$3.filteredIssues;
  var match$4 = ViewerContext.use(undefined);
  var viewerId = match$4.id;
  var match$5 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedViewStatus = match$5.savedViewStatus;
  var sendActions = SendActionsContext.use(undefined);
  return React.createElement(SimpleTooltip.make, {
              maxWidth: 200,
              tooltip: disconnecting ? "Disconnecting…" : "Alerts for new issues on this view are sent to " + channel.metadata.channelName + ".\nClick to disconnect",
              children: React.createElement(Button.make, {
                    disabled: disconnecting,
                    icon: "slack",
                    label: channel.metadata.channelName,
                    loading: disconnecting,
                    onClick: (function (param) {
                        return handleDisconnect(channel, eventType, globalSend, schemaId, viewName, savedView, sendActions, viewerId, (function (param) {
                                      return Curry._1(setDisconnecting, (function (param) {
                                                    return true;
                                                  }));
                                    }), (function (param) {
                                      return AnalyticsRe.inspectorAlertsDisabled(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_Option.mapWithDefault(savedView, "All issues", (function (param) {
                                                        return param.name;
                                                      })), Belt_Option.mapWithDefault(savedView, "all-issues", (function (param) {
                                                        return param.id;
                                                      })), savedView !== undefined && viewerId === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" ? Belt_List.size(savedViews.VAL) : -1, InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, filteredIssues), InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), "Slack", workspace.id);
                                    }));
                      }),
                    size: "small",
                    style: style,
                    singleLine: true
                  })
            });
}

var ConnectedButton = {
  make: ConfigureAlertsButton$ConnectedButton
};

function ConfigureAlertsButton$InTable(Props) {
  var globalSend = Props.globalSend;
  var savedView = Props.savedView;
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  var eventType = Belt_Option.mapWithDefault(savedView, /* InspectorIssues */0, (function (param) {
          return /* SavedView */{
                  _0: param.id
                };
        }));
  var viewName = Belt_Option.mapWithDefault(savedView, "all issues", (function (param) {
          return param.name;
        }));
  var channels = FirebaseFetcherHooks.useNotificationChannels(undefined, schemaId, eventType);
  useChannelToasts(channels);
  if (typeof channels !== "object") {
    return null;
  }
  var match$1 = channels.VAL;
  if (match$1) {
    return React.createElement(ConfigureAlertsButton$ConnectedButton, {
                schemaId: schemaId,
                globalSend: globalSend,
                channel: match$1.hd,
                viewName: viewName,
                eventType: eventType,
                style: "ghost",
                savedView: savedView
              });
  } else {
    return React.createElement(SimpleTooltip.make, {
                maxWidth: 200,
                tooltip: "Get alerted for every new issue Avo detects for this view",
                children: React.createElement(Button.make, {
                      icon: "bell",
                      label: "Set up alerts",
                      onClick: (function (param) {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "IssueAlertsSetup",
                                        VAL: savedView
                                      }
                                    });
                        }),
                      size: "small",
                      style: "ghost"
                    })
              });
  }
}

var InTable = {
  make: ConfigureAlertsButton$InTable
};

function ConfigureAlertsButton(Props) {
  var globalSend = Props.globalSend;
  var savedView = Props.savedView;
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  var match$1 = InspectorIssuesFilterHooks.useDraftState(undefined);
  var isDraft = match$1.isDraft;
  var eventType = Belt_Option.mapWithDefault(savedView, /* InspectorIssues */0, (function (param) {
          return /* SavedView */{
                  _0: param.id
                };
        }));
  var viewName = Belt_Option.mapWithDefault(savedView, "all issues", (function (param) {
          return param.name;
        }));
  var channels = FirebaseFetcherHooks.useNotificationChannels(undefined, schemaId, eventType);
  useChannelToasts(channels);
  var tmp;
  var exit = 0;
  var channel;
  if (savedView !== undefined) {
    if (typeof channels === "object" && channels.NAME === "Loaded") {
      var match$2 = channels.VAL;
      if (match$2) {
        channel = match$2.hd;
        exit = 3;
      } else {
        exit = isDraft ? 1 : 2;
      }
    } else {
      tmp = null;
    }
  } else if (isDraft) {
    exit = 1;
  } else if (typeof channels === "object" && channels.NAME === "Loaded") {
    var match$3 = channels.VAL;
    if (match$3) {
      channel = match$3.hd;
      exit = 3;
    } else {
      exit = 2;
    }
  } else {
    tmp = null;
  }
  switch (exit) {
    case 1 :
        tmp = React.createElement(SimpleTooltip.make, {
              maxWidth: 200,
              tooltip: "Save and get alerted for every new issue Avo detects for this view",
              children: React.createElement(Button.make, {
                    icon: "bell",
                    label: "Set up alerts",
                    onClick: (function (param) {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: {
                                      NAME: "IssueAlertsSetup",
                                      VAL: savedView
                                    }
                                  });
                      }),
                    size: "small",
                    style: "outline"
                  })
            });
        break;
    case 2 :
        tmp = React.createElement(SimpleTooltip.make, {
              maxWidth: 200,
              tooltip: "Get alerted for every new issue Avo detects for this view",
              children: React.createElement(Button.make, {
                    icon: "bell",
                    label: "Set up alerts",
                    onClick: (function (param) {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: {
                                      NAME: "IssueAlertsSetup",
                                      VAL: savedView
                                    }
                                  });
                      }),
                    size: "small",
                    style: "primary"
                  })
            });
        break;
    case 3 :
        tmp = React.createElement(ConfigureAlertsButton$ConnectedButton, {
              schemaId: schemaId,
              globalSend: globalSend,
              channel: channel,
              viewName: viewName,
              eventType: eventType,
              savedView: savedView
            });
        break;
    
  }
  return React.createElement("span", {
              id: "configure-alerts-button"
            }, tmp);
}

var make = ConfigureAlertsButton;

export {
  handleDisconnect ,
  useChannelToasts ,
  ConnectedButton ,
  InTable ,
  make ,
  
}
/* Dopt Not a pure module */
