// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Router from "./Router.mjs";
import * as $$Comment from "./Comment.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ObjectActivityLogAction from "./ObjectActivityLogAction.mjs";

function GoalActivityItem(Props) {
  var model = Props.model;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var goalName = Props.goalName;
  var showComments = Props.showComments;
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "CompletedImport" || variant === "ImportDeprecated") {
    return null;
  }
  if (variant === "AddGoal") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "created the category"
              });
  }
  if (variant === "CreateDemoBranch") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "created the category on a demo branch"
              });
  }
  if (variant === "UpdateGoalDescription") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "updated the category description"
              });
  }
  if (variant === "ReorderEventsInGoal" || variant === "ReorderEventsInGoalV2") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "reordered the events"
              });
  }
  if (variant === "ReorderMetricsInGoalV2" || variant === "ReorderMetricsInGoal") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "reordered the metrics"
              });
  }
  if (variant === "Archive") {
    var match$1 = match.VAL;
    if (typeof match$1 === "object" && match$1.NAME === "Goal") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "archived the category"
                });
    } else {
      return null;
    }
  }
  if (variant === "GoalComment") {
    if (!showComments) {
      return null;
    }
    var match$2 = match.VAL;
    var goalId = match$2[0];
    return React.createElement($$Comment.make, {
                threadId: goalId,
                itemType: "Goal",
                itemName: goalName,
                commentId: match$2[1],
                events: model.events,
                userId: userId,
                goToId: goToId,
                action: action,
                link: (function (commentId) {
                    return Router.Link.addDrawerItem(undefined, {
                                NAME: "category",
                                VAL: [
                                  goalId,
                                  commentId
                                ]
                              });
                  }),
                openBranches: openBranches
              });
  }
  if (variant === "AddMetricToGoal") {
    var metric = ModelUtils.getMetricByIdWithArchive(match.VAL[1], model);
    if (metric !== undefined) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "added the metric ", React.createElement("b", undefined, metric.name));
    } else {
      return null;
    }
  }
  if (variant === "AddEventToGoal") {
    var $$event = ModelUtils.getEventByIdWithArchive(match.VAL[1], model);
    if ($$event !== undefined) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "added the event ", React.createElement("b", undefined, $$event.name));
    } else {
      return null;
    }
  }
  if (variant === "UpdateGoalName") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, "renamed the category to ", React.createElement("b", undefined, match.VAL[1]));
  }
  if (variant === "RemoveEventFromGoal") {
    var $$event$1 = ModelUtils.getEventByIdWithArchive(match.VAL[1], model);
    if ($$event$1 !== undefined) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "removed the event ", React.createElement("b", undefined, $$event$1.name));
    } else {
      return null;
    }
  }
  if (variant === "RemoveMetricFromGoal") {
    var metric$1 = ModelUtils.getMetricByIdWithArchive(match.VAL[1], model);
    if (metric$1 !== undefined) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "removed the metric ", React.createElement("b", undefined, metric$1.name));
    } else {
      return null;
    }
  }
  if (variant !== "Unarchive") {
    return null;
  }
  var match$3 = match.VAL;
  if (typeof match$3 === "object" && match$3.NAME === "Goal") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "unarchived the category"
              });
  } else {
    return null;
  }
}

var make = GoalActivityItem;

export {
  make ,
  
}
/* react Not a pure module */
