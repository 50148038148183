// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconSettings(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var hoverColorOpt = Props.hoverColor;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light08;
  var hoverColor = hoverColorOpt !== undefined ? hoverColorOpt : Styles.Color.light10;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css.hover({
                            hd: Css_Legacy_Core.SVG.fill(hoverColor),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }),
              width: String(size),
              fill: "none",
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M13.3 5.2L14.4 3.1L13 1.7L10.9 2.8C10.6 2.6 10.2 2.5 9.8 2.4L9 0H7L6.2 2.3C5.9 2.4 5.5 2.5 5.2 2.7L3.1 1.6L1.6 3.1L2.7 5.2C2.5 5.5 2.4 5.9 2.3 6.2L0 7V9L2.3 9.8C2.4 10.2 2.6 10.5 2.7 10.9L1.6 13L3 14.4L5.1 13.3C5.4 13.5 5.8 13.6 6.2 13.7L7 16H9L9.8 13.7C10.2 13.6 10.5 13.4 10.9 13.3L13 14.4L14.4 13L13.3 10.9C13.5 10.6 13.6 10.2 13.7 9.8L16 9V7L13.7 6.2C13.6 5.9 13.5 5.5 13.3 5.2ZM8 11C6.3 11 5 9.7 5 8C5 6.3 6.3 5 8 5C9.7 5 11 6.3 11 8C11 9.7 9.7 11 8 11Z"
                }));
}

var make = IconSettings;

export {
  make ,
  
}
/* Css Not a pure module */
