// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dopt from "../externals/Dopt.mjs";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var defaultContext_flow = Dopt.Flow.empty;

function defaultContext_getStepNumber(param) {
  
}

var defaultContext = {
  flow: defaultContext_flow,
  getStepNumber: defaultContext_getStepNumber,
  resetOnExit: false,
  showStepCount: true,
  totalSteps: 0,
  tourId: "",
  tourIdOverride: undefined,
  tourMayShow: false
};

var context = React.createContext(defaultContext);

var provider = context.Provider;

function use(param) {
  return React.useContext(context);
}

function ProductTour__TourProvider(Props) {
  var flow = Props.flow;
  var getStepNumber = Props.getStepNumber;
  var resetOnExit = Props.resetOnExit;
  var showStepCountOpt = Props.showStepCount;
  var tourId = Props.tourId;
  var tourIdOverride = Props.tourIdOverride;
  var tourMayShow = Props.tourMayShow;
  var totalStepsOverride = Props.totalStepsOverride;
  var children = Props.children;
  var showStepCount = showStepCountOpt !== undefined ? showStepCountOpt : false;
  return React.createElement(provider, {
              value: {
                flow: flow,
                getStepNumber: getStepNumber,
                resetOnExit: resetOnExit,
                showStepCount: showStepCount,
                totalSteps: Belt_Option.getWithDefault(totalStepsOverride, Dopt.Flow.getOrderedBlocks(flow).numSteps),
                tourId: tourId,
                tourIdOverride: tourIdOverride,
                tourMayShow: tourMayShow
              },
              children: children
            });
}

var make = ProductTour__TourProvider;

export {
  defaultContext ,
  context ,
  provider ,
  use ,
  make ,
  
}
/* context Not a pure module */
