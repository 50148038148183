// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import LodashDebounce from "lodash.debounce";

function debouncedFunction(timeout) {
  return LodashDebounce((function (cb) {
                return Curry._1(cb, undefined);
              }), timeout, {
              leading: false,
              trailing: true
            });
}

export {
  debouncedFunction ,
  
}
/* lodash.debounce Not a pure module */
