// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Guides from "../markdown/guides.mjs";

function init(param) {
  _iub.cons.init({
        api_key: "Z1awIDZya0ywCaQiOMIYE9gbAD9qxUNZ",
        log_level: "fatal",
        logger: "console"
      });
  
}

function submit(userId, email, formString) {
  var payload = {
    consent: {
      subject: {
        id: userId,
        email: email
      },
      legal_notices: [
        {
          identifier: "privacy_policy",
          content: undefined
        },
        {
          identifier: "cookie_policy",
          content: undefined
        },
        {
          identifier: "terms",
          content: Guides.terms
        }
      ],
      preferences: {
        terms: true,
        privacy: true,
        cookies: true
      },
      proofs: [{
          content: JSON.stringify({
                email: email
              }),
          form: formString
        }]
    }
  };
  _iub.cons.submit(payload);
  
}

export {
  init ,
  submit ,
  
}
/* Guides Not a pure module */
