// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as BranchStateFirebaseModelUtils from "../../shared/models/BranchStateFirebaseModelUtils.mjs";

function getBackgroundColor(status) {
  if (status === "Closed") {
    return Styles.Color.lightRed;
  } else if (status === "ChangesRequested") {
    return Styles.Color.lightOrange;
  } else if (status === "Approved") {
    return Styles.Color.lightGreen;
  } else if (status === "Open" || status === "Unknown" || status === "Draft") {
    return Styles.Color.light03;
  } else if (status === "Merged") {
    return Styles.Color.lightBlue;
  } else {
    return Styles.Color.lightPurple;
  }
}

function getColor(status) {
  if (status === "Closed") {
    return Styles.Color.deepRed;
  } else if (status === "ChangesRequested") {
    return Styles.Color.deepOrange;
  } else if (status === "Approved") {
    return Styles.Color.deepGreen;
  } else if (status === "Open" || status === "Unknown" || status === "Draft") {
    return Styles.Color.light12;
  } else if (status === "Merged") {
    return Styles.Color.deepBlue;
  } else {
    return Styles.Color.deepPurple;
  }
}

function BranchStatusPill(Props) {
  var status = Props.status;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width("fitContent"),
                    tl: {
                      hd: Css.whiteSpace("nowrap"),
                      tl: {
                        hd: Css.marginRight(Css.px(4)),
                        tl: {
                          hd: Css.borderRadius(Css.px(32)),
                          tl: {
                            hd: Css.backgroundColor(getBackgroundColor(status)),
                            tl: {
                              hd: Css.fontFamily(Styles.FontFamily.polar),
                              tl: {
                                hd: Css.fontSize(Css.px(12)),
                                tl: {
                                  hd: Css.fontWeight("medium"),
                                  tl: {
                                    hd: Css.color(getColor(status)),
                                    tl: {
                                      hd: Css.flexShrink(0.0),
                                      tl: {
                                        hd: Css.paddingTop(Css.px(3)),
                                        tl: {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingBottom(Css.px(5)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(12)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, BranchStateFirebaseModelUtils.toString(status));
}

var make = BranchStatusPill;

export {
  getBackgroundColor ,
  getColor ,
  make ,
  
}
/* Css Not a pure module */
