// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Tag from "./Tag.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function DiffEventGroups(Props) {
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var maybeAddGroupTypeToLogEventAction = Belt_List.getByU(actions, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "AddGroupTypeToLogEvent";
          } else {
            return false;
          }
        }));
  var maybeRemoveGroupTypeFromLogEvent = Belt_List.getByU(actions, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "RemoveGroupTypeFromLogEvent";
          } else {
            return false;
          }
        }));
  var maybeAddUserToGroup = Belt_List.getByU(actions, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "AddUserToGroup";
          } else {
            return false;
          }
        }));
  var maybeRemoveUserFromGroup = Belt_List.getByU(actions, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "RemoveUserFromGroup";
          } else {
            return false;
          }
        }));
  var eventAssociationChanges = Belt_Option.isSome(maybeAddGroupTypeToLogEventAction) || Belt_Option.isSome(maybeRemoveGroupTypeFromLogEvent);
  var userAssociationChanges = Belt_Option.isSome(maybeAddUserToGroup) || Belt_Option.isSome(maybeRemoveUserFromGroup);
  var groupAssociationChanges = eventAssociationChanges || userAssociationChanges;
  if (groupAssociationChanges) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.padding2(Css.px(10), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(2), Css.px(10), Css.px(0), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(Icons.UpdateGroup.make, {
                        size: 16,
                        color: Styles.Color.light07
                      })), React.createElement("div", undefined, eventAssociationChanges ? React.createElement("div", undefined, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: "Groups event is associated with"
                            }), React.createElement(Spacer.make, {
                              height: 4
                            }), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(actions, (function (action) {
                                            if (typeof action === "object" && action.NAME === "RemoveGroupTypeFromLogEvent") {
                                              return ModelUtils.getGroupTypeName(action.VAL[1], branchModel);
                                            }
                                            
                                          })), (function (groupTypeName) {
                                        return React.createElement(Tag.make, {
                                                    tag: "- " + groupTypeName.toUpperCase(),
                                                    hue: "red",
                                                    tooltip: "This event will no longer be associated with this group after this change",
                                                    key: groupTypeName
                                                  });
                                      }))), Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(actions, (function (action) {
                                            if (typeof action === "object" && action.NAME === "AddGroupTypeToLogEvent") {
                                              return ModelUtils.getGroupTypeName(action.VAL[1], branchModel);
                                            }
                                            
                                          })), (function (groupTypeName) {
                                        return React.createElement(Tag.make, {
                                                    tag: "+ " + groupTypeName.toUpperCase(),
                                                    hue: "green",
                                                    tooltip: "This event will be associated with this group after this change",
                                                    key: groupTypeName
                                                  });
                                      })))), React.createElement(Spacer.make, {
                              height: 8
                            })) : null, userAssociationChanges ? React.createElement("div", undefined, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: "Groups user is added to"
                            }), React.createElement(Spacer.make, {
                              height: 4
                            }), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(actions, (function (action) {
                                            if (typeof action === "object" && action.NAME === "RemoveUserFromGroup") {
                                              return ModelUtils.getGroupTypeName(action.VAL[1], branchModel);
                                            }
                                            
                                          })), (function (groupTypeName) {
                                        return React.createElement(Tag.make, {
                                                    tag: "- " + groupTypeName.toUpperCase(),
                                                    hue: "red",
                                                    tooltip: "This event will no longer add the user to this group after this change",
                                                    key: groupTypeName
                                                  });
                                      }))), Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(actions, (function (action) {
                                            if (typeof action === "object" && action.NAME === "AddUserToGroup") {
                                              return ModelUtils.getGroupTypeName(action.VAL[1], branchModel);
                                            }
                                            
                                          })), (function (groupTypeName) {
                                        return React.createElement(Tag.make, {
                                                    tag: "+ " + groupTypeName.toUpperCase(),
                                                    hue: "green",
                                                    tooltip: "This event will add the user to this group after this change",
                                                    key: groupTypeName
                                                  });
                                      }))))) : null));
  } else {
    return null;
  }
}

var make = DiffEventGroups;

export {
  make ,
  
}
/* Css Not a pure module */
