// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Timestamp from "../Timestamp.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "../ContextMenu.mjs";
import * as ProfilePhoto from "../ProfilePhoto.mjs";
import * as FirebaseUtils from "../firebaseUtils.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as ConfigureAlertsButton from "./ConfigureAlertsButton.mjs";

function InspectorViewsTableConfig$LastModified(Props) {
  var view = Props.view;
  var lastModifiedById = Belt_Option.getWithDefault(view.lastModifiedBy, view.createdBy);
  var userState = FirebaseFetcherHooks.useUser(lastModifiedById);
  var name = typeof userState === "object" ? AvoConfig.getUserDisplayName(userState.VAL) : undefined;
  return React.createElement($$Text.make, {
              size: "Small",
              color: Styles.Color.light10,
              children: null
            }, Timestamp.renderJsTimestamp(Belt_Option.getWithDefault(view.lastModifiedAt, view.createdAt)), name !== undefined ? " by " + name : null);
}

var LastModified = {
  make: InspectorViewsTableConfig$LastModified
};

function InspectorViewsTableConfig$Menu(Props) {
  var view = Props.view;
  var withArchiveOpt = Props.withArchive;
  var globalSend = Props.globalSend;
  var withArchive = withArchiveOpt !== undefined ? withArchiveOpt : true;
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  var sendActions = SendActionsContext.use(undefined);
  var handleArchive = function (param) {
    return Curry._1(globalSend, {
                TAG: /* OpenModal */4,
                _0: {
                  NAME: "ConfirmModal",
                  VAL: [
                    "Archive View",
                    "Are you sure you want to archive this saved view?",
                    "Archive",
                    (function (param) {
                        return FirebaseUtils.archiveSavedView(sendActions, schemaId, view.id, view.name, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid);
                      }),
                    (function (param) {
                        
                      })
                  ]
                }
              });
  };
  if (withArchive) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }),
                onClick: (function (prim) {
                    prim.stopPropagation();
                    
                  })
              }, React.createElement(ContextMenu.V2.make, {
                    position: "bottom-end",
                    children: React.createElement(ContextMenu.V2.Item.make, {
                          label: "Archive View",
                          onClick: handleArchive,
                          style: "Warning"
                        })
                  }));
  } else {
    return null;
  }
}

var Menu = {
  make: InspectorViewsTableConfig$Menu
};

function InspectorViewsTableConfig$IssueCount(Props) {
  var issuesCount = Props.issuesCount;
  return React.createElement($$Text.make, {
              size: "Small",
              color: Styles.Color.light10,
              children: String(issuesCount)
            });
}

var IssueCount = {
  make: InspectorViewsTableConfig$IssueCount
};

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.overflow("hidden"),
          tl: /* [] */0
        }
      }
    });

function InspectorViewsTableConfig$CreatedBy(Props) {
  var id = Props.id;
  var userState = FirebaseFetcherHooks.useUser(id);
  if (id === "avo@avo.app") {
    return React.createElement("div", {
                className: rootStyles
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.borderRadius(Css.px(12)),
                          tl: {
                            hd: Css.overflow("hidden"),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.width(Css.px(24)),
                                    tl: {
                                      hd: Css.height(Css.px(24)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.light12),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Icon.make, {
                        type_: "avoSmall",
                        size: {
                          NAME: "int",
                          VAL: 22
                        }
                      })), React.createElement(Spacer.make, {
                    width: 8
                  }), React.createElement($$Text.make, {
                    element: "Span",
                    size: "Small",
                    singleLine: true,
                    color: Styles.Color.light10,
                    children: "Avo"
                  }));
  }
  if (typeof userState !== "object") {
    return null;
  }
  var user = userState.VAL;
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(ProfilePhoto.make, {
                  user: user,
                  size: 24
                }), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  singleLine: true,
                  color: Styles.Color.light10,
                  children: AvoConfig.getUserDisplayName(user)
                }));
}

var CreatedBy = {
  rootStyles: rootStyles,
  make: InspectorViewsTableConfig$CreatedBy
};

function InspectorViewsTableConfig$Alerts(Props) {
  var globalSend = Props.globalSend;
  var savedView = Props.savedView;
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.marginLeft(Css.px(-8)),
                    tl: {
                      hd: Css.maxWidth(Css.pct(100.0)),
                      tl: {
                        hd: Css.selector(" button", {
                              hd: Css.maxWidth(Css.pct(100.0)),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }),
              onClick: (function (prim) {
                  prim.stopPropagation();
                  
                })
            }, React.createElement(ConfigureAlertsButton.InTable.make, {
                  globalSend: globalSend,
                  savedView: savedView
                }));
}

var Alerts = {
  make: InspectorViewsTableConfig$Alerts
};

function getRowId(view) {
  return view.id;
}

var CustomViews_defaultOrder = [
  "lastModified",
  "desc"
];

var CustomViews = {
  getRowId: getRowId,
  defaultOrder: CustomViews_defaultOrder,
  tableName: "saved-views"
};

function getRowId$1(view) {
  return view.id;
}

var AvoViews = {
  getRowId: getRowId$1,
  defaultOrder: undefined,
  tableName: "avo-views"
};

export {
  LastModified ,
  Menu ,
  IssueCount ,
  CreatedBy ,
  Alerts ,
  CustomViews ,
  AvoViews ,
  
}
/* rootStyles Not a pure module */
