// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

function filterEmptyValidations(model) {
  return {
          types: model.types,
          properties: Belt_List.mapU(model.properties, (function (property) {
                  if (property.TAG === /* PropertyRef */0) {
                    return property;
                  }
                  var property$1 = property._0;
                  var newrecord = Caml_obj.caml_obj_dup(property$1);
                  return {
                          TAG: /* Property */1,
                          _0: (newrecord.validations = Belt_List.keep(property$1.validations, (function (validation) {
                                    var variant = validation.NAME;
                                    if (variant === "EndsWith" || variant === "Shape" || variant === "Contains" || variant === "Min" || variant === "Max" || variant === "StartsWith") {
                                      return true;
                                    }
                                    if (variant !== "Regex") {
                                      if (variant === "NestedProperty") {
                                        if (validation.VAL) {
                                          return true;
                                        } else {
                                          return false;
                                        }
                                      } else if (validation.VAL) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }
                                    var match = validation.VAL;
                                    if (match.propertyRule !== undefined || !Belt_MapString.isEmpty(match.eventOverrides)) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  })), newrecord)
                        };
                })),
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

export {
  filterEmptyValidations ,
  
}
/* No side effect */
