// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as SheetUtils from "./SheetUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as SheetHeader from "./SheetHeader.mjs";
import * as ReactWindow from "react-window";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as SheetContainer from "./SheetContainer.mjs";
import * as PropertiesSheet from "./PropertiesSheet.mjs";
import * as StateFilterUtils from "./StateFilterUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as PropertiesSheetRow from "./PropertiesSheetRow.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";

var headerStyles = Curry._1(Css.style, {
      hd: Css.height(Css.px(64)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingLeft(Css.px(30)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function sheet(withSandboxBanner) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.height({
                      NAME: "subtract",
                      VAL: [
                        Css.vh(100),
                        Css.px(104 + SandboxBanner.getHeight(withSandboxBanner) | 0)
                      ]
                    }),
                tl: {
                  hd: Css.paddingLeft(Css.px(16)),
                  tl: {
                    hd: Css.position("relative"),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Style = {
  headerHeight: 64,
  columnsHeaderHeight: 40,
  headerStyles: headerStyles,
  sheet: sheet
};

function PropertyGroupsSheet(Props) {
  var currentBranch = Props.currentBranch;
  var globalSend = Props.globalSend;
  var model = Props.model;
  var user = Props.user;
  var viewerCanEdit = Props.viewerCanEdit;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = GlobalStateContext.use(undefined);
  var globalStateFilters = match.filters;
  var workspace = WorkspaceContext.use(undefined);
  var currentFilters = AnalyticsUtils.currentFilters(model, globalStateFilters, match.eventSort);
  var filters = Belt_List.fromArray(Belt_Array.keepMapU(globalStateFilters, StateFilterUtils.toModel));
  var sourceFilterNames = StateFilterUtils.getSourceFilterNames(globalStateFilters, model);
  var match$1 = SheetUtils.Properties.usePropertySheetColumns(user);
  var columns = match$1[0];
  var match$2 = SheetUtils.Properties.useScrollSync(undefined);
  var handleReactWindowRef = match$2.handleReactWindowRef;
  var handleOuterRef = match$2.handleOuterRef;
  var items = SheetUtils.Properties.getPropertyGroups(model.propertyBundles, model.properties);
  var hasEmptyState = model.propertyBundles === /* [] */0;
  React.useEffect((function () {
          if (hasEmptyState) {
            AnalyticsRe.emptyStateDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "PropertyGroups", schemaBundle.schemaId);
          }
          
        }), []);
  var mappedEvents = ModelStore.Mapped.useEvents(undefined);
  var mappedProperties = ModelStore.Mapped.useProperties(undefined);
  var mappedPropertyBundles = ModelStore.Mapped.usePropertyBundles(undefined);
  var eventsSendingPropertiesMap = React.useMemo((function () {
          return PropertiesSheet.getEventsSendingPropertiesMap(mappedEvents, mappedProperties, mappedPropertyBundles);
        }), [
        mappedEvents,
        mappedProperties,
        mappedPropertyBundles
      ]);
  var makeItem = function (item) {
    return {
            item: item,
            columns: columns,
            currentBranch: currentBranch,
            model: model,
            globalSend: globalSend,
            sendActions: sendActions,
            schemaBundle: schemaBundle,
            currentFilters: currentFilters,
            viewerCanEdit: viewerCanEdit,
            filters: filters,
            sourceFilterNames: sourceFilterNames,
            eventsSendingPropertiesMap: eventsSendingPropertiesMap
          };
  };
  var itemData = hasEmptyState ? [makeItem(/* PropertyGroupEmptyState */2)] : Belt_Array.map(items, makeItem);
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyles
                }, React.createElement(Title.make, {
                      children: null,
                      shrink: 0.0,
                      size: "Tiny"
                    }, "Event Property Bundles ", React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          color: Styles.Color.light10,
                          children: "(" + Belt_List.length(model.propertyBundles).toString() + ")"
                        })), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(Button.make, {
                      label: "Add Event Property Bundle",
                      onClick: (function (param) {
                          if (Belt_List.length(model.propertyBundles) === 0) {
                            AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "PropertyGroups", "Setup", undefined);
                          }
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "NewPropertyGroup",
                                        VAL: [
                                          undefined,
                                          undefined
                                        ]
                                      }
                                    });
                        }),
                      size: "small"
                    }), React.createElement(Spacer.make, {
                      width: 16,
                      grow: 1
                    }), React.createElement(Link.make, {
                      path: Router.Link.getSchemaRouteLink(undefined, "properties"),
                      children: null
                    }, React.createElement(Icons.ArrowLeft.make, {
                          size: 8,
                          color: Styles.Color.darkBlue
                        }), React.createElement($$Text.make, {
                          element: "Span",
                          size: "Small",
                          weight: "Medium",
                          color: Styles.Color.darkBlue,
                          children: " View All Properties"
                        })), React.createElement(Spacer.make, {
                      width: 16
                    })), React.createElement(SheetHeader.make, {
                  columns: columns,
                  setColumns: match$1[1],
                  view: /* ByCategory */0,
                  onWidthChange: (function (direction, param, columnType) {
                      return AnalyticsRe.propertiesViewConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, direction === "Increase" ? "IncreaseColumnWidth" : "DecreaseColumnWidth", undefined, undefined, AnalyticsUtils.columnTypeToPropertyColumnsName(columnType), schemaBundle.branchId, schemaBundle.schemaId);
                    }),
                  ref: match$2.handleSheetHeaderRef
                }), React.createElement("div", {
                  className: sheet(workspace.isSandbox)
                }, React.createElement(ReactVirtualizedAutoSizer, {
                      children: (function (size) {
                          return React.createElement(ReactWindow.VariableSizeList, {
                                      estimatedItemSize: 50,
                                      height: size.height,
                                      innerElementType: SheetContainer.make,
                                      itemCount: hasEmptyState ? itemData.length : items.length,
                                      itemData: itemData,
                                      itemKey: (function (index) {
                                          return index.toString();
                                        }),
                                      itemSize: (function (param) {
                                          return 50;
                                        }),
                                      outerRef: handleOuterRef,
                                      overscanCount: 30,
                                      ref: handleReactWindowRef,
                                      width: size.width,
                                      children: PropertiesSheetRow.make
                                    });
                        })
                    })));
}

var make = PropertyGroupsSheet;

export {
  Style ,
  make ,
  
}
/* headerStyles Not a pure module */
