// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Select from "../Select.mjs";
import * as Styles from "../styles.mjs";
import * as TextInput from "../TextInput.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconQuestionMark from "../IconQuestionMark.mjs";

var labelBox = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: /* [] */0
    });

var labelDocsLink = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.marginLeft(Css.px(4)),
        tl: /* [] */0
      }
    });

var label = Curry._1(Css.merge, {
      hd: $$Text.sizeStyles("Small"),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.fontWeight(Styles.FontWeight.semi),
              tl: {
                hd: Css.color(Styles.Color.light11),
                tl: {
                  hd: Css.marginLeft(Css.px(8)),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var Style = {
  labelBox: labelBox,
  labelDocsLink: labelDocsLink,
  label: label
};

function IntegrationInput$IntegrationInputContainer(Props) {
  var id = Props.id;
  var docsUrl = Props.docsUrl;
  var label$1 = Props.label;
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: labelBox
                }, React.createElement("label", {
                      className: label,
                      htmlFor: id
                    }, label$1), docsUrl !== undefined ? React.createElement("a", {
                        className: labelDocsLink,
                        href: docsUrl,
                        rel: "noopener",
                        target: "_blank"
                      }, React.createElement(IconQuestionMark.make, {
                            color: Styles.Color.light10
                          })) : null), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(6)),
                        tl: /* [] */0
                      })
                }), children);
}

var IntegrationInputContainer = {
  Style: Style,
  make: IntegrationInput$IntegrationInputContainer
};

function IntegrationInput$IntegrationSelectInput(Props) {
  var id = Props.id;
  var value = Props.value;
  var docsUrl = Props.docsUrl;
  var onSelect = Props.onSelect;
  var options = Props.options;
  var children = Props.children;
  var tmp = {
    id: id,
    label: children,
    children: React.createElement(Select.make, {
          onSelect: onSelect,
          options: options,
          value: value
        })
  };
  if (docsUrl !== undefined) {
    tmp.docsUrl = Caml_option.valFromOption(docsUrl);
  }
  return React.createElement(IntegrationInput$IntegrationInputContainer, tmp);
}

var IntegrationSelectInput = {
  make: IntegrationInput$IntegrationSelectInput
};

function IntegrationInput$IntegrationTextInput(Props) {
  var id = Props.id;
  var value = Props.value;
  var docsUrl = Props.docsUrl;
  var onFlush = Props.onFlush;
  var placeholder = Props.placeholder;
  var onChangeCompleted = Props.onChangeCompleted;
  var children = Props.children;
  var match = Hooks.useOpsWithState(value, onFlush, onChangeCompleted);
  var tmp = {
    id: id,
    onBlur: match[2],
    onChange: match[1],
    onFocus: match[3],
    stretch: true,
    value: match[0]
  };
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  var tmp$1 = {
    id: id,
    label: children,
    children: React.createElement(TextInput.make, tmp)
  };
  if (docsUrl !== undefined) {
    tmp$1.docsUrl = Caml_option.valFromOption(docsUrl);
  }
  return React.createElement(IntegrationInput$IntegrationInputContainer, tmp$1);
}

var IntegrationTextInput = {
  make: IntegrationInput$IntegrationTextInput
};

var label$1 = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(8)),
      tl: {
        hd: Css.display("flex"),
        tl: /* [] */0
      }
    });

var labelDocsLink$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.marginLeft(Css.px(4)),
        tl: /* [] */0
      }
    });

var box = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.marginTop(Css.px(6)),
          tl: {
            hd: Css.paddingTop(Css.px(6)),
            tl: {
              hd: Css.paddingRight(Css.px(6)),
              tl: {
                hd: Css.paddingBottom(Css.px(6)),
                tl: {
                  hd: Css.paddingLeft(Css.px(10)),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                      tl: {
                        hd: Css.borderRadius(Css.px(6)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.light02),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var empty = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: {
          hd: Css.fontWeight("medium"),
          tl: {
            hd: Css.fontSize(Css.px(11)),
            tl: {
              hd: Css.color(Styles.Color.light10),
              tl: /* [] */0
            }
          }
        }
      }
    });

var secret = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: {
          hd: Css.fontWeight("medium"),
          tl: {
            hd: Css.fontSize(Css.px(11)),
            tl: {
              hd: Css.color(Styles.Color.light11),
              tl: /* [] */0
            }
          }
        }
      }
    });

var secretLabel = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: {
          hd: Css.fontWeight("medium"),
          tl: {
            hd: Css.fontSize(Css.px(11)),
            tl: {
              hd: Css.color(Styles.Color.light11),
              tl: {
                hd: Css.textTransform("uppercase"),
                tl: {
                  hd: Css.after({
                        hd: Css.display("block"),
                        tl: {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.right(Css.pct(100.0)),
                            tl: {
                              hd: Css.top(Css.px(0)),
                              tl: {
                                hd: Css.height(Css.pct(100.0)),
                                tl: {
                                  hd: Css.width(Css.px(40)),
                                  tl: {
                                    hd: Css.background({
                                          NAME: "linearGradient",
                                          VAL: [
                                            {
                                              NAME: "deg",
                                              VAL: 90.0
                                            },
                                            [
                                              [
                                                Css.pct(0.0),
                                                Styles.Color.setAlpha(Styles.Color.light02, 0.0)
                                              ],
                                              [
                                                Css.pct(100.0),
                                                Styles.Color.light02
                                              ]
                                            ]
                                          ]
                                        }),
                                    tl: {
                                      hd: Css.unsafe("content", "' '"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var Style$1 = {
  label: label$1,
  labelDocsLink: labelDocsLink$1,
  box: box,
  empty: empty,
  secret: secret,
  secretLabel: secretLabel
};

function IntegrationInput$SecretInput(Props) {
  var label$2 = Props.label;
  var docsUrl = Props.docsUrl;
  var hasValue = Props.hasValue;
  var disabledOpt = Props.disabled;
  var onClick = Props.onClick;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: label$1
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: label$2
                    }), docsUrl !== undefined ? React.createElement("a", {
                        className: labelDocsLink$1,
                        href: docsUrl,
                        rel: "noopener",
                        target: "_blank"
                      }, React.createElement(IconQuestionMark.make, {
                            color: Styles.Color.light10
                          })) : null), React.createElement("div", {
                  className: box
                }, hasValue ? React.createElement("div", {
                        className: secret
                      }, "•••••••••", React.createElement("span", {
                            className: secretLabel
                          }, "Encrypted")) : React.createElement("div", {
                        className: empty
                      }, "Not set"), React.createElement(Button.make, {
                      disabled: disabled,
                      label: hasValue ? "Update Secret" : "Add Secret",
                      onClick: onClick,
                      style: "outline"
                    })));
}

var SecretInput = {
  Style: Style$1,
  make: IntegrationInput$SecretInput
};

export {
  IntegrationInputContainer ,
  IntegrationSelectInput ,
  IntegrationTextInput ,
  SecretInput ,
  
}
/* labelBox Not a pure module */
