// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ModelUtils_mapped from "../../../app/src/ModelUtils_mapped.mjs";
import * as TrackingPlanMappedModel from "../TrackingPlanMappedModel.mjs";
import * as PropertyCodegenNameUseCase from "../../../codegen/src/PropertyCodegenNameUseCase.mjs";
import * as GetEventIdsSendingPropertyForEventSpecificValuesUseCase from "./GetEventIdsSendingPropertyForEventSpecificValuesUseCase.mjs";

var ComparableSet = Belt_Id.MakeComparable({
      cmp: Belt_SetString.cmp
    });

function empty(param) {
  return Belt_Map.make(ComparableSet);
}

var get = Belt_Map.get;

var getWithDefault = Belt_Map.getWithDefault;

function set(t, allowedValues, eventIds, index) {
  return Belt_Map.set(t, allowedValues, [
              eventIds,
              index
            ]);
}

var size = Belt_Map.size;

var toArray = Belt_Map.toArray;

var AllowedValuesToEventIdsWithIndex = {
  empty: empty,
  get: get,
  getWithDefault: getWithDefault,
  set: set,
  size: size,
  toArray: toArray
};

function allowedValuesDataStructureName(property, index) {
  var propertyName = PropertyCodegenNameUseCase.get(property);
  if (index === 0) {
    return propertyName;
  } else {
    return propertyName + " " + String(index);
  }
}

function buildAllowedValuesSetsPerEvent(property, modelEvents, eventIdsSendingPropertyWithSystemAndNested, sourceIds) {
  var eventIdsEmptyMap = Belt_MapString.fromArray(Belt_Array.mapU(eventIdsSendingPropertyWithSystemAndNested, (function (eventId) {
              return [
                      eventId,
                      undefined
                    ];
            })));
  var definitelyPerEventPropertyAllowedValues = property.eventSpecificAllowedPropertyValues;
  return Belt_Array.reduceU(definitelyPerEventPropertyAllowedValues, eventIdsEmptyMap, (function (eventIdsToValueSet, param) {
                var disallowedConfig = param[1];
                var literal = param[0];
                var isLiteralAllowedOnEventId = function (eventId) {
                  var match = Belt_MapString.get(disallowedConfig, eventId);
                  if (match === undefined) {
                    return true;
                  }
                  if (!match) {
                    return false;
                  }
                  var disallowedSourcesSet = match._0;
                  var isPropertyEventIndependent = property.sendAs === /* SystemProperty */0;
                  var includedSourcesSet = Belt_SetString.fromArray(Belt_List.toArray(Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Events.get, modelEvents, eventId), /* [] */0, (function ($$event) {
                                  return Belt_List.map($$event.includeSources, (function (source) {
                                                return source.id;
                                              }));
                                }))));
                  return Belt_Array.someU(sourceIds, (function (sourceId) {
                                var isSourceAllowed = !Belt_SetString.has(disallowedSourcesSet, sourceId);
                                var isSourceIncludedInEvent = Belt_SetString.has(includedSourcesSet, sourceId);
                                if (isSourceAllowed) {
                                  if (isPropertyEventIndependent) {
                                    return true;
                                  } else {
                                    return isSourceIncludedInEvent;
                                  }
                                } else {
                                  return false;
                                }
                              }));
                };
                return Belt_Array.reduceU(eventIdsSendingPropertyWithSystemAndNested, eventIdsToValueSet, (function (eventIdsToValueSet, eventId) {
                              if (!isLiteralAllowedOnEventId(eventId)) {
                                return eventIdsToValueSet;
                              }
                              var enumBefore = Belt_MapString.getWithDefault(eventIdsToValueSet, eventId, undefined);
                              var variant = literal.NAME;
                              var newAllowedValue = variant === "BooleanLit" ? Pervasives.string_of_bool(literal.VAL) : (
                                  variant === "StringLit" ? literal.VAL : (
                                      variant === "FloatLit" ? Pervasives.string_of_float(literal.VAL) : String(literal.VAL)
                                    )
                                );
                              var enumAfter = Belt_SetString.add(enumBefore, newAllowedValue);
                              return Belt_MapString.set(eventIdsToValueSet, eventId, enumAfter);
                            }));
              }));
}

function buildUniqueValueSetsWithSendingEventIdsAndIndex(eventIdsToAllowedValues) {
  return Belt_MapString.reduceU(eventIdsToAllowedValues, Belt_Map.make(ComparableSet), (function (allowedValuesToEventsAndIndex, eventId, allowedValues) {
                var nextIndex = Belt_Map.size(allowedValuesToEventsAndIndex);
                var match = Belt_Map.getWithDefault(allowedValuesToEventsAndIndex, allowedValues, [
                      undefined,
                      nextIndex
                    ]);
                var eventIdsAfter = Belt_SetString.add(match[0], eventId);
                return set(allowedValuesToEventsAndIndex, allowedValues, eventIdsAfter, match[1]);
              }));
}

function buildEventIdsToAllowedValuesStructureIndex(eventIdsToAllowedValues, allowedValuesToEventsAndIndex, eventIdsSendingPropertyWithSystemAndNested) {
  return Belt_Array.reduceU(eventIdsSendingPropertyWithSystemAndNested, undefined, (function (eventIdsToAllowedValuesStructureIndex, eventId) {
                var allowedValuesForGivenEvent = Belt_MapString.getWithDefault(eventIdsToAllowedValues, eventId, undefined);
                var match = Belt_Map.get(allowedValuesToEventsAndIndex, allowedValuesForGivenEvent);
                var index = match !== undefined ? match[1] : Pervasives.failwith("This should not happen");
                return Belt_MapString.set(eventIdsToAllowedValuesStructureIndex, eventId, index);
              }));
}

function compute(model, modelPropertiesArray, sourceIds, forSingleEventIdOpt, property) {
  var forSingleEventId = forSingleEventIdOpt !== undefined ? Caml_option.valFromOption(forSingleEventIdOpt) : undefined;
  var propertyValuesAllEventsKey = GetEventIdsSendingPropertyForEventSpecificValuesUseCase.get(modelPropertiesArray, property, (function (propertyId) {
          var $$event = Belt_Option.flatMapU(forSingleEventId, (function (eventId) {
                  return Curry._2(TrackingPlanMappedModel.Events.get, model.events, eventId);
                }));
          if ($$event !== undefined) {
            if (ModelUtils_mapped.doesEventSendPropertyFromSource($$event, model.propertyBundles, Caml_option.some(Belt_SetString.fromArray(sourceIds)), propertyId)) {
              return [$$event.id];
            } else {
              return [];
            }
          } else {
            return Curry._1(TrackingPlanMappedModel.Events.keys, ModelUtils_mapped.eventsSendingPropertyFromSources(forSingleEventId !== undefined ? Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Events.get, model.events, forSingleEventId), TrackingPlanMappedModel.Events.empty, (function ($$event) {
                                    return Curry._1(TrackingPlanMappedModel.Events.fromArray, [$$event]);
                                  })) : model.events, model.propertyBundles, sourceIds, propertyId));
          }
        }));
  var eventIdsSendingPropertyWithSystemAndNested;
  switch (propertyValuesAllEventsKey.TAG | 0) {
    case /* SystemProperty */0 :
        eventIdsSendingPropertyWithSystemAndNested = [propertyValuesAllEventsKey._0];
        break;
    case /* SystemAndNotSystemProperty */1 :
    case /* NotSystemProperty */2 :
        eventIdsSendingPropertyWithSystemAndNested = propertyValuesAllEventsKey._0;
        break;
    
  }
  var eventIdsToAllowedValues = buildAllowedValuesSetsPerEvent(property, model.events, eventIdsSendingPropertyWithSystemAndNested, sourceIds);
  var allowedValuesToEventsAndIndex = buildUniqueValueSetsWithSendingEventIdsAndIndex(eventIdsToAllowedValues);
  var eventIdsToAllowedValuesStructureIndex = buildEventIdsToAllowedValuesStructureIndex(eventIdsToAllowedValues, allowedValuesToEventsAndIndex, eventIdsSendingPropertyWithSystemAndNested);
  return {
          allowedValuesWithNames: Belt_Array.mapU(Belt_Map.toArray(allowedValuesToEventsAndIndex), (function (param) {
                  return [
                          Belt_SetString.toArray(param[0]),
                          allowedValuesDataStructureName(property, param[1][1])
                        ];
                })),
          eventIdToAllowedValuesDataStructureName: Belt_MapString.mapU(eventIdsToAllowedValuesStructureIndex, (function (index) {
                  return allowedValuesDataStructureName(property, index);
                })),
          eventIdsToAllowedValues: eventIdsToAllowedValues
        };
}

export {
  ComparableSet ,
  AllowedValuesToEventIdsWithIndex ,
  compute ,
  
}
/* ComparableSet Not a pure module */
