// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as Markdown from "../Markdown.mjs";
import * as Tooltips from "../markdown/tooltips/tooltips.mjs";
import * as ModelStore from "../ModelStore.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as NamedBranch from "../NamedBranch.mjs";
import * as IconCategory from "../IconCategory.mjs";
import * as CategoryInput from "../CategoryInput.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as ModelUtils_mapped from "../ModelUtils_mapped.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";

function EventDetailsCategories(Props) {
  var $$event = Props.event;
  var fromItem = Props.fromItem;
  var viewerCanEdit = Props.viewerCanEdit;
  var categories = ModelStore.Mapped.useCategories(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("flexStart"),
                      tl: {
                        hd: Css.padding4(Css.px(5), Css.px(30), Css.px(0), Css.px(30)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(5)),
                        tl: {
                          hd: Css.marginRight(Css.px(5)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(IconCategory.make, {})), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.color(Styles.Color.light12),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.padding4(Css.px(4), Css.px(0), Css.px(8), Css.px(10)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(-2)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, "Categories", React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Categories",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.categories
                                    })
                              })
                        })), React.createElement(CategoryInput.make, {
                      categories: Curry._1(TrackingPlanMappedModel.Categories.toList, categories),
                      currentCategories: ModelUtils_mapped.getGoalsWithEvent($$event.id, categories),
                      viewerCanEdit: viewerCanEdit,
                      onRemove: (function (category) {
                          return Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                          return AnalyticsRe.categoryUpdated(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, category.name, category.id, "EventRemoved", "N/A", undefined, $$event.name, $$event.id, NamedBranch.getId(branch), schemaBundle.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "RemoveEventFromGoal",
                                            VAL: [
                                              category.id,
                                              $$event.id
                                            ]
                                          },
                                          {
                                            eventId: $$event.id,
                                            eventQuery: $$event.id,
                                            goalId: category.id,
                                            goalQuery: category.id
                                          }
                                        ]]
                                    ]);
                        }),
                      onAdd: (function (id, name) {
                          return Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                          return AnalyticsRe.categoryUpdated(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, name, id, "EventAdded", "N/A", undefined, $$event.name, $$event.id, NamedBranch.getId(branch), schemaBundle.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "AddEventToGoal",
                                            VAL: [
                                              id,
                                              $$event.id
                                            ]
                                          },
                                          {
                                            eventId: $$event.id,
                                            eventQuery: $$event.id,
                                            goalId: id,
                                            goalQuery: id
                                          }
                                        ]]
                                    ]);
                        })
                    })));
}

var make = EventDetailsCategories;

export {
  make ,
  
}
/* Css Not a pure module */
