// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as TextButton from "./TextButton.mjs";
import * as PlanLightning from "./PlanLightning.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as IconArrowDownEast from "./IconArrowDownEast.mjs";
import * as PortalMenuAnimator from "./PortalMenuAnimator.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.minWidth(Css.px(300)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.borderRadius(Styles.Border.radius),
              tl: {
                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                              NAME: "num",
                              VAL: 0.10
                            }))),
                tl: {
                  hd: Css.paddingBottom(Css.px(16)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingTop(Css.px(16)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: {
                hd: Css.paddingRight(Css.px(20)),
                tl: {
                  hd: Css.paddingBottom(Css.px(13)),
                  tl: {
                    hd: Css.marginBottom(Css.px(12)),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var rowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginTop(Css.px(4)),
            tl: {
              hd: Css.marginBottom(Css.px(4)),
              tl: {
                hd: Css.paddingLeft(Css.px(20)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var footerStyles = Curry._1(Css.style, {
      hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
      tl: {
        hd: Css.marginTop(Css.px(12)),
        tl: {
          hd: Css.paddingLeft(Css.px(20)),
          tl: {
            hd: Css.paddingRight(Css.px(20)),
            tl: {
              hd: Css.paddingTop(Css.px(12)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function SourceRowEditDestinationsPopup(Props) {
  var availability = Props.availability;
  var destinations = Props.destinations;
  var globalSend = Props.globalSend;
  var includedDestinations = Props.includedDestinations;
  var onClose = Props.onClose;
  var onEnableAll = Props.onEnableAll;
  var onToggle = Props.onToggle;
  var onToggleSegmentIntegration = Props.onToggleSegmentIntegration;
  var segmentDestinationOptions = Props.segmentDestinationOptions;
  var unSelectedDestinations = Belt_List.keepU(destinations, (function (destination) {
          return !Belt_List.someU(includedDestinations, (function (destinationId) {
                        return destinationId === destination.id;
                      }));
        }));
  return React.createElement(PortalMenuAnimator.make, {
              key_: "source-row-edit-destinations-popup",
              className: rootStyles,
              children: React.createElement("section", {
                    onKeyDown: (function ($$event) {
                        if ($$event.key === "Escape") {
                          return Curry._1(onClose, undefined);
                        }
                        
                      })
                  }, React.createElement("header", {
                        className: headerStyles
                      }, React.createElement($$Text.make, {
                            element: "H1",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light12,
                            children: "Edit Destinations"
                          }), availability === "Available" ? null : React.createElement("span", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("inlineFlex"),
                                    tl: {
                                      hd: Css_Legacy_Core.SVG.fill("transparent"),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light12),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(4)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement(PlanLightning.make, {
                                  tooltipText: availability === "Unavailable" ? "Upgrade Plan to unlock" : "Available during trial",
                                  tooltipDirection: "right"
                                })), availability === "Unavailable" && unSelectedDestinations !== /* [] */0 ? React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.flexGrow(1.0),
                                    tl: {
                                      hd: Css.textAlign("right"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement(TextButton.make, {
                                  onClick: (function (param) {
                                      return Curry._1(onEnableAll, unSelectedDestinations);
                                    }),
                                  style: "Blue",
                                  size: "Small",
                                  children: "Enable all"
                                })) : null), Belt_List.toArray(Belt_List.map(destinations, (function (destination) {
                              var isSelected = Belt_List.some(includedDestinations, (function (destinationId) {
                                      return destinationId === destination.id;
                                    }));
                              var match = destination.type_;
                              var match$1 = Belt_List.length(segmentDestinationOptions) > 0 && isSelected;
                              return React.createElement(React.Fragment, {
                                          children: null,
                                          key: destination.id
                                        }, React.createElement("div", {
                                              className: rowStyles
                                            }, React.createElement(Switch.make, {
                                                  value: isSelected,
                                                  onChange: (function (isSelected) {
                                                      if (availability === "AvailableDuringTrial" || availability === "Available") {
                                                        return Curry._2(onToggle, destination, isSelected);
                                                      } else {
                                                        return Curry._1(globalSend, {
                                                                    TAG: /* OpenModal */4,
                                                                    _0: {
                                                                      NAME: "BillingPrompt",
                                                                      VAL: "ConfigureEventsPerPlatform"
                                                                    }
                                                                  });
                                                      }
                                                    }),
                                                  faded: availability === "Unavailable",
                                                  size: "Small"
                                                }), React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.width(Css.px(12)),
                                                        tl: /* [] */0
                                                      })
                                                }), React.createElement($$Text.make, {
                                                  size: "Medium",
                                                  weight: "Semi",
                                                  children: ModelUtils.getDestinationName(destination)
                                                })), match !== undefined && match === "Segment" && match$1 ? Belt_List.toArray(Belt_List.map(segmentDestinationOptions, (function (param) {
                                                      var integration = param[0];
                                                      return React.createElement("div", {
                                                                  key: integration,
                                                                  className: rowStyles
                                                                }, React.createElement("div", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.width(Css.px(20)),
                                                                            tl: /* [] */0
                                                                          })
                                                                    }), React.createElement(IconArrowDownEast.make, {
                                                                      size: 12
                                                                    }), React.createElement("div", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.width(Css.px(10)),
                                                                            tl: /* [] */0
                                                                          })
                                                                    }), React.createElement(Switch.make, {
                                                                      value: param[1],
                                                                      onChange: (function (isSelected) {
                                                                          if (availability === "AvailableDuringTrial" || availability === "Available") {
                                                                            return Curry._3(onToggleSegmentIntegration, destination, integration, isSelected);
                                                                          } else {
                                                                            return Curry._1(globalSend, {
                                                                                        TAG: /* OpenModal */4,
                                                                                        _0: {
                                                                                          NAME: "BillingPrompt",
                                                                                          VAL: "ConfigureEventsPerPlatform"
                                                                                        }
                                                                                      });
                                                                          }
                                                                        }),
                                                                      faded: !isSelected || availability === "Unavailable",
                                                                      size: "Small",
                                                                      disabled: !isSelected
                                                                    }), React.createElement("div", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.width(Css.px(10)),
                                                                            tl: /* [] */0
                                                                          })
                                                                    }), React.createElement($$Text.make, {
                                                                      size: "Small",
                                                                      weight: "Semi",
                                                                      color: Styles.Color.light10,
                                                                      children: integration
                                                                    }));
                                                    }))) : null);
                            }))), availability === "Available" ? null : React.createElement("footer", {
                          className: footerStyles
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light10,
                              children: null
                            }, availability === "Unavailable" ? "Upgrade plan to make changes. " : "Upgrade plan to keep destinations editable. ", React.createElement(TextButton.make, {
                                  onClick: (function (param) {
                                      return Curry._1(globalSend, {
                                                  TAG: /* OpenModal */4,
                                                  _0: {
                                                    NAME: "BillingPrompt",
                                                    VAL: "ConfigureEventsPerPlatform"
                                                  }
                                                });
                                    }),
                                  style: "Blue",
                                  children: "Learn more"
                                }))))
            });
}

var make = SourceRowEditDestinationsPopup;

export {
  rootStyles ,
  headerStyles ,
  rowStyles ,
  footerStyles ,
  make ,
  
}
/* rootStyles Not a pure module */
