// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(t) {
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "ok",
                      t.ok
                    ],
                    tl: /* [] */0
                  },
                  Belt_Option.mapWithDefault(t.schemaId, /* [] */0, (function (id) {
                          return {
                                  hd: [
                                    "schemaId",
                                    id
                                  ],
                                  tl: /* [] */0
                                };
                        }))
                ]));
}

function decode(json) {
  return {
          ok: Json_decode.field("ok", Json_decode.bool, json),
          schemaId: Json_decode.optional((function (param) {
                  return Json_decode.field("schemaId", Json_decode.string, param);
                }), json)
        };
}

var $$Response = {
  encode: encode,
  decode: decode
};

export {
  $$Response ,
  
}
/* No side effect */
