// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as DateFns from "../DateFns.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Markdown from "../Markdown.mjs";
import * as Tooltips from "../markdown/tooltips/tooltips.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as HoverTitle from "./HoverTitle.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DiffComments from "../DiffComments.mjs";
import * as SnippetUtils from "../detailviews/SnippetUtils.mjs";
import * as Core from "@mantine/core";
import * as SimpleTooltip from "../SimpleTooltip.mjs";
import * as VariantEventIdUseCase from "../../../model/src/variants/variantEventIdUseCase.mjs";
import * as BranchImplementationUtils from "./BranchImplementationUtils.mjs";
import * as EventImplementationSnippet from "../detailviews/EventImplementationSnippet.mjs";

function getEventModificationStatus(fromModelWithFlattenedEventVariants, $$event, sourceId) {
  return Belt_Option.mapWithDefault(ModelUtils.getEventById($$event.id, fromModelWithFlattenedEventVariants), /* New */0, (function ($$event) {
                if (Belt_List.someU($$event.includeSources, (function (param) {
                          return param.id === sourceId;
                        }))) {
                  return /* Modified */2;
                } else {
                  return /* AddedToSource */1;
                }
              }));
}

var container = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
        tl: {
          hd: Css.borderRadius(Css.px(5)),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.width(Css.pct(100.0)),
              tl: {
                hd: Css.unsafe("height", "fit-content"),
                tl: {
                  hd: Css.maxWidth(Css.px(934)),
                  tl: {
                    hd: Css.minWidth(Css.px(480)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.gap(Css.px(8)),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

function modificationStatus(modificationStatus$1) {
  return Curry._1(Css.style, {
              hd: Css.borderRadius(Css.px(10)),
              tl: {
                hd: Css.padding2(Css.px(2), Css.px(8)),
                tl: {
                  hd: Css.backgroundColor(modificationStatus$1 >= 2 ? Styles.Color.lightPurple : Styles.Color.lightGreen),
                  tl: {
                    hd: Css.color(modificationStatus$1 >= 2 ? Styles.Color.primaryPurple : Styles.Color.darkGreen),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function status(status$1) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.gap(Css.px(4)),
                  tl: {
                    hd: Css.padding2(Css.px(1), Css.px(5)),
                    tl: {
                      hd: Css.borderRadius(Css.px(10)),
                      tl: {
                        hd: Css.whiteSpace("nowrap"),
                        tl: {
                          hd: Css.color(typeof status$1 === "number" ? Styles.Color.light09 : Styles.Color.green),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", typeof status$1 === "number" ? Styles.Color.light07 : Styles.Color.paleGreen),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function statusBall(status) {
  return Curry._1(Css.style, {
              hd: Css.minWidth(Css.px(8)),
              tl: {
                hd: Css.minHeight(Css.px(8)),
                tl: {
                  hd: Css.width(Css.px(8)),
                  tl: {
                    hd: Css.height(Css.px(8)),
                    tl: {
                      hd: Css.borderRadius(Css.px(4)),
                      tl: {
                        hd: Css.backgroundColor(typeof status === "number" ? Styles.Color.light07 : Styles.Color.paleGreen),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var Style = {
  container: container,
  header: header,
  modificationStatus: modificationStatus,
  status: status,
  statusBall: statusBall
};

function ChangedEvent(Props) {
  var branchStatus = Props.branchStatus;
  var enrichedFromModel = Props.enrichedFromModel;
  var enrichedToModel = Props.enrichedToModel;
  var diffViewType = Props.diffViewType;
  var $$event = Props.event;
  var fromModel = Props.fromModel;
  var fromModelWithFlattenedEventVariants = Props.fromModelWithFlattenedEventVariants;
  var goToCommentId = Props.goToCommentId;
  var sourceId = Props.sourceId;
  var status$1 = Props.status;
  var sourceUpdatesLoadingState = Props.sourceUpdatesLoadingState;
  var toModel = Props.toModel;
  var containerRef = React.useRef(null);
  SnippetUtils.useScrollIntoView($$event.id, "BranchImplementation", 0, containerRef);
  var match = React.useState(function () {
        return false;
      });
  var setTimestampVisible = match[1];
  var modificationStatus$1 = getEventModificationStatus(fromModelWithFlattenedEventVariants, $$event, sourceId);
  var isVariant = VariantEventIdUseCase.isVariantEventId($$event.id);
  var tmp;
  if (isVariant) {
    var tmp$1;
    switch (modificationStatus$1) {
      case /* New */0 :
          tmp$1 = "New";
          break;
      case /* AddedToSource */1 :
          tmp$1 = "Added";
          break;
      case /* Modified */2 :
          tmp$1 = "Updated";
          break;
      
    }
    tmp = React.createElement(SimpleTooltip.WithComponent.make, {
          arrowSize: 8,
          arrowOffset: 12,
          maxWidth: 300,
          offset: 4,
          position: "bottom-start",
          tooltip: (function (param) {
              return React.createElement($$Text.make, {
                          size: "Small",
                          children: React.createElement(Markdown.make, {
                                source: Tooltips.codeChangesEventVariant
                              })
                        });
            }),
          children: React.createElement($$Text.make, {
                size: "Small",
                weight: "Semi",
                children: tmp$1 + " Variant"
              })
        });
  } else {
    var tmp$2;
    switch (modificationStatus$1) {
      case /* New */0 :
          tmp$2 = "New";
          break;
      case /* AddedToSource */1 :
          tmp$2 = "Added";
          break;
      case /* Modified */2 :
          tmp$2 = "Updated";
          break;
      
    }
    tmp = React.createElement($$Text.make, {
          size: "Small",
          weight: "Semi",
          children: tmp$2
        });
  }
  var tmp$3;
  if (BranchImplementationUtils.hasCodegenForSource($$event, sourceId)) {
    if (sourceUpdatesLoadingState === "loading") {
      tmp$3 = React.createElement("span", {
            className: status(status$1)
          }, React.createElement("span", {
                className: statusBall(status$1)
              }), React.createElement($$Text.make, {
                size: "Small",
                children: "Loading..."
              }));
    } else if (typeof status$1 === "number") {
      if (status$1 !== 1) {
        var tmp$4;
        if (typeof status$1 === "number") {
          switch (status$1) {
            case /* NotSeen */0 :
                tmp$4 = "Never seen";
                break;
            case /* NotImplementedWithAvo */1 :
                tmp$4 = "";
                break;
            case /* Unknown */2 :
                tmp$4 = "Not supported";
                break;
            
          }
        } else {
          tmp$4 = "";
        }
        tmp$3 = React.createElement("span", {
              className: status(status$1)
            }, React.createElement("span", {
                  className: statusBall(status$1)
                }), React.createElement($$Text.make, {
                  size: "Small",
                  children: tmp$4
                }));
      } else {
        tmp$3 = null;
      }
    } else {
      var since = DateFns.formatDistanceToNow(false, false, status$1._0);
      tmp$3 = React.createElement(Mantine.Popover.make, {
            opened: match[0],
            position: "top",
            children: null
          }, React.createElement(Core.Popover.Target, {
                children: React.createElement("span", {
                      className: status(status$1),
                      onMouseEnter: (function (param) {
                          return Curry._1(setTimestampVisible, (function (param) {
                                        return true;
                                      }));
                        }),
                      onMouseLeave: (function (param) {
                          return Curry._1(setTimestampVisible, (function (param) {
                                        return false;
                                      }));
                        })
                    }, React.createElement("span", {
                          className: statusBall(status$1)
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          children: "Implemented"
                        }))
              }), React.createElement(Mantine.Popover.Dropdown.make, {
                children: React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.width("auto"),
                            tl: {
                              hd: Css.whiteSpace("nowrap"),
                              tl: {
                                hd: Css.padding2(Css.px(3), Css.px(6)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.light11),
                                  tl: {
                                    hd: Css.color(Styles.Color.white),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.small),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.bold),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, "Seen in development " + since + " ago")
              }));
    }
  } else {
    tmp$3 = null;
  }
  var tmp$5;
  switch (modificationStatus$1) {
    case /* New */0 :
        tmp$5 = "new";
        break;
    case /* AddedToSource */1 :
        tmp$5 = "added";
        break;
    case /* Modified */2 :
        tmp$5 = "updated";
        break;
    
  }
  var tmp$6 = {
    branchStatus: branchStatus,
    eventDiffState: tmp$5,
    enrichedToModel: enrichedToModel,
    event: $$event,
    fromModel: fromModel,
    toModel: toModel,
    pageName: "BranchImplementation",
    viewType: /* SingleSource */{
      _0: sourceId
    },
    diffViewType: diffViewType,
    key: sourceId
  };
  if (enrichedFromModel !== undefined) {
    tmp$6.enrichedFromModel = Caml_option.valFromOption(enrichedFromModel);
  }
  var tmp$7;
  if (isVariant) {
    tmp$7 = null;
  } else {
    var tmp$8 = {
      branchId: Router.Schema.getBranchId(undefined),
      threadId: $$event.id,
      itemType: "Event",
      itemName: $$event.name,
      stickyTop: BranchImplementationUtils.codeChangesHeaderHeight + 4 | 0,
      events: toModel.events
    };
    if (goToCommentId !== undefined) {
      tmp$8.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp$7 = React.createElement(DiffComments.make, tmp$8);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: /* [] */0
                  })
            }, React.createElement("section", {
                  ref: containerRef,
                  className: container
                }, React.createElement("header", {
                      className: header
                    }, React.createElement("span", {
                          className: modificationStatus(modificationStatus$1)
                        }, tmp), React.createElement(HoverTitle.make, {
                          label: Belt_Option.getWithDefault($$event.uniqueName, $$event.name)
                        }), React.createElement("span", undefined, tmp$3), React.createElement(Spacer.make, {
                          width: 0,
                          grow: 1.0
                        }), React.createElement(Button.make, {
                          icon: "sidebar",
                          label: "View",
                          onClick: (function (param) {
                              return Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "event",
                                          VAL: [
                                            $$event.id,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        });
                            }),
                          style: "outline"
                        })), $$event.description.trim().length > 0 ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light09,
                            children: $$event.description
                          })) : null, React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement(EventImplementationSnippet.make, tmp$6)), tmp$7);
}

var make = ChangedEvent;

export {
  getEventModificationStatus ,
  Style ,
  make ,
  
}
/* container Not a pure module */
