// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Mantine from "../Mantine.mjs";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as EmptyState from "../EmptyState.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RingProgress from "./RingProgress.mjs";
import * as ChangedEvents from "./ChangedEvents.mjs";
import * as IconCheckmark from "../IconCheckmark.mjs";
import * as BranchImplementationUtils from "./BranchImplementationUtils.mjs";
import * as BranchImplementationHeader from "./BranchImplementationHeader.mjs";
import * as BranchImplementationOverview from "./BranchImplementationOverview.mjs";
import * as CodeChangesAuditAccordionItem from "../CodeChangesAuditAccordionItem.mjs";

function BranchImplementation$Empty(Props) {
  var forSourceOpt = Props.forSource;
  var forSource = forSourceOpt !== undefined ? forSourceOpt : false;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.height(Css.vh(100.0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(EmptyState.Container.make, {
                  artwork: "codegen",
                  children: null
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(340)),
                            tl: /* [] */0
                          })
                    }, React.createElement(Title.make, {
                          children: "There are no changes " + (
                            forSource ? "for this source " : ""
                          ) + "on this branch that require implementation changes",
                          size: "Small"
                        })), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      children: "If you make any changes on this branch that affect how events are implemented, you’ll see them here."
                    })));
}

var pageContainer = Curry._1(Css.style, {
      hd: Css.height(Css.pct(100.0)),
      tl: {
        hd: Css.overflow("auto"),
        tl: /* [] */0
      }
    });

var events = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.paddingLeft(Css.px(20)),
          tl: {
            hd: Css.gap(Css.px(16)),
            tl: {
              hd: Css.paddingBottom(Css.px(32)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var sourceHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gap(Css.px(12)),
            tl: {
              hd: Css.marginLeft(Css.px(20)),
              tl: {
                hd: Css.marginRight(Css.px(BranchImplementationUtils.commentMarginWidth)),
                tl: {
                  hd: Css.padding(Css.px(16)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.maxWidth(Css.px(934)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var loadingContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.marginLeft(Css.px(2)),
            tl: {
              hd: Css.height(Css.px(24)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var ringProgressContainer = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(24)),
      tl: {
        hd: Css.maxHeight(Css.px(24)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

function BranchImplementation(Props) {
  var branchStatus = Props.branchStatus;
  var fromModel = Props.fromModel;
  var fromModelWithFlattenedEventVariants = Props.fromModelWithFlattenedEventVariants;
  var subRoute = Props.subRoute;
  var toModel = Props.toModel;
  var sourceUpdates = Props.sourceUpdates;
  var sourceUpdates$1 = sourceUpdates.sourceUpdates;
  var sourceUpdatesLoadingState = sourceUpdates.loadingState;
  if (!sourceUpdates$1) {
    return React.createElement(BranchImplementation$Empty, {});
  }
  var tmp;
  if (typeof subRoute === "object") {
    var match = subRoute.VAL;
    var sourceId = match[0];
    var match$1 = Belt_Option.mapWithDefault(Belt_List.getBy(sourceUpdates$1, (function (param) {
                return param[0].id === sourceId;
              })), [
          undefined,
          /* [] */0
        ], (function (param) {
            return [
                    param[0],
                    Curry._1(BranchImplementationUtils.EventComparator.sort, param[1])
                  ];
          }));
    var sourceEvents = match$1[1];
    var source = match$1[0];
    var implementedWithCodegen = BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", undefined, sourceId, sourceEvents);
    var seenOnSource = Belt_List.keep(implementedWithCodegen, (function ($$event) {
            if (typeof $$event === "object" && $$event.NAME === "Updated" && typeof $$event.VAL[1] !== "number") {
              return true;
            } else {
              return false;
            }
          }));
    var implementedManually = BranchImplementationUtils.filterEvents("implementedManually", "updated", undefined, sourceId, sourceEvents);
    var deletedEvents = Belt_List.length(BranchImplementationUtils.filterEvents("any", "removed", undefined, sourceId, sourceEvents));
    var hasEventsOnSource = Belt_List.length(sourceEvents) > 0;
    var codegenString = implementedWithCodegen ? (
        implementedWithCodegen.tl ? String(Belt_List.length(seenOnSource)) + " of " + String(Belt_List.length(implementedWithCodegen)) + " events " + (
            Belt_List.length(seenOnSource) === 1 ? "has" : "have"
          ) + " been implemented using Codegen" : String(Belt_List.length(seenOnSource)) + " of 1 event " + (
            Belt_List.length(seenOnSource) === 1 ? "has" : "have"
          ) + " been implemented using Codegen"
      ) : undefined;
    var manualString = implementedWithCodegen !== /* [] */0 || !implementedManually ? undefined : (
        implementedManually.tl ? String(Belt_List.length(implementedManually)) + " events not using Codegen need manual implementation and validation" : "1 event not using Codegen needs manual implementation and validation"
      );
    var deletedString = deletedEvents !== 0 && !(implementedManually !== /* [] */0 || implementedWithCodegen !== /* [] */0) ? (
        deletedEvents !== 1 ? String(deletedEvents) + " events removed" : "1 event removed"
      ) : undefined;
    var manualString$1 = implementedWithCodegen === /* [] */0 || !implementedManually ? undefined : (
        implementedManually.tl ? String(Belt_List.length(implementedManually)) + " events not using Codegen need manual implementation and validation" : "1 event not using Codegen needs manual implementation and validation"
      );
    var deletedString$1 = deletedEvents !== 0 && !(implementedManually === /* [] */0 && implementedWithCodegen === /* [] */0) ? (
        deletedEvents !== 1 ? String(deletedEvents) + " events removed" : "1 event removed"
      ) : undefined;
    var arr = Belt_Array.keepMap([
          manualString$1,
          deletedString$1
        ], (function (s) {
            return s;
          }));
    var tmp$1;
    if (source !== undefined && hasEventsOnSource) {
      var tmp$2 = {
        branchStatus: branchStatus,
        events: sourceEvents,
        fromModel: fromModel,
        fromModelWithFlattenedEventVariants: fromModelWithFlattenedEventVariants,
        source: source,
        sourceUpdatesLoadingState: sourceUpdatesLoadingState,
        toModel: toModel
      };
      var tmp$3 = match[1];
      if (tmp$3 !== undefined) {
        tmp$2.goToCommentId = Caml_option.valFromOption(tmp$3);
      }
      tmp$1 = React.createElement("section", {
            className: events
          }, React.createElement(ChangedEvents.make, tmp$2));
    } else {
      tmp$1 = null;
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement("header", {
              className: sourceHeader
            }, sourceUpdatesLoadingState === "loading" ? React.createElement("div", {
                    className: loadingContainer
                  }, React.createElement(Mantine.Loader.make, {
                        size: 22,
                        color: Styles.Color.darkBlue
                      })) : (
                implementedWithCodegen !== /* [] */0 && Belt_List.length(seenOnSource) === Belt_List.length(implementedWithCodegen) ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.marginLeft(Css.px(6)),
                              tl: {
                                hd: Css.marginRight(Css.px(6)),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement(IconCheckmark.make, {
                            size: 13,
                            color: Styles.Color.green
                          })) : (
                    implementedWithCodegen !== /* [] */0 ? React.createElement("div", {
                            className: ringProgressContainer
                          }, React.createElement(RingProgress.make, {
                                size: 30,
                                thickness: 4,
                                rootColor: Styles.Color.light03,
                                sections: CodeChangesAuditAccordionItem.getImplementationRingProgress(Styles.Color.darkBlue, Belt_List.length(implementedWithCodegen), Belt_List.length(seenOnSource)),
                                tooltip: String(Belt_List.length(seenOnSource)) + " of " + String(Belt_List.length(implementedWithCodegen)) + " event" + (
                                  Belt_List.length(implementedWithCodegen) === 1 ? " has" : "s have"
                                ) + " been implemented using Codegen"
                              })) : React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.marginLeft(Css.px(3)),
                                  tl: {
                                    hd: Css.marginRight(Css.px(3)),
                                    tl: {
                                      hd: Css.height(Css.px(20)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Icon.make, {
                                type_: "code",
                                size: {
                                  NAME: "int",
                                  VAL: 18
                                },
                                color: Styles.Color.light10
                              }))
                  )
              ), React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Medium",
                      children: null
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: Belt_Option.mapWithDefault(source, "", AvoConfig.getSourceName) + " "
                        }), React.createElement($$Text.make, {
                          element: "Span",
                          color: Styles.Color.light10,
                          children: Belt_Array.keepMap([
                                  codegenString,
                                  manualString,
                                  deletedString
                                ], (function (s) {
                                    return s;
                                  })).join(", ")
                        })), arr.length !== 0 ? React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light10,
                        children: arr.join(", ")
                      }) : null)), React.createElement(Spacer.make, {
              height: 24
            }), tmp$1);
  } else {
    tmp = React.createElement(BranchImplementationOverview.make, {
          branchStatus: branchStatus,
          sourceUpdates: sourceUpdates$1,
          sourceUpdatesLoadingState: sourceUpdatesLoadingState
        });
  }
  return React.createElement("main", {
              className: pageContainer,
              id: "branch-implementation-view"
            }, React.createElement(BranchImplementationHeader.make, {
                  sourceUpdates: sourceUpdates$1,
                  sourceUpdatesLoadingState: sourceUpdatesLoadingState,
                  subRoute: subRoute
                }), React.createElement(Spacer.make, {
                  height: BranchImplementationUtils.codeChangesHeaderHeight
                }), tmp);
}

var make = BranchImplementation;

export {
  make ,
  
}
/* pageContainer Not a pure module */
