// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

function getImpactedEventAndVariantIds(changes) {
  return Belt_SetString.fromArray(Belt_Array.concatMany([
                  Belt_MapString.keysToArray(changes.new),
                  Belt_MapString.keysToArray(changes.changed),
                  Belt_MapString.keysToArray(changes.removed)
                ]));
}

function getImpactedEventAndVariantIdsByDomain(impactedItemIds, domains) {
  return Curry._2(Domains.keepMap, domains, (function (domain) {
                var events = Belt_Array.keep(domain.items.events, (function (id) {
                        return Belt_SetString.has(impactedItemIds, id);
                      }));
                var variants = Belt_Array.keep(domain.items.eventVariants, (function (id) {
                        return Belt_SetString.has(impactedItemIds, id);
                      }));
                var items = Belt_Array.concat(events, variants);
                if (items.length !== 0) {
                  return [
                          domain,
                          items
                        ];
                }
                
              }));
}

export {
  getImpactedEventAndVariantIds ,
  getImpactedEventAndVariantIdsByDomain ,
  
}
/* Domains Not a pure module */
