// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function can(viewerCanEditParent, isEditableWhenInVariant, variant) {
  var hasNoVariant = Belt_Option.isNone(variant);
  if (viewerCanEditParent) {
    if (hasNoVariant) {
      return true;
    } else {
      return isEditableWhenInVariant;
    }
  } else {
    return false;
  }
}

export {
  can ,
  
}
/* No side effect */
