// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Spacer from "./Spacer.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Redirect from "./Redirect.mjs";
import * as SsoUtils from "./SsoUtils.mjs";
import * as SsoChecks from "./SsoChecks.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as AuthenticationMethods from "./AuthenticationMethods.mjs";
import * as ContinueWithGoogleButton from "./ContinueWithGoogleButton.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.marginLeft("auto"),
              tl: {
                hd: Css.marginRight("auto"),
                tl: {
                  hd: Css.marginTop(Css.vh(40)),
                  tl: {
                    hd: Css.width(Css.px(350)),
                    tl: {
                      hd: Css.maxWidth(Css.pct(100)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var centerLoadingStyles = Curry._1(Css.style, {
      hd: Css.margin("auto"),
      tl: {
        hd: Css.width(Css.px(22)),
        tl: /* [] */0
      }
    });

var errorMessageStyles = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.padding2(Css.px(8), Css.px(10)),
        tl: {
          hd: Css.margin2(Css.px(24), Css.px(0)),
          tl: {
            hd: Css.lineHeight(Css.pct(120.0)),
            tl: {
              hd: Css.color(Styles.Color.red),
              tl: {
                hd: Css.backgroundColor(Styles.Color.lightRed),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.red),
                    tl: {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function UnauthenticatedSchemaAccessWall(Props) {
  var schemaId = Props.schemaId;
  var forceReloadUser = Props.forceReloadUser;
  var auth = Firebase.getAuth(undefined);
  var match = React.useState(function () {
        return /* InitialLoading */0;
      });
  var setStats = match[1];
  var stats = match[0];
  React.useEffect((function () {
          SsoChecks.getSsoProviderForSchemaFromFirestore(schemaId).then(function (ssoProvider) {
                if (ssoProvider !== undefined) {
                  var provider = Caml_option.valFromOption(ssoProvider);
                  if ($$String.lowercase_ascii(provider.authMethod) === SsoUtils.samlAuthMethodLowercaseString) {
                    return Promise.resolve(Curry._1(setStats, (function (param) {
                                      return {
                                              TAG: /* SamlSso */1,
                                              _0: provider.companyName,
                                              _1: provider.authProviderId,
                                              _2: undefined
                                            };
                                    })));
                  }
                  if ($$String.lowercase_ascii(provider.authMethod) === SsoUtils.googleAuthMethodLowercaseString) {
                    return Promise.resolve(Curry._1(setStats, (function (param) {
                                      return {
                                              TAG: /* GoogleSso */0,
                                              _0: provider.companyName,
                                              _1: undefined
                                            };
                                    })));
                  }
                  
                }
                return Promise.resolve(Curry._1(setStats, (function (param) {
                                  return /* NoSso */1;
                                })));
              });
          
        }), [schemaId]);
  var parseFirebaseAuthSigninError = function (error) {
    var match = error.code;
    switch (match) {
      case "auth/user-disabled" :
          return "This user has been disabled, please contact support ☎️";
      case "auth/user-not-found" :
          return "Hmm, we couldn't find a user with those credentials 🕵️‍. Try another account or sign up.";
      case "auth/wrong-password" :
          return "Whoops, incorrect password. If you forgot your password please contact support ☎️";
      default:
        Sentry.captureException(error, {});
        return "Hmm, this is awkward, something unexpected happened while trying to sign in 😟";
    }
  };
  var tmp;
  if (typeof stats === "number") {
    tmp = stats === /* InitialLoading */0 ? React.createElement("div", {
            className: centerLoadingStyles
          }, React.createElement(LoadingCircle.make, {
                color: Styles.Color.light07
              })) : React.createElement(Redirect.make, {
            path: "/login?redirect=" + Router.buildRedirectPath(undefined)
          });
  } else if (stats.TAG === /* GoogleSso */0) {
    var error = stats._1;
    var companyName = stats._0;
    tmp = React.createElement("div", undefined, React.createElement(Icons.AvoLogoAInRoundedSquare.make, {
              width: 76
            }), React.createElement(Spacer.make, {
              height: 32
            }), React.createElement($$Text.make, {
              size: "Huge",
              weight: "Semi",
              color: Styles.Color.light12,
              children: "Welcome to " + companyName + " on Avo"
            }), React.createElement(Spacer.make, {
              height: 8
            }), React.createElement($$Text.make, {
              size: "Medium",
              color: Styles.Color.light12,
              children: "Login with Google to access the " + companyName + " workspace"
            }), React.createElement(Spacer.make, {
              height: 32
            }), React.createElement(ContinueWithGoogleButton.make, {
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  $$Promise.$$catch(AuthenticationMethods.signInViaGoogle(auth, undefined, forceReloadUser).then(function (param) {
                            return AuthenticationMethods.Utils.redirectToWelcome(undefined);
                          }), (function (error) {
                          return Promise.resolve(Curry._1(setStats, (function (param) {
                                            return {
                                                    TAG: /* GoogleSso */0,
                                                    _0: companyName,
                                                    _1: parseFirebaseAuthSigninError(error)
                                                  };
                                          })));
                        }));
                  
                }),
              backgroundColor: Styles.Color.darkBlue
            }), error !== undefined ? React.createElement("div", {
                className: errorMessageStyles
              }, error) : null);
  } else {
    var error$1 = stats._2;
    var authProviderId = stats._1;
    var companyName$1 = stats._0;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Icons.AvoLogoAInRoundedSquare.make, {
              width: 76
            }), React.createElement(Spacer.make, {
              height: 32
            }), React.createElement($$Text.make, {
              size: "Huge",
              weight: "Semi",
              color: Styles.Color.light12,
              children: "Welcome to " + companyName$1 + " on Avo"
            }), React.createElement(Spacer.make, {
              height: 8
            }), React.createElement($$Text.make, {
              size: "Medium",
              color: Styles.Color.light12,
              children: "Login with SSO to access the " + companyName$1 + " workspace"
            }), React.createElement(Spacer.make, {
              height: 24
            }), React.createElement(Button.make, {
              label: "Continue with SSO",
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  $$Promise.$$catch(AuthenticationMethods.signInViaSaml(auth, authProviderId, forceReloadUser).then(function (param) {
                            return AuthenticationMethods.Utils.redirectToWelcome(undefined);
                          }), (function (error) {
                          return Promise.resolve(Curry._1(setStats, (function (param) {
                                            return {
                                                    TAG: /* SamlSso */1,
                                                    _0: companyName$1,
                                                    _1: authProviderId,
                                                    _2: parseFirebaseAuthSigninError(error)
                                                  };
                                          })));
                        }));
                  
                }),
              size: "large"
            }), error$1 !== undefined ? React.createElement("div", {
                className: errorMessageStyles
              }, error$1) : null);
  }
  return React.createElement("div", {
              className: rootStyles
            }, tmp);
}

var make = UnauthenticatedSchemaAccessWall;

export {
  rootStyles ,
  centerLoadingStyles ,
  errorMessageStyles ,
  make ,
  
}
/* rootStyles Not a pure module */
