// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Router from "./Router.mjs";
import * as $$Comment from "./Comment.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ObjectActivityLogAction from "./ObjectActivityLogAction.mjs";

function PropertyActivityItem(Props) {
  var $$event = Props.event;
  var property = Props.property;
  var model = Props.model;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var showComments = Props.showComments;
  var itemName = "event";
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match === "object") {
    var variant = match.NAME;
    if (variant === "AddProperty") {
      var $$event$1 = ModelUtils.getEventByIdWithArchive(match.VAL[0], model);
      if ($$event$1 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "created the property and added it to the event ", React.createElement("b", undefined, $$event$1.name));
      } else {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "created the property"
                  });
      }
    }
    if (variant === "DeprecatedRemovePropertyValidationMatch") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "removed the allowed value ", React.createElement("b", undefined, match.VAL[1]));
    }
    if (variant === "TogglePropertyValidationMatchSource") {
      var match$1 = match.VAL;
      var source = ModelUtils.getSourceById(match$1[2], model);
      if (source !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, match$1[3] ? "enabled " : "disabled ", React.createElement("b", undefined, match$1[1]), " to be sent as the value of the property from ", React.createElement("b", undefined, AvoConfig.getSourceName(source)));
      } else {
        return null;
      }
    }
    if (variant === "RemoveEventSpecificPropertyValueForAllEvents") {
      var literal = match.VAL[1];
      if (typeof literal === "object" && literal.NAME === "StringLit") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "removed the allowed value ", React.createElement("b", undefined, literal.VAL));
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyUniqueName") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "set unique name to " + match.VAL[1]
                });
    }
    if (variant === "RemovePropertyPermanently") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "deleted the property"
                });
    }
    if (variant === "RemovePropertyFromWhitelist") {
      var match$2 = match.VAL;
      var $$event$2 = ModelUtils.getEventByIdWithArchive(match$2[0], model);
      if ($$event$2 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "stopped sending the property to ", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$2[2])), "with the " + itemName + " ", React.createElement("b", undefined, $$event$2.name));
      } else {
        return null;
      }
    }
    if (!(variant === "CompletedImport" || variant === "ImportDeprecated")) {
      if (variant === "CreateDemoBranch") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "created the property on a demo branch"
                  });
      }
      if (variant === "DeprecatedAddPropertyValidationMatch") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "added the allowed value ", React.createElement("b", undefined, match.VAL[1]));
      }
      if (variant === "DeprecatedRemovePropertyValidationMatches") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "removed all matches validation"
                  });
      }
      if (variant === "UpdatePropertyList") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "changed the property to ", React.createElement("b", undefined, match.VAL[1] ? "list" : "single item"));
      }
      if (variant === "UpdatePropertyName") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "updated the name to " + match.VAL[1]
                  });
      }
      if (variant === "UpdatePropertyDescription") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "updated the description to " + match.VAL[1]
                  });
      }
      if (variant === "ClearAllEventSpecificPropertyValuesFromProperty") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "cleared all allowed values on the property"
                  });
      }
      if (variant === "UpdatePropertySendAs") {
        var sendAs = match.VAL[1];
        var tmp;
        if (typeof sendAs === "number") {
          switch (sendAs) {
            case /* SystemProperty */0 :
                tmp = "system property";
                break;
            case /* EventProperty */1 :
                tmp = "event property";
                break;
            case /* UserProperty */2 :
                tmp = "user property";
                break;
            
          }
        } else {
          tmp = sendAs._0 + " group property";
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "changed the property to ", React.createElement("b", undefined, tmp));
      }
      if (variant === "AddRule") {
        var match$3 = match.VAL;
        var match$4 = match$3[1];
        if (typeof match$4 !== "object") {
          return null;
        }
        if (match$4.NAME !== "Property") {
          return null;
        }
        var match$5 = match$3[2];
        if (typeof match$5 !== "object") {
          return null;
        }
        var variant$1 = match$5.NAME;
        if (variant$1 === "NameMapping") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "added a name mapping"
                    });
        } else if (variant$1 === "NameInLocalWorkspace") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "added a name mapping"
                    });
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyType") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "set the type to ", React.createElement("b", undefined, match.VAL[1]));
      }
      if (variant === "AddPropertyRef") {
        var eventId = match.VAL[0];
        var $$event$3 = ModelUtils.getEventByIdWithArchive(eventId, model);
        if ($$event$3 !== undefined) {
          return React.createElement(ObjectActivityLogAction.make, {
                      action: action,
                      users: users$1,
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              eventId,
                              undefined,
                              undefined,
                              false
                            ]
                          }),
                      openBranches: openBranches,
                      children: null
                    }, "added property to the " + itemName + " ", React.createElement("b", undefined, $$event$3.name));
        } else {
          return null;
        }
      }
      if (variant === "ConvertPropertyToGlobalPropertyV2" || variant === "ConvertPropertyToGlobalProperty") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "merged the property with global requirements"
                  });
      }
      if (variant === "UpdatePropertyRegexValidation") {
        var match$6 = match.VAL;
        var eventId$1 = match$6[1];
        if (eventId$1 !== undefined) {
          var match$7 = match$6[2];
          if (match$7 !== undefined) {
            var $$event$4 = ModelUtils.getEventByIdWithArchive(eventId$1, model);
            if ($$event$4 !== undefined) {
              return React.createElement(ObjectActivityLogAction.Content.make, {
                          action: action,
                          users: users$1,
                          openBranches: openBranches,
                          children: null
                        }, "updated the regex constraint on the event ", React.createElement("b", undefined, $$event$4.name), " to ", React.createElement("b", undefined, match$7.regex));
            } else {
              return null;
            }
          }
          var $$event$5 = ModelUtils.getEventByIdWithArchive(eventId$1, model);
          if ($$event$5 !== undefined) {
            return React.createElement(ObjectActivityLogAction.Content.make, {
                        action: action,
                        users: users$1,
                        openBranches: openBranches,
                        children: null
                      }, "removed the regex constraint on the event ", React.createElement("b", undefined, $$event$5.name));
          } else {
            return null;
          }
        }
        var match$8 = match$6[2];
        if (match$8 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "set the regex constraint to ", React.createElement("b", undefined, match$8.regex));
        } else {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "removed the regex constraint "
                    });
        }
      }
      if (variant === "RemoveEventSpecificPropertyValue") {
        var match$9 = match.VAL;
        var sources = match$9[3];
        var literal$1 = match$9[2];
        var match$10 = ModelUtils.getEventByIdWithArchive(match$9[0], model);
        if (typeof literal$1 !== "object") {
          return null;
        }
        if (literal$1.NAME !== "StringLit") {
          return null;
        }
        if (match$10 === undefined) {
          return null;
        }
        var sourcesAreAllEventSources = Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(match$10.includeSources, (function (param) {
                            return param.id;
                          })))), sources);
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "removed the allowed value ", React.createElement("b", undefined, literal$1.VAL), " on the event ", React.createElement("b", undefined, match$10.name), sourcesAreAllEventSources ? null : React.createElement(React.Fragment, undefined, " for ", React.createElement("b", undefined, Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(sources), (function (sourceId) {
                                          return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                                        })), AvoConfig.getSourceName).join(", "))));
      }
      if (variant === "Archive") {
        var match$11 = match.VAL;
        if (typeof match$11 === "object" && match$11.NAME === "Property") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "archived the property"
                    });
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyRefV3" || variant === "RemovePropertyRefV2Deprecated") {
        var eventId$2 = match.VAL[0];
        var $$event$6 = ModelUtils.getEventByIdWithArchive(eventId$2, model);
        if ($$event$6 !== undefined) {
          return React.createElement(ObjectActivityLogAction.make, {
                      action: action,
                      users: users$1,
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              eventId$2,
                              undefined,
                              undefined,
                              false
                            ]
                          }),
                      openBranches: openBranches,
                      children: null
                    }, "removed property from the " + itemName + " ", React.createElement("b", undefined, $$event$6.name));
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyOperation") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "made the property ", React.createElement("b", undefined, AvoConfig.operationLabel(match.VAL[1])));
      }
      if (variant === "TogglePropertyExcludedSource") {
        var match$12 = match.VAL;
        var source$1 = ModelUtils.getSourceById(match$12[1], model);
        if (source$1 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, (
                      match$12[2] ? "started " : "stopped "
                    ) + "sending property to ", React.createElement("b", undefined, AvoConfig.getSourceName(source$1)));
        } else {
          return null;
        }
      }
      if (variant === "SetEventSpecificPropertyValueSources") {
        var match$13 = match.VAL;
        var removedSources = match$13[4];
        var addedSources = match$13[3];
        var literal$2 = match$13[2];
        var match$14 = ModelUtils.getEventByIdWithArchive(match$13[0], model);
        if (typeof literal$2 !== "object") {
          return null;
        }
        if (literal$2.NAME !== "StringLit") {
          return null;
        }
        if (match$14 === undefined) {
          return null;
        }
        var match$15 = literal$2.VAL;
        var tmp$1;
        if (Belt_SetString.isEmpty(addedSources)) {
          tmp$1 = null;
        } else {
          var sources$1 = Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(addedSources), (function (sourceId) {
                        return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                      })), AvoConfig.getSourceName).join(", ");
          tmp$1 = React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, "added the allowed value ", React.createElement("b", undefined, match$15), " on the event ", React.createElement("b", undefined, match$14.name), " for ", React.createElement("b", undefined, sources$1));
        }
        var tmp$2;
        if (Belt_SetString.isEmpty(removedSources)) {
          tmp$2 = null;
        } else {
          var sources$2 = Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(removedSources), (function (sourceId) {
                        return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                      })), AvoConfig.getSourceName).join(", ");
          tmp$2 = React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, "removed the allowed value ", React.createElement("b", undefined, match$15), " on the event ", React.createElement("b", undefined, match$14.name), " for ", React.createElement("b", undefined, sources$2));
        }
        return React.createElement(React.Fragment, undefined, tmp$1, tmp$2);
      }
      if (variant === "DeprecatedUpdatePropertyValidationMinOrMax") {
        var match$16 = match.VAL;
        var validation = TrackingPlanModel.constraint_ToJs(match$16[2]).toLowerCase();
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "set " + validation + " constraint to ", React.createElement("b", undefined, match$16[1]));
      }
      if (variant === "UpdatePropertyOptional") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "made the property ", React.createElement("b", undefined, match.VAL[1] ? "optional" : "required"));
      }
      if (variant === "ClearEventVariantSpecificPropertyValueOverride" || variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" || variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed") {
        return null;
      }
      if (variant === "CreateProperty" || variant === "AddSystemProperty") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "created the property"
                  });
      }
      if (variant === "UpdateRule") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "updated a name mapping"
                  });
      }
      if (variant === "AddEventSpecificPropertyValue") {
        var match$17 = match.VAL;
        var sources$3 = match$17[3];
        var literal$3 = match$17[2];
        var match$18 = ModelUtils.getEventByIdWithArchive(match$17[0], model);
        if (typeof literal$3 !== "object") {
          return null;
        }
        if (literal$3.NAME !== "StringLit") {
          return null;
        }
        if (match$18 === undefined) {
          return null;
        }
        var sourcesAreAllEventSources$1 = Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(match$18.includeSources, (function (param) {
                            return param.id;
                          })))), sources$3);
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "added the allowed value ", React.createElement("b", undefined, literal$3.VAL), " on the event ", React.createElement("b", undefined, match$18.name), sourcesAreAllEventSources$1 ? null : React.createElement(React.Fragment, undefined, " for ", React.createElement("b", undefined, Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(sources$3), (function (sourceId) {
                                          return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                                        })), AvoConfig.getSourceName).join(", "))));
      }
      if (variant === "AddEventSpecificPropertyValueForAllEvents") {
        var literal$4 = match.VAL[1];
        if (typeof literal$4 === "object" && literal$4.NAME === "StringLit") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "added the allowed value ", React.createElement("b", undefined, literal$4.VAL));
        } else {
          return null;
        }
      }
      if (variant === "AddPropertyToWhitelist") {
        var match$19 = match.VAL;
        var $$event$7 = ModelUtils.getEventByIdWithArchive(match$19[0], model);
        if ($$event$7 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "started sending the property to ", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$19[2])), "with the " + itemName + " ", React.createElement("b", undefined, $$event$7.name));
        } else {
          return null;
        }
      }
      if (variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "allowed all values on all events and sources"
                  });
      }
      if (variant === "RemovePropertyFromGroup") {
        var group = ModelUtils.getPropertyGroupByIdWithArchive(match.VAL[0], model);
        if (group !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "removed property from the group ", React.createElement("b", undefined, group.name));
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyPinnedValue") {
        var match$20 = match.VAL;
        var $$event$8 = ModelUtils.getEventById(match$20[0], model);
        if ($$event$8 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "pinned the value on ", React.createElement("b", undefined, $$event$8.name), " to ", React.createElement("b", undefined, PropertyUtils.printPinnedValue(match$20[2])));
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyValidation") {
        var validation$1 = match.VAL[1];
        var variant$2 = validation$1.NAME;
        var tmp$3;
        if (variant$2 === "EndsWith" || variant$2 === "NestedProperty" || variant$2 === "Contains" || variant$2 === "StartsWith") {
          tmp$3 = null;
        } else if (variant$2 === "Regex") {
          tmp$3 = React.createElement(React.Fragment, undefined, "removed the regex constraint ", React.createElement("b", undefined, Belt_Option.mapWithDefault(validation$1.VAL.propertyRule, null, (function (param) {
                          return param.regex;
                        }))));
        } else if (variant$2 === "Max") {
          var match$21 = validation$1.VAL;
          if (typeof match$21 === "object") {
            var variant$3 = match$21.NAME;
            tmp$3 = variant$3 === "IntLit" || variant$3 === "FloatLit" ? React.createElement(React.Fragment, undefined, "removed the max ", React.createElement("b", undefined, match$21.VAL.toString()), " constraint") : null;
          } else {
            tmp$3 = null;
          }
        } else if (variant$2 === "Min") {
          var match$22 = validation$1.VAL;
          if (typeof match$22 === "object") {
            var variant$4 = match$22.NAME;
            tmp$3 = variant$4 === "IntLit" || variant$4 === "FloatLit" ? React.createElement(React.Fragment, undefined, "removed the min ", React.createElement("b", undefined, match$22.VAL.toString()), " constraint") : null;
          } else {
            tmp$3 = null;
          }
        } else if (variant$2 === "Shape") {
          var match$23 = validation$1.VAL;
          tmp$3 = match$23 && !match$23.tl ? React.createElement(React.Fragment, undefined, "removed the object key ", React.createElement("b", undefined, match$23.hd.key)) : null;
        } else {
          var match$24 = validation$1.VAL;
          if (match$24) {
            var match$25 = match$24.hd[0].VAL;
            tmp$3 = typeof match$25 === "object" && match$25.NAME === "StringLit" && !match$24.tl ? React.createElement(React.Fragment, undefined, "removed the allowed value ", React.createElement("b", undefined, match$25.VAL)) : null;
          } else {
            tmp$3 = "removed all constraints";
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: tmp$3
                  });
      }
      if (variant === "AddPropertyValidation") {
        var validation$2 = match.VAL[1];
        var variant$5 = validation$2.NAME;
        var tmp$4;
        if (variant$5 === "EndsWith" || variant$5 === "Contains" || variant$5 === "StartsWith") {
          tmp$4 = null;
        } else if (variant$5 === "Regex") {
          var match$26 = validation$2.VAL.propertyRule;
          tmp$4 = match$26 !== undefined ? React.createElement(React.Fragment, undefined, "set the regex constraint to ", React.createElement("b", undefined, match$26.regex)) : "set an event-specific regex constraint";
        } else if (variant$5 === "Max") {
          var match$27 = validation$2.VAL;
          if (typeof match$27 === "object") {
            var variant$6 = match$27.NAME;
            tmp$4 = variant$6 === "IntLit" || variant$6 === "FloatLit" ? React.createElement(React.Fragment, undefined, "set max constraint to ", React.createElement("b", undefined, match$27.VAL.toString())) : null;
          } else {
            tmp$4 = null;
          }
        } else if (variant$5 === "Min") {
          var match$28 = validation$2.VAL;
          if (typeof match$28 === "object") {
            var variant$7 = match$28.NAME;
            tmp$4 = variant$7 === "IntLit" || variant$7 === "FloatLit" ? React.createElement(React.Fragment, undefined, "set min constraint to ", React.createElement("b", undefined, match$28.VAL.toString())) : null;
          } else {
            tmp$4 = null;
          }
        } else if (variant$5 === "NestedProperty") {
          var match$29 = validation$2.VAL;
          tmp$4 = match$29 ? (
              match$29.tl ? null : React.createElement(React.Fragment, undefined, "added ", React.createElement("b", undefined, Belt_Option.getWithDefault(ModelUtils.getPropertyNameById(match$29.hd.id, model), "unknown property")), " to object")
            ) : "added object validation";
        } else if (variant$5 === "Shape") {
          var match$30 = validation$2.VAL;
          if (match$30 && !match$30.tl) {
            var item = match$30.hd;
            tmp$4 = React.createElement(React.Fragment, undefined, "set the object key ", React.createElement("b", undefined, item.key), " to be a " + PropertyUtils.printValue(item));
          } else {
            tmp$4 = "added object validation";
          }
        } else {
          var match$31 = validation$2.VAL;
          if (match$31) {
            var match$32 = match$31.hd[0].VAL;
            tmp$4 = typeof match$32 === "object" && match$32.NAME === "StringLit" && !match$31.tl ? React.createElement(React.Fragment, undefined, "added the allowed value ", React.createElement("b", undefined, match$32.VAL)) : null;
          } else {
            tmp$4 = null;
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: tmp$4
                  });
      }
      if (variant === "AddEventSpecificPropertyValueForSomeEvents") {
        var match$33 = match.VAL;
        var literal$5 = match$33[1];
        var match$34 = ModelUtils.getPropertyByIdWithArchive(match$33[0], model);
        var formattedTextChunks = match$34 !== undefined ? (
            match$34.TAG === /* PropertyRef */0 ? [] : (
                typeof literal$5 === "object" ? (
                    literal$5.NAME === "StringLit" ? [
                        {
                          TAG: /* Plain */0,
                          _0: "added the allowed value "
                        },
                        {
                          TAG: /* Bold */1,
                          _0: literal$5.VAL
                        },
                        {
                          TAG: /* Plain */0,
                          _0: " on some events"
                        }
                      ] : []
                  ) : []
              )
          ) : [];
        if (formattedTextChunks.length !== 0) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: Belt_Array.mapU(formattedTextChunks, (function (textChunk) {
                              if (textChunk.TAG === /* Plain */0) {
                                return textChunk._0;
                              } else {
                                return React.createElement("b", undefined, textChunk._0);
                              }
                            }))
                    });
        } else {
          return null;
        }
      }
      if (variant === "ClearPropertyExcludedSources") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "started sending the property to ", React.createElement("b", undefined, "all sources"));
      }
      if (variant === "DeprecatedRemovePropertyValidationMinOrMax") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "removed min or max constraint"
                  });
      }
      if (variant === "PropertyComment") {
        if (!showComments) {
          return null;
        }
        var match$35 = match.VAL;
        var propertyId = match$35[0];
        var tmp$5 = {
          threadId: propertyId,
          itemType: "Property",
          itemName: property.name,
          commentId: match$35[1],
          events: model.events,
          userId: userId,
          goToId: goToId,
          action: action,
          link: (function (commentId) {
              return Router.Link.addDrawerItem(undefined, {
                          NAME: "property",
                          VAL: [
                            propertyId,
                            commentId
                          ]
                        });
            }),
          openBranches: openBranches
        };
        var tmp$6 = Belt_Option.map($$event, (function ($$event) {
                return $$event.id;
              }));
        if (tmp$6 !== undefined) {
          tmp$5.eventId = Caml_option.valFromOption(tmp$6);
        }
        return React.createElement($$Comment.make, tmp$5);
      }
      if (variant === "UpdatePropertyOptionalWhenInObject") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "made the property ", React.createElement("b", undefined, match.VAL[1] ? "optional" : "required"), " when in object");
      }
      if (variant === "AddPropertyToGroup") {
        var group$1 = ModelUtils.getPropertyGroupByIdWithArchive(match.VAL[0], model);
        if (group$1 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "added property to the group ", React.createElement("b", undefined, group$1.name));
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyPinnedValue") {
        var $$event$9 = ModelUtils.getEventById(match.VAL[0], model);
        if ($$event$9 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "removed the pinned value on ", React.createElement("b", undefined, $$event$9.name));
        } else {
          return null;
        }
      }
      if (variant === "DeprecatedAddPropertyValidationMinOrMax") {
        var validation$3 = TrackingPlanModel.constraint_ToJs(match.VAL[1]).toLowerCase();
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "added " + validation$3 + " constraint"
                  });
      }
      if (variant === "UpdatePropertyAbsence") {
        var match$36 = match.VAL;
        var maybeEventSourceAbsence = match$36[2];
        var propertyAbsenceUpdateType = match$36[1];
        var tmp$7;
        if (typeof propertyAbsenceUpdateType === "number") {
          tmp$7 = React.createElement(React.Fragment, undefined, " on ", React.createElement("b", undefined, "all events"));
        } else {
          switch (propertyAbsenceUpdateType.TAG | 0) {
            case /* Event */0 :
                var eventId$3 = propertyAbsenceUpdateType._0;
                if (eventId$3 === TrackingPlanModel.propertyAbsenceAllEventsKey) {
                  tmp$7 = React.createElement(React.Fragment, undefined, " on ", React.createElement("b", undefined, "all events"));
                } else {
                  var $$event$10 = ModelUtils.getEventByIdWithArchive(eventId$3, model);
                  tmp$7 = $$event$10 !== undefined ? React.createElement(React.Fragment, undefined, " on the ", React.createElement("b", undefined, $$event$10.name), " event") : " on one event";
                }
                break;
            case /* Source */1 :
                var source$2 = ModelUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._0, model);
                tmp$7 = source$2 !== undefined ? React.createElement(React.Fragment, undefined, " from the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$2)), " source") : " from one source";
                break;
            case /* EventSource */2 :
                var sourceId = propertyAbsenceUpdateType._1;
                var eventId$4 = propertyAbsenceUpdateType._0;
                if (eventId$4 === TrackingPlanModel.propertyAbsenceAllEventsKey) {
                  var source$3 = ModelUtils.getSourceByIdWithArchive(sourceId, model);
                  tmp$7 = source$3 !== undefined ? React.createElement(React.Fragment, undefined, " on all events when sent from the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$3)), " source") : " on all events on one source";
                } else {
                  var match$37 = ModelUtils.getEventByIdWithArchive(eventId$4, model);
                  var match$38 = ModelUtils.getSourceByIdWithArchive(sourceId, model);
                  tmp$7 = match$37 !== undefined && match$38 !== undefined ? React.createElement(React.Fragment, undefined, " on the ", React.createElement("b", undefined, match$37.name), " event when sent from the ", React.createElement("b", undefined, AvoConfig.getSourceName(match$38)), " source") : " on one event and source";
                }
                break;
            
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "set the property to be ", React.createElement("b", undefined, maybeEventSourceAbsence !== undefined ? (
                          maybeEventSourceAbsence ? "sometimes sent" : "never sent"
                        ) : "always sent"), tmp$7);
      }
      if (variant !== "Unarchive") {
        if (variant === "RemoveRule") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "removed a name mapping"
                    });
        } else {
          return null;
        }
      }
      var match$39 = match.VAL;
      if (typeof match$39 === "object" && match$39.NAME === "Property") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "unarchived the property "
                  });
      } else {
        return null;
      }
    }
    
  } else if (match !== "StartedImport") {
    return null;
  }
  return React.createElement(ObjectActivityLogAction.Content.make, {
              action: action,
              users: users$1,
              openBranches: openBranches,
              children: "imported the property"
            });
}

var make = PropertyActivityItem;

export {
  make ,
  
}
/* react Not a pure module */
