// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as FramerMotion from "framer-motion";
import * as WorkspaceContext from "./WorkspaceContext.mjs";

function slideOverStyles(topOffset) {
  return Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.top(Css.px(topOffset)),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.maxWidth(Css.vw(80.0)),
                      tl: {
                        hd: Css.minWidth(Css.px(320)),
                        tl: {
                          hd: Css.overflow("auto"),
                          tl: {
                            hd: Css.zIndex(Styles.ZIndex.aboveAll),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.boxShadow(Styles.Shadow.standard),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function backdropStyles(topOffset) {
  return Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.top(Css.px(topOffset)),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.zIndex(Styles.ZIndex.aboveAll),
                        tl: {
                          hd: Css.backgroundColor({
                                NAME: "rgb",
                                VAL: [
                                  0,
                                  0,
                                  0
                                ]
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function SlideOverContainer(Props) {
  var onClose = Props.onClose;
  var slideOver = Props.slideOver;
  var isSandbox = WorkspaceContext.use(undefined).isSandbox;
  return React.createElement(FramerMotion.AnimatePresence, {
              exitBeforeEnter: true,
              children: slideOver !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 0.4
                          },
                          transition: {
                            duration: 0.3
                          },
                          initial: {
                            opacity: 0.0
                          },
                          exit: {
                            opacity: 0.0
                          },
                          className: backdropStyles(SandboxBanner.getHeight(isSandbox)),
                          key: "backdrop",
                          onClick: (function (param) {
                              return Curry._1(onClose, undefined);
                            })
                        }), React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 1,
                            x: 0
                          },
                          transition: {
                            duration: 0.3
                          },
                          initial: {
                            opacity: 0,
                            x: 50
                          },
                          exit: {
                            opacity: 0,
                            x: 50
                          },
                          className: slideOverStyles(SandboxBanner.getHeight(isSandbox)),
                          key: slideOver[1],
                          children: slideOver[0]
                        })) : null
            });
}

var make = SlideOverContainer;

export {
  make ,
  
}
/* Css Not a pure module */
