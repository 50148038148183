// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Issues from "../../shared/models/Issues.mjs";
import * as $$Number from "./Number.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Window from "./externals/window.mjs";
import * as DateFns from "./DateFns.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as DateFns$1 from "date-fns";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IssuesTable from "./inspector/IssuesTable.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as SegmentControl from "./SegmentControl.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as IssuesTableConfig from "./inspector/IssuesTableConfig.mjs";
import * as InspectorIssueType from "../../shared/models/InspectorIssueType.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as InspectorIssueStatus from "../../shared/models/InspectorIssueStatus.mjs";
import * as InspectorIssuesStore from "./inspector/InspectorIssuesStore.mjs";
import * as InspectorIssuesTypes from "./InspectorIssuesTypes.mjs";
import * as InspectorIssuesFilter from "../../shared/models/InspectorIssuesFilter.mjs";
import * as InspectorIssuesFilters from "./InspectorIssuesFilters.mjs";
import * as InspectorIssueViewModel from "./inspector/InspectorIssueViewModel.mjs";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesFilterUtils from "../../shared/utils/InspectorIssuesFilterUtils.mjs";
import * as InspectorIssuesView__EmptyState from "./InspectorIssuesView__EmptyState.mjs";
import * as InspectorIssuesView__ImportEventsButton from "./InspectorIssuesView__ImportEventsButton.mjs";

var regression_1 = [
  {
    id: "regression",
    name: "",
    width: {
      NAME: "fixed",
      VAL: Css.px(24)
    },
    compare: (function (a, b) {
        return Caml.caml_bool_compare(a.regression, b.regression);
      }),
    defaultOrder: "asc",
    alignment: "left"
  },
  (function (param) {
      if (param.issueStatus.status === /* Unresolved */0 && param.regression) {
        return React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginLeft(Css.px(-4)),
                          tl: /* [] */0
                        })
                  }, React.createElement(IssuesTableConfig.TableTooltip.make, {
                        tooltip: "Inspector detected a regression on this issue",
                        position: "bottom-start",
                        children: React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.width(Css.px(24)),
                                      tl: {
                                        hd: Css.height(Css.px(24)),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(12)),
                                          tl: {
                                            hd: Css.marginLeft(Css.px(-4)),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.justifyContent("center"),
                                                tl: {
                                                  hd: Css.backgroundColor(Styles.Color.lightRed),
                                                  tl: {
                                                    hd: Css.transition({
                                                          NAME: "ms",
                                                          VAL: 100.0
                                                        }, undefined, undefined, "all"),
                                                    tl: {
                                                      hd: Icon.styles({
                                                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.red),
                                                            tl: {
                                                              hd: Css.transition({
                                                                    NAME: "ms",
                                                                    VAL: 100.0
                                                                  }, undefined, undefined, "all"),
                                                              tl: /* [] */0
                                                            }
                                                          }),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.backgroundColor(Styles.Color.paleRed),
                                                              tl: {
                                                                hd: Icon.color(Styles.Color.white),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement(Icon.make, {
                                  type_: "inspector"
                                }))
                      }));
      } else {
        return null;
      }
    })
];

var regression = {
  NAME: "component",
  VAL: regression_1
};

var type__1 = [
  {
    id: "issue",
    name: "Issue",
    width: "grow",
    compare: (function (a, b) {
        return Caml.caml_string_compare(InspectorIssueType.getIssueTypeAsText(a.issueType), InspectorIssueType.getIssueTypeAsText(b.issueType));
      }),
    defaultOrder: "asc",
    alignment: "left"
  },
  (function (issue) {
      return React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light11,
                  children: StringExt.widowless(InspectorIssuesFilter.FilterIssueType.toDisplayString(InspectorIssuesFilter.FilterIssueType.fromNewIssue(issue)))
                });
    })
];

var type_ = {
  NAME: "component",
  VAL: type__1
};

var event_1 = [
  {
    id: "event",
    name: "Event",
    width: "grow",
    compare: (function (a, b) {
        return Caml.caml_string_compare(a.eventName.toLocaleLowerCase(), b.eventName.toLocaleLowerCase());
      }),
    defaultOrder: "asc",
    alignment: "left"
  },
  (function (issue) {
      return issue.eventName;
    })
];

var $$event = {
  NAME: "disambiguatedString",
  VAL: event_1
};

var property_1 = [
  {
    id: "property",
    name: "Property",
    width: "grow",
    compare: (function (a, b) {
        return Caml_obj.caml_compare(a.propertyName, b.propertyName);
      }),
    defaultOrder: "asc",
    alignment: "left"
  },
  (function (issue) {
      return Belt_Option.getWithDefault(issue.propertyName, "");
    })
];

var property = {
  NAME: "disambiguatedString",
  VAL: property_1
};

var source_1 = [
  {
    id: "source",
    name: "Source",
    width: "grow",
    compare: (function (a, b) {
        return Caml.caml_string_compare(a.source.name, b.source.name);
      }),
    defaultOrder: "desc",
    alignment: "left"
  },
  (function (issue) {
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(-8)),
                        tl: /* [] */0
                      })
                }, React.createElement("span", {
                      className: Curry._1(Css.style, Styles.maxLines(2))
                    }, React.createElement(IssuesTableConfig.Pill.make, {
                          label: issue.source.name
                        })));
    })
];

var source = {
  NAME: "component",
  VAL: source_1
};

function impactedReleases(appVersions, releaseFilter) {
  return {
          NAME: "component",
          VAL: [
            {
              id: "impacted-releases",
              name: "Impacted releases",
              width: "grow",
              compare: (function (a, b) {
                  if (a.source.name !== b.source.name) {
                    return Caml.caml_string_compare(a.source.name, b.source.name);
                  }
                  var a$1 = Belt_Option.getExn(Belt_Array.get(a.source.impactedVersions, 0));
                  var b$1 = Belt_Option.getExn(Belt_Array.get(b.source.impactedVersions, 0));
                  return InspectorIssueViewModel.Version.compare(a$1, b$1);
                }),
              defaultOrder: "desc",
              alignment: "left"
            },
            (function (issue) {
                return React.createElement(IssuesTableConfig.Releases.make, {
                            issue: issue,
                            appVersions: appVersions,
                            releaseFilter: releaseFilter
                          });
              })
          ]
        };
}

var firstSeen_1 = [
  {
    id: "firstSeen",
    name: "First Seen",
    width: {
      NAME: "fixed",
      VAL: Css.px(100)
    },
    compare: (function (a, b) {
        return Caml_obj.caml_compare(a.firstSeen, b.firstSeen);
      }),
    defaultOrder: "desc",
    alignment: "left"
  },
  (function (issue) {
      return React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light11,
                  title: issue.firstSeen.toLocaleString(),
                  children: DateFns.formatDistance(undefined, true, new Date(), issue.firstSeen).replace("about ", "")
                });
    })
];

var firstSeen = {
  NAME: "component",
  VAL: firstSeen_1
};

var lastSeen_1 = [
  {
    id: "lastSeen",
    name: "Last Seen",
    width: {
      NAME: "fixed",
      VAL: Css.px(100)
    },
    compare: (function (a, b) {
        return Caml_obj.caml_compare(a.lastSeen, b.lastSeen);
      }),
    defaultOrder: "desc",
    alignment: "left"
  },
  (function (issue) {
      return React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light11,
                  title: issue.lastSeen.toLocaleString(),
                  children: DateFns.formatDistance(undefined, true, new Date(), issue.lastSeen).replace("about ", "")
                });
    })
];

var lastSeen = {
  NAME: "component",
  VAL: lastSeen_1
};

function eventVolume(releaseFilter) {
  return {
          NAME: "component",
          VAL: [
            {
              id: "eventVolume",
              name: "Event Vol.",
              width: {
                NAME: "fixed",
                VAL: Css.px(76)
              },
              compare: (function (a, b) {
                  return Caml.caml_int_compare(a.eventCount, b.eventCount);
                }),
              defaultOrder: "desc",
              alignment: "right"
            },
            (function (issue) {
                var tooltip = releaseFilter !== undefined ? (
                    releaseFilter === "threeLatestReleases" ? "Event volume across the three latest releases sending this event" : (
                        releaseFilter === "twoLatestReleases" ? "Event volume across the two latest releases sending this event" : "Event volume on the latest release sending this event"
                      )
                  ) : "Event volume across all releases sending this event";
                return React.createElement(IssuesTableConfig.Count.make, {
                            count: issue.eventCount,
                            tooltip: tooltip
                          });
              })
          ]
        };
}

function issueVolume(releaseFilter) {
  return {
          NAME: "component",
          VAL: [
            {
              id: "issueVolume",
              name: "Issue Vol.",
              width: {
                NAME: "fixed",
                VAL: Css.px(76)
              },
              compare: (function (a, b) {
                  return Caml.caml_int_compare(a.issueCount, b.issueCount);
                }),
              defaultOrder: "desc",
              alignment: "right"
            },
            (function (issue) {
                var tooltip = releaseFilter !== undefined ? (
                    releaseFilter === "threeLatestReleases" ? "Issue volume across the three latest releases sending this event" : (
                        releaseFilter === "twoLatestReleases" ? "Issue volume across the two latest releases sending this event" : "Issue volume on the latest release sending this event"
                      )
                  ) : "Issue volume across all releases sending this event";
                return React.createElement(IssuesTableConfig.Count.make, {
                            count: issue.issueCount,
                            tooltip: tooltip
                          });
              })
          ]
        };
}

var issuePercentage_1 = [
  {
    id: "issuePercentage",
    name: "Issue %",
    width: {
      NAME: "fixed",
      VAL: Css.px(66)
    },
    compare: (function (a, b) {
        return Caml.caml_float_compare(a.issueCount / a.eventCount, b.issueCount / b.eventCount);
      }),
    defaultOrder: "desc",
    alignment: "right"
  },
  (function (issue) {
      var match = issue.issueCount;
      var match$1 = issue.eventCount;
      if (match !== 0 && match$1 !== 0) {
        return $$Number.getRoughPercentageString(100.0 * match / match$1) + "%";
      } else {
        return "0%";
      }
    })
];

var issuePercentage = {
  NAME: "string",
  VAL: issuePercentage_1
};

var ignoredAt_1 = [
  {
    id: "ignoredSince",
    name: "Ignored Since",
    width: {
      NAME: "fixed",
      VAL: Css.px(100)
    },
    compare: (function (a, b) {
        return Caml_obj.caml_compare(a.issueStatus.updatedAt, b.issueStatus.updatedAt);
      }),
    defaultOrder: "desc",
    alignment: "right"
  },
  (function (param) {
      var updatedAt = param.issueStatus.updatedAt;
      if (updatedAt === undefined) {
        return null;
      }
      var updatedAt$1 = Caml_option.valFromOption(updatedAt);
      return React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light11,
                  title: updatedAt$1.toLocaleString(),
                  children: DateFns.formatDistance(undefined, true, new Date(), updatedAt$1).replace("about ", "")
                });
    })
];

var ignoredAt = {
  NAME: "component",
  VAL: ignoredAt_1
};

var resolvedAt_1 = [
  {
    id: "resolvedAt",
    name: "Resolved",
    width: {
      NAME: "fixed",
      VAL: Css.px(100)
    },
    compare: (function (a, b) {
        return Caml_obj.caml_compare(a.issueStatus.updatedAt, b.issueStatus.updatedAt);
      }),
    defaultOrder: "desc",
    alignment: "right"
  },
  (function (param) {
      var match = param.issueStatus.status;
      var tmp;
      if (typeof match === "number" || match.TAG === /* Ignored */0) {
        tmp = null;
      } else {
        var date = match._0;
        var exit = 0;
        if (typeof date === "number") {
          tmp = null;
        } else {
          switch (date.TAG | 0) {
            case /* NextAppVersion */1 :
                tmp = React.createElement(React.Fragment, undefined, "after ", React.createElement(IssuesTableConfig.Pill.make, {
                          label: date._0
                        }));
                break;
            case /* CurrentAppVersion */0 :
            case /* CustomAppVersion */2 :
                exit = 1;
                break;
            case /* Date */3 :
                var date$1 = date._0;
                tmp = (
                  DateFns$1.isPast(date$1) ? "since" : "in"
                ) + " " + DateFns.formatDistanceToNow(undefined, undefined, date$1) + (
                  DateFns$1.isPast(date$1) ? " ago" : ""
                );
                break;
            
          }
        }
        if (exit === 1) {
          tmp = React.createElement(React.Fragment, undefined, "as of ", React.createElement(IssuesTableConfig.Pill.make, {
                    label: date._0
                  }));
        }
        
      }
      return React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light11,
                  children: tmp
                });
    })
];

var resolvedAt = {
  NAME: "component",
  VAL: resolvedAt_1
};

function getColumns(appVersions, releaseFilter, status) {
  var tmp;
  switch (status) {
    case /* Unresolved */0 :
        tmp = [];
        break;
    case /* Ignored */1 :
        tmp = [ignoredAt];
        break;
    case /* Resolved */2 :
        tmp = [resolvedAt];
        break;
    
  }
  return Belt_Array.concatMany([
              [
                regression,
                type_,
                $$event,
                property,
                source,
                impactedReleases(appVersions, releaseFilter),
                firstSeen,
                lastSeen,
                eventVolume(releaseFilter),
                issueVolume(releaseFilter),
                issuePercentage
              ],
              tmp
            ]);
}

function InspectorIssuesView__Table(Props) {
  var inspectorSources = Props.inspectorSources;
  var globalSend = Props.globalSend;
  var status = Props.status;
  var setStatus = Props.setStatus;
  var viewerRole = Props.viewerRole;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match.filters;
  var workspace = WorkspaceContext.use(undefined);
  var match$1 = InspectorIssuesStore.useFilteredIssues(status, undefined, undefined);
  var filteredIssues = match$1.filteredIssues;
  var issues = match$1.issues;
  var loadingState = match$1.loadingState;
  var hasDrawerItems = RouterStore.Schema.useHasDrawerItems(undefined);
  var match$2 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedView = match$2.savedView;
  var savedViewStatus = match$2.savedViewStatus;
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var model = ModelStore.useModel(undefined);
  var match$3 = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined, undefined);
  var appVersions = match$3[0];
  var match$4 = React.useState(function () {
        return Css.vh(100.0);
      });
  var setTableMaxHeight = match$4[1];
  React.useEffect((function () {
          var el = $$Window.$$Document.getElementById("issues-table-header");
          if (el !== undefined) {
            var domRect = Caml_option.valFromOption(el).getBoundingClientRect();
            Curry._1(setTableMaxHeight, (function (param) {
                    return {
                            NAME: "subtract",
                            VAL: [
                              Css.vh(100.0),
                              Css.pxFloat(domRect.top + domRect.height + window.pageYOffset)
                            ]
                          };
                  }));
          }
          
        }), []);
  var countedAvailableFilters = React.useMemo((function () {
          return InspectorIssuesFilterUtils.FilterCounter.get(inspectorSources, model, appVersions, Curry._1(Issues.toArray, issues));
        }), [issues]);
  var getCountedAvailableFilters = React.useCallback((function (ignoring) {
          return InspectorIssuesFilterUtils.FilterCounter.get(inspectorSources, model, appVersions, Curry._1(Issues.toArray, InspectorIssuesFilterUtils.filterIssueViewModels([ignoring], appVersions, issues, filters)));
        }), [
        inspectorSources,
        model,
        issues,
        appVersions
      ]);
  var orderedBy = InspectorIssuesFilterHooks.useOrderedBy(undefined);
  var onOrderByChange = InspectorIssuesFilterHooks.useSetOrderedBy(undefined);
  var inspectorIssuesViewEventSent = React.useRef(false);
  var savedViewEventSent = React.useRef(undefined);
  React.useEffect((function () {
          var exit = 0;
          var savedViews$1;
          if (typeof loadingState === "object") {
            if (loadingState.NAME === "error" && typeof savedViews === "object" && savedViews.NAME === "Loaded") {
              savedViews$1 = savedViews.VAL;
              exit = 1;
            }
            
          } else if (loadingState === "success" && typeof savedViews === "object" && savedViews.NAME === "Loaded") {
            savedViews$1 = savedViews.VAL;
            exit = 1;
          }
          if (exit === 1) {
            if (!inspectorIssuesViewEventSent.current) {
              inspectorIssuesViewEventSent.current = true;
              AnalyticsRe.inspectorIssuesViewed(InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, filteredIssues), Belt_Option.isSome(savedView) ? "SavedView" : "AvoCreated", InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_List.length(savedViews$1), Curry._1(Issues.size, InspectorIssuesFilterUtils.filterIssueViewModels(undefined, appVersions, issues, [
                            {
                              NAME: "issueType",
                              VAL: {
                                NAME: "new",
                                VAL: "eventNotInTrackingPlanV2"
                              }
                            },
                            {
                              NAME: "issueType",
                              VAL: {
                                NAME: "old",
                                VAL: "eventNotInTrackingPlan"
                              }
                            }
                          ])), Belt_List.size(model.events), schemaBundle.schemaId, schemaBundle.schemaId, issues.orderedItems.length !== 0 ? "EventsReceived" : undefined);
            }
            
          }
          
        }), [
        loadingState,
        savedViewStatus
      ]);
  React.useEffect((function () {
          if (savedView !== undefined && Caml_obj.caml_notequal(savedView.id, savedViewEventSent.current)) {
            savedViewEventSent.current = savedView.id;
            AnalyticsRe.inspectorSavedViewOpened(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, issues), "SavedView", InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), savedView.name, savedView.id, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1, schemaBundle.schemaId);
          }
          
        }), [savedView]);
  Hooks.useDidUpdate1((function (param) {
          if (Router.getSearch(undefined) === "") {
            
          } else {
            AnalyticsRe.inspectorIssuesViewConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, issues), InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), "OrderView", InspectorIssuesFilterUtils.orderedByToConfigureItemAnalytics(orderedBy), schemaBundle.schemaId);
          }
          
        }), [orderedBy]);
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  var activeRowId = React.useMemo((function () {
          if (!drawerItems) {
            return ;
          }
          var match = drawerItems.hd;
          if (typeof match === "object" && match.NAME === "inspectorIssue") {
            return match.VAL[0];
          }
          
        }), [drawerItems]);
  var columns = React.useMemo((function () {
          var match = Belt_Array.get(InspectorIssuesFilterUtils.getFilters(filters, "release"), 0);
          return getColumns(appVersions, match !== undefined && typeof match === "object" && match.NAME === "release" ? match.VAL : undefined, status);
        }), [
        appVersions,
        filters,
        status
      ]);
  var sourceReport = React.useMemo((function () {
          return InspectorIssueViewModel.Utils.getSourceReport(Curry._1(Issues.toArray, issues));
        }), [issues]);
  var isInspectorVolumeBlocked = AvoLimits.isInspectorVolumeBlocked(workspace);
  var tableData = React.useMemo((function () {
          var issues = Curry._1(Issues.toArray, filteredIssues);
          if (isInspectorVolumeBlocked) {
            return Belt_Array.slice(issues, 0, 3);
          } else {
            return issues;
          }
        }), [
        filteredIssues,
        isInspectorVolumeBlocked
      ]);
  var tmp;
  if (loadingState === "success") {
    var tmp$1;
    switch (status) {
      case /* Unresolved */0 :
          tmp$1 = "unresolved issue";
          break;
      case /* Ignored */1 :
          tmp$1 = "ignored issue";
          break;
      case /* Resolved */2 :
          tmp$1 = "resolved issue";
          break;
      
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
              size: "Small",
              children: null
            }, React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: AvoUtils.plural(undefined, undefined, Curry._1(Issues.size, filteredIssues), tmp$1)
                }), " seen in ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: AvoUtils.plural(undefined, undefined, sourceReport.releasesCount, "release") + " on " + AvoUtils.plural(undefined, undefined, sourceReport.sourcesCount, "source")
                })), sourceReport.releasesCount > 0 ? React.createElement(Button.make, {
                icon: "table",
                onClick: (function (param) {
                    return Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "IssueSourcesBreakdown",
                                  VAL: sourceReport
                                }
                              });
                  }),
                size: "tiny",
                style: "outline"
              }) : null, React.createElement(InspectorIssuesView__ImportEventsButton.make, {
              appVersions: appVersions,
              filteredIssues: filteredIssues
            }));
  } else {
    tmp = null;
  }
  var tmp$2 = {
    columns: columns,
    id: "issues-table",
    maxHeight: match$4[0],
    minWidth: 1100,
    onRowClick: (function (id) {
        return Router.Schema.pushDrawerItem(undefined, undefined, {
                    NAME: "inspectorIssue",
                    VAL: [
                      id,
                      undefined
                    ]
                  });
      }),
    rowSize: 56,
    data: tableData,
    orderBy: Belt_Array.some(columns, (function (column) {
            return Caml_obj.caml_equal(column.VAL[0].id, Belt_Option.map(orderedBy, (function (param) {
                              return param[0];
                            })));
          })) ? orderedBy : InspectorIssuesTypes.defaultOrder,
    onOrderByChange: onOrderByChange,
    emptyState: React.createElement(InspectorIssuesView__EmptyState.make, {
          filtersCount: filters.length,
          globalSend: globalSend,
          loadingState: loadingState,
          status: status
        }),
    showScrollToTopButton: !hasDrawerItems
  };
  if (activeRowId !== undefined) {
    tmp$2.activeRowId = Caml_option.valFromOption(activeRowId);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(InspectorIssuesFilters.make, {
                  countedAvailableFilters: countedAvailableFilters,
                  getCountedAvailableFilters: getCountedAvailableFilters,
                  inspectorDisplayedIssueCount: Curry._1(Issues.size, filteredIssues)
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.gap(Css.px(8)),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.paddingLeft(Css.px(24)),
                              tl: {
                                hd: Css.paddingRight(Css.px(24)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(SegmentControl.make, {
                      id: "issues-table-segment-control",
                      options: Belt_Array.map([
                            /* Unresolved */0,
                            /* Ignored */1,
                            /* Resolved */2
                          ], (function (s) {
                              return [
                                      InspectorIssueStatus.StatusQueryParam.toString(s),
                                      s
                                    ];
                            })),
                      onSelect: (function (status) {
                          Curry._1(setStatus, status);
                          return AnalyticsRe.inspectorIssuesViewConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), InspectorIssuesFilterUtils.savedViewOrderDirectionToAnalytics(orderedBy), InspectorIssuesFilterUtils.groupedFiltersToAnalytics(InspectorIssuesFilterUtils.groupFilters(filters)), Curry._1(Issues.size, issues), InspectorIssuesFilterUtils.savedViewStatusToViewTypeAnalytics(savedViewStatus), InspectorIssuesFilterUtils.inspectorIssueStatusViewToAnalytics(status), "SelectIssueStatus", InspectorIssuesFilterUtils.orderedByToConfigureItemAnalytics(orderedBy), schemaBundle.schemaId);
                        }),
                      selected: status,
                      circular: true
                    }), status >= 2 ? null : React.createElement(React.Fragment, undefined, React.createElement(Icon.make, {
                            type_: "time",
                            size: "small",
                            color: Styles.Color.light11
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Past 24 hours in Production"
                          })), React.createElement(Spacer.make, {
                      width: 16,
                      grow: 1.0
                    }), tmp), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(24)),
                        tl: {
                          hd: Css.paddingRight(Css.px(24)),
                          tl: {
                            hd: Css.position("relative"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(IssuesTable.make, tmp$2), isInspectorVolumeBlocked ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.top(Css.px(0)),
                                tl: {
                                  hd: Css.right(Css.px(24)),
                                  tl: {
                                    hd: Css.bottom(Css.px(0)),
                                    tl: {
                                      hd: Css.zIndex(1),
                                      tl: {
                                        hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
                                        tl: {
                                          hd: Css.left(Css.px(1)),
                                          tl: {
                                            hd: Css.background({
                                                  NAME: "linearGradient",
                                                  VAL: [
                                                    {
                                                      NAME: "deg",
                                                      VAL: 180.0
                                                    },
                                                    [
                                                      [
                                                        Css.pct(0.0),
                                                        Styles.Color.setAlpha(Styles.Color.white, 0.0)
                                                      ],
                                                      [
                                                        Css.pct(100.0),
                                                        Styles.Color.white
                                                      ]
                                                    ]
                                                  ]
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }) : null), isInspectorVolumeBlocked ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                            tl: {
                              hd: Css.paddingTop(Css.px(56)),
                              tl: {
                                hd: Css.paddingRight(Css.px(40)),
                                tl: {
                                  hd: Css.paddingBottom(Css.px(40)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(40)),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.white),
                                      tl: {
                                        hd: Css.borderBottomRightRadius(Styles.Border.radius),
                                        tl: {
                                          hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                                          tl: {
                                            hd: Css.marginLeft(Css.px(24)),
                                            tl: {
                                              hd: Css.marginRight(Css.px(24)),
                                              tl: {
                                                hd: Css.marginBottom(Css.px(24)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Title.make, {
                        children: "Upgrade to unlock Inspector issues",
                        size: "Small"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light10,
                        maxWidth: Css.px(540),
                        children: "Inspector issues are no longer accessible as you have exceeded the monthly event volume limit provided on our free plan."
                      }), React.createElement(Spacer.make, {
                        height: 24
                      }), viewerRole === "Admin" ? React.createElement(Button.make, {
                          label: "Explore plans",
                          onClick: (function (param) {
                              return Router.push(undefined, Router.Link.getSchemaRouteLink(undefined, "billing"));
                            }),
                          size: "large"
                        }) : React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.light10,
                          maxWidth: Css.px(540),
                          children: "Reach out to a workspace admin to upgrade your plan and unlock Inspector Issues."
                        })) : null);
}

var EmptyState;

var ImportButton;

var rowSize = 56;

var make = InspectorIssuesView__Table;

export {
  EmptyState ,
  ImportButton ,
  regression ,
  type_ ,
  $$event ,
  property ,
  source ,
  impactedReleases ,
  firstSeen ,
  lastSeen ,
  eventVolume ,
  issueVolume ,
  issuePercentage ,
  ignoredAt ,
  resolvedAt ,
  getColumns ,
  rowSize ,
  make ,
  
}
/* regression Not a pure module */
