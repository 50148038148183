// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../../bs-case/src/case.mjs";
import * as Dopt from "../externals/Dopt.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Confetti from "../Confetti.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as ProductTour__TourProvider from "./ProductTour__TourProvider.mjs";

function useOnStart(flow, tourMayShow, continuingFlow) {
  var schemaBundle = SchemaBundleContext.use(undefined);
  var started = flow.state.started && !flow.state.stopped && tourMayShow;
  var match = Dopt.Flow.getOrderedBlocks(flow);
  var active = match.active;
  var previousStarted = Hooks.usePrevious1(started);
  var previousActive = Hooks.usePrevious1(active);
  var eventSent = React.useRef(false);
  React.useEffect((function () {
          if (!previousStarted && started || previousActive.length === 0 && active.length !== 0 && !continuingFlow && !eventSent.current) {
            eventSent.current = true;
            AnalyticsRe.productFlowStarted(Case.title(flow.uid), flow.uid, String(flow.version), schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.schemaId);
          }
          
        }), [Belt_Option.mapU(Belt_Array.get(active, 0), (function (b) {
                return b.uid;
              }))]);
  
}

function useOnComplete(tourIdOverride, onComplete, setShowConfetti, withConfetti, flow) {
  var schemaBundle = SchemaBundleContext.use(undefined);
  var previousFinished = Hooks.usePrevious1(flow.state.finished);
  var flowId = tourIdOverride !== undefined ? tourIdOverride : flow.uid;
  React.useEffect((function () {
          if (!previousFinished && flow.state.finished) {
            AnalyticsRe.productFlowCompleted(Case.title(flowId), flowId, String(flow.version), schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.schemaId);
            if (withConfetti) {
              Curry._1(setShowConfetti, (function (param) {
                      return true;
                    }));
            }
            setTimeout((function (param) {
                    return Belt_Option.forEach(onComplete, (function (fn) {
                                  return Curry._1(fn, undefined);
                                }));
                  }), 0);
          }
          
        }), [flow.state.finished]);
  
}

function ProductTour__Root$Root(Props) {
  var continuingFlowOpt = Props.continuingFlow;
  var debugOpt = Props.debug;
  var onComplete = Props.onComplete;
  var resetOnExitOpt = Props.resetOnExit;
  var showStepCount = Props.showStepCount;
  var stepStartOffsetOpt = Props.stepStartOffset;
  var totalStepsOverride = Props.totalStepsOverride;
  var tourId = Props.tourId;
  var tourIdOverride = Props.tourIdOverride;
  var tourMayShowOpt = Props.tourMayShow;
  var withConfettiOpt = Props.withConfetti;
  var children = Props.children;
  var continuingFlow = continuingFlowOpt !== undefined ? continuingFlowOpt : false;
  var debug = debugOpt !== undefined ? debugOpt : false;
  var resetOnExit = resetOnExitOpt !== undefined ? resetOnExitOpt : false;
  var stepStartOffset = stepStartOffsetOpt !== undefined ? stepStartOffsetOpt : 0;
  var tourMayShow = tourMayShowOpt !== undefined ? tourMayShowOpt : true;
  var withConfetti = withConfettiOpt !== undefined ? withConfettiOpt : false;
  var match = Dopt.Flow.use(tourId);
  var flow = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowConfetti = match$1[1];
  var showConfetti = match$1[0];
  useOnStart(flow, tourMayShow, continuingFlow);
  useOnComplete(tourIdOverride, onComplete, setShowConfetti, withConfetti, flow);
  React.useEffect((function () {
          var timeout = {
            contents: undefined
          };
          if (showConfetti) {
            timeout.contents = Caml_option.some(setTimeout((function (param) {
                        return Curry._1(setShowConfetti, (function (param) {
                                      return false;
                                    }));
                      }), 10000));
          }
          return (function (param) {
                    return Belt_Option.forEach(timeout.contents, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [showConfetti]);
  React.useEffect((function () {
          if (debug) {
            Belt_Option.forEachU(Belt_Array.getByU(flow.blocks, (function (param) {
                        var match = param.state;
                        if (match.active) {
                          return match.entered;
                        } else {
                          return false;
                        }
                      })), (function (block) {
                    console.log("DOPT " + tourId + ":\n", {
                          "Active step": block,
                          Flow: flow,
                          Blocks: Dopt.Flow.getOrderedBlocks(flow)
                        });
                    
                  }));
          }
          
        }), [debug ? Caml_option.some(Belt_Option.map(Belt_Array.get(Dopt.Flow.getOrderedBlocks(flow).active, 0), (function (block) {
                      return block.uid;
                    }))) : undefined]);
  var tmp;
  if (!flow.state.finished && !flow.state.stopped) {
    var tmp$1 = {
      flow: flow,
      getStepNumber: (function (stepId) {
          return Belt_Option.map(Dopt.Flow.getStepNumber(stepId), (function (stepNumber) {
                        return stepStartOffset + stepNumber | 0;
                      }));
        }),
      resetOnExit: resetOnExit,
      tourId: tourId,
      tourMayShow: tourMayShow,
      children: children
    };
    if (showStepCount !== undefined) {
      tmp$1.showStepCount = Caml_option.valFromOption(showStepCount);
    }
    if (tourIdOverride !== undefined) {
      tmp$1.tourIdOverride = Caml_option.valFromOption(tourIdOverride);
    }
    if (totalStepsOverride !== undefined) {
      tmp$1.totalStepsOverride = Caml_option.valFromOption(totalStepsOverride);
    }
    tmp = React.createElement(ProductTour__TourProvider.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, showConfetti ? React.createElement(Confetti.make, {
                    numberOfPieces: 600,
                    width: window.innerWidth,
                    height: window.innerHeight,
                    colors: Belt_Array.map(Styles.Color.secondaryColors, Styles.Color.toString),
                    run: showConfetti,
                    recycle: false
                  }) : null, tmp);
}

var Root = {
  make: ProductTour__Root$Root
};

function ProductTour__Root(Props) {
  var tourId = Props.tourId;
  var continuingFlow = Props.continuingFlow;
  var debug = Props.debug;
  var onComplete = Props.onComplete;
  var resetOnExit = Props.resetOnExit;
  var showStepCount = Props.showStepCount;
  var stepStartOffset = Props.stepStartOffset;
  var totalStepsOverride = Props.totalStepsOverride;
  var tourMayShow = Props.tourMayShow;
  var tourIdOverride = Props.tourIdOverride;
  var withConfetti = Props.withConfetti;
  var children = Props.children;
  var isReady = Dopt.FlowStatus.useReady(tourId);
  if (!isReady) {
    return null;
  }
  var tmp = {
    tourId: tourId,
    children: children
  };
  if (continuingFlow !== undefined) {
    tmp.continuingFlow = Caml_option.valFromOption(continuingFlow);
  }
  if (debug !== undefined) {
    tmp.debug = Caml_option.valFromOption(debug);
  }
  if (onComplete !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(onComplete);
  }
  if (resetOnExit !== undefined) {
    tmp.resetOnExit = Caml_option.valFromOption(resetOnExit);
  }
  if (showStepCount !== undefined) {
    tmp.showStepCount = Caml_option.valFromOption(showStepCount);
  }
  if (stepStartOffset !== undefined) {
    tmp.stepStartOffset = Caml_option.valFromOption(stepStartOffset);
  }
  if (totalStepsOverride !== undefined) {
    tmp.totalStepsOverride = Caml_option.valFromOption(totalStepsOverride);
  }
  if (tourIdOverride !== undefined) {
    tmp.tourIdOverride = Caml_option.valFromOption(tourIdOverride);
  }
  if (tourMayShow !== undefined) {
    tmp.tourMayShow = Caml_option.valFromOption(tourMayShow);
  }
  if (withConfetti !== undefined) {
    tmp.withConfetti = Caml_option.valFromOption(withConfetti);
  }
  return React.createElement(ProductTour__Root$Root, tmp);
}

var make = ProductTour__Root;

export {
  useOnStart ,
  useOnComplete ,
  Root ,
  make ,
  
}
/* Case Not a pure module */
