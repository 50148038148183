// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Actions from "./actions.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as DiffUtils from "./DiffUtils.mjs";
import * as ActionDiff from "../../shared/ActionDiff.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as ActionUtils from "../../model/src/ActionUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Collaborators from "./Collaborators.mjs";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as AppVersionsStore from "./AppVersionsStore.mjs";
import * as AppVersionsTypes from "../../shared/models/AppVersionsTypes.mjs";
import * as DiscrepancyUtils from "./DiscrepancyUtils.mjs";
import * as FirebaseFetchers from "./FirebaseFetchers.mjs";
import * as IntegrationUtils from "../../api/functions/src/IntegrationUtils.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as BranchActionBox__MergeBranchModalBody from "./BranchActionBox__MergeBranchModalBody.mjs";
import * as InspectorVersionValidationIntentHelpers from "./InspectorVersionValidationIntentHelpers.mjs";

function sendBranchMergedAnalytics(schemaId, branchId, schemaBundle, actions, actionDiff, autoPublishIntegrations, model, completedEventsCounter, totalEventsCounter, completedSourcesCounter, totalSourcesCounter, numSourcesUpdatedOnBranchConnectedToAvoFunctions, numSourcesUpdatedOnBranchConnectedToInspector, numSourcesUpdatedOnBranchConnectedToImplementationStatus, eventInstancesImplementedRatioPerSource, workspace, numApprovals, numNewSchemaIssuesOnBranch, numSchemaIssues, numSchemaIssuesDelta, workspaceNumIssues, role, mergeWithoutApproval) {
  var proportionOfUpdatedEventInstancesImplementedOnBranch = totalEventsCounter !== undefined && completedEventsCounter !== undefined && totalEventsCounter > 0 ? completedEventsCounter / totalEventsCounter : undefined;
  var proportionOfUpdatedSourcesImplementedOnBranch = totalSourcesCounter !== undefined && completedSourcesCounter !== undefined && totalSourcesCounter > 0 ? completedSourcesCounter / totalSourcesCounter : undefined;
  var newProperties = Belt_List.keepMapU(actionDiff, (function (action) {
          if (typeof action === "object" && action.NAME === "CreateProperty") {
            return AvoModel.getPropertyById(model, action.VAL[0]);
          }
          
        }));
  var numNewProperties = Belt_List.length(newProperties);
  var numUpdatedPropertiesIncludingNew = Belt_List.length(Belt_List.keepU(actions, (function (groupedActions) {
              return Belt_List.someU(groupedActions, (function (action) {
                            var match = action[0];
                            if (typeof match === "object") {
                              return match.NAME === "Property";
                            } else {
                              return false;
                            }
                          }));
            })));
  var numNewEvents = Belt_List.length(Belt_List.keepU(actionDiff, (function (action) {
              if (typeof action === "object") {
                return action.NAME === "AddEvent";
              } else {
                return false;
              }
            })));
  var numUpdatedEventsIncludingNew = Belt_List.length(Belt_List.keepU(actions, (function (groupedActions) {
              return Belt_List.someU(groupedActions, (function (action) {
                            var match = action[0];
                            if (typeof match === "object") {
                              return match.NAME === "Event";
                            } else {
                              return false;
                            }
                          }));
            })));
  var partial_arg = schemaBundle.schemaType;
  var partial_arg$1 = schemaBundle.schemaTrialPlanSlug;
  var partial_arg$2 = schemaBundle.schemaSubscriptionPlanSlug;
  var partial_arg$3 = schemaBundle.authenticationConfig;
  var partial_arg$4 = schemaBundle.schemaTrialPlan;
  var partial_arg$5 = schemaBundle.schemaSubscriptionPlan;
  var partial_arg$6 = schemaBundle.branchName;
  var partial_arg$7 = schemaBundle.branchId;
  var partial_arg$8 = schemaBundle.schemaBillingStatus;
  var partial_arg$9 = schemaBundle.schemaName;
  var partial_arg$10 = schemaBundle.schemaId;
  var arg = Belt_List.length(autoPublishIntegrations);
  var arg$1 = Belt_List.toArray(Belt_List.keepMapU(autoPublishIntegrations, (function (integration) {
              return IntegrationUtils.configToAnalyticsType(integration.config);
            })));
  var arg$2 = Belt_List.length(Belt_List.keepU(newProperties, (function (property) {
              var match = property.absence;
              if (match !== undefined) {
                if (typeof match === "number" || match.TAG !== /* SometimesSent */0) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return property.optionalDeprecated;
              }
            })));
  var arg$3 = Belt_List.length(model.properties);
  var arg$4 = Belt_List.length(model.events);
  var arg$5 = Belt_List.length(Belt_List.keepU(model.properties, (function (property) {
              if (property.TAG === /* PropertyRef */0) {
                return false;
              }
              var property$1 = property._0;
              var match = property$1.absence;
              if (match !== undefined) {
                if (typeof match === "number" || match.TAG !== /* SometimesSent */0) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return property$1.optionalDeprecated;
              }
            })));
  var arg$6 = Belt_List.length(Belt_List.keepU(actionDiff, (function (action) {
              if (typeof action === "object" && action.NAME === "AddEvent") {
                return Belt_Option.mapWithDefault(ModelUtils.getEventById(action.VAL[0], model), false, (function (param) {
                              return param.triggers.length !== 0;
                            }));
              } else {
                return false;
              }
            })));
  var arg$7 = Belt_List.length(Belt_List.keepU(Belt_List.keepMapU(actions, (function (groupedActions) {
                  return Belt_Option.flatMap(Belt_List.getByU(groupedActions, (function (action) {
                                    var match = action[0];
                                    if (typeof match === "object") {
                                      return match.NAME === "Event";
                                    } else {
                                      return false;
                                    }
                                  })), (function (action) {
                                var match = action[0];
                                if (typeof match === "object" && match.NAME === "Event") {
                                  return match.VAL;
                                }
                                
                              }));
                })), (function (eventId) {
              return Belt_Option.mapWithDefault(ModelUtils.getEventById(eventId, model), false, (function (param) {
                            return param.triggers.length !== 0;
                          }));
            })));
  var arg$8 = Belt_List.length(Belt_List.keepU(model.sources, (function (source) {
              return Belt_List.someU(ModelUtils.getSourceEvents(undefined, model, source.id), (function ($$event) {
                            return Belt_Array.someU($$event.triggers, (function (trigger) {
                                          var sourceIds = trigger.sources;
                                          if (sourceIds) {
                                            return Belt_Array.someU(sourceIds._0, (function (sourceId) {
                                                          return sourceId === source.id;
                                                        }));
                                          } else {
                                            return true;
                                          }
                                        }));
                          }));
            })));
  var arg$9 = workspace.settings.numRequiredApprovals;
  var arg$10 = workspace.settings.protectedMainBranch ? (
      workspace.settings.requireAdminApproval ? "Admin" : "Editor"
    ) : "None";
  var arg$11 = AnalyticsUtils.roleToAnalyticsRole(role);
  var arg$12 = mergeWithoutApproval ? "MergeWithoutApproval" : "None";
  var arg$13 = schemaBundle.branchId;
  var arg$14 = schemaBundle.schemaId;
  var arg$15 = schemaBundle.branchId;
  var arg$16 = schemaBundle.schemaId;
  var partialBranchMergedAnalytics = function (param) {
    return AnalyticsRe.branchMerged(partial_arg$10, partial_arg$9, partial_arg$8, partial_arg$7, partial_arg$6, partial_arg$5, partial_arg$4, partial_arg$3, partial_arg$2, partial_arg$1, partial_arg, numUpdatedEventsIncludingNew, numNewEvents, param, arg, arg$1, numNewProperties, numUpdatedPropertiesIncludingNew, arg$2, arg$3, arg$4, arg$5, totalEventsCounter, completedEventsCounter, totalSourcesCounter, completedSourcesCounter, proportionOfUpdatedEventInstancesImplementedOnBranch, proportionOfUpdatedSourcesImplementedOnBranch, numSourcesUpdatedOnBranchConnectedToAvoFunctions, numSourcesUpdatedOnBranchConnectedToInspector, numSourcesUpdatedOnBranchConnectedToImplementationStatus, eventInstancesImplementedRatioPerSource, arg$6, arg$7, arg$8, arg$9, numApprovals, arg$10, arg$11, arg$12, numNewSchemaIssuesOnBranch, numSchemaIssues, numSchemaIssuesDelta, arg$13, arg$14, arg$15, numUpdatedEventsIncludingNew, numUpdatedPropertiesIncludingNew, arg$16, workspaceNumIssues);
  };
  $$Promise.$$catch(FirebaseFetchers.fetchAllCommentActionsOnBranch(schemaId, branchId).then(function (comments) {
            partialBranchMergedAnalytics(Belt_Array.concatMany(Belt_Array.map(comments, (function (prim) {
                            return prim.docs;
                          }))).length);
            return Promise.resolve(undefined);
          }), (function (error) {
          console.log(error);
          partialBranchMergedAnalytics(-1);
          return Promise.resolve(undefined);
        }));
  
}

function handleMerge(actionDiff, actions, addToast, adminMerge, appVersions, branchAuditWarningCount, branchId, branchModel, branchModelWithPulledMaster, branchName, breakingChanges, discrepancyConfig, eventComponentsWithNames, globalSend, invocationsPromise, masterModel, masterModelBeforeBranch, maybeSharingLink, numApprovals, role, schemaBundle, sendActions, setLoading, sourceInitStatus, workspace, shouldDeleteSharingLink, validationIntent, isGlobalWorkspace, masterModelLatestAction) {
  var autoPublishIntegrations = IntegrationUtils.toAutoPublish(branchModel, workspace);
  var sourceIdsWithVersionsSet = Belt_SetString.fromArray(Belt_MapString.keysToArray(appVersions));
  var sourceIds = breakingChanges.breakingSources;
  var hasSourcesToConfig = Belt_Array.some(sourceIds, (function (sourceId) {
          return Belt_SetString.has(sourceIdsWithVersionsSet, sourceId);
        }));
  var showVersionValidation = breakingChanges.breakingSources.length !== 0 && hasSourcesToConfig;
  var hasValueChecking = ValidationConfigV2.EventsV2.hasValueChecking(discrepancyConfig.events);
  var match = ValidationConfigV2.EventsV2.getCase(discrepancyConfig.events);
  var hasSimpleNameCasing = typeof match === "object" ? true : false;
  var title = adminMerge ? "Merge branch override" : "Merge branch";
  var message = adminMerge ? "Are you sure you want to merge this branch into the main branch without approval?" : "Ready to merge this branch into the main branch?";
  var setInspectorValidationIfNeeded = function (onDone) {
    var shouldSetInspectorValidation = breakingChanges.breakingSources.length !== 0 && breakingChanges.breakingEvents.length !== 0 && showVersionValidation;
    if (shouldSetInspectorValidation) {
      Curry._1(setLoading, (function (param) {
              return true;
            }));
      Promise.all([
              InspectorVersionValidationIntentHelpers.getIntents(undefined),
              AppVersionsStore.get(undefined, undefined)
            ]).then(function (param) {
            var appVersions = param[1];
            var intents = param[0];
            if (!Belt_MapString.isEmpty(intents) || Belt_Array.some(Belt_MapString.keysToArray(intents), (function (sourceId) {
                      return Belt_SetString.has(Belt_SetString.fromArray(breakingChanges.breakingSources), sourceId);
                    }))) {
              var now = new Date();
              var eventIds = Belt_List.fromArray(breakingChanges.breakingEvents);
              var sourceValidationMap = Belt_MapString.fromArray(Belt_Array.map(breakingChanges.breakingSources, (function (sourceId) {
                          return [
                                  sourceId,
                                  Belt_Option.flatMap(Belt_MapString.get(intents, sourceId), (function (intent) {
                                          var latestVersion = AppVersionsTypes.getLatestVersion(undefined, appVersions, sourceId);
                                          if (intent === "nextVersion") {
                                            if (latestVersion !== undefined && latestVersion.NAME !== "invalid") {
                                              return {
                                                      NAME: "nextVersion",
                                                      VAL: latestVersion.VAL
                                                    };
                                            } else {
                                              return ;
                                            }
                                          } else if (intent === "oneHourAfterMerge") {
                                            return {
                                                    NAME: "oneHourAfterMerge",
                                                    VAL: now
                                                  };
                                          } else if (intent === "currentVersion") {
                                            if (latestVersion !== undefined && latestVersion.NAME !== "invalid") {
                                              return {
                                                      NAME: "currentVersion",
                                                      VAL: latestVersion.VAL
                                                    };
                                            } else {
                                              return ;
                                            }
                                          } else if (intent === "twentyFourHoursAfterMerge") {
                                            return {
                                                    NAME: "twentyFourHoursAfterMerge",
                                                    VAL: now
                                                  };
                                          } else {
                                            return ;
                                          }
                                        }))
                                ];
                        })));
              var action_1 = [
                eventIds,
                sourceValidationMap
              ];
              var action = {
                NAME: "ConfigureInspectorValidation",
                VAL: action_1
              };
              Curry.app(sendActions, [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    (function (param) {
                        var toModel = Actions.reduce(branchModel, action);
                        return Curry._1(onDone, ActionDiff.diff((function (error) {
                                          return Sentry.captureExceptionString("Illegal state in ActionDiff", {
                                                      errorMessage: error,
                                                      origin: "DiffViewActionBox.res"
                                                    });
                                        }), masterModelBeforeBranch, toModel, undefined));
                      }),
                    undefined,
                    [[
                        action,
                        {
                          branchId: branchId,
                          branchQuery: branchId
                        }
                      ]]
                  ]);
            } else {
              Curry._1(onDone, actionDiff);
            }
            return Promise.resolve(undefined);
          });
      return ;
    } else {
      return Curry._1(onDone, actionDiff);
    }
  };
  return Curry._1(globalSend, {
              TAG: /* OpenModal */4,
              _0: {
                NAME: "ComposableModal",
                VAL: [
                  {
                    NAME: "string",
                    VAL: title
                  },
                  {
                    NAME: "component",
                    VAL: React.createElement(BranchActionBox__MergeBranchModalBody.make, {
                          breakingChanges: breakingChanges,
                          message: message,
                          hasSharingLink: Belt_Option.isSome(maybeSharingLink),
                          setShouldDeleteSharingLink: (function (value) {
                              shouldDeleteSharingLink.current = value;
                              
                            }),
                          toggleText: "Delete Public Link",
                          toggleMessage: "A public link for the code changes exists. Do you wish to delete it when merging?",
                          validationIntent: validationIntent
                        })
                  },
                  [
                    {
                      NAME: "secondary",
                      VAL: {
                        label: "Cancel",
                        onClick: (function (closeModal) {
                            shouldDeleteSharingLink.current = false;
                            return Curry._1(closeModal, undefined);
                          })
                      }
                    },
                    {
                      NAME: "primary",
                      VAL: {
                        label: "Merge " + (
                          isGlobalWorkspace ? "and push" : "branch"
                        ),
                        onClick: (function (closeModal) {
                            setInspectorValidationIfNeeded(function (actionDiff) {
                                  var deletePublicLink = shouldDeleteSharingLink.current;
                                  Curry._1(setLoading, (function (param) {
                                          return true;
                                        }));
                                  var mergeActionsAndContext = Belt_List.mapU(ActionUtils.splitActionsIntoByteSizedChunks(undefined, actionDiff), (function (chunk) {
                                          return [
                                                  {
                                                    NAME: "MergeBranch",
                                                    VAL: [
                                                      branchId,
                                                      branchName,
                                                      chunk
                                                    ]
                                                  },
                                                  {
                                                    branchId: branchId,
                                                    branchQuery: branchId
                                                  }
                                                ];
                                        }));
                                  var writeActionsAndContextSequentially = function (actions) {
                                    if (Belt_List.length(actions) <= 5) {
                                      return new Promise((function (resolve, reject) {
                                                    return Curry.app(sendActions, [
                                                                "Master",
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                (function (param) {
                                                                    Curry._1(addToast, {
                                                                          message: "Branch " + branchName + " merged 🎉"
                                                                        });
                                                                    return resolve(Curry._1(setLoading, (function (param) {
                                                                                      return false;
                                                                                    })));
                                                                  }),
                                                                (function (exn) {
                                                                    console.error("Error merging branch", exn);
                                                                    Curry._1(addToast, {
                                                                          message: "Could not merge branch " + branchName,
                                                                          toastType: /* Error */1
                                                                        });
                                                                    Curry._1(setLoading, (function (param) {
                                                                            return false;
                                                                          }));
                                                                    return reject(exn);
                                                                  }),
                                                                Belt_List.toArray(actions)
                                                              ]);
                                                  }));
                                    }
                                    var mainModelAfterBranchMerge = Belt_List.reduceU(actions, masterModel, (function (model, param) {
                                            return Actions.reduce(model, param[0]);
                                          }));
                                    var mainModelAfterBranchMerge_types = mainModelAfterBranchMerge.types;
                                    var mainModelAfterBranchMerge_properties = mainModelAfterBranchMerge.properties;
                                    var mainModelAfterBranchMerge_propertyBundles = mainModelAfterBranchMerge.propertyBundles;
                                    var mainModelAfterBranchMerge_events = mainModelAfterBranchMerge.events;
                                    var mainModelAfterBranchMerge_migrations = mainModelAfterBranchMerge.migrations;
                                    var mainModelAfterBranchMerge_sources = mainModelAfterBranchMerge.sources;
                                    var mainModelAfterBranchMerge_destinations = mainModelAfterBranchMerge.destinations;
                                    var mainModelAfterBranchMerge_groupTypes = mainModelAfterBranchMerge.groupTypes;
                                    var mainModelAfterBranchMerge_goals = mainModelAfterBranchMerge.goals;
                                    var mainModelAfterBranchMerge_metrics = mainModelAfterBranchMerge.metrics;
                                    var mainModelAfterBranchMerge_archive = mainModelAfterBranchMerge.archive;
                                    var mainModelAfterBranchMerge_openBranches = masterModel.openBranches;
                                    var mainModelAfterBranchMerge_branchPointer = mainModelAfterBranchMerge.branchPointer;
                                    var mainModelAfterBranchMerge_rules = mainModelAfterBranchMerge.rules;
                                    var mainModelAfterBranchMerge_integrations = mainModelAfterBranchMerge.integrations;
                                    var mainModelAfterBranchMerge_globalRequirements = mainModelAfterBranchMerge.globalRequirements;
                                    var mainModelAfterBranchMerge$1 = {
                                      types: mainModelAfterBranchMerge_types,
                                      properties: mainModelAfterBranchMerge_properties,
                                      propertyBundles: mainModelAfterBranchMerge_propertyBundles,
                                      events: mainModelAfterBranchMerge_events,
                                      migrations: mainModelAfterBranchMerge_migrations,
                                      sources: mainModelAfterBranchMerge_sources,
                                      destinations: mainModelAfterBranchMerge_destinations,
                                      groupTypes: mainModelAfterBranchMerge_groupTypes,
                                      goals: mainModelAfterBranchMerge_goals,
                                      metrics: mainModelAfterBranchMerge_metrics,
                                      archive: mainModelAfterBranchMerge_archive,
                                      openBranches: mainModelAfterBranchMerge_openBranches,
                                      branchPointer: mainModelAfterBranchMerge_branchPointer,
                                      rules: mainModelAfterBranchMerge_rules,
                                      integrations: mainModelAfterBranchMerge_integrations,
                                      globalRequirements: mainModelAfterBranchMerge_globalRequirements
                                    };
                                    if (masterModelLatestAction !== undefined) {
                                      return $$Promise.$$catch(Actions.snapshot(schemaBundle.schemaId, "master", mainModelAfterBranchMerge$1, Caml_option.valFromOption(masterModelLatestAction)).then(function (param) {
                                                      return new Promise((function (resolve, reject) {
                                                                    return Curry.app(sendActions, [
                                                                                "Master",
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                (function (param) {
                                                                                    Curry._1(addToast, {
                                                                                          message: "Branch " + branchName + " merged 🎉"
                                                                                        });
                                                                                    return resolve(Curry._1(setLoading, (function (param) {
                                                                                                      return false;
                                                                                                    })));
                                                                                  }),
                                                                                (function (exn) {
                                                                                    console.error("Error committing branch merge action after writing branch merge snapshot to main", exn);
                                                                                    Curry._1(addToast, {
                                                                                          message: "Could not complete merging the branch " + branchName + ". Please contact support.",
                                                                                          toastType: /* Error */1,
                                                                                          persist: true
                                                                                        });
                                                                                    Curry._1(setLoading, (function (param) {
                                                                                            return false;
                                                                                          }));
                                                                                    return reject(exn);
                                                                                  }),
                                                                                [[
                                                                                    {
                                                                                      NAME: "MergeBranch",
                                                                                      VAL: [
                                                                                        branchId,
                                                                                        branchName,
                                                                                        /* [] */0
                                                                                      ]
                                                                                    },
                                                                                    {
                                                                                      branchId: branchId,
                                                                                      branchQuery: branchId
                                                                                    }
                                                                                  ]]
                                                                              ]);
                                                                  }));
                                                    }), (function (error) {
                                                    console.error("Error merging branch", error);
                                                    Curry._1(addToast, {
                                                          message: "Could not merge branch " + branchName + " due to its size. Please contact support.",
                                                          toastType: /* Error */1
                                                        });
                                                    Curry._1(setLoading, (function (param) {
                                                            return false;
                                                          }));
                                                    return Promise.reject(error);
                                                  }));
                                    } else {
                                      console.error("Could not merge branch with snapshot, no action on main branch");
                                      Curry._1(addToast, {
                                            message: "Unexpected error occurred while merging branch " + branchName + " due to its size. Please contact support.",
                                            toastType: /* Error */1,
                                            persist: true
                                          });
                                      Curry._1(setLoading, (function (param) {
                                              return false;
                                            }));
                                      return Promise.reject("Could not merge branch with snapshot, no action on main branch");
                                    }
                                  };
                                  var numSchemaIssuesOnBranchWithPulledMain = DiscrepancyUtils.getTotalWarningsCount(hasSimpleNameCasing, hasValueChecking, DiscrepancyUtils.getDiscrepancies(eventComponentsWithNames, discrepancyConfig, branchModelWithPulledMaster).discrepancies);
                                  var numSchemaIssuesOnMain = DiscrepancyUtils.getTotalWarningsCount(hasSimpleNameCasing, hasValueChecking, DiscrepancyUtils.getDiscrepancies(eventComponentsWithNames, discrepancyConfig, masterModel).discrepancies);
                                  $$Promise.$$catch(invocationsPromise.then(function (invocations) {
                                            var sourceUpdates = DiffUtils.getBranchImplementationStatus(actions, branchModel, masterModel, {
                                                  NAME: "Success",
                                                  VAL: invocations
                                                }, sourceInitStatus, branchId);
                                            var completedEventsCounter = Belt_List.length(Belt_List.keep(Belt_List.flatten(Belt_List.mapU(sourceUpdates, (function (param) {
                                                                return param[1];
                                                              }))), (function (x) {
                                                        if (typeof x === "object" && x.NAME === "Updated" && typeof x.VAL[1] !== "number") {
                                                          return true;
                                                        } else {
                                                          return false;
                                                        }
                                                      })));
                                            var totalEventsCounter = Belt_List.length(Belt_List.keep(Belt_List.flatten(Belt_List.mapU(sourceUpdates, (function (param) {
                                                                return param[1];
                                                              }))), (function (x) {
                                                        if (typeof x === "object") {
                                                          return x.NAME !== "Removed";
                                                        } else {
                                                          return true;
                                                        }
                                                      })));
                                            var filteredSources = Belt_List.keepU(sourceUpdates, (function (param) {
                                                    return AvoConfig.supportsInvocations(param[0]);
                                                  }));
                                            var numSourcesRequiringImplementation = Belt_List.length(sourceUpdates);
                                            var numSourcesFullyImplemented = Belt_List.length(Belt_List.keepU(sourceUpdates, (function (param) {
                                                        var events = param[1];
                                                        var totalCompletedEvents = Belt_List.length(Belt_List.keep(events, (function (x) {
                                                                    if (typeof x.VAL[1] === "number") {
                                                                      return false;
                                                                    } else {
                                                                      return true;
                                                                    }
                                                                  })));
                                                        var totalEvents = Belt_List.length(events);
                                                        return totalCompletedEvents === totalEvents;
                                                      })));
                                            var numSourcesConnectedtoAvoFunctions = Belt_List.length(filteredSources);
                                            var numSourcesConnectedToImplementationStatus = numSourcesConnectedtoAvoFunctions + 0 | 0;
                                            var eventInstancesImplementedRatioPerSource = Belt_List.toArray(Belt_List.mapU(sourceUpdates, (function (param) {
                                                        var events = param[1];
                                                        var totalCompletedEvents = Belt_List.length(Belt_List.keep(events, (function (x) {
                                                                    if (typeof x.VAL[1] === "number") {
                                                                      return false;
                                                                    } else {
                                                                      return true;
                                                                    }
                                                                  })));
                                                        var totalEvents = Belt_List.length(events);
                                                        return totalCompletedEvents / totalEvents;
                                                      })));
                                            return $$Promise.$$catch(writeActionsAndContextSequentially(mergeActionsAndContext).then(function (param) {
                                                            Router.Schema.pushSchemaRoute("master", undefined, "dashboard");
                                                            return Promise.resolve(sendBranchMergedAnalytics(workspace.id, branchId, schemaBundle, actions, actionDiff, autoPublishIntegrations, branchModel, completedEventsCounter, totalEventsCounter, numSourcesFullyImplemented, numSourcesRequiringImplementation, numSourcesConnectedtoAvoFunctions, 0, numSourcesConnectedToImplementationStatus, eventInstancesImplementedRatioPerSource, workspace, numApprovals, branchAuditWarningCount, numSchemaIssuesOnBranchWithPulledMain, numSchemaIssuesOnBranchWithPulledMain - numSchemaIssuesOnMain | 0, numSchemaIssuesOnBranchWithPulledMain, role, adminMerge));
                                                          }), (function (error) {
                                                          return Promise.resolve((console.error("Error merging branch", error), undefined));
                                                        }));
                                          }), (function (error) {
                                          console.log("Error fetching invocations for branch merge", error);
                                          return $$Promise.$$catch(writeActionsAndContextSequentially(mergeActionsAndContext).then(function (param) {
                                                          return Promise.resolve(sendBranchMergedAnalytics(workspace.id, branchId, schemaBundle, actions, actionDiff, autoPublishIntegrations, branchModel, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, workspace, numApprovals, branchAuditWarningCount, numSchemaIssuesOnBranchWithPulledMain, numSchemaIssuesOnBranchWithPulledMain - numSchemaIssuesOnMain | 0, numSchemaIssuesOnBranchWithPulledMain, role, adminMerge));
                                                        }), (function (error) {
                                                        return Promise.resolve((console.error("Error merging branch", error), undefined));
                                                      }));
                                        }));
                                  if (maybeSharingLink === undefined) {
                                    return ;
                                  }
                                  if (!deletePublicLink) {
                                    return ;
                                  }
                                  var shareId = maybeSharingLink.shareId;
                                  FirebaseUtils.deletePublicLink(shareId);
                                  Curry._1(addToast, {
                                        message: "Public link deleted"
                                      });
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [[
                                            {
                                              NAME: "DeletePublicBranchImplementation",
                                              VAL: shareId
                                            },
                                            {
                                              branchId: branchId,
                                              branchQuery: branchId
                                            }
                                          ]]
                                      ]);
                                  return AnalyticsRe.branchImplementationShareModalInteraction("PublicShareLinkDeleted", shareId, schemaBundle.branchId, schemaBundle.schemaId);
                                });
                            shouldDeleteSharingLink.current = false;
                            return Curry._1(closeModal, undefined);
                          })
                      }
                    }
                  ],
                  undefined
                ]
              }
            });
}

function handleDelete(addToast, branchId, branchName, globalSend, schemaBundle, sendActions) {
  return Curry._1(globalSend, {
              TAG: /* OpenModal */4,
              _0: {
                NAME: "AreYouSureModal",
                VAL: [
                  "Delete branch",
                  "Are you sure you want to delete this branch?",
                  "Delete",
                  (function (param) {
                      Curry.app(sendActions, [
                            "Master",
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (_branch) {
                                Curry._1(addToast, {
                                      message: "Branch " + branchName + " deleted"
                                    });
                                return AnalyticsRe.branchDeleted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.branchId, schemaBundle.schemaId);
                              }),
                            undefined,
                            [[
                                {
                                  NAME: "CloseBranch",
                                  VAL: [
                                    branchId,
                                    branchName
                                  ]
                                },
                                {
                                  branchId: branchId,
                                  branchQuery: branchId
                                }
                              ]]
                          ]);
                      var directSchemaRoute = Router.getStaticRoute(undefined).schemaRoute;
                      if (directSchemaRoute === undefined) {
                        return ;
                      }
                      if (typeof directSchemaRoute !== "object") {
                        if (directSchemaRoute === "implement" || directSchemaRoute === "metrics" || directSchemaRoute === "events" || directSchemaRoute === "integrations" || directSchemaRoute === "gettingStarted" || directSchemaRoute === "dashboard" || directSchemaRoute === "settings" || directSchemaRoute === "auditLog" || directSchemaRoute === "properties" || directSchemaRoute === "billing" || directSchemaRoute === "propertyGroups") {
                          return Router.Schema.pushSchemaRoute("master", undefined, directSchemaRoute);
                        } else {
                          return ;
                        }
                      }
                      var variant = directSchemaRoute.NAME;
                      if (variant === "domain" || variant === "inspector" || variant === "diff") {
                        return Router.Schema.pushSchemaRoute("master", undefined, directSchemaRoute);
                      }
                      
                    }),
                  (function (param) {
                      
                    })
                ]
              }
            });
}

function handleSetBranchStatus(branchCreatorId, branchId, branchInteractionLocation, branchName, collaborators, newStatus, numApprovals, schemaBundle, sendActions, setLoading, statusBefore, viewerEmail, viewerId, viewerRole, workspace) {
  Curry._1(setLoading, (function (param) {
          return true;
        }));
  var branchStatus = newStatus === "ChangesRequested" ? "ChangesRequested" : (
      newStatus === "Approved" ? "Approved" : (
          newStatus === "Draft" ? "Open" : "ReadyForReview"
        )
    );
  var branchParticipationRole;
  if (branchCreatorId === viewerId) {
    branchParticipationRole = "BranchCreator";
  } else {
    var collaborator = Belt_List.getBy(collaborators, (function (collaborator) {
            return collaborator.id === viewerId;
          }));
    branchParticipationRole = collaborator !== undefined ? (
        Caml_obj.caml_equal(Caml_option.undefined_to_opt(Caml_option.valFromOption(collaborator).isReviewer), true) ? "BranchReviewer" : "Collaborator"
      ) : "None";
  }
  var numBranchReviewers = Belt_List.length(Belt_List.keep(collaborators, (function (c) {
              return Caml_obj.caml_equal(Caml_option.undefined_to_opt(c.isReviewer), true);
            })));
  var tmp;
  if (typeof statusBefore === "number") {
    tmp = "Open";
  } else {
    switch (statusBefore.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          tmp = Pervasives.failwith("Cannot set branch status on closed or merged branch");
          break;
      case /* ReadyForReview */4 :
          tmp = "ReadyForReview";
          break;
      case /* ChangesRequested */5 :
          tmp = "ChangesRequested";
          break;
      case /* Approved */6 :
          tmp = "Approved";
          break;
      default:
        tmp = "Open";
    }
  }
  AnalyticsRe.branchStatusUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, branchStatus, numApprovals, workspace.settings.numRequiredApprovals, workspace.settings.protectedMainBranch ? (
          workspace.settings.requireAdminApproval ? "Admin" : "Editor"
        ) : "None", branchInteractionLocation, numBranchReviewers, tmp, branchParticipationRole, schemaBundle.branchId, schemaBundle.schemaId, schemaBundle.branchId, branchStatus);
  return Curry.app(sendActions, [
              {
                NAME: "Branch",
                VAL: branchId
              },
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              (function (param) {
                  Curry._1(setLoading, (function (param) {
                          return false;
                        }));
                  if (newStatus === "Approved" || newStatus === "ChangesRequested") {
                    Collaborators.getCollaborator(workspace.id, branchId, viewerId).then(function (maybeCollaborator) {
                          if (maybeCollaborator !== undefined) {
                            if (!Belt_Option.getWithDefault(Caml_option.undefined_to_opt(Caml_option.valFromOption(maybeCollaborator).isReviewer), false)) {
                              return Collaborators.updateCollaboratorIsReviewer(schemaBundle.schemaId, branchId, viewerId, viewerId, sendActions, true, (function (param) {
                                            var arg = workspace.settings.numRequiredApprovals;
                                            var arg$1 = workspace.settings.requireAdminApproval ? "Admin" : "Editor";
                                            var arg$2 = Belt_Option.getWithDefault((viewerEmail == null) ? undefined : Caml_option.some(viewerEmail), "");
                                            var arg$3 = schemaBundle.schemaId;
                                            return AnalyticsUtils.withSchemaBundle((function (param) {
                                                          var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
                                                            return AnalyticsRe.branchReviewerAdded(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21);
                                                          };
                                                          return function (param) {
                                                            var func$1 = Curry._1(func, param);
                                                            return function (param) {
                                                              var func$2 = Curry._1(func$1, param);
                                                              return function (param) {
                                                                var func$3 = Curry._1(func$2, param);
                                                                return function (param) {
                                                                  var func$4 = Curry._1(func$3, param);
                                                                  return function (param) {
                                                                    var func$5 = Curry._1(func$4, param);
                                                                    return function (param) {
                                                                      var func$6 = Curry._1(func$5, param);
                                                                      return function (param) {
                                                                        var func$7 = Curry._1(func$6, param);
                                                                        return function (param) {
                                                                          var func$8 = Curry._1(func$7, param);
                                                                          return function (param) {
                                                                            var func$9 = Curry._1(func$8, param);
                                                                            return function (param) {
                                                                              return Curry.app(func$9, [
                                                                                          param,
                                                                                          branchStatus,
                                                                                          numApprovals,
                                                                                          arg,
                                                                                          arg$1,
                                                                                          branchInteractionLocation,
                                                                                          numBranchReviewers,
                                                                                          viewerRole,
                                                                                          arg$2,
                                                                                          branchId,
                                                                                          branchId,
                                                                                          arg$3
                                                                                        ]);
                                                                            };
                                                                          };
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        }), schemaBundle);
                                          }), (function (param) {
                                            var arg = workspace.settings.numRequiredApprovals;
                                            var arg$1 = workspace.settings.requireAdminApproval ? "Admin" : "Editor";
                                            var arg$2 = Belt_Option.getWithDefault((viewerEmail == null) ? undefined : Caml_option.some(viewerEmail), "");
                                            var arg$3 = schemaBundle.schemaId;
                                            return AnalyticsUtils.withSchemaBundle((function (param) {
                                                          var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
                                                            return AnalyticsRe.branchReviewerRemoved(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21);
                                                          };
                                                          return function (param) {
                                                            var func$1 = Curry._1(func, param);
                                                            return function (param) {
                                                              var func$2 = Curry._1(func$1, param);
                                                              return function (param) {
                                                                var func$3 = Curry._1(func$2, param);
                                                                return function (param) {
                                                                  var func$4 = Curry._1(func$3, param);
                                                                  return function (param) {
                                                                    var func$5 = Curry._1(func$4, param);
                                                                    return function (param) {
                                                                      var func$6 = Curry._1(func$5, param);
                                                                      return function (param) {
                                                                        var func$7 = Curry._1(func$6, param);
                                                                        return function (param) {
                                                                          var func$8 = Curry._1(func$7, param);
                                                                          return function (param) {
                                                                            var func$9 = Curry._1(func$8, param);
                                                                            return function (param) {
                                                                              return Curry.app(func$9, [
                                                                                          param,
                                                                                          branchStatus,
                                                                                          numApprovals,
                                                                                          arg,
                                                                                          arg$1,
                                                                                          branchInteractionLocation,
                                                                                          numBranchReviewers,
                                                                                          viewerRole,
                                                                                          arg$2,
                                                                                          branchId,
                                                                                          branchId,
                                                                                          arg$3
                                                                                        ]);
                                                                            };
                                                                          };
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        }), schemaBundle);
                                          }));
                            } else {
                              return ;
                            }
                          } else {
                            return Collaborators.addCollaborator(schemaBundle, viewerId, {
                                        NAME: "Branch",
                                        VAL: branchId
                                      }, "Branch", branchId, (viewerEmail == null) ? undefined : Caml_option.some(viewerEmail), "AddCollaborator", sendActions, true, (function (param) {
                                          var arg = workspace.settings.numRequiredApprovals;
                                          var arg$1 = workspace.settings.requireAdminApproval ? "Admin" : "Editor";
                                          var arg$2 = Belt_Option.getWithDefault((viewerEmail == null) ? undefined : Caml_option.some(viewerEmail), "");
                                          var arg$3 = schemaBundle.schemaId;
                                          return AnalyticsUtils.withSchemaBundle((function (param) {
                                                        var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
                                                          return AnalyticsRe.branchReviewerAdded(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21);
                                                        };
                                                        return function (param) {
                                                          var func$1 = Curry._1(func, param);
                                                          return function (param) {
                                                            var func$2 = Curry._1(func$1, param);
                                                            return function (param) {
                                                              var func$3 = Curry._1(func$2, param);
                                                              return function (param) {
                                                                var func$4 = Curry._1(func$3, param);
                                                                return function (param) {
                                                                  var func$5 = Curry._1(func$4, param);
                                                                  return function (param) {
                                                                    var func$6 = Curry._1(func$5, param);
                                                                    return function (param) {
                                                                      var func$7 = Curry._1(func$6, param);
                                                                      return function (param) {
                                                                        var func$8 = Curry._1(func$7, param);
                                                                        return function (param) {
                                                                          var func$9 = Curry._1(func$8, param);
                                                                          return function (param) {
                                                                            return Curry.app(func$9, [
                                                                                        param,
                                                                                        branchStatus,
                                                                                        numApprovals,
                                                                                        arg,
                                                                                        arg$1,
                                                                                        branchInteractionLocation,
                                                                                        numBranchReviewers,
                                                                                        viewerRole,
                                                                                        arg$2,
                                                                                        branchId,
                                                                                        branchId,
                                                                                        arg$3
                                                                                      ]);
                                                                          };
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        };
                                                      }), schemaBundle);
                                        }), viewerId);
                          }
                        });
                    return ;
                  }
                  
                }),
              undefined,
              [[
                  {
                    NAME: "SetBranchStatus",
                    VAL: [
                      branchId,
                      branchName,
                      newStatus
                    ]
                  },
                  {
                    branchId: branchId,
                    branchQuery: branchId
                  }
                ]]
            ]);
}

export {
  sendBranchMergedAnalytics ,
  handleMerge ,
  handleDelete ,
  handleSetBranchStatus ,
  
}
/* react Not a pure module */
