// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SheetTypes from "./SheetTypes.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(-1)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.height({
                NAME: "subtract",
                VAL: [
                  Css.pct(100),
                  Css.px(40)
                ]
              }),
          tl: {
            hd: Css.borderBottomLeftRadius(Styles.Border.radius),
            tl: {
              hd: Css.borderBottomRightRadius(Styles.Border.radius),
              tl: /* [] */0
            }
          }
        }
      }
    });

function stickyStyles(customWidth, borderColor, customBackgroundColor) {
  return Curry._1(Css.style, {
              hd: Css.zIndex(2),
              tl: {
                hd: Styles.backgroundColorGuarantee(customBackgroundColor),
                tl: {
                  hd: Css.borderRightWidth(Css.px(0)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", borderColor),
                    tl: {
                      hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.left(Css.px(0)),
                        tl: {
                          hd: Css.position("sticky"),
                          tl: {
                            hd: Css.flexShrink(0.0),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.height(Css.pct(100)),
                                tl: {
                                  hd: Css.width(Css.px(customWidth)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(16)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function emptyColumnStyles(customWidth, customBackgroundColor, borderColor) {
  return Curry._1(Css.style, {
              hd: Css.lastChild({
                    hd: Css.borderRight(Css.px(1), "solid", borderColor),
                    tl: {
                      hd: Css.borderBottomRightRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.flexGrow(1.0),
                        tl: {
                          hd: Css.marginRight(Css.px(16)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              tl: {
                hd: Styles.backgroundColorGuarantee(customBackgroundColor),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.borderTop(Css.px(1), "solid", borderColor),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", borderColor),
                      tl: {
                        hd: Css.width(Css.px(customWidth)),
                        tl: {
                          hd: Css.flexShrink(0.0),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Style = {
  rootStyles: rootStyles,
  stickyStyles: stickyStyles,
  emptyColumnStyles: emptyColumnStyles
};

function SheetSectionFooter(Props) {
  var columns = Props.columns;
  var tintsOpt = Props.tints;
  var tints = tintsOpt !== undefined ? tintsOpt : [
      Styles.Color.light07,
      Styles.Color.light04
    ];
  var themeColor = tints[1];
  var accentColor = tints[0];
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: /* [] */0
                        }
                      })
                }, Belt_Array.mapWithIndexU(columns, (function (index, column) {
                        if (index > 0) {
                          if (column.visible) {
                            return React.createElement("div", {
                                        key: "sectionFooter-" + SheetTypes.columnTypeToJs(column.columnType) + "-" + String(index),
                                        className: emptyColumnStyles(column.width, themeColor, accentColor)
                                      });
                          } else {
                            return null;
                          }
                        } else {
                          return React.createElement("div", {
                                      key: "sectionFooter-" + SheetTypes.columnTypeToJs(column.columnType) + "-" + String(index),
                                      className: stickyStyles(column.width, accentColor, themeColor)
                                    });
                        }
                      }))));
}

var make = SheetSectionFooter;

export {
  Style ,
  make ,
  
}
/* rootStyles Not a pure module */
