// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AutofillInput from "./AutofillInput.mjs";

var MetricAutofillInput = AutofillInput.Component({});

function MetricAutofill(Props) {
  var metrics = Props.metrics;
  var selectedMetrics = Props.selectedMetrics;
  var onSelect = Props.onSelect;
  var onCreate = Props.onCreate;
  return React.createElement(MetricAutofillInput.make, {
              onSelect: onSelect,
              onCreate: onCreate,
              placeholder: "+ Add Metric",
              search: (function (query) {
                  var availableMetrics = Belt_List.keep(metrics, (function (metric) {
                          return !Belt_List.has(selectedMetrics, metric.id, (function (a, b) {
                                        return a === b;
                                      }));
                        }));
                  var results = Belt_List.map(Belt_List.fromArray(Fuzzaldrin.filter(Belt_List.toArray(Belt_List.map(availableMetrics, (function (metric) {
                                          return {
                                                  id: metric.id,
                                                  name: metric.name
                                                };
                                        }))), query, {
                                key: "name",
                                maxResults: 5
                              })), (function (metric) {
                          return {
                                  NAME: "Item",
                                  VAL: metric
                                };
                        }));
                  var currentMetrics = Belt_List.keep(metrics, (function (metric) {
                          return Belt_List.has(selectedMetrics, metric.id, (function (prim0, prim1) {
                                        return prim0 === prim1;
                                      }));
                        }));
                  var metricAlreadyAdded = Belt_List.has(currentMetrics, query, (function (metric, query) {
                          return metric.name === query;
                        }));
                  var newMetric = query !== "" && !Belt_List.has(metrics, query, (function (metric, query) {
                          return metric.name === query;
                        }));
                  if (metricAlreadyAdded) {
                    return Belt_List.concat(results, {
                                hd: {
                                  NAME: "Message",
                                  VAL: "Metric already added"
                                },
                                tl: /* [] */0
                              });
                  } else if (newMetric) {
                    return Belt_List.concat(results, {
                                hd: {
                                  NAME: "CreateNew",
                                  VAL: query
                                },
                                tl: /* [] */0
                              });
                  } else {
                    return results;
                  }
                }),
              renderItem: (function (item, isActive) {
                  var variant = item.NAME;
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding(Css.px(15)),
                                    tl: {
                                      hd: Css.backgroundColor(isActive ? Styles.Color.lightBlue : Styles.Color.white),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(Styles.Color.light02),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.color(Styles.Color.light11),
                                            tl: {
                                              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, variant === "CreateNew" ? React.createElement("div", undefined, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.fontWeight(Styles.FontWeight.regular),
                                              tl: {
                                                hd: Css.fontStyle("italic"),
                                                tl: {
                                                  hd: Css.color(Styles.Color.light10),
                                                  tl: {
                                                    hd: Css.fontSize(Css.px(10)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            })
                                      }, "New Metric"), React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.fontSize(Css.px(14)),
                                                tl: {
                                                  hd: Css.paddingTop(Css.px(2)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, item.VAL)) : (
                                variant === "Item" ? React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("inlineBlock"),
                                              tl: /* [] */0
                                            })
                                      }, React.createElement("div", undefined, item.VAL.name)) : React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.fontWeight(Styles.FontWeight.regular),
                                              tl: /* [] */0
                                            })
                                      }, item.VAL)
                              ));
                }),
              refreshKey: [
                metrics,
                selectedMetrics
              ]
            });
}

var make = MetricAutofill;

export {
  MetricAutofillInput ,
  make ,
  
}
/* MetricAutofillInput Not a pure module */
