// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "../Text.mjs";
import * as React from "react";
import * as Mantine from "../Mantine.mjs";
import * as Tooltip from "../Tooltip.mjs";

function RingProgress(Props) {
  var size = Props.size;
  var thickness = Props.thickness;
  var rootColor = Props.rootColor;
  var sections = Props.sections;
  var tooltip = Props.tooltip;
  return React.createElement(Tooltip.make, {
              position: "top",
              title: "",
              tooltipPadding: 8,
              withAskUsAnything: false,
              withDismiss: false,
              withTitle: false,
              children: null
            }, React.createElement(Tooltip.Target.make, {
                  children: React.createElement(Mantine.RingProgress.make, {
                        rootColor: rootColor,
                        sections: sections,
                        size: size,
                        thickness: thickness
                      })
                }), React.createElement(Tooltip.Content.make, {
                  children: React.createElement($$Text.make, {
                        size: "Small",
                        children: tooltip
                      })
                }));
}

var make = RingProgress;

export {
  make ,
  
}
/* Text Not a pure module */
