// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Sentry from "./externals/Sentry.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as App from "firebase/app";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

var Unavailable = /* @__PURE__ */Caml_exceptions.create("TrialRequest.Unavailable");

function useTrialRequest(param) {
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useState(function () {
        return false;
      });
  var setHasRequested = match[1];
  var hasRequested = match[0];
  var hasExistingTrial = FirebaseFetcherHooks.useHasExistingTrial(workspace.id);
  var match$1 = workspace.plan.name;
  var match$2 = workspace.trial;
  var status;
  var exit = 0;
  if (match$2 !== undefined) {
    status = hasRequested ? /* Success */5 : /* Ongoing */1;
  } else if (hasExistingTrial) {
    status = /* Expired */0;
  } else if (hasRequested) {
    status = /* Pending */4;
  } else if (match$1 >= 11) {
    status = /* Unavailable */3;
  } else {
    switch (match$1) {
      case /* Empty */0 :
      case /* Scholarship */3 :
      case /* Growth */4 :
      case /* GrowthV2 */5 :
      case /* Enterprise */6 :
      case /* Starter */7 :
      case /* TeamV2 */8 :
      case /* TeamV2Annual */9 :
          status = /* Unavailable */3;
          break;
      case /* Free */1 :
      case /* FreeV2 */2 :
      case /* FreeV3 */10 :
          exit = 1;
          break;
      
    }
  }
  if (exit === 1) {
    status = match$2 !== undefined || hasRequested || hasExistingTrial ? /* Unavailable */3 : /* Available */2;
  }
  var requestTrial = function (viewerId) {
    if (status === /* Available */2) {
      Curry._1(setHasRequested, (function (param) {
              return true;
            }));
      return $$Promise.$$catch(Firebase.app(undefined).firestore().collection("schemas").doc(workspace.id).collection("trialRequests").doc().set({
                      requesterId: viewerId,
                      createdAt: App.default.firestore.FieldValue.serverTimestamp()
                    }), (function (error) {
                    Curry._1(setHasRequested, (function (param) {
                            return false;
                          }));
                    Sentry.captureException(error, {});
                    return Promise.reject(error);
                  }));
    } else {
      return Promise.reject({
                  RE_EXN_ID: Unavailable,
                  _1: "Can't request trial if it's not available"
                });
    }
  };
  return [
          status,
          requestTrial
        ];
}

export {
  Unavailable ,
  useTrialRequest ,
  
}
/* react Not a pure module */
