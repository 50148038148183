// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

var context = React.createContext(undefined);

var provider = context.Provider;

function EventsSheet__EventNameComponentsContext(Props) {
  var eventNameComponents = Props.eventNameComponents;
  var children = Props.children;
  return React.createElement(provider, {
              value: eventNameComponents,
              children: children
            });
}

function use(param) {
  return React.useContext(context);
}

function useEventName(eventName) {
  var eventNameComponents = React.useContext(context);
  return React.useMemo((function () {
                return Belt_MapString.get(eventNameComponents, eventName);
              }), [eventNameComponents]);
}

var make = EventsSheet__EventNameComponentsContext;

export {
  context ,
  provider ,
  make ,
  use ,
  useEventName ,
  
}
/* context Not a pure module */
