// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ModelUtils_mapped from "../../../app/src/ModelUtils_mapped.mjs";
import * as TrackingPlanMappedModel from "../TrackingPlanMappedModel.mjs";
import * as GetEventIdsSendingPropertyForEventSpecificValuesUseCase from "./GetEventIdsSendingPropertyForEventSpecificValuesUseCase.mjs";

function compute(properties, sourceIds, property, getEventIdsSendingPropertyAsPropertyRef) {
  var propertyValuesAllEventsKey = GetEventIdsSendingPropertyForEventSpecificValuesUseCase.get(properties, property, getEventIdsSendingPropertyAsPropertyRef);
  var eventIdsSendingPropertyForEventSpecificValues;
  switch (propertyValuesAllEventsKey.TAG | 0) {
    case /* SystemProperty */0 :
        eventIdsSendingPropertyForEventSpecificValues = [propertyValuesAllEventsKey._0];
        break;
    case /* SystemAndNotSystemProperty */1 :
    case /* NotSystemProperty */2 :
        eventIdsSendingPropertyForEventSpecificValues = propertyValuesAllEventsKey._0;
        break;
    
  }
  var eventSpecificAllowedPropertyValues = property.type_ !== "string" ? [] : property.eventSpecificAllowedPropertyValues;
  return Belt_Array.reduce(eventSpecificAllowedPropertyValues, [], (function (accAllowedValues, param) {
                var propertyValueDisallowedEventSources = param[1];
                var literal = param[0];
                var litString = typeof literal === "object" && literal.NAME === "StringLit" ? literal.VAL : Pervasives.failwith("Only string literals are supported");
                var areAllSendingEventIdsDisallowedOnSource = Belt_Array.everyU(eventIdsSendingPropertyForEventSpecificValues, (function (eventId) {
                        var disallowedConfig = Belt_MapString.getWithDefault(propertyValueDisallowedEventSources, eventId, /* DisallowedSources */{
                              _0: undefined
                            });
                        return Belt_Array.everyU(sourceIds, (function (sourceId) {
                                      if (disallowedConfig) {
                                        return Belt_SetString.has(disallowedConfig._0, sourceId);
                                      } else {
                                        return true;
                                      }
                                    }));
                      }));
                if (areAllSendingEventIdsDisallowedOnSource) {
                  return accAllowedValues;
                } else {
                  return Belt_Array.concat(accAllowedValues, [litString]);
                }
              }));
}

function computeWithMappedModel(model, sourceIds, property) {
  return compute(Curry._1(TrackingPlanMappedModel.Properties.toArray, model.properties), sourceIds, property, (function (propertyId) {
                return Curry._1(TrackingPlanMappedModel.Events.keys, ModelUtils_mapped.eventsSendingPropertyFromSources(model.events, model.propertyBundles, sourceIds, propertyId));
              }));
}

function computeWithSlowModel(model, sourceIds, property) {
  return compute(Belt_Array.keepMap(Belt_List.toArray(model.properties), (function (param) {
                    return ModelUtils.resolveProperty(model, param);
                  })), sourceIds, property, (function (propertyId) {
                return Belt_Array.mapU(Belt_List.toArray(ModelUtils.eventsSendingPropertyFromSources(model, Belt_List.fromArray(sourceIds), propertyId)), (function ($$event) {
                              return $$event.id;
                            }));
              }));
}

export {
  computeWithMappedModel ,
  computeWithSlowModel ,
  
}
/* ModelUtils Not a pure module */
