// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "./Modal.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import Shortid from "shortid";
import * as DocsLink from "./DocsLink.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as TextInput from "./TextInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("stretch"),
          tl: {
            hd: Css.paddingTop(Css.px(20)),
            tl: {
              hd: Css.paddingRight(Css.px(16)),
              tl: {
                hd: Css.paddingBottom(Css.px(20)),
                tl: {
                  hd: Css.paddingLeft(Css.px(16)),
                  tl: {
                    hd: Css.minWidth(Css.px(480)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.fontSize(Css.px(11)),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.lineHeight(Css.pct(140.0)),
            tl: {
              hd: Css.color(Styles.Color.light10),
              tl: {
                hd: Css.marginBottom(Css.px(4)),
                tl: {
                  hd: Css.marginLeft(Css.px(8)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var infoStyles = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(400)),
      tl: {
        hd: Css.marginTop(Css.px(16)),
        tl: {
          hd: Css.marginBottom(Css.px(4)),
          tl: {
            hd: Css.marginLeft(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var footer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.padding(Css.px(12)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function CreateCategoryModal(Props) {
  var actionsOnCreate = Props.actionsOnCreate;
  var categories = Props.categories;
  var categoryName = Props.categoryName;
  var onClose = Props.onClose;
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        return Belt_Option.getWithDefault(categoryName, "");
      });
  var setName = match[1];
  var name = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var isLoading = match$1[0];
  var maybeConflictingCategory = Belt_List.getBy(categories, (function (category) {
          return Case.camel(category.name.trim()) === Case.camel(name.trim());
        }));
  var schemaBundle = SchemaBundleContext.use(undefined);
  var handleCreate = function (param) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    var id = Shortid();
    var context = {
      goalId: id,
      goalQuery: id
    };
    var actions = [
      [
        {
          NAME: "AddGoal",
          VAL: id
        },
        context
      ],
      [
        {
          NAME: "UpdateGoalName",
          VAL: [
            id,
            name
          ]
        },
        context
      ]
    ];
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
              Router.Schema.pushDrawerItem(undefined, undefined, {
                    NAME: "category",
                    VAL: [
                      id,
                      undefined
                    ]
                  });
              return AnalyticsRe.categoryCreated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, id, name, NamedBranch.getId(branch), schemaBundle$1.schemaId);
            }),
          undefined,
          Belt_Option.mapWithDefault(actionsOnCreate, actions, (function (actionsOnCreate) {
                  return Belt_Array.concat(actions, Curry._2(actionsOnCreate, id, name));
                }))
        ]);
    Curry._1(setLoading, (function (param) {
            return false;
          }));
    return Curry._1(onClose, undefined);
  };
  return React.createElement(Modal.make, {
              title: "New Category",
              onClose: onClose,
              footer: React.createElement("footer", {
                    className: footer
                  }, React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            return Curry._1(onClose, undefined);
                          }),
                        size: "Small",
                        disabled: isLoading,
                        children: "Cancel"
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.px(12)),
                              tl: /* [] */0
                            })
                      }), React.createElement(Button.make, {
                        disabled: name === "" || Belt_Option.isSome(maybeConflictingCategory) || isLoading,
                        label: isLoading ? "Creating..." : "Create",
                        onClick: handleCreate
                      })),
              children: React.createElement("div", {
                    className: content
                  }, React.createElement("label", {
                        className: label,
                        htmlFor: "name"
                      }, "Name"), React.createElement(TextInput.make, {
                        id: "name",
                        onChange: (function (value) {
                            return Curry._1(setName, (function (param) {
                                          return value;
                                        }));
                          }),
                        value: name
                      }), maybeConflictingCategory !== undefined ? React.createElement("div", {
                          className: infoStyles
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.red,
                              children: "Category with the name \"" + maybeConflictingCategory.name + "\" already exists."
                            })) : null, React.createElement("div", {
                        className: infoStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light10,
                            children: null
                          }, React.createElement($$Text.make, {
                                element: "Span",
                                weight: "Semi",
                                children: "Categories"
                              }), " are a way to create a organized structure for events and metrics. It is useful to create categories for important features and/or important flows in the product.")), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.marginLeft(Css.px(8)),
                              tl: /* [] */0
                            })
                      }, React.createElement(DocsLink.make, {
                            path: "best-practices/organizing-metrics-and-events#a-namecategoriesa-categories"
                          })))
            });
}

var make = CreateCategoryModal;

export {
  content ,
  label ,
  infoStyles ,
  footer ,
  make ,
  
}
/* content Not a pure module */
