// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Actions from "./actions.mjs";
import * as IconList from "./IconList.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as PortalMenu from "./PortalMenu.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconListAdd from "./IconListAdd.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SidebarBranchPopup from "./SidebarBranchPopup.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";

function branchStatusStyles(customColor) {
  return Curry._1(Css.style, {
              hd: Css.backgroundColor(Styles.Color.setAlpha(customColor, 0.1)),
              tl: {
                hd: Css.paddingTop(Css.px(5)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(5)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(8)),
                      tl: {
                        hd: Css.borderRadius(Css.px(4)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var branchNameContainerStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.padding(Css.px(8)),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.width(Css.pct(100.0)),
                    tl: {
                      hd: Css.marginRight(Css.px(-1)),
                      tl: {
                        hd: Css.selector(" path, p", {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "all"),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.hover({
                                hd: Css.selector(" path, p", {
                                      hd: Css_Legacy_Core.SVG.fill(Styles.Color.light04),
                                      tl: {
                                        hd: Css.color(Styles.Color.light04),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function linkStyles(statusColor) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.marginTop(Css.px(-2)),
                    tl: {
                      hd: Css.marginRight(Css.px(6)),
                      tl: {
                        hd: Css.marginBottom(Css.px(6)),
                        tl: {
                          hd: Css.marginLeft(Css.px(6)),
                          tl: {
                            hd: Css.color(Styles.Color.light08),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$short
                                  }, undefined, undefined, "all"),
                              tl: {
                                hd: Css.active({
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.focus({
                                        hd: Css.outlineStyle("none"),
                                        tl: {
                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.setAlpha(statusColor, 0.5))),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.color(Styles.Color.light04),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function SidebarBranchControl(Props) {
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var globalSend = Props.globalSend;
  var role = Props.role;
  var branchStatus = Props.branchStatus;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var buttonRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setShowBranchPopup = match[1];
  var openBranch = Belt_List.getByU(openBranches, (function (param) {
          return param[0] === Actions.branchToId(currentBranch);
        }));
  var branchOpenAction = FirebaseFetcherHooks.useBranchOpenAction(workspace.id, Actions.branchToId(currentBranch), "All", undefined, undefined);
  var match$1 = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  var approvalWorkflow = match$1 === "AvailableDuringTrial" || match$1 === "Available";
  var branchStatus$1;
  var exit = 0;
  if (approvalWorkflow) {
    if (typeof branchStatus === "number") {
      branchStatus$1 = branchStatus;
    } else {
      switch (branchStatus.TAG | 0) {
        case /* Draft */3 :
        case /* ReadyForReview */4 :
        case /* ChangesRequested */5 :
            branchStatus$1 = branchStatus;
            break;
        case /* Approved */6 :
            var approvals = branchStatus._0;
            branchStatus$1 = Belt_List.length(approvals) < workspace.settings.numRequiredApprovals ? ({
                  TAG: /* ReadyForReview */4,
                  _0: Belt_List.headExn(approvals)
                }) : branchStatus;
            break;
        default:
          branchStatus$1 = branchStatus;
      }
    }
  } else if (typeof branchStatus === "number") {
    branchStatus$1 = branchStatus;
  } else {
    switch (branchStatus.TAG | 0) {
      case /* Draft */3 :
      case /* ReadyForReview */4 :
      case /* ChangesRequested */5 :
      case /* Approved */6 :
          exit = 1;
          break;
      default:
        branchStatus$1 = branchStatus;
    }
  }
  if (exit === 1) {
    branchStatus$1 = Belt_Option.mapWithDefault(branchOpenAction, /* Loading */1, (function (openAction) {
            return {
                    TAG: /* Open */0,
                    _0: openAction
                  };
          }));
  }
  var getBranchName = function (branchId, name) {
    if (GlobalRequirementsUtils.isGlobalBranch(branchId)) {
      return GlobalRequirementsUtils.getNormalizedName(branchId);
    } else {
      return name;
    }
  };
  var currentBranchName;
  if (currentBranch === "Master") {
    currentBranchName = "main";
  } else if (openBranch !== undefined) {
    currentBranchName = getBranchName(Actions.branchToId(currentBranch), openBranch[1]);
  } else if (branchOpenAction !== undefined) {
    var match$2 = Caml_option.valFromOption(branchOpenAction).contents;
    currentBranchName = typeof match$2 === "object" && match$2.NAME === "OpenBranch" ? getBranchName(Actions.branchToId(currentBranch), match$2.VAL[1]) : getBranchName(Actions.branchToId(currentBranch), Actions.branchToId(currentBranch));
  } else {
    currentBranchName = "-";
  }
  var statusColor;
  if (typeof branchStatus$1 === "number") {
    statusColor = Styles.Color.light07;
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Open */0 :
          statusColor = Styles.Color.light08;
          break;
      case /* Merged */1 :
          statusColor = Styles.Color.blue;
          break;
      case /* Closed */2 :
          statusColor = Styles.Color.red;
          break;
      case /* Draft */3 :
          statusColor = Styles.Color.light04;
          break;
      case /* ReadyForReview */4 :
          statusColor = Styles.Color.purple;
          break;
      case /* ChangesRequested */5 :
          statusColor = Styles.Color.orange;
          break;
      case /* Approved */6 :
          statusColor = Styles.Color.green;
          break;
      
    }
  }
  var tmp;
  if (currentBranch === "Master") {
    tmp = null;
  } else {
    var tmp$1;
    if (typeof branchStatus$1 === "number") {
      tmp$1 = branchStatus$1 === /* Master */0 ? "" : "LOADING...";
    } else {
      switch (branchStatus$1.TAG | 0) {
        case /* Open */0 :
            tmp$1 = approvalWorkflow ? "DRAFT" : "OPEN";
            break;
        case /* Merged */1 :
            tmp$1 = "MERGED";
            break;
        case /* Closed */2 :
            tmp$1 = "DELETED";
            break;
        case /* Draft */3 :
            tmp$1 = "DRAFT";
            break;
        case /* ReadyForReview */4 :
            tmp$1 = "READY FOR REVIEW";
            break;
        case /* ChangesRequested */5 :
            tmp$1 = "CHANGES REQUESTED";
            break;
        case /* Approved */6 :
            tmp$1 = "APPROVED";
            break;
        
      }
    }
    tmp = React.createElement(Link.make, {
          path: Router.Link.getSchemaRouteLink(undefined, {
                NAME: "diff",
                VAL: "index"
              }),
          className: linkStyles(statusColor),
          id: "branch-tour-review",
          children: null
        }, React.createElement("span", {
              className: branchStatusStyles(statusColor)
            }, React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Semi",
                  color: statusColor,
                  children: tmp$1
                })), React.createElement(Spacer.make, {
              width: 8
            }), React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              children: "View changes"
            }));
  }
  return React.createElement("div", {
              id: "product-tour-branches"
            }, React.createElement("button", {
                  ref: buttonRef,
                  className: branchNameContainerStyle,
                  id: "sidebar-tour-branches",
                  onClick: (function (param) {
                      return Curry._1(setShowBranchPopup, (function (state) {
                                    return !state;
                                  }));
                    })
                }, React.createElement(Icon.make, {
                      type_: "branch",
                      size: {
                        NAME: "int",
                        VAL: 12
                      },
                      color: Styles.Color.light08
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      singleLine: true,
                      color: Styles.Color.light08,
                      children: currentBranchName
                    }), React.createElement(Spacer.make, {
                      width: 4,
                      grow: 1.0
                    }), RoleUtils.canEdit(role) && currentBranch === "Master" ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement(IconListAdd.make, {
                            color: Styles.Color.light08,
                            hoverColor: Styles.Color.white
                          })) : React.createElement(IconList.make, {
                        color: Styles.Color.light08,
                        hoverColor: Styles.Color.white
                      })), React.createElement(PortalMenu.make, {
                  position: "Right",
                  placement: "Start",
                  offset: {
                    top: -8
                  },
                  distance: 4,
                  visible: match[0],
                  onClose: (function (param) {
                      return Curry._1(setShowBranchPopup, (function (param) {
                                    return false;
                                  }));
                    }),
                  referenceItemRef: buttonRef,
                  children: React.createElement(SidebarBranchPopup.make, {
                        currentBranch: currentBranch,
                        openBranches: openBranches,
                        onClose: (function (param) {
                            return Curry._1(setShowBranchPopup, (function (param) {
                                          return false;
                                        }));
                          }),
                        globalSend: globalSend,
                        role: role
                      })
                }), tmp);
}

var make = SidebarBranchControl;

export {
  make ,
  
}
/* branchNameContainerStyle Not a pure module */
