// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var rootStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
            tl: /* [] */0
          }
        }
      }
    });

var contentStyles = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(12)),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: /* [] */0
        }
      }
    });

var buttonBoxStyles = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: /* [] */0
    });

function ListLimitsInfo(Props) {
  var title = Props.title;
  var description = Props.description;
  var globalSend = Props.globalSend;
  var paywallName = Props.paywallName;
  var handleButtonClick = function (param) {
    return Curry._1(globalSend, {
                TAG: /* OpenModal */4,
                _0: {
                  NAME: "BillingPrompt",
                  VAL: paywallName
                }
              });
  };
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: contentStyles
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light10,
                      children: title
                    }), Belt_Option.mapWithDefault(description, null, (function (description) {
                        return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                        height: 2
                                      }), React.createElement($$Text.make, {
                                        size: "Small",
                                        color: Styles.Color.light10,
                                        children: description
                                      }));
                      }))), React.createElement("div", {
                  className: buttonBoxStyles
                }, React.createElement(Button.make, {
                      label: "Learn More",
                      onClick: handleButtonClick
                    })));
}

var make = ListLimitsInfo;

export {
  rootStyles ,
  contentStyles ,
  buttonBoxStyles ,
  make ,
  
}
/* rootStyles Not a pure module */
