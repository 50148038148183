// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as LoggedOut from "./LoggedOut.mjs";
import IsMobile from "is-mobile";
import * as FetchModel from "./FetchModel.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactHelmet from "react-helmet";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as SandboxEndpoint from "../../shared/endpoints/SandboxEndpoint.mjs";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as AuthenticationMethods from "./AuthenticationMethods.mjs";

function DemoSandbox$NarrowScreen(Props) {
  var onContinue = Props.onContinue;
  React.useEffect((function () {
          AnalyticsRe.sandboxModalSeenWithoutWorkspace("TinyScreenAlert", SandboxBanner.isAnonymousUser(undefined) ? "Anonymous" : "Authenticated");
          
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.width(Css.vw(100.0)),
                          tl: {
                            hd: Css.minHeight(Css.vh(100.0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(ReactHelmet.Helmet, {
                  children: React.createElement("meta", {
                        content: "width=device-width, initial-scale=1.0",
                        name: "viewport"
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.alignItems("stretch"),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: {
                                hd: Css.maxWidth(Css.px(460)),
                                tl: {
                                  hd: Css.padding(Css.px(40)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(Title.make, {
                      children: "Narrow screen alert!",
                      size: "Large"
                    }), React.createElement(Spacer.make, {
                      height: 32
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light11,
                      children: "The Avo sandbox is best experienced with a bit more elbow room. We suggest opening it in a bigger window."
                    }), React.createElement(Spacer.make, {
                      height: 24
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light11,
                      children: "If that’s not an option for you right now, you can watch a demo video, or keep going (but don’t say we didn’t warn you)."
                    }), React.createElement(Spacer.make, {
                      height: 32
                    }), React.createElement(Button.make, {
                      label: "Watch Avo demo",
                      onClick: (function (param) {
                          AnalyticsRe.sandboxInteractionWithoutWorkspace("WatchAvoDemo", "TinyScreenAlert", SandboxBanner.isAnonymousUser(undefined) ? "Anonymous" : "Authenticated");
                          window.location.href = "https://www.avo.app/tour";
                          
                        }),
                      size: "large"
                    }), React.createElement(Spacer.make, {
                      height: 20
                    }), React.createElement(Button.make, {
                      label: "Continue to sandbox",
                      onClick: (function (param) {
                          AnalyticsRe.sandboxInteractionWithoutWorkspace("ContinueAnyway", "TinyScreenAlert", SandboxBanner.isAnonymousUser(undefined) ? "Anonymous" : "Authenticated");
                          return Curry._1(onContinue, undefined);
                        }),
                      size: "large",
                      style: "outline"
                    })));
}

var CreateSandboxFailed = /* @__PURE__ */Caml_exceptions.create("DemoSandbox.CreateSandboxFailed");

var SignInAsAnonymousFailed = /* @__PURE__ */Caml_exceptions.create("DemoSandbox.SignInAsAnonymousFailed");

function DemoSandbox(Props) {
  var authState = Props.authState;
  var match = React.useState(function () {
        if (IsMobile()) {
          return /* ConfirmMobile */0;
        } else {
          return {
                  TAG: /* Building */0,
                  _0: "Initializing sandbox environment..."
                };
        }
      });
  var setStatus = match[1];
  var status = match[0];
  var createSandbox = function (param) {
    $$Promise.$$catch($$Promise.$$catch(typeof authState === "object" ? Promise.resolve(undefined) : (Curry._1(setStatus, (function (param) {
                            return {
                                    TAG: /* Building */0,
                                    _0: "Creating sandbox user..."
                                  };
                          })), AuthenticationMethods.signInAsAnonymous(Firebase.getAuth(undefined))), (function (error) {
                    return Promise.reject({
                                RE_EXN_ID: SignInAsAnonymousFailed,
                                _1: LoggedOut.processFirebaseAuthSigninError("", error)
                              });
                  })).then(function (param) {
                Curry._1(setStatus, (function (param) {
                        return {
                                TAG: /* Building */0,
                                _0: "Building sandbox..."
                              };
                      }));
                return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.getAuth(undefined).currentUser)).getIdToken().then(function (token) {
                                return fetch(Firebase.apiUrl + "/sandbox", Fetch.RequestInit.make(/* Post */2, [
                                                  [
                                                    "Accept",
                                                    "application/json"
                                                  ],
                                                  [
                                                    "Content-Type",
                                                    "application/json"
                                                  ],
                                                  [
                                                    "Authorization",
                                                    "Bearer " + token
                                                  ]
                                                ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                              }).then(function (prim) {
                              return prim.json();
                            }).then(function (json) {
                            var match = SandboxEndpoint.$$Response.decode(json);
                            if (!match.ok) {
                              return Promise.reject({
                                          RE_EXN_ID: CreateSandboxFailed
                                        });
                            }
                            var schemaId = match.schemaId;
                            if (schemaId !== undefined) {
                              return Promise.resolve(schemaId);
                            } else {
                              return Promise.reject({
                                          RE_EXN_ID: CreateSandboxFailed
                                        });
                            }
                          });
              }).then(function (schemaId) {
              return Router.push(undefined, "/schemas/" + schemaId + "/getting-started");
            }), (function (error) {
            Curry._1(setStatus, (function (param) {
                    return {
                            TAG: /* Error */1,
                            _0: error.RE_EXN_ID === SignInAsAnonymousFailed ? error._1 : "There was an unexpected error creating your sandbox. Please try again."
                          };
                  }));
            return Promise.resolve(undefined);
          }));
    
  };
  React.useEffect((function () {
          if (typeof status !== "number" && status.TAG === /* Building */0) {
            createSandbox(undefined);
          }
          
        }), []);
  if (typeof status === "number") {
    return React.createElement(DemoSandbox$NarrowScreen, {
                onContinue: (function (param) {
                    return createSandbox(undefined);
                  })
              });
  } else if (status.TAG === /* Building */0) {
    return React.createElement(LoadingFullscreen.make, {
                message: status._0
              });
  } else {
    return React.createElement(FetchModel.$$Error.make, {
                message: status._0
              });
  }
}

var make = DemoSandbox;

export {
  make ,
  
}
/* Css Not a pure module */
