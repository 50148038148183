// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Icons from "./Icons.mjs";
import * as Input from "./Input.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as SsoChecks from "./SsoChecks.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as QueryString from "query-string";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.mjs";
import * as AuthenticationMethods from "./AuthenticationMethods.mjs";
import * as ContinueWithGoogleButton from "./ContinueWithGoogleButton.mjs";
import Loggedout_confettiSvg from "./svg/loggedout_confetti.svg";

var confetti = Loggedout_confettiSvg;

function processFirebaseAuthSigninError(email, error) {
  var match = AuthenticationMethods.Utils.getAuthenticationErrorFromException(error);
  AnalyticsRe.signInFailed(match[0], email);
  return match[1];
}

function LoggedOut(Props) {
  var forceReloadUser = Props.forceReloadUser;
  var auth = Firebase.getAuth(undefined);
  var baseRoute = Router.useBaseRoute(undefined);
  var maybeInitialEmailQuery = Belt_Option.map(Caml_option.nullable_to_opt(QueryString.parse(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).search).email), (function (prim) {
          return decodeURIComponent(prim);
        }));
  var ssoLoginLink = Belt_Option.isSome(Belt_Option.map(Caml_option.nullable_to_opt(QueryString.parse(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).search).sso), (function (prim) {
              return decodeURIComponent(prim);
            })));
  var match = React.useState(function () {
        return Belt_Option.getWithDefault(maybeInitialEmailQuery, "");
      });
  var setEmail = match[1];
  var email = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setPassword = match$1[1];
  var password = match$1[0];
  var match$2 = React.useState(function () {
        return /* Idle */0;
      });
  var setStatus = match$2[1];
  var status = match$2[0];
  var match$3 = React.useState(function () {
        return [
                "",
                /* NotSso */0
              ];
      });
  var setSsoMode = match$3[1];
  var match$4 = match$3[0];
  var ssoMode = match$4[1];
  var ssoModeEmail = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setShowSsoUnknownEmail = match$5[1];
  var match$6 = React.useState(function () {
        return ssoLoginLink;
      });
  var setOnlySsoLogin = match$6[1];
  var onlySsoLogin = match$6[0];
  var emailRef = React.useRef(null);
  Hooks.useDidUpdate1((function (param) {
          Curry._1(setOnlySsoLogin, (function (onlySsoLogin) {
                  if (onlySsoLogin) {
                    return true;
                  } else {
                    return ssoMode !== /* NotSso */0;
                  }
                }));
          Curry._1(setShowSsoUnknownEmail, (function (param) {
                  return ssoMode === /* NotSso */0;
                }));
          
        }), [ssoMode]);
  React.useEffect((function () {
          if (Belt_Option.isSome(maybeInitialEmailQuery)) {
            SsoChecks.ssoEmailCheck(email).then(function (ssoMode) {
                  Curry._1(setSsoMode, (function (param) {
                          return [
                                  email,
                                  ssoMode
                                ];
                        }));
                  return Promise.resolve(undefined);
                });
          }
          
        }), []);
  var handleSignupViaGoogle = function (param) {
    Curry._1(setStatus, (function (param) {
            return /* Loading */1;
          }));
    $$Promise.$$catch(AuthenticationMethods.signInViaGoogle(auth, email, forceReloadUser).then(function (param) {
              return AuthenticationMethods.Utils.redirectToWelcome(undefined);
            }), (function (error) {
            var errorMessage = processFirebaseAuthSigninError(email, error);
            Curry._1(setStatus, (function (param) {
                    return {
                            TAG: /* Error */0,
                            _0: errorMessage
                          };
                  }));
            return Promise.resolve(undefined);
          }));
    
  };
  var query = Router.getSearch(undefined);
  var handleSubmit = function (ssoMode) {
    if (typeof ssoMode === "number") {
      if (ssoMode !== 0) {
        return handleSignupViaGoogle(undefined);
      } else if (baseRoute === "passwordReset") {
        Curry._1(setStatus, (function (param) {
                return /* Loading */1;
              }));
        $$Promise.$$catch(auth.sendPasswordResetEmail(email).then(function (param) {
                  Curry._1(setStatus, (function (param) {
                          return {
                                  TAG: /* Success */1,
                                  _0: "Reset email sent to " + email
                                };
                        }));
                  AnalyticsRe.passwordResetRequested(email);
                  return Promise.resolve(undefined);
                }), (function (error) {
                var match = AuthenticationMethods.Utils.getAuthenticationErrorFromException(error);
                var message = match[1];
                Curry._1(setStatus, (function (param) {
                        return {
                                TAG: /* Error */0,
                                _0: message
                              };
                      }));
                return Promise.resolve(undefined);
              }));
        return ;
      } else if (baseRoute === "signUp" || baseRoute === "invite") {
        Curry._1(setStatus, (function (param) {
                return /* Loading */1;
              }));
        $$Promise.$$catch(AuthenticationMethods.signUpViaEmailAndPassword(auth, email, password, forceReloadUser), (function (error) {
                var errorMessage = processFirebaseAuthSigninError(email, error);
                Curry._1(setStatus, (function (param) {
                        return {
                                TAG: /* Error */0,
                                _0: errorMessage
                              };
                      }));
                return Promise.resolve(undefined);
              }));
        return ;
      } else {
        Curry._1(setStatus, (function (param) {
                return /* Loading */1;
              }));
        $$Promise.$$catch(AuthenticationMethods.signInViaEmailAndPassword(auth, email, password), (function (error) {
                var errorMessage = processFirebaseAuthSigninError(email, error);
                Curry._1(setStatus, (function (param) {
                        return {
                                TAG: /* Error */0,
                                _0: errorMessage
                              };
                      }));
                return Promise.resolve(undefined);
              }));
        return ;
      }
    } else {
      var authProviderId = ssoMode._0;
      Curry._1(setStatus, (function (param) {
              return /* Loading */1;
            }));
      $$Promise.$$catch(AuthenticationMethods.signInViaSaml(auth, authProviderId, forceReloadUser).then(function (param) {
                return AuthenticationMethods.Utils.redirectToWelcome(undefined);
              }), (function (error) {
              var errorMessage = processFirebaseAuthSigninError(email, error);
              Curry._1(setStatus, (function (param) {
                      return {
                              TAG: /* Error */0,
                              _0: errorMessage
                            };
                    }));
              return Promise.resolve(undefined);
            }));
      return ;
    }
  };
  var tmp;
  var exit = 0;
  if (onlySsoLogin) {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = ssoMode !== /* NotSso */0 ? null : React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.marginTop(Css.px(-4)),
                  tl: /* [] */0
                })
          }, React.createElement("button", {
                className: Curry._1(Css.merge, {
                      hd: Curry._1(Css.style, Styles.button),
                      tl: {
                        hd: Curry._1(Css.style, {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.color(Styles.Color.darkBlue),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.textAlign("right"),
                                    tl: {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$default
                                            }, undefined, undefined, "all"),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.color(Styles.Color.deepBlue),
                                                tl: /* [] */0
                                              }),
                                          tl: {
                                            hd: Css.focus({
                                                  hd: Css.color(Styles.Color.avoPink),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }),
                type: "button",
                onClick: (function ($$event) {
                    $$event.preventDefault();
                    return Router.push(undefined, "/reset" + query);
                  })
              }, "Forgot password?"));
  }
  var tmp$1;
  var exit$1 = 0;
  if (typeof ssoMode === "number" && ssoMode !== 0) {
    tmp$1 = React.createElement(ContinueWithGoogleButton.make, {
          onClick: (function ($$event) {
              $$event.preventDefault();
              return handleSignupViaGoogle(undefined);
            }),
          label: "Sign In with Google SSO",
          backgroundColor: Styles.Color.darkBlue
        });
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp$1 = React.createElement(Button.make, {
          disabled: ssoMode === /* NotSso */0 && onlySsoLogin && !new RegExp("^[a-zA-Z0-9.!#$%&\xe2\x80\x99*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$").test(email),
          label: ssoMode !== /* NotSso */0 || onlySsoLogin ? "Sign In with SSO" : (
              baseRoute === "passwordReset" ? "Reset Password" : (
                  baseRoute === "signUp" || baseRoute === "invite" ? "Sign Up" : "Sign In"
                )
            ),
          loading: status === /* Loading */1,
          size: "large"
        });
  }
  var tmp$2;
  var exit$2 = 0;
  exit$2 = 1;
  if (exit$2 === 1) {
    tmp$2 = ssoMode !== /* NotSso */0 ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.padding2(Css.px(5), Css.px(0)),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: /* [] */0
                  }
                })
          }, "By signing up you agree to our ", React.createElement("a", {
                href: "https://www.avo.app/terms",
                target: "_blank"
              }, "Terms and Conditions"), ", ", React.createElement("a", {
                href: "https://www.avo.app/privacy",
                target: "_blank"
              }, "Privacy Policy"), " and ", React.createElement("a", {
                href: "https://www.avo.app/cookies",
                target: "_blank"
              }, "Cookie Policy"), ".") : null;
  }
  var tmp$3;
  tmp$3 = typeof status === "number" ? null : (
      status.TAG === /* Error */0 ? React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.padding2(Css.px(8), Css.px(10)),
                      tl: {
                        hd: Css.margin2(Css.px(10), Css.px(0)),
                        tl: {
                          hd: Css.lineHeight(Css.pct(120.0)),
                          tl: {
                            hd: Css.color(Styles.Color.red),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.lightRed),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.red),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, status._0) : React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.padding2(Css.px(8), Css.px(10)),
                      tl: {
                        hd: Css.margin2(Css.px(10), Css.px(0)),
                        tl: {
                          hd: Css.lineHeight(Css.pct(120.0)),
                          tl: {
                            hd: Css.color(Styles.Color.green),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.lightGreen),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.green),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, status._0)
    );
  return React.createElement("div", {
              key: baseRoute === "passwordReset" ? "PasswordReset" : (
                  baseRoute === "signUp" ? "SignUp" : "SignIn"
                ),
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.height(Css.vh(100)),
                            tl: {
                              hd: Css.minHeight(Css.px(500)),
                              tl: {
                                hd: Css.padding(Css.px(15)),
                                tl: {
                                  hd: Css.position("relative"),
                                  tl: {
                                    hd: Css.zIndex(0),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(Icons.AvoLogoAInRoundedSquare.make, {
                  width: 76
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin4(Css.px(15), Css.px(0), Css.px(25), Css.px(0)),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.huge),
                            tl: {
                              hd: Css.color(Styles.Color.light11),
                              tl: {
                                hd: Css.textAlign("center"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, baseRoute === "passwordReset" ? "Reset password" : (
                    baseRoute === "invite" ? "Welcome to Avo" : (
                        baseRoute === "signUp" ? "Sign up to Avo" : "Sign in to Avo"
                      )
                  )), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(250)),
                        tl: {
                          hd: Css.maxWidth(Css.pct(100)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginTop(Css.px(15)),
                            tl: /* [] */0
                          })
                    }, onlySsoLogin || !(ssoMode === 0 && baseRoute !== "passwordReset") ? null : React.createElement(ContinueWithGoogleButton.make, {
                            onClick: (function ($$event) {
                                $$event.preventDefault();
                                return handleSignupViaGoogle(undefined);
                              }),
                            backgroundColor: Styles.Color.darkBlue
                          }), onlySsoLogin || !(ssoMode === 0 && baseRoute !== "passwordReset") ? null : React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.height(Css.px(1)),
                                      tl: {
                                        hd: Css.width(Css.pct(100)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.light07),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.margin2(Css.px(10), Css.px(30)),
                                      tl: {
                                        hd: Css.color(Styles.Color.light08),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.flexShrink(0.0),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, "or use email"), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.height(Css.px(1)),
                                      tl: {
                                        hd: Css.width(Css.pct(100)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.light07),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }))), React.createElement("form", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.zIndex(100),
                              tl: /* [] */0
                            }
                          }),
                      id: "form",
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          if (onlySsoLogin && ssoMode === /* NotSso */0) {
                            return Curry._1(setShowSsoUnknownEmail, (function (param) {
                                          return true;
                                        }));
                          } else if (email !== ssoModeEmail) {
                            Curry._1(setStatus, (function (status) {
                                    if (status === 0) {
                                      return /* Loading */1;
                                    } else {
                                      return status;
                                    }
                                  }));
                            $$Promise.$$catch(SsoChecks.ssoEmailCheck(email).then(function (ssoMode) {
                                      Curry._1(setStatus, (function (status) {
                                              if (typeof status === "number" && status !== 0) {
                                                return /* Idle */0;
                                              } else {
                                                return status;
                                              }
                                            }));
                                      handleSubmit(ssoMode);
                                      return Promise.resolve(undefined);
                                    }), (function (param) {
                                    handleSubmit(ssoMode);
                                    return Promise.resolve(undefined);
                                  }));
                            return ;
                          } else {
                            return handleSubmit(ssoMode);
                          }
                        })
                    }, React.createElement("div", undefined, React.createElement(Input.make, {
                              label: "Email",
                              value: email,
                              onChange: (function (email) {
                                  Curry._1(setEmail, (function (param) {
                                          return email;
                                        }));
                                  return SsoChecks.debouncedSsoEmailCheck(email, (function (ssoMode) {
                                                return Curry._1(setSsoMode, (function (param) {
                                                              return [
                                                                      email,
                                                                      ssoMode
                                                                    ];
                                                            }));
                                              }));
                                }),
                              id: "EmailInput",
                              autoFocus: true,
                              data: {
                                "data-cons-subject": "email"
                              },
                              disabled: Belt_Option.isSome(maybeInitialEmailQuery),
                              forwardRef: emailRef
                            }), onlySsoLogin || baseRoute === "passwordReset" || ssoMode !== 0 ? null : React.createElement(Input.make, {
                                label: "Password",
                                value: password,
                                onChange: (function (password) {
                                    return Curry._1(setPassword, (function (param) {
                                                  return password;
                                                }));
                                  }),
                                id: "PasswordInput",
                                autoFocus: Belt_Option.isSome(maybeInitialEmailQuery),
                                data: {
                                  "data-cons-exclude": ""
                                },
                                type_: "password"
                              }), tmp, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding2(Css.px(5), Css.px(0)),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.small),
                                      tl: /* [] */0
                                    }
                                  })
                            }, onlySsoLogin && match$5[0] ? "We couldn't detect SSO for this email" : (
                                typeof ssoMode === "number" ? (
                                    ssoMode !== 0 ? "We detected Google SSO enabled for this email" : null
                                  ) : "We detected SSO enabled for this email"
                              )), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.flexDirection("column"),
                                      tl: {
                                        hd: Css.alignItems("stretch"),
                                        tl: {
                                          hd: Css.marginTop(Css.px(10)),
                                          tl: {
                                            hd: Css.marginBottom(Css.px(10)),
                                            tl: {
                                              hd: Css.zIndex(100),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, tmp$1), tmp$2, tmp$3, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding2(Css.px(10), Css.px(0)),
                                    tl: {
                                      hd: Css.textAlign("center"),
                                      tl: {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.flexDirection("column"),
                                          tl: {
                                            hd: Css.gap(Css.px(4)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement("button", {
                                  className: Curry._1(Css.merge, {
                                        hd: Curry._1(Css.style, Styles.button),
                                        tl: {
                                          hd: Curry._1(Css.style, {
                                                hd: Css.cursor("pointer"),
                                                tl: {
                                                  hd: Css.color(Styles.Color.darkBlue),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.medium),
                                                    tl: {
                                                      hd: Css.transition({
                                                            NAME: "ms",
                                                            VAL: Styles.Duration.$$default
                                                          }, undefined, undefined, "all"),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.color(Styles.Color.deepBlue),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: {
                                                          hd: Css.focus({
                                                                hd: Css.color(Styles.Color.avoPink),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }),
                                  onClick: (function (e) {
                                      e.preventDefault();
                                      if (ssoMode !== /* NotSso */0) {
                                        Curry._1(setEmail, (function (param) {
                                                return "";
                                              }));
                                        Curry._1(setSsoMode, (function (param) {
                                                return [
                                                        "",
                                                        /* NotSso */0
                                                      ];
                                              }));
                                      }
                                      Curry._1(setOnlySsoLogin, (function (state) {
                                              return !state;
                                            }));
                                      return Belt_Option.forEach(Caml_option.nullable_to_opt(emailRef.current), (function (prim) {
                                                    prim.focus();
                                                    
                                                  }));
                                    })
                                }, onlySsoLogin || ssoMode !== /* NotSso */0 ? "Login with email" : "Continue with SSO"), React.createElement("div", undefined, React.createElement("span", undefined, baseRoute === "passwordReset" ? (
                                        ssoMode !== /* NotSso */0 ? "" : "New to avo?"
                                      ) : (
                                        baseRoute === "signUp" || baseRoute === "invite" ? "Already have an account?" : (
                                            ssoMode !== /* NotSso */0 ? "" : "New to avo?"
                                          )
                                      )), React.createElement("button", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.button),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.cursor("pointer"),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.darkBlue),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.medium),
                                                        tl: {
                                                          hd: Css.transition({
                                                                NAME: "ms",
                                                                VAL: Styles.Duration.$$default
                                                              }, undefined, undefined, "all"),
                                                          tl: {
                                                            hd: Css.hover({
                                                                  hd: Css.color(Styles.Color.deepBlue),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: {
                                                              hd: Css.focus({
                                                                    hd: Css.color(Styles.Color.avoPink),
                                                                    tl: /* [] */0
                                                                  }),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onClick: (function ($$event) {
                                          $$event.preventDefault();
                                          if (baseRoute === "signUp" || baseRoute === "invite" || baseRoute === "passwordReset") {
                                            return Router.push(undefined, "/login" + query);
                                          } else {
                                            return Router.push(undefined, "/onboarding");
                                          }
                                        })
                                    }, ssoMode === 0 ? (
                                        baseRoute === "passwordReset" ? "Back to sign in page" : (
                                            baseRoute === "signUp" || baseRoute === "invite" ? "Sign in" : "Create an account"
                                          )
                                      ) : "")))))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.zIndex(-1),
                                  tl: {
                                    hd: Css.pointerEvents("none"),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.justifyContent("center"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("img", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.px(900)),
                            tl: /* [] */0
                          }),
                      src: confetti
                    })));
}

var make = LoggedOut;

export {
  confetti ,
  processFirebaseAuthSigninError ,
  make ,
  
}
/* confetti Not a pure module */
