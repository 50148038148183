// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dopt from "./externals/Dopt.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Styles from "./styles.mjs";

function LearnHub__InspectorIssuesTourButton(Props) {
  var onTourStart = Props.onTourStart;
  var match = Dopt.Flow.use("inspector-issues-onboarding-flow-v2");
  var issuesTourIntent = match[1];
  return React.createElement(Button.make, {
              icon: "presentation",
              label: "Take tour",
              onClick: (function (param) {
                  Curry._1(onTourStart, undefined);
                  Dopt.Flow.reset(true, issuesTourIntent);
                  return Dopt.Flow.start(true, issuesTourIntent);
                }),
              style: {
                NAME: "custom",
                VAL: {
                  customColor: Styles.Color.white,
                  hoverColor: Styles.Color.white,
                  customBackgroundColor: Styles.Color.primaryPurple,
                  customBackgroundHoverColor: Styles.Color.darkPurple,
                  optionalCustomOutlineColor: undefined,
                  optionalCustomOutlineHoverColor: undefined
                }
              },
              minWidth: 132
            });
}

var make = LearnHub__InspectorIssuesTourButton;

export {
  make ,
  
}
/* Dopt Not a pure module */
