// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as IconLogin from "./IconLogin.mjs";
import * as IconMoney from "./IconMoney.mjs";
import * as IconLogout from "./IconLogout.mjs";
import * as IconLogEvent from "./IconLogEvent.mjs";
import * as IconNavigate from "./IconNavigate.mjs";
import * as IconAddMember from "./IconAddMember.mjs";
import * as SideEffectUtils from "./SideEffectUtils.mjs";

function EventTypeIcon(Props) {
  var sideEffect = Props.sideEffect;
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.marginTop(Css.px(2)),
                        tl: /* [] */0
                      }
                    }
                  }),
              title: SideEffectUtils.getSideEffectLabel(sideEffect)
            }, typeof sideEffect === "object" ? React.createElement(IconAddMember.make, {
                    size: size,
                    color: color
                  }) : (
                sideEffect === "Revenue" ? React.createElement(IconMoney.make, {
                        size: size,
                        color: color
                      }) : (
                    sideEffect === "Alias" || sideEffect === "Identify" ? React.createElement(IconLogin.make, {
                            size: size,
                            color: color
                          }) : (
                        sideEffect === "Page" ? React.createElement(IconNavigate.make, {
                                size: size,
                                color: color
                              }) : (
                            sideEffect === "Unidentify" ? React.createElement(IconLogout.make, {
                                    size: size,
                                    color: color
                                  }) : (
                                sideEffect === "LogEvent" ? React.createElement(IconLogEvent.make, {
                                        size: size,
                                        color: color
                                      }) : React.createElement(Icons.UpdateGroup.make, {
                                        size: size * 1.42 | 0,
                                        color: color
                                      })
                              )
                          )
                      )
                  )
              ));
}

var make = EventTypeIcon;

export {
  make ,
  
}
/* Css Not a pure module */
