// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "./styles.mjs";

function IconLock(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  return React.createElement("div", undefined, React.createElement("svg", {
                  width: String(size),
                  viewBox: "0 0 48 48",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", undefined, React.createElement("path", {
                          d: "M17,17v-5c0-3.85986,3.14014-7,7-7s7,3.14014,7,7v5h4v-5c0-6.06543-4.93457-11-11-11S13,5.93457,13,12v5H17z ",
                          fill: Styles.Color.toString(Styles.Color.light07)
                        }), React.createElement("path", {
                          d: "M39,47H9c-1.10457,0-2-0.89543-2-2V19c0-1.10457,0.89543-2,2-2h30c1.10457,0,2,0.89543,2,2v26 C41,46.10457,40.10457,47,39,47z",
                          fill: Styles.Color.toString(Styles.Color.orange)
                        }), React.createElement("path", {
                          d: "M28,29c0-2.20917-1.79083-4-4-4c-2.20911,0-4,1.79083-4,4c0,1.47681,0.80945,2.75232,2,3.44519V39h4 v-6.55481C27.19061,31.75226,28,30.47681,28,29z",
                          fill: Styles.Color.toString(Styles.Color.orange)
                        }))));
}

var make = IconLock;

export {
  make ,
  
}
/* react Not a pure module */
