// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Models from "../Models.mjs";
import * as Select from "../Select.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Switch from "../Switch.mjs";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchSelect from "../SearchSelect.mjs";
import * as App from "firebase/app";
import * as LoadingCircle from "../LoadingCircle.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as AppFeatureFlag from "../AppFeatureFlag.mjs";
import * as SlackAppConfig from "../../../shared/SlackAppConfig.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as IconSlackEmblem from "../IconSlackEmblem.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";

function updateRemindersConfig(schemaId, channelId, remindersEnabled, reminderHour, timeZone) {
  var reminderEventType = Models.NotificationChannel.printEventType(/* WorkspaceReminders */2);
  var channelRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("notificationChannels").doc(channelId);
  return channelRef.set({
              events: remindersEnabled ? App.default.firestore.FieldValue.arrayUnion(reminderEventType) : App.default.firestore.FieldValue.arrayRemove(reminderEventType),
              metadata: {
                reminderHour: reminderHour,
                timeZone: timeZone
              }
            }, {"merge": true});
}

var targetContainerStyles = Curry._1(Css.style, {
      hd: Css.background(Styles.Color.white),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
        tl: {
          hd: Css.position("relative"),
          tl: {
            hd: Css.borderRadius(Css.px(4)),
            tl: {
              hd: Css.hover({
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.focus({
                      hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.overflow("visible"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var targetContentStyles = Curry._1(Css.style, {
      hd: Css.zIndex(0),
      tl: {
        hd: Css.padding("zero"),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: /* [] */0
        }
      }
    });

var targetOpenedStyles = Curry._1(Css.style, {
      hd: Css.outline(Css.px(1), "solid", Styles.Color.light07),
      tl: /* [] */0
    });

var buttonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("row"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.padding2(Css.px(1), Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var chevronStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(4)),
      tl: /* [] */0
    });

function dropdownItemStyles(selected) {
  return Curry._1(Css.style, {
              hd: Css.color(Styles.Color.light10),
              tl: {
                hd: selected ? Css.fontWeight(Styles.FontWeight.semi) : Styles.emptyStyle,
                tl: /* [] */0
              }
            });
}

function ConnectToSlackButton$ConnectedToSlack$TimeZoneSelect(Props) {
  var value = Props.value;
  var onSelect = Props.onSelect;
  var allTimeZones = React.useMemo((function () {
          return Belt_Array.map(window.Intl.supportedValuesOf("timeZone"), (function (timeZone) {
                        var now = new Date();
                        var dateInTimeZone = new Date(now.toLocaleString("en-US", {
                                  timeZone: timeZone
                                }));
                        var localTime = dateInTimeZone.getTime();
                        var utcTime = now.getTime() + now.getTimezoneOffset();
                        var timeZoneOffset = (localTime - utcTime) / 1000 / 60 / 60;
                        var timeZoneOffset$1 = Math.round(timeZoneOffset * 2) / 2;
                        var pad = function (value) {
                          if (value.length === 1) {
                            return "0" + value;
                          } else {
                            return value;
                          }
                        };
                        var sign = timeZoneOffset$1 >= 0.0 ? "+" : "-";
                        var absOffset = Math.abs(timeZoneOffset$1);
                        var hours = pad(String(Js_math.floor(absOffset)));
                        var minutes = pad(String(Js_math.floor(absOffset * 60.0 % 60.0)));
                        var offsetString = sign + hours + ":" + minutes;
                        return [
                                timeZone,
                                timeZone.replace("_", " ") + " (" + offsetString + ")"
                              ];
                      }));
        }), []);
  var buttonRef = React.useRef(null);
  var currentTimeZoneLabel = Belt_Option.mapWithDefault(Belt_Array.getBy(allTimeZones, (function (param) {
              return param[0] === value;
            })), value, (function (prim) {
          return prim[1];
        }));
  return React.createElement(SearchSelect.make, {
              items: Belt_Array.map(allTimeZones, (function (param) {
                      var timeZone = param[0];
                      return {
                              value: timeZone,
                              label: param[1],
                              selected: timeZone === value,
                              valid: true
                            };
                    })),
              maxItemsBeforeOfferingSearch: 0,
              onChange: onSelect,
              targetContainerStyles: targetContainerStyles,
              targetContentStyles: targetContentStyles,
              targetOpenedStyles: targetOpenedStyles,
              dropdownContainerStyles: /* [] */0,
              dropdownItemStyles: dropdownItemStyles,
              children: React.createElement("button", {
                    ref: buttonRef,
                    className: buttonStyles,
                    autoFocus: false
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light11,
                        children: currentTimeZoneLabel
                      }), React.createElement("span", {
                        className: chevronStyles + " allowed-value__chevron-down"
                      }, React.createElement(Spacer.make, {
                            width: 4,
                            grow: 1.0
                          }), React.createElement(Icon.make, {
                            type_: "chevronDown",
                            size: "small",
                            color: Styles.Color.light08
                          })))
            });
}

var TimeZoneSelect = {
  targetContainerStyles: targetContainerStyles,
  targetContentStyles: targetContentStyles,
  targetOpenedStyles: targetOpenedStyles,
  buttonStyles: buttonStyles,
  dropdownContainerStyles: /* [] */0,
  chevronStyles: chevronStyles,
  dropdownItemStyles: dropdownItemStyles,
  make: ConnectToSlackButton$ConnectedToSlack$TimeZoneSelect
};

var RemoveChannelFailed = /* @__PURE__ */Caml_exceptions.create("ConnectToSlackButton.ConnectedToSlack.RemoveChannelFailed");

function removeEventType(schemaId, channel, eventType) {
  var removeEventTypes = typeof eventType === "number" ? (
      eventType !== 0 ? [
          /* WorkspaceNotifications */1,
          /* WorkspaceReminders */2
        ] : [/* InspectorIssues */0]
    ) : [/* SavedView */{
        _0: eventType._0
      }];
  var hasOtherEventTypes = Belt_Array.keep(channel.events, (function (existingEventType) {
          return !Belt_Array.some(removeEventTypes, (function (removeEventType) {
                        return Caml_obj.caml_equal(removeEventType, Models.NotificationChannel.parseEventType(existingEventType));
                      }));
        })).length !== 0;
  if (hasOtherEventTypes) {
    return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("notificationChannels").doc(channel.id).set((function (param) {
                  return {
                          events: App.default.firestore.FieldValue.arrayRemove(Belt_Array.map(removeEventTypes, Models.NotificationChannel.printEventType))
                        };
                }), {"merge": true});
  } else {
    return Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                  return fetch(Firebase.apiUrl + "/disconnectNotificationChannel?schemaId=" + schemaId + "&channelId=" + channel.id, Fetch.RequestInit.make(/* Post */2, [[
                                      "Authorization",
                                      "Bearer " + userToken
                                    ]], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (response) {
                if (response.status === 200) {
                  return Promise.resolve(undefined);
                } else {
                  return Promise.reject({
                              RE_EXN_ID: RemoveChannelFailed
                            });
                }
              });
  }
}

var connectedBoxStyles = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.paddingTop(Css.px(16)),
        tl: {
          hd: Css.paddingRight(Css.px(20)),
          tl: {
            hd: Css.paddingBottom(Css.px(16)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light06),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light01),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var slackConnectionContainerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("flexStart"),
        tl: /* [] */0
      }
    });

function ConnectToSlackButton$ConnectedToSlack(Props) {
  var channel = Props.channel;
  var viewerRole = Props.viewerRole;
  var disconnectWarningMessage = Props.disconnectWarningMessage;
  var eventType = Props.eventType;
  var onDisconnectOpt = Props.onDisconnect;
  var hasInspectorNotifications = Props.hasInspectorNotifications;
  var onDisconnect = onDisconnectOpt !== undefined ? onDisconnectOpt : (function (param) {
        
      });
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useState(function () {
        return /* Initial */0;
      });
  var setState = match[1];
  var handleRemoveEventType = function (param) {
    if (window.confirm(disconnectWarningMessage)) {
      Curry._1(setState, (function (param) {
              return /* Loading */1;
            }));
      $$Promise.$$catch(removeEventType(workspace.id, channel, eventType).then(function (param) {
                Curry._1(onDisconnect, undefined);
                return Promise.resolve(undefined);
              }), (function (error) {
              console.log("Failed to disconnect notification channel", error);
              window.alert("Something went wrong when trying to disconnect");
              Curry._1(setState, (function (param) {
                      return /* Initial */0;
                    }));
              return Promise.reject({
                          RE_EXN_ID: RemoveChannelFailed
                        });
            }));
      return ;
    }
    
  };
  var hasRemindersEnabled = Belt_Array.some(channel.events, (function (eventType) {
          return eventType === Models.NotificationChannel.printEventType(/* WorkspaceReminders */2);
        }));
  var reminderHour = channel.metadata.reminderHour;
  var reminderHour$1 = (reminderHour == null) ? undefined : Caml_option.some(reminderHour);
  var reminderTimeZone = channel.metadata.timeZone;
  var reminderTimeZone$1 = (reminderTimeZone == null) ? undefined : Caml_option.some(reminderTimeZone);
  var currentDateTimeFormatOptions = window.Intl.DateTimeFormat().resolvedOptions();
  var userTimeZone = currentDateTimeFormatOptions.timeZone;
  var allHours = React.useMemo((function () {
          return Belt_Array.map(Belt_Array.mapWithIndex(Belt_Array.make(24, 0), (function (index, _value) {
                            return index;
                          })), (function (value) {
                        var amPm = value >= 12 ? "PM" : "AM";
                        var displayHour = value !== 0 ? (
                            value > 12 ? String(value - 12 | 0) : String(value)
                          ) : "12";
                        var label = displayHour + ":00 " + amPm;
                        return [
                                value,
                                label
                              ];
                      }));
        }), []);
  return React.createElement("div", {
              className: connectedBoxStyles
            }, React.createElement("div", {
                  className: slackConnectionContainerStyles
                }, React.createElement("div", undefined, React.createElement(Spacer.make, {
                          height: 4
                        }), React.createElement(IconSlackEmblem.make, {
                          size: 32
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(16)),
                            tl: {
                              hd: Css.marginRight(Css.px(16)),
                              tl: {
                                hd: Css.maxWidth(Css.px(350)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Connected to Slack"
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light11,
                          children: null
                        }, "You are receiving notifications on ", React.createElement($$Text.make, {
                              element: "Span",
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: channel.metadata.channelName
                            }))), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), viewerRole === "Admin" || viewerRole === "Editor" ? React.createElement(Button.make, {
                        disabled: match[0] === /* Loading */1,
                        label: "Disconnect",
                        onClick: handleRemoveEventType,
                        style: "outline"
                      }) : null), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light06),
                        tl: {
                          hd: Css.marginLeft(Css.px(48)),
                          tl: /* [] */0
                        }
                      })
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(48)),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Scheduled branch reminders"
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement(Switch.make, {
                          value: hasRemindersEnabled,
                          onChange: (function (isToggled) {
                              updateRemindersConfig(workspace.id, channel.id, isToggled, Belt_Option.getWithDefault(reminderHour$1, 9), Belt_Option.getWithDefault(reminderTimeZone$1, userTimeZone)).then(function (param) {
                                    var arg = Belt_Option.getWithDefault(reminderHour$1, 9);
                                    var arg$1 = Belt_Option.getWithDefault(reminderTimeZone$1, userTimeZone);
                                    var arg$2 = isToggled ? "On" : "Off";
                                    var arg$3 = schemaBundle.branchId;
                                    var arg$4 = workspace.id;
                                    var arg$5 = workspace.id;
                                    var arg$6 = Belt_Array.concatMany([
                                          ["WorkspaceNotifications"],
                                          isToggled ? ["WorkspaceReminders"] : [],
                                          hasInspectorNotifications ? ["InspectorNotifications"] : []
                                        ]);
                                    AnalyticsUtils.withSchemaBundle((function (param) {
                                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20) {
                                              return AnalyticsRe.workspaceSettingUpdatedUpdateSlackReminders(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20);
                                            };
                                            return function (param) {
                                              var func$1 = Curry._1(func, param);
                                              return function (param) {
                                                var func$2 = Curry._1(func$1, param);
                                                return function (param) {
                                                  var func$3 = Curry._1(func$2, param);
                                                  return function (param) {
                                                    var func$4 = Curry._1(func$3, param);
                                                    return function (param) {
                                                      var func$5 = Curry._1(func$4, param);
                                                      return function (param) {
                                                        var func$6 = Curry._1(func$5, param);
                                                        return function (param) {
                                                          var func$7 = Curry._1(func$6, param);
                                                          return function (param) {
                                                            var func$8 = Curry._1(func$7, param);
                                                            return function (param) {
                                                              var func$9 = Curry._1(func$8, param);
                                                              return function (param) {
                                                                return Curry.app(func$9, [
                                                                            param,
                                                                            arg,
                                                                            arg$1,
                                                                            arg$2,
                                                                            arg$3,
                                                                            arg$4,
                                                                            arg$5,
                                                                            undefined,
                                                                            arg$6,
                                                                            undefined,
                                                                            undefined
                                                                          ]);
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          }), schemaBundle);
                                    
                                  });
                              
                            }),
                          color: "avoPink",
                          size: "Small"
                        })), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light11,
                      children: "Get daily reminders about branches pending review in Avo"
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginTop(Css.px(12)),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.gap(Css.px(8)),
                                  tl: {
                                    hd: Css.opacity(hasRemindersEnabled ? 1.0 : 0.5),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Regular",
                          color: Styles.Color.light12,
                          children: "Weekdays at"
                        }), React.createElement(Select.make, {
                          disabled: !hasRemindersEnabled,
                          onSelect: (function (nextReminderHour) {
                              updateRemindersConfig(workspace.id, channel.id, hasRemindersEnabled, Belt_Option.getWithDefault(Belt_Int.fromString(nextReminderHour), 9), Belt_Option.getWithDefault(reminderTimeZone$1, userTimeZone)).then(function (param) {
                                    var arg = Belt_Option.getWithDefault(Belt_Int.fromString(nextReminderHour), 9);
                                    var arg$1 = Belt_Option.getWithDefault(reminderTimeZone$1, userTimeZone);
                                    var arg$2 = schemaBundle.branchId;
                                    var arg$3 = workspace.id;
                                    var arg$4 = workspace.id;
                                    var arg$5 = Belt_Array.concatMany([
                                          [
                                            "WorkspaceNotifications",
                                            "WorkspaceReminders"
                                          ],
                                          hasInspectorNotifications ? ["InspectorNotifications"] : []
                                        ]);
                                    AnalyticsUtils.withSchemaBundle((function (param) {
                                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19) {
                                              return AnalyticsRe.workspaceSettingUpdatedUpdateSlackRemindersHour(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19);
                                            };
                                            return function (param) {
                                              var func$1 = Curry._1(func, param);
                                              return function (param) {
                                                var func$2 = Curry._1(func$1, param);
                                                return function (param) {
                                                  var func$3 = Curry._1(func$2, param);
                                                  return function (param) {
                                                    var func$4 = Curry._1(func$3, param);
                                                    return function (param) {
                                                      var func$5 = Curry._1(func$4, param);
                                                      return function (param) {
                                                        var func$6 = Curry._1(func$5, param);
                                                        return function (param) {
                                                          var func$7 = Curry._1(func$6, param);
                                                          return function (param) {
                                                            var func$8 = Curry._1(func$7, param);
                                                            return function (param) {
                                                              var func$9 = Curry._1(func$8, param);
                                                              return function (param) {
                                                                return Curry.app(func$9, [
                                                                            param,
                                                                            arg,
                                                                            arg$1,
                                                                            arg$2,
                                                                            arg$3,
                                                                            arg$4,
                                                                            undefined,
                                                                            arg$5,
                                                                            undefined,
                                                                            undefined
                                                                          ]);
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          }), schemaBundle);
                                    
                                  });
                              
                            }),
                          options: Belt_Array.map(allHours, (function (param) {
                                  return [
                                          {
                                            NAME: "Label",
                                            VAL: param[1]
                                          },
                                          String(param[0])
                                        ];
                                })),
                          value: Belt_Option.mapWithDefault(reminderHour$1, String(9), (function (reminderHour) {
                                  return String(reminderHour);
                                }))
                        }), React.createElement(ConnectToSlackButton$ConnectedToSlack$TimeZoneSelect, {
                          value: Belt_Option.getWithDefault(reminderTimeZone$1, userTimeZone),
                          onSelect: (function (nextTimeZone) {
                              updateRemindersConfig(workspace.id, channel.id, hasRemindersEnabled, Belt_Option.getWithDefault(reminderHour$1, 9), nextTimeZone).then(function (param) {
                                    var arg = Belt_Option.getWithDefault(reminderHour$1, 9);
                                    var arg$1 = schemaBundle.branchId;
                                    var arg$2 = workspace.id;
                                    var arg$3 = workspace.id;
                                    var arg$4 = Belt_Array.concatMany([
                                          [
                                            "WorkspaceNotifications",
                                            "WorkspaceReminders"
                                          ],
                                          hasInspectorNotifications ? ["InspectorNotifications"] : []
                                        ]);
                                    AnalyticsUtils.withSchemaBundle((function (param) {
                                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19) {
                                              return AnalyticsRe.workspaceSettingUpdatedUpdateSlackRemindersTimeZone(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19);
                                            };
                                            return function (param) {
                                              var func$1 = Curry._1(func, param);
                                              return function (param) {
                                                var func$2 = Curry._1(func$1, param);
                                                return function (param) {
                                                  var func$3 = Curry._1(func$2, param);
                                                  return function (param) {
                                                    var func$4 = Curry._1(func$3, param);
                                                    return function (param) {
                                                      var func$5 = Curry._1(func$4, param);
                                                      return function (param) {
                                                        var func$6 = Curry._1(func$5, param);
                                                        return function (param) {
                                                          var func$7 = Curry._1(func$6, param);
                                                          return function (param) {
                                                            var func$8 = Curry._1(func$7, param);
                                                            return function (param) {
                                                              var func$9 = Curry._1(func$8, param);
                                                              return function (param) {
                                                                return Curry.app(func$9, [
                                                                            param,
                                                                            arg,
                                                                            nextTimeZone,
                                                                            arg$1,
                                                                            arg$2,
                                                                            arg$3,
                                                                            undefined,
                                                                            arg$4,
                                                                            undefined,
                                                                            undefined
                                                                          ]);
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          }), schemaBundle);
                                    
                                  });
                              
                            })
                        }))));
}

var ConnectedToSlack = {
  TimeZoneSelect: TimeZoneSelect,
  RemoveChannelFailed: RemoveChannelFailed,
  removeEventType: removeEventType,
  connectedBoxStyles: connectedBoxStyles,
  slackConnectionContainerStyles: slackConnectionContainerStyles,
  make: ConnectToSlackButton$ConnectedToSlack
};

var buttonStyles$1 = Curry._1(Css.style, Belt_List.concat(Styles.buttonReset, {
          hd: Css.padding4(Css.px(12), Css.px(20), Css.px(12), Css.px(12)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.borderRadius(Css.px(10)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.disabled({
                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                              tl: {
                                hd: Css.opacity(0.6),
                                tl: {
                                  hd: Css.cursor("default"),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: {
                          hd: Css.focus({
                                hd: Css.outlineStyle("none"),
                                tl: {
                                  hd: Css.boxShadows({
                                        hd: Css_Legacy_Core.Shadow.box(undefined, Css.px(5), Css.px(10), undefined, undefined, Css.rgba(0, 0, 0, {
                                                  NAME: "num",
                                                  VAL: 0.03
                                                })),
                                        tl: {
                                          hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), true, Styles.Color.darkBlue),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.focus({
                                        hd: Css.boxShadows({
                                              hd: Css_Legacy_Core.Shadow.box(undefined, Css.px(20), Css.px(40), undefined, undefined, Css.rgba(0, 0, 0, {
                                                        NAME: "num",
                                                        VAL: 0.1
                                                      })),
                                              tl: {
                                                hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), true, Styles.Color.darkBlue),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(20), Css.px(40), undefined, undefined, Css.rgba(0, 0, 0, {
                                                  NAME: "num",
                                                  VAL: 0.1
                                                }))),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: /* [] */0
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }));

var iconStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.width(Css.px(40)),
            tl: {
              hd: Css.height(Css.px(40)),
              tl: {
                hd: Css.marginRight(Css.px(12)),
                tl: {
                  hd: Css.borderRadius(Css.px(20)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function connectChannel(schemaId, redirectPath, eventType, useDevSlackApp) {
  var requestRef = Firebase.app(undefined).firestore().collection("oauthRequests").doc();
  window.open("https://slack.com/oauth/v2/authorize?client_id=" + SlackAppConfig.getClientId(useDevSlackApp) + "&scope=" + SlackAppConfig.getScopes(useDevSlackApp) + "&redirect_uri=" + SlackAppConfig.getRedirectUri(useDevSlackApp) + "&state=" + requestRef.id, "_blank", "width=600,height=750");
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  var oAuthRequest = {
    id: requestRef.id,
    createdAt: App.default.firestore.FieldValue.serverTimestamp(),
    createdBy: viewerId,
    service: "slack",
    schemaId: schemaId,
    redirectPath: redirectPath,
    eventTypes: [Models.NotificationChannel.printEventType(eventType)]
  };
  return requestRef.set(oAuthRequest);
}

function ConnectToSlackButton$ConnectToSlack(Props) {
  var schemaId = Props.schemaId;
  var redirectPath = Props.redirectPath;
  var eventType = Props.eventType;
  var onConnectOpt = Props.onConnect;
  var onConnect = onConnectOpt !== undefined ? onConnectOpt : (function (param) {
        
      });
  var match = React.useState(function () {
        return /* Initial */0;
      });
  var setState = match[1];
  var state = match[0];
  var useDevSlackApp = AppFeatureFlag.useFeatureFlag("UseDevSlackApp");
  var handleConnectClick = function (param) {
    Curry._1(setState, (function (param) {
            return /* Loading */1;
          }));
    $$Promise.$$catch(connectChannel(schemaId, redirectPath, eventType, useDevSlackApp).then(function (param) {
              Curry._1(onConnect, undefined);
              Curry._1(setState, (function (param) {
                      return /* Initial */0;
                    }));
              return Promise.resolve(undefined);
            }), (function (param) {
            return Promise.resolve(Curry._1(setState, (function (param) {
                              return /* Error */2;
                            })));
          }));
    
  };
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: buttonStyles$1,
                  disabled: state === /* Loading */1,
                  onClick: handleConnectClick
                }, React.createElement("div", {
                      className: iconStyles
                    }, React.createElement(IconSlackEmblem.make, {
                          size: 32
                        })), React.createElement($$Text.make, {
                      element: "Span",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Connect to Slack"
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(12)),
                        tl: /* [] */0
                      })
                }), state === /* Error */2 ? React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.red,
                    children: "Something went wrong when connecting to Slack. Please try again."
                  }) : null);
}

var ConnectToSlack = {
  buttonStyles: buttonStyles$1,
  iconStyles: iconStyles,
  connectChannel: connectChannel,
  make: ConnectToSlackButton$ConnectToSlack
};

var loaderBoxStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(20)),
      tl: {
        hd: Css.marginLeft(Css.px(20)),
        tl: /* [] */0
      }
    });

var noAccessStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingTop(Css.px(12)),
          tl: {
            hd: Css.paddingRight(Css.px(20)),
            tl: {
              hd: Css.paddingBottom(Css.px(12)),
              tl: {
                hd: Css.paddingLeft(Css.px(12)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.borderRadius(Css.px(10)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var iconStyles$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.width(Css.px(40)),
            tl: {
              hd: Css.height(Css.px(40)),
              tl: {
                hd: Css.marginRight(Css.px(12)),
                tl: {
                  hd: Css.borderRadius(Css.px(20)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function ConnectToSlackButton(Props) {
  var schemaId = Props.schemaId;
  var eventType = Props.eventType;
  var onSuccessRedirectPath = Props.onSuccessRedirectPath;
  var onConnect = Props.onConnect;
  var onDisconnect = Props.onDisconnect;
  var disconnectWarningMessage = Props.disconnectWarningMessage;
  var hasInspectorNotifications = Props.hasInspectorNotifications;
  var channels = FirebaseFetcherHooks.useNotificationChannels(undefined, schemaId, eventType);
  var viewerRole = FirebaseFetcherHooks.useViewerRole(schemaId);
  var exit = 0;
  var exit$1 = 0;
  if (typeof channels === "object") {
    if (channels.NAME === "Loaded") {
      var match = channels.VAL;
      if (match) {
        if (typeof viewerRole === "object") {
          if (viewerRole.NAME === "Success") {
            var tmp = {
              channel: match.hd,
              viewerRole: viewerRole.VAL,
              disconnectWarningMessage: disconnectWarningMessage,
              eventType: eventType,
              hasInspectorNotifications: hasInspectorNotifications
            };
            if (onDisconnect !== undefined) {
              tmp.onDisconnect = Caml_option.valFromOption(onDisconnect);
            }
            return React.createElement(ConnectToSlackButton$ConnectedToSlack, tmp);
          }
          exit$1 = 3;
        } else {
          exit$1 = 3;
        }
      } else if (typeof viewerRole === "object") {
        if (viewerRole.NAME === "Success") {
          var match$1 = viewerRole.VAL;
          if (!(match$1 === "Admin" || match$1 === "Editor")) {
            return React.createElement("div", {
                        className: noAccessStyles
                      }, React.createElement("div", {
                            className: iconStyles$1
                          }, React.createElement(IconSlackEmblem.make, {
                                size: 32
                              })), React.createElement("div", undefined, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.light08,
                                children: "Connect to Slack"
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.light10,
                                children: "Contact an Admin or Editor to get started"
                              })));
          }
          var tmp$1 = {
            schemaId: schemaId,
            redirectPath: onSuccessRedirectPath,
            eventType: eventType
          };
          if (onConnect !== undefined) {
            tmp$1.onConnect = Caml_option.valFromOption(onConnect);
          }
          return React.createElement(ConnectToSlackButton$ConnectToSlack, tmp$1);
        }
        exit$1 = 3;
      } else {
        exit$1 = 3;
      }
    } else {
      exit$1 = 3;
    }
  } else if (channels === "Initial") {
    exit = 1;
  } else {
    exit$1 = 3;
  }
  if (exit$1 === 3) {
    exit = viewerRole === "Loading" ? 1 : 2;
  }
  switch (exit) {
    case 1 :
        return React.createElement("div", {
                    className: loaderBoxStyles
                  }, React.createElement(LoadingCircle.make, {
                        color: Styles.Color.light07
                      }));
    case 2 :
        return React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.red,
                    children: "Something went wrong when fetching your connections. Try again."
                  });
    
  }
}

var make = ConnectToSlackButton;

export {
  updateRemindersConfig ,
  ConnectedToSlack ,
  ConnectToSlack ,
  loaderBoxStyles ,
  noAccessStyles ,
  iconStyles$1 as iconStyles,
  make ,
  
}
/* targetContainerStyles Not a pure module */
