// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

var dropdown_0 = Css.borderWidth("zero");

var dropdown_1 = {
  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                NAME: "num",
                VAL: 0.10
              }))),
  tl: {
    hd: Css.minWidth("fitContent"),
    tl: {
      hd: Css.padding(Css.px(0)),
      tl: {
        hd: Css.margin(Css.px(0)),
        tl: {
          hd: Css.marginTop(Css.px(5)),
          tl: {
            hd: Css.overflow("hidden"),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var dropdown = {
  hd: dropdown_0,
  tl: dropdown_1
};

var itemsWrapper_0 = Css.padding("zero");

var itemsWrapper_1 = {
  hd: Css.margin("zero"),
  tl: /* [] */0
};

var itemsWrapper = {
  hd: itemsWrapper_0,
  tl: itemsWrapper_1
};

function input(currentInputWidth) {
  return {
          hd: Css.fontFamily(Styles.FontFamily.inter),
          tl: {
            hd: Css.fontWeight(Styles.FontWeight.semi),
            tl: {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.width(Css.px(currentInputWidth)),
                tl: {
                  hd: Css.maxWidth(Css.px(250)),
                  tl: {
                    hd: Css.borderColor(Styles.Color.light04),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.textOverflow("ellipsis"),
                        tl: {
                          hd: Css.color(Styles.Color.light11),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$short
                                }, undefined, undefined, "background-color"),
                            tl: {
                              hd: Css.selector(" + div", {
                                    hd: Icon.color(Styles.Color.light09),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.color(Styles.Color.light12),
                                      tl: {
                                        hd: Css.borderColor(Styles.Color.light07),
                                        tl: {
                                          hd: Css.selector(" + div", {
                                                hd: Icon.color(Styles.Color.light11),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        };
}

var item_0 = Css.display("flex");

var item_1 = {
  hd: Css.alignItems("center"),
  tl: {
    hd: Css.margin("zero"),
    tl: {
      hd: Css.marginBottom(Css.px(1)),
      tl: {
        hd: Css.borderRadius("zero"),
        tl: {
          hd: Css.paddingRight(Css.px(32)),
          tl: {
            hd: Css.transitions({
                  hd: Css_Legacy_Core.Transition.shorthand({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "background-color"),
                  tl: {
                    hd: Css_Legacy_Core.Transition.shorthand({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "color"),
                    tl: /* [] */0
                  }
                }),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    }
  }
};

var item = {
  hd: item_0,
  tl: item_1
};

var itemSelected_0 = Css.backgroundColor(Styles.Color.white);

var itemSelected_1 = {
  hd: Css.position("relative"),
  tl: {
    hd: Css.hover({
          hd: Css.backgroundColor(Styles.Color.light02),
          tl: /* [] */0
        }),
    tl: {
      hd: Css.after({
            hd: Css.position("absolute"),
            tl: {
              hd: Css.top(Css.pct(50.0)),
              tl: {
                hd: Css.right(Css.px(12)),
                tl: {
                  hd: Css.marginTop(Css.px(-8)),
                  tl: {
                    hd: Css.width(Css.px(16)),
                    tl: {
                      hd: Css.height(Css.px(16)),
                      tl: {
                        hd: Css.unsafe("content", "url(\"data:image/svg+xml,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%3E%3Cpath%20fill%3D%22%23FF0EB4%22%20d%3D%22m14.53%205.03-8%208a.75.75%200%200%201-1.06%200l-3.5-3.5a.75.75%200%201%201%201.06-1.06L6%2011.44l7.47-7.47a.75.75%200%200%201%201.06%201.06Z%22%2F%3E%3C%2Fsvg%3E\")"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }),
      tl: /* [] */0
    }
  }
};

var itemSelected = {
  hd: itemSelected_0,
  tl: itemSelected_1
};

var iconWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.paddingRight(Css.px(10)),
            tl: /* [] */0
          }
        }
      }
    });

var icon = Curry._1(Css.style, {
      hd: Css.width(Css.px(28)),
      tl: {
        hd: Css.height(Css.px(28)),
        tl: {
          hd: Css.overflow("hidden"),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.borderRadius(Css.px(15)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function SourceSelectDropdown$ItemComponent(Props) {
  var item = Props.item;
  var el = item.icon;
  var description = item.description;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: /* [] */0
                  })
            }, el !== undefined ? React.createElement("div", {
                    className: iconWrapper
                  }, React.createElement("div", {
                        className: icon
                      }, el)) : null, React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      maxWidth: Css.pct(100.0),
                      children: item.label
                    }), description !== undefined ? React.createElement("div", {
                        className: "avo-select-item-description"
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Medium",
                            color: Styles.Color.light08,
                            withWordBreak: true,
                            children: description
                          })) : null));
}

var ItemComponent = Mantine.Select.Item({
      make: SourceSelectDropdown$ItemComponent
    });

function SourceSelectDropdown(Props) {
  var data = Props.data;
  var value = Props.value;
  var zIndex = Props.zIndex;
  var eventCount = Props.eventCount;
  var onChange = Props.onChange;
  var rightSectionRef = React.useRef(null);
  var match = React.useState(function () {
        return 100;
      });
  var setCurrentRightSectionWidth = match[1];
  var currentRightSectionWidth = match[0];
  React.useEffect((function () {
          Curry._1(setCurrentRightSectionWidth, (function (param) {
                  var rightSectionEl = rightSectionRef.current;
                  if (rightSectionEl == null) {
                    return 100;
                  } else {
                    return rightSectionEl.getBoundingClientRect().width;
                  }
                }));
          
        }), [value]);
  var currentLabelWidth = Belt_Option.mapWithDefault(Belt_Array.getBy(data, (function (item) {
              return Caml_obj.caml_equal(Caml_option.some(item.value), value);
            })), 100, (function (item) {
          return $$Text.getWidth(undefined, Styles.FontWeight.semi, Styles.FontSize.regular, item.label);
        }));
  var currentInputWidth = (currentLabelWidth + currentRightSectionWidth | 0) + 20 | 0;
  var tmp = {
    data: data,
    dropdownStyles: dropdown,
    inputStyles: input(currentInputWidth),
    itemComponent: ItemComponent.make,
    itemStyles: item,
    itemSelectedStyles: itemSelected,
    itemsWrapperStyles: itemsWrapper,
    onChange: onChange,
    rightSection: React.createElement("div", {
          ref: rightSectionRef,
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.paddingRight(Css.px(8)),
                    tl: /* [] */0
                  }
                }
              })
        }, React.createElement($$Text.make, {
              size: "Small",
              singleLine: true,
              color: Styles.Color.light10,
              children: String(eventCount) + " event" + (
                eventCount === 1 ? "" : "s"
              )
            }), React.createElement(Spacer.make, {
              width: 4
            }), React.createElement(Icon.make, {
              type_: "chevronDown",
              size: "small"
            })),
    rightSectionWidth: currentRightSectionWidth,
    size: "sm",
    withinPortal: true,
    zIndex: Belt_Option.getWithDefault(zIndex, 0)
  };
  var tmp$1 = data.length < 5 ? undefined : Math.min(Math.imul(54, data.length), 400);
  if (tmp$1 !== undefined) {
    tmp.maxDropdownHeight = Caml_option.valFromOption(tmp$1);
  }
  if (value !== undefined) {
    tmp.value = Caml_option.valFromOption(value);
  }
  return React.createElement(Mantine.Select.make, tmp);
}

var make = SourceSelectDropdown;

export {
  make ,
  
}
/* dropdown Not a pure module */
